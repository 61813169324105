const riot = require('riot');

riot.tag2('item-user-notes-column', '<div class="relative pl16 pr12 py8"> <div> <div class="w-full f fm fbw font-lato py6"> <div class="f fm"><img class="s28 circle object-fit-cover border border-white mr8" riot-src="{app.utils.getImageUrl(opts.user.data.icon_image)}"><span class="bold line-clamp-1 word-break-all lh13 mr8">{opts.user.data.screen_name}</span></div><span class="flex-fixed text-label_dark_medium fs12">({opts.notes.length})</span> </div> <div class="h90vh overflow-scroll" ref="body"> <div class="s-full px12 pb12" ref="notes" data-user-id="{opts.user.id}"> <div class="rounded-8 border border-transparent mxn1 mb6" each="{note in opts.notes}"> <div class="hover-trigger hover-board-shadow box-shadow-primary rounded-8 overflow-hidden transition" data-is="item-note" link="{location.href}" show-project="{true}" onclick="{onClickNote}" item="{note}"></div> </div> <div class="h1"></div> </div> </div> </div> </div>', '', '', function(opts) {
    this.on('mount', async () => {

      if (!app.useragent.isMobile) {
        Sortable.create(this.refs.notes, {
          group: 'user',
          animation: 150,

          avoidImplicitDeselect: true,
          selectedClass: 'border-primary',

          onEnd: async (e) => {
            const note = e.item._tag.note;

            const to_user_id = e.to.dataset.userId;
            const from_user_id = e.from.dataset.userId;
            const from_note_assign_user_ids = note.data.assigned_user_refs.map(user => user.id)

            const user_ids = [to_user_id, ...from_note_assign_user_ids];

            user_ids.splice(user_ids.indexOf(from_user_id), 1);
            try {
              await note.assign(user_ids);
              spat.toast.message("アサインを変更しました。", { icon: "assign"});
            }
            catch (err) {
              console.error(err);
            }
          }
        });
      }
    });

    this.onClickNote = (e) => {
      e.preventDefault();
      e.stopPropagation();
      this.onOpenNoteModal(e.item.note);
    };

    this.onOpenNoteModal = (note) => {
      spat.modal.open('modal-note', {
        note,
      });
    };
});