export const emojisJa = {
    "100": {
        "id": "100",
        "name": "Hundred Points",
        "keywords": [
            "100",
            "score",
            "perfect",
            "numbers",
            "century",
            "exam",
            "quiz",
            "test",
            "pass",
            "100",
            "スコア",
            "完全",
            "数字",
            "世紀",
            "テスト",
            "クイズ",
            "テスト",
            "合格"
        ],
        "skins": [
            {
                "unified": "1f4af",
                "native": "💯"
            }
        ],
        "version": 1
    },
    "1234": {
        "id": "1234",
        "name": "Input Numbers",
        "keywords": [
            "1234",
            "blue",
            "square",
            "1234",
            "青",
            "四角"
        ],
        "skins": [
            {
                "unified": "1f522",
                "native": "🔢"
            }
        ],
        "version": 1
    },
    "grinning": {
        "id": "grinning",
        "name": "Grinning Face",
        "emoticons": [
            ":D"
        ],
        "keywords": [
            "smile",
            "happy",
            "joy",
            ":D",
            "grin",
            "笑顔",
            "ハッピー",
            "喜び",
            ":D",
            "にっこり"
        ],
        "skins": [
            {
                "unified": "1f600",
                "native": "😀"
            }
        ],
        "version": 1
    },
    "smiley": {
        "id": "smiley",
        "name": "Grinning Face with Big Eyes",
        "emoticons": [
            ":)",
            "=)",
            "=-)"
        ],
        "keywords": [
            "smiley",
            "happy",
            "joy",
            "haha",
            ":D",
            ":)",
            "smile",
            "funny",
            "スマイリー",
            "ハッピー",
            "喜び",
            "はは",
            ":D",
            ":)",
            "笑顔",
            "面白い"
        ],
        "skins": [
            {
                "unified": "1f603",
                "native": "😃"
            }
        ],
        "version": 1
    },
    "smile": {
        "id": "smile",
        "name": "Grinning Face with Smiling Eyes",
        "emoticons": [
            ":)",
            "C:",
            "c:",
            ":D",
            ":-D"
        ],
        "keywords": [
            "smile",
            "happy",
            "joy",
            "funny",
            "haha",
            "laugh",
            "like",
            ":D",
            ":)",
            "笑顔",
            "ハッピー",
            "喜び",
            "面白い",
            "はは",
            "笑う",
            "好き",
            ":D",
            ":)"
        ],
        "skins": [
            {
                "unified": "1f604",
                "native": "😄"
            }
        ],
        "version": 1
    },
    "grin": {
        "id": "grin",
        "name": "Beaming Face with Smiling Eyes",
        "keywords": [
            "grin",
            "happy",
            "smile",
            "joy",
            "kawaii",
            "ニヤリ",
            "ハッピー",
            "笑顔",
            "喜び",
            "かわいい"
        ],
        "skins": [
            {
                "unified": "1f601",
                "native": "😁"
            }
        ],
        "version": 1
    },
    "laughing": {
        "id": "laughing",
        "name": "Grinning Squinting Face",
        "emoticons": [
            ":>",
            ":->"
        ],
        "keywords": [
            "laughing",
            "satisfied",
            "happy",
            "joy",
            "lol",
            "haha",
            "glad",
            "XD",
            "laugh",
            "笑い",
            "満足",
            "ハッピー",
            "喜び",
            "笑",
            "はは",
            "嬉しい",
            "XD",
            "笑う"
        ],
        "skins": [
            {
                "unified": "1f606",
                "native": "😆"
            }
        ],
        "version": 1
    },
    "sweat_smile": {
        "id": "sweat_smile",
        "name": "Grinning Face with Sweat",
        "keywords": [
            "smile",
            "hot",
            "happy",
            "laugh",
            "relief",
            "笑顔",
            "熱い",
            "ハッピー",
            "笑う",
            "安心"
        ],
        "skins": [
            {
                "unified": "1f605",
                "native": "😅"
            }
        ],
        "version": 1
    },
    "rolling_on_the_floor_laughing": {
        "id": "rolling_on_the_floor_laughing",
        "name": "Rolling on the Floor Laughing",
        "keywords": [
            "face",
            "lol",
            "haha",
            "rofl",
            "顔",
            "笑",
            "はは",
            "ロフル"
        ],
        "skins": [
            {
                "unified": "1f923",
                "native": "🤣"
            }
        ],
        "version": 3
    },
    "joy": {
        "id": "joy",
        "name": "Face with Tears of Joy",
        "keywords": [
            "cry",
            "weep",
            "happy",
            "happytears",
            "haha",
            "泣く",
            "泣く",
            "ハッピー",
            "ハッピーティアーズ",
            "ハハ"
        ],
        "skins": [
            {
                "unified": "1f602",
                "native": "😂"
            }
        ],
        "version": 1
    },
    "slightly_smiling_face": {
        "id": "slightly_smiling_face",
        "name": "Slightly Smiling Face",
        "emoticons": [
            ":)",
            "(:",
            ":-)"
        ],
        "keywords": [
            "smile",
            "笑顔"
        ],
        "skins": [
            {
                "unified": "1f642",
                "native": "🙂"
            }
        ],
        "version": 1
    },
    "upside_down_face": {
        "id": "upside_down_face",
        "name": "Upside-Down Face",
        "keywords": [
            "upside",
            "down",
            "flipped",
            "silly",
            "smile",
            "上向き",
            "下",
            "反転",
            "馬鹿な",
            "笑顔"
        ],
        "skins": [
            {
                "unified": "1f643",
                "native": "🙃"
            }
        ],
        "version": 1
    },
    "melting_face": {
        "id": "melting_face",
        "name": "Melting Face",
        "keywords": [
            "hot",
            "heat",
            "熱い",
            "熱"
        ],
        "skins": [
            {
                "unified": "1fae0",
                "native": "🫠"
            }
        ],
        "version": 14
    },
    "wink": {
        "id": "wink",
        "name": "Winking Face",
        "emoticons": [
            ";)",
            ";-)"
        ],
        "keywords": [
            "wink",
            "happy",
            "mischievous",
            "secret",
            ";)",
            "smile",
            "eye",
            "ウィンク",
            "ハッピー",
            "いたずら",
            "ひみつ",
            ";)",
            "笑顔",
            "目"
        ],
        "skins": [
            {
                "unified": "1f609",
                "native": "😉"
            }
        ],
        "version": 1
    },
    "blush": {
        "id": "blush",
        "name": "Smiling Face with Smiling Eyes",
        "emoticons": [
            ":)"
        ],
        "keywords": [
            "blush",
            "smile",
            "happy",
            "flushed",
            "crush",
            "embarrassed",
            "shy",
            "joy",
            "赤面",
            "笑顔",
            "ハッピー",
            "フラッシュ",
            "打ち砕く",
            "恥ずかしい",
            "シャイ",
            "喜び"
        ],
        "skins": [
            {
                "unified": "1f60a",
                "native": "😊"
            }
        ],
        "version": 1
    },
    "innocent": {
        "id": "innocent",
        "name": "Smiling Face with Halo",
        "keywords": [
            "innocent",
            "angel",
            "heaven",
            "無垢",
            "天使",
            "天気"
        ],
        "skins": [
            {
                "unified": "1f607",
                "native": "😇"
            }
        ],
        "version": 1
    },
    "smiling_face_with_3_hearts": {
        "id": "smiling_face_with_3_hearts",
        "name": "Smiling Face with Hearts",
        "keywords": [
            "3",
            "love",
            "like",
            "affection",
            "valentines",
            "infatuation",
            "crush",
            "adore",
            "3",
            "愛",
            "好き",
            "愛情",
            "バレンタイン",
            "心酔",
            "打ち砕く",
            "崇拝します"
        ],
        "skins": [
            {
                "unified": "1f970",
                "native": "🥰"
            }
        ],
        "version": 11
    },
    "heart_eyes": {
        "id": "heart_eyes",
        "name": "Smiling Face with Heart-Eyes",
        "keywords": [
            "heart",
            "eyes",
            "love",
            "like",
            "affection",
            "valentines",
            "infatuation",
            "crush",
            "心臓",
            "目",
            "愛",
            "好き",
            "愛情",
            "バレンタイン",
            "心酔",
            "打ち砕く"
        ],
        "skins": [
            {
                "unified": "1f60d",
                "native": "😍"
            }
        ],
        "version": 1
    },
    "star-struck": {
        "id": "star-struck",
        "name": "Star-Struck",
        "keywords": [
            "star",
            "struck",
            "grinning",
            "face",
            "with",
            "eyes",
            "smile",
            "starry",
            "星",
            "打たれた",
            "にやにや",
            "顔",
            "と",
            "目",
            "笑顔",
            "星空"
        ],
        "skins": [
            {
                "unified": "1f929",
                "native": "🤩"
            }
        ],
        "version": 5
    },
    "kissing_heart": {
        "id": "kissing_heart",
        "name": "Face Blowing a Kiss",
        "emoticons": [
            ":*",
            ":-*"
        ],
        "keywords": [
            "kissing",
            "heart",
            "love",
            "like",
            "affection",
            "valentines",
            "infatuation",
            "キス",
            "心臓",
            "愛",
            "好き",
            "愛情",
            "バレンタイン",
            "夢中"
        ],
        "skins": [
            {
                "unified": "1f618",
                "native": "😘"
            }
        ],
        "version": 1
    },
    "kissing": {
        "id": "kissing",
        "name": "Kissing Face",
        "keywords": [
            "love",
            "like",
            "3",
            "valentines",
            "infatuation",
            "kiss",
            "愛",
            "好き",
            "3",
            "バレンタイン",
            "心酔",
            "キス"
        ],
        "skins": [
            {
                "unified": "1f617",
                "native": "😗"
            }
        ],
        "version": 1
    },
    "relaxed": {
        "id": "relaxed",
        "name": "Smiling Face",
        "keywords": [
            "relaxed",
            "blush",
            "massage",
            "happiness",
            "リラックスした",
            "赤面",
            "マッサージ",
            "幸せ"
        ],
        "skins": [
            {
                "unified": "263a-fe0f",
                "native": "☺️"
            }
        ],
        "version": 1
    },
    "kissing_closed_eyes": {
        "id": "kissing_closed_eyes",
        "name": "Kissing Face with Closed Eyes",
        "keywords": [
            "love",
            "like",
            "affection",
            "valentines",
            "infatuation",
            "kiss",
            "愛",
            "好き",
            "愛情",
            "バレンタイン",
            "心酔",
            "キス"
        ],
        "skins": [
            {
                "unified": "1f61a",
                "native": "😚"
            }
        ],
        "version": 1
    },
    "kissing_smiling_eyes": {
        "id": "kissing_smiling_eyes",
        "name": "Kissing Face with Smiling Eyes",
        "keywords": [
            "affection",
            "valentines",
            "infatuation",
            "kiss",
            "愛情",
            "バレンタイン",
            "心酔",
            "キス"
        ],
        "skins": [
            {
                "unified": "1f619",
                "native": "😙"
            }
        ],
        "version": 1
    },
    "smiling_face_with_tear": {
        "id": "smiling_face_with_tear",
        "name": "Smiling Face with Tear",
        "keywords": [
            "sad",
            "cry",
            "pretend",
            "悲しい",
            "泣く",
            "演じる"
        ],
        "skins": [
            {
                "unified": "1f972",
                "native": "🥲"
            }
        ],
        "version": 13
    },
    "yum": {
        "id": "yum",
        "name": "Face Savoring Food",
        "keywords": [
            "yum",
            "happy",
            "joy",
            "tongue",
            "smile",
            "silly",
            "yummy",
            "nom",
            "delicious",
            "savouring",
            "うむ",
            "ハッピー",
            "喜び",
            "舌",
            "笑顔",
            "馬鹿な",
            "うまい",
            "ノム",
            "美味しい",
            "味わう"
        ],
        "skins": [
            {
                "unified": "1f60b",
                "native": "😋"
            }
        ],
        "version": 1
    },
    "stuck_out_tongue": {
        "id": "stuck_out_tongue",
        "name": "Face with Tongue",
        "emoticons": [
            ":p",
            ":-p",
            ":P",
            ":-P",
            ":b",
            ":-b"
        ],
        "keywords": [
            "stuck",
            "out",
            "prank",
            "childish",
            "playful",
            "mischievous",
            "smile",
            "立ち往生",
            "外",
            "いたずら",
            "子供っぽい",
            "遊び心",
            "いたずら",
            "笑顔"
        ],
        "skins": [
            {
                "unified": "1f61b",
                "native": "😛"
            }
        ],
        "version": 1
    },
    "stuck_out_tongue_winking_eye": {
        "id": "stuck_out_tongue_winking_eye",
        "name": "Winking Face with Tongue",
        "emoticons": [
            ";p",
            ";-p",
            ";b",
            ";-b",
            ";P",
            ";-P"
        ],
        "keywords": [
            "stuck",
            "out",
            "eye",
            "prank",
            "childish",
            "playful",
            "mischievous",
            "smile",
            "wink",
            "立ち往生",
            "外",
            "目",
            "いたずら",
            "子供っぽい",
            "遊び心",
            "いたずら",
            "笑顔",
            "ウィンク"
        ],
        "skins": [
            {
                "unified": "1f61c",
                "native": "😜"
            }
        ],
        "version": 1
    },
    "zany_face": {
        "id": "zany_face",
        "name": "Zany Face",
        "keywords": [
            "grinning",
            "with",
            "one",
            "large",
            "and",
            "small",
            "eye",
            "goofy",
            "crazy",
            "にやにや",
            "と",
            "一",
            "大きい",
            "と",
            "小さい",
            "目",
            "間抜けな",
            "クレイジー"
        ],
        "skins": [
            {
                "unified": "1f92a",
                "native": "🤪"
            }
        ],
        "version": 5
    },
    "stuck_out_tongue_closed_eyes": {
        "id": "stuck_out_tongue_closed_eyes",
        "name": "Squinting Face with Tongue",
        "keywords": [
            "stuck",
            "out",
            "closed",
            "eyes",
            "prank",
            "playful",
            "mischievous",
            "smile",
            "立ち往生",
            "外",
            "閉まっている",
            "目",
            "いたずら",
            "遊び心",
            "いたずら",
            "笑顔"
        ],
        "skins": [
            {
                "unified": "1f61d",
                "native": "😝"
            }
        ],
        "version": 1
    },
    "money_mouth_face": {
        "id": "money_mouth_face",
        "name": "Money-Mouth Face",
        "keywords": [
            "money",
            "mouth",
            "rich",
            "dollar",
            "お金",
            "口",
            "リッチ",
            "ドル"
        ],
        "skins": [
            {
                "unified": "1f911",
                "native": "🤑"
            }
        ],
        "version": 1
    },
    "hugging_face": {
        "id": "hugging_face",
        "name": "Hugging Face",
        "keywords": [
            "smile",
            "hug",
            "笑顔",
            "ハグ"
        ],
        "skins": [
            {
                "unified": "1f917",
                "native": "🤗"
            }
        ],
        "version": 1
    },
    "face_with_hand_over_mouth": {
        "id": "face_with_hand_over_mouth",
        "name": "Face with Hand over Mouth",
        "keywords": [
            "smiling",
            "eyes",
            "and",
            "covering",
            "whoops",
            "shock",
            "surprise",
            "笑顔",
            "目",
            "と",
            "カバー",
            "おっと",
            "ショック",
            "サプライズ"
        ],
        "skins": [
            {
                "unified": "1f92d",
                "native": "🤭"
            }
        ],
        "version": 5
    },
    "face_with_open_eyes_and_hand_over_mouth": {
        "id": "face_with_open_eyes_and_hand_over_mouth",
        "name": "Face with Open Eyes and Hand over Mouth",
        "keywords": [
            "silence",
            "secret",
            "shock",
            "surprise",
            "沈黙",
            "ひみつ",
            "ショック",
            "サプライズ"
        ],
        "skins": [
            {
                "unified": "1fae2",
                "native": "🫢"
            }
        ],
        "version": 14
    },
    "face_with_peeking_eye": {
        "id": "face_with_peeking_eye",
        "name": "Face with Peeking Eye",
        "keywords": [
            "scared",
            "frightening",
            "embarrassing",
            "怖がった",
            "恐ろしい",
            "恥ずかしい",
            "ガーン",
            "ショック",
        ],
        "skins": [
            {
                "unified": "1fae3",
                "native": "🫣"
            }
        ],
        "version": 14
    },
    "shushing_face": {
        "id": "shushing_face",
        "name": "Shushing Face",
        "keywords": [
            "with",
            "finger",
            "covering",
            "closed",
            "lips",
            "quiet",
            "shhh",
            "と",
            "指",
            "カバー",
            "閉まっている",
            "唇",
            "静かな",
            "シーッ"
        ],
        "skins": [
            {
                "unified": "1f92b",
                "native": "🤫"
            }
        ],
        "version": 5
    },
    "thinking_face": {
        "id": "thinking_face",
        "name": "Thinking Face",
        "keywords": [
            "hmmm",
            "think",
            "consider",
            "うーん",
            "考え",
            "検討"
        ],
        "skins": [
            {
                "unified": "1f914",
                "native": "🤔"
            }
        ],
        "version": 1
    },
    "saluting_face": {
        "id": "saluting_face",
        "name": "Saluting Face",
        "keywords": [
            "respect",
            "salute",
            "尊敬",
            "敬礼"
        ],
        "skins": [
            {
                "unified": "1fae1",
                "native": "🫡"
            }
        ],
        "version": 14
    },
    "zipper_mouth_face": {
        "id": "zipper_mouth_face",
        "name": "Zipper-Mouth Face",
        "keywords": [
            "zipper",
            "mouth",
            "sealed",
            "secret",
            "ジッパー",
            "口",
            "封印された",
            "ひみつ"
        ],
        "skins": [
            {
                "unified": "1f910",
                "native": "🤐"
            }
        ],
        "version": 1
    },
    "face_with_raised_eyebrow": {
        "id": "face_with_raised_eyebrow",
        "name": "Face with Raised Eyebrow",
        "keywords": [
            "one",
            "distrust",
            "scepticism",
            "disapproval",
            "disbelief",
            "surprise",
            "一",
            "不信",
            "懐疑主義",
            "不承認",
            "不信",
            "サプライズ"
        ],
        "skins": [
            {
                "unified": "1f928",
                "native": "🤨"
            }
        ],
        "version": 5
    },
    "neutral_face": {
        "id": "neutral_face",
        "name": "Neutral Face",
        "emoticons": [
            ":|",
            ":-|"
        ],
        "keywords": [
            "indifference",
            "meh",
            ":",
            "",
            "無関心",
            "まあ",
            ":",
            ""
        ],
        "skins": [
            {
                "unified": "1f610",
                "native": "😐"
            }
        ],
        "version": 1
    },
    "expressionless": {
        "id": "expressionless",
        "name": "Expressionless Face",
        "emoticons": [
            "-_-"
        ],
        "keywords": [
            "indifferent",
            "-",
            "",
            "meh",
            "deadpan",
            "無関心",
            "-",
            "",
            "まあ",
            "デッドパン"
        ],
        "skins": [
            {
                "unified": "1f611",
                "native": "😑"
            }
        ],
        "version": 1
    },
    "no_mouth": {
        "id": "no_mouth",
        "name": "Face Without Mouth",
        "keywords": [
            "no",
            "hellokitty",
            "いいえ",
            "ハローキティ"
        ],
        "skins": [
            {
                "unified": "1f636",
                "native": "😶"
            }
        ],
        "version": 1
    },
    "dotted_line_face": {
        "id": "dotted_line_face",
        "name": "Dotted Line Face",
        "keywords": [
            "invisible",
            "lonely",
            "isolation",
            "depression",
            "見えない",
            "さみしい",
            "隔離",
            "うつ"
        ],
        "skins": [
            {
                "unified": "1fae5",
                "native": "🫥"
            }
        ],
        "version": 14
    },
    "face_in_clouds": {
        "id": "face_in_clouds",
        "name": "Face in Clouds",
        "keywords": [
            "shower",
            "steam",
            "dream",
            "シャワー",
            "蒸気",
            "夢"
        ],
        "skins": [
            {
                "unified": "1f636-200d-1f32b-fe0f",
                "native": "😶‍🌫️"
            }
        ],
        "version": 13.1
    },
    "smirk": {
        "id": "smirk",
        "name": "Smirking Face",
        "keywords": [
            "smirk",
            "smile",
            "mean",
            "prank",
            "smug",
            "sarcasm",
            "ニヤリ",
            "笑顔",
            "平均",
            "いたずら",
            "スマグ",
            "皮肉"
        ],
        "skins": [
            {
                "unified": "1f60f",
                "native": "😏"
            }
        ],
        "version": 1
    },
    "unamused": {
        "id": "unamused",
        "name": "Unamused Face",
        "emoticons": [
            ":("
        ],
        "keywords": [
            "indifference",
            "bored",
            "straight",
            "serious",
            "sarcasm",
            "unimpressed",
            "skeptical",
            "dubious",
            "side",
            "eye",
            "無関心",
            "退屈",
            "真っ直ぐ",
            "深刻",
            "皮肉",
            "感銘を受けていない",
            "懐疑的",
            "疑わしい",
            "側",
            "目"
        ],
        "skins": [
            {
                "unified": "1f612",
                "native": "😒"
            }
        ],
        "version": 1
    },
    "face_with_rolling_eyes": {
        "id": "face_with_rolling_eyes",
        "name": "Face with Rolling Eyes",
        "keywords": [
            "eyeroll",
            "frustrated",
            "アイロール",
            "欲求不満"
        ],
        "skins": [
            {
                "unified": "1f644",
                "native": "🙄"
            }
        ],
        "version": 1
    },
    "grimacing": {
        "id": "grimacing",
        "name": "Grimacing Face",
        "keywords": [
            "grimace",
            "teeth",
            "しかめっ面",
            "歯"
        ],
        "skins": [
            {
                "unified": "1f62c",
                "native": "😬"
            }
        ],
        "version": 1
    },
    "face_exhaling": {
        "id": "face_exhaling",
        "name": "Face Exhaling",
        "keywords": [
            "relieve",
            "relief",
            "tired",
            "sigh",
            "緩和",
            "安心",
            "疲れた",
            "はぁ"
        ],
        "skins": [
            {
                "unified": "1f62e-200d-1f4a8",
                "native": "😮‍💨"
            }
        ],
        "version": 13.1
    },
    "lying_face": {
        "id": "lying_face",
        "name": "Lying Face",
        "keywords": [
            "lie",
            "pinocchio",
            "嘘",
            "ピノキオ"
        ],
        "skins": [
            {
                "unified": "1f925",
                "native": "🤥"
            }
        ],
        "version": 3
    },
    "relieved": {
        "id": "relieved",
        "name": "Relieved Face",
        "keywords": [
            "relaxed",
            "phew",
            "massage",
            "happiness",
            "リラックスした",
            "ふう",
            "マッサージ",
            "幸せ"
        ],
        "skins": [
            {
                "unified": "1f60c",
                "native": "😌"
            }
        ],
        "version": 1
    },
    "pensive": {
        "id": "pensive",
        "name": "Pensive Face",
        "keywords": [
            "sad",
            "depressed",
            "upset",
            "悲しい",
            "うつ病",
            "動揺"
        ],
        "skins": [
            {
                "unified": "1f614",
                "native": "😔"
            }
        ],
        "version": 1
    },
    "sleepy": {
        "id": "sleepy",
        "name": "Sleepy Face",
        "keywords": [
            "tired",
            "rest",
            "nap",
            "疲れた",
            "休み",
            "昼寝"
        ],
        "skins": [
            {
                "unified": "1f62a",
                "native": "😪"
            }
        ],
        "version": 1
    },
    "drooling_face": {
        "id": "drooling_face",
        "name": "Drooling Face",
        "keywords": [],
        "skins": [
            {
                "unified": "1f924",
                "native": "🤤"
            }
        ],
        "version": 3
    },
    "sleeping": {
        "id": "sleeping",
        "name": "Sleeping Face",
        "keywords": [
            "tired",
            "sleepy",
            "night",
            "zzz",
            "疲れた",
            "眠る",
            "夜",
            "ズッ"
        ],
        "skins": [
            {
                "unified": "1f634",
                "native": "😴"
            }
        ],
        "version": 1
    },
    "mask": {
        "id": "mask",
        "name": "Face with Medical Mask",
        "keywords": [
            "sick",
            "ill",
            "disease",
            "病気",
            "病気",
            "疾患"
        ],
        "skins": [
            {
                "unified": "1f637",
                "native": "😷"
            }
        ],
        "version": 1
    },
    "face_with_thermometer": {
        "id": "face_with_thermometer",
        "name": "Face with Thermometer",
        "keywords": [
            "sick",
            "temperature",
            "cold",
            "fever",
            "病気",
            "温度",
            "寒い",
            "熱"
        ],
        "skins": [
            {
                "unified": "1f912",
                "native": "🤒"
            }
        ],
        "version": 1
    },
    "face_with_head_bandage": {
        "id": "face_with_head_bandage",
        "name": "Face with Head-Bandage",
        "keywords": [
            "head",
            "bandage",
            "injured",
            "clumsy",
            "hurt",
            "頭",
            "包帯",
            "怪我した",
            "不器用",
            "傷つく"
        ],
        "skins": [
            {
                "unified": "1f915",
                "native": "🤕"
            }
        ],
        "version": 1
    },
    "nauseated_face": {
        "id": "nauseated_face",
        "name": "Nauseated Face",
        "keywords": [
            "vomit",
            "gross",
            "green",
            "sick",
            "throw",
            "up",
            "ill",
            "吐瀉物",
            "きもい",
            "緑",
            "病気",
            "投げる",
            "上",
            "病気"
        ],
        "skins": [
            {
                "unified": "1f922",
                "native": "🤢"
            }
        ],
        "version": 3
    },
    "face_vomiting": {
        "id": "face_vomiting",
        "name": "Face Vomiting",
        "keywords": [
            "with",
            "open",
            "mouth",
            "sick",
            "と",
            "開ける",
            "口",
            "病気"
        ],
        "skins": [
            {
                "unified": "1f92e",
                "native": "🤮"
            }
        ],
        "version": 5
    },
    "sneezing_face": {
        "id": "sneezing_face",
        "name": "Sneezing Face",
        "keywords": [
            "gesundheit",
            "sneeze",
            "sick",
            "allergy",
            "ゲスンハイト",
            "くしゃみ",
            "病気",
            "アレルギー"
        ],
        "skins": [
            {
                "unified": "1f927",
                "native": "🤧"
            }
        ],
        "version": 3
    },
    "hot_face": {
        "id": "hot_face",
        "name": "Hot Face",
        "keywords": [
            "feverish",
            "heat",
            "red",
            "sweating",
            "熱っぽい",
            "熱",
            "赤",
            "発汗"
        ],
        "skins": [
            {
                "unified": "1f975",
                "native": "🥵"
            }
        ],
        "version": 11
    },
    "cold_face": {
        "id": "cold_face",
        "name": "Cold Face",
        "keywords": [
            "blue",
            "freezing",
            "frozen",
            "frostbite",
            "icicles",
            "青",
            "凍結",
            "凍った",
            "凍傷",
            "つらら"
        ],
        "skins": [
            {
                "unified": "1f976",
                "native": "🥶"
            }
        ],
        "version": 11
    },
    "woozy_face": {
        "id": "woozy_face",
        "name": "Woozy Face",
        "keywords": [
            "dizzy",
            "intoxicated",
            "tipsy",
            "wavy",
            "めまい",
            "酔っ払った",
            "ほろ酔い",
            "波状"
        ],
        "skins": [
            {
                "unified": "1f974",
                "native": "🥴"
            }
        ],
        "version": 11
    },
    "dizzy_face": {
        "id": "dizzy_face",
        "name": "Dizzy Face",
        "keywords": [
            "spent",
            "unconscious",
            "xox",
            "消耗",
            "無意識",
            "クソ"
        ],
        "skins": [
            {
                "unified": "1f635",
                "native": "😵"
            }
        ],
        "version": 1
    },
    "face_with_spiral_eyes": {
        "id": "face_with_spiral_eyes",
        "name": "Face with Spiral Eyes",
        "keywords": [
            "sick",
            "ill",
            "confused",
            "nauseous",
            "nausea",
            "病気",
            "病気",
            "混乱している",
            "吐き気",
            "吐き気"
        ],
        "skins": [
            {
                "unified": "1f635-200d-1f4ab",
                "native": "😵‍💫"
            }
        ],
        "version": 13.1
    },
    "exploding_head": {
        "id": "exploding_head",
        "name": "Exploding Head",
        "keywords": [
            "shocked",
            "face",
            "with",
            "mind",
            "blown",
            "ショックを受けた",
            "顔",
            "と",
            "マインド",
            "吹いた"
        ],
        "skins": [
            {
                "unified": "1f92f",
                "native": "🤯"
            }
        ],
        "version": 5
    },
    "face_with_cowboy_hat": {
        "id": "face_with_cowboy_hat",
        "name": "Cowboy Hat Face",
        "keywords": [
            "with",
            "cowgirl",
            "と",
            "カウガール"
        ],
        "skins": [
            {
                "unified": "1f920",
                "native": "🤠"
            }
        ],
        "version": 3
    },
    "partying_face": {
        "id": "partying_face",
        "name": "Partying Face",
        "keywords": [
            "celebration",
            "woohoo",
            "お祝い",
            "ウーフー"
        ],
        "skins": [
            {
                "unified": "1f973",
                "native": "🥳"
            }
        ],
        "version": 11
    },
    "disguised_face": {
        "id": "disguised_face",
        "name": "Disguised Face",
        "keywords": [
            "pretent",
            "brows",
            "glasses",
            "moustache",
            "ふりをする",
            "眉",
            "眼鏡",
            "口ひげ"
        ],
        "skins": [
            {
                "unified": "1f978",
                "native": "🥸"
            }
        ],
        "version": 13
    },
    "sunglasses": {
        "id": "sunglasses",
        "name": "Smiling Face with Sunglasses",
        "emoticons": [
            "8)"
        ],
        "keywords": [
            "cool",
            "smile",
            "summer",
            "beach",
            "sunglass",
            "いいね",
            "笑顔",
            "夏",
            "ビーチ",
            "サングラス"
        ],
        "skins": [
            {
                "unified": "1f60e",
                "native": "😎"
            }
        ],
        "version": 1
    },
    "nerd_face": {
        "id": "nerd_face",
        "name": "Nerd Face",
        "keywords": [
            "nerdy",
            "geek",
            "dork",
            "オタク",
            "オタク",
            "ドーク"
        ],
        "skins": [
            {
                "unified": "1f913",
                "native": "🤓"
            }
        ],
        "version": 1
    },
    "face_with_monocle": {
        "id": "face_with_monocle",
        "name": "Face with Monocle",
        "keywords": [
            "stuffy",
            "wealthy",
            "息苦しい",
            "裕福"
        ],
        "skins": [
            {
                "unified": "1f9d0",
                "native": "🧐"
            }
        ],
        "version": 5
    },
    "confused": {
        "id": "confused",
        "name": "Confused Face",
        "emoticons": [
            ":\\",
            ":-\\",
            ":/",
            ":-/"
        ],
        "keywords": [
            "indifference",
            "huh",
            "weird",
            "hmmm",
            ":/",
            "無関心",
            "は",
            "変",
            "うーん",
            ":/"
        ],
        "skins": [
            {
                "unified": "1f615",
                "native": "😕"
            }
        ],
        "version": 1
    },
    "face_with_diagonal_mouth": {
        "id": "face_with_diagonal_mouth",
        "name": "Face with Diagonal Mouth",
        "keywords": [
            "skeptic",
            "confuse",
            "frustrated",
            "indifferent",
            "懐疑的",
            "混乱",
            "欲求不満",
            "無関心"
        ],
        "skins": [
            {
                "unified": "1fae4",
                "native": "🫤"
            }
        ],
        "version": 14
    },
    "worried": {
        "id": "worried",
        "name": "Worried Face",
        "keywords": [
            "concern",
            "nervous",
            ":(",
            "懸念",
            "緊張",
            ":("
        ],
        "skins": [
            {
                "unified": "1f61f",
                "native": "😟"
            }
        ],
        "version": 1
    },
    "slightly_frowning_face": {
        "id": "slightly_frowning_face",
        "name": "Slightly Frowning Face",
        "keywords": [
            "disappointed",
            "sad",
            "upset",
            "残念だった",
            "悲しい",
            "動揺"
        ],
        "skins": [
            {
                "unified": "1f641",
                "native": "🙁"
            }
        ],
        "version": 1
    },
    "white_frowning_face": {
        "id": "white_frowning_face",
        "name": "Frowning Face",
        "keywords": [
            "white",
            "sad",
            "upset",
            "frown",
            "白",
            "悲しい",
            "動揺",
            "しかめっ面"
        ],
        "skins": [
            {
                "unified": "2639-fe0f",
                "native": "☹️"
            }
        ],
        "version": 1
    },
    "open_mouth": {
        "id": "open_mouth",
        "name": "Face with Open Mouth",
        "emoticons": [
            ":o",
            ":-o",
            ":O",
            ":-O"
        ],
        "keywords": [
            "surprise",
            "impressed",
            "wow",
            "whoa",
            ":O",
            "サプライズ",
            "感動",
            "おお",
            "うわあ",
            ":O"
        ],
        "skins": [
            {
                "unified": "1f62e",
                "native": "😮"
            }
        ],
        "version": 1
    },
    "hushed": {
        "id": "hushed",
        "name": "Hushed Face",
        "keywords": [
            "woo",
            "shh",
            "ウー",
            "シーッ"
        ],
        "skins": [
            {
                "unified": "1f62f",
                "native": "😯"
            }
        ],
        "version": 1
    },
    "astonished": {
        "id": "astonished",
        "name": "Astonished Face",
        "keywords": [
            "xox",
            "surprised",
            "poisoned",
            "xox",
            "驚いた",
            "中毒"
        ],
        "skins": [
            {
                "unified": "1f632",
                "native": "😲"
            }
        ],
        "version": 1
    },
    "flushed": {
        "id": "flushed",
        "name": "Flushed Face",
        "keywords": [
            "blush",
            "shy",
            "flattered",
            "赤面",
            "シャイ",
            "お世辞"
        ],
        "skins": [
            {
                "unified": "1f633",
                "native": "😳"
            }
        ],
        "version": 1
    },
    "pleading_face": {
        "id": "pleading_face",
        "name": "Pleading Face",
        "keywords": [
            "begging",
            "mercy",
            "物乞い",
            "慈悲"
        ],
        "skins": [
            {
                "unified": "1f97a",
                "native": "🥺"
            }
        ],
        "version": 11
    },
    "face_holding_back_tears": {
        "id": "face_holding_back_tears",
        "name": "Face Holding Back Tears",
        "keywords": [
            "touched",
            "gratitude",
            "触れた",
            "感謝"
        ],
        "skins": [
            {
                "unified": "1f979",
                "native": "🥹"
            }
        ],
        "version": 14
    },
    "frowning": {
        "id": "frowning",
        "name": "Frowning Face with Open Mouth",
        "keywords": [
            "aw",
            "what",
            "ああ",
            "何"
        ],
        "skins": [
            {
                "unified": "1f626",
                "native": "😦"
            }
        ],
        "version": 1
    },
    "anguished": {
        "id": "anguished",
        "name": "Anguished Face",
        "emoticons": [
            "D:"
        ],
        "keywords": [
            "stunned",
            "nervous",
            "唖然",
            "緊張"
        ],
        "skins": [
            {
                "unified": "1f627",
                "native": "😧"
            }
        ],
        "version": 1
    },
    "fearful": {
        "id": "fearful",
        "name": "Fearful Face",
        "keywords": [
            "scared",
            "terrified",
            "nervous",
            "oops",
            "huh",
            "怖がった",
            "おびえた",
            "緊張",
            "おっと",
            "は",
            "ショック",
            "ガーン",
        ],
        "skins": [
            {
                "unified": "1f628",
                "native": "😨"
            }
        ],
        "version": 1
    },
    "cold_sweat": {
        "id": "cold_sweat",
        "name": "Anxious Face with Sweat",
        "keywords": [
            "cold",
            "nervous",
            "寒い",
            "緊張"
        ],
        "skins": [
            {
                "unified": "1f630",
                "native": "😰"
            }
        ],
        "version": 1
    },
    "disappointed_relieved": {
        "id": "disappointed_relieved",
        "name": "Sad but Relieved Face",
        "keywords": [
            "disappointed",
            "phew",
            "sweat",
            "nervous",
            "残念だった",
            "ふう",
            "汗",
            "緊張"
        ],
        "skins": [
            {
                "unified": "1f625",
                "native": "😥"
            }
        ],
        "version": 1
    },
    "cry": {
        "id": "cry",
        "name": "Crying Face",
        "emoticons": [
            ":'("
        ],
        "keywords": [
            "cry",
            "tears",
            "sad",
            "depressed",
            "upset",
            ":'(",
            "泣く",
            "涙",
            "悲しい",
            "うつ病",
            "動揺",
            ":'("
        ],
        "skins": [
            {
                "unified": "1f622",
                "native": "😢"
            }
        ],
        "version": 1
    },
    "sob": {
        "id": "sob",
        "name": "Loudly Crying Face",
        "emoticons": [
            ":'("
        ],
        "keywords": [
            "sob",
            "cry",
            "tears",
            "sad",
            "upset",
            "depressed",
            "すすり泣き",
            "泣く",
            "涙",
            "悲しい",
            "動揺",
            "うつ病"
        ],
        "skins": [
            {
                "unified": "1f62d",
                "native": "😭"
            }
        ],
        "version": 1
    },
    "scream": {
        "id": "scream",
        "name": "Face Screaming in Fear",
        "keywords": [
            "scream",
            "munch",
            "scared",
            "omg",
            "悲鳴",
            "ムンク",
            "怖がった",
            "ああ,神様"
        ],
        "skins": [
            {
                "unified": "1f631",
                "native": "😱"
            }
        ],
        "version": 1
    },
    "confounded": {
        "id": "confounded",
        "name": "Confounded Face",
        "keywords": [
            "confused",
            "sick",
            "unwell",
            "oops",
            ":S",
            "混乱している",
            "病気",
            "気分が悪い",
            "おっと",
            ":S"
        ],
        "skins": [
            {
                "unified": "1f616",
                "native": "😖"
            }
        ],
        "version": 1
    },
    "persevere": {
        "id": "persevere",
        "name": "Persevering Face",
        "keywords": [
            "persevere",
            "sick",
            "no",
            "upset",
            "oops",
            "がんばる",
            "病気",
            "いいえ",
            "動揺",
            "おっと"
        ],
        "skins": [
            {
                "unified": "1f623",
                "native": "😣"
            }
        ],
        "version": 1
    },
    "disappointed": {
        "id": "disappointed",
        "name": "Disappointed Face",
        "emoticons": [
            "):",
            ":(",
            ":-("
        ],
        "keywords": [
            "sad",
            "upset",
            "depressed",
            ":(",
            "悲しい",
            "動揺",
            "うつ病",
            ":("
        ],
        "skins": [
            {
                "unified": "1f61e",
                "native": "😞"
            }
        ],
        "version": 1
    },
    "sweat": {
        "id": "sweat",
        "name": "Face with Cold Sweat",
        "keywords": [
            "downcast",
            "hot",
            "sad",
            "tired",
            "exercise",
            "落ち込んで",
            "熱い",
            "悲しい",
            "疲れた",
            "エクササイズ"
        ],
        "skins": [
            {
                "unified": "1f613",
                "native": "😓"
            }
        ],
        "version": 1
    },
    "weary": {
        "id": "weary",
        "name": "Weary Face",
        "keywords": [
            "tired",
            "sleepy",
            "sad",
            "frustrated",
            "upset",
            "疲れた",
            "眠る",
            "悲しい",
            "欲求不満",
            "動揺"
        ],
        "skins": [
            {
                "unified": "1f629",
                "native": "😩"
            }
        ],
        "version": 1
    },
    "tired_face": {
        "id": "tired_face",
        "name": "Tired Face",
        "keywords": [
            "sick",
            "whine",
            "upset",
            "frustrated",
            "病気",
            "泣き声",
            "動揺",
            "欲求不満"
        ],
        "skins": [
            {
                "unified": "1f62b",
                "native": "😫"
            }
        ],
        "version": 1
    },
    "yawning_face": {
        "id": "yawning_face",
        "name": "Yawning Face",
        "keywords": [
            "tired",
            "sleepy",
            "疲れた",
            "眠る"
        ],
        "skins": [
            {
                "unified": "1f971",
                "native": "🥱"
            }
        ],
        "version": 12
    },
    "triumph": {
        "id": "triumph",
        "name": "Face with Look of Triumph",
        "keywords": [
            "steam",
            "from",
            "nose",
            "gas",
            "phew",
            "proud",
            "pride",
            "蒸気",
            "から",
            "鼻",
            "ガス",
            "ふう",
            "誇り",
            "誇り"
        ],
        "skins": [
            {
                "unified": "1f624",
                "native": "😤"
            }
        ],
        "version": 1
    },
    "rage": {
        "id": "rage",
        "name": "Pouting Face",
        "keywords": [
            "rage",
            "angry",
            "mad",
            "hate",
            "despise",
            "怒り",
            "怒り",
            "狂った",
            "嫌い",
            "軽蔑する"
        ],
        "skins": [
            {
                "unified": "1f621",
                "native": "😡"
            }
        ],
        "version": 1
    },
    "angry": {
        "id": "angry",
        "name": "Angry Face",
        "emoticons": [
            ">:(",
            ">:-("
        ],
        "keywords": [
            "mad",
            "annoyed",
            "frustrated",
            "狂った",
            "イライラ",
            "欲求不満"
        ],
        "skins": [
            {
                "unified": "1f620",
                "native": "😠"
            }
        ],
        "version": 1
    },
    "face_with_symbols_on_mouth": {
        "id": "face_with_symbols_on_mouth",
        "name": "Face with Symbols on Mouth",
        "keywords": [
            "serious",
            "covering",
            "swearing",
            "cursing",
            "cussing",
            "profanity",
            "expletive",
            "深刻",
            "カバー",
            "宣誓",
            "のろい",
            "ののしり",
            "冒涜",
            "罵倒"
        ],
        "skins": [
            {
                "unified": "1f92c",
                "native": "🤬"
            }
        ],
        "version": 5
    },
    "smiling_imp": {
        "id": "smiling_imp",
        "name": "Smiling Face with Horns",
        "keywords": [
            "imp",
            "devil",
            "インプ",
            "悪魔"
        ],
        "skins": [
            {
                "unified": "1f608",
                "native": "😈"
            }
        ],
        "version": 1
    },
    "imp": {
        "id": "imp",
        "name": "Imp",
        "keywords": [
            "angry",
            "face",
            "with",
            "horns",
            "devil",
            "怒り",
            "顔",
            "と",
            "角",
            "悪魔"
        ],
        "skins": [
            {
                "unified": "1f47f",
                "native": "👿"
            }
        ],
        "version": 1
    },
    "skull": {
        "id": "skull",
        "name": "Skull",
        "keywords": [
            "dead",
            "skeleton",
            "creepy",
            "death",
            "死",
            "スケルトン",
            "気味の悪い",
            "死"
        ],
        "skins": [
            {
                "unified": "1f480",
                "native": "💀"
            }
        ],
        "version": 1
    },
    "skull_and_crossbones": {
        "id": "skull_and_crossbones",
        "name": "Skull and Crossbones",
        "keywords": [
            "poison",
            "danger",
            "deadly",
            "scary",
            "death",
            "pirate",
            "evil",
            "毒",
            "危険",
            "致命的",
            "怖い",
            "死",
            "海賊",
            "悪"
        ],
        "skins": [
            {
                "unified": "2620-fe0f",
                "native": "☠️"
            }
        ],
        "version": 1
    },
    "hankey": {
        "id": "hankey",
        "name": "Pile of Poo",
        "keywords": [
            "hankey",
            "poop",
            "shit",
            "shitface",
            "fail",
            "turd",
            "ハンキー",
            "うんこ",
            "たわごと",
            "たわごと",
            "失敗",
            "糞"
        ],
        "skins": [
            {
                "unified": "1f4a9",
                "native": "💩"
            }
        ],
        "version": 1
    },
    "clown_face": {
        "id": "clown_face",
        "name": "Clown Face",
        "keywords": [],
        "skins": [
            {
                "unified": "1f921",
                "native": "🤡"
            }
        ],
        "version": 3
    },
    "japanese_ogre": {
        "id": "japanese_ogre",
        "name": "Ogre",
        "keywords": [
            "japanese",
            "monster",
            "red",
            "mask",
            "halloween",
            "scary",
            "creepy",
            "devil",
            "demon",
            "日本",
            "モンスター",
            "赤",
            "マスク",
            "ハロウィン",
            "怖い",
            "気味の悪い",
            "悪魔",
            "悪魔"
        ],
        "skins": [
            {
                "unified": "1f479",
                "native": "👹"
            }
        ],
        "version": 1
    },
    "japanese_goblin": {
        "id": "japanese_goblin",
        "name": "Goblin",
        "keywords": [
            "japanese",
            "red",
            "evil",
            "mask",
            "monster",
            "scary",
            "creepy",
            "日本",
            "赤",
            "悪",
            "マスク",
            "モンスター",
            "怖い",
            "気味の悪い"
        ],
        "skins": [
            {
                "unified": "1f47a",
                "native": "👺"
            }
        ],
        "version": 1
    },
    "ghost": {
        "id": "ghost",
        "name": "Ghost",
        "keywords": [
            "halloween",
            "spooky",
            "scary",
            "ハロウィン",
            "不気味な",
            "怖い"
        ],
        "skins": [
            {
                "unified": "1f47b",
                "native": "👻"
            }
        ],
        "version": 1
    },
    "alien": {
        "id": "alien",
        "name": "Alien",
        "keywords": [
            "UFO",
            "paul",
            "weird",
            "outer",
            "space",
            "UFO",
            "ポール",
            "変",
            "外側",
            "空"
        ],
        "skins": [
            {
                "unified": "1f47d",
                "native": "👽"
            }
        ],
        "version": 1
    },
    "space_invader": {
        "id": "space_invader",
        "name": "Alien Monster",
        "keywords": [
            "space",
            "invader",
            "game",
            "arcade",
            "play",
            "空",
            "インベーダー",
            "ゲーム",
            "アーケード",
            "遊ぶ"
        ],
        "skins": [
            {
                "unified": "1f47e",
                "native": "👾"
            }
        ],
        "version": 1
    },
    "robot_face": {
        "id": "robot_face",
        "name": "Robot",
        "keywords": [
            "face",
            "computer",
            "machine",
            "bot",
            "顔",
            "コンピューター",
            "マシーン",
            "ボット"
        ],
        "skins": [
            {
                "unified": "1f916",
                "native": "🤖"
            }
        ],
        "version": 1
    },
    "smiley_cat": {
        "id": "smiley_cat",
        "name": "Grinning Cat",
        "keywords": [
            "smiley",
            "animal",
            "cats",
            "happy",
            "smile",
            "スマイリー",
            "動物",
            "猫",
            "ハッピー",
            "笑顔"
        ],
        "skins": [
            {
                "unified": "1f63a",
                "native": "😺"
            }
        ],
        "version": 1
    },
    "smile_cat": {
        "id": "smile_cat",
        "name": "Grinning Cat with Smiling Eyes",
        "keywords": [
            "smile",
            "animal",
            "cats",
            "笑顔",
            "動物",
            "猫"
        ],
        "skins": [
            {
                "unified": "1f638",
                "native": "😸"
            }
        ],
        "version": 1
    },
    "joy_cat": {
        "id": "joy_cat",
        "name": "Cat with Tears of Joy",
        "keywords": [
            "animal",
            "cats",
            "haha",
            "happy",
            "動物",
            "猫",
            "はは",
            "ハッピー"
        ],
        "skins": [
            {
                "unified": "1f639",
                "native": "😹"
            }
        ],
        "version": 1
    },
    "heart_eyes_cat": {
        "id": "heart_eyes_cat",
        "name": "Smiling Cat with Heart-Eyes",
        "keywords": [
            "heart",
            "eyes",
            "animal",
            "love",
            "like",
            "affection",
            "cats",
            "valentines",
            "心臓",
            "目",
            "動物",
            "愛",
            "好き",
            "愛情",
            "猫",
            "バレンタイン"
        ],
        "skins": [
            {
                "unified": "1f63b",
                "native": "😻"
            }
        ],
        "version": 1
    },
    "smirk_cat": {
        "id": "smirk_cat",
        "name": "Cat with Wry Smile",
        "keywords": [
            "smirk",
            "animal",
            "cats",
            "ニヤリ",
            "動物",
            "猫"
        ],
        "skins": [
            {
                "unified": "1f63c",
                "native": "😼"
            }
        ],
        "version": 1
    },
    "kissing_cat": {
        "id": "kissing_cat",
        "name": "Kissing Cat",
        "keywords": [
            "animal",
            "cats",
            "kiss",
            "動物",
            "猫",
            "キス"
        ],
        "skins": [
            {
                "unified": "1f63d",
                "native": "😽"
            }
        ],
        "version": 1
    },
    "scream_cat": {
        "id": "scream_cat",
        "name": "Weary Cat",
        "keywords": [
            "scream",
            "animal",
            "cats",
            "munch",
            "scared",
            "悲鳴",
            "動物",
            "猫",
            "ムンク",
            "怖がった"
        ],
        "skins": [
            {
                "unified": "1f640",
                "native": "🙀"
            }
        ],
        "version": 1
    },
    "crying_cat_face": {
        "id": "crying_cat_face",
        "name": "Crying Cat",
        "keywords": [
            "face",
            "animal",
            "tears",
            "weep",
            "sad",
            "cats",
            "upset",
            "cry",
            "顔",
            "動物",
            "涙",
            "泣く",
            "悲しい",
            "猫",
            "動揺",
            "泣く"
        ],
        "skins": [
            {
                "unified": "1f63f",
                "native": "😿"
            }
        ],
        "version": 1
    },
    "pouting_cat": {
        "id": "pouting_cat",
        "name": "Pouting Cat",
        "keywords": [
            "animal",
            "cats",
            "動物",
            "猫"
        ],
        "skins": [
            {
                "unified": "1f63e",
                "native": "😾"
            }
        ],
        "version": 1
    },
    "see_no_evil": {
        "id": "see_no_evil",
        "name": "See-No-Evil Monkey",
        "keywords": [
            "see",
            "no",
            "evil",
            "animal",
            "nature",
            "haha",
            "見る",
            "いいえ",
            "悪",
            "動物",
            "自然",
            "ハハ"
        ],
        "skins": [
            {
                "unified": "1f648",
                "native": "🙈"
            }
        ],
        "version": 1
    },
    "hear_no_evil": {
        "id": "hear_no_evil",
        "name": "Hear-No-Evil Monkey",
        "keywords": [
            "hear",
            "no",
            "evil",
            "animal",
            "nature",
            "聞く",
            "いいえ",
            "悪",
            "動物",
            "自然"
        ],
        "skins": [
            {
                "unified": "1f649",
                "native": "🙉"
            }
        ],
        "version": 1
    },
    "speak_no_evil": {
        "id": "speak_no_evil",
        "name": "Speak-No-Evil Monkey",
        "keywords": [
            "speak",
            "no",
            "evil",
            "animal",
            "nature",
            "omg",
            "話す",
            "いいえ",
            "悪",
            "動物",
            "自然",
            "ああ,神様"
        ],
        "skins": [
            {
                "unified": "1f64a",
                "native": "🙊"
            }
        ],
        "version": 1
    },
    "kiss": {
        "id": "kiss",
        "name": "Kiss Mark",
        "keywords": [
            "face",
            "lips",
            "love",
            "like",
            "affection",
            "valentines",
            "顔",
            "唇",
            "愛",
            "好き",
            "愛情",
            "バレンタイン"
        ],
        "skins": [
            {
                "unified": "1f48b",
                "native": "💋"
            }
        ],
        "version": 1
    },
    "love_letter": {
        "id": "love_letter",
        "name": "Love Letter",
        "keywords": [
            "email",
            "like",
            "affection",
            "envelope",
            "valentines",
            "Eメール",
            "好き",
            "愛情",
            "封筒",
            "バレンタイン"
        ],
        "skins": [
            {
                "unified": "1f48c",
                "native": "💌"
            }
        ],
        "version": 1
    },
    "cupid": {
        "id": "cupid",
        "name": "Heart with Arrow",
        "keywords": [
            "cupid",
            "love",
            "like",
            "affection",
            "valentines",
            "キューピッド",
            "愛",
            "好き",
            "愛情",
            "バレンタイン"
        ],
        "skins": [
            {
                "unified": "1f498",
                "native": "💘"
            }
        ],
        "version": 1
    },
    "gift_heart": {
        "id": "gift_heart",
        "name": "Heart with Ribbon",
        "keywords": [
            "gift",
            "love",
            "valentines",
            "贈り物",
            "愛",
            "バレンタイン"
        ],
        "skins": [
            {
                "unified": "1f49d",
                "native": "💝"
            }
        ],
        "version": 1
    },
    "sparkling_heart": {
        "id": "sparkling_heart",
        "name": "Sparkling Heart",
        "keywords": [
            "love",
            "like",
            "affection",
            "valentines",
            "愛",
            "好き",
            "愛情",
            "バレンタイン"
        ],
        "skins": [
            {
                "unified": "1f496",
                "native": "💖"
            }
        ],
        "version": 1
    },
    "heartpulse": {
        "id": "heartpulse",
        "name": "Growing Heart",
        "keywords": [
            "heartpulse",
            "like",
            "love",
            "affection",
            "valentines",
            "pink",
            "ハートパルス",
            "好き",
            "愛",
            "愛情",
            "バレンタイン",
            "ピンク"
        ],
        "skins": [
            {
                "unified": "1f497",
                "native": "💗"
            }
        ],
        "version": 1
    },
    "heartbeat": {
        "id": "heartbeat",
        "name": "Beating Heart",
        "keywords": [
            "heartbeat",
            "love",
            "like",
            "affection",
            "valentines",
            "pink",
            "ハートビート",
            "愛",
            "好き",
            "愛情",
            "バレンタイン",
            "ピンク"
        ],
        "skins": [
            {
                "unified": "1f493",
                "native": "💓"
            }
        ],
        "version": 1
    },
    "revolving_hearts": {
        "id": "revolving_hearts",
        "name": "Revolving Hearts",
        "keywords": [
            "love",
            "like",
            "affection",
            "valentines",
            "愛",
            "好き",
            "愛情",
            "バレンタイン"
        ],
        "skins": [
            {
                "unified": "1f49e",
                "native": "💞"
            }
        ],
        "version": 1
    },
    "two_hearts": {
        "id": "two_hearts",
        "name": "Two Hearts",
        "keywords": [
            "love",
            "like",
            "affection",
            "valentines",
            "heart",
            "愛",
            "好き",
            "愛情",
            "バレンタイン",
            "心臓"
        ],
        "skins": [
            {
                "unified": "1f495",
                "native": "💕"
            }
        ],
        "version": 1
    },
    "heart_decoration": {
        "id": "heart_decoration",
        "name": "Heart Decoration",
        "keywords": [
            "purple",
            "square",
            "love",
            "like",
            "紫",
            "四角",
            "愛",
            "好き"
        ],
        "skins": [
            {
                "unified": "1f49f",
                "native": "💟"
            }
        ],
        "version": 1
    },
    "heavy_heart_exclamation_mark_ornament": {
        "id": "heavy_heart_exclamation_mark_ornament",
        "name": "Heart Exclamation",
        "keywords": [
            "heavy",
            "mark",
            "ornament",
            "decoration",
            "love",
            "重い",
            "マーク",
            "オーナメント",
            "装飾",
            "愛"
        ],
        "skins": [
            {
                "unified": "2763-fe0f",
                "native": "❣️"
            }
        ],
        "version": 1
    },
    "broken_heart": {
        "id": "broken_heart",
        "name": "Broken Heart",
        "emoticons": [
            "</3"
        ],
        "keywords": [
            "sad",
            "sorry",
            "break",
            "heartbreak",
            "悲しい",
            "ごめん",
            "壊す",
            "失恋"
        ],
        "skins": [
            {
                "unified": "1f494",
                "native": "💔"
            }
        ],
        "version": 1
    },
    "heart_on_fire": {
        "id": "heart_on_fire",
        "name": "Heart on Fire",
        "keywords": [
            "passionate",
            "enthusiastic",
            "情熱的",
            "熱狂的"
        ],
        "skins": [
            {
                "unified": "2764-fe0f-200d-1f525",
                "native": "❤️‍🔥"
            }
        ],
        "version": 13.1
    },
    "mending_heart": {
        "id": "mending_heart",
        "name": "Mending Heart",
        "keywords": [
            "broken",
            "bandage",
            "wounded",
            "壊れた",
            "包帯",
            "負傷した"
        ],
        "skins": [
            {
                "unified": "2764-fe0f-200d-1fa79",
                "native": "❤️‍🩹"
            }
        ],
        "version": 13.1
    },
    "heart": {
        "id": "heart",
        "name": "Red Heart",
        "emoticons": [
            "<3"
        ],
        "keywords": [
            "love",
            "like",
            "valentines",
            "愛",
            "好き",
            "バレンタイン"
        ],
        "skins": [
            {
                "unified": "2764-fe0f",
                "native": "❤️"
            }
        ],
        "version": 1
    },
    "orange_heart": {
        "id": "orange_heart",
        "name": "Orange Heart",
        "keywords": [
            "love",
            "like",
            "affection",
            "valentines",
            "愛",
            "好き",
            "愛情",
            "バレンタイン"
        ],
        "skins": [
            {
                "unified": "1f9e1",
                "native": "🧡"
            }
        ],
        "version": 5
    },
    "yellow_heart": {
        "id": "yellow_heart",
        "name": "Yellow Heart",
        "emoticons": [
            "<3"
        ],
        "keywords": [
            "love",
            "like",
            "affection",
            "valentines",
            "愛",
            "好き",
            "愛情",
            "バレンタイン"
        ],
        "skins": [
            {
                "unified": "1f49b",
                "native": "💛"
            }
        ],
        "version": 1
    },
    "green_heart": {
        "id": "green_heart",
        "name": "Green Heart",
        "emoticons": [
            "<3"
        ],
        "keywords": [
            "love",
            "like",
            "affection",
            "valentines",
            "愛",
            "好き",
            "愛情",
            "バレンタイン"
        ],
        "skins": [
            {
                "unified": "1f49a",
                "native": "💚"
            }
        ],
        "version": 1
    },
    "blue_heart": {
        "id": "blue_heart",
        "name": "Blue Heart",
        "emoticons": [
            "<3"
        ],
        "keywords": [
            "love",
            "like",
            "affection",
            "valentines",
            "愛",
            "好き",
            "愛情",
            "バレンタイン"
        ],
        "skins": [
            {
                "unified": "1f499",
                "native": "💙"
            }
        ],
        "version": 1
    },
    "purple_heart": {
        "id": "purple_heart",
        "name": "Purple Heart",
        "emoticons": [
            "<3"
        ],
        "keywords": [
            "love",
            "like",
            "affection",
            "valentines",
            "愛",
            "好き",
            "愛情",
            "バレンタイン"
        ],
        "skins": [
            {
                "unified": "1f49c",
                "native": "💜"
            }
        ],
        "version": 1
    },
    "brown_heart": {
        "id": "brown_heart",
        "name": "Brown Heart",
        "keywords": [
            "coffee",
            "コーヒー"
        ],
        "skins": [
            {
                "unified": "1f90e",
                "native": "🤎"
            }
        ],
        "version": 12
    },
    "black_heart": {
        "id": "black_heart",
        "name": "Black Heart",
        "keywords": [
            "evil",
            "悪"
        ],
        "skins": [
            {
                "unified": "1f5a4",
                "native": "🖤"
            }
        ],
        "version": 3
    },
    "white_heart": {
        "id": "white_heart",
        "name": "White Heart",
        "keywords": [
            "pure",
            "ピュア"
        ],
        "skins": [
            {
                "unified": "1f90d",
                "native": "🤍"
            }
        ],
        "version": 12
    },
    "anger": {
        "id": "anger",
        "name": "Anger Symbol",
        "keywords": [
            "angry",
            "mad",
            "怒り",
            "狂った"
        ],
        "skins": [
            {
                "unified": "1f4a2",
                "native": "💢"
            }
        ],
        "version": 1
    },
    "boom": {
        "id": "boom",
        "name": "Collision",
        "keywords": [
            "boom",
            "bomb",
            "explode",
            "explosion",
            "blown",
            "ブーム",
            "爆弾",
            "爆発",
            "爆発",
            "吹いた"
        ],
        "skins": [
            {
                "unified": "1f4a5",
                "native": "💥"
            }
        ],
        "version": 1
    },
    "dizzy": {
        "id": "dizzy",
        "name": "Dizzy",
        "keywords": [
            "star",
            "sparkle",
            "shoot",
            "magic",
            "星",
            "輝く",
            "シュート",
            "魔法"
        ],
        "skins": [
            {
                "unified": "1f4ab",
                "native": "💫"
            }
        ],
        "version": 1
    },
    "sweat_drops": {
        "id": "sweat_drops",
        "name": "Sweat Droplets",
        "keywords": [
            "drops",
            "water",
            "drip",
            "oops",
            "ドロップ",
            "水",
            "滴下",
            "おっと"
        ],
        "skins": [
            {
                "unified": "1f4a6",
                "native": "💦"
            }
        ],
        "version": 1
    },
    "dash": {
        "id": "dash",
        "name": "Dash Symbol",
        "keywords": [
            "dashing",
            "away",
            "wind",
            "air",
            "fast",
            "shoo",
            "fart",
            "smoke",
            "puff",
            "威勢のいい",
            "あちらへ",
            "風",
            "空気",
            "速い",
            "シュー",
            "おなら",
            "煙",
            "パフ"
        ],
        "skins": [
            {
                "unified": "1f4a8",
                "native": "💨"
            }
        ],
        "version": 1
    },
    "hole": {
        "id": "hole",
        "name": "Hole",
        "keywords": [
            "embarrassing",
            "恥ずかしい"
        ],
        "skins": [
            {
                "unified": "1f573-fe0f",
                "native": "🕳️"
            }
        ],
        "version": 1
    },
    "bomb": {
        "id": "bomb",
        "name": "Bomb",
        "keywords": [
            "boom",
            "explode",
            "explosion",
            "terrorism",
            "ブーム",
            "爆発",
            "爆発",
            "テロ"
        ],
        "skins": [
            {
                "unified": "1f4a3",
                "native": "💣"
            }
        ],
        "version": 1
    },
    "speech_balloon": {
        "id": "speech_balloon",
        "name": "Speech Balloon",
        "keywords": [
            "bubble",
            "words",
            "message",
            "talk",
            "chatting",
            "バブル",
            "言葉",
            "メッセージ",
            "話",
            "おしゃべり"
        ],
        "skins": [
            {
                "unified": "1f4ac",
                "native": "💬"
            }
        ],
        "version": 1
    },
    "eye-in-speech-bubble": {
        "id": "eye-in-speech-bubble",
        "name": "Eye in Speech Bubble",
        "keywords": [
            "in-speech-bubble",
            "info",
            "吹き出し",
            "情報"
        ],
        "skins": [
            {
                "unified": "1f441-fe0f-200d-1f5e8-fe0f",
                "native": "👁️‍🗨️"
            }
        ],
        "version": 2
    },
    "left_speech_bubble": {
        "id": "left_speech_bubble",
        "name": "Left Speech Bubble",
        "keywords": [
            "words",
            "message",
            "talk",
            "chatting",
            "言葉",
            "メッセージ",
            "話",
            "おしゃべり"
        ],
        "skins": [
            {
                "unified": "1f5e8-fe0f",
                "native": "🗨️"
            }
        ],
        "version": 2
    },
    "right_anger_bubble": {
        "id": "right_anger_bubble",
        "name": "Right Anger Bubble",
        "keywords": [
            "caption",
            "speech",
            "thinking",
            "mad",
            "キャプション",
            "スピーチ",
            "考え",
            "狂った"
        ],
        "skins": [
            {
                "unified": "1f5ef-fe0f",
                "native": "🗯️"
            }
        ],
        "version": 1
    },
    "thought_balloon": {
        "id": "thought_balloon",
        "name": "Thought Balloon",
        "keywords": [
            "bubble",
            "cloud",
            "speech",
            "thinking",
            "dream",
            "バブル",
            "雲",
            "スピーチ",
            "考え",
            "夢"
        ],
        "skins": [
            {
                "unified": "1f4ad",
                "native": "💭"
            }
        ],
        "version": 1
    },
    "zzz": {
        "id": "zzz",
        "name": "Zzz",
        "keywords": [
            "sleepy",
            "tired",
            "dream",
            "眠る",
            "疲れた",
            "夢"
        ],
        "skins": [
            {
                "unified": "1f4a4",
                "native": "💤"
            }
        ],
        "version": 1
    },
    "wave": {
        "id": "wave",
        "name": "Waving Hand",
        "keywords": [
            "wave",
            "hands",
            "gesture",
            "goodbye",
            "solong",
            "farewell",
            "hello",
            "hi",
            "palm",
            "波",
            "手",
            "ジェスチャー",
            "さようなら",
            "さよなら",
            "さようなら",
            "こんにちは",
            "やあ",
            "やし"
        ],
        "skins": [
            {
                "unified": "1f44b",
                "native": "👋"
            },
            {
                "unified": "1f44b-1f3fb",
                "native": "👋🏻"
            },
            {
                "unified": "1f44b-1f3fc",
                "native": "👋🏼"
            },
            {
                "unified": "1f44b-1f3fd",
                "native": "👋🏽"
            },
            {
                "unified": "1f44b-1f3fe",
                "native": "👋🏾"
            },
            {
                "unified": "1f44b-1f3ff",
                "native": "👋🏿"
            }
        ],
        "version": 1
    },
    "raised_back_of_hand": {
        "id": "raised_back_of_hand",
        "name": "Raised Back of Hand",
        "keywords": [
            "fingers",
            "backhand",
            "指",
            "バックハンド"
        ],
        "skins": [
            {
                "unified": "1f91a",
                "native": "🤚"
            },
            {
                "unified": "1f91a-1f3fb",
                "native": "🤚🏻"
            },
            {
                "unified": "1f91a-1f3fc",
                "native": "🤚🏼"
            },
            {
                "unified": "1f91a-1f3fd",
                "native": "🤚🏽"
            },
            {
                "unified": "1f91a-1f3fe",
                "native": "🤚🏾"
            },
            {
                "unified": "1f91a-1f3ff",
                "native": "🤚🏿"
            }
        ],
        "version": 3
    },
    "raised_hand_with_fingers_splayed": {
        "id": "raised_hand_with_fingers_splayed",
        "name": "Hand with Fingers Splayed",
        "keywords": [
            "raised",
            "palm",
            "上げた",
            "やし"
        ],
        "skins": [
            {
                "unified": "1f590-fe0f",
                "native": "🖐️"
            },
            {
                "unified": "1f590-1f3fb",
                "native": "🖐🏻"
            },
            {
                "unified": "1f590-1f3fc",
                "native": "🖐🏼"
            },
            {
                "unified": "1f590-1f3fd",
                "native": "🖐🏽"
            },
            {
                "unified": "1f590-1f3fe",
                "native": "🖐🏾"
            },
            {
                "unified": "1f590-1f3ff",
                "native": "🖐🏿"
            }
        ],
        "version": 1
    },
    "hand": {
        "id": "hand",
        "name": "Raised Hand",
        "keywords": [
            "fingers",
            "stop",
            "highfive",
            "high",
            "five",
            "palm",
            "ban",
            "指",
            "ストップ",
            "ハイタッチ",
            "高い",
            "五",
            "やし",
            "禁止"
        ],
        "skins": [
            {
                "unified": "270b",
                "native": "✋"
            },
            {
                "unified": "270b-1f3fb",
                "native": "✋🏻"
            },
            {
                "unified": "270b-1f3fc",
                "native": "✋🏼"
            },
            {
                "unified": "270b-1f3fd",
                "native": "✋🏽"
            },
            {
                "unified": "270b-1f3fe",
                "native": "✋🏾"
            },
            {
                "unified": "270b-1f3ff",
                "native": "✋🏿"
            }
        ],
        "version": 1
    },
    "spock-hand": {
        "id": "spock-hand",
        "name": "Vulcan Salute",
        "keywords": [
            "spock",
            "hand",
            "fingers",
            "star",
            "trek",
            "スポック",
            "手",
            "指",
            "星",
            "トレッキング"
        ],
        "skins": [
            {
                "unified": "1f596",
                "native": "🖖"
            },
            {
                "unified": "1f596-1f3fb",
                "native": "🖖🏻"
            },
            {
                "unified": "1f596-1f3fc",
                "native": "🖖🏼"
            },
            {
                "unified": "1f596-1f3fd",
                "native": "🖖🏽"
            },
            {
                "unified": "1f596-1f3fe",
                "native": "🖖🏾"
            },
            {
                "unified": "1f596-1f3ff",
                "native": "🖖🏿"
            }
        ],
        "version": 1
    },
    "rightwards_hand": {
        "id": "rightwards_hand",
        "name": "Rightwards Hand",
        "keywords": [
            "palm",
            "offer",
            "やし",
            "オファー"
        ],
        "skins": [
            {
                "unified": "1faf1",
                "native": "🫱"
            },
            {
                "unified": "1faf1-1f3fb",
                "native": "🫱🏻"
            },
            {
                "unified": "1faf1-1f3fc",
                "native": "🫱🏼"
            },
            {
                "unified": "1faf1-1f3fd",
                "native": "🫱🏽"
            },
            {
                "unified": "1faf1-1f3fe",
                "native": "🫱🏾"
            },
            {
                "unified": "1faf1-1f3ff",
                "native": "🫱🏿"
            }
        ],
        "version": 14
    },
    "leftwards_hand": {
        "id": "leftwards_hand",
        "name": "Leftwards Hand",
        "keywords": [
            "palm",
            "offer",
            "やし",
            "オファー"
        ],
        "skins": [
            {
                "unified": "1faf2",
                "native": "🫲"
            },
            {
                "unified": "1faf2-1f3fb",
                "native": "🫲🏻"
            },
            {
                "unified": "1faf2-1f3fc",
                "native": "🫲🏼"
            },
            {
                "unified": "1faf2-1f3fd",
                "native": "🫲🏽"
            },
            {
                "unified": "1faf2-1f3fe",
                "native": "🫲🏾"
            },
            {
                "unified": "1faf2-1f3ff",
                "native": "🫲🏿"
            }
        ],
        "version": 14
    },
    "palm_down_hand": {
        "id": "palm_down_hand",
        "name": "Palm Down Hand",
        "keywords": [
            "drop",
            "落とす"
        ],
        "skins": [
            {
                "unified": "1faf3",
                "native": "🫳"
            },
            {
                "unified": "1faf3-1f3fb",
                "native": "🫳🏻"
            },
            {
                "unified": "1faf3-1f3fc",
                "native": "🫳🏼"
            },
            {
                "unified": "1faf3-1f3fd",
                "native": "🫳🏽"
            },
            {
                "unified": "1faf3-1f3fe",
                "native": "🫳🏾"
            },
            {
                "unified": "1faf3-1f3ff",
                "native": "🫳🏿"
            }
        ],
        "version": 14
    },
    "palm_up_hand": {
        "id": "palm_up_hand",
        "name": "Palm Up Hand",
        "keywords": [
            "lift",
            "offer",
            "demand",
            "リフト",
            "オファー",
            "要求"
        ],
        "skins": [
            {
                "unified": "1faf4",
                "native": "🫴"
            },
            {
                "unified": "1faf4-1f3fb",
                "native": "🫴🏻"
            },
            {
                "unified": "1faf4-1f3fc",
                "native": "🫴🏼"
            },
            {
                "unified": "1faf4-1f3fd",
                "native": "🫴🏽"
            },
            {
                "unified": "1faf4-1f3fe",
                "native": "🫴🏾"
            },
            {
                "unified": "1faf4-1f3ff",
                "native": "🫴🏿"
            }
        ],
        "version": 14
    },
    "ok_hand": {
        "id": "ok_hand",
        "name": "Ok Hand",
        "keywords": [
            "fingers",
            "limbs",
            "perfect",
            "okay",
            "指",
            "四肢",
            "完全",
            "わかった"
        ],
        "skins": [
            {
                "unified": "1f44c",
                "native": "👌"
            },
            {
                "unified": "1f44c-1f3fb",
                "native": "👌🏻"
            },
            {
                "unified": "1f44c-1f3fc",
                "native": "👌🏼"
            },
            {
                "unified": "1f44c-1f3fd",
                "native": "👌🏽"
            },
            {
                "unified": "1f44c-1f3fe",
                "native": "👌🏾"
            },
            {
                "unified": "1f44c-1f3ff",
                "native": "👌🏿"
            }
        ],
        "version": 1
    },
    "pinched_fingers": {
        "id": "pinched_fingers",
        "name": "Pinched Fingers",
        "keywords": [
            "size",
            "tiny",
            "small",
            "サイズ",
            "小さい",
            "小さい"
        ],
        "skins": [
            {
                "unified": "1f90c",
                "native": "🤌"
            },
            {
                "unified": "1f90c-1f3fb",
                "native": "🤌🏻"
            },
            {
                "unified": "1f90c-1f3fc",
                "native": "🤌🏼"
            },
            {
                "unified": "1f90c-1f3fd",
                "native": "🤌🏽"
            },
            {
                "unified": "1f90c-1f3fe",
                "native": "🤌🏾"
            },
            {
                "unified": "1f90c-1f3ff",
                "native": "🤌🏿"
            }
        ],
        "version": 13
    },
    "pinching_hand": {
        "id": "pinching_hand",
        "name": "Pinching Hand",
        "keywords": [
            "tiny",
            "small",
            "size",
            "小さい",
            "小さい",
            "サイズ"
        ],
        "skins": [
            {
                "unified": "1f90f",
                "native": "🤏"
            },
            {
                "unified": "1f90f-1f3fb",
                "native": "🤏🏻"
            },
            {
                "unified": "1f90f-1f3fc",
                "native": "🤏🏼"
            },
            {
                "unified": "1f90f-1f3fd",
                "native": "🤏🏽"
            },
            {
                "unified": "1f90f-1f3fe",
                "native": "🤏🏾"
            },
            {
                "unified": "1f90f-1f3ff",
                "native": "🤏🏿"
            }
        ],
        "version": 12
    },
    "v": {
        "id": "v",
        "name": "Victory Hand",
        "keywords": [
            "v",
            "fingers",
            "ohyeah",
            "peace",
            "two",
            "v",
            "指",
            "そうそう",
            "平和",
            "二"
        ],
        "skins": [
            {
                "unified": "270c-fe0f",
                "native": "✌️"
            },
            {
                "unified": "270c-1f3fb",
                "native": "✌🏻"
            },
            {
                "unified": "270c-1f3fc",
                "native": "✌🏼"
            },
            {
                "unified": "270c-1f3fd",
                "native": "✌🏽"
            },
            {
                "unified": "270c-1f3fe",
                "native": "✌🏾"
            },
            {
                "unified": "270c-1f3ff",
                "native": "✌🏿"
            }
        ],
        "version": 1
    },
    "crossed_fingers": {
        "id": "crossed_fingers",
        "name": "Crossed Fingers",
        "keywords": [
            "hand",
            "with",
            "index",
            "and",
            "middle",
            "good",
            "lucky",
            "手",
            "と",
            "索引",
            "と",
            "真ん中",
            "良い",
            "ラッキー"
        ],
        "skins": [
            {
                "unified": "1f91e",
                "native": "🤞"
            },
            {
                "unified": "1f91e-1f3fb",
                "native": "🤞🏻"
            },
            {
                "unified": "1f91e-1f3fc",
                "native": "🤞🏼"
            },
            {
                "unified": "1f91e-1f3fd",
                "native": "🤞🏽"
            },
            {
                "unified": "1f91e-1f3fe",
                "native": "🤞🏾"
            },
            {
                "unified": "1f91e-1f3ff",
                "native": "🤞🏿"
            }
        ],
        "version": 3
    },
    "hand_with_index_finger_and_thumb_crossed": {
        "id": "hand_with_index_finger_and_thumb_crossed",
        "name": "Hand with Index Finger and Thumb Crossed",
        "keywords": [
            "heart",
            "love",
            "money",
            "expensive",
            "心臓",
            "愛",
            "お金",
            "高い"
        ],
        "skins": [
            {
                "unified": "1faf0",
                "native": "🫰"
            },
            {
                "unified": "1faf0-1f3fb",
                "native": "🫰🏻"
            },
            {
                "unified": "1faf0-1f3fc",
                "native": "🫰🏼"
            },
            {
                "unified": "1faf0-1f3fd",
                "native": "🫰🏽"
            },
            {
                "unified": "1faf0-1f3fe",
                "native": "🫰🏾"
            },
            {
                "unified": "1faf0-1f3ff",
                "native": "🫰🏿"
            }
        ],
        "version": 14
    },
    "i_love_you_hand_sign": {
        "id": "i_love_you_hand_sign",
        "name": "Love-You Gesture",
        "keywords": [
            "i",
            "love",
            "you",
            "hand",
            "sign",
            "fingers",
            "私",
            "愛",
            "あなた",
            "手",
            "サイン",
            "指"
        ],
        "skins": [
            {
                "unified": "1f91f",
                "native": "🤟"
            },
            {
                "unified": "1f91f-1f3fb",
                "native": "🤟🏻"
            },
            {
                "unified": "1f91f-1f3fc",
                "native": "🤟🏼"
            },
            {
                "unified": "1f91f-1f3fd",
                "native": "🤟🏽"
            },
            {
                "unified": "1f91f-1f3fe",
                "native": "🤟🏾"
            },
            {
                "unified": "1f91f-1f3ff",
                "native": "🤟🏿"
            }
        ],
        "version": 5
    },
    "the_horns": {
        "id": "the_horns",
        "name": "Sign of the Horns",
        "keywords": [
            "hand",
            "fingers",
            "evil",
            "eye",
            "rock",
            "on",
            "手",
            "指",
            "悪",
            "目",
            "石",
            "の上"
        ],
        "skins": [
            {
                "unified": "1f918",
                "native": "🤘"
            },
            {
                "unified": "1f918-1f3fb",
                "native": "🤘🏻"
            },
            {
                "unified": "1f918-1f3fc",
                "native": "🤘🏼"
            },
            {
                "unified": "1f918-1f3fd",
                "native": "🤘🏽"
            },
            {
                "unified": "1f918-1f3fe",
                "native": "🤘🏾"
            },
            {
                "unified": "1f918-1f3ff",
                "native": "🤘🏿"
            }
        ],
        "version": 1
    },
    "call_me_hand": {
        "id": "call_me_hand",
        "name": "Call Me Hand",
        "keywords": [
            "hands",
            "gesture",
            "shaka",
            "手",
            "ジェスチャー",
            "シャカ"
        ],
        "skins": [
            {
                "unified": "1f919",
                "native": "🤙"
            },
            {
                "unified": "1f919-1f3fb",
                "native": "🤙🏻"
            },
            {
                "unified": "1f919-1f3fc",
                "native": "🤙🏼"
            },
            {
                "unified": "1f919-1f3fd",
                "native": "🤙🏽"
            },
            {
                "unified": "1f919-1f3fe",
                "native": "🤙🏾"
            },
            {
                "unified": "1f919-1f3ff",
                "native": "🤙🏿"
            }
        ],
        "version": 3
    },
    "point_left": {
        "id": "point_left",
        "name": "Backhand Index Pointing Left",
        "keywords": [
            "point",
            "direction",
            "fingers",
            "hand",
            "点",
            "方向",
            "指",
            "手"
        ],
        "skins": [
            {
                "unified": "1f448",
                "native": "👈"
            },
            {
                "unified": "1f448-1f3fb",
                "native": "👈🏻"
            },
            {
                "unified": "1f448-1f3fc",
                "native": "👈🏼"
            },
            {
                "unified": "1f448-1f3fd",
                "native": "👈🏽"
            },
            {
                "unified": "1f448-1f3fe",
                "native": "👈🏾"
            },
            {
                "unified": "1f448-1f3ff",
                "native": "👈🏿"
            }
        ],
        "version": 1
    },
    "point_right": {
        "id": "point_right",
        "name": "Backhand Index Pointing Right",
        "keywords": [
            "point",
            "fingers",
            "hand",
            "direction",
            "点",
            "指",
            "手",
            "方向"
        ],
        "skins": [
            {
                "unified": "1f449",
                "native": "👉"
            },
            {
                "unified": "1f449-1f3fb",
                "native": "👉🏻"
            },
            {
                "unified": "1f449-1f3fc",
                "native": "👉🏼"
            },
            {
                "unified": "1f449-1f3fd",
                "native": "👉🏽"
            },
            {
                "unified": "1f449-1f3fe",
                "native": "👉🏾"
            },
            {
                "unified": "1f449-1f3ff",
                "native": "👉🏿"
            }
        ],
        "version": 1
    },
    "point_up_2": {
        "id": "point_up_2",
        "name": "Backhand Index Pointing Up",
        "keywords": [
            "point",
            "2",
            "fingers",
            "hand",
            "direction",
            "点",
            "2",
            "指",
            "手",
            "方向"
        ],
        "skins": [
            {
                "unified": "1f446",
                "native": "👆"
            },
            {
                "unified": "1f446-1f3fb",
                "native": "👆🏻"
            },
            {
                "unified": "1f446-1f3fc",
                "native": "👆🏼"
            },
            {
                "unified": "1f446-1f3fd",
                "native": "👆🏽"
            },
            {
                "unified": "1f446-1f3fe",
                "native": "👆🏾"
            },
            {
                "unified": "1f446-1f3ff",
                "native": "👆🏿"
            }
        ],
        "version": 1
    },
    "middle_finger": {
        "id": "middle_finger",
        "name": "Middle Finger",
        "keywords": [
            "reversed",
            "hand",
            "with",
            "extended",
            "fingers",
            "rude",
            "flipping",
            "反転",
            "手",
            "と",
            "拡張",
            "指",
            "失礼",
            "フリッピング"
        ],
        "skins": [
            {
                "unified": "1f595",
                "native": "🖕"
            },
            {
                "unified": "1f595-1f3fb",
                "native": "🖕🏻"
            },
            {
                "unified": "1f595-1f3fc",
                "native": "🖕🏼"
            },
            {
                "unified": "1f595-1f3fd",
                "native": "🖕🏽"
            },
            {
                "unified": "1f595-1f3fe",
                "native": "🖕🏾"
            },
            {
                "unified": "1f595-1f3ff",
                "native": "🖕🏿"
            }
        ],
        "version": 1
    },
    "point_down": {
        "id": "point_down",
        "name": "Backhand Index Pointing Down",
        "keywords": [
            "point",
            "fingers",
            "hand",
            "direction",
            "点",
            "指",
            "手",
            "方向"
        ],
        "skins": [
            {
                "unified": "1f447",
                "native": "👇"
            },
            {
                "unified": "1f447-1f3fb",
                "native": "👇🏻"
            },
            {
                "unified": "1f447-1f3fc",
                "native": "👇🏼"
            },
            {
                "unified": "1f447-1f3fd",
                "native": "👇🏽"
            },
            {
                "unified": "1f447-1f3fe",
                "native": "👇🏾"
            },
            {
                "unified": "1f447-1f3ff",
                "native": "👇🏿"
            }
        ],
        "version": 1
    },
    "point_up": {
        "id": "point_up",
        "name": "Index Pointing Up",
        "keywords": [
            "point",
            "hand",
            "fingers",
            "direction",
            "点",
            "手",
            "指",
            "方向"
        ],
        "skins": [
            {
                "unified": "261d-fe0f",
                "native": "☝️"
            },
            {
                "unified": "261d-1f3fb",
                "native": "☝🏻"
            },
            {
                "unified": "261d-1f3fc",
                "native": "☝🏼"
            },
            {
                "unified": "261d-1f3fd",
                "native": "☝🏽"
            },
            {
                "unified": "261d-1f3fe",
                "native": "☝🏾"
            },
            {
                "unified": "261d-1f3ff",
                "native": "☝🏿"
            }
        ],
        "version": 1
    },
    "index_pointing_at_the_viewer": {
        "id": "index_pointing_at_the_viewer",
        "name": "Index Pointing at the Viewer",
        "keywords": [
            "you",
            "recruit",
            "あなた",
            "リクルート"
        ],
        "skins": [
            {
                "unified": "1faf5",
                "native": "🫵"
            },
            {
                "unified": "1faf5-1f3fb",
                "native": "🫵🏻"
            },
            {
                "unified": "1faf5-1f3fc",
                "native": "🫵🏼"
            },
            {
                "unified": "1faf5-1f3fd",
                "native": "🫵🏽"
            },
            {
                "unified": "1faf5-1f3fe",
                "native": "🫵🏾"
            },
            {
                "unified": "1faf5-1f3ff",
                "native": "🫵🏿"
            }
        ],
        "version": 14
    },
    "+1": {
        "id": "+1",
        "name": "Thumbs Up",
        "keywords": [
            "+1",
            "thumbsup",
            "yes",
            "awesome",
            "good",
            "agree",
            "accept",
            "cool",
            "hand",
            "like",
            "+1",
            "いいぞ",
            "はい",
            "素晴らしい",
            "良い",
            "同意",
            "受け入れる",
            "いいね",
            "手",
            "好き"
        ],
        "skins": [
            {
                "unified": "1f44d",
                "native": "👍"
            },
            {
                "unified": "1f44d-1f3fb",
                "native": "👍🏻"
            },
            {
                "unified": "1f44d-1f3fc",
                "native": "👍🏼"
            },
            {
                "unified": "1f44d-1f3fd",
                "native": "👍🏽"
            },
            {
                "unified": "1f44d-1f3fe",
                "native": "👍🏾"
            },
            {
                "unified": "1f44d-1f3ff",
                "native": "👍🏿"
            }
        ],
        "version": 1
    },
    "-1": {
        "id": "-1",
        "name": "Thumbs Down",
        "keywords": [
            "-1",
            "thumbsdown",
            "no",
            "dislike",
            "hand",
            "-1",
            "拒絶",
            "いいえ",
            "嫌い",
            "手"
        ],
        "skins": [
            {
                "unified": "1f44e",
                "native": "👎"
            },
            {
                "unified": "1f44e-1f3fb",
                "native": "👎🏻"
            },
            {
                "unified": "1f44e-1f3fc",
                "native": "👎🏼"
            },
            {
                "unified": "1f44e-1f3fd",
                "native": "👎🏽"
            },
            {
                "unified": "1f44e-1f3fe",
                "native": "👎🏾"
            },
            {
                "unified": "1f44e-1f3ff",
                "native": "👎🏿"
            }
        ],
        "version": 1
    },
    "fist": {
        "id": "fist",
        "name": "Raised Fist",
        "keywords": [
            "fingers",
            "hand",
            "grasp",
            "指",
            "手",
            "把握"
        ],
        "skins": [
            {
                "unified": "270a",
                "native": "✊"
            },
            {
                "unified": "270a-1f3fb",
                "native": "✊🏻"
            },
            {
                "unified": "270a-1f3fc",
                "native": "✊🏼"
            },
            {
                "unified": "270a-1f3fd",
                "native": "✊🏽"
            },
            {
                "unified": "270a-1f3fe",
                "native": "✊🏾"
            },
            {
                "unified": "270a-1f3ff",
                "native": "✊🏿"
            }
        ],
        "version": 1
    },
    "facepunch": {
        "id": "facepunch",
        "name": "Oncoming Fist",
        "keywords": [
            "facepunch",
            "punch",
            "angry",
            "violence",
            "hit",
            "attack",
            "hand",
            "フェイスパンチ",
            "パンチ",
            "怒り",
            "暴力",
            "打つ",
            "攻撃",
            "手"
        ],
        "skins": [
            {
                "unified": "1f44a",
                "native": "👊"
            },
            {
                "unified": "1f44a-1f3fb",
                "native": "👊🏻"
            },
            {
                "unified": "1f44a-1f3fc",
                "native": "👊🏼"
            },
            {
                "unified": "1f44a-1f3fd",
                "native": "👊🏽"
            },
            {
                "unified": "1f44a-1f3fe",
                "native": "👊🏾"
            },
            {
                "unified": "1f44a-1f3ff",
                "native": "👊🏿"
            }
        ],
        "version": 1
    },
    "left-facing_fist": {
        "id": "left-facing_fist",
        "name": "Left-Facing Fist",
        "keywords": [
            "left",
            "facing",
            "hand",
            "fistbump",
            "左",
            "直面している",
            "手",
            "こぶし"
        ],
        "skins": [
            {
                "unified": "1f91b",
                "native": "🤛"
            },
            {
                "unified": "1f91b-1f3fb",
                "native": "🤛🏻"
            },
            {
                "unified": "1f91b-1f3fc",
                "native": "🤛🏼"
            },
            {
                "unified": "1f91b-1f3fd",
                "native": "🤛🏽"
            },
            {
                "unified": "1f91b-1f3fe",
                "native": "🤛🏾"
            },
            {
                "unified": "1f91b-1f3ff",
                "native": "🤛🏿"
            }
        ],
        "version": 3
    },
    "right-facing_fist": {
        "id": "right-facing_fist",
        "name": "Right-Facing Fist",
        "keywords": [
            "right",
            "facing",
            "hand",
            "fistbump",
            "右",
            "直面している",
            "手",
            "こぶし"
        ],
        "skins": [
            {
                "unified": "1f91c",
                "native": "🤜"
            },
            {
                "unified": "1f91c-1f3fb",
                "native": "🤜🏻"
            },
            {
                "unified": "1f91c-1f3fc",
                "native": "🤜🏼"
            },
            {
                "unified": "1f91c-1f3fd",
                "native": "🤜🏽"
            },
            {
                "unified": "1f91c-1f3fe",
                "native": "🤜🏾"
            },
            {
                "unified": "1f91c-1f3ff",
                "native": "🤜🏿"
            }
        ],
        "version": 3
    },
    "clap": {
        "id": "clap",
        "name": "Clapping Hands",
        "keywords": [
            "clap",
            "praise",
            "applause",
            "congrats",
            "yay",
            "拍手",
            "賞賛",
            "拍手",
            "おめでとう",
            "わーい"
        ],
        "skins": [
            {
                "unified": "1f44f",
                "native": "👏"
            },
            {
                "unified": "1f44f-1f3fb",
                "native": "👏🏻"
            },
            {
                "unified": "1f44f-1f3fc",
                "native": "👏🏼"
            },
            {
                "unified": "1f44f-1f3fd",
                "native": "👏🏽"
            },
            {
                "unified": "1f44f-1f3fe",
                "native": "👏🏾"
            },
            {
                "unified": "1f44f-1f3ff",
                "native": "👏🏿"
            }
        ],
        "version": 1
    },
    "raised_hands": {
        "id": "raised_hands",
        "name": "Raising Hands",
        "keywords": [
            "raised",
            "gesture",
            "hooray",
            "yea",
            "celebration",
            "上げた",
            "ジェスチャー",
            "万歳",
            "はい",
            "お祝い"
        ],
        "skins": [
            {
                "unified": "1f64c",
                "native": "🙌"
            },
            {
                "unified": "1f64c-1f3fb",
                "native": "🙌🏻"
            },
            {
                "unified": "1f64c-1f3fc",
                "native": "🙌🏼"
            },
            {
                "unified": "1f64c-1f3fd",
                "native": "🙌🏽"
            },
            {
                "unified": "1f64c-1f3fe",
                "native": "🙌🏾"
            },
            {
                "unified": "1f64c-1f3ff",
                "native": "🙌🏿"
            }
        ],
        "version": 1
    },
    "heart_hands": {
        "id": "heart_hands",
        "name": "Heart Hands",
        "keywords": [
            "love",
            "appreciation",
            "support",
            "愛",
            "感謝",
            "サポート"
        ],
        "skins": [
            {
                "unified": "1faf6",
                "native": "🫶"
            },
            {
                "unified": "1faf6-1f3fb",
                "native": "🫶🏻"
            },
            {
                "unified": "1faf6-1f3fc",
                "native": "🫶🏼"
            },
            {
                "unified": "1faf6-1f3fd",
                "native": "🫶🏽"
            },
            {
                "unified": "1faf6-1f3fe",
                "native": "🫶🏾"
            },
            {
                "unified": "1faf6-1f3ff",
                "native": "🫶🏿"
            }
        ],
        "version": 14
    },
    "open_hands": {
        "id": "open_hands",
        "name": "Open Hands",
        "keywords": [
            "fingers",
            "butterfly",
            "指",
            "蝶"
        ],
        "skins": [
            {
                "unified": "1f450",
                "native": "👐"
            },
            {
                "unified": "1f450-1f3fb",
                "native": "👐🏻"
            },
            {
                "unified": "1f450-1f3fc",
                "native": "👐🏼"
            },
            {
                "unified": "1f450-1f3fd",
                "native": "👐🏽"
            },
            {
                "unified": "1f450-1f3fe",
                "native": "👐🏾"
            },
            {
                "unified": "1f450-1f3ff",
                "native": "👐🏿"
            }
        ],
        "version": 1
    },
    "palms_up_together": {
        "id": "palms_up_together",
        "name": "Palms Up Together",
        "keywords": [
            "hands",
            "gesture",
            "cupped",
            "prayer",
            "手",
            "ジェスチャー",
            "カップ",
            "祈り"
        ],
        "skins": [
            {
                "unified": "1f932",
                "native": "🤲"
            },
            {
                "unified": "1f932-1f3fb",
                "native": "🤲🏻"
            },
            {
                "unified": "1f932-1f3fc",
                "native": "🤲🏼"
            },
            {
                "unified": "1f932-1f3fd",
                "native": "🤲🏽"
            },
            {
                "unified": "1f932-1f3fe",
                "native": "🤲🏾"
            },
            {
                "unified": "1f932-1f3ff",
                "native": "🤲🏿"
            }
        ],
        "version": 5
    },
    "handshake": {
        "id": "handshake",
        "name": "Handshake",
        "keywords": [
            "agreement",
            "shake",
            "合意",
            "振る"
        ],
        "skins": [
            {
                "unified": "1f91d",
                "native": "🤝"
            },
            {
                "unified": "1f91d-1f3fb",
                "native": "🤝🏻"
            },
            {
                "unified": "1f91d-1f3fc",
                "native": "🤝🏼"
            },
            {
                "unified": "1f91d-1f3fd",
                "native": "🤝🏽"
            },
            {
                "unified": "1f91d-1f3fe",
                "native": "🤝🏾"
            },
            {
                "unified": "1f91d-1f3ff",
                "native": "🤝🏿"
            }
        ],
        "version": 3
    },
    "pray": {
        "id": "pray",
        "name": "Folded Hands",
        "keywords": [
            "pray",
            "please",
            "hope",
            "wish",
            "namaste",
            "highfive",
            "high",
            "five",
            "祈る",
            "お願いします",
            "望み",
            "願い",
            "ナマステ",
            "ハイタッチ",
            "高い",
            "五"
        ],
        "skins": [
            {
                "unified": "1f64f",
                "native": "🙏"
            },
            {
                "unified": "1f64f-1f3fb",
                "native": "🙏🏻"
            },
            {
                "unified": "1f64f-1f3fc",
                "native": "🙏🏼"
            },
            {
                "unified": "1f64f-1f3fd",
                "native": "🙏🏽"
            },
            {
                "unified": "1f64f-1f3fe",
                "native": "🙏🏾"
            },
            {
                "unified": "1f64f-1f3ff",
                "native": "🙏🏿"
            }
        ],
        "version": 1
    },
    "writing_hand": {
        "id": "writing_hand",
        "name": "Writing Hand",
        "keywords": [
            "lower",
            "left",
            "ballpoint",
            "pen",
            "stationery",
            "write",
            "compose",
            "低い",
            "左",
            "ボールペン",
            "ペン",
            "文房具",
            "書く",
            "作曲"
        ],
        "skins": [
            {
                "unified": "270d-fe0f",
                "native": "✍️"
            },
            {
                "unified": "270d-1f3fb",
                "native": "✍🏻"
            },
            {
                "unified": "270d-1f3fc",
                "native": "✍🏼"
            },
            {
                "unified": "270d-1f3fd",
                "native": "✍🏽"
            },
            {
                "unified": "270d-1f3fe",
                "native": "✍🏾"
            },
            {
                "unified": "270d-1f3ff",
                "native": "✍🏿"
            }
        ],
        "version": 1
    },
    "nail_care": {
        "id": "nail_care",
        "name": "Nail Polish",
        "keywords": [
            "care",
            "beauty",
            "manicure",
            "finger",
            "fashion",
            "お手入れ",
            "美しさ",
            "マニキュア",
            "指",
            "ファッション"
        ],
        "skins": [
            {
                "unified": "1f485",
                "native": "💅"
            },
            {
                "unified": "1f485-1f3fb",
                "native": "💅🏻"
            },
            {
                "unified": "1f485-1f3fc",
                "native": "💅🏼"
            },
            {
                "unified": "1f485-1f3fd",
                "native": "💅🏽"
            },
            {
                "unified": "1f485-1f3fe",
                "native": "💅🏾"
            },
            {
                "unified": "1f485-1f3ff",
                "native": "💅🏿"
            }
        ],
        "version": 1
    },
    "selfie": {
        "id": "selfie",
        "name": "Selfie",
        "keywords": [
            "camera",
            "phone",
            "カメラ",
            "電話"
        ],
        "skins": [
            {
                "unified": "1f933",
                "native": "🤳"
            },
            {
                "unified": "1f933-1f3fb",
                "native": "🤳🏻"
            },
            {
                "unified": "1f933-1f3fc",
                "native": "🤳🏼"
            },
            {
                "unified": "1f933-1f3fd",
                "native": "🤳🏽"
            },
            {
                "unified": "1f933-1f3fe",
                "native": "🤳🏾"
            },
            {
                "unified": "1f933-1f3ff",
                "native": "🤳🏿"
            }
        ],
        "version": 3
    },
    "muscle": {
        "id": "muscle",
        "name": "Flexed Biceps",
        "keywords": [
            "muscle",
            "arm",
            "flex",
            "hand",
            "summer",
            "strong",
            "筋",
            "腕",
            "フレックス",
            "手",
            "夏",
            "強い"
        ],
        "skins": [
            {
                "unified": "1f4aa",
                "native": "💪"
            },
            {
                "unified": "1f4aa-1f3fb",
                "native": "💪🏻"
            },
            {
                "unified": "1f4aa-1f3fc",
                "native": "💪🏼"
            },
            {
                "unified": "1f4aa-1f3fd",
                "native": "💪🏽"
            },
            {
                "unified": "1f4aa-1f3fe",
                "native": "💪🏾"
            },
            {
                "unified": "1f4aa-1f3ff",
                "native": "💪🏿"
            }
        ],
        "version": 1
    },
    "mechanical_arm": {
        "id": "mechanical_arm",
        "name": "Mechanical Arm",
        "keywords": [
            "accessibility",
            "アクセシビリティ"
        ],
        "skins": [
            {
                "unified": "1f9be",
                "native": "🦾"
            }
        ],
        "version": 12
    },
    "mechanical_leg": {
        "id": "mechanical_leg",
        "name": "Mechanical Leg",
        "keywords": [
            "accessibility",
            "アクセシビリティ"
        ],
        "skins": [
            {
                "unified": "1f9bf",
                "native": "🦿"
            }
        ],
        "version": 12
    },
    "leg": {
        "id": "leg",
        "name": "Leg",
        "keywords": [
            "kick",
            "limb",
            "キック",
            "四肢"
        ],
        "skins": [
            {
                "unified": "1f9b5",
                "native": "🦵"
            },
            {
                "unified": "1f9b5-1f3fb",
                "native": "🦵🏻"
            },
            {
                "unified": "1f9b5-1f3fc",
                "native": "🦵🏼"
            },
            {
                "unified": "1f9b5-1f3fd",
                "native": "🦵🏽"
            },
            {
                "unified": "1f9b5-1f3fe",
                "native": "🦵🏾"
            },
            {
                "unified": "1f9b5-1f3ff",
                "native": "🦵🏿"
            }
        ],
        "version": 11
    },
    "foot": {
        "id": "foot",
        "name": "Foot",
        "keywords": [
            "kick",
            "stomp",
            "キック",
            "踏みつける"
        ],
        "skins": [
            {
                "unified": "1f9b6",
                "native": "🦶"
            },
            {
                "unified": "1f9b6-1f3fb",
                "native": "🦶🏻"
            },
            {
                "unified": "1f9b6-1f3fc",
                "native": "🦶🏼"
            },
            {
                "unified": "1f9b6-1f3fd",
                "native": "🦶🏽"
            },
            {
                "unified": "1f9b6-1f3fe",
                "native": "🦶🏾"
            },
            {
                "unified": "1f9b6-1f3ff",
                "native": "🦶🏿"
            }
        ],
        "version": 11
    },
    "ear": {
        "id": "ear",
        "name": "Ear",
        "keywords": [
            "face",
            "hear",
            "sound",
            "listen",
            "顔",
            "聞く",
            "音",
            "聞く"
        ],
        "skins": [
            {
                "unified": "1f442",
                "native": "👂"
            },
            {
                "unified": "1f442-1f3fb",
                "native": "👂🏻"
            },
            {
                "unified": "1f442-1f3fc",
                "native": "👂🏼"
            },
            {
                "unified": "1f442-1f3fd",
                "native": "👂🏽"
            },
            {
                "unified": "1f442-1f3fe",
                "native": "👂🏾"
            },
            {
                "unified": "1f442-1f3ff",
                "native": "👂🏿"
            }
        ],
        "version": 1
    },
    "ear_with_hearing_aid": {
        "id": "ear_with_hearing_aid",
        "name": "Ear with Hearing Aid",
        "keywords": [
            "accessibility",
            "アクセシビリティ"
        ],
        "skins": [
            {
                "unified": "1f9bb",
                "native": "🦻"
            },
            {
                "unified": "1f9bb-1f3fb",
                "native": "🦻🏻"
            },
            {
                "unified": "1f9bb-1f3fc",
                "native": "🦻🏼"
            },
            {
                "unified": "1f9bb-1f3fd",
                "native": "🦻🏽"
            },
            {
                "unified": "1f9bb-1f3fe",
                "native": "🦻🏾"
            },
            {
                "unified": "1f9bb-1f3ff",
                "native": "🦻🏿"
            }
        ],
        "version": 12
    },
    "nose": {
        "id": "nose",
        "name": "Nose",
        "keywords": [
            "smell",
            "sniff",
            "匂い",
            "嗅ぐ"
        ],
        "skins": [
            {
                "unified": "1f443",
                "native": "👃"
            },
            {
                "unified": "1f443-1f3fb",
                "native": "👃🏻"
            },
            {
                "unified": "1f443-1f3fc",
                "native": "👃🏼"
            },
            {
                "unified": "1f443-1f3fd",
                "native": "👃🏽"
            },
            {
                "unified": "1f443-1f3fe",
                "native": "👃🏾"
            },
            {
                "unified": "1f443-1f3ff",
                "native": "👃🏿"
            }
        ],
        "version": 1
    },
    "brain": {
        "id": "brain",
        "name": "Brain",
        "keywords": [
            "smart",
            "intelligent",
            "頭いい",
            "知的"
        ],
        "skins": [
            {
                "unified": "1f9e0",
                "native": "🧠"
            }
        ],
        "version": 5
    },
    "anatomical_heart": {
        "id": "anatomical_heart",
        "name": "Anatomical Heart",
        "keywords": [
            "health",
            "heartbeat",
            "健康",
            "ハートビート"
        ],
        "skins": [
            {
                "unified": "1fac0",
                "native": "🫀"
            }
        ],
        "version": 13
    },
    "lungs": {
        "id": "lungs",
        "name": "Lungs",
        "keywords": [
            "breathe",
            "息"
        ],
        "skins": [
            {
                "unified": "1fac1",
                "native": "🫁"
            }
        ],
        "version": 13
    },
    "tooth": {
        "id": "tooth",
        "name": "Tooth",
        "keywords": [
            "teeth",
            "dentist",
            "歯",
            "歯医者"
        ],
        "skins": [
            {
                "unified": "1f9b7",
                "native": "🦷"
            }
        ],
        "version": 11
    },
    "bone": {
        "id": "bone",
        "name": "Bone",
        "keywords": [
            "skeleton",
            "スケルトン"
        ],
        "skins": [
            {
                "unified": "1f9b4",
                "native": "🦴"
            }
        ],
        "version": 11
    },
    "eyes": {
        "id": "eyes",
        "name": "Eyes",
        "keywords": [
            "look",
            "watch",
            "stalk",
            "peek",
            "see",
            "見て",
            "時計",
            "茎",
            "ピーク",
            "見る"
        ],
        "skins": [
            {
                "unified": "1f440",
                "native": "👀"
            }
        ],
        "version": 1
    },
    "eye": {
        "id": "eye",
        "name": "Eye",
        "keywords": [
            "face",
            "look",
            "see",
            "watch",
            "stare",
            "顔",
            "見て",
            "見る",
            "時計",
            "見詰める"
        ],
        "skins": [
            {
                "unified": "1f441-fe0f",
                "native": "👁️"
            }
        ],
        "version": 1
    },
    "tongue": {
        "id": "tongue",
        "name": "Tongue",
        "keywords": [
            "mouth",
            "playful",
            "口",
            "遊び心"
        ],
        "skins": [
            {
                "unified": "1f445",
                "native": "👅"
            }
        ],
        "version": 1
    },
    "lips": {
        "id": "lips",
        "name": "Mouth",
        "keywords": [
            "lips",
            "kiss",
            "唇",
            "キス"
        ],
        "skins": [
            {
                "unified": "1f444",
                "native": "👄"
            }
        ],
        "version": 1
    },
    "biting_lip": {
        "id": "biting_lip",
        "name": "Biting Lip",
        "keywords": [
            "flirt",
            "sexy",
            "pain",
            "worry",
            "浮気",
            "セクシー",
            "痛み",
            "心配"
        ],
        "skins": [
            {
                "unified": "1fae6",
                "native": "🫦"
            }
        ],
        "version": 14
    },
    "baby": {
        "id": "baby",
        "name": "Baby",
        "keywords": [
            "child",
            "boy",
            "girl",
            "toddler",
            "子供",
            "男の子",
            "女の子",
            "幼児"
        ],
        "skins": [
            {
                "unified": "1f476",
                "native": "👶"
            },
            {
                "unified": "1f476-1f3fb",
                "native": "👶🏻"
            },
            {
                "unified": "1f476-1f3fc",
                "native": "👶🏼"
            },
            {
                "unified": "1f476-1f3fd",
                "native": "👶🏽"
            },
            {
                "unified": "1f476-1f3fe",
                "native": "👶🏾"
            },
            {
                "unified": "1f476-1f3ff",
                "native": "👶🏿"
            }
        ],
        "version": 1
    },
    "child": {
        "id": "child",
        "name": "Child",
        "keywords": [
            "gender",
            "neutral",
            "young",
            "性別",
            "中性",
            "若い"
        ],
        "skins": [
            {
                "unified": "1f9d2",
                "native": "🧒"
            },
            {
                "unified": "1f9d2-1f3fb",
                "native": "🧒🏻"
            },
            {
                "unified": "1f9d2-1f3fc",
                "native": "🧒🏼"
            },
            {
                "unified": "1f9d2-1f3fd",
                "native": "🧒🏽"
            },
            {
                "unified": "1f9d2-1f3fe",
                "native": "🧒🏾"
            },
            {
                "unified": "1f9d2-1f3ff",
                "native": "🧒🏿"
            }
        ],
        "version": 5
    },
    "boy": {
        "id": "boy",
        "name": "Boy",
        "keywords": [
            "man",
            "male",
            "guy",
            "teenager",
            "男",
            "男",
            "男",
            "十代"
        ],
        "skins": [
            {
                "unified": "1f466",
                "native": "👦"
            },
            {
                "unified": "1f466-1f3fb",
                "native": "👦🏻"
            },
            {
                "unified": "1f466-1f3fc",
                "native": "👦🏼"
            },
            {
                "unified": "1f466-1f3fd",
                "native": "👦🏽"
            },
            {
                "unified": "1f466-1f3fe",
                "native": "👦🏾"
            },
            {
                "unified": "1f466-1f3ff",
                "native": "👦🏿"
            }
        ],
        "version": 1
    },
    "girl": {
        "id": "girl",
        "name": "Girl",
        "keywords": [
            "female",
            "woman",
            "teenager",
            "女性",
            "女性",
            "十代"
        ],
        "skins": [
            {
                "unified": "1f467",
                "native": "👧"
            },
            {
                "unified": "1f467-1f3fb",
                "native": "👧🏻"
            },
            {
                "unified": "1f467-1f3fc",
                "native": "👧🏼"
            },
            {
                "unified": "1f467-1f3fd",
                "native": "👧🏽"
            },
            {
                "unified": "1f467-1f3fe",
                "native": "👧🏾"
            },
            {
                "unified": "1f467-1f3ff",
                "native": "👧🏿"
            }
        ],
        "version": 1
    },
    "adult": {
        "id": "adult",
        "name": "Adult",
        "keywords": [
            "person",
            "gender",
            "neutral",
            "人",
            "性別",
            "中性"
        ],
        "skins": [
            {
                "unified": "1f9d1",
                "native": "🧑"
            },
            {
                "unified": "1f9d1-1f3fb",
                "native": "🧑🏻"
            },
            {
                "unified": "1f9d1-1f3fc",
                "native": "🧑🏼"
            },
            {
                "unified": "1f9d1-1f3fd",
                "native": "🧑🏽"
            },
            {
                "unified": "1f9d1-1f3fe",
                "native": "🧑🏾"
            },
            {
                "unified": "1f9d1-1f3ff",
                "native": "🧑🏿"
            }
        ],
        "version": 5
    },
    "person_with_blond_hair": {
        "id": "person_with_blond_hair",
        "name": "Person Blond Hair",
        "keywords": [
            "with",
            "hairstyle",
            "と",
            "髪型"
        ],
        "skins": [
            {
                "unified": "1f471",
                "native": "👱"
            },
            {
                "unified": "1f471-1f3fb",
                "native": "👱🏻"
            },
            {
                "unified": "1f471-1f3fc",
                "native": "👱🏼"
            },
            {
                "unified": "1f471-1f3fd",
                "native": "👱🏽"
            },
            {
                "unified": "1f471-1f3fe",
                "native": "👱🏾"
            },
            {
                "unified": "1f471-1f3ff",
                "native": "👱🏿"
            }
        ],
        "version": 1
    },
    "man": {
        "id": "man",
        "name": "Man",
        "keywords": [
            "mustache",
            "father",
            "dad",
            "guy",
            "classy",
            "sir",
            "moustache",
            "口ひげ",
            "父親",
            "お父さん",
            "男",
            "上品な",
            "お客様",
            "口ひげ"
        ],
        "skins": [
            {
                "unified": "1f468",
                "native": "👨"
            },
            {
                "unified": "1f468-1f3fb",
                "native": "👨🏻"
            },
            {
                "unified": "1f468-1f3fc",
                "native": "👨🏼"
            },
            {
                "unified": "1f468-1f3fd",
                "native": "👨🏽"
            },
            {
                "unified": "1f468-1f3fe",
                "native": "👨🏾"
            },
            {
                "unified": "1f468-1f3ff",
                "native": "👨🏿"
            }
        ],
        "version": 1
    },
    "bearded_person": {
        "id": "bearded_person",
        "name": "Person Beard",
        "keywords": [
            "bearded",
            "man",
            "bewhiskered",
            "あごひげを生やした",
            "男",
            "ひげを生やした"
        ],
        "skins": [
            {
                "unified": "1f9d4",
                "native": "🧔"
            },
            {
                "unified": "1f9d4-1f3fb",
                "native": "🧔🏻"
            },
            {
                "unified": "1f9d4-1f3fc",
                "native": "🧔🏼"
            },
            {
                "unified": "1f9d4-1f3fd",
                "native": "🧔🏽"
            },
            {
                "unified": "1f9d4-1f3fe",
                "native": "🧔🏾"
            },
            {
                "unified": "1f9d4-1f3ff",
                "native": "🧔🏿"
            }
        ],
        "version": 5
    },
    "man_with_beard": {
        "id": "man_with_beard",
        "name": "Man: Beard",
        "keywords": [
            "man",
            "with",
            "facial",
            "hair",
            "男",
            "と",
            "フェイシャル",
            "髪"
        ],
        "skins": [
            {
                "unified": "1f9d4-200d-2642-fe0f",
                "native": "🧔‍♂️"
            },
            {
                "unified": "1f9d4-1f3fb-200d-2642-fe0f",
                "native": "🧔🏻‍♂️"
            },
            {
                "unified": "1f9d4-1f3fc-200d-2642-fe0f",
                "native": "🧔🏼‍♂️"
            },
            {
                "unified": "1f9d4-1f3fd-200d-2642-fe0f",
                "native": "🧔🏽‍♂️"
            },
            {
                "unified": "1f9d4-1f3fe-200d-2642-fe0f",
                "native": "🧔🏾‍♂️"
            },
            {
                "unified": "1f9d4-1f3ff-200d-2642-fe0f",
                "native": "🧔🏿‍♂️"
            }
        ],
        "version": 13.1
    },
    "woman_with_beard": {
        "id": "woman_with_beard",
        "name": "Woman: Beard",
        "keywords": [
            "woman",
            "with",
            "facial",
            "hair",
            "女性",
            "と",
            "フェイシャル",
            "髪"
        ],
        "skins": [
            {
                "unified": "1f9d4-200d-2640-fe0f",
                "native": "🧔‍♀️"
            },
            {
                "unified": "1f9d4-1f3fb-200d-2640-fe0f",
                "native": "🧔🏻‍♀️"
            },
            {
                "unified": "1f9d4-1f3fc-200d-2640-fe0f",
                "native": "🧔🏼‍♀️"
            },
            {
                "unified": "1f9d4-1f3fd-200d-2640-fe0f",
                "native": "🧔🏽‍♀️"
            },
            {
                "unified": "1f9d4-1f3fe-200d-2640-fe0f",
                "native": "🧔🏾‍♀️"
            },
            {
                "unified": "1f9d4-1f3ff-200d-2640-fe0f",
                "native": "🧔🏿‍♀️"
            }
        ],
        "version": 13.1
    },
    "red_haired_man": {
        "id": "red_haired_man",
        "name": "Man: Red Hair",
        "keywords": [
            "haired",
            "man",
            "hairstyle",
            "髪",
            "男",
            "髪型"
        ],
        "skins": [
            {
                "unified": "1f468-200d-1f9b0",
                "native": "👨‍🦰"
            },
            {
                "unified": "1f468-1f3fb-200d-1f9b0",
                "native": "👨🏻‍🦰"
            },
            {
                "unified": "1f468-1f3fc-200d-1f9b0",
                "native": "👨🏼‍🦰"
            },
            {
                "unified": "1f468-1f3fd-200d-1f9b0",
                "native": "👨🏽‍🦰"
            },
            {
                "unified": "1f468-1f3fe-200d-1f9b0",
                "native": "👨🏾‍🦰"
            },
            {
                "unified": "1f468-1f3ff-200d-1f9b0",
                "native": "👨🏿‍🦰"
            }
        ],
        "version": 11
    },
    "curly_haired_man": {
        "id": "curly_haired_man",
        "name": "Man: Curly Hair",
        "keywords": [
            "haired",
            "man",
            "hairstyle",
            "髪",
            "男",
            "髪型"
        ],
        "skins": [
            {
                "unified": "1f468-200d-1f9b1",
                "native": "👨‍🦱"
            },
            {
                "unified": "1f468-1f3fb-200d-1f9b1",
                "native": "👨🏻‍🦱"
            },
            {
                "unified": "1f468-1f3fc-200d-1f9b1",
                "native": "👨🏼‍🦱"
            },
            {
                "unified": "1f468-1f3fd-200d-1f9b1",
                "native": "👨🏽‍🦱"
            },
            {
                "unified": "1f468-1f3fe-200d-1f9b1",
                "native": "👨🏾‍🦱"
            },
            {
                "unified": "1f468-1f3ff-200d-1f9b1",
                "native": "👨🏿‍🦱"
            }
        ],
        "version": 11
    },
    "white_haired_man": {
        "id": "white_haired_man",
        "name": "Man: White Hair",
        "keywords": [
            "haired",
            "man",
            "old",
            "elder",
            "髪",
            "男",
            "年",
            "長老"
        ],
        "skins": [
            {
                "unified": "1f468-200d-1f9b3",
                "native": "👨‍🦳"
            },
            {
                "unified": "1f468-1f3fb-200d-1f9b3",
                "native": "👨🏻‍🦳"
            },
            {
                "unified": "1f468-1f3fc-200d-1f9b3",
                "native": "👨🏼‍🦳"
            },
            {
                "unified": "1f468-1f3fd-200d-1f9b3",
                "native": "👨🏽‍🦳"
            },
            {
                "unified": "1f468-1f3fe-200d-1f9b3",
                "native": "👨🏾‍🦳"
            },
            {
                "unified": "1f468-1f3ff-200d-1f9b3",
                "native": "👨🏿‍🦳"
            }
        ],
        "version": 11
    },
    "bald_man": {
        "id": "bald_man",
        "name": "Man: Bald",
        "keywords": [
            "man",
            "hairless",
            "男",
            "無毛"
        ],
        "skins": [
            {
                "unified": "1f468-200d-1f9b2",
                "native": "👨‍🦲"
            },
            {
                "unified": "1f468-1f3fb-200d-1f9b2",
                "native": "👨🏻‍🦲"
            },
            {
                "unified": "1f468-1f3fc-200d-1f9b2",
                "native": "👨🏼‍🦲"
            },
            {
                "unified": "1f468-1f3fd-200d-1f9b2",
                "native": "👨🏽‍🦲"
            },
            {
                "unified": "1f468-1f3fe-200d-1f9b2",
                "native": "👨🏾‍🦲"
            },
            {
                "unified": "1f468-1f3ff-200d-1f9b2",
                "native": "👨🏿‍🦲"
            }
        ],
        "version": 11
    },
    "woman": {
        "id": "woman",
        "name": "Woman",
        "keywords": [
            "female",
            "girls",
            "lady",
            "女性",
            "女の子",
            "レディ"
        ],
        "skins": [
            {
                "unified": "1f469",
                "native": "👩"
            },
            {
                "unified": "1f469-1f3fb",
                "native": "👩🏻"
            },
            {
                "unified": "1f469-1f3fc",
                "native": "👩🏼"
            },
            {
                "unified": "1f469-1f3fd",
                "native": "👩🏽"
            },
            {
                "unified": "1f469-1f3fe",
                "native": "👩🏾"
            },
            {
                "unified": "1f469-1f3ff",
                "native": "👩🏿"
            }
        ],
        "version": 1
    },
    "red_haired_woman": {
        "id": "red_haired_woman",
        "name": "Woman: Red Hair",
        "keywords": [
            "haired",
            "woman",
            "hairstyle",
            "髪",
            "女性",
            "髪型"
        ],
        "skins": [
            {
                "unified": "1f469-200d-1f9b0",
                "native": "👩‍🦰"
            },
            {
                "unified": "1f469-1f3fb-200d-1f9b0",
                "native": "👩🏻‍🦰"
            },
            {
                "unified": "1f469-1f3fc-200d-1f9b0",
                "native": "👩🏼‍🦰"
            },
            {
                "unified": "1f469-1f3fd-200d-1f9b0",
                "native": "👩🏽‍🦰"
            },
            {
                "unified": "1f469-1f3fe-200d-1f9b0",
                "native": "👩🏾‍🦰"
            },
            {
                "unified": "1f469-1f3ff-200d-1f9b0",
                "native": "👩🏿‍🦰"
            }
        ],
        "version": 11
    },
    "red_haired_person": {
        "id": "red_haired_person",
        "name": "Person: Red Hair",
        "keywords": [
            "haired",
            "person",
            "hairstyle",
            "髪",
            "人",
            "髪型"
        ],
        "skins": [
            {
                "unified": "1f9d1-200d-1f9b0",
                "native": "🧑‍🦰"
            },
            {
                "unified": "1f9d1-1f3fb-200d-1f9b0",
                "native": "🧑🏻‍🦰"
            },
            {
                "unified": "1f9d1-1f3fc-200d-1f9b0",
                "native": "🧑🏼‍🦰"
            },
            {
                "unified": "1f9d1-1f3fd-200d-1f9b0",
                "native": "🧑🏽‍🦰"
            },
            {
                "unified": "1f9d1-1f3fe-200d-1f9b0",
                "native": "🧑🏾‍🦰"
            },
            {
                "unified": "1f9d1-1f3ff-200d-1f9b0",
                "native": "🧑🏿‍🦰"
            }
        ],
        "version": 12.1
    },
    "curly_haired_woman": {
        "id": "curly_haired_woman",
        "name": "Woman: Curly Hair",
        "keywords": [
            "haired",
            "woman",
            "hairstyle",
            "髪",
            "女性",
            "髪型"
        ],
        "skins": [
            {
                "unified": "1f469-200d-1f9b1",
                "native": "👩‍🦱"
            },
            {
                "unified": "1f469-1f3fb-200d-1f9b1",
                "native": "👩🏻‍🦱"
            },
            {
                "unified": "1f469-1f3fc-200d-1f9b1",
                "native": "👩🏼‍🦱"
            },
            {
                "unified": "1f469-1f3fd-200d-1f9b1",
                "native": "👩🏽‍🦱"
            },
            {
                "unified": "1f469-1f3fe-200d-1f9b1",
                "native": "👩🏾‍🦱"
            },
            {
                "unified": "1f469-1f3ff-200d-1f9b1",
                "native": "👩🏿‍🦱"
            }
        ],
        "version": 11
    },
    "curly_haired_person": {
        "id": "curly_haired_person",
        "name": "Person: Curly Hair",
        "keywords": [
            "haired",
            "person",
            "hairstyle",
            "髪",
            "人",
            "髪型"
        ],
        "skins": [
            {
                "unified": "1f9d1-200d-1f9b1",
                "native": "🧑‍🦱"
            },
            {
                "unified": "1f9d1-1f3fb-200d-1f9b1",
                "native": "🧑🏻‍🦱"
            },
            {
                "unified": "1f9d1-1f3fc-200d-1f9b1",
                "native": "🧑🏼‍🦱"
            },
            {
                "unified": "1f9d1-1f3fd-200d-1f9b1",
                "native": "🧑🏽‍🦱"
            },
            {
                "unified": "1f9d1-1f3fe-200d-1f9b1",
                "native": "🧑🏾‍🦱"
            },
            {
                "unified": "1f9d1-1f3ff-200d-1f9b1",
                "native": "🧑🏿‍🦱"
            }
        ],
        "version": 12.1
    },
    "white_haired_woman": {
        "id": "white_haired_woman",
        "name": "Woman: White Hair",
        "keywords": [
            "haired",
            "woman",
            "old",
            "elder",
            "髪",
            "女性",
            "年",
            "長老"
        ],
        "skins": [
            {
                "unified": "1f469-200d-1f9b3",
                "native": "👩‍🦳"
            },
            {
                "unified": "1f469-1f3fb-200d-1f9b3",
                "native": "👩🏻‍🦳"
            },
            {
                "unified": "1f469-1f3fc-200d-1f9b3",
                "native": "👩🏼‍🦳"
            },
            {
                "unified": "1f469-1f3fd-200d-1f9b3",
                "native": "👩🏽‍🦳"
            },
            {
                "unified": "1f469-1f3fe-200d-1f9b3",
                "native": "👩🏾‍🦳"
            },
            {
                "unified": "1f469-1f3ff-200d-1f9b3",
                "native": "👩🏿‍🦳"
            }
        ],
        "version": 11
    },
    "white_haired_person": {
        "id": "white_haired_person",
        "name": "Person: White Hair",
        "keywords": [
            "haired",
            "person",
            "elder",
            "old",
            "髪",
            "人",
            "長老",
            "年"
        ],
        "skins": [
            {
                "unified": "1f9d1-200d-1f9b3",
                "native": "🧑‍🦳"
            },
            {
                "unified": "1f9d1-1f3fb-200d-1f9b3",
                "native": "🧑🏻‍🦳"
            },
            {
                "unified": "1f9d1-1f3fc-200d-1f9b3",
                "native": "🧑🏼‍🦳"
            },
            {
                "unified": "1f9d1-1f3fd-200d-1f9b3",
                "native": "🧑🏽‍🦳"
            },
            {
                "unified": "1f9d1-1f3fe-200d-1f9b3",
                "native": "🧑🏾‍🦳"
            },
            {
                "unified": "1f9d1-1f3ff-200d-1f9b3",
                "native": "🧑🏿‍🦳"
            }
        ],
        "version": 12.1
    },
    "bald_woman": {
        "id": "bald_woman",
        "name": "Woman: Bald",
        "keywords": [
            "woman",
            "hairless",
            "女性",
            "無毛"
        ],
        "skins": [
            {
                "unified": "1f469-200d-1f9b2",
                "native": "👩‍🦲"
            },
            {
                "unified": "1f469-1f3fb-200d-1f9b2",
                "native": "👩🏻‍🦲"
            },
            {
                "unified": "1f469-1f3fc-200d-1f9b2",
                "native": "👩🏼‍🦲"
            },
            {
                "unified": "1f469-1f3fd-200d-1f9b2",
                "native": "👩🏽‍🦲"
            },
            {
                "unified": "1f469-1f3fe-200d-1f9b2",
                "native": "👩🏾‍🦲"
            },
            {
                "unified": "1f469-1f3ff-200d-1f9b2",
                "native": "👩🏿‍🦲"
            }
        ],
        "version": 11
    },
    "bald_person": {
        "id": "bald_person",
        "name": "Person: Bald",
        "keywords": [
            "person",
            "hairless",
            "人",
            "無毛"
        ],
        "skins": [
            {
                "unified": "1f9d1-200d-1f9b2",
                "native": "🧑‍🦲"
            },
            {
                "unified": "1f9d1-1f3fb-200d-1f9b2",
                "native": "🧑🏻‍🦲"
            },
            {
                "unified": "1f9d1-1f3fc-200d-1f9b2",
                "native": "🧑🏼‍🦲"
            },
            {
                "unified": "1f9d1-1f3fd-200d-1f9b2",
                "native": "🧑🏽‍🦲"
            },
            {
                "unified": "1f9d1-1f3fe-200d-1f9b2",
                "native": "🧑🏾‍🦲"
            },
            {
                "unified": "1f9d1-1f3ff-200d-1f9b2",
                "native": "🧑🏿‍🦲"
            }
        ],
        "version": 12.1
    },
    "blond-haired-woman": {
        "id": "blond-haired-woman",
        "name": "Woman: Blond Hair",
        "keywords": [
            "haired-woman",
            "woman",
            "female",
            "girl",
            "blonde",
            "person",
            "髪の女",
            "女性",
            "女性",
            "女の子",
            "ブロンド",
            "人"
        ],
        "skins": [
            {
                "unified": "1f471-200d-2640-fe0f",
                "native": "👱‍♀️"
            },
            {
                "unified": "1f471-1f3fb-200d-2640-fe0f",
                "native": "👱🏻‍♀️"
            },
            {
                "unified": "1f471-1f3fc-200d-2640-fe0f",
                "native": "👱🏼‍♀️"
            },
            {
                "unified": "1f471-1f3fd-200d-2640-fe0f",
                "native": "👱🏽‍♀️"
            },
            {
                "unified": "1f471-1f3fe-200d-2640-fe0f",
                "native": "👱🏾‍♀️"
            },
            {
                "unified": "1f471-1f3ff-200d-2640-fe0f",
                "native": "👱🏿‍♀️"
            }
        ],
        "version": 4
    },
    "blond-haired-man": {
        "id": "blond-haired-man",
        "name": "Man: Blond Hair",
        "keywords": [
            "haired-man",
            "man",
            "male",
            "boy",
            "blonde",
            "guy",
            "person",
            "髪の男",
            "男",
            "男",
            "男の子",
            "ブロンド",
            "男",
            "人"
        ],
        "skins": [
            {
                "unified": "1f471-200d-2642-fe0f",
                "native": "👱‍♂️"
            },
            {
                "unified": "1f471-1f3fb-200d-2642-fe0f",
                "native": "👱🏻‍♂️"
            },
            {
                "unified": "1f471-1f3fc-200d-2642-fe0f",
                "native": "👱🏼‍♂️"
            },
            {
                "unified": "1f471-1f3fd-200d-2642-fe0f",
                "native": "👱🏽‍♂️"
            },
            {
                "unified": "1f471-1f3fe-200d-2642-fe0f",
                "native": "👱🏾‍♂️"
            },
            {
                "unified": "1f471-1f3ff-200d-2642-fe0f",
                "native": "👱🏿‍♂️"
            }
        ],
        "version": 4
    },
    "older_adult": {
        "id": "older_adult",
        "name": "Older Adult",
        "keywords": [
            "person",
            "human",
            "elder",
            "senior",
            "gender",
            "neutral",
            "人",
            "人間",
            "長老",
            "シニア",
            "性別",
            "中性"
        ],
        "skins": [
            {
                "unified": "1f9d3",
                "native": "🧓"
            },
            {
                "unified": "1f9d3-1f3fb",
                "native": "🧓🏻"
            },
            {
                "unified": "1f9d3-1f3fc",
                "native": "🧓🏼"
            },
            {
                "unified": "1f9d3-1f3fd",
                "native": "🧓🏽"
            },
            {
                "unified": "1f9d3-1f3fe",
                "native": "🧓🏾"
            },
            {
                "unified": "1f9d3-1f3ff",
                "native": "🧓🏿"
            }
        ],
        "version": 5
    },
    "older_man": {
        "id": "older_man",
        "name": "Old Man",
        "keywords": [
            "older",
            "human",
            "male",
            "men",
            "elder",
            "senior",
            "古い",
            "人間",
            "男",
            "男性",
            "長老",
            "シニア"
        ],
        "skins": [
            {
                "unified": "1f474",
                "native": "👴"
            },
            {
                "unified": "1f474-1f3fb",
                "native": "👴🏻"
            },
            {
                "unified": "1f474-1f3fc",
                "native": "👴🏼"
            },
            {
                "unified": "1f474-1f3fd",
                "native": "👴🏽"
            },
            {
                "unified": "1f474-1f3fe",
                "native": "👴🏾"
            },
            {
                "unified": "1f474-1f3ff",
                "native": "👴🏿"
            }
        ],
        "version": 1
    },
    "older_woman": {
        "id": "older_woman",
        "name": "Old Woman",
        "keywords": [
            "older",
            "human",
            "female",
            "women",
            "lady",
            "elder",
            "senior",
            "古い",
            "人間",
            "女性",
            "女性",
            "レディ",
            "長老",
            "シニア"
        ],
        "skins": [
            {
                "unified": "1f475",
                "native": "👵"
            },
            {
                "unified": "1f475-1f3fb",
                "native": "👵🏻"
            },
            {
                "unified": "1f475-1f3fc",
                "native": "👵🏼"
            },
            {
                "unified": "1f475-1f3fd",
                "native": "👵🏽"
            },
            {
                "unified": "1f475-1f3fe",
                "native": "👵🏾"
            },
            {
                "unified": "1f475-1f3ff",
                "native": "👵🏿"
            }
        ],
        "version": 1
    },
    "person_frowning": {
        "id": "person_frowning",
        "name": "Person Frowning",
        "keywords": [
            "worried",
            "心配した"
        ],
        "skins": [
            {
                "unified": "1f64d",
                "native": "🙍"
            },
            {
                "unified": "1f64d-1f3fb",
                "native": "🙍🏻"
            },
            {
                "unified": "1f64d-1f3fc",
                "native": "🙍🏼"
            },
            {
                "unified": "1f64d-1f3fd",
                "native": "🙍🏽"
            },
            {
                "unified": "1f64d-1f3fe",
                "native": "🙍🏾"
            },
            {
                "unified": "1f64d-1f3ff",
                "native": "🙍🏿"
            }
        ],
        "version": 1
    },
    "man-frowning": {
        "id": "man-frowning",
        "name": "Man Frowning",
        "keywords": [
            "male",
            "boy",
            "sad",
            "depressed",
            "discouraged",
            "unhappy",
            "男",
            "男の子",
            "悲しい",
            "うつ病",
            "がっかり",
            "不幸"
        ],
        "skins": [
            {
                "unified": "1f64d-200d-2642-fe0f",
                "native": "🙍‍♂️"
            },
            {
                "unified": "1f64d-1f3fb-200d-2642-fe0f",
                "native": "🙍🏻‍♂️"
            },
            {
                "unified": "1f64d-1f3fc-200d-2642-fe0f",
                "native": "🙍🏼‍♂️"
            },
            {
                "unified": "1f64d-1f3fd-200d-2642-fe0f",
                "native": "🙍🏽‍♂️"
            },
            {
                "unified": "1f64d-1f3fe-200d-2642-fe0f",
                "native": "🙍🏾‍♂️"
            },
            {
                "unified": "1f64d-1f3ff-200d-2642-fe0f",
                "native": "🙍🏿‍♂️"
            }
        ],
        "version": 4
    },
    "woman-frowning": {
        "id": "woman-frowning",
        "name": "Woman Frowning",
        "keywords": [
            "female",
            "girl",
            "sad",
            "depressed",
            "discouraged",
            "unhappy",
            "女性",
            "女の子",
            "悲しい",
            "うつ病",
            "がっかり",
            "不幸"
        ],
        "skins": [
            {
                "unified": "1f64d-200d-2640-fe0f",
                "native": "🙍‍♀️"
            },
            {
                "unified": "1f64d-1f3fb-200d-2640-fe0f",
                "native": "🙍🏻‍♀️"
            },
            {
                "unified": "1f64d-1f3fc-200d-2640-fe0f",
                "native": "🙍🏼‍♀️"
            },
            {
                "unified": "1f64d-1f3fd-200d-2640-fe0f",
                "native": "🙍🏽‍♀️"
            },
            {
                "unified": "1f64d-1f3fe-200d-2640-fe0f",
                "native": "🙍🏾‍♀️"
            },
            {
                "unified": "1f64d-1f3ff-200d-2640-fe0f",
                "native": "🙍🏿‍♀️"
            }
        ],
        "version": 4
    },
    "person_with_pouting_face": {
        "id": "person_with_pouting_face",
        "name": "Person Pouting",
        "keywords": [
            "with",
            "face",
            "upset",
            "と",
            "顔",
            "動揺"
        ],
        "skins": [
            {
                "unified": "1f64e",
                "native": "🙎"
            },
            {
                "unified": "1f64e-1f3fb",
                "native": "🙎🏻"
            },
            {
                "unified": "1f64e-1f3fc",
                "native": "🙎🏼"
            },
            {
                "unified": "1f64e-1f3fd",
                "native": "🙎🏽"
            },
            {
                "unified": "1f64e-1f3fe",
                "native": "🙎🏾"
            },
            {
                "unified": "1f64e-1f3ff",
                "native": "🙎🏿"
            }
        ],
        "version": 1
    },
    "man-pouting": {
        "id": "man-pouting",
        "name": "Man Pouting",
        "keywords": [
            "male",
            "boy",
            "男",
            "男の子"
        ],
        "skins": [
            {
                "unified": "1f64e-200d-2642-fe0f",
                "native": "🙎‍♂️"
            },
            {
                "unified": "1f64e-1f3fb-200d-2642-fe0f",
                "native": "🙎🏻‍♂️"
            },
            {
                "unified": "1f64e-1f3fc-200d-2642-fe0f",
                "native": "🙎🏼‍♂️"
            },
            {
                "unified": "1f64e-1f3fd-200d-2642-fe0f",
                "native": "🙎🏽‍♂️"
            },
            {
                "unified": "1f64e-1f3fe-200d-2642-fe0f",
                "native": "🙎🏾‍♂️"
            },
            {
                "unified": "1f64e-1f3ff-200d-2642-fe0f",
                "native": "🙎🏿‍♂️"
            }
        ],
        "version": 4
    },
    "woman-pouting": {
        "id": "woman-pouting",
        "name": "Woman Pouting",
        "keywords": [
            "female",
            "girl",
            "女性",
            "女の子"
        ],
        "skins": [
            {
                "unified": "1f64e-200d-2640-fe0f",
                "native": "🙎‍♀️"
            },
            {
                "unified": "1f64e-1f3fb-200d-2640-fe0f",
                "native": "🙎🏻‍♀️"
            },
            {
                "unified": "1f64e-1f3fc-200d-2640-fe0f",
                "native": "🙎🏼‍♀️"
            },
            {
                "unified": "1f64e-1f3fd-200d-2640-fe0f",
                "native": "🙎🏽‍♀️"
            },
            {
                "unified": "1f64e-1f3fe-200d-2640-fe0f",
                "native": "🙎🏾‍♀️"
            },
            {
                "unified": "1f64e-1f3ff-200d-2640-fe0f",
                "native": "🙎🏿‍♀️"
            }
        ],
        "version": 4
    },
    "no_good": {
        "id": "no_good",
        "name": "Person Gesturing No",
        "keywords": [
            "good",
            "decline",
            "良い",
            "却下"
        ],
        "skins": [
            {
                "unified": "1f645",
                "native": "🙅"
            },
            {
                "unified": "1f645-1f3fb",
                "native": "🙅🏻"
            },
            {
                "unified": "1f645-1f3fc",
                "native": "🙅🏼"
            },
            {
                "unified": "1f645-1f3fd",
                "native": "🙅🏽"
            },
            {
                "unified": "1f645-1f3fe",
                "native": "🙅🏾"
            },
            {
                "unified": "1f645-1f3ff",
                "native": "🙅🏿"
            }
        ],
        "version": 1
    },
    "man-gesturing-no": {
        "id": "man-gesturing-no",
        "name": "Man Gesturing No",
        "keywords": [
            "gesturing-no",
            "male",
            "boy",
            "nope",
            "身振りのない",
            "男",
            "男の子",
            "いいえ"
        ],
        "skins": [
            {
                "unified": "1f645-200d-2642-fe0f",
                "native": "🙅‍♂️"
            },
            {
                "unified": "1f645-1f3fb-200d-2642-fe0f",
                "native": "🙅🏻‍♂️"
            },
            {
                "unified": "1f645-1f3fc-200d-2642-fe0f",
                "native": "🙅🏼‍♂️"
            },
            {
                "unified": "1f645-1f3fd-200d-2642-fe0f",
                "native": "🙅🏽‍♂️"
            },
            {
                "unified": "1f645-1f3fe-200d-2642-fe0f",
                "native": "🙅🏾‍♂️"
            },
            {
                "unified": "1f645-1f3ff-200d-2642-fe0f",
                "native": "🙅🏿‍♂️"
            }
        ],
        "version": 4
    },
    "woman-gesturing-no": {
        "id": "woman-gesturing-no",
        "name": "Woman Gesturing No",
        "keywords": [
            "gesturing-no",
            "female",
            "girl",
            "nope",
            "身振りのない",
            "女性",
            "女の子",
            "いいえ"
        ],
        "skins": [
            {
                "unified": "1f645-200d-2640-fe0f",
                "native": "🙅‍♀️"
            },
            {
                "unified": "1f645-1f3fb-200d-2640-fe0f",
                "native": "🙅🏻‍♀️"
            },
            {
                "unified": "1f645-1f3fc-200d-2640-fe0f",
                "native": "🙅🏼‍♀️"
            },
            {
                "unified": "1f645-1f3fd-200d-2640-fe0f",
                "native": "🙅🏽‍♀️"
            },
            {
                "unified": "1f645-1f3fe-200d-2640-fe0f",
                "native": "🙅🏾‍♀️"
            },
            {
                "unified": "1f645-1f3ff-200d-2640-fe0f",
                "native": "🙅🏿‍♀️"
            }
        ],
        "version": 4
    },
    "ok_woman": {
        "id": "ok_woman",
        "name": "Person Gesturing Ok",
        "keywords": [
            "woman",
            "agree",
            "女性",
            "同意"
        ],
        "skins": [
            {
                "unified": "1f646",
                "native": "🙆"
            },
            {
                "unified": "1f646-1f3fb",
                "native": "🙆🏻"
            },
            {
                "unified": "1f646-1f3fc",
                "native": "🙆🏼"
            },
            {
                "unified": "1f646-1f3fd",
                "native": "🙆🏽"
            },
            {
                "unified": "1f646-1f3fe",
                "native": "🙆🏾"
            },
            {
                "unified": "1f646-1f3ff",
                "native": "🙆🏿"
            }
        ],
        "version": 1
    },
    "man-gesturing-ok": {
        "id": "man-gesturing-ok",
        "name": "Man Gesturing Ok",
        "keywords": [
            "gesturing-ok",
            "men",
            "boy",
            "male",
            "blue",
            "human",
            "ジェスチャーOK",
            "男性",
            "男の子",
            "男",
            "青",
            "人間"
        ],
        "skins": [
            {
                "unified": "1f646-200d-2642-fe0f",
                "native": "🙆‍♂️"
            },
            {
                "unified": "1f646-1f3fb-200d-2642-fe0f",
                "native": "🙆🏻‍♂️"
            },
            {
                "unified": "1f646-1f3fc-200d-2642-fe0f",
                "native": "🙆🏼‍♂️"
            },
            {
                "unified": "1f646-1f3fd-200d-2642-fe0f",
                "native": "🙆🏽‍♂️"
            },
            {
                "unified": "1f646-1f3fe-200d-2642-fe0f",
                "native": "🙆🏾‍♂️"
            },
            {
                "unified": "1f646-1f3ff-200d-2642-fe0f",
                "native": "🙆🏿‍♂️"
            }
        ],
        "version": 4
    },
    "woman-gesturing-ok": {
        "id": "woman-gesturing-ok",
        "name": "Woman Gesturing Ok",
        "keywords": [
            "gesturing-ok",
            "women",
            "girl",
            "female",
            "pink",
            "human",
            "ジェスチャーOK",
            "女性",
            "女の子",
            "女性",
            "ピンク",
            "人間"
        ],
        "skins": [
            {
                "unified": "1f646-200d-2640-fe0f",
                "native": "🙆‍♀️"
            },
            {
                "unified": "1f646-1f3fb-200d-2640-fe0f",
                "native": "🙆🏻‍♀️"
            },
            {
                "unified": "1f646-1f3fc-200d-2640-fe0f",
                "native": "🙆🏼‍♀️"
            },
            {
                "unified": "1f646-1f3fd-200d-2640-fe0f",
                "native": "🙆🏽‍♀️"
            },
            {
                "unified": "1f646-1f3fe-200d-2640-fe0f",
                "native": "🙆🏾‍♀️"
            },
            {
                "unified": "1f646-1f3ff-200d-2640-fe0f",
                "native": "🙆🏿‍♀️"
            }
        ],
        "version": 4
    },
    "information_desk_person": {
        "id": "information_desk_person",
        "name": "Person Tipping Hand",
        "keywords": [
            "information",
            "desk",
            "情報",
            "机"
        ],
        "skins": [
            {
                "unified": "1f481",
                "native": "💁"
            },
            {
                "unified": "1f481-1f3fb",
                "native": "💁🏻"
            },
            {
                "unified": "1f481-1f3fc",
                "native": "💁🏼"
            },
            {
                "unified": "1f481-1f3fd",
                "native": "💁🏽"
            },
            {
                "unified": "1f481-1f3fe",
                "native": "💁🏾"
            },
            {
                "unified": "1f481-1f3ff",
                "native": "💁🏿"
            }
        ],
        "version": 1
    },
    "man-tipping-hand": {
        "id": "man-tipping-hand",
        "name": "Man Tipping Hand",
        "keywords": [
            "tipping-hand",
            "male",
            "boy",
            "human",
            "information",
            "チップハンド",
            "男",
            "男の子",
            "人間",
            "情報"
        ],
        "skins": [
            {
                "unified": "1f481-200d-2642-fe0f",
                "native": "💁‍♂️"
            },
            {
                "unified": "1f481-1f3fb-200d-2642-fe0f",
                "native": "💁🏻‍♂️"
            },
            {
                "unified": "1f481-1f3fc-200d-2642-fe0f",
                "native": "💁🏼‍♂️"
            },
            {
                "unified": "1f481-1f3fd-200d-2642-fe0f",
                "native": "💁🏽‍♂️"
            },
            {
                "unified": "1f481-1f3fe-200d-2642-fe0f",
                "native": "💁🏾‍♂️"
            },
            {
                "unified": "1f481-1f3ff-200d-2642-fe0f",
                "native": "💁🏿‍♂️"
            }
        ],
        "version": 4
    },
    "woman-tipping-hand": {
        "id": "woman-tipping-hand",
        "name": "Woman Tipping Hand",
        "keywords": [
            "tipping-hand",
            "female",
            "girl",
            "human",
            "information",
            "チップハンド",
            "女性",
            "女の子",
            "人間",
            "情報"
        ],
        "skins": [
            {
                "unified": "1f481-200d-2640-fe0f",
                "native": "💁‍♀️"
            },
            {
                "unified": "1f481-1f3fb-200d-2640-fe0f",
                "native": "💁🏻‍♀️"
            },
            {
                "unified": "1f481-1f3fc-200d-2640-fe0f",
                "native": "💁🏼‍♀️"
            },
            {
                "unified": "1f481-1f3fd-200d-2640-fe0f",
                "native": "💁🏽‍♀️"
            },
            {
                "unified": "1f481-1f3fe-200d-2640-fe0f",
                "native": "💁🏾‍♀️"
            },
            {
                "unified": "1f481-1f3ff-200d-2640-fe0f",
                "native": "💁🏿‍♀️"
            }
        ],
        "version": 4
    },
    "raising_hand": {
        "id": "raising_hand",
        "name": "Person Raising Hand",
        "keywords": [
            "question",
            "質問"
        ],
        "skins": [
            {
                "unified": "1f64b",
                "native": "🙋"
            },
            {
                "unified": "1f64b-1f3fb",
                "native": "🙋🏻"
            },
            {
                "unified": "1f64b-1f3fc",
                "native": "🙋🏼"
            },
            {
                "unified": "1f64b-1f3fd",
                "native": "🙋🏽"
            },
            {
                "unified": "1f64b-1f3fe",
                "native": "🙋🏾"
            },
            {
                "unified": "1f64b-1f3ff",
                "native": "🙋🏿"
            }
        ],
        "version": 1
    },
    "man-raising-hand": {
        "id": "man-raising-hand",
        "name": "Man Raising Hand",
        "keywords": [
            "raising-hand",
            "male",
            "boy",
            "挙手",
            "男",
            "男の子"
        ],
        "skins": [
            {
                "unified": "1f64b-200d-2642-fe0f",
                "native": "🙋‍♂️"
            },
            {
                "unified": "1f64b-1f3fb-200d-2642-fe0f",
                "native": "🙋🏻‍♂️"
            },
            {
                "unified": "1f64b-1f3fc-200d-2642-fe0f",
                "native": "🙋🏼‍♂️"
            },
            {
                "unified": "1f64b-1f3fd-200d-2642-fe0f",
                "native": "🙋🏽‍♂️"
            },
            {
                "unified": "1f64b-1f3fe-200d-2642-fe0f",
                "native": "🙋🏾‍♂️"
            },
            {
                "unified": "1f64b-1f3ff-200d-2642-fe0f",
                "native": "🙋🏿‍♂️"
            }
        ],
        "version": 4
    },
    "woman-raising-hand": {
        "id": "woman-raising-hand",
        "name": "Woman Raising Hand",
        "keywords": [
            "raising-hand",
            "female",
            "girl",
            "挙手",
            "女性",
            "女の子"
        ],
        "skins": [
            {
                "unified": "1f64b-200d-2640-fe0f",
                "native": "🙋‍♀️"
            },
            {
                "unified": "1f64b-1f3fb-200d-2640-fe0f",
                "native": "🙋🏻‍♀️"
            },
            {
                "unified": "1f64b-1f3fc-200d-2640-fe0f",
                "native": "🙋🏼‍♀️"
            },
            {
                "unified": "1f64b-1f3fd-200d-2640-fe0f",
                "native": "🙋🏽‍♀️"
            },
            {
                "unified": "1f64b-1f3fe-200d-2640-fe0f",
                "native": "🙋🏾‍♀️"
            },
            {
                "unified": "1f64b-1f3ff-200d-2640-fe0f",
                "native": "🙋🏿‍♀️"
            }
        ],
        "version": 4
    },
    "deaf_person": {
        "id": "deaf_person",
        "name": "Deaf Person",
        "keywords": [
            "accessibility",
            "アクセシビリティ"
        ],
        "skins": [
            {
                "unified": "1f9cf",
                "native": "🧏"
            },
            {
                "unified": "1f9cf-1f3fb",
                "native": "🧏🏻"
            },
            {
                "unified": "1f9cf-1f3fc",
                "native": "🧏🏼"
            },
            {
                "unified": "1f9cf-1f3fd",
                "native": "🧏🏽"
            },
            {
                "unified": "1f9cf-1f3fe",
                "native": "🧏🏾"
            },
            {
                "unified": "1f9cf-1f3ff",
                "native": "🧏🏿"
            }
        ],
        "version": 12
    },
    "deaf_man": {
        "id": "deaf_man",
        "name": "Deaf Man",
        "keywords": [
            "accessibility",
            "アクセシビリティ"
        ],
        "skins": [
            {
                "unified": "1f9cf-200d-2642-fe0f",
                "native": "🧏‍♂️"
            },
            {
                "unified": "1f9cf-1f3fb-200d-2642-fe0f",
                "native": "🧏🏻‍♂️"
            },
            {
                "unified": "1f9cf-1f3fc-200d-2642-fe0f",
                "native": "🧏🏼‍♂️"
            },
            {
                "unified": "1f9cf-1f3fd-200d-2642-fe0f",
                "native": "🧏🏽‍♂️"
            },
            {
                "unified": "1f9cf-1f3fe-200d-2642-fe0f",
                "native": "🧏🏾‍♂️"
            },
            {
                "unified": "1f9cf-1f3ff-200d-2642-fe0f",
                "native": "🧏🏿‍♂️"
            }
        ],
        "version": 12
    },
    "deaf_woman": {
        "id": "deaf_woman",
        "name": "Deaf Woman",
        "keywords": [
            "accessibility",
            "アクセシビリティ"
        ],
        "skins": [
            {
                "unified": "1f9cf-200d-2640-fe0f",
                "native": "🧏‍♀️"
            },
            {
                "unified": "1f9cf-1f3fb-200d-2640-fe0f",
                "native": "🧏🏻‍♀️"
            },
            {
                "unified": "1f9cf-1f3fc-200d-2640-fe0f",
                "native": "🧏🏼‍♀️"
            },
            {
                "unified": "1f9cf-1f3fd-200d-2640-fe0f",
                "native": "🧏🏽‍♀️"
            },
            {
                "unified": "1f9cf-1f3fe-200d-2640-fe0f",
                "native": "🧏🏾‍♀️"
            },
            {
                "unified": "1f9cf-1f3ff-200d-2640-fe0f",
                "native": "🧏🏿‍♀️"
            }
        ],
        "version": 12
    },
    "bow": {
        "id": "bow",
        "name": "Person Bowing",
        "keywords": [
            "bow",
            "respectiful",
            "敬意を表します",
            "すみません",
            "ごめん",
            "ごめんなさい",
            "土下座",
        ],
        "skins": [
            {
                "unified": "1f647",
                "native": "🙇"
            },
            {
                "unified": "1f647-1f3fb",
                "native": "🙇🏻"
            },
            {
                "unified": "1f647-1f3fc",
                "native": "🙇🏼"
            },
            {
                "unified": "1f647-1f3fd",
                "native": "🙇🏽"
            },
            {
                "unified": "1f647-1f3fe",
                "native": "🙇🏾"
            },
            {
                "unified": "1f647-1f3ff",
                "native": "🙇🏿"
            }
        ],
        "version": 1
    },
    "man-bowing": {
        "id": "man-bowing",
        "name": "Man Bowing",
        "keywords": [
            "male",
            "boy",
            "男",
            "男の子"
        ],
        "skins": [
            {
                "unified": "1f647-200d-2642-fe0f",
                "native": "🙇‍♂️"
            },
            {
                "unified": "1f647-1f3fb-200d-2642-fe0f",
                "native": "🙇🏻‍♂️"
            },
            {
                "unified": "1f647-1f3fc-200d-2642-fe0f",
                "native": "🙇🏼‍♂️"
            },
            {
                "unified": "1f647-1f3fd-200d-2642-fe0f",
                "native": "🙇🏽‍♂️"
            },
            {
                "unified": "1f647-1f3fe-200d-2642-fe0f",
                "native": "🙇🏾‍♂️"
            },
            {
                "unified": "1f647-1f3ff-200d-2642-fe0f",
                "native": "🙇🏿‍♂️"
            }
        ],
        "version": 4
    },
    "woman-bowing": {
        "id": "woman-bowing",
        "name": "Woman Bowing",
        "keywords": [
            "female",
            "girl",
            "女性",
            "女の子"
        ],
        "skins": [
            {
                "unified": "1f647-200d-2640-fe0f",
                "native": "🙇‍♀️"
            },
            {
                "unified": "1f647-1f3fb-200d-2640-fe0f",
                "native": "🙇🏻‍♀️"
            },
            {
                "unified": "1f647-1f3fc-200d-2640-fe0f",
                "native": "🙇🏼‍♀️"
            },
            {
                "unified": "1f647-1f3fd-200d-2640-fe0f",
                "native": "🙇🏽‍♀️"
            },
            {
                "unified": "1f647-1f3fe-200d-2640-fe0f",
                "native": "🙇🏾‍♀️"
            },
            {
                "unified": "1f647-1f3ff-200d-2640-fe0f",
                "native": "🙇🏿‍♀️"
            }
        ],
        "version": 4
    },
    "face_palm": {
        "id": "face_palm",
        "name": "Face Palm",
        "keywords": [
            "person",
            "facepalming",
            "disappointed",
            "人",
            "フェイスパーミング",
            "残念だった"
        ],
        "skins": [
            {
                "unified": "1f926",
                "native": "🤦"
            },
            {
                "unified": "1f926-1f3fb",
                "native": "🤦🏻"
            },
            {
                "unified": "1f926-1f3fc",
                "native": "🤦🏼"
            },
            {
                "unified": "1f926-1f3fd",
                "native": "🤦🏽"
            },
            {
                "unified": "1f926-1f3fe",
                "native": "🤦🏾"
            },
            {
                "unified": "1f926-1f3ff",
                "native": "🤦🏿"
            }
        ],
        "version": 3
    },
    "man-facepalming": {
        "id": "man-facepalming",
        "name": "Man Facepalming",
        "keywords": [
            "male",
            "boy",
            "disbelief",
            "男",
            "男の子",
            "不信"
        ],
        "skins": [
            {
                "unified": "1f926-200d-2642-fe0f",
                "native": "🤦‍♂️"
            },
            {
                "unified": "1f926-1f3fb-200d-2642-fe0f",
                "native": "🤦🏻‍♂️"
            },
            {
                "unified": "1f926-1f3fc-200d-2642-fe0f",
                "native": "🤦🏼‍♂️"
            },
            {
                "unified": "1f926-1f3fd-200d-2642-fe0f",
                "native": "🤦🏽‍♂️"
            },
            {
                "unified": "1f926-1f3fe-200d-2642-fe0f",
                "native": "🤦🏾‍♂️"
            },
            {
                "unified": "1f926-1f3ff-200d-2642-fe0f",
                "native": "🤦🏿‍♂️"
            }
        ],
        "version": 4
    },
    "woman-facepalming": {
        "id": "woman-facepalming",
        "name": "Woman Facepalming",
        "keywords": [
            "female",
            "girl",
            "disbelief",
            "女性",
            "女の子",
            "不信"
        ],
        "skins": [
            {
                "unified": "1f926-200d-2640-fe0f",
                "native": "🤦‍♀️"
            },
            {
                "unified": "1f926-1f3fb-200d-2640-fe0f",
                "native": "🤦🏻‍♀️"
            },
            {
                "unified": "1f926-1f3fc-200d-2640-fe0f",
                "native": "🤦🏼‍♀️"
            },
            {
                "unified": "1f926-1f3fd-200d-2640-fe0f",
                "native": "🤦🏽‍♀️"
            },
            {
                "unified": "1f926-1f3fe-200d-2640-fe0f",
                "native": "🤦🏾‍♀️"
            },
            {
                "unified": "1f926-1f3ff-200d-2640-fe0f",
                "native": "🤦🏿‍♀️"
            }
        ],
        "version": 4
    },
    "shrug": {
        "id": "shrug",
        "name": "Shrug",
        "keywords": [
            "person",
            "shrugging",
            "regardless",
            "人",
            "肩をすくめる",
            "関係なく"
        ],
        "skins": [
            {
                "unified": "1f937",
                "native": "🤷"
            },
            {
                "unified": "1f937-1f3fb",
                "native": "🤷🏻"
            },
            {
                "unified": "1f937-1f3fc",
                "native": "🤷🏼"
            },
            {
                "unified": "1f937-1f3fd",
                "native": "🤷🏽"
            },
            {
                "unified": "1f937-1f3fe",
                "native": "🤷🏾"
            },
            {
                "unified": "1f937-1f3ff",
                "native": "🤷🏿"
            }
        ],
        "version": 3
    },
    "man-shrugging": {
        "id": "man-shrugging",
        "name": "Man Shrugging",
        "keywords": [
            "male",
            "boy",
            "confused",
            "indifferent",
            "doubt",
            "男",
            "男の子",
            "混乱している",
            "無関心",
            "疑い"
        ],
        "skins": [
            {
                "unified": "1f937-200d-2642-fe0f",
                "native": "🤷‍♂️"
            },
            {
                "unified": "1f937-1f3fb-200d-2642-fe0f",
                "native": "🤷🏻‍♂️"
            },
            {
                "unified": "1f937-1f3fc-200d-2642-fe0f",
                "native": "🤷🏼‍♂️"
            },
            {
                "unified": "1f937-1f3fd-200d-2642-fe0f",
                "native": "🤷🏽‍♂️"
            },
            {
                "unified": "1f937-1f3fe-200d-2642-fe0f",
                "native": "🤷🏾‍♂️"
            },
            {
                "unified": "1f937-1f3ff-200d-2642-fe0f",
                "native": "🤷🏿‍♂️"
            }
        ],
        "version": 4
    },
    "woman-shrugging": {
        "id": "woman-shrugging",
        "name": "Woman Shrugging",
        "keywords": [
            "female",
            "girl",
            "confused",
            "indifferent",
            "doubt",
            "女性",
            "女の子",
            "混乱している",
            "無関心",
            "疑い"
        ],
        "skins": [
            {
                "unified": "1f937-200d-2640-fe0f",
                "native": "🤷‍♀️"
            },
            {
                "unified": "1f937-1f3fb-200d-2640-fe0f",
                "native": "🤷🏻‍♀️"
            },
            {
                "unified": "1f937-1f3fc-200d-2640-fe0f",
                "native": "🤷🏼‍♀️"
            },
            {
                "unified": "1f937-1f3fd-200d-2640-fe0f",
                "native": "🤷🏽‍♀️"
            },
            {
                "unified": "1f937-1f3fe-200d-2640-fe0f",
                "native": "🤷🏾‍♀️"
            },
            {
                "unified": "1f937-1f3ff-200d-2640-fe0f",
                "native": "🤷🏿‍♀️"
            }
        ],
        "version": 4
    },
    "health_worker": {
        "id": "health_worker",
        "name": "Health Worker",
        "keywords": [
            "hospital",
            "病院"
        ],
        "skins": [
            {
                "unified": "1f9d1-200d-2695-fe0f",
                "native": "🧑‍⚕️"
            },
            {
                "unified": "1f9d1-1f3fb-200d-2695-fe0f",
                "native": "🧑🏻‍⚕️"
            },
            {
                "unified": "1f9d1-1f3fc-200d-2695-fe0f",
                "native": "🧑🏼‍⚕️"
            },
            {
                "unified": "1f9d1-1f3fd-200d-2695-fe0f",
                "native": "🧑🏽‍⚕️"
            },
            {
                "unified": "1f9d1-1f3fe-200d-2695-fe0f",
                "native": "🧑🏾‍⚕️"
            },
            {
                "unified": "1f9d1-1f3ff-200d-2695-fe0f",
                "native": "🧑🏿‍⚕️"
            }
        ],
        "version": 12.1
    },
    "male-doctor": {
        "id": "male-doctor",
        "name": "Man Health Worker",
        "keywords": [
            "male",
            "doctor",
            "nurse",
            "therapist",
            "healthcare",
            "human",
            "男",
            "医者",
            "看護婦",
            "セラピスト",
            "健康管理",
            "人間"
        ],
        "skins": [
            {
                "unified": "1f468-200d-2695-fe0f",
                "native": "👨‍⚕️"
            },
            {
                "unified": "1f468-1f3fb-200d-2695-fe0f",
                "native": "👨🏻‍⚕️"
            },
            {
                "unified": "1f468-1f3fc-200d-2695-fe0f",
                "native": "👨🏼‍⚕️"
            },
            {
                "unified": "1f468-1f3fd-200d-2695-fe0f",
                "native": "👨🏽‍⚕️"
            },
            {
                "unified": "1f468-1f3fe-200d-2695-fe0f",
                "native": "👨🏾‍⚕️"
            },
            {
                "unified": "1f468-1f3ff-200d-2695-fe0f",
                "native": "👨🏿‍⚕️"
            }
        ],
        "version": 4
    },
    "female-doctor": {
        "id": "female-doctor",
        "name": "Woman Health Worker",
        "keywords": [
            "female",
            "doctor",
            "nurse",
            "therapist",
            "healthcare",
            "human",
            "女性",
            "医者",
            "看護婦",
            "セラピスト",
            "健康管理",
            "人間"
        ],
        "skins": [
            {
                "unified": "1f469-200d-2695-fe0f",
                "native": "👩‍⚕️"
            },
            {
                "unified": "1f469-1f3fb-200d-2695-fe0f",
                "native": "👩🏻‍⚕️"
            },
            {
                "unified": "1f469-1f3fc-200d-2695-fe0f",
                "native": "👩🏼‍⚕️"
            },
            {
                "unified": "1f469-1f3fd-200d-2695-fe0f",
                "native": "👩🏽‍⚕️"
            },
            {
                "unified": "1f469-1f3fe-200d-2695-fe0f",
                "native": "👩🏾‍⚕️"
            },
            {
                "unified": "1f469-1f3ff-200d-2695-fe0f",
                "native": "👩🏿‍⚕️"
            }
        ],
        "version": 4
    },
    "student": {
        "id": "student",
        "name": "Student",
        "keywords": [
            "learn",
            "学び"
        ],
        "skins": [
            {
                "unified": "1f9d1-200d-1f393",
                "native": "🧑‍🎓"
            },
            {
                "unified": "1f9d1-1f3fb-200d-1f393",
                "native": "🧑🏻‍🎓"
            },
            {
                "unified": "1f9d1-1f3fc-200d-1f393",
                "native": "🧑🏼‍🎓"
            },
            {
                "unified": "1f9d1-1f3fd-200d-1f393",
                "native": "🧑🏽‍🎓"
            },
            {
                "unified": "1f9d1-1f3fe-200d-1f393",
                "native": "🧑🏾‍🎓"
            },
            {
                "unified": "1f9d1-1f3ff-200d-1f393",
                "native": "🧑🏿‍🎓"
            }
        ],
        "version": 12.1
    },
    "male-student": {
        "id": "male-student",
        "name": "Man Student",
        "keywords": [
            "male",
            "graduate",
            "human",
            "男",
            "卒業",
            "人間"
        ],
        "skins": [
            {
                "unified": "1f468-200d-1f393",
                "native": "👨‍🎓"
            },
            {
                "unified": "1f468-1f3fb-200d-1f393",
                "native": "👨🏻‍🎓"
            },
            {
                "unified": "1f468-1f3fc-200d-1f393",
                "native": "👨🏼‍🎓"
            },
            {
                "unified": "1f468-1f3fd-200d-1f393",
                "native": "👨🏽‍🎓"
            },
            {
                "unified": "1f468-1f3fe-200d-1f393",
                "native": "👨🏾‍🎓"
            },
            {
                "unified": "1f468-1f3ff-200d-1f393",
                "native": "👨🏿‍🎓"
            }
        ],
        "version": 4
    },
    "female-student": {
        "id": "female-student",
        "name": "Woman Student",
        "keywords": [
            "female",
            "graduate",
            "human",
            "女性",
            "卒業",
            "人間"
        ],
        "skins": [
            {
                "unified": "1f469-200d-1f393",
                "native": "👩‍🎓"
            },
            {
                "unified": "1f469-1f3fb-200d-1f393",
                "native": "👩🏻‍🎓"
            },
            {
                "unified": "1f469-1f3fc-200d-1f393",
                "native": "👩🏼‍🎓"
            },
            {
                "unified": "1f469-1f3fd-200d-1f393",
                "native": "👩🏽‍🎓"
            },
            {
                "unified": "1f469-1f3fe-200d-1f393",
                "native": "👩🏾‍🎓"
            },
            {
                "unified": "1f469-1f3ff-200d-1f393",
                "native": "👩🏿‍🎓"
            }
        ],
        "version": 4
    },
    "teacher": {
        "id": "teacher",
        "name": "Teacher",
        "keywords": [
            "professor",
            "教授"
        ],
        "skins": [
            {
                "unified": "1f9d1-200d-1f3eb",
                "native": "🧑‍🏫"
            },
            {
                "unified": "1f9d1-1f3fb-200d-1f3eb",
                "native": "🧑🏻‍🏫"
            },
            {
                "unified": "1f9d1-1f3fc-200d-1f3eb",
                "native": "🧑🏼‍🏫"
            },
            {
                "unified": "1f9d1-1f3fd-200d-1f3eb",
                "native": "🧑🏽‍🏫"
            },
            {
                "unified": "1f9d1-1f3fe-200d-1f3eb",
                "native": "🧑🏾‍🏫"
            },
            {
                "unified": "1f9d1-1f3ff-200d-1f3eb",
                "native": "🧑🏿‍🏫"
            }
        ],
        "version": 12.1
    },
    "male-teacher": {
        "id": "male-teacher",
        "name": "Man Teacher",
        "keywords": [
            "male",
            "instructor",
            "professor",
            "human",
            "男",
            "インストラクター",
            "教授",
            "人間"
        ],
        "skins": [
            {
                "unified": "1f468-200d-1f3eb",
                "native": "👨‍🏫"
            },
            {
                "unified": "1f468-1f3fb-200d-1f3eb",
                "native": "👨🏻‍🏫"
            },
            {
                "unified": "1f468-1f3fc-200d-1f3eb",
                "native": "👨🏼‍🏫"
            },
            {
                "unified": "1f468-1f3fd-200d-1f3eb",
                "native": "👨🏽‍🏫"
            },
            {
                "unified": "1f468-1f3fe-200d-1f3eb",
                "native": "👨🏾‍🏫"
            },
            {
                "unified": "1f468-1f3ff-200d-1f3eb",
                "native": "👨🏿‍🏫"
            }
        ],
        "version": 4
    },
    "female-teacher": {
        "id": "female-teacher",
        "name": "Woman Teacher",
        "keywords": [
            "female",
            "instructor",
            "professor",
            "human",
            "女性",
            "インストラクター",
            "教授",
            "人間"
        ],
        "skins": [
            {
                "unified": "1f469-200d-1f3eb",
                "native": "👩‍🏫"
            },
            {
                "unified": "1f469-1f3fb-200d-1f3eb",
                "native": "👩🏻‍🏫"
            },
            {
                "unified": "1f469-1f3fc-200d-1f3eb",
                "native": "👩🏼‍🏫"
            },
            {
                "unified": "1f469-1f3fd-200d-1f3eb",
                "native": "👩🏽‍🏫"
            },
            {
                "unified": "1f469-1f3fe-200d-1f3eb",
                "native": "👩🏾‍🏫"
            },
            {
                "unified": "1f469-1f3ff-200d-1f3eb",
                "native": "👩🏿‍🏫"
            }
        ],
        "version": 4
    },
    "judge": {
        "id": "judge",
        "name": "Judge",
        "keywords": [
            "law",
            "法"
        ],
        "skins": [
            {
                "unified": "1f9d1-200d-2696-fe0f",
                "native": "🧑‍⚖️"
            },
            {
                "unified": "1f9d1-1f3fb-200d-2696-fe0f",
                "native": "🧑🏻‍⚖️"
            },
            {
                "unified": "1f9d1-1f3fc-200d-2696-fe0f",
                "native": "🧑🏼‍⚖️"
            },
            {
                "unified": "1f9d1-1f3fd-200d-2696-fe0f",
                "native": "🧑🏽‍⚖️"
            },
            {
                "unified": "1f9d1-1f3fe-200d-2696-fe0f",
                "native": "🧑🏾‍⚖️"
            },
            {
                "unified": "1f9d1-1f3ff-200d-2696-fe0f",
                "native": "🧑🏿‍⚖️"
            }
        ],
        "version": 12.1
    },
    "male-judge": {
        "id": "male-judge",
        "name": "Man Judge",
        "keywords": [
            "male",
            "justice",
            "court",
            "human",
            "男",
            "正義",
            "裁判所",
            "人間"
        ],
        "skins": [
            {
                "unified": "1f468-200d-2696-fe0f",
                "native": "👨‍⚖️"
            },
            {
                "unified": "1f468-1f3fb-200d-2696-fe0f",
                "native": "👨🏻‍⚖️"
            },
            {
                "unified": "1f468-1f3fc-200d-2696-fe0f",
                "native": "👨🏼‍⚖️"
            },
            {
                "unified": "1f468-1f3fd-200d-2696-fe0f",
                "native": "👨🏽‍⚖️"
            },
            {
                "unified": "1f468-1f3fe-200d-2696-fe0f",
                "native": "👨🏾‍⚖️"
            },
            {
                "unified": "1f468-1f3ff-200d-2696-fe0f",
                "native": "👨🏿‍⚖️"
            }
        ],
        "version": 4
    },
    "female-judge": {
        "id": "female-judge",
        "name": "Woman Judge",
        "keywords": [
            "female",
            "justice",
            "court",
            "human",
            "女性",
            "正義",
            "裁判所",
            "人間"
        ],
        "skins": [
            {
                "unified": "1f469-200d-2696-fe0f",
                "native": "👩‍⚖️"
            },
            {
                "unified": "1f469-1f3fb-200d-2696-fe0f",
                "native": "👩🏻‍⚖️"
            },
            {
                "unified": "1f469-1f3fc-200d-2696-fe0f",
                "native": "👩🏼‍⚖️"
            },
            {
                "unified": "1f469-1f3fd-200d-2696-fe0f",
                "native": "👩🏽‍⚖️"
            },
            {
                "unified": "1f469-1f3fe-200d-2696-fe0f",
                "native": "👩🏾‍⚖️"
            },
            {
                "unified": "1f469-1f3ff-200d-2696-fe0f",
                "native": "👩🏿‍⚖️"
            }
        ],
        "version": 4
    },
    "farmer": {
        "id": "farmer",
        "name": "Farmer",
        "keywords": [
            "crops",
            "作物"
        ],
        "skins": [
            {
                "unified": "1f9d1-200d-1f33e",
                "native": "🧑‍🌾"
            },
            {
                "unified": "1f9d1-1f3fb-200d-1f33e",
                "native": "🧑🏻‍🌾"
            },
            {
                "unified": "1f9d1-1f3fc-200d-1f33e",
                "native": "🧑🏼‍🌾"
            },
            {
                "unified": "1f9d1-1f3fd-200d-1f33e",
                "native": "🧑🏽‍🌾"
            },
            {
                "unified": "1f9d1-1f3fe-200d-1f33e",
                "native": "🧑🏾‍🌾"
            },
            {
                "unified": "1f9d1-1f3ff-200d-1f33e",
                "native": "🧑🏿‍🌾"
            }
        ],
        "version": 12.1
    },
    "male-farmer": {
        "id": "male-farmer",
        "name": "Man Farmer",
        "keywords": [
            "male",
            "rancher",
            "gardener",
            "human",
            "男",
            "牧場主",
            "庭師",
            "人間"
        ],
        "skins": [
            {
                "unified": "1f468-200d-1f33e",
                "native": "👨‍🌾"
            },
            {
                "unified": "1f468-1f3fb-200d-1f33e",
                "native": "👨🏻‍🌾"
            },
            {
                "unified": "1f468-1f3fc-200d-1f33e",
                "native": "👨🏼‍🌾"
            },
            {
                "unified": "1f468-1f3fd-200d-1f33e",
                "native": "👨🏽‍🌾"
            },
            {
                "unified": "1f468-1f3fe-200d-1f33e",
                "native": "👨🏾‍🌾"
            },
            {
                "unified": "1f468-1f3ff-200d-1f33e",
                "native": "👨🏿‍🌾"
            }
        ],
        "version": 4
    },
    "female-farmer": {
        "id": "female-farmer",
        "name": "Woman Farmer",
        "keywords": [
            "female",
            "rancher",
            "gardener",
            "human",
            "女性",
            "牧場主",
            "庭師",
            "人間"
        ],
        "skins": [
            {
                "unified": "1f469-200d-1f33e",
                "native": "👩‍🌾"
            },
            {
                "unified": "1f469-1f3fb-200d-1f33e",
                "native": "👩🏻‍🌾"
            },
            {
                "unified": "1f469-1f3fc-200d-1f33e",
                "native": "👩🏼‍🌾"
            },
            {
                "unified": "1f469-1f3fd-200d-1f33e",
                "native": "👩🏽‍🌾"
            },
            {
                "unified": "1f469-1f3fe-200d-1f33e",
                "native": "👩🏾‍🌾"
            },
            {
                "unified": "1f469-1f3ff-200d-1f33e",
                "native": "👩🏿‍🌾"
            }
        ],
        "version": 4
    },
    "cook": {
        "id": "cook",
        "name": "Cook",
        "keywords": [
            "food",
            "kitchen",
            "culinary",
            "食べ物",
            "台所",
            "料理"
        ],
        "skins": [
            {
                "unified": "1f9d1-200d-1f373",
                "native": "🧑‍🍳"
            },
            {
                "unified": "1f9d1-1f3fb-200d-1f373",
                "native": "🧑🏻‍🍳"
            },
            {
                "unified": "1f9d1-1f3fc-200d-1f373",
                "native": "🧑🏼‍🍳"
            },
            {
                "unified": "1f9d1-1f3fd-200d-1f373",
                "native": "🧑🏽‍🍳"
            },
            {
                "unified": "1f9d1-1f3fe-200d-1f373",
                "native": "🧑🏾‍🍳"
            },
            {
                "unified": "1f9d1-1f3ff-200d-1f373",
                "native": "🧑🏿‍🍳"
            }
        ],
        "version": 12.1
    },
    "male-cook": {
        "id": "male-cook",
        "name": "Man Cook",
        "keywords": [
            "male",
            "chef",
            "human",
            "男",
            "シェフ",
            "人間"
        ],
        "skins": [
            {
                "unified": "1f468-200d-1f373",
                "native": "👨‍🍳"
            },
            {
                "unified": "1f468-1f3fb-200d-1f373",
                "native": "👨🏻‍🍳"
            },
            {
                "unified": "1f468-1f3fc-200d-1f373",
                "native": "👨🏼‍🍳"
            },
            {
                "unified": "1f468-1f3fd-200d-1f373",
                "native": "👨🏽‍🍳"
            },
            {
                "unified": "1f468-1f3fe-200d-1f373",
                "native": "👨🏾‍🍳"
            },
            {
                "unified": "1f468-1f3ff-200d-1f373",
                "native": "👨🏿‍🍳"
            }
        ],
        "version": 4
    },
    "female-cook": {
        "id": "female-cook",
        "name": "Woman Cook",
        "keywords": [
            "female",
            "chef",
            "human",
            "女性",
            "シェフ",
            "人間"
        ],
        "skins": [
            {
                "unified": "1f469-200d-1f373",
                "native": "👩‍🍳"
            },
            {
                "unified": "1f469-1f3fb-200d-1f373",
                "native": "👩🏻‍🍳"
            },
            {
                "unified": "1f469-1f3fc-200d-1f373",
                "native": "👩🏼‍🍳"
            },
            {
                "unified": "1f469-1f3fd-200d-1f373",
                "native": "👩🏽‍🍳"
            },
            {
                "unified": "1f469-1f3fe-200d-1f373",
                "native": "👩🏾‍🍳"
            },
            {
                "unified": "1f469-1f3ff-200d-1f373",
                "native": "👩🏿‍🍳"
            }
        ],
        "version": 4
    },
    "mechanic": {
        "id": "mechanic",
        "name": "Mechanic",
        "keywords": [
            "worker",
            "technician",
            "ワーカー",
            "テクニシャン"
        ],
        "skins": [
            {
                "unified": "1f9d1-200d-1f527",
                "native": "🧑‍🔧"
            },
            {
                "unified": "1f9d1-1f3fb-200d-1f527",
                "native": "🧑🏻‍🔧"
            },
            {
                "unified": "1f9d1-1f3fc-200d-1f527",
                "native": "🧑🏼‍🔧"
            },
            {
                "unified": "1f9d1-1f3fd-200d-1f527",
                "native": "🧑🏽‍🔧"
            },
            {
                "unified": "1f9d1-1f3fe-200d-1f527",
                "native": "🧑🏾‍🔧"
            },
            {
                "unified": "1f9d1-1f3ff-200d-1f527",
                "native": "🧑🏿‍🔧"
            }
        ],
        "version": 12.1
    },
    "male-mechanic": {
        "id": "male-mechanic",
        "name": "Man Mechanic",
        "keywords": [
            "male",
            "plumber",
            "human",
            "wrench",
            "男",
            "配管工",
            "人間",
            "レンチ"
        ],
        "skins": [
            {
                "unified": "1f468-200d-1f527",
                "native": "👨‍🔧"
            },
            {
                "unified": "1f468-1f3fb-200d-1f527",
                "native": "👨🏻‍🔧"
            },
            {
                "unified": "1f468-1f3fc-200d-1f527",
                "native": "👨🏼‍🔧"
            },
            {
                "unified": "1f468-1f3fd-200d-1f527",
                "native": "👨🏽‍🔧"
            },
            {
                "unified": "1f468-1f3fe-200d-1f527",
                "native": "👨🏾‍🔧"
            },
            {
                "unified": "1f468-1f3ff-200d-1f527",
                "native": "👨🏿‍🔧"
            }
        ],
        "version": 4
    },
    "female-mechanic": {
        "id": "female-mechanic",
        "name": "Woman Mechanic",
        "keywords": [
            "female",
            "plumber",
            "human",
            "wrench",
            "女性",
            "配管工",
            "人間",
            "レンチ"
        ],
        "skins": [
            {
                "unified": "1f469-200d-1f527",
                "native": "👩‍🔧"
            },
            {
                "unified": "1f469-1f3fb-200d-1f527",
                "native": "👩🏻‍🔧"
            },
            {
                "unified": "1f469-1f3fc-200d-1f527",
                "native": "👩🏼‍🔧"
            },
            {
                "unified": "1f469-1f3fd-200d-1f527",
                "native": "👩🏽‍🔧"
            },
            {
                "unified": "1f469-1f3fe-200d-1f527",
                "native": "👩🏾‍🔧"
            },
            {
                "unified": "1f469-1f3ff-200d-1f527",
                "native": "👩🏿‍🔧"
            }
        ],
        "version": 4
    },
    "factory_worker": {
        "id": "factory_worker",
        "name": "Factory Worker",
        "keywords": [
            "labor",
            "労働"
        ],
        "skins": [
            {
                "unified": "1f9d1-200d-1f3ed",
                "native": "🧑‍🏭"
            },
            {
                "unified": "1f9d1-1f3fb-200d-1f3ed",
                "native": "🧑🏻‍🏭"
            },
            {
                "unified": "1f9d1-1f3fc-200d-1f3ed",
                "native": "🧑🏼‍🏭"
            },
            {
                "unified": "1f9d1-1f3fd-200d-1f3ed",
                "native": "🧑🏽‍🏭"
            },
            {
                "unified": "1f9d1-1f3fe-200d-1f3ed",
                "native": "🧑🏾‍🏭"
            },
            {
                "unified": "1f9d1-1f3ff-200d-1f3ed",
                "native": "🧑🏿‍🏭"
            }
        ],
        "version": 12.1
    },
    "male-factory-worker": {
        "id": "male-factory-worker",
        "name": "Man Factory Worker",
        "keywords": [
            "male",
            "factory-worker",
            "assembly",
            "industrial",
            "human",
            "男",
            "工場労働者",
            "組み立て",
            "工業用",
            "人間"
        ],
        "skins": [
            {
                "unified": "1f468-200d-1f3ed",
                "native": "👨‍🏭"
            },
            {
                "unified": "1f468-1f3fb-200d-1f3ed",
                "native": "👨🏻‍🏭"
            },
            {
                "unified": "1f468-1f3fc-200d-1f3ed",
                "native": "👨🏼‍🏭"
            },
            {
                "unified": "1f468-1f3fd-200d-1f3ed",
                "native": "👨🏽‍🏭"
            },
            {
                "unified": "1f468-1f3fe-200d-1f3ed",
                "native": "👨🏾‍🏭"
            },
            {
                "unified": "1f468-1f3ff-200d-1f3ed",
                "native": "👨🏿‍🏭"
            }
        ],
        "version": 4
    },
    "female-factory-worker": {
        "id": "female-factory-worker",
        "name": "Woman Factory Worker",
        "keywords": [
            "female",
            "factory-worker",
            "assembly",
            "industrial",
            "human",
            "女性",
            "工場労働者",
            "組み立て",
            "工業用",
            "人間"
        ],
        "skins": [
            {
                "unified": "1f469-200d-1f3ed",
                "native": "👩‍🏭"
            },
            {
                "unified": "1f469-1f3fb-200d-1f3ed",
                "native": "👩🏻‍🏭"
            },
            {
                "unified": "1f469-1f3fc-200d-1f3ed",
                "native": "👩🏼‍🏭"
            },
            {
                "unified": "1f469-1f3fd-200d-1f3ed",
                "native": "👩🏽‍🏭"
            },
            {
                "unified": "1f469-1f3fe-200d-1f3ed",
                "native": "👩🏾‍🏭"
            },
            {
                "unified": "1f469-1f3ff-200d-1f3ed",
                "native": "👩🏿‍🏭"
            }
        ],
        "version": 4
    },
    "office_worker": {
        "id": "office_worker",
        "name": "Office Worker",
        "keywords": [
            "business",
            "仕事"
        ],
        "skins": [
            {
                "unified": "1f9d1-200d-1f4bc",
                "native": "🧑‍💼"
            },
            {
                "unified": "1f9d1-1f3fb-200d-1f4bc",
                "native": "🧑🏻‍💼"
            },
            {
                "unified": "1f9d1-1f3fc-200d-1f4bc",
                "native": "🧑🏼‍💼"
            },
            {
                "unified": "1f9d1-1f3fd-200d-1f4bc",
                "native": "🧑🏽‍💼"
            },
            {
                "unified": "1f9d1-1f3fe-200d-1f4bc",
                "native": "🧑🏾‍💼"
            },
            {
                "unified": "1f9d1-1f3ff-200d-1f4bc",
                "native": "🧑🏿‍💼"
            }
        ],
        "version": 12.1
    },
    "male-office-worker": {
        "id": "male-office-worker",
        "name": "Man Office Worker",
        "keywords": [
            "male",
            "office-worker",
            "business",
            "manager",
            "human",
            "男",
            "会社員",
            "仕事",
            "マネジャー",
            "人間"
        ],
        "skins": [
            {
                "unified": "1f468-200d-1f4bc",
                "native": "👨‍💼"
            },
            {
                "unified": "1f468-1f3fb-200d-1f4bc",
                "native": "👨🏻‍💼"
            },
            {
                "unified": "1f468-1f3fc-200d-1f4bc",
                "native": "👨🏼‍💼"
            },
            {
                "unified": "1f468-1f3fd-200d-1f4bc",
                "native": "👨🏽‍💼"
            },
            {
                "unified": "1f468-1f3fe-200d-1f4bc",
                "native": "👨🏾‍💼"
            },
            {
                "unified": "1f468-1f3ff-200d-1f4bc",
                "native": "👨🏿‍💼"
            }
        ],
        "version": 4
    },
    "female-office-worker": {
        "id": "female-office-worker",
        "name": "Woman Office Worker",
        "keywords": [
            "female",
            "office-worker",
            "business",
            "manager",
            "human",
            "女性",
            "会社員",
            "仕事",
            "マネジャー",
            "人間"
        ],
        "skins": [
            {
                "unified": "1f469-200d-1f4bc",
                "native": "👩‍💼"
            },
            {
                "unified": "1f469-1f3fb-200d-1f4bc",
                "native": "👩🏻‍💼"
            },
            {
                "unified": "1f469-1f3fc-200d-1f4bc",
                "native": "👩🏼‍💼"
            },
            {
                "unified": "1f469-1f3fd-200d-1f4bc",
                "native": "👩🏽‍💼"
            },
            {
                "unified": "1f469-1f3fe-200d-1f4bc",
                "native": "👩🏾‍💼"
            },
            {
                "unified": "1f469-1f3ff-200d-1f4bc",
                "native": "👩🏿‍💼"
            }
        ],
        "version": 4
    },
    "scientist": {
        "id": "scientist",
        "name": "Scientist",
        "keywords": [
            "chemistry",
            "化学"
        ],
        "skins": [
            {
                "unified": "1f9d1-200d-1f52c",
                "native": "🧑‍🔬"
            },
            {
                "unified": "1f9d1-1f3fb-200d-1f52c",
                "native": "🧑🏻‍🔬"
            },
            {
                "unified": "1f9d1-1f3fc-200d-1f52c",
                "native": "🧑🏼‍🔬"
            },
            {
                "unified": "1f9d1-1f3fd-200d-1f52c",
                "native": "🧑🏽‍🔬"
            },
            {
                "unified": "1f9d1-1f3fe-200d-1f52c",
                "native": "🧑🏾‍🔬"
            },
            {
                "unified": "1f9d1-1f3ff-200d-1f52c",
                "native": "🧑🏿‍🔬"
            }
        ],
        "version": 12.1
    },
    "male-scientist": {
        "id": "male-scientist",
        "name": "Man Scientist",
        "keywords": [
            "male",
            "biologist",
            "chemist",
            "engineer",
            "physicist",
            "human",
            "男",
            "生物学者",
            "化学者",
            "エンジニア",
            "物理学者",
            "人間"
        ],
        "skins": [
            {
                "unified": "1f468-200d-1f52c",
                "native": "👨‍🔬"
            },
            {
                "unified": "1f468-1f3fb-200d-1f52c",
                "native": "👨🏻‍🔬"
            },
            {
                "unified": "1f468-1f3fc-200d-1f52c",
                "native": "👨🏼‍🔬"
            },
            {
                "unified": "1f468-1f3fd-200d-1f52c",
                "native": "👨🏽‍🔬"
            },
            {
                "unified": "1f468-1f3fe-200d-1f52c",
                "native": "👨🏾‍🔬"
            },
            {
                "unified": "1f468-1f3ff-200d-1f52c",
                "native": "👨🏿‍🔬"
            }
        ],
        "version": 4
    },
    "female-scientist": {
        "id": "female-scientist",
        "name": "Woman Scientist",
        "keywords": [
            "female",
            "biologist",
            "chemist",
            "engineer",
            "physicist",
            "human",
            "女性",
            "生物学者",
            "化学者",
            "エンジニア",
            "物理学者",
            "人間"
        ],
        "skins": [
            {
                "unified": "1f469-200d-1f52c",
                "native": "👩‍🔬"
            },
            {
                "unified": "1f469-1f3fb-200d-1f52c",
                "native": "👩🏻‍🔬"
            },
            {
                "unified": "1f469-1f3fc-200d-1f52c",
                "native": "👩🏼‍🔬"
            },
            {
                "unified": "1f469-1f3fd-200d-1f52c",
                "native": "👩🏽‍🔬"
            },
            {
                "unified": "1f469-1f3fe-200d-1f52c",
                "native": "👩🏾‍🔬"
            },
            {
                "unified": "1f469-1f3ff-200d-1f52c",
                "native": "👩🏿‍🔬"
            }
        ],
        "version": 4
    },
    "technologist": {
        "id": "technologist",
        "name": "Technologist",
        "keywords": [
            "computer",
            "コンピューター"
        ],
        "skins": [
            {
                "unified": "1f9d1-200d-1f4bb",
                "native": "🧑‍💻"
            },
            {
                "unified": "1f9d1-1f3fb-200d-1f4bb",
                "native": "🧑🏻‍💻"
            },
            {
                "unified": "1f9d1-1f3fc-200d-1f4bb",
                "native": "🧑🏼‍💻"
            },
            {
                "unified": "1f9d1-1f3fd-200d-1f4bb",
                "native": "🧑🏽‍💻"
            },
            {
                "unified": "1f9d1-1f3fe-200d-1f4bb",
                "native": "🧑🏾‍💻"
            },
            {
                "unified": "1f9d1-1f3ff-200d-1f4bb",
                "native": "🧑🏿‍💻"
            }
        ],
        "version": 12.1
    },
    "male-technologist": {
        "id": "male-technologist",
        "name": "Man Technologist",
        "keywords": [
            "male",
            "coder",
            "developer",
            "engineer",
            "programmer",
            "software",
            "human",
            "laptop",
            "computer",
            "男",
            "コーダー",
            "デベロッパー",
            "エンジニア",
            "プログラマー",
            "ソフトウェア",
            "人間",
            "ラップトップ",
            "コンピューター"
        ],
        "skins": [
            {
                "unified": "1f468-200d-1f4bb",
                "native": "👨‍💻"
            },
            {
                "unified": "1f468-1f3fb-200d-1f4bb",
                "native": "👨🏻‍💻"
            },
            {
                "unified": "1f468-1f3fc-200d-1f4bb",
                "native": "👨🏼‍💻"
            },
            {
                "unified": "1f468-1f3fd-200d-1f4bb",
                "native": "👨🏽‍💻"
            },
            {
                "unified": "1f468-1f3fe-200d-1f4bb",
                "native": "👨🏾‍💻"
            },
            {
                "unified": "1f468-1f3ff-200d-1f4bb",
                "native": "👨🏿‍💻"
            }
        ],
        "version": 4
    },
    "female-technologist": {
        "id": "female-technologist",
        "name": "Woman Technologist",
        "keywords": [
            "female",
            "coder",
            "developer",
            "engineer",
            "programmer",
            "software",
            "human",
            "laptop",
            "computer",
            "女性",
            "コーダー",
            "デベロッパー",
            "エンジニア",
            "プログラマー",
            "ソフトウェア",
            "人間",
            "ラップトップ",
            "コンピューター"
        ],
        "skins": [
            {
                "unified": "1f469-200d-1f4bb",
                "native": "👩‍💻"
            },
            {
                "unified": "1f469-1f3fb-200d-1f4bb",
                "native": "👩🏻‍💻"
            },
            {
                "unified": "1f469-1f3fc-200d-1f4bb",
                "native": "👩🏼‍💻"
            },
            {
                "unified": "1f469-1f3fd-200d-1f4bb",
                "native": "👩🏽‍💻"
            },
            {
                "unified": "1f469-1f3fe-200d-1f4bb",
                "native": "👩🏾‍💻"
            },
            {
                "unified": "1f469-1f3ff-200d-1f4bb",
                "native": "👩🏿‍💻"
            }
        ],
        "version": 4
    },
    "singer": {
        "id": "singer",
        "name": "Singer",
        "keywords": [
            "song",
            "artist",
            "performer",
            "歌",
            "アーティスト",
            "パフォーマー"
        ],
        "skins": [
            {
                "unified": "1f9d1-200d-1f3a4",
                "native": "🧑‍🎤"
            },
            {
                "unified": "1f9d1-1f3fb-200d-1f3a4",
                "native": "🧑🏻‍🎤"
            },
            {
                "unified": "1f9d1-1f3fc-200d-1f3a4",
                "native": "🧑🏼‍🎤"
            },
            {
                "unified": "1f9d1-1f3fd-200d-1f3a4",
                "native": "🧑🏽‍🎤"
            },
            {
                "unified": "1f9d1-1f3fe-200d-1f3a4",
                "native": "🧑🏾‍🎤"
            },
            {
                "unified": "1f9d1-1f3ff-200d-1f3a4",
                "native": "🧑🏿‍🎤"
            }
        ],
        "version": 12.1
    },
    "male-singer": {
        "id": "male-singer",
        "name": "Man Singer",
        "keywords": [
            "male",
            "rockstar",
            "entertainer",
            "human",
            "男",
            "ロックスター",
            "エンターテイナー",
            "人間"
        ],
        "skins": [
            {
                "unified": "1f468-200d-1f3a4",
                "native": "👨‍🎤"
            },
            {
                "unified": "1f468-1f3fb-200d-1f3a4",
                "native": "👨🏻‍🎤"
            },
            {
                "unified": "1f468-1f3fc-200d-1f3a4",
                "native": "👨🏼‍🎤"
            },
            {
                "unified": "1f468-1f3fd-200d-1f3a4",
                "native": "👨🏽‍🎤"
            },
            {
                "unified": "1f468-1f3fe-200d-1f3a4",
                "native": "👨🏾‍🎤"
            },
            {
                "unified": "1f468-1f3ff-200d-1f3a4",
                "native": "👨🏿‍🎤"
            }
        ],
        "version": 4
    },
    "female-singer": {
        "id": "female-singer",
        "name": "Woman Singer",
        "keywords": [
            "female",
            "rockstar",
            "entertainer",
            "human",
            "女性",
            "ロックスター",
            "エンターテイナー",
            "人間"
        ],
        "skins": [
            {
                "unified": "1f469-200d-1f3a4",
                "native": "👩‍🎤"
            },
            {
                "unified": "1f469-1f3fb-200d-1f3a4",
                "native": "👩🏻‍🎤"
            },
            {
                "unified": "1f469-1f3fc-200d-1f3a4",
                "native": "👩🏼‍🎤"
            },
            {
                "unified": "1f469-1f3fd-200d-1f3a4",
                "native": "👩🏽‍🎤"
            },
            {
                "unified": "1f469-1f3fe-200d-1f3a4",
                "native": "👩🏾‍🎤"
            },
            {
                "unified": "1f469-1f3ff-200d-1f3a4",
                "native": "👩🏿‍🎤"
            }
        ],
        "version": 4
    },
    "artist": {
        "id": "artist",
        "name": "Artist",
        "keywords": [
            "painting",
            "draw",
            "creativity",
            "ペインティング",
            "描く",
            "創造性"
        ],
        "skins": [
            {
                "unified": "1f9d1-200d-1f3a8",
                "native": "🧑‍🎨"
            },
            {
                "unified": "1f9d1-1f3fb-200d-1f3a8",
                "native": "🧑🏻‍🎨"
            },
            {
                "unified": "1f9d1-1f3fc-200d-1f3a8",
                "native": "🧑🏼‍🎨"
            },
            {
                "unified": "1f9d1-1f3fd-200d-1f3a8",
                "native": "🧑🏽‍🎨"
            },
            {
                "unified": "1f9d1-1f3fe-200d-1f3a8",
                "native": "🧑🏾‍🎨"
            },
            {
                "unified": "1f9d1-1f3ff-200d-1f3a8",
                "native": "🧑🏿‍🎨"
            }
        ],
        "version": 12.1
    },
    "male-artist": {
        "id": "male-artist",
        "name": "Man Artist",
        "keywords": [
            "male",
            "painter",
            "human",
            "男",
            "画家",
            "人間"
        ],
        "skins": [
            {
                "unified": "1f468-200d-1f3a8",
                "native": "👨‍🎨"
            },
            {
                "unified": "1f468-1f3fb-200d-1f3a8",
                "native": "👨🏻‍🎨"
            },
            {
                "unified": "1f468-1f3fc-200d-1f3a8",
                "native": "👨🏼‍🎨"
            },
            {
                "unified": "1f468-1f3fd-200d-1f3a8",
                "native": "👨🏽‍🎨"
            },
            {
                "unified": "1f468-1f3fe-200d-1f3a8",
                "native": "👨🏾‍🎨"
            },
            {
                "unified": "1f468-1f3ff-200d-1f3a8",
                "native": "👨🏿‍🎨"
            }
        ],
        "version": 4
    },
    "female-artist": {
        "id": "female-artist",
        "name": "Woman Artist",
        "keywords": [
            "female",
            "painter",
            "human",
            "女性",
            "画家",
            "人間"
        ],
        "skins": [
            {
                "unified": "1f469-200d-1f3a8",
                "native": "👩‍🎨"
            },
            {
                "unified": "1f469-1f3fb-200d-1f3a8",
                "native": "👩🏻‍🎨"
            },
            {
                "unified": "1f469-1f3fc-200d-1f3a8",
                "native": "👩🏼‍🎨"
            },
            {
                "unified": "1f469-1f3fd-200d-1f3a8",
                "native": "👩🏽‍🎨"
            },
            {
                "unified": "1f469-1f3fe-200d-1f3a8",
                "native": "👩🏾‍🎨"
            },
            {
                "unified": "1f469-1f3ff-200d-1f3a8",
                "native": "👩🏿‍🎨"
            }
        ],
        "version": 4
    },
    "pilot": {
        "id": "pilot",
        "name": "Pilot",
        "keywords": [
            "fly",
            "plane",
            "airplane",
            "飛ぶ",
            "飛行機",
            "飛行機"
        ],
        "skins": [
            {
                "unified": "1f9d1-200d-2708-fe0f",
                "native": "🧑‍✈️"
            },
            {
                "unified": "1f9d1-1f3fb-200d-2708-fe0f",
                "native": "🧑🏻‍✈️"
            },
            {
                "unified": "1f9d1-1f3fc-200d-2708-fe0f",
                "native": "🧑🏼‍✈️"
            },
            {
                "unified": "1f9d1-1f3fd-200d-2708-fe0f",
                "native": "🧑🏽‍✈️"
            },
            {
                "unified": "1f9d1-1f3fe-200d-2708-fe0f",
                "native": "🧑🏾‍✈️"
            },
            {
                "unified": "1f9d1-1f3ff-200d-2708-fe0f",
                "native": "🧑🏿‍✈️"
            }
        ],
        "version": 12.1
    },
    "male-pilot": {
        "id": "male-pilot",
        "name": "Man Pilot",
        "keywords": [
            "male",
            "aviator",
            "plane",
            "human",
            "男",
            "飛行士",
            "飛行機",
            "人間"
        ],
        "skins": [
            {
                "unified": "1f468-200d-2708-fe0f",
                "native": "👨‍✈️"
            },
            {
                "unified": "1f468-1f3fb-200d-2708-fe0f",
                "native": "👨🏻‍✈️"
            },
            {
                "unified": "1f468-1f3fc-200d-2708-fe0f",
                "native": "👨🏼‍✈️"
            },
            {
                "unified": "1f468-1f3fd-200d-2708-fe0f",
                "native": "👨🏽‍✈️"
            },
            {
                "unified": "1f468-1f3fe-200d-2708-fe0f",
                "native": "👨🏾‍✈️"
            },
            {
                "unified": "1f468-1f3ff-200d-2708-fe0f",
                "native": "👨🏿‍✈️"
            }
        ],
        "version": 4
    },
    "female-pilot": {
        "id": "female-pilot",
        "name": "Woman Pilot",
        "keywords": [
            "female",
            "aviator",
            "plane",
            "human",
            "女性",
            "飛行士",
            "飛行機",
            "人間"
        ],
        "skins": [
            {
                "unified": "1f469-200d-2708-fe0f",
                "native": "👩‍✈️"
            },
            {
                "unified": "1f469-1f3fb-200d-2708-fe0f",
                "native": "👩🏻‍✈️"
            },
            {
                "unified": "1f469-1f3fc-200d-2708-fe0f",
                "native": "👩🏼‍✈️"
            },
            {
                "unified": "1f469-1f3fd-200d-2708-fe0f",
                "native": "👩🏽‍✈️"
            },
            {
                "unified": "1f469-1f3fe-200d-2708-fe0f",
                "native": "👩🏾‍✈️"
            },
            {
                "unified": "1f469-1f3ff-200d-2708-fe0f",
                "native": "👩🏿‍✈️"
            }
        ],
        "version": 4
    },
    "astronaut": {
        "id": "astronaut",
        "name": "Astronaut",
        "keywords": [
            "outerspace",
            "宇宙"
        ],
        "skins": [
            {
                "unified": "1f9d1-200d-1f680",
                "native": "🧑‍🚀"
            },
            {
                "unified": "1f9d1-1f3fb-200d-1f680",
                "native": "🧑🏻‍🚀"
            },
            {
                "unified": "1f9d1-1f3fc-200d-1f680",
                "native": "🧑🏼‍🚀"
            },
            {
                "unified": "1f9d1-1f3fd-200d-1f680",
                "native": "🧑🏽‍🚀"
            },
            {
                "unified": "1f9d1-1f3fe-200d-1f680",
                "native": "🧑🏾‍🚀"
            },
            {
                "unified": "1f9d1-1f3ff-200d-1f680",
                "native": "🧑🏿‍🚀"
            }
        ],
        "version": 12.1
    },
    "male-astronaut": {
        "id": "male-astronaut",
        "name": "Man Astronaut",
        "keywords": [
            "male",
            "space",
            "rocket",
            "human",
            "男",
            "空",
            "ロケット",
            "人間"
        ],
        "skins": [
            {
                "unified": "1f468-200d-1f680",
                "native": "👨‍🚀"
            },
            {
                "unified": "1f468-1f3fb-200d-1f680",
                "native": "👨🏻‍🚀"
            },
            {
                "unified": "1f468-1f3fc-200d-1f680",
                "native": "👨🏼‍🚀"
            },
            {
                "unified": "1f468-1f3fd-200d-1f680",
                "native": "👨🏽‍🚀"
            },
            {
                "unified": "1f468-1f3fe-200d-1f680",
                "native": "👨🏾‍🚀"
            },
            {
                "unified": "1f468-1f3ff-200d-1f680",
                "native": "👨🏿‍🚀"
            }
        ],
        "version": 4
    },
    "female-astronaut": {
        "id": "female-astronaut",
        "name": "Woman Astronaut",
        "keywords": [
            "female",
            "space",
            "rocket",
            "human",
            "女性",
            "空",
            "ロケット",
            "人間"
        ],
        "skins": [
            {
                "unified": "1f469-200d-1f680",
                "native": "👩‍🚀"
            },
            {
                "unified": "1f469-1f3fb-200d-1f680",
                "native": "👩🏻‍🚀"
            },
            {
                "unified": "1f469-1f3fc-200d-1f680",
                "native": "👩🏼‍🚀"
            },
            {
                "unified": "1f469-1f3fd-200d-1f680",
                "native": "👩🏽‍🚀"
            },
            {
                "unified": "1f469-1f3fe-200d-1f680",
                "native": "👩🏾‍🚀"
            },
            {
                "unified": "1f469-1f3ff-200d-1f680",
                "native": "👩🏿‍🚀"
            }
        ],
        "version": 4
    },
    "firefighter": {
        "id": "firefighter",
        "name": "Firefighter",
        "keywords": [
            "fire",
            "火"
        ],
        "skins": [
            {
                "unified": "1f9d1-200d-1f692",
                "native": "🧑‍🚒"
            },
            {
                "unified": "1f9d1-1f3fb-200d-1f692",
                "native": "🧑🏻‍🚒"
            },
            {
                "unified": "1f9d1-1f3fc-200d-1f692",
                "native": "🧑🏼‍🚒"
            },
            {
                "unified": "1f9d1-1f3fd-200d-1f692",
                "native": "🧑🏽‍🚒"
            },
            {
                "unified": "1f9d1-1f3fe-200d-1f692",
                "native": "🧑🏾‍🚒"
            },
            {
                "unified": "1f9d1-1f3ff-200d-1f692",
                "native": "🧑🏿‍🚒"
            }
        ],
        "version": 12.1
    },
    "male-firefighter": {
        "id": "male-firefighter",
        "name": "Man Firefighter",
        "keywords": [
            "male",
            "fireman",
            "human",
            "男",
            "消防士",
            "人間"
        ],
        "skins": [
            {
                "unified": "1f468-200d-1f692",
                "native": "👨‍🚒"
            },
            {
                "unified": "1f468-1f3fb-200d-1f692",
                "native": "👨🏻‍🚒"
            },
            {
                "unified": "1f468-1f3fc-200d-1f692",
                "native": "👨🏼‍🚒"
            },
            {
                "unified": "1f468-1f3fd-200d-1f692",
                "native": "👨🏽‍🚒"
            },
            {
                "unified": "1f468-1f3fe-200d-1f692",
                "native": "👨🏾‍🚒"
            },
            {
                "unified": "1f468-1f3ff-200d-1f692",
                "native": "👨🏿‍🚒"
            }
        ],
        "version": 4
    },
    "female-firefighter": {
        "id": "female-firefighter",
        "name": "Woman Firefighter",
        "keywords": [
            "female",
            "fireman",
            "human",
            "女性",
            "消防士",
            "人間"
        ],
        "skins": [
            {
                "unified": "1f469-200d-1f692",
                "native": "👩‍🚒"
            },
            {
                "unified": "1f469-1f3fb-200d-1f692",
                "native": "👩🏻‍🚒"
            },
            {
                "unified": "1f469-1f3fc-200d-1f692",
                "native": "👩🏼‍🚒"
            },
            {
                "unified": "1f469-1f3fd-200d-1f692",
                "native": "👩🏽‍🚒"
            },
            {
                "unified": "1f469-1f3fe-200d-1f692",
                "native": "👩🏾‍🚒"
            },
            {
                "unified": "1f469-1f3ff-200d-1f692",
                "native": "👩🏿‍🚒"
            }
        ],
        "version": 4
    },
    "cop": {
        "id": "cop",
        "name": "Police Officer",
        "keywords": [
            "cop",
            "警官"
        ],
        "skins": [
            {
                "unified": "1f46e",
                "native": "👮"
            },
            {
                "unified": "1f46e-1f3fb",
                "native": "👮🏻"
            },
            {
                "unified": "1f46e-1f3fc",
                "native": "👮🏼"
            },
            {
                "unified": "1f46e-1f3fd",
                "native": "👮🏽"
            },
            {
                "unified": "1f46e-1f3fe",
                "native": "👮🏾"
            },
            {
                "unified": "1f46e-1f3ff",
                "native": "👮🏿"
            }
        ],
        "version": 1
    },
    "male-police-officer": {
        "id": "male-police-officer",
        "name": "Man Police Officer",
        "keywords": [
            "male",
            "police-officer",
            "law",
            "legal",
            "enforcement",
            "arrest",
            "911",
            "男",
            "警察官",
            "法",
            "法的",
            "執行",
            "逮捕",
            "911"
        ],
        "skins": [
            {
                "unified": "1f46e-200d-2642-fe0f",
                "native": "👮‍♂️"
            },
            {
                "unified": "1f46e-1f3fb-200d-2642-fe0f",
                "native": "👮🏻‍♂️"
            },
            {
                "unified": "1f46e-1f3fc-200d-2642-fe0f",
                "native": "👮🏼‍♂️"
            },
            {
                "unified": "1f46e-1f3fd-200d-2642-fe0f",
                "native": "👮🏽‍♂️"
            },
            {
                "unified": "1f46e-1f3fe-200d-2642-fe0f",
                "native": "👮🏾‍♂️"
            },
            {
                "unified": "1f46e-1f3ff-200d-2642-fe0f",
                "native": "👮🏿‍♂️"
            }
        ],
        "version": 4
    },
    "female-police-officer": {
        "id": "female-police-officer",
        "name": "Woman Police Officer",
        "keywords": [
            "female",
            "police-officer",
            "law",
            "legal",
            "enforcement",
            "arrest",
            "911",
            "女性",
            "警察官",
            "法",
            "法的",
            "執行",
            "逮捕",
            "911"
        ],
        "skins": [
            {
                "unified": "1f46e-200d-2640-fe0f",
                "native": "👮‍♀️"
            },
            {
                "unified": "1f46e-1f3fb-200d-2640-fe0f",
                "native": "👮🏻‍♀️"
            },
            {
                "unified": "1f46e-1f3fc-200d-2640-fe0f",
                "native": "👮🏼‍♀️"
            },
            {
                "unified": "1f46e-1f3fd-200d-2640-fe0f",
                "native": "👮🏽‍♀️"
            },
            {
                "unified": "1f46e-1f3fe-200d-2640-fe0f",
                "native": "👮🏾‍♀️"
            },
            {
                "unified": "1f46e-1f3ff-200d-2640-fe0f",
                "native": "👮🏿‍♀️"
            }
        ],
        "version": 4
    },
    "sleuth_or_spy": {
        "id": "sleuth_or_spy",
        "name": "Detective",
        "keywords": [
            "sleuth",
            "or",
            "spy",
            "human",
            "探偵",
            "また",
            "スパイ",
            "人間"
        ],
        "skins": [
            {
                "unified": "1f575-fe0f",
                "native": "🕵️"
            },
            {
                "unified": "1f575-1f3fb",
                "native": "🕵🏻"
            },
            {
                "unified": "1f575-1f3fc",
                "native": "🕵🏼"
            },
            {
                "unified": "1f575-1f3fd",
                "native": "🕵🏽"
            },
            {
                "unified": "1f575-1f3fe",
                "native": "🕵🏾"
            },
            {
                "unified": "1f575-1f3ff",
                "native": "🕵🏿"
            }
        ],
        "version": 1
    },
    "male-detective": {
        "id": "male-detective",
        "name": "Man Detective",
        "keywords": [
            "male",
            "crime",
            "男",
            "犯罪"
        ],
        "skins": [
            {
                "unified": "1f575-fe0f-200d-2642-fe0f",
                "native": "🕵️‍♂️"
            },
            {
                "unified": "1f575-1f3fb-200d-2642-fe0f",
                "native": "🕵🏻‍♂️"
            },
            {
                "unified": "1f575-1f3fc-200d-2642-fe0f",
                "native": "🕵🏼‍♂️"
            },
            {
                "unified": "1f575-1f3fd-200d-2642-fe0f",
                "native": "🕵🏽‍♂️"
            },
            {
                "unified": "1f575-1f3fe-200d-2642-fe0f",
                "native": "🕵🏾‍♂️"
            },
            {
                "unified": "1f575-1f3ff-200d-2642-fe0f",
                "native": "🕵🏿‍♂️"
            }
        ],
        "version": 4
    },
    "female-detective": {
        "id": "female-detective",
        "name": "Woman Detective",
        "keywords": [
            "female",
            "human",
            "spy",
            "女性",
            "人間",
            "スパイ"
        ],
        "skins": [
            {
                "unified": "1f575-fe0f-200d-2640-fe0f",
                "native": "🕵️‍♀️"
            },
            {
                "unified": "1f575-1f3fb-200d-2640-fe0f",
                "native": "🕵🏻‍♀️"
            },
            {
                "unified": "1f575-1f3fc-200d-2640-fe0f",
                "native": "🕵🏼‍♀️"
            },
            {
                "unified": "1f575-1f3fd-200d-2640-fe0f",
                "native": "🕵🏽‍♀️"
            },
            {
                "unified": "1f575-1f3fe-200d-2640-fe0f",
                "native": "🕵🏾‍♀️"
            },
            {
                "unified": "1f575-1f3ff-200d-2640-fe0f",
                "native": "🕵🏿‍♀️"
            }
        ],
        "version": 4
    },
    "guardsman": {
        "id": "guardsman",
        "name": "Guard",
        "keywords": [
            "guardsman",
            "protect",
            "ガードマン",
            "守る"
        ],
        "skins": [
            {
                "unified": "1f482",
                "native": "💂"
            },
            {
                "unified": "1f482-1f3fb",
                "native": "💂🏻"
            },
            {
                "unified": "1f482-1f3fc",
                "native": "💂🏼"
            },
            {
                "unified": "1f482-1f3fd",
                "native": "💂🏽"
            },
            {
                "unified": "1f482-1f3fe",
                "native": "💂🏾"
            },
            {
                "unified": "1f482-1f3ff",
                "native": "💂🏿"
            }
        ],
        "version": 1
    },
    "male-guard": {
        "id": "male-guard",
        "name": "Man Guard",
        "keywords": [
            "male",
            "uk",
            "gb",
            "british",
            "guy",
            "royal",
            "男",
            "イギリス",
            "GB",
            "イギリス人",
            "男",
            "王立"
        ],
        "skins": [
            {
                "unified": "1f482-200d-2642-fe0f",
                "native": "💂‍♂️"
            },
            {
                "unified": "1f482-1f3fb-200d-2642-fe0f",
                "native": "💂🏻‍♂️"
            },
            {
                "unified": "1f482-1f3fc-200d-2642-fe0f",
                "native": "💂🏼‍♂️"
            },
            {
                "unified": "1f482-1f3fd-200d-2642-fe0f",
                "native": "💂🏽‍♂️"
            },
            {
                "unified": "1f482-1f3fe-200d-2642-fe0f",
                "native": "💂🏾‍♂️"
            },
            {
                "unified": "1f482-1f3ff-200d-2642-fe0f",
                "native": "💂🏿‍♂️"
            }
        ],
        "version": 4
    },
    "female-guard": {
        "id": "female-guard",
        "name": "Woman Guard",
        "keywords": [
            "female",
            "uk",
            "gb",
            "british",
            "royal",
            "女性",
            "イギリス",
            "GB",
            "イギリス人",
            "王立"
        ],
        "skins": [
            {
                "unified": "1f482-200d-2640-fe0f",
                "native": "💂‍♀️"
            },
            {
                "unified": "1f482-1f3fb-200d-2640-fe0f",
                "native": "💂🏻‍♀️"
            },
            {
                "unified": "1f482-1f3fc-200d-2640-fe0f",
                "native": "💂🏼‍♀️"
            },
            {
                "unified": "1f482-1f3fd-200d-2640-fe0f",
                "native": "💂🏽‍♀️"
            },
            {
                "unified": "1f482-1f3fe-200d-2640-fe0f",
                "native": "💂🏾‍♀️"
            },
            {
                "unified": "1f482-1f3ff-200d-2640-fe0f",
                "native": "💂🏿‍♀️"
            }
        ],
        "version": 4
    },
    "ninja": {
        "id": "ninja",
        "name": "Ninja",
        "keywords": [
            "ninjutsu",
            "skills",
            "japanese",
            "忍術",
            "スキル",
            "日本"
        ],
        "skins": [
            {
                "unified": "1f977",
                "native": "🥷"
            },
            {
                "unified": "1f977-1f3fb",
                "native": "🥷🏻"
            },
            {
                "unified": "1f977-1f3fc",
                "native": "🥷🏼"
            },
            {
                "unified": "1f977-1f3fd",
                "native": "🥷🏽"
            },
            {
                "unified": "1f977-1f3fe",
                "native": "🥷🏾"
            },
            {
                "unified": "1f977-1f3ff",
                "native": "🥷🏿"
            }
        ],
        "version": 13
    },
    "construction_worker": {
        "id": "construction_worker",
        "name": "Construction Worker",
        "keywords": [
            "labor",
            "build",
            "労働",
            "建てる"
        ],
        "skins": [
            {
                "unified": "1f477",
                "native": "👷"
            },
            {
                "unified": "1f477-1f3fb",
                "native": "👷🏻"
            },
            {
                "unified": "1f477-1f3fc",
                "native": "👷🏼"
            },
            {
                "unified": "1f477-1f3fd",
                "native": "👷🏽"
            },
            {
                "unified": "1f477-1f3fe",
                "native": "👷🏾"
            },
            {
                "unified": "1f477-1f3ff",
                "native": "👷🏿"
            }
        ],
        "version": 1
    },
    "male-construction-worker": {
        "id": "male-construction-worker",
        "name": "Man Construction Worker",
        "keywords": [
            "male",
            "construction-worker",
            "human",
            "wip",
            "guy",
            "build",
            "labor",
            "男",
            "建設労働者",
            "人間",
            "ワイプ",
            "男",
            "建てる",
            "労働"
        ],
        "skins": [
            {
                "unified": "1f477-200d-2642-fe0f",
                "native": "👷‍♂️"
            },
            {
                "unified": "1f477-1f3fb-200d-2642-fe0f",
                "native": "👷🏻‍♂️"
            },
            {
                "unified": "1f477-1f3fc-200d-2642-fe0f",
                "native": "👷🏼‍♂️"
            },
            {
                "unified": "1f477-1f3fd-200d-2642-fe0f",
                "native": "👷🏽‍♂️"
            },
            {
                "unified": "1f477-1f3fe-200d-2642-fe0f",
                "native": "👷🏾‍♂️"
            },
            {
                "unified": "1f477-1f3ff-200d-2642-fe0f",
                "native": "👷🏿‍♂️"
            }
        ],
        "version": 4
    },
    "female-construction-worker": {
        "id": "female-construction-worker",
        "name": "Woman Construction Worker",
        "keywords": [
            "female",
            "construction-worker",
            "human",
            "wip",
            "build",
            "labor",
            "女性",
            "建設労働者",
            "人間",
            "ワイプ",
            "建てる",
            "労働"
        ],
        "skins": [
            {
                "unified": "1f477-200d-2640-fe0f",
                "native": "👷‍♀️"
            },
            {
                "unified": "1f477-1f3fb-200d-2640-fe0f",
                "native": "👷🏻‍♀️"
            },
            {
                "unified": "1f477-1f3fc-200d-2640-fe0f",
                "native": "👷🏼‍♀️"
            },
            {
                "unified": "1f477-1f3fd-200d-2640-fe0f",
                "native": "👷🏽‍♀️"
            },
            {
                "unified": "1f477-1f3fe-200d-2640-fe0f",
                "native": "👷🏾‍♀️"
            },
            {
                "unified": "1f477-1f3ff-200d-2640-fe0f",
                "native": "👷🏿‍♀️"
            }
        ],
        "version": 4
    },
    "person_with_crown": {
        "id": "person_with_crown",
        "name": "Person with Crown",
        "keywords": [
            "royalty",
            "power",
            "ロイヤリティ",
            "力"
        ],
        "skins": [
            {
                "unified": "1fac5",
                "native": "🫅"
            },
            {
                "unified": "1fac5-1f3fb",
                "native": "🫅🏻"
            },
            {
                "unified": "1fac5-1f3fc",
                "native": "🫅🏼"
            },
            {
                "unified": "1fac5-1f3fd",
                "native": "🫅🏽"
            },
            {
                "unified": "1fac5-1f3fe",
                "native": "🫅🏾"
            },
            {
                "unified": "1fac5-1f3ff",
                "native": "🫅🏿"
            }
        ],
        "version": 14
    },
    "prince": {
        "id": "prince",
        "name": "Prince",
        "keywords": [
            "boy",
            "man",
            "male",
            "crown",
            "royal",
            "king",
            "男の子",
            "男",
            "男",
            "クラウン",
            "王立",
            "王"
        ],
        "skins": [
            {
                "unified": "1f934",
                "native": "🤴"
            },
            {
                "unified": "1f934-1f3fb",
                "native": "🤴🏻"
            },
            {
                "unified": "1f934-1f3fc",
                "native": "🤴🏼"
            },
            {
                "unified": "1f934-1f3fd",
                "native": "🤴🏽"
            },
            {
                "unified": "1f934-1f3fe",
                "native": "🤴🏾"
            },
            {
                "unified": "1f934-1f3ff",
                "native": "🤴🏿"
            }
        ],
        "version": 3
    },
    "princess": {
        "id": "princess",
        "name": "Princess",
        "keywords": [
            "girl",
            "woman",
            "female",
            "blond",
            "crown",
            "royal",
            "queen",
            "女の子",
            "女性",
            "女性",
            "ブロンド",
            "クラウン",
            "王立",
            "女王"
        ],
        "skins": [
            {
                "unified": "1f478",
                "native": "👸"
            },
            {
                "unified": "1f478-1f3fb",
                "native": "👸🏻"
            },
            {
                "unified": "1f478-1f3fc",
                "native": "👸🏼"
            },
            {
                "unified": "1f478-1f3fd",
                "native": "👸🏽"
            },
            {
                "unified": "1f478-1f3fe",
                "native": "👸🏾"
            },
            {
                "unified": "1f478-1f3ff",
                "native": "👸🏿"
            }
        ],
        "version": 1
    },
    "man_with_turban": {
        "id": "man_with_turban",
        "name": "Man with Turban",
        "keywords": [
            "person",
            "wearing",
            "headdress",
            "人",
            "着る",
            "頭飾り"
        ],
        "skins": [
            {
                "unified": "1f473",
                "native": "👳"
            },
            {
                "unified": "1f473-1f3fb",
                "native": "👳🏻"
            },
            {
                "unified": "1f473-1f3fc",
                "native": "👳🏼"
            },
            {
                "unified": "1f473-1f3fd",
                "native": "👳🏽"
            },
            {
                "unified": "1f473-1f3fe",
                "native": "👳🏾"
            },
            {
                "unified": "1f473-1f3ff",
                "native": "👳🏿"
            }
        ],
        "version": 1
    },
    "man-wearing-turban": {
        "id": "man-wearing-turban",
        "name": "Man Wearing Turban",
        "keywords": [
            "wearing-turban",
            "male",
            "indian",
            "hinduism",
            "arabs",
            "着ターバン",
            "男",
            "インド人",
            "ヒンズー教",
            "アラブ人"
        ],
        "skins": [
            {
                "unified": "1f473-200d-2642-fe0f",
                "native": "👳‍♂️"
            },
            {
                "unified": "1f473-1f3fb-200d-2642-fe0f",
                "native": "👳🏻‍♂️"
            },
            {
                "unified": "1f473-1f3fc-200d-2642-fe0f",
                "native": "👳🏼‍♂️"
            },
            {
                "unified": "1f473-1f3fd-200d-2642-fe0f",
                "native": "👳🏽‍♂️"
            },
            {
                "unified": "1f473-1f3fe-200d-2642-fe0f",
                "native": "👳🏾‍♂️"
            },
            {
                "unified": "1f473-1f3ff-200d-2642-fe0f",
                "native": "👳🏿‍♂️"
            }
        ],
        "version": 4
    },
    "woman-wearing-turban": {
        "id": "woman-wearing-turban",
        "name": "Woman Wearing Turban",
        "keywords": [
            "wearing-turban",
            "female",
            "indian",
            "hinduism",
            "arabs",
            "着ターバン",
            "女性",
            "インド人",
            "ヒンズー教",
            "アラブ人"
        ],
        "skins": [
            {
                "unified": "1f473-200d-2640-fe0f",
                "native": "👳‍♀️"
            },
            {
                "unified": "1f473-1f3fb-200d-2640-fe0f",
                "native": "👳🏻‍♀️"
            },
            {
                "unified": "1f473-1f3fc-200d-2640-fe0f",
                "native": "👳🏼‍♀️"
            },
            {
                "unified": "1f473-1f3fd-200d-2640-fe0f",
                "native": "👳🏽‍♀️"
            },
            {
                "unified": "1f473-1f3fe-200d-2640-fe0f",
                "native": "👳🏾‍♀️"
            },
            {
                "unified": "1f473-1f3ff-200d-2640-fe0f",
                "native": "👳🏿‍♀️"
            }
        ],
        "version": 4
    },
    "man_with_gua_pi_mao": {
        "id": "man_with_gua_pi_mao",
        "name": "Man with Gua Pi Mao",
        "keywords": [
            "skullcap",
            "male",
            "boy",
            "chinese",
            "スカルキャップ",
            "男",
            "男の子",
            "中国語"
        ],
        "skins": [
            {
                "unified": "1f472",
                "native": "👲"
            },
            {
                "unified": "1f472-1f3fb",
                "native": "👲🏻"
            },
            {
                "unified": "1f472-1f3fc",
                "native": "👲🏼"
            },
            {
                "unified": "1f472-1f3fd",
                "native": "👲🏽"
            },
            {
                "unified": "1f472-1f3fe",
                "native": "👲🏾"
            },
            {
                "unified": "1f472-1f3ff",
                "native": "👲🏿"
            }
        ],
        "version": 1
    },
    "person_with_headscarf": {
        "id": "person_with_headscarf",
        "name": "Woman with Headscarf",
        "keywords": [
            "person",
            "female",
            "hijab",
            "mantilla",
            "tichel",
            "人",
            "女性",
            "ヒジャブ",
            "マンティラ",
            "ティチェル"
        ],
        "skins": [
            {
                "unified": "1f9d5",
                "native": "🧕"
            },
            {
                "unified": "1f9d5-1f3fb",
                "native": "🧕🏻"
            },
            {
                "unified": "1f9d5-1f3fc",
                "native": "🧕🏼"
            },
            {
                "unified": "1f9d5-1f3fd",
                "native": "🧕🏽"
            },
            {
                "unified": "1f9d5-1f3fe",
                "native": "🧕🏾"
            },
            {
                "unified": "1f9d5-1f3ff",
                "native": "🧕🏿"
            }
        ],
        "version": 5
    },
    "person_in_tuxedo": {
        "id": "person_in_tuxedo",
        "name": "Man in Tuxedo",
        "keywords": [
            "person",
            "couple",
            "marriage",
            "wedding",
            "groom",
            "人",
            "カップル",
            "結婚",
            "結婚式",
            "新郎"
        ],
        "skins": [
            {
                "unified": "1f935",
                "native": "🤵"
            },
            {
                "unified": "1f935-1f3fb",
                "native": "🤵🏻"
            },
            {
                "unified": "1f935-1f3fc",
                "native": "🤵🏼"
            },
            {
                "unified": "1f935-1f3fd",
                "native": "🤵🏽"
            },
            {
                "unified": "1f935-1f3fe",
                "native": "🤵🏾"
            },
            {
                "unified": "1f935-1f3ff",
                "native": "🤵🏿"
            }
        ],
        "version": 3
    },
    "man_in_tuxedo": {
        "id": "man_in_tuxedo",
        "name": "Man in Tuxedo",
        "keywords": [
            "formal",
            "fashion",
            "丁寧",
            "ファッション"
        ],
        "skins": [
            {
                "unified": "1f935-200d-2642-fe0f",
                "native": "🤵‍♂️"
            },
            {
                "unified": "1f935-1f3fb-200d-2642-fe0f",
                "native": "🤵🏻‍♂️"
            },
            {
                "unified": "1f935-1f3fc-200d-2642-fe0f",
                "native": "🤵🏼‍♂️"
            },
            {
                "unified": "1f935-1f3fd-200d-2642-fe0f",
                "native": "🤵🏽‍♂️"
            },
            {
                "unified": "1f935-1f3fe-200d-2642-fe0f",
                "native": "🤵🏾‍♂️"
            },
            {
                "unified": "1f935-1f3ff-200d-2642-fe0f",
                "native": "🤵🏿‍♂️"
            }
        ],
        "version": 13
    },
    "woman_in_tuxedo": {
        "id": "woman_in_tuxedo",
        "name": "Woman in Tuxedo",
        "keywords": [
            "formal",
            "fashion",
            "丁寧",
            "ファッション"
        ],
        "skins": [
            {
                "unified": "1f935-200d-2640-fe0f",
                "native": "🤵‍♀️"
            },
            {
                "unified": "1f935-1f3fb-200d-2640-fe0f",
                "native": "🤵🏻‍♀️"
            },
            {
                "unified": "1f935-1f3fc-200d-2640-fe0f",
                "native": "🤵🏼‍♀️"
            },
            {
                "unified": "1f935-1f3fd-200d-2640-fe0f",
                "native": "🤵🏽‍♀️"
            },
            {
                "unified": "1f935-1f3fe-200d-2640-fe0f",
                "native": "🤵🏾‍♀️"
            },
            {
                "unified": "1f935-1f3ff-200d-2640-fe0f",
                "native": "🤵🏿‍♀️"
            }
        ],
        "version": 13
    },
    "bride_with_veil": {
        "id": "bride_with_veil",
        "name": "Bride with Veil",
        "keywords": [
            "couple",
            "marriage",
            "wedding",
            "woman",
            "カップル",
            "結婚",
            "結婚式",
            "女性"
        ],
        "skins": [
            {
                "unified": "1f470",
                "native": "👰"
            },
            {
                "unified": "1f470-1f3fb",
                "native": "👰🏻"
            },
            {
                "unified": "1f470-1f3fc",
                "native": "👰🏼"
            },
            {
                "unified": "1f470-1f3fd",
                "native": "👰🏽"
            },
            {
                "unified": "1f470-1f3fe",
                "native": "👰🏾"
            },
            {
                "unified": "1f470-1f3ff",
                "native": "👰🏿"
            }
        ],
        "version": 1
    },
    "man_with_veil": {
        "id": "man_with_veil",
        "name": "Man with Veil",
        "keywords": [
            "wedding",
            "marriage",
            "結婚式",
            "結婚"
        ],
        "skins": [
            {
                "unified": "1f470-200d-2642-fe0f",
                "native": "👰‍♂️"
            },
            {
                "unified": "1f470-1f3fb-200d-2642-fe0f",
                "native": "👰🏻‍♂️"
            },
            {
                "unified": "1f470-1f3fc-200d-2642-fe0f",
                "native": "👰🏼‍♂️"
            },
            {
                "unified": "1f470-1f3fd-200d-2642-fe0f",
                "native": "👰🏽‍♂️"
            },
            {
                "unified": "1f470-1f3fe-200d-2642-fe0f",
                "native": "👰🏾‍♂️"
            },
            {
                "unified": "1f470-1f3ff-200d-2642-fe0f",
                "native": "👰🏿‍♂️"
            }
        ],
        "version": 13
    },
    "woman_with_veil": {
        "id": "woman_with_veil",
        "name": "Woman with Veil",
        "keywords": [
            "wedding",
            "marriage",
            "結婚式",
            "結婚"
        ],
        "skins": [
            {
                "unified": "1f470-200d-2640-fe0f",
                "native": "👰‍♀️"
            },
            {
                "unified": "1f470-1f3fb-200d-2640-fe0f",
                "native": "👰🏻‍♀️"
            },
            {
                "unified": "1f470-1f3fc-200d-2640-fe0f",
                "native": "👰🏼‍♀️"
            },
            {
                "unified": "1f470-1f3fd-200d-2640-fe0f",
                "native": "👰🏽‍♀️"
            },
            {
                "unified": "1f470-1f3fe-200d-2640-fe0f",
                "native": "👰🏾‍♀️"
            },
            {
                "unified": "1f470-1f3ff-200d-2640-fe0f",
                "native": "👰🏿‍♀️"
            }
        ],
        "version": 13
    },
    "pregnant_woman": {
        "id": "pregnant_woman",
        "name": "Pregnant Woman",
        "keywords": [
            "baby",
            "赤ちゃん"
        ],
        "skins": [
            {
                "unified": "1f930",
                "native": "🤰"
            },
            {
                "unified": "1f930-1f3fb",
                "native": "🤰🏻"
            },
            {
                "unified": "1f930-1f3fc",
                "native": "🤰🏼"
            },
            {
                "unified": "1f930-1f3fd",
                "native": "🤰🏽"
            },
            {
                "unified": "1f930-1f3fe",
                "native": "🤰🏾"
            },
            {
                "unified": "1f930-1f3ff",
                "native": "🤰🏿"
            }
        ],
        "version": 3
    },
    "pregnant_man": {
        "id": "pregnant_man",
        "name": "Pregnant Man",
        "keywords": [
            "baby",
            "belly",
            "赤ちゃん",
            "腹"
        ],
        "skins": [
            {
                "unified": "1fac3",
                "native": "🫃"
            },
            {
                "unified": "1fac3-1f3fb",
                "native": "🫃🏻"
            },
            {
                "unified": "1fac3-1f3fc",
                "native": "🫃🏼"
            },
            {
                "unified": "1fac3-1f3fd",
                "native": "🫃🏽"
            },
            {
                "unified": "1fac3-1f3fe",
                "native": "🫃🏾"
            },
            {
                "unified": "1fac3-1f3ff",
                "native": "🫃🏿"
            }
        ],
        "version": 14
    },
    "pregnant_person": {
        "id": "pregnant_person",
        "name": "Pregnant Person",
        "keywords": [
            "baby",
            "belly",
            "赤ちゃん",
            "腹"
        ],
        "skins": [
            {
                "unified": "1fac4",
                "native": "🫄"
            },
            {
                "unified": "1fac4-1f3fb",
                "native": "🫄🏻"
            },
            {
                "unified": "1fac4-1f3fc",
                "native": "🫄🏼"
            },
            {
                "unified": "1fac4-1f3fd",
                "native": "🫄🏽"
            },
            {
                "unified": "1fac4-1f3fe",
                "native": "🫄🏾"
            },
            {
                "unified": "1fac4-1f3ff",
                "native": "🫄🏿"
            }
        ],
        "version": 14
    },
    "breast-feeding": {
        "id": "breast-feeding",
        "name": "Breast-Feeding",
        "keywords": [
            "breast",
            "feeding",
            "nursing",
            "baby",
            "胸",
            "給餌",
            "看護",
            "赤ちゃん"
        ],
        "skins": [
            {
                "unified": "1f931",
                "native": "🤱"
            },
            {
                "unified": "1f931-1f3fb",
                "native": "🤱🏻"
            },
            {
                "unified": "1f931-1f3fc",
                "native": "🤱🏼"
            },
            {
                "unified": "1f931-1f3fd",
                "native": "🤱🏽"
            },
            {
                "unified": "1f931-1f3fe",
                "native": "🤱🏾"
            },
            {
                "unified": "1f931-1f3ff",
                "native": "🤱🏿"
            }
        ],
        "version": 5
    },
    "woman_feeding_baby": {
        "id": "woman_feeding_baby",
        "name": "Woman Feeding Baby",
        "keywords": [
            "birth",
            "food",
            "誕生",
            "食べ物"
        ],
        "skins": [
            {
                "unified": "1f469-200d-1f37c",
                "native": "👩‍🍼"
            },
            {
                "unified": "1f469-1f3fb-200d-1f37c",
                "native": "👩🏻‍🍼"
            },
            {
                "unified": "1f469-1f3fc-200d-1f37c",
                "native": "👩🏼‍🍼"
            },
            {
                "unified": "1f469-1f3fd-200d-1f37c",
                "native": "👩🏽‍🍼"
            },
            {
                "unified": "1f469-1f3fe-200d-1f37c",
                "native": "👩🏾‍🍼"
            },
            {
                "unified": "1f469-1f3ff-200d-1f37c",
                "native": "👩🏿‍🍼"
            }
        ],
        "version": 13
    },
    "man_feeding_baby": {
        "id": "man_feeding_baby",
        "name": "Man Feeding Baby",
        "keywords": [
            "birth",
            "food",
            "誕生",
            "食べ物"
        ],
        "skins": [
            {
                "unified": "1f468-200d-1f37c",
                "native": "👨‍🍼"
            },
            {
                "unified": "1f468-1f3fb-200d-1f37c",
                "native": "👨🏻‍🍼"
            },
            {
                "unified": "1f468-1f3fc-200d-1f37c",
                "native": "👨🏼‍🍼"
            },
            {
                "unified": "1f468-1f3fd-200d-1f37c",
                "native": "👨🏽‍🍼"
            },
            {
                "unified": "1f468-1f3fe-200d-1f37c",
                "native": "👨🏾‍🍼"
            },
            {
                "unified": "1f468-1f3ff-200d-1f37c",
                "native": "👨🏿‍🍼"
            }
        ],
        "version": 13
    },
    "person_feeding_baby": {
        "id": "person_feeding_baby",
        "name": "Person Feeding Baby",
        "keywords": [
            "birth",
            "food",
            "誕生",
            "食べ物"
        ],
        "skins": [
            {
                "unified": "1f9d1-200d-1f37c",
                "native": "🧑‍🍼"
            },
            {
                "unified": "1f9d1-1f3fb-200d-1f37c",
                "native": "🧑🏻‍🍼"
            },
            {
                "unified": "1f9d1-1f3fc-200d-1f37c",
                "native": "🧑🏼‍🍼"
            },
            {
                "unified": "1f9d1-1f3fd-200d-1f37c",
                "native": "🧑🏽‍🍼"
            },
            {
                "unified": "1f9d1-1f3fe-200d-1f37c",
                "native": "🧑🏾‍🍼"
            },
            {
                "unified": "1f9d1-1f3ff-200d-1f37c",
                "native": "🧑🏿‍🍼"
            }
        ],
        "version": 13
    },
    "angel": {
        "id": "angel",
        "name": "Baby Angel",
        "keywords": [
            "heaven",
            "wings",
            "halo",
            "天気",
            "翼",
            "ハロ"
        ],
        "skins": [
            {
                "unified": "1f47c",
                "native": "👼"
            },
            {
                "unified": "1f47c-1f3fb",
                "native": "👼🏻"
            },
            {
                "unified": "1f47c-1f3fc",
                "native": "👼🏼"
            },
            {
                "unified": "1f47c-1f3fd",
                "native": "👼🏽"
            },
            {
                "unified": "1f47c-1f3fe",
                "native": "👼🏾"
            },
            {
                "unified": "1f47c-1f3ff",
                "native": "👼🏿"
            }
        ],
        "version": 1
    },
    "santa": {
        "id": "santa",
        "name": "Santa Claus",
        "keywords": [
            "festival",
            "man",
            "male",
            "xmas",
            "father",
            "christmas",
            "祭り",
            "男",
            "男",
            "クリスマス",
            "父親",
            "クリスマス"
        ],
        "skins": [
            {
                "unified": "1f385",
                "native": "🎅"
            },
            {
                "unified": "1f385-1f3fb",
                "native": "🎅🏻"
            },
            {
                "unified": "1f385-1f3fc",
                "native": "🎅🏼"
            },
            {
                "unified": "1f385-1f3fd",
                "native": "🎅🏽"
            },
            {
                "unified": "1f385-1f3fe",
                "native": "🎅🏾"
            },
            {
                "unified": "1f385-1f3ff",
                "native": "🎅🏿"
            }
        ],
        "version": 1
    },
    "mrs_claus": {
        "id": "mrs_claus",
        "name": "Mrs. Claus",
        "keywords": [
            "mrs",
            "mother",
            "christmas",
            "woman",
            "female",
            "xmas",
            "夫人",
            "母親",
            "クリスマス",
            "女性",
            "女性",
            "クリスマス"
        ],
        "skins": [
            {
                "unified": "1f936",
                "native": "🤶"
            },
            {
                "unified": "1f936-1f3fb",
                "native": "🤶🏻"
            },
            {
                "unified": "1f936-1f3fc",
                "native": "🤶🏼"
            },
            {
                "unified": "1f936-1f3fd",
                "native": "🤶🏽"
            },
            {
                "unified": "1f936-1f3fe",
                "native": "🤶🏾"
            },
            {
                "unified": "1f936-1f3ff",
                "native": "🤶🏿"
            }
        ],
        "version": 3
    },
    "mx_claus": {
        "id": "mx_claus",
        "name": "Mx Claus",
        "keywords": [
            "christmas",
            "クリスマス"
        ],
        "skins": [
            {
                "unified": "1f9d1-200d-1f384",
                "native": "🧑‍🎄"
            },
            {
                "unified": "1f9d1-1f3fb-200d-1f384",
                "native": "🧑🏻‍🎄"
            },
            {
                "unified": "1f9d1-1f3fc-200d-1f384",
                "native": "🧑🏼‍🎄"
            },
            {
                "unified": "1f9d1-1f3fd-200d-1f384",
                "native": "🧑🏽‍🎄"
            },
            {
                "unified": "1f9d1-1f3fe-200d-1f384",
                "native": "🧑🏾‍🎄"
            },
            {
                "unified": "1f9d1-1f3ff-200d-1f384",
                "native": "🧑🏿‍🎄"
            }
        ],
        "version": 13
    },
    "superhero": {
        "id": "superhero",
        "name": "Superhero",
        "keywords": [
            "marvel",
            "マーベル"
        ],
        "skins": [
            {
                "unified": "1f9b8",
                "native": "🦸"
            },
            {
                "unified": "1f9b8-1f3fb",
                "native": "🦸🏻"
            },
            {
                "unified": "1f9b8-1f3fc",
                "native": "🦸🏼"
            },
            {
                "unified": "1f9b8-1f3fd",
                "native": "🦸🏽"
            },
            {
                "unified": "1f9b8-1f3fe",
                "native": "🦸🏾"
            },
            {
                "unified": "1f9b8-1f3ff",
                "native": "🦸🏿"
            }
        ],
        "version": 11
    },
    "male_superhero": {
        "id": "male_superhero",
        "name": "Man Superhero",
        "keywords": [
            "male",
            "good",
            "hero",
            "superpowers",
            "男",
            "良い",
            "ヒーロー",
            "超大国"
        ],
        "skins": [
            {
                "unified": "1f9b8-200d-2642-fe0f",
                "native": "🦸‍♂️"
            },
            {
                "unified": "1f9b8-1f3fb-200d-2642-fe0f",
                "native": "🦸🏻‍♂️"
            },
            {
                "unified": "1f9b8-1f3fc-200d-2642-fe0f",
                "native": "🦸🏼‍♂️"
            },
            {
                "unified": "1f9b8-1f3fd-200d-2642-fe0f",
                "native": "🦸🏽‍♂️"
            },
            {
                "unified": "1f9b8-1f3fe-200d-2642-fe0f",
                "native": "🦸🏾‍♂️"
            },
            {
                "unified": "1f9b8-1f3ff-200d-2642-fe0f",
                "native": "🦸🏿‍♂️"
            }
        ],
        "version": 11
    },
    "female_superhero": {
        "id": "female_superhero",
        "name": "Woman Superhero",
        "keywords": [
            "female",
            "good",
            "heroine",
            "superpowers",
            "女性",
            "良い",
            "ヒロイン",
            "超大国"
        ],
        "skins": [
            {
                "unified": "1f9b8-200d-2640-fe0f",
                "native": "🦸‍♀️"
            },
            {
                "unified": "1f9b8-1f3fb-200d-2640-fe0f",
                "native": "🦸🏻‍♀️"
            },
            {
                "unified": "1f9b8-1f3fc-200d-2640-fe0f",
                "native": "🦸🏼‍♀️"
            },
            {
                "unified": "1f9b8-1f3fd-200d-2640-fe0f",
                "native": "🦸🏽‍♀️"
            },
            {
                "unified": "1f9b8-1f3fe-200d-2640-fe0f",
                "native": "🦸🏾‍♀️"
            },
            {
                "unified": "1f9b8-1f3ff-200d-2640-fe0f",
                "native": "🦸🏿‍♀️"
            }
        ],
        "version": 11
    },
    "supervillain": {
        "id": "supervillain",
        "name": "Supervillain",
        "keywords": [
            "marvel",
            "マーベル"
        ],
        "skins": [
            {
                "unified": "1f9b9",
                "native": "🦹"
            },
            {
                "unified": "1f9b9-1f3fb",
                "native": "🦹🏻"
            },
            {
                "unified": "1f9b9-1f3fc",
                "native": "🦹🏼"
            },
            {
                "unified": "1f9b9-1f3fd",
                "native": "🦹🏽"
            },
            {
                "unified": "1f9b9-1f3fe",
                "native": "🦹🏾"
            },
            {
                "unified": "1f9b9-1f3ff",
                "native": "🦹🏿"
            }
        ],
        "version": 11
    },
    "male_supervillain": {
        "id": "male_supervillain",
        "name": "Man Supervillain",
        "keywords": [
            "male",
            "evil",
            "bad",
            "criminal",
            "hero",
            "superpowers",
            "男",
            "悪",
            "悪い",
            "犯罪的",
            "ヒーロー",
            "超大国"
        ],
        "skins": [
            {
                "unified": "1f9b9-200d-2642-fe0f",
                "native": "🦹‍♂️"
            },
            {
                "unified": "1f9b9-1f3fb-200d-2642-fe0f",
                "native": "🦹🏻‍♂️"
            },
            {
                "unified": "1f9b9-1f3fc-200d-2642-fe0f",
                "native": "🦹🏼‍♂️"
            },
            {
                "unified": "1f9b9-1f3fd-200d-2642-fe0f",
                "native": "🦹🏽‍♂️"
            },
            {
                "unified": "1f9b9-1f3fe-200d-2642-fe0f",
                "native": "🦹🏾‍♂️"
            },
            {
                "unified": "1f9b9-1f3ff-200d-2642-fe0f",
                "native": "🦹🏿‍♂️"
            }
        ],
        "version": 11
    },
    "female_supervillain": {
        "id": "female_supervillain",
        "name": "Woman Supervillain",
        "keywords": [
            "female",
            "evil",
            "bad",
            "criminal",
            "heroine",
            "superpowers",
            "女性",
            "悪",
            "悪い",
            "犯罪的",
            "ヒロイン",
            "超大国"
        ],
        "skins": [
            {
                "unified": "1f9b9-200d-2640-fe0f",
                "native": "🦹‍♀️"
            },
            {
                "unified": "1f9b9-1f3fb-200d-2640-fe0f",
                "native": "🦹🏻‍♀️"
            },
            {
                "unified": "1f9b9-1f3fc-200d-2640-fe0f",
                "native": "🦹🏼‍♀️"
            },
            {
                "unified": "1f9b9-1f3fd-200d-2640-fe0f",
                "native": "🦹🏽‍♀️"
            },
            {
                "unified": "1f9b9-1f3fe-200d-2640-fe0f",
                "native": "🦹🏾‍♀️"
            },
            {
                "unified": "1f9b9-1f3ff-200d-2640-fe0f",
                "native": "🦹🏿‍♀️"
            }
        ],
        "version": 11
    },
    "mage": {
        "id": "mage",
        "name": "Mage",
        "keywords": [
            "magic",
            "魔法"
        ],
        "skins": [
            {
                "unified": "1f9d9",
                "native": "🧙"
            },
            {
                "unified": "1f9d9-1f3fb",
                "native": "🧙🏻"
            },
            {
                "unified": "1f9d9-1f3fc",
                "native": "🧙🏼"
            },
            {
                "unified": "1f9d9-1f3fd",
                "native": "🧙🏽"
            },
            {
                "unified": "1f9d9-1f3fe",
                "native": "🧙🏾"
            },
            {
                "unified": "1f9d9-1f3ff",
                "native": "🧙🏿"
            }
        ],
        "version": 5
    },
    "male_mage": {
        "id": "male_mage",
        "name": "Man Mage",
        "keywords": [
            "male",
            "sorcerer",
            "男",
            "魔術師"
        ],
        "skins": [
            {
                "unified": "1f9d9-200d-2642-fe0f",
                "native": "🧙‍♂️"
            },
            {
                "unified": "1f9d9-1f3fb-200d-2642-fe0f",
                "native": "🧙🏻‍♂️"
            },
            {
                "unified": "1f9d9-1f3fc-200d-2642-fe0f",
                "native": "🧙🏼‍♂️"
            },
            {
                "unified": "1f9d9-1f3fd-200d-2642-fe0f",
                "native": "🧙🏽‍♂️"
            },
            {
                "unified": "1f9d9-1f3fe-200d-2642-fe0f",
                "native": "🧙🏾‍♂️"
            },
            {
                "unified": "1f9d9-1f3ff-200d-2642-fe0f",
                "native": "🧙🏿‍♂️"
            }
        ],
        "version": 5
    },
    "female_mage": {
        "id": "female_mage",
        "name": "Woman Mage",
        "keywords": [
            "female",
            "witch",
            "女性",
            "魔女"
        ],
        "skins": [
            {
                "unified": "1f9d9-200d-2640-fe0f",
                "native": "🧙‍♀️"
            },
            {
                "unified": "1f9d9-1f3fb-200d-2640-fe0f",
                "native": "🧙🏻‍♀️"
            },
            {
                "unified": "1f9d9-1f3fc-200d-2640-fe0f",
                "native": "🧙🏼‍♀️"
            },
            {
                "unified": "1f9d9-1f3fd-200d-2640-fe0f",
                "native": "🧙🏽‍♀️"
            },
            {
                "unified": "1f9d9-1f3fe-200d-2640-fe0f",
                "native": "🧙🏾‍♀️"
            },
            {
                "unified": "1f9d9-1f3ff-200d-2640-fe0f",
                "native": "🧙🏿‍♀️"
            }
        ],
        "version": 5
    },
    "fairy": {
        "id": "fairy",
        "name": "Fairy",
        "keywords": [
            "wings",
            "magical",
            "翼",
            "魔法の"
        ],
        "skins": [
            {
                "unified": "1f9da",
                "native": "🧚"
            },
            {
                "unified": "1f9da-1f3fb",
                "native": "🧚🏻"
            },
            {
                "unified": "1f9da-1f3fc",
                "native": "🧚🏼"
            },
            {
                "unified": "1f9da-1f3fd",
                "native": "🧚🏽"
            },
            {
                "unified": "1f9da-1f3fe",
                "native": "🧚🏾"
            },
            {
                "unified": "1f9da-1f3ff",
                "native": "🧚🏿"
            }
        ],
        "version": 5
    },
    "male_fairy": {
        "id": "male_fairy",
        "name": "Man Fairy",
        "keywords": [
            "male",
            "男"
        ],
        "skins": [
            {
                "unified": "1f9da-200d-2642-fe0f",
                "native": "🧚‍♂️"
            },
            {
                "unified": "1f9da-1f3fb-200d-2642-fe0f",
                "native": "🧚🏻‍♂️"
            },
            {
                "unified": "1f9da-1f3fc-200d-2642-fe0f",
                "native": "🧚🏼‍♂️"
            },
            {
                "unified": "1f9da-1f3fd-200d-2642-fe0f",
                "native": "🧚🏽‍♂️"
            },
            {
                "unified": "1f9da-1f3fe-200d-2642-fe0f",
                "native": "🧚🏾‍♂️"
            },
            {
                "unified": "1f9da-1f3ff-200d-2642-fe0f",
                "native": "🧚🏿‍♂️"
            }
        ],
        "version": 5
    },
    "female_fairy": {
        "id": "female_fairy",
        "name": "Woman Fairy",
        "keywords": [
            "female",
            "女性"
        ],
        "skins": [
            {
                "unified": "1f9da-200d-2640-fe0f",
                "native": "🧚‍♀️"
            },
            {
                "unified": "1f9da-1f3fb-200d-2640-fe0f",
                "native": "🧚🏻‍♀️"
            },
            {
                "unified": "1f9da-1f3fc-200d-2640-fe0f",
                "native": "🧚🏼‍♀️"
            },
            {
                "unified": "1f9da-1f3fd-200d-2640-fe0f",
                "native": "🧚🏽‍♀️"
            },
            {
                "unified": "1f9da-1f3fe-200d-2640-fe0f",
                "native": "🧚🏾‍♀️"
            },
            {
                "unified": "1f9da-1f3ff-200d-2640-fe0f",
                "native": "🧚🏿‍♀️"
            }
        ],
        "version": 5
    },
    "vampire": {
        "id": "vampire",
        "name": "Vampire",
        "keywords": [
            "blood",
            "twilight",
            "血",
            "トワイライト"
        ],
        "skins": [
            {
                "unified": "1f9db",
                "native": "🧛"
            },
            {
                "unified": "1f9db-1f3fb",
                "native": "🧛🏻"
            },
            {
                "unified": "1f9db-1f3fc",
                "native": "🧛🏼"
            },
            {
                "unified": "1f9db-1f3fd",
                "native": "🧛🏽"
            },
            {
                "unified": "1f9db-1f3fe",
                "native": "🧛🏾"
            },
            {
                "unified": "1f9db-1f3ff",
                "native": "🧛🏿"
            }
        ],
        "version": 5
    },
    "male_vampire": {
        "id": "male_vampire",
        "name": "Man Vampire",
        "keywords": [
            "male",
            "dracula",
            "男",
            "ドラキュラ"
        ],
        "skins": [
            {
                "unified": "1f9db-200d-2642-fe0f",
                "native": "🧛‍♂️"
            },
            {
                "unified": "1f9db-1f3fb-200d-2642-fe0f",
                "native": "🧛🏻‍♂️"
            },
            {
                "unified": "1f9db-1f3fc-200d-2642-fe0f",
                "native": "🧛🏼‍♂️"
            },
            {
                "unified": "1f9db-1f3fd-200d-2642-fe0f",
                "native": "🧛🏽‍♂️"
            },
            {
                "unified": "1f9db-1f3fe-200d-2642-fe0f",
                "native": "🧛🏾‍♂️"
            },
            {
                "unified": "1f9db-1f3ff-200d-2642-fe0f",
                "native": "🧛🏿‍♂️"
            }
        ],
        "version": 5
    },
    "female_vampire": {
        "id": "female_vampire",
        "name": "Woman Vampire",
        "keywords": [
            "female",
            "女性"
        ],
        "skins": [
            {
                "unified": "1f9db-200d-2640-fe0f",
                "native": "🧛‍♀️"
            },
            {
                "unified": "1f9db-1f3fb-200d-2640-fe0f",
                "native": "🧛🏻‍♀️"
            },
            {
                "unified": "1f9db-1f3fc-200d-2640-fe0f",
                "native": "🧛🏼‍♀️"
            },
            {
                "unified": "1f9db-1f3fd-200d-2640-fe0f",
                "native": "🧛🏽‍♀️"
            },
            {
                "unified": "1f9db-1f3fe-200d-2640-fe0f",
                "native": "🧛🏾‍♀️"
            },
            {
                "unified": "1f9db-1f3ff-200d-2640-fe0f",
                "native": "🧛🏿‍♀️"
            }
        ],
        "version": 5
    },
    "merperson": {
        "id": "merperson",
        "name": "Merperson",
        "keywords": [
            "sea",
            "海"
        ],
        "skins": [
            {
                "unified": "1f9dc",
                "native": "🧜"
            },
            {
                "unified": "1f9dc-1f3fb",
                "native": "🧜🏻"
            },
            {
                "unified": "1f9dc-1f3fc",
                "native": "🧜🏼"
            },
            {
                "unified": "1f9dc-1f3fd",
                "native": "🧜🏽"
            },
            {
                "unified": "1f9dc-1f3fe",
                "native": "🧜🏾"
            },
            {
                "unified": "1f9dc-1f3ff",
                "native": "🧜🏿"
            }
        ],
        "version": 5
    },
    "merman": {
        "id": "merman",
        "name": "Merman",
        "keywords": [
            "man",
            "male",
            "triton",
            "男",
            "男",
            "トリトン"
        ],
        "skins": [
            {
                "unified": "1f9dc-200d-2642-fe0f",
                "native": "🧜‍♂️"
            },
            {
                "unified": "1f9dc-1f3fb-200d-2642-fe0f",
                "native": "🧜🏻‍♂️"
            },
            {
                "unified": "1f9dc-1f3fc-200d-2642-fe0f",
                "native": "🧜🏼‍♂️"
            },
            {
                "unified": "1f9dc-1f3fd-200d-2642-fe0f",
                "native": "🧜🏽‍♂️"
            },
            {
                "unified": "1f9dc-1f3fe-200d-2642-fe0f",
                "native": "🧜🏾‍♂️"
            },
            {
                "unified": "1f9dc-1f3ff-200d-2642-fe0f",
                "native": "🧜🏿‍♂️"
            }
        ],
        "version": 5
    },
    "mermaid": {
        "id": "mermaid",
        "name": "Mermaid",
        "keywords": [
            "woman",
            "female",
            "merwoman",
            "ariel",
            "女性",
            "女性",
            "マーウーマン",
            "アリエル"
        ],
        "skins": [
            {
                "unified": "1f9dc-200d-2640-fe0f",
                "native": "🧜‍♀️"
            },
            {
                "unified": "1f9dc-1f3fb-200d-2640-fe0f",
                "native": "🧜🏻‍♀️"
            },
            {
                "unified": "1f9dc-1f3fc-200d-2640-fe0f",
                "native": "🧜🏼‍♀️"
            },
            {
                "unified": "1f9dc-1f3fd-200d-2640-fe0f",
                "native": "🧜🏽‍♀️"
            },
            {
                "unified": "1f9dc-1f3fe-200d-2640-fe0f",
                "native": "🧜🏾‍♀️"
            },
            {
                "unified": "1f9dc-1f3ff-200d-2640-fe0f",
                "native": "🧜🏿‍♀️"
            }
        ],
        "version": 5
    },
    "elf": {
        "id": "elf",
        "name": "Elf",
        "keywords": [
            "magical",
            "魔法の"
        ],
        "skins": [
            {
                "unified": "1f9dd",
                "native": "🧝"
            },
            {
                "unified": "1f9dd-1f3fb",
                "native": "🧝🏻"
            },
            {
                "unified": "1f9dd-1f3fc",
                "native": "🧝🏼"
            },
            {
                "unified": "1f9dd-1f3fd",
                "native": "🧝🏽"
            },
            {
                "unified": "1f9dd-1f3fe",
                "native": "🧝🏾"
            },
            {
                "unified": "1f9dd-1f3ff",
                "native": "🧝🏿"
            }
        ],
        "version": 5
    },
    "male_elf": {
        "id": "male_elf",
        "name": "Man Elf",
        "keywords": [
            "male",
            "男"
        ],
        "skins": [
            {
                "unified": "1f9dd-200d-2642-fe0f",
                "native": "🧝‍♂️"
            },
            {
                "unified": "1f9dd-1f3fb-200d-2642-fe0f",
                "native": "🧝🏻‍♂️"
            },
            {
                "unified": "1f9dd-1f3fc-200d-2642-fe0f",
                "native": "🧝🏼‍♂️"
            },
            {
                "unified": "1f9dd-1f3fd-200d-2642-fe0f",
                "native": "🧝🏽‍♂️"
            },
            {
                "unified": "1f9dd-1f3fe-200d-2642-fe0f",
                "native": "🧝🏾‍♂️"
            },
            {
                "unified": "1f9dd-1f3ff-200d-2642-fe0f",
                "native": "🧝🏿‍♂️"
            }
        ],
        "version": 5
    },
    "female_elf": {
        "id": "female_elf",
        "name": "Woman Elf",
        "keywords": [
            "female",
            "女性"
        ],
        "skins": [
            {
                "unified": "1f9dd-200d-2640-fe0f",
                "native": "🧝‍♀️"
            },
            {
                "unified": "1f9dd-1f3fb-200d-2640-fe0f",
                "native": "🧝🏻‍♀️"
            },
            {
                "unified": "1f9dd-1f3fc-200d-2640-fe0f",
                "native": "🧝🏼‍♀️"
            },
            {
                "unified": "1f9dd-1f3fd-200d-2640-fe0f",
                "native": "🧝🏽‍♀️"
            },
            {
                "unified": "1f9dd-1f3fe-200d-2640-fe0f",
                "native": "🧝🏾‍♀️"
            },
            {
                "unified": "1f9dd-1f3ff-200d-2640-fe0f",
                "native": "🧝🏿‍♀️"
            }
        ],
        "version": 5
    },
    "genie": {
        "id": "genie",
        "name": "Genie",
        "keywords": [
            "magical",
            "wishes",
            "魔法の",
            "願い"
        ],
        "skins": [
            {
                "unified": "1f9de",
                "native": "🧞"
            }
        ],
        "version": 5
    },
    "male_genie": {
        "id": "male_genie",
        "name": "Man Genie",
        "keywords": [
            "male",
            "男"
        ],
        "skins": [
            {
                "unified": "1f9de-200d-2642-fe0f",
                "native": "🧞‍♂️"
            }
        ],
        "version": 5
    },
    "female_genie": {
        "id": "female_genie",
        "name": "Woman Genie",
        "keywords": [
            "female",
            "女性"
        ],
        "skins": [
            {
                "unified": "1f9de-200d-2640-fe0f",
                "native": "🧞‍♀️"
            }
        ],
        "version": 5
    },
    "zombie": {
        "id": "zombie",
        "name": "Zombie",
        "keywords": [
            "dead",
            "死"
        ],
        "skins": [
            {
                "unified": "1f9df",
                "native": "🧟"
            }
        ],
        "version": 5
    },
    "male_zombie": {
        "id": "male_zombie",
        "name": "Man Zombie",
        "keywords": [
            "male",
            "dracula",
            "undead",
            "walking",
            "dead",
            "男",
            "ドラキュラ",
            "アンデッド",
            "歩く",
            "死"
        ],
        "skins": [
            {
                "unified": "1f9df-200d-2642-fe0f",
                "native": "🧟‍♂️"
            }
        ],
        "version": 5
    },
    "female_zombie": {
        "id": "female_zombie",
        "name": "Woman Zombie",
        "keywords": [
            "female",
            "undead",
            "walking",
            "dead",
            "女性",
            "アンデッド",
            "歩く",
            "死"
        ],
        "skins": [
            {
                "unified": "1f9df-200d-2640-fe0f",
                "native": "🧟‍♀️"
            }
        ],
        "version": 5
    },
    "troll": {
        "id": "troll",
        "name": "Troll",
        "keywords": [
            "mystical",
            "monster",
            "神秘的な",
            "モンスター"
        ],
        "skins": [
            {
                "unified": "1f9cc",
                "native": "🧌"
            }
        ],
        "version": 14
    },
    "massage": {
        "id": "massage",
        "name": "Face Massage",
        "keywords": [
            "person",
            "getting",
            "relax",
            "人",
            "取得",
            "リラックス"
        ],
        "skins": [
            {
                "unified": "1f486",
                "native": "💆"
            },
            {
                "unified": "1f486-1f3fb",
                "native": "💆🏻"
            },
            {
                "unified": "1f486-1f3fc",
                "native": "💆🏼"
            },
            {
                "unified": "1f486-1f3fd",
                "native": "💆🏽"
            },
            {
                "unified": "1f486-1f3fe",
                "native": "💆🏾"
            },
            {
                "unified": "1f486-1f3ff",
                "native": "💆🏿"
            }
        ],
        "version": 1
    },
    "man-getting-massage": {
        "id": "man-getting-massage",
        "name": "Man Getting Massage",
        "keywords": [
            "getting-massage",
            "male",
            "boy",
            "head",
            "取得マッサージ",
            "男",
            "男の子",
            "頭"
        ],
        "skins": [
            {
                "unified": "1f486-200d-2642-fe0f",
                "native": "💆‍♂️"
            },
            {
                "unified": "1f486-1f3fb-200d-2642-fe0f",
                "native": "💆🏻‍♂️"
            },
            {
                "unified": "1f486-1f3fc-200d-2642-fe0f",
                "native": "💆🏼‍♂️"
            },
            {
                "unified": "1f486-1f3fd-200d-2642-fe0f",
                "native": "💆🏽‍♂️"
            },
            {
                "unified": "1f486-1f3fe-200d-2642-fe0f",
                "native": "💆🏾‍♂️"
            },
            {
                "unified": "1f486-1f3ff-200d-2642-fe0f",
                "native": "💆🏿‍♂️"
            }
        ],
        "version": 4
    },
    "woman-getting-massage": {
        "id": "woman-getting-massage",
        "name": "Woman Getting Massage",
        "keywords": [
            "getting-massage",
            "female",
            "girl",
            "head",
            "取得マッサージ",
            "女性",
            "女の子",
            "頭"
        ],
        "skins": [
            {
                "unified": "1f486-200d-2640-fe0f",
                "native": "💆‍♀️"
            },
            {
                "unified": "1f486-1f3fb-200d-2640-fe0f",
                "native": "💆🏻‍♀️"
            },
            {
                "unified": "1f486-1f3fc-200d-2640-fe0f",
                "native": "💆🏼‍♀️"
            },
            {
                "unified": "1f486-1f3fd-200d-2640-fe0f",
                "native": "💆🏽‍♀️"
            },
            {
                "unified": "1f486-1f3fe-200d-2640-fe0f",
                "native": "💆🏾‍♀️"
            },
            {
                "unified": "1f486-1f3ff-200d-2640-fe0f",
                "native": "💆🏿‍♀️"
            }
        ],
        "version": 4
    },
    "haircut": {
        "id": "haircut",
        "name": "Haircut",
        "keywords": [
            "person",
            "getting",
            "hairstyle",
            "人",
            "取得",
            "髪型"
        ],
        "skins": [
            {
                "unified": "1f487",
                "native": "💇"
            },
            {
                "unified": "1f487-1f3fb",
                "native": "💇🏻"
            },
            {
                "unified": "1f487-1f3fc",
                "native": "💇🏼"
            },
            {
                "unified": "1f487-1f3fd",
                "native": "💇🏽"
            },
            {
                "unified": "1f487-1f3fe",
                "native": "💇🏾"
            },
            {
                "unified": "1f487-1f3ff",
                "native": "💇🏿"
            }
        ],
        "version": 1
    },
    "man-getting-haircut": {
        "id": "man-getting-haircut",
        "name": "Man Getting Haircut",
        "keywords": [
            "getting-haircut",
            "male",
            "boy",
            "ヘアカット",
            "男",
            "男の子"
        ],
        "skins": [
            {
                "unified": "1f487-200d-2642-fe0f",
                "native": "💇‍♂️"
            },
            {
                "unified": "1f487-1f3fb-200d-2642-fe0f",
                "native": "💇🏻‍♂️"
            },
            {
                "unified": "1f487-1f3fc-200d-2642-fe0f",
                "native": "💇🏼‍♂️"
            },
            {
                "unified": "1f487-1f3fd-200d-2642-fe0f",
                "native": "💇🏽‍♂️"
            },
            {
                "unified": "1f487-1f3fe-200d-2642-fe0f",
                "native": "💇🏾‍♂️"
            },
            {
                "unified": "1f487-1f3ff-200d-2642-fe0f",
                "native": "💇🏿‍♂️"
            }
        ],
        "version": 4
    },
    "woman-getting-haircut": {
        "id": "woman-getting-haircut",
        "name": "Woman Getting Haircut",
        "keywords": [
            "getting-haircut",
            "female",
            "girl",
            "ヘアカット",
            "女性",
            "女の子"
        ],
        "skins": [
            {
                "unified": "1f487-200d-2640-fe0f",
                "native": "💇‍♀️"
            },
            {
                "unified": "1f487-1f3fb-200d-2640-fe0f",
                "native": "💇🏻‍♀️"
            },
            {
                "unified": "1f487-1f3fc-200d-2640-fe0f",
                "native": "💇🏼‍♀️"
            },
            {
                "unified": "1f487-1f3fd-200d-2640-fe0f",
                "native": "💇🏽‍♀️"
            },
            {
                "unified": "1f487-1f3fe-200d-2640-fe0f",
                "native": "💇🏾‍♀️"
            },
            {
                "unified": "1f487-1f3ff-200d-2640-fe0f",
                "native": "💇🏿‍♀️"
            }
        ],
        "version": 4
    },
    "walking": {
        "id": "walking",
        "name": "Pedestrian",
        "keywords": [
            "walking",
            "person",
            "move",
            "歩く",
            "人",
            "動く"
        ],
        "skins": [
            {
                "unified": "1f6b6",
                "native": "🚶"
            },
            {
                "unified": "1f6b6-1f3fb",
                "native": "🚶🏻"
            },
            {
                "unified": "1f6b6-1f3fc",
                "native": "🚶🏼"
            },
            {
                "unified": "1f6b6-1f3fd",
                "native": "🚶🏽"
            },
            {
                "unified": "1f6b6-1f3fe",
                "native": "🚶🏾"
            },
            {
                "unified": "1f6b6-1f3ff",
                "native": "🚶🏿"
            }
        ],
        "version": 1
    },
    "man-walking": {
        "id": "man-walking",
        "name": "Man Walking",
        "keywords": [
            "human",
            "feet",
            "steps",
            "人間",
            "フィート",
            "ステップ"
        ],
        "skins": [
            {
                "unified": "1f6b6-200d-2642-fe0f",
                "native": "🚶‍♂️"
            },
            {
                "unified": "1f6b6-1f3fb-200d-2642-fe0f",
                "native": "🚶🏻‍♂️"
            },
            {
                "unified": "1f6b6-1f3fc-200d-2642-fe0f",
                "native": "🚶🏼‍♂️"
            },
            {
                "unified": "1f6b6-1f3fd-200d-2642-fe0f",
                "native": "🚶🏽‍♂️"
            },
            {
                "unified": "1f6b6-1f3fe-200d-2642-fe0f",
                "native": "🚶🏾‍♂️"
            },
            {
                "unified": "1f6b6-1f3ff-200d-2642-fe0f",
                "native": "🚶🏿‍♂️"
            }
        ],
        "version": 4
    },
    "woman-walking": {
        "id": "woman-walking",
        "name": "Woman Walking",
        "keywords": [
            "human",
            "feet",
            "steps",
            "female",
            "人間",
            "フィート",
            "ステップ",
            "女性"
        ],
        "skins": [
            {
                "unified": "1f6b6-200d-2640-fe0f",
                "native": "🚶‍♀️"
            },
            {
                "unified": "1f6b6-1f3fb-200d-2640-fe0f",
                "native": "🚶🏻‍♀️"
            },
            {
                "unified": "1f6b6-1f3fc-200d-2640-fe0f",
                "native": "🚶🏼‍♀️"
            },
            {
                "unified": "1f6b6-1f3fd-200d-2640-fe0f",
                "native": "🚶🏽‍♀️"
            },
            {
                "unified": "1f6b6-1f3fe-200d-2640-fe0f",
                "native": "🚶🏾‍♀️"
            },
            {
                "unified": "1f6b6-1f3ff-200d-2640-fe0f",
                "native": "🚶🏿‍♀️"
            }
        ],
        "version": 4
    },
    "standing_person": {
        "id": "standing_person",
        "name": "Standing Person",
        "keywords": [
            "still",
            "まだ"
        ],
        "skins": [
            {
                "unified": "1f9cd",
                "native": "🧍"
            },
            {
                "unified": "1f9cd-1f3fb",
                "native": "🧍🏻"
            },
            {
                "unified": "1f9cd-1f3fc",
                "native": "🧍🏼"
            },
            {
                "unified": "1f9cd-1f3fd",
                "native": "🧍🏽"
            },
            {
                "unified": "1f9cd-1f3fe",
                "native": "🧍🏾"
            },
            {
                "unified": "1f9cd-1f3ff",
                "native": "🧍🏿"
            }
        ],
        "version": 12
    },
    "man_standing": {
        "id": "man_standing",
        "name": "Man Standing",
        "keywords": [
            "still",
            "まだ"
        ],
        "skins": [
            {
                "unified": "1f9cd-200d-2642-fe0f",
                "native": "🧍‍♂️"
            },
            {
                "unified": "1f9cd-1f3fb-200d-2642-fe0f",
                "native": "🧍🏻‍♂️"
            },
            {
                "unified": "1f9cd-1f3fc-200d-2642-fe0f",
                "native": "🧍🏼‍♂️"
            },
            {
                "unified": "1f9cd-1f3fd-200d-2642-fe0f",
                "native": "🧍🏽‍♂️"
            },
            {
                "unified": "1f9cd-1f3fe-200d-2642-fe0f",
                "native": "🧍🏾‍♂️"
            },
            {
                "unified": "1f9cd-1f3ff-200d-2642-fe0f",
                "native": "🧍🏿‍♂️"
            }
        ],
        "version": 12
    },
    "woman_standing": {
        "id": "woman_standing",
        "name": "Woman Standing",
        "keywords": [
            "still",
            "まだ"
        ],
        "skins": [
            {
                "unified": "1f9cd-200d-2640-fe0f",
                "native": "🧍‍♀️"
            },
            {
                "unified": "1f9cd-1f3fb-200d-2640-fe0f",
                "native": "🧍🏻‍♀️"
            },
            {
                "unified": "1f9cd-1f3fc-200d-2640-fe0f",
                "native": "🧍🏼‍♀️"
            },
            {
                "unified": "1f9cd-1f3fd-200d-2640-fe0f",
                "native": "🧍🏽‍♀️"
            },
            {
                "unified": "1f9cd-1f3fe-200d-2640-fe0f",
                "native": "🧍🏾‍♀️"
            },
            {
                "unified": "1f9cd-1f3ff-200d-2640-fe0f",
                "native": "🧍🏿‍♀️"
            }
        ],
        "version": 12
    },
    "kneeling_person": {
        "id": "kneeling_person",
        "name": "Kneeling Person",
        "keywords": [
            "pray",
            "respectful",
            "祈る",
            "敬意を表する"
        ],
        "skins": [
            {
                "unified": "1f9ce",
                "native": "🧎"
            },
            {
                "unified": "1f9ce-1f3fb",
                "native": "🧎🏻"
            },
            {
                "unified": "1f9ce-1f3fc",
                "native": "🧎🏼"
            },
            {
                "unified": "1f9ce-1f3fd",
                "native": "🧎🏽"
            },
            {
                "unified": "1f9ce-1f3fe",
                "native": "🧎🏾"
            },
            {
                "unified": "1f9ce-1f3ff",
                "native": "🧎🏿"
            }
        ],
        "version": 12
    },
    "man_kneeling": {
        "id": "man_kneeling",
        "name": "Man Kneeling",
        "keywords": [
            "pray",
            "respectful",
            "祈る",
            "敬意を表する"
        ],
        "skins": [
            {
                "unified": "1f9ce-200d-2642-fe0f",
                "native": "🧎‍♂️"
            },
            {
                "unified": "1f9ce-1f3fb-200d-2642-fe0f",
                "native": "🧎🏻‍♂️"
            },
            {
                "unified": "1f9ce-1f3fc-200d-2642-fe0f",
                "native": "🧎🏼‍♂️"
            },
            {
                "unified": "1f9ce-1f3fd-200d-2642-fe0f",
                "native": "🧎🏽‍♂️"
            },
            {
                "unified": "1f9ce-1f3fe-200d-2642-fe0f",
                "native": "🧎🏾‍♂️"
            },
            {
                "unified": "1f9ce-1f3ff-200d-2642-fe0f",
                "native": "🧎🏿‍♂️"
            }
        ],
        "version": 12
    },
    "woman_kneeling": {
        "id": "woman_kneeling",
        "name": "Woman Kneeling",
        "keywords": [
            "respectful",
            "pray",
            "敬意を表する",
            "祈る"
        ],
        "skins": [
            {
                "unified": "1f9ce-200d-2640-fe0f",
                "native": "🧎‍♀️"
            },
            {
                "unified": "1f9ce-1f3fb-200d-2640-fe0f",
                "native": "🧎🏻‍♀️"
            },
            {
                "unified": "1f9ce-1f3fc-200d-2640-fe0f",
                "native": "🧎🏼‍♀️"
            },
            {
                "unified": "1f9ce-1f3fd-200d-2640-fe0f",
                "native": "🧎🏽‍♀️"
            },
            {
                "unified": "1f9ce-1f3fe-200d-2640-fe0f",
                "native": "🧎🏾‍♀️"
            },
            {
                "unified": "1f9ce-1f3ff-200d-2640-fe0f",
                "native": "🧎🏿‍♀️"
            }
        ],
        "version": 12
    },
    "person_with_probing_cane": {
        "id": "person_with_probing_cane",
        "name": "Person with White Cane",
        "keywords": [
            "probing",
            "blind",
            "プロービング",
            "盲目"
        ],
        "skins": [
            {
                "unified": "1f9d1-200d-1f9af",
                "native": "🧑‍🦯"
            },
            {
                "unified": "1f9d1-1f3fb-200d-1f9af",
                "native": "🧑🏻‍🦯"
            },
            {
                "unified": "1f9d1-1f3fc-200d-1f9af",
                "native": "🧑🏼‍🦯"
            },
            {
                "unified": "1f9d1-1f3fd-200d-1f9af",
                "native": "🧑🏽‍🦯"
            },
            {
                "unified": "1f9d1-1f3fe-200d-1f9af",
                "native": "🧑🏾‍🦯"
            },
            {
                "unified": "1f9d1-1f3ff-200d-1f9af",
                "native": "🧑🏿‍🦯"
            }
        ],
        "version": 12.1
    },
    "man_with_probing_cane": {
        "id": "man_with_probing_cane",
        "name": "Man with White Cane",
        "keywords": [
            "probing",
            "blind",
            "プロービング",
            "盲目"
        ],
        "skins": [
            {
                "unified": "1f468-200d-1f9af",
                "native": "👨‍🦯"
            },
            {
                "unified": "1f468-1f3fb-200d-1f9af",
                "native": "👨🏻‍🦯"
            },
            {
                "unified": "1f468-1f3fc-200d-1f9af",
                "native": "👨🏼‍🦯"
            },
            {
                "unified": "1f468-1f3fd-200d-1f9af",
                "native": "👨🏽‍🦯"
            },
            {
                "unified": "1f468-1f3fe-200d-1f9af",
                "native": "👨🏾‍🦯"
            },
            {
                "unified": "1f468-1f3ff-200d-1f9af",
                "native": "👨🏿‍🦯"
            }
        ],
        "version": 12
    },
    "woman_with_probing_cane": {
        "id": "woman_with_probing_cane",
        "name": "Woman with White Cane",
        "keywords": [
            "probing",
            "blind",
            "プロービング",
            "盲目"
        ],
        "skins": [
            {
                "unified": "1f469-200d-1f9af",
                "native": "👩‍🦯"
            },
            {
                "unified": "1f469-1f3fb-200d-1f9af",
                "native": "👩🏻‍🦯"
            },
            {
                "unified": "1f469-1f3fc-200d-1f9af",
                "native": "👩🏼‍🦯"
            },
            {
                "unified": "1f469-1f3fd-200d-1f9af",
                "native": "👩🏽‍🦯"
            },
            {
                "unified": "1f469-1f3fe-200d-1f9af",
                "native": "👩🏾‍🦯"
            },
            {
                "unified": "1f469-1f3ff-200d-1f9af",
                "native": "👩🏿‍🦯"
            }
        ],
        "version": 12
    },
    "person_in_motorized_wheelchair": {
        "id": "person_in_motorized_wheelchair",
        "name": "Person in Motorized Wheelchair",
        "keywords": [
            "disability",
            "accessibility",
            "障害",
            "アクセシビリティ"
        ],
        "skins": [
            {
                "unified": "1f9d1-200d-1f9bc",
                "native": "🧑‍🦼"
            },
            {
                "unified": "1f9d1-1f3fb-200d-1f9bc",
                "native": "🧑🏻‍🦼"
            },
            {
                "unified": "1f9d1-1f3fc-200d-1f9bc",
                "native": "🧑🏼‍🦼"
            },
            {
                "unified": "1f9d1-1f3fd-200d-1f9bc",
                "native": "🧑🏽‍🦼"
            },
            {
                "unified": "1f9d1-1f3fe-200d-1f9bc",
                "native": "🧑🏾‍🦼"
            },
            {
                "unified": "1f9d1-1f3ff-200d-1f9bc",
                "native": "🧑🏿‍🦼"
            }
        ],
        "version": 12.1
    },
    "man_in_motorized_wheelchair": {
        "id": "man_in_motorized_wheelchair",
        "name": "Man in Motorized Wheelchair",
        "keywords": [
            "disability",
            "accessibility",
            "障害",
            "アクセシビリティ"
        ],
        "skins": [
            {
                "unified": "1f468-200d-1f9bc",
                "native": "👨‍🦼"
            },
            {
                "unified": "1f468-1f3fb-200d-1f9bc",
                "native": "👨🏻‍🦼"
            },
            {
                "unified": "1f468-1f3fc-200d-1f9bc",
                "native": "👨🏼‍🦼"
            },
            {
                "unified": "1f468-1f3fd-200d-1f9bc",
                "native": "👨🏽‍🦼"
            },
            {
                "unified": "1f468-1f3fe-200d-1f9bc",
                "native": "👨🏾‍🦼"
            },
            {
                "unified": "1f468-1f3ff-200d-1f9bc",
                "native": "👨🏿‍🦼"
            }
        ],
        "version": 12
    },
    "woman_in_motorized_wheelchair": {
        "id": "woman_in_motorized_wheelchair",
        "name": "Woman in Motorized Wheelchair",
        "keywords": [
            "disability",
            "accessibility",
            "障害",
            "アクセシビリティ"
        ],
        "skins": [
            {
                "unified": "1f469-200d-1f9bc",
                "native": "👩‍🦼"
            },
            {
                "unified": "1f469-1f3fb-200d-1f9bc",
                "native": "👩🏻‍🦼"
            },
            {
                "unified": "1f469-1f3fc-200d-1f9bc",
                "native": "👩🏼‍🦼"
            },
            {
                "unified": "1f469-1f3fd-200d-1f9bc",
                "native": "👩🏽‍🦼"
            },
            {
                "unified": "1f469-1f3fe-200d-1f9bc",
                "native": "👩🏾‍🦼"
            },
            {
                "unified": "1f469-1f3ff-200d-1f9bc",
                "native": "👩🏿‍🦼"
            }
        ],
        "version": 12
    },
    "person_in_manual_wheelchair": {
        "id": "person_in_manual_wheelchair",
        "name": "Person in Manual Wheelchair",
        "keywords": [
            "disability",
            "accessibility",
            "障害",
            "アクセシビリティ"
        ],
        "skins": [
            {
                "unified": "1f9d1-200d-1f9bd",
                "native": "🧑‍🦽"
            },
            {
                "unified": "1f9d1-1f3fb-200d-1f9bd",
                "native": "🧑🏻‍🦽"
            },
            {
                "unified": "1f9d1-1f3fc-200d-1f9bd",
                "native": "🧑🏼‍🦽"
            },
            {
                "unified": "1f9d1-1f3fd-200d-1f9bd",
                "native": "🧑🏽‍🦽"
            },
            {
                "unified": "1f9d1-1f3fe-200d-1f9bd",
                "native": "🧑🏾‍🦽"
            },
            {
                "unified": "1f9d1-1f3ff-200d-1f9bd",
                "native": "🧑🏿‍🦽"
            }
        ],
        "version": 12.1
    },
    "man_in_manual_wheelchair": {
        "id": "man_in_manual_wheelchair",
        "name": "Man in Manual Wheelchair",
        "keywords": [
            "disability",
            "accessibility",
            "障害",
            "アクセシビリティ"
        ],
        "skins": [
            {
                "unified": "1f468-200d-1f9bd",
                "native": "👨‍🦽"
            },
            {
                "unified": "1f468-1f3fb-200d-1f9bd",
                "native": "👨🏻‍🦽"
            },
            {
                "unified": "1f468-1f3fc-200d-1f9bd",
                "native": "👨🏼‍🦽"
            },
            {
                "unified": "1f468-1f3fd-200d-1f9bd",
                "native": "👨🏽‍🦽"
            },
            {
                "unified": "1f468-1f3fe-200d-1f9bd",
                "native": "👨🏾‍🦽"
            },
            {
                "unified": "1f468-1f3ff-200d-1f9bd",
                "native": "👨🏿‍🦽"
            }
        ],
        "version": 12
    },
    "woman_in_manual_wheelchair": {
        "id": "woman_in_manual_wheelchair",
        "name": "Woman in Manual Wheelchair",
        "keywords": [
            "disability",
            "accessibility",
            "障害",
            "アクセシビリティ"
        ],
        "skins": [
            {
                "unified": "1f469-200d-1f9bd",
                "native": "👩‍🦽"
            },
            {
                "unified": "1f469-1f3fb-200d-1f9bd",
                "native": "👩🏻‍🦽"
            },
            {
                "unified": "1f469-1f3fc-200d-1f9bd",
                "native": "👩🏼‍🦽"
            },
            {
                "unified": "1f469-1f3fd-200d-1f9bd",
                "native": "👩🏽‍🦽"
            },
            {
                "unified": "1f469-1f3fe-200d-1f9bd",
                "native": "👩🏾‍🦽"
            },
            {
                "unified": "1f469-1f3ff-200d-1f9bd",
                "native": "👩🏿‍🦽"
            }
        ],
        "version": 12
    },
    "runner": {
        "id": "runner",
        "name": "Runner",
        "keywords": [
            "running",
            "person",
            "move",
            "ランニング",
            "人",
            "動く"
        ],
        "skins": [
            {
                "unified": "1f3c3",
                "native": "🏃"
            },
            {
                "unified": "1f3c3-1f3fb",
                "native": "🏃🏻"
            },
            {
                "unified": "1f3c3-1f3fc",
                "native": "🏃🏼"
            },
            {
                "unified": "1f3c3-1f3fd",
                "native": "🏃🏽"
            },
            {
                "unified": "1f3c3-1f3fe",
                "native": "🏃🏾"
            },
            {
                "unified": "1f3c3-1f3ff",
                "native": "🏃🏿"
            }
        ],
        "version": 1
    },
    "man-running": {
        "id": "man-running",
        "name": "Man Running",
        "keywords": [
            "walking",
            "exercise",
            "race",
            "歩く",
            "エクササイズ",
            "人種"
        ],
        "skins": [
            {
                "unified": "1f3c3-200d-2642-fe0f",
                "native": "🏃‍♂️"
            },
            {
                "unified": "1f3c3-1f3fb-200d-2642-fe0f",
                "native": "🏃🏻‍♂️"
            },
            {
                "unified": "1f3c3-1f3fc-200d-2642-fe0f",
                "native": "🏃🏼‍♂️"
            },
            {
                "unified": "1f3c3-1f3fd-200d-2642-fe0f",
                "native": "🏃🏽‍♂️"
            },
            {
                "unified": "1f3c3-1f3fe-200d-2642-fe0f",
                "native": "🏃🏾‍♂️"
            },
            {
                "unified": "1f3c3-1f3ff-200d-2642-fe0f",
                "native": "🏃🏿‍♂️"
            }
        ],
        "version": 4
    },
    "woman-running": {
        "id": "woman-running",
        "name": "Woman Running",
        "keywords": [
            "walking",
            "exercise",
            "race",
            "female",
            "歩く",
            "エクササイズ",
            "人種",
            "女性"
        ],
        "skins": [
            {
                "unified": "1f3c3-200d-2640-fe0f",
                "native": "🏃‍♀️"
            },
            {
                "unified": "1f3c3-1f3fb-200d-2640-fe0f",
                "native": "🏃🏻‍♀️"
            },
            {
                "unified": "1f3c3-1f3fc-200d-2640-fe0f",
                "native": "🏃🏼‍♀️"
            },
            {
                "unified": "1f3c3-1f3fd-200d-2640-fe0f",
                "native": "🏃🏽‍♀️"
            },
            {
                "unified": "1f3c3-1f3fe-200d-2640-fe0f",
                "native": "🏃🏾‍♀️"
            },
            {
                "unified": "1f3c3-1f3ff-200d-2640-fe0f",
                "native": "🏃🏿‍♀️"
            }
        ],
        "version": 4
    },
    "dancer": {
        "id": "dancer",
        "name": "Dancer",
        "keywords": [
            "woman",
            "dancing",
            "female",
            "girl",
            "fun",
            "女性",
            "ダンシング",
            "女性",
            "女の子",
            "楽しい"
        ],
        "skins": [
            {
                "unified": "1f483",
                "native": "💃"
            },
            {
                "unified": "1f483-1f3fb",
                "native": "💃🏻"
            },
            {
                "unified": "1f483-1f3fc",
                "native": "💃🏼"
            },
            {
                "unified": "1f483-1f3fd",
                "native": "💃🏽"
            },
            {
                "unified": "1f483-1f3fe",
                "native": "💃🏾"
            },
            {
                "unified": "1f483-1f3ff",
                "native": "💃🏿"
            }
        ],
        "version": 1
    },
    "man_dancing": {
        "id": "man_dancing",
        "name": "Man Dancing",
        "keywords": [
            "male",
            "boy",
            "fun",
            "dancer",
            "男",
            "男の子",
            "楽しい",
            "踊り子"
        ],
        "skins": [
            {
                "unified": "1f57a",
                "native": "🕺"
            },
            {
                "unified": "1f57a-1f3fb",
                "native": "🕺🏻"
            },
            {
                "unified": "1f57a-1f3fc",
                "native": "🕺🏼"
            },
            {
                "unified": "1f57a-1f3fd",
                "native": "🕺🏽"
            },
            {
                "unified": "1f57a-1f3fe",
                "native": "🕺🏾"
            },
            {
                "unified": "1f57a-1f3ff",
                "native": "🕺🏿"
            }
        ],
        "version": 3
    },
    "man_in_business_suit_levitating": {
        "id": "man_in_business_suit_levitating",
        "name": "Person in Suit Levitating",
        "keywords": [
            "man",
            "business",
            "levitate",
            "hover",
            "jump",
            "男",
            "仕事",
            "浮揚",
            "ホバー",
            "ジャンプ"
        ],
        "skins": [
            {
                "unified": "1f574-fe0f",
                "native": "🕴️"
            },
            {
                "unified": "1f574-1f3fb",
                "native": "🕴🏻"
            },
            {
                "unified": "1f574-1f3fc",
                "native": "🕴🏼"
            },
            {
                "unified": "1f574-1f3fd",
                "native": "🕴🏽"
            },
            {
                "unified": "1f574-1f3fe",
                "native": "🕴🏾"
            },
            {
                "unified": "1f574-1f3ff",
                "native": "🕴🏿"
            }
        ],
        "version": 1
    },
    "dancers": {
        "id": "dancers",
        "name": "Woman with Bunny Ears",
        "keywords": [
            "dancers",
            "people",
            "perform",
            "costume",
            "ダンサー",
            "人々",
            "実行",
            "コスチューム"
        ],
        "skins": [
            {
                "unified": "1f46f",
                "native": "👯"
            }
        ],
        "version": 1
    },
    "men-with-bunny-ears-partying": {
        "id": "men-with-bunny-ears-partying",
        "name": "Men with Bunny Ears",
        "keywords": [
            "with-bunny-ears-partying",
            "man",
            "male",
            "boys",
            "バニーイヤーパーティー",
            "男",
            "男",
            "男の子"
        ],
        "skins": [
            {
                "unified": "1f46f-200d-2642-fe0f",
                "native": "👯‍♂️"
            }
        ],
        "version": 4
    },
    "women-with-bunny-ears-partying": {
        "id": "women-with-bunny-ears-partying",
        "name": "Women with Bunny Ears",
        "keywords": [
            "with-bunny-ears-partying",
            "woman",
            "female",
            "girls",
            "バニーイヤーパーティー",
            "女性",
            "女性",
            "女の子"
        ],
        "skins": [
            {
                "unified": "1f46f-200d-2640-fe0f",
                "native": "👯‍♀️"
            }
        ],
        "version": 4
    },
    "person_in_steamy_room": {
        "id": "person_in_steamy_room",
        "name": "Person in Steamy Room",
        "keywords": [
            "relax",
            "spa",
            "リラックス",
            "スパ"
        ],
        "skins": [
            {
                "unified": "1f9d6",
                "native": "🧖"
            },
            {
                "unified": "1f9d6-1f3fb",
                "native": "🧖🏻"
            },
            {
                "unified": "1f9d6-1f3fc",
                "native": "🧖🏼"
            },
            {
                "unified": "1f9d6-1f3fd",
                "native": "🧖🏽"
            },
            {
                "unified": "1f9d6-1f3fe",
                "native": "🧖🏾"
            },
            {
                "unified": "1f9d6-1f3ff",
                "native": "🧖🏿"
            }
        ],
        "version": 5
    },
    "man_in_steamy_room": {
        "id": "man_in_steamy_room",
        "name": "Man in Steamy Room",
        "keywords": [
            "male",
            "spa",
            "steamroom",
            "sauna",
            "男",
            "スパ",
            "サウナ",
            "サウナ"
        ],
        "skins": [
            {
                "unified": "1f9d6-200d-2642-fe0f",
                "native": "🧖‍♂️"
            },
            {
                "unified": "1f9d6-1f3fb-200d-2642-fe0f",
                "native": "🧖🏻‍♂️"
            },
            {
                "unified": "1f9d6-1f3fc-200d-2642-fe0f",
                "native": "🧖🏼‍♂️"
            },
            {
                "unified": "1f9d6-1f3fd-200d-2642-fe0f",
                "native": "🧖🏽‍♂️"
            },
            {
                "unified": "1f9d6-1f3fe-200d-2642-fe0f",
                "native": "🧖🏾‍♂️"
            },
            {
                "unified": "1f9d6-1f3ff-200d-2642-fe0f",
                "native": "🧖🏿‍♂️"
            }
        ],
        "version": 5
    },
    "woman_in_steamy_room": {
        "id": "woman_in_steamy_room",
        "name": "Woman in Steamy Room",
        "keywords": [
            "female",
            "spa",
            "steamroom",
            "sauna",
            "女性",
            "スパ",
            "サウナ",
            "サウナ"
        ],
        "skins": [
            {
                "unified": "1f9d6-200d-2640-fe0f",
                "native": "🧖‍♀️"
            },
            {
                "unified": "1f9d6-1f3fb-200d-2640-fe0f",
                "native": "🧖🏻‍♀️"
            },
            {
                "unified": "1f9d6-1f3fc-200d-2640-fe0f",
                "native": "🧖🏼‍♀️"
            },
            {
                "unified": "1f9d6-1f3fd-200d-2640-fe0f",
                "native": "🧖🏽‍♀️"
            },
            {
                "unified": "1f9d6-1f3fe-200d-2640-fe0f",
                "native": "🧖🏾‍♀️"
            },
            {
                "unified": "1f9d6-1f3ff-200d-2640-fe0f",
                "native": "🧖🏿‍♀️"
            }
        ],
        "version": 5
    },
    "person_climbing": {
        "id": "person_climbing",
        "name": "Person Climbing",
        "keywords": [
            "sport",
            "スポーツ"
        ],
        "skins": [
            {
                "unified": "1f9d7",
                "native": "🧗"
            },
            {
                "unified": "1f9d7-1f3fb",
                "native": "🧗🏻"
            },
            {
                "unified": "1f9d7-1f3fc",
                "native": "🧗🏼"
            },
            {
                "unified": "1f9d7-1f3fd",
                "native": "🧗🏽"
            },
            {
                "unified": "1f9d7-1f3fe",
                "native": "🧗🏾"
            },
            {
                "unified": "1f9d7-1f3ff",
                "native": "🧗🏿"
            }
        ],
        "version": 5
    },
    "man_climbing": {
        "id": "man_climbing",
        "name": "Man Climbing",
        "keywords": [
            "sports",
            "hobby",
            "male",
            "rock",
            "スポーツ",
            "趣味",
            "男",
            "石"
        ],
        "skins": [
            {
                "unified": "1f9d7-200d-2642-fe0f",
                "native": "🧗‍♂️"
            },
            {
                "unified": "1f9d7-1f3fb-200d-2642-fe0f",
                "native": "🧗🏻‍♂️"
            },
            {
                "unified": "1f9d7-1f3fc-200d-2642-fe0f",
                "native": "🧗🏼‍♂️"
            },
            {
                "unified": "1f9d7-1f3fd-200d-2642-fe0f",
                "native": "🧗🏽‍♂️"
            },
            {
                "unified": "1f9d7-1f3fe-200d-2642-fe0f",
                "native": "🧗🏾‍♂️"
            },
            {
                "unified": "1f9d7-1f3ff-200d-2642-fe0f",
                "native": "🧗🏿‍♂️"
            }
        ],
        "version": 5
    },
    "woman_climbing": {
        "id": "woman_climbing",
        "name": "Woman Climbing",
        "keywords": [
            "sports",
            "hobby",
            "female",
            "rock",
            "スポーツ",
            "趣味",
            "女性",
            "石"
        ],
        "skins": [
            {
                "unified": "1f9d7-200d-2640-fe0f",
                "native": "🧗‍♀️"
            },
            {
                "unified": "1f9d7-1f3fb-200d-2640-fe0f",
                "native": "🧗🏻‍♀️"
            },
            {
                "unified": "1f9d7-1f3fc-200d-2640-fe0f",
                "native": "🧗🏼‍♀️"
            },
            {
                "unified": "1f9d7-1f3fd-200d-2640-fe0f",
                "native": "🧗🏽‍♀️"
            },
            {
                "unified": "1f9d7-1f3fe-200d-2640-fe0f",
                "native": "🧗🏾‍♀️"
            },
            {
                "unified": "1f9d7-1f3ff-200d-2640-fe0f",
                "native": "🧗🏿‍♀️"
            }
        ],
        "version": 5
    },
    "fencer": {
        "id": "fencer",
        "name": "Fencer",
        "keywords": [
            "person",
            "fencing",
            "sports",
            "sword",
            "人",
            "フェンシング",
            "スポーツ",
            "剣"
        ],
        "skins": [
            {
                "unified": "1f93a",
                "native": "🤺"
            }
        ],
        "version": 3
    },
    "horse_racing": {
        "id": "horse_racing",
        "name": "Horse Racing",
        "keywords": [
            "animal",
            "betting",
            "competition",
            "gambling",
            "luck",
            "動物",
            "賭け",
            "コンペ",
            "ギャンブル",
            "ラック"
        ],
        "skins": [
            {
                "unified": "1f3c7",
                "native": "🏇"
            },
            {
                "unified": "1f3c7-1f3fb",
                "native": "🏇🏻"
            },
            {
                "unified": "1f3c7-1f3fc",
                "native": "🏇🏼"
            },
            {
                "unified": "1f3c7-1f3fd",
                "native": "🏇🏽"
            },
            {
                "unified": "1f3c7-1f3fe",
                "native": "🏇🏾"
            },
            {
                "unified": "1f3c7-1f3ff",
                "native": "🏇🏿"
            }
        ],
        "version": 1
    },
    "skier": {
        "id": "skier",
        "name": "Skier",
        "keywords": [
            "sports",
            "winter",
            "snow",
            "スポーツ",
            "冬",
            "雪"
        ],
        "skins": [
            {
                "unified": "26f7-fe0f",
                "native": "⛷️"
            }
        ],
        "version": 1
    },
    "snowboarder": {
        "id": "snowboarder",
        "name": "Snowboarder",
        "keywords": [
            "sports",
            "winter",
            "スポーツ",
            "冬"
        ],
        "skins": [
            {
                "unified": "1f3c2",
                "native": "🏂"
            },
            {
                "unified": "1f3c2-1f3fb",
                "native": "🏂🏻"
            },
            {
                "unified": "1f3c2-1f3fc",
                "native": "🏂🏼"
            },
            {
                "unified": "1f3c2-1f3fd",
                "native": "🏂🏽"
            },
            {
                "unified": "1f3c2-1f3fe",
                "native": "🏂🏾"
            },
            {
                "unified": "1f3c2-1f3ff",
                "native": "🏂🏿"
            }
        ],
        "version": 1
    },
    "golfer": {
        "id": "golfer",
        "name": "Person Golfing",
        "keywords": [
            "golfer",
            "sports",
            "business",
            "ゴルファー",
            "スポーツ",
            "仕事"
        ],
        "skins": [
            {
                "unified": "1f3cc-fe0f",
                "native": "🏌️"
            },
            {
                "unified": "1f3cc-1f3fb",
                "native": "🏌🏻"
            },
            {
                "unified": "1f3cc-1f3fc",
                "native": "🏌🏼"
            },
            {
                "unified": "1f3cc-1f3fd",
                "native": "🏌🏽"
            },
            {
                "unified": "1f3cc-1f3fe",
                "native": "🏌🏾"
            },
            {
                "unified": "1f3cc-1f3ff",
                "native": "🏌🏿"
            }
        ],
        "version": 1
    },
    "man-golfing": {
        "id": "man-golfing",
        "name": "Man Golfing",
        "keywords": [
            "sport",
            "スポーツ"
        ],
        "skins": [
            {
                "unified": "1f3cc-fe0f-200d-2642-fe0f",
                "native": "🏌️‍♂️"
            },
            {
                "unified": "1f3cc-1f3fb-200d-2642-fe0f",
                "native": "🏌🏻‍♂️"
            },
            {
                "unified": "1f3cc-1f3fc-200d-2642-fe0f",
                "native": "🏌🏼‍♂️"
            },
            {
                "unified": "1f3cc-1f3fd-200d-2642-fe0f",
                "native": "🏌🏽‍♂️"
            },
            {
                "unified": "1f3cc-1f3fe-200d-2642-fe0f",
                "native": "🏌🏾‍♂️"
            },
            {
                "unified": "1f3cc-1f3ff-200d-2642-fe0f",
                "native": "🏌🏿‍♂️"
            }
        ],
        "version": 4
    },
    "woman-golfing": {
        "id": "woman-golfing",
        "name": "Woman Golfing",
        "keywords": [
            "sports",
            "business",
            "female",
            "スポーツ",
            "仕事",
            "女性"
        ],
        "skins": [
            {
                "unified": "1f3cc-fe0f-200d-2640-fe0f",
                "native": "🏌️‍♀️"
            },
            {
                "unified": "1f3cc-1f3fb-200d-2640-fe0f",
                "native": "🏌🏻‍♀️"
            },
            {
                "unified": "1f3cc-1f3fc-200d-2640-fe0f",
                "native": "🏌🏼‍♀️"
            },
            {
                "unified": "1f3cc-1f3fd-200d-2640-fe0f",
                "native": "🏌🏽‍♀️"
            },
            {
                "unified": "1f3cc-1f3fe-200d-2640-fe0f",
                "native": "🏌🏾‍♀️"
            },
            {
                "unified": "1f3cc-1f3ff-200d-2640-fe0f",
                "native": "🏌🏿‍♀️"
            }
        ],
        "version": 4
    },
    "surfer": {
        "id": "surfer",
        "name": "Surfer",
        "keywords": [
            "person",
            "surfing",
            "sport",
            "sea",
            "人",
            "サーフィン",
            "スポーツ",
            "海"
        ],
        "skins": [
            {
                "unified": "1f3c4",
                "native": "🏄"
            },
            {
                "unified": "1f3c4-1f3fb",
                "native": "🏄🏻"
            },
            {
                "unified": "1f3c4-1f3fc",
                "native": "🏄🏼"
            },
            {
                "unified": "1f3c4-1f3fd",
                "native": "🏄🏽"
            },
            {
                "unified": "1f3c4-1f3fe",
                "native": "🏄🏾"
            },
            {
                "unified": "1f3c4-1f3ff",
                "native": "🏄🏿"
            }
        ],
        "version": 1
    },
    "man-surfing": {
        "id": "man-surfing",
        "name": "Man Surfing",
        "keywords": [
            "sports",
            "ocean",
            "sea",
            "summer",
            "beach",
            "スポーツ",
            "海洋",
            "海",
            "夏",
            "ビーチ"
        ],
        "skins": [
            {
                "unified": "1f3c4-200d-2642-fe0f",
                "native": "🏄‍♂️"
            },
            {
                "unified": "1f3c4-1f3fb-200d-2642-fe0f",
                "native": "🏄🏻‍♂️"
            },
            {
                "unified": "1f3c4-1f3fc-200d-2642-fe0f",
                "native": "🏄🏼‍♂️"
            },
            {
                "unified": "1f3c4-1f3fd-200d-2642-fe0f",
                "native": "🏄🏽‍♂️"
            },
            {
                "unified": "1f3c4-1f3fe-200d-2642-fe0f",
                "native": "🏄🏾‍♂️"
            },
            {
                "unified": "1f3c4-1f3ff-200d-2642-fe0f",
                "native": "🏄🏿‍♂️"
            }
        ],
        "version": 4
    },
    "woman-surfing": {
        "id": "woman-surfing",
        "name": "Woman Surfing",
        "keywords": [
            "sports",
            "ocean",
            "sea",
            "summer",
            "beach",
            "female",
            "スポーツ",
            "海洋",
            "海",
            "夏",
            "ビーチ",
            "女性"
        ],
        "skins": [
            {
                "unified": "1f3c4-200d-2640-fe0f",
                "native": "🏄‍♀️"
            },
            {
                "unified": "1f3c4-1f3fb-200d-2640-fe0f",
                "native": "🏄🏻‍♀️"
            },
            {
                "unified": "1f3c4-1f3fc-200d-2640-fe0f",
                "native": "🏄🏼‍♀️"
            },
            {
                "unified": "1f3c4-1f3fd-200d-2640-fe0f",
                "native": "🏄🏽‍♀️"
            },
            {
                "unified": "1f3c4-1f3fe-200d-2640-fe0f",
                "native": "🏄🏾‍♀️"
            },
            {
                "unified": "1f3c4-1f3ff-200d-2640-fe0f",
                "native": "🏄🏿‍♀️"
            }
        ],
        "version": 4
    },
    "rowboat": {
        "id": "rowboat",
        "name": "Rowboat",
        "keywords": [
            "person",
            "rowing",
            "boat",
            "sport",
            "move",
            "人",
            "ローイング",
            "ボート",
            "スポーツ",
            "動く"
        ],
        "skins": [
            {
                "unified": "1f6a3",
                "native": "🚣"
            },
            {
                "unified": "1f6a3-1f3fb",
                "native": "🚣🏻"
            },
            {
                "unified": "1f6a3-1f3fc",
                "native": "🚣🏼"
            },
            {
                "unified": "1f6a3-1f3fd",
                "native": "🚣🏽"
            },
            {
                "unified": "1f6a3-1f3fe",
                "native": "🚣🏾"
            },
            {
                "unified": "1f6a3-1f3ff",
                "native": "🚣🏿"
            }
        ],
        "version": 1
    },
    "man-rowing-boat": {
        "id": "man-rowing-boat",
        "name": "Man Rowing Boat",
        "keywords": [
            "rowing-boat",
            "sports",
            "hobby",
            "water",
            "ship",
            "手漕ぎボート",
            "スポーツ",
            "趣味",
            "水",
            "船"
        ],
        "skins": [
            {
                "unified": "1f6a3-200d-2642-fe0f",
                "native": "🚣‍♂️"
            },
            {
                "unified": "1f6a3-1f3fb-200d-2642-fe0f",
                "native": "🚣🏻‍♂️"
            },
            {
                "unified": "1f6a3-1f3fc-200d-2642-fe0f",
                "native": "🚣🏼‍♂️"
            },
            {
                "unified": "1f6a3-1f3fd-200d-2642-fe0f",
                "native": "🚣🏽‍♂️"
            },
            {
                "unified": "1f6a3-1f3fe-200d-2642-fe0f",
                "native": "🚣🏾‍♂️"
            },
            {
                "unified": "1f6a3-1f3ff-200d-2642-fe0f",
                "native": "🚣🏿‍♂️"
            }
        ],
        "version": 4
    },
    "woman-rowing-boat": {
        "id": "woman-rowing-boat",
        "name": "Woman Rowing Boat",
        "keywords": [
            "rowing-boat",
            "sports",
            "hobby",
            "water",
            "ship",
            "female",
            "手漕ぎボート",
            "スポーツ",
            "趣味",
            "水",
            "船",
            "女性"
        ],
        "skins": [
            {
                "unified": "1f6a3-200d-2640-fe0f",
                "native": "🚣‍♀️"
            },
            {
                "unified": "1f6a3-1f3fb-200d-2640-fe0f",
                "native": "🚣🏻‍♀️"
            },
            {
                "unified": "1f6a3-1f3fc-200d-2640-fe0f",
                "native": "🚣🏼‍♀️"
            },
            {
                "unified": "1f6a3-1f3fd-200d-2640-fe0f",
                "native": "🚣🏽‍♀️"
            },
            {
                "unified": "1f6a3-1f3fe-200d-2640-fe0f",
                "native": "🚣🏾‍♀️"
            },
            {
                "unified": "1f6a3-1f3ff-200d-2640-fe0f",
                "native": "🚣🏿‍♀️"
            }
        ],
        "version": 4
    },
    "swimmer": {
        "id": "swimmer",
        "name": "Swimmer",
        "keywords": [
            "person",
            "swimming",
            "sport",
            "pool",
            "人",
            "水泳",
            "スポーツ",
            "プール"
        ],
        "skins": [
            {
                "unified": "1f3ca",
                "native": "🏊"
            },
            {
                "unified": "1f3ca-1f3fb",
                "native": "🏊🏻"
            },
            {
                "unified": "1f3ca-1f3fc",
                "native": "🏊🏼"
            },
            {
                "unified": "1f3ca-1f3fd",
                "native": "🏊🏽"
            },
            {
                "unified": "1f3ca-1f3fe",
                "native": "🏊🏾"
            },
            {
                "unified": "1f3ca-1f3ff",
                "native": "🏊🏿"
            }
        ],
        "version": 1
    },
    "man-swimming": {
        "id": "man-swimming",
        "name": "Man Swimming",
        "keywords": [
            "sports",
            "exercise",
            "human",
            "athlete",
            "water",
            "summer",
            "スポーツ",
            "エクササイズ",
            "人間",
            "アスリート",
            "水",
            "夏"
        ],
        "skins": [
            {
                "unified": "1f3ca-200d-2642-fe0f",
                "native": "🏊‍♂️"
            },
            {
                "unified": "1f3ca-1f3fb-200d-2642-fe0f",
                "native": "🏊🏻‍♂️"
            },
            {
                "unified": "1f3ca-1f3fc-200d-2642-fe0f",
                "native": "🏊🏼‍♂️"
            },
            {
                "unified": "1f3ca-1f3fd-200d-2642-fe0f",
                "native": "🏊🏽‍♂️"
            },
            {
                "unified": "1f3ca-1f3fe-200d-2642-fe0f",
                "native": "🏊🏾‍♂️"
            },
            {
                "unified": "1f3ca-1f3ff-200d-2642-fe0f",
                "native": "🏊🏿‍♂️"
            }
        ],
        "version": 4
    },
    "woman-swimming": {
        "id": "woman-swimming",
        "name": "Woman Swimming",
        "keywords": [
            "sports",
            "exercise",
            "human",
            "athlete",
            "water",
            "summer",
            "female",
            "スポーツ",
            "エクササイズ",
            "人間",
            "アスリート",
            "水",
            "夏",
            "女性"
        ],
        "skins": [
            {
                "unified": "1f3ca-200d-2640-fe0f",
                "native": "🏊‍♀️"
            },
            {
                "unified": "1f3ca-1f3fb-200d-2640-fe0f",
                "native": "🏊🏻‍♀️"
            },
            {
                "unified": "1f3ca-1f3fc-200d-2640-fe0f",
                "native": "🏊🏼‍♀️"
            },
            {
                "unified": "1f3ca-1f3fd-200d-2640-fe0f",
                "native": "🏊🏽‍♀️"
            },
            {
                "unified": "1f3ca-1f3fe-200d-2640-fe0f",
                "native": "🏊🏾‍♀️"
            },
            {
                "unified": "1f3ca-1f3ff-200d-2640-fe0f",
                "native": "🏊🏿‍♀️"
            }
        ],
        "version": 4
    },
    "person_with_ball": {
        "id": "person_with_ball",
        "name": "Person Bouncing Ball",
        "keywords": [
            "with",
            "sports",
            "human",
            "と",
            "スポーツ",
            "人間"
        ],
        "skins": [
            {
                "unified": "26f9-fe0f",
                "native": "⛹️"
            },
            {
                "unified": "26f9-1f3fb",
                "native": "⛹🏻"
            },
            {
                "unified": "26f9-1f3fc",
                "native": "⛹🏼"
            },
            {
                "unified": "26f9-1f3fd",
                "native": "⛹🏽"
            },
            {
                "unified": "26f9-1f3fe",
                "native": "⛹🏾"
            },
            {
                "unified": "26f9-1f3ff",
                "native": "⛹🏿"
            }
        ],
        "version": 1
    },
    "man-bouncing-ball": {
        "id": "man-bouncing-ball",
        "name": "Man Bouncing Ball",
        "keywords": [
            "bouncing-ball",
            "sport",
            "弾むボール",
            "スポーツ"
        ],
        "skins": [
            {
                "unified": "26f9-fe0f-200d-2642-fe0f",
                "native": "⛹️‍♂️"
            },
            {
                "unified": "26f9-1f3fb-200d-2642-fe0f",
                "native": "⛹🏻‍♂️"
            },
            {
                "unified": "26f9-1f3fc-200d-2642-fe0f",
                "native": "⛹🏼‍♂️"
            },
            {
                "unified": "26f9-1f3fd-200d-2642-fe0f",
                "native": "⛹🏽‍♂️"
            },
            {
                "unified": "26f9-1f3fe-200d-2642-fe0f",
                "native": "⛹🏾‍♂️"
            },
            {
                "unified": "26f9-1f3ff-200d-2642-fe0f",
                "native": "⛹🏿‍♂️"
            }
        ],
        "version": 4
    },
    "woman-bouncing-ball": {
        "id": "woman-bouncing-ball",
        "name": "Woman Bouncing Ball",
        "keywords": [
            "bouncing-ball",
            "sports",
            "human",
            "female",
            "弾むボール",
            "スポーツ",
            "人間",
            "女性"
        ],
        "skins": [
            {
                "unified": "26f9-fe0f-200d-2640-fe0f",
                "native": "⛹️‍♀️"
            },
            {
                "unified": "26f9-1f3fb-200d-2640-fe0f",
                "native": "⛹🏻‍♀️"
            },
            {
                "unified": "26f9-1f3fc-200d-2640-fe0f",
                "native": "⛹🏼‍♀️"
            },
            {
                "unified": "26f9-1f3fd-200d-2640-fe0f",
                "native": "⛹🏽‍♀️"
            },
            {
                "unified": "26f9-1f3fe-200d-2640-fe0f",
                "native": "⛹🏾‍♀️"
            },
            {
                "unified": "26f9-1f3ff-200d-2640-fe0f",
                "native": "⛹🏿‍♀️"
            }
        ],
        "version": 4
    },
    "weight_lifter": {
        "id": "weight_lifter",
        "name": "Person Lifting Weights",
        "keywords": [
            "weight",
            "lifter",
            "sports",
            "training",
            "exercise",
            "重さ",
            "リフター",
            "スポーツ",
            "トレーニング",
            "エクササイズ"
        ],
        "skins": [
            {
                "unified": "1f3cb-fe0f",
                "native": "🏋️"
            },
            {
                "unified": "1f3cb-1f3fb",
                "native": "🏋🏻"
            },
            {
                "unified": "1f3cb-1f3fc",
                "native": "🏋🏼"
            },
            {
                "unified": "1f3cb-1f3fd",
                "native": "🏋🏽"
            },
            {
                "unified": "1f3cb-1f3fe",
                "native": "🏋🏾"
            },
            {
                "unified": "1f3cb-1f3ff",
                "native": "🏋🏿"
            }
        ],
        "version": 1
    },
    "man-lifting-weights": {
        "id": "man-lifting-weights",
        "name": "Man Lifting Weights",
        "keywords": [
            "lifting-weights",
            "sport",
            "重量挙げ",
            "スポーツ"
        ],
        "skins": [
            {
                "unified": "1f3cb-fe0f-200d-2642-fe0f",
                "native": "🏋️‍♂️"
            },
            {
                "unified": "1f3cb-1f3fb-200d-2642-fe0f",
                "native": "🏋🏻‍♂️"
            },
            {
                "unified": "1f3cb-1f3fc-200d-2642-fe0f",
                "native": "🏋🏼‍♂️"
            },
            {
                "unified": "1f3cb-1f3fd-200d-2642-fe0f",
                "native": "🏋🏽‍♂️"
            },
            {
                "unified": "1f3cb-1f3fe-200d-2642-fe0f",
                "native": "🏋🏾‍♂️"
            },
            {
                "unified": "1f3cb-1f3ff-200d-2642-fe0f",
                "native": "🏋🏿‍♂️"
            }
        ],
        "version": 4
    },
    "woman-lifting-weights": {
        "id": "woman-lifting-weights",
        "name": "Woman Lifting Weights",
        "keywords": [
            "lifting-weights",
            "sports",
            "training",
            "exercise",
            "female",
            "重量挙げ",
            "スポーツ",
            "トレーニング",
            "エクササイズ",
            "女性"
        ],
        "skins": [
            {
                "unified": "1f3cb-fe0f-200d-2640-fe0f",
                "native": "🏋️‍♀️"
            },
            {
                "unified": "1f3cb-1f3fb-200d-2640-fe0f",
                "native": "🏋🏻‍♀️"
            },
            {
                "unified": "1f3cb-1f3fc-200d-2640-fe0f",
                "native": "🏋🏼‍♀️"
            },
            {
                "unified": "1f3cb-1f3fd-200d-2640-fe0f",
                "native": "🏋🏽‍♀️"
            },
            {
                "unified": "1f3cb-1f3fe-200d-2640-fe0f",
                "native": "🏋🏾‍♀️"
            },
            {
                "unified": "1f3cb-1f3ff-200d-2640-fe0f",
                "native": "🏋🏿‍♀️"
            }
        ],
        "version": 4
    },
    "bicyclist": {
        "id": "bicyclist",
        "name": "Bicyclist",
        "keywords": [
            "person",
            "biking",
            "sport",
            "move",
            "人",
            "サイクリング",
            "スポーツ",
            "動く"
        ],
        "skins": [
            {
                "unified": "1f6b4",
                "native": "🚴"
            },
            {
                "unified": "1f6b4-1f3fb",
                "native": "🚴🏻"
            },
            {
                "unified": "1f6b4-1f3fc",
                "native": "🚴🏼"
            },
            {
                "unified": "1f6b4-1f3fd",
                "native": "🚴🏽"
            },
            {
                "unified": "1f6b4-1f3fe",
                "native": "🚴🏾"
            },
            {
                "unified": "1f6b4-1f3ff",
                "native": "🚴🏿"
            }
        ],
        "version": 1
    },
    "man-biking": {
        "id": "man-biking",
        "name": "Man Biking",
        "keywords": [
            "sports",
            "bike",
            "exercise",
            "hipster",
            "スポーツ",
            "自転車",
            "エクササイズ",
            "ヒップスター"
        ],
        "skins": [
            {
                "unified": "1f6b4-200d-2642-fe0f",
                "native": "🚴‍♂️"
            },
            {
                "unified": "1f6b4-1f3fb-200d-2642-fe0f",
                "native": "🚴🏻‍♂️"
            },
            {
                "unified": "1f6b4-1f3fc-200d-2642-fe0f",
                "native": "🚴🏼‍♂️"
            },
            {
                "unified": "1f6b4-1f3fd-200d-2642-fe0f",
                "native": "🚴🏽‍♂️"
            },
            {
                "unified": "1f6b4-1f3fe-200d-2642-fe0f",
                "native": "🚴🏾‍♂️"
            },
            {
                "unified": "1f6b4-1f3ff-200d-2642-fe0f",
                "native": "🚴🏿‍♂️"
            }
        ],
        "version": 4
    },
    "woman-biking": {
        "id": "woman-biking",
        "name": "Woman Biking",
        "keywords": [
            "sports",
            "bike",
            "exercise",
            "hipster",
            "female",
            "スポーツ",
            "自転車",
            "エクササイズ",
            "ヒップスター",
            "女性"
        ],
        "skins": [
            {
                "unified": "1f6b4-200d-2640-fe0f",
                "native": "🚴‍♀️"
            },
            {
                "unified": "1f6b4-1f3fb-200d-2640-fe0f",
                "native": "🚴🏻‍♀️"
            },
            {
                "unified": "1f6b4-1f3fc-200d-2640-fe0f",
                "native": "🚴🏼‍♀️"
            },
            {
                "unified": "1f6b4-1f3fd-200d-2640-fe0f",
                "native": "🚴🏽‍♀️"
            },
            {
                "unified": "1f6b4-1f3fe-200d-2640-fe0f",
                "native": "🚴🏾‍♀️"
            },
            {
                "unified": "1f6b4-1f3ff-200d-2640-fe0f",
                "native": "🚴🏿‍♀️"
            }
        ],
        "version": 4
    },
    "mountain_bicyclist": {
        "id": "mountain_bicyclist",
        "name": "Mountain Bicyclist",
        "keywords": [
            "person",
            "biking",
            "sport",
            "move",
            "人",
            "サイクリング",
            "スポーツ",
            "動く"
        ],
        "skins": [
            {
                "unified": "1f6b5",
                "native": "🚵"
            },
            {
                "unified": "1f6b5-1f3fb",
                "native": "🚵🏻"
            },
            {
                "unified": "1f6b5-1f3fc",
                "native": "🚵🏼"
            },
            {
                "unified": "1f6b5-1f3fd",
                "native": "🚵🏽"
            },
            {
                "unified": "1f6b5-1f3fe",
                "native": "🚵🏾"
            },
            {
                "unified": "1f6b5-1f3ff",
                "native": "🚵🏿"
            }
        ],
        "version": 1
    },
    "man-mountain-biking": {
        "id": "man-mountain-biking",
        "name": "Man Mountain Biking",
        "keywords": [
            "mountain-biking",
            "transportation",
            "sports",
            "human",
            "race",
            "bike",
            "マウンテンバイク",
            "交通機関",
            "スポーツ",
            "人間",
            "人種",
            "自転車"
        ],
        "skins": [
            {
                "unified": "1f6b5-200d-2642-fe0f",
                "native": "🚵‍♂️"
            },
            {
                "unified": "1f6b5-1f3fb-200d-2642-fe0f",
                "native": "🚵🏻‍♂️"
            },
            {
                "unified": "1f6b5-1f3fc-200d-2642-fe0f",
                "native": "🚵🏼‍♂️"
            },
            {
                "unified": "1f6b5-1f3fd-200d-2642-fe0f",
                "native": "🚵🏽‍♂️"
            },
            {
                "unified": "1f6b5-1f3fe-200d-2642-fe0f",
                "native": "🚵🏾‍♂️"
            },
            {
                "unified": "1f6b5-1f3ff-200d-2642-fe0f",
                "native": "🚵🏿‍♂️"
            }
        ],
        "version": 4
    },
    "woman-mountain-biking": {
        "id": "woman-mountain-biking",
        "name": "Woman Mountain Biking",
        "keywords": [
            "mountain-biking",
            "transportation",
            "sports",
            "human",
            "race",
            "bike",
            "female",
            "マウンテンバイク",
            "交通機関",
            "スポーツ",
            "人間",
            "人種",
            "自転車",
            "女性"
        ],
        "skins": [
            {
                "unified": "1f6b5-200d-2640-fe0f",
                "native": "🚵‍♀️"
            },
            {
                "unified": "1f6b5-1f3fb-200d-2640-fe0f",
                "native": "🚵🏻‍♀️"
            },
            {
                "unified": "1f6b5-1f3fc-200d-2640-fe0f",
                "native": "🚵🏼‍♀️"
            },
            {
                "unified": "1f6b5-1f3fd-200d-2640-fe0f",
                "native": "🚵🏽‍♀️"
            },
            {
                "unified": "1f6b5-1f3fe-200d-2640-fe0f",
                "native": "🚵🏾‍♀️"
            },
            {
                "unified": "1f6b5-1f3ff-200d-2640-fe0f",
                "native": "🚵🏿‍♀️"
            }
        ],
        "version": 4
    },
    "person_doing_cartwheel": {
        "id": "person_doing_cartwheel",
        "name": "Person Cartwheeling",
        "keywords": [
            "doing",
            "cartwheel",
            "sport",
            "gymnastic",
            "している",
            "側転",
            "スポーツ",
            "体操"
        ],
        "skins": [
            {
                "unified": "1f938",
                "native": "🤸"
            },
            {
                "unified": "1f938-1f3fb",
                "native": "🤸🏻"
            },
            {
                "unified": "1f938-1f3fc",
                "native": "🤸🏼"
            },
            {
                "unified": "1f938-1f3fd",
                "native": "🤸🏽"
            },
            {
                "unified": "1f938-1f3fe",
                "native": "🤸🏾"
            },
            {
                "unified": "1f938-1f3ff",
                "native": "🤸🏿"
            }
        ],
        "version": 3
    },
    "man-cartwheeling": {
        "id": "man-cartwheeling",
        "name": "Man Cartwheeling",
        "keywords": [
            "gymnastics",
            "体操"
        ],
        "skins": [
            {
                "unified": "1f938-200d-2642-fe0f",
                "native": "🤸‍♂️"
            },
            {
                "unified": "1f938-1f3fb-200d-2642-fe0f",
                "native": "🤸🏻‍♂️"
            },
            {
                "unified": "1f938-1f3fc-200d-2642-fe0f",
                "native": "🤸🏼‍♂️"
            },
            {
                "unified": "1f938-1f3fd-200d-2642-fe0f",
                "native": "🤸🏽‍♂️"
            },
            {
                "unified": "1f938-1f3fe-200d-2642-fe0f",
                "native": "🤸🏾‍♂️"
            },
            {
                "unified": "1f938-1f3ff-200d-2642-fe0f",
                "native": "🤸🏿‍♂️"
            }
        ],
        "version": 4
    },
    "woman-cartwheeling": {
        "id": "woman-cartwheeling",
        "name": "Woman Cartwheeling",
        "keywords": [
            "gymnastics",
            "体操"
        ],
        "skins": [
            {
                "unified": "1f938-200d-2640-fe0f",
                "native": "🤸‍♀️"
            },
            {
                "unified": "1f938-1f3fb-200d-2640-fe0f",
                "native": "🤸🏻‍♀️"
            },
            {
                "unified": "1f938-1f3fc-200d-2640-fe0f",
                "native": "🤸🏼‍♀️"
            },
            {
                "unified": "1f938-1f3fd-200d-2640-fe0f",
                "native": "🤸🏽‍♀️"
            },
            {
                "unified": "1f938-1f3fe-200d-2640-fe0f",
                "native": "🤸🏾‍♀️"
            },
            {
                "unified": "1f938-1f3ff-200d-2640-fe0f",
                "native": "🤸🏿‍♀️"
            }
        ],
        "version": 4
    },
    "wrestlers": {
        "id": "wrestlers",
        "name": "Wrestlers",
        "keywords": [
            "people",
            "wrestling",
            "sport",
            "人々",
            "レスリング",
            "スポーツ"
        ],
        "skins": [
            {
                "unified": "1f93c",
                "native": "🤼"
            }
        ],
        "version": 3
    },
    "man-wrestling": {
        "id": "man-wrestling",
        "name": "Men Wrestling",
        "keywords": [
            "man",
            "sports",
            "wrestlers",
            "男",
            "スポーツ",
            "力士"
        ],
        "skins": [
            {
                "unified": "1f93c-200d-2642-fe0f",
                "native": "🤼‍♂️"
            }
        ],
        "version": 4
    },
    "woman-wrestling": {
        "id": "woman-wrestling",
        "name": "Women Wrestling",
        "keywords": [
            "woman",
            "sports",
            "wrestlers",
            "女性",
            "スポーツ",
            "力士"
        ],
        "skins": [
            {
                "unified": "1f93c-200d-2640-fe0f",
                "native": "🤼‍♀️"
            }
        ],
        "version": 4
    },
    "water_polo": {
        "id": "water_polo",
        "name": "Water Polo",
        "keywords": [
            "person",
            "playing",
            "sport",
            "人",
            "遊ぶ",
            "スポーツ"
        ],
        "skins": [
            {
                "unified": "1f93d",
                "native": "🤽"
            },
            {
                "unified": "1f93d-1f3fb",
                "native": "🤽🏻"
            },
            {
                "unified": "1f93d-1f3fc",
                "native": "🤽🏼"
            },
            {
                "unified": "1f93d-1f3fd",
                "native": "🤽🏽"
            },
            {
                "unified": "1f93d-1f3fe",
                "native": "🤽🏾"
            },
            {
                "unified": "1f93d-1f3ff",
                "native": "🤽🏿"
            }
        ],
        "version": 3
    },
    "man-playing-water-polo": {
        "id": "man-playing-water-polo",
        "name": "Man Playing Water Polo",
        "keywords": [
            "playing-water-polo",
            "sports",
            "pool",
            "水球をする",
            "スポーツ",
            "プール"
        ],
        "skins": [
            {
                "unified": "1f93d-200d-2642-fe0f",
                "native": "🤽‍♂️"
            },
            {
                "unified": "1f93d-1f3fb-200d-2642-fe0f",
                "native": "🤽🏻‍♂️"
            },
            {
                "unified": "1f93d-1f3fc-200d-2642-fe0f",
                "native": "🤽🏼‍♂️"
            },
            {
                "unified": "1f93d-1f3fd-200d-2642-fe0f",
                "native": "🤽🏽‍♂️"
            },
            {
                "unified": "1f93d-1f3fe-200d-2642-fe0f",
                "native": "🤽🏾‍♂️"
            },
            {
                "unified": "1f93d-1f3ff-200d-2642-fe0f",
                "native": "🤽🏿‍♂️"
            }
        ],
        "version": 4
    },
    "woman-playing-water-polo": {
        "id": "woman-playing-water-polo",
        "name": "Woman Playing Water Polo",
        "keywords": [
            "playing-water-polo",
            "sports",
            "pool",
            "水球をする",
            "スポーツ",
            "プール"
        ],
        "skins": [
            {
                "unified": "1f93d-200d-2640-fe0f",
                "native": "🤽‍♀️"
            },
            {
                "unified": "1f93d-1f3fb-200d-2640-fe0f",
                "native": "🤽🏻‍♀️"
            },
            {
                "unified": "1f93d-1f3fc-200d-2640-fe0f",
                "native": "🤽🏼‍♀️"
            },
            {
                "unified": "1f93d-1f3fd-200d-2640-fe0f",
                "native": "🤽🏽‍♀️"
            },
            {
                "unified": "1f93d-1f3fe-200d-2640-fe0f",
                "native": "🤽🏾‍♀️"
            },
            {
                "unified": "1f93d-1f3ff-200d-2640-fe0f",
                "native": "🤽🏿‍♀️"
            }
        ],
        "version": 4
    },
    "handball": {
        "id": "handball",
        "name": "Handball",
        "keywords": [
            "person",
            "playing",
            "sport",
            "人",
            "遊ぶ",
            "スポーツ"
        ],
        "skins": [
            {
                "unified": "1f93e",
                "native": "🤾"
            },
            {
                "unified": "1f93e-1f3fb",
                "native": "🤾🏻"
            },
            {
                "unified": "1f93e-1f3fc",
                "native": "🤾🏼"
            },
            {
                "unified": "1f93e-1f3fd",
                "native": "🤾🏽"
            },
            {
                "unified": "1f93e-1f3fe",
                "native": "🤾🏾"
            },
            {
                "unified": "1f93e-1f3ff",
                "native": "🤾🏿"
            }
        ],
        "version": 3
    },
    "man-playing-handball": {
        "id": "man-playing-handball",
        "name": "Man Playing Handball",
        "keywords": [
            "playing-handball",
            "sports",
            "ハンドボールをする",
            "スポーツ"
        ],
        "skins": [
            {
                "unified": "1f93e-200d-2642-fe0f",
                "native": "🤾‍♂️"
            },
            {
                "unified": "1f93e-1f3fb-200d-2642-fe0f",
                "native": "🤾🏻‍♂️"
            },
            {
                "unified": "1f93e-1f3fc-200d-2642-fe0f",
                "native": "🤾🏼‍♂️"
            },
            {
                "unified": "1f93e-1f3fd-200d-2642-fe0f",
                "native": "🤾🏽‍♂️"
            },
            {
                "unified": "1f93e-1f3fe-200d-2642-fe0f",
                "native": "🤾🏾‍♂️"
            },
            {
                "unified": "1f93e-1f3ff-200d-2642-fe0f",
                "native": "🤾🏿‍♂️"
            }
        ],
        "version": 4
    },
    "woman-playing-handball": {
        "id": "woman-playing-handball",
        "name": "Woman Playing Handball",
        "keywords": [
            "playing-handball",
            "sports",
            "ハンドボールをする",
            "スポーツ"
        ],
        "skins": [
            {
                "unified": "1f93e-200d-2640-fe0f",
                "native": "🤾‍♀️"
            },
            {
                "unified": "1f93e-1f3fb-200d-2640-fe0f",
                "native": "🤾🏻‍♀️"
            },
            {
                "unified": "1f93e-1f3fc-200d-2640-fe0f",
                "native": "🤾🏼‍♀️"
            },
            {
                "unified": "1f93e-1f3fd-200d-2640-fe0f",
                "native": "🤾🏽‍♀️"
            },
            {
                "unified": "1f93e-1f3fe-200d-2640-fe0f",
                "native": "🤾🏾‍♀️"
            },
            {
                "unified": "1f93e-1f3ff-200d-2640-fe0f",
                "native": "🤾🏿‍♀️"
            }
        ],
        "version": 4
    },
    "juggling": {
        "id": "juggling",
        "name": "Juggling",
        "keywords": [
            "person",
            "performance",
            "balance",
            "人",
            "パフォーマンス",
            "バランス"
        ],
        "skins": [
            {
                "unified": "1f939",
                "native": "🤹"
            },
            {
                "unified": "1f939-1f3fb",
                "native": "🤹🏻"
            },
            {
                "unified": "1f939-1f3fc",
                "native": "🤹🏼"
            },
            {
                "unified": "1f939-1f3fd",
                "native": "🤹🏽"
            },
            {
                "unified": "1f939-1f3fe",
                "native": "🤹🏾"
            },
            {
                "unified": "1f939-1f3ff",
                "native": "🤹🏿"
            }
        ],
        "version": 3
    },
    "man-juggling": {
        "id": "man-juggling",
        "name": "Man Juggling",
        "keywords": [
            "juggle",
            "balance",
            "skill",
            "multitask",
            "ジャグリング",
            "バランス",
            "スキル",
            "マルチタスク"
        ],
        "skins": [
            {
                "unified": "1f939-200d-2642-fe0f",
                "native": "🤹‍♂️"
            },
            {
                "unified": "1f939-1f3fb-200d-2642-fe0f",
                "native": "🤹🏻‍♂️"
            },
            {
                "unified": "1f939-1f3fc-200d-2642-fe0f",
                "native": "🤹🏼‍♂️"
            },
            {
                "unified": "1f939-1f3fd-200d-2642-fe0f",
                "native": "🤹🏽‍♂️"
            },
            {
                "unified": "1f939-1f3fe-200d-2642-fe0f",
                "native": "🤹🏾‍♂️"
            },
            {
                "unified": "1f939-1f3ff-200d-2642-fe0f",
                "native": "🤹🏿‍♂️"
            }
        ],
        "version": 4
    },
    "woman-juggling": {
        "id": "woman-juggling",
        "name": "Woman Juggling",
        "keywords": [
            "juggle",
            "balance",
            "skill",
            "multitask",
            "ジャグリング",
            "バランス",
            "スキル",
            "マルチタスク"
        ],
        "skins": [
            {
                "unified": "1f939-200d-2640-fe0f",
                "native": "🤹‍♀️"
            },
            {
                "unified": "1f939-1f3fb-200d-2640-fe0f",
                "native": "🤹🏻‍♀️"
            },
            {
                "unified": "1f939-1f3fc-200d-2640-fe0f",
                "native": "🤹🏼‍♀️"
            },
            {
                "unified": "1f939-1f3fd-200d-2640-fe0f",
                "native": "🤹🏽‍♀️"
            },
            {
                "unified": "1f939-1f3fe-200d-2640-fe0f",
                "native": "🤹🏾‍♀️"
            },
            {
                "unified": "1f939-1f3ff-200d-2640-fe0f",
                "native": "🤹🏿‍♀️"
            }
        ],
        "version": 4
    },
    "person_in_lotus_position": {
        "id": "person_in_lotus_position",
        "name": "Person in Lotus Position",
        "keywords": [
            "meditate",
            "瞑想する"
        ],
        "skins": [
            {
                "unified": "1f9d8",
                "native": "🧘"
            },
            {
                "unified": "1f9d8-1f3fb",
                "native": "🧘🏻"
            },
            {
                "unified": "1f9d8-1f3fc",
                "native": "🧘🏼"
            },
            {
                "unified": "1f9d8-1f3fd",
                "native": "🧘🏽"
            },
            {
                "unified": "1f9d8-1f3fe",
                "native": "🧘🏾"
            },
            {
                "unified": "1f9d8-1f3ff",
                "native": "🧘🏿"
            }
        ],
        "version": 5
    },
    "man_in_lotus_position": {
        "id": "man_in_lotus_position",
        "name": "Man in Lotus Position",
        "keywords": [
            "male",
            "meditation",
            "yoga",
            "serenity",
            "zen",
            "mindfulness",
            "男",
            "瞑想",
            "ヨガ",
            "静けさ",
            "禅",
            "マインドフルネス"
        ],
        "skins": [
            {
                "unified": "1f9d8-200d-2642-fe0f",
                "native": "🧘‍♂️"
            },
            {
                "unified": "1f9d8-1f3fb-200d-2642-fe0f",
                "native": "🧘🏻‍♂️"
            },
            {
                "unified": "1f9d8-1f3fc-200d-2642-fe0f",
                "native": "🧘🏼‍♂️"
            },
            {
                "unified": "1f9d8-1f3fd-200d-2642-fe0f",
                "native": "🧘🏽‍♂️"
            },
            {
                "unified": "1f9d8-1f3fe-200d-2642-fe0f",
                "native": "🧘🏾‍♂️"
            },
            {
                "unified": "1f9d8-1f3ff-200d-2642-fe0f",
                "native": "🧘🏿‍♂️"
            }
        ],
        "version": 5
    },
    "woman_in_lotus_position": {
        "id": "woman_in_lotus_position",
        "name": "Woman in Lotus Position",
        "keywords": [
            "female",
            "meditation",
            "yoga",
            "serenity",
            "zen",
            "mindfulness",
            "女性",
            "瞑想",
            "ヨガ",
            "静けさ",
            "禅",
            "マインドフルネス"
        ],
        "skins": [
            {
                "unified": "1f9d8-200d-2640-fe0f",
                "native": "🧘‍♀️"
            },
            {
                "unified": "1f9d8-1f3fb-200d-2640-fe0f",
                "native": "🧘🏻‍♀️"
            },
            {
                "unified": "1f9d8-1f3fc-200d-2640-fe0f",
                "native": "🧘🏼‍♀️"
            },
            {
                "unified": "1f9d8-1f3fd-200d-2640-fe0f",
                "native": "🧘🏽‍♀️"
            },
            {
                "unified": "1f9d8-1f3fe-200d-2640-fe0f",
                "native": "🧘🏾‍♀️"
            },
            {
                "unified": "1f9d8-1f3ff-200d-2640-fe0f",
                "native": "🧘🏿‍♀️"
            }
        ],
        "version": 5
    },
    "bath": {
        "id": "bath",
        "name": "Bath",
        "keywords": [
            "person",
            "taking",
            "clean",
            "shower",
            "bathroom",
            "人",
            "取る",
            "綺麗",
            "シャワー",
            "トイレ"
        ],
        "skins": [
            {
                "unified": "1f6c0",
                "native": "🛀"
            },
            {
                "unified": "1f6c0-1f3fb",
                "native": "🛀🏻"
            },
            {
                "unified": "1f6c0-1f3fc",
                "native": "🛀🏼"
            },
            {
                "unified": "1f6c0-1f3fd",
                "native": "🛀🏽"
            },
            {
                "unified": "1f6c0-1f3fe",
                "native": "🛀🏾"
            },
            {
                "unified": "1f6c0-1f3ff",
                "native": "🛀🏿"
            }
        ],
        "version": 1
    },
    "sleeping_accommodation": {
        "id": "sleeping_accommodation",
        "name": "Person in Bed",
        "keywords": [
            "sleeping",
            "accommodation",
            "rest",
            "睡眠",
            "宿泊施設",
            "休み"
        ],
        "skins": [
            {
                "unified": "1f6cc",
                "native": "🛌"
            },
            {
                "unified": "1f6cc-1f3fb",
                "native": "🛌🏻"
            },
            {
                "unified": "1f6cc-1f3fc",
                "native": "🛌🏼"
            },
            {
                "unified": "1f6cc-1f3fd",
                "native": "🛌🏽"
            },
            {
                "unified": "1f6cc-1f3fe",
                "native": "🛌🏾"
            },
            {
                "unified": "1f6cc-1f3ff",
                "native": "🛌🏿"
            }
        ],
        "version": 1
    },
    "people_holding_hands": {
        "id": "people_holding_hands",
        "name": "People Holding Hands",
        "keywords": [
            "friendship",
            "友情"
        ],
        "skins": [
            {
                "unified": "1f9d1-200d-1f91d-200d-1f9d1",
                "native": "🧑‍🤝‍🧑"
            },
            {
                "unified": "1f9d1-1f3fb-200d-1f91d-200d-1f9d1-1f3fb",
                "native": "🧑🏻‍🤝‍🧑🏻"
            },
            {
                "unified": "1f9d1-1f3fc-200d-1f91d-200d-1f9d1-1f3fc",
                "native": "🧑🏼‍🤝‍🧑🏼"
            },
            {
                "unified": "1f9d1-1f3fd-200d-1f91d-200d-1f9d1-1f3fd",
                "native": "🧑🏽‍🤝‍🧑🏽"
            },
            {
                "unified": "1f9d1-1f3fe-200d-1f91d-200d-1f9d1-1f3fe",
                "native": "🧑🏾‍🤝‍🧑🏾"
            },
            {
                "unified": "1f9d1-1f3ff-200d-1f91d-200d-1f9d1-1f3ff",
                "native": "🧑🏿‍🤝‍🧑🏿"
            }
        ],
        "version": 12
    },
    "two_women_holding_hands": {
        "id": "two_women_holding_hands",
        "name": "Women Holding Hands",
        "keywords": [
            "two",
            "pair",
            "friendship",
            "couple",
            "love",
            "like",
            "female",
            "people",
            "human",
            "二",
            "ペア",
            "友情",
            "カップル",
            "愛",
            "好き",
            "女性",
            "人々",
            "人間"
        ],
        "skins": [
            {
                "unified": "1f46d",
                "native": "👭"
            },
            {
                "unified": "1f46d-1f3fb",
                "native": "👭🏻"
            },
            {
                "unified": "1f46d-1f3fc",
                "native": "👭🏼"
            },
            {
                "unified": "1f46d-1f3fd",
                "native": "👭🏽"
            },
            {
                "unified": "1f46d-1f3fe",
                "native": "👭🏾"
            },
            {
                "unified": "1f46d-1f3ff",
                "native": "👭🏿"
            }
        ],
        "version": 1
    },
    "man_and_woman_holding_hands": {
        "id": "man_and_woman_holding_hands",
        "name": "Man and Woman Holding Hands",
        "keywords": [
            "couple",
            "pair",
            "people",
            "human",
            "love",
            "date",
            "dating",
            "like",
            "affection",
            "valentines",
            "marriage",
            "カップル",
            "ペア",
            "人々",
            "人間",
            "愛",
            "日にち",
            "デート",
            "好き",
            "愛情",
            "バレンタイン",
            "結婚"
        ],
        "skins": [
            {
                "unified": "1f46b",
                "native": "👫"
            },
            {
                "unified": "1f46b-1f3fb",
                "native": "👫🏻"
            },
            {
                "unified": "1f46b-1f3fc",
                "native": "👫🏼"
            },
            {
                "unified": "1f46b-1f3fd",
                "native": "👫🏽"
            },
            {
                "unified": "1f46b-1f3fe",
                "native": "👫🏾"
            },
            {
                "unified": "1f46b-1f3ff",
                "native": "👫🏿"
            }
        ],
        "version": 1
    },
    "two_men_holding_hands": {
        "id": "two_men_holding_hands",
        "name": "Men Holding Hands",
        "keywords": [
            "two",
            "pair",
            "couple",
            "love",
            "like",
            "bromance",
            "friendship",
            "people",
            "human",
            "二",
            "ペア",
            "カップル",
            "愛",
            "好き",
            "ブロマンス",
            "友情",
            "人々",
            "人間"
        ],
        "skins": [
            {
                "unified": "1f46c",
                "native": "👬"
            },
            {
                "unified": "1f46c-1f3fb",
                "native": "👬🏻"
            },
            {
                "unified": "1f46c-1f3fc",
                "native": "👬🏼"
            },
            {
                "unified": "1f46c-1f3fd",
                "native": "👬🏽"
            },
            {
                "unified": "1f46c-1f3fe",
                "native": "👬🏾"
            },
            {
                "unified": "1f46c-1f3ff",
                "native": "👬🏿"
            }
        ],
        "version": 1
    },
    "couplekiss": {
        "id": "couplekiss",
        "name": "Kiss",
        "keywords": [
            "couplekiss",
            "pair",
            "valentines",
            "love",
            "like",
            "dating",
            "marriage",
            "カップルキス",
            "ペア",
            "バレンタイン",
            "愛",
            "好き",
            "デート",
            "結婚"
        ],
        "skins": [
            {
                "unified": "1f48f",
                "native": "💏"
            },
            {
                "unified": "1f48f-1f3fb",
                "native": "💏🏻"
            },
            {
                "unified": "1f48f-1f3fc",
                "native": "💏🏼"
            },
            {
                "unified": "1f48f-1f3fd",
                "native": "💏🏽"
            },
            {
                "unified": "1f48f-1f3fe",
                "native": "💏🏾"
            },
            {
                "unified": "1f48f-1f3ff",
                "native": "💏🏿"
            }
        ],
        "version": 1
    },
    "woman-kiss-man": {
        "id": "woman-kiss-man",
        "name": "Kiss: Woman, Man",
        "keywords": [
            "woman",
            "kiss-man",
            "kiss",
            "love",
            "女性",
            "キスマン",
            "キス",
            "愛"
        ],
        "skins": [
            {
                "unified": "1f469-200d-2764-fe0f-200d-1f48b-200d-1f468",
                "native": "👩‍❤️‍💋‍👨"
            },
            {
                "unified": "1f469-1f3fb-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fb",
                "native": "👩🏻‍❤️‍💋‍👨🏻"
            },
            {
                "unified": "1f469-1f3fc-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fc",
                "native": "👩🏼‍❤️‍💋‍👨🏼"
            },
            {
                "unified": "1f469-1f3fd-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fd",
                "native": "👩🏽‍❤️‍💋‍👨🏽"
            },
            {
                "unified": "1f469-1f3fe-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fe",
                "native": "👩🏾‍❤️‍💋‍👨🏾"
            },
            {
                "unified": "1f469-1f3ff-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3ff",
                "native": "👩🏿‍❤️‍💋‍👨🏿"
            }
        ],
        "version": 2
    },
    "man-kiss-man": {
        "id": "man-kiss-man",
        "name": "Kiss: Man, Man",
        "keywords": [
            "kiss-man",
            "kiss",
            "pair",
            "valentines",
            "love",
            "like",
            "dating",
            "marriage",
            "キスマン",
            "キス",
            "ペア",
            "バレンタイン",
            "愛",
            "好き",
            "デート",
            "結婚"
        ],
        "skins": [
            {
                "unified": "1f468-200d-2764-fe0f-200d-1f48b-200d-1f468",
                "native": "👨‍❤️‍💋‍👨"
            },
            {
                "unified": "1f468-1f3fb-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fb",
                "native": "👨🏻‍❤️‍💋‍👨🏻"
            },
            {
                "unified": "1f468-1f3fc-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fc",
                "native": "👨🏼‍❤️‍💋‍👨🏼"
            },
            {
                "unified": "1f468-1f3fd-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fd",
                "native": "👨🏽‍❤️‍💋‍👨🏽"
            },
            {
                "unified": "1f468-1f3fe-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fe",
                "native": "👨🏾‍❤️‍💋‍👨🏾"
            },
            {
                "unified": "1f468-1f3ff-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3ff",
                "native": "👨🏿‍❤️‍💋‍👨🏿"
            }
        ],
        "version": 2
    },
    "woman-kiss-woman": {
        "id": "woman-kiss-woman",
        "name": "Kiss: Woman, Woman",
        "keywords": [
            "kiss-woman",
            "kiss",
            "pair",
            "valentines",
            "love",
            "like",
            "dating",
            "marriage",
            "キスウーマン",
            "キス",
            "ペア",
            "バレンタイン",
            "愛",
            "好き",
            "デート",
            "結婚"
        ],
        "skins": [
            {
                "unified": "1f469-200d-2764-fe0f-200d-1f48b-200d-1f469",
                "native": "👩‍❤️‍💋‍👩"
            },
            {
                "unified": "1f469-1f3fb-200d-2764-fe0f-200d-1f48b-200d-1f469-1f3fb",
                "native": "👩🏻‍❤️‍💋‍👩🏻"
            },
            {
                "unified": "1f469-1f3fc-200d-2764-fe0f-200d-1f48b-200d-1f469-1f3fc",
                "native": "👩🏼‍❤️‍💋‍👩🏼"
            },
            {
                "unified": "1f469-1f3fd-200d-2764-fe0f-200d-1f48b-200d-1f469-1f3fd",
                "native": "👩🏽‍❤️‍💋‍👩🏽"
            },
            {
                "unified": "1f469-1f3fe-200d-2764-fe0f-200d-1f48b-200d-1f469-1f3fe",
                "native": "👩🏾‍❤️‍💋‍👩🏾"
            },
            {
                "unified": "1f469-1f3ff-200d-2764-fe0f-200d-1f48b-200d-1f469-1f3ff",
                "native": "👩🏿‍❤️‍💋‍👩🏿"
            }
        ],
        "version": 2
    },
    "couple_with_heart": {
        "id": "couple_with_heart",
        "name": "Couple with Heart",
        "keywords": [
            "pair",
            "love",
            "like",
            "affection",
            "human",
            "dating",
            "valentines",
            "marriage",
            "ペア",
            "愛",
            "好き",
            "愛情",
            "人間",
            "デート",
            "バレンタイン",
            "結婚"
        ],
        "skins": [
            {
                "unified": "1f491",
                "native": "💑"
            },
            {
                "unified": "1f491-1f3fb",
                "native": "💑🏻"
            },
            {
                "unified": "1f491-1f3fc",
                "native": "💑🏼"
            },
            {
                "unified": "1f491-1f3fd",
                "native": "💑🏽"
            },
            {
                "unified": "1f491-1f3fe",
                "native": "💑🏾"
            },
            {
                "unified": "1f491-1f3ff",
                "native": "💑🏿"
            }
        ],
        "version": 1
    },
    "woman-heart-man": {
        "id": "woman-heart-man",
        "name": "Couple with Heart: Woman, Man",
        "keywords": [
            "woman",
            "heart-man",
            "heart",
            "love",
            "女性",
            "ハートマン",
            "心臓",
            "愛"
        ],
        "skins": [
            {
                "unified": "1f469-200d-2764-fe0f-200d-1f468",
                "native": "👩‍❤️‍👨"
            },
            {
                "unified": "1f469-1f3fb-200d-2764-fe0f-200d-1f468-1f3fb",
                "native": "👩🏻‍❤️‍👨🏻"
            },
            {
                "unified": "1f469-1f3fc-200d-2764-fe0f-200d-1f468-1f3fc",
                "native": "👩🏼‍❤️‍👨🏼"
            },
            {
                "unified": "1f469-1f3fd-200d-2764-fe0f-200d-1f468-1f3fd",
                "native": "👩🏽‍❤️‍👨🏽"
            },
            {
                "unified": "1f469-1f3fe-200d-2764-fe0f-200d-1f468-1f3fe",
                "native": "👩🏾‍❤️‍👨🏾"
            },
            {
                "unified": "1f469-1f3ff-200d-2764-fe0f-200d-1f468-1f3ff",
                "native": "👩🏿‍❤️‍👨🏿"
            }
        ],
        "version": 2
    },
    "man-heart-man": {
        "id": "man-heart-man",
        "name": "Couple with Heart: Man, Man",
        "keywords": [
            "heart-man",
            "heart",
            "pair",
            "love",
            "like",
            "affection",
            "human",
            "dating",
            "valentines",
            "marriage",
            "ハートマン",
            "心臓",
            "ペア",
            "愛",
            "好き",
            "愛情",
            "人間",
            "デート",
            "バレンタイン",
            "結婚"
        ],
        "skins": [
            {
                "unified": "1f468-200d-2764-fe0f-200d-1f468",
                "native": "👨‍❤️‍👨"
            },
            {
                "unified": "1f468-1f3fb-200d-2764-fe0f-200d-1f468-1f3fb",
                "native": "👨🏻‍❤️‍👨🏻"
            },
            {
                "unified": "1f468-1f3fc-200d-2764-fe0f-200d-1f468-1f3fc",
                "native": "👨🏼‍❤️‍👨🏼"
            },
            {
                "unified": "1f468-1f3fd-200d-2764-fe0f-200d-1f468-1f3fd",
                "native": "👨🏽‍❤️‍👨🏽"
            },
            {
                "unified": "1f468-1f3fe-200d-2764-fe0f-200d-1f468-1f3fe",
                "native": "👨🏾‍❤️‍👨🏾"
            },
            {
                "unified": "1f468-1f3ff-200d-2764-fe0f-200d-1f468-1f3ff",
                "native": "👨🏿‍❤️‍👨🏿"
            }
        ],
        "version": 2
    },
    "woman-heart-woman": {
        "id": "woman-heart-woman",
        "name": "Couple with Heart: Woman, Woman",
        "keywords": [
            "heart-woman",
            "heart",
            "pair",
            "love",
            "like",
            "affection",
            "human",
            "dating",
            "valentines",
            "marriage",
            "ハートウーマン",
            "心臓",
            "ペア",
            "愛",
            "好き",
            "愛情",
            "人間",
            "デート",
            "バレンタイン",
            "結婚"
        ],
        "skins": [
            {
                "unified": "1f469-200d-2764-fe0f-200d-1f469",
                "native": "👩‍❤️‍👩"
            },
            {
                "unified": "1f469-1f3fb-200d-2764-fe0f-200d-1f469-1f3fb",
                "native": "👩🏻‍❤️‍👩🏻"
            },
            {
                "unified": "1f469-1f3fc-200d-2764-fe0f-200d-1f469-1f3fc",
                "native": "👩🏼‍❤️‍👩🏼"
            },
            {
                "unified": "1f469-1f3fd-200d-2764-fe0f-200d-1f469-1f3fd",
                "native": "👩🏽‍❤️‍👩🏽"
            },
            {
                "unified": "1f469-1f3fe-200d-2764-fe0f-200d-1f469-1f3fe",
                "native": "👩🏾‍❤️‍👩🏾"
            },
            {
                "unified": "1f469-1f3ff-200d-2764-fe0f-200d-1f469-1f3ff",
                "native": "👩🏿‍❤️‍👩🏿"
            }
        ],
        "version": 2
    },
    "family": {
        "id": "family",
        "name": "Family",
        "keywords": [
            "home",
            "parents",
            "child",
            "mom",
            "dad",
            "father",
            "mother",
            "people",
            "human",
            "家",
            "両親",
            "子供",
            "お母さん",
            "お父さん",
            "父親",
            "母親",
            "人々",
            "人間"
        ],
        "skins": [
            {
                "unified": "1f46a",
                "native": "👪"
            }
        ],
        "version": 1
    },
    "man-woman-boy": {
        "id": "man-woman-boy",
        "name": "Family: Man, Woman, Boy",
        "keywords": [
            "man",
            "woman-boy",
            "family",
            "woman",
            "love",
            "男",
            "ウーマンボーイ",
            "家族",
            "女性",
            "愛"
        ],
        "skins": [
            {
                "unified": "1f468-200d-1f469-200d-1f466",
                "native": "👨‍👩‍👦"
            }
        ],
        "version": 2
    },
    "man-woman-girl": {
        "id": "man-woman-girl",
        "name": "Family: Man, Woman, Girl",
        "keywords": [
            "man",
            "woman-girl",
            "family",
            "woman",
            "home",
            "parents",
            "people",
            "human",
            "child",
            "男",
            "女性-女の子",
            "家族",
            "女性",
            "家",
            "両親",
            "人々",
            "人間",
            "子供"
        ],
        "skins": [
            {
                "unified": "1f468-200d-1f469-200d-1f467",
                "native": "👨‍👩‍👧"
            }
        ],
        "version": 2
    },
    "man-woman-girl-boy": {
        "id": "man-woman-girl-boy",
        "name": "Family: Man, Woman, Girl, Boy",
        "keywords": [
            "man",
            "woman-girl-boy",
            "family",
            "woman",
            "girl",
            "home",
            "parents",
            "people",
            "human",
            "children",
            "男",
            "ウーマン・ガール・ボーイ",
            "家族",
            "女性",
            "女の子",
            "家",
            "両親",
            "人々",
            "人間",
            "子供"
        ],
        "skins": [
            {
                "unified": "1f468-200d-1f469-200d-1f467-200d-1f466",
                "native": "👨‍👩‍👧‍👦"
            }
        ],
        "version": 2
    },
    "man-woman-boy-boy": {
        "id": "man-woman-boy-boy",
        "name": "Family: Man, Woman, Boy, Boy",
        "keywords": [
            "man",
            "woman-boy-boy",
            "family",
            "woman",
            "home",
            "parents",
            "people",
            "human",
            "children",
            "男",
            "ウーマン・ボーイ・ボーイ",
            "家族",
            "女性",
            "家",
            "両親",
            "人々",
            "人間",
            "子供"
        ],
        "skins": [
            {
                "unified": "1f468-200d-1f469-200d-1f466-200d-1f466",
                "native": "👨‍👩‍👦‍👦"
            }
        ],
        "version": 2
    },
    "man-woman-girl-girl": {
        "id": "man-woman-girl-girl",
        "name": "Family: Man, Woman, Girl, Girl",
        "keywords": [
            "man",
            "woman-girl-girl",
            "family",
            "woman",
            "home",
            "parents",
            "people",
            "human",
            "children",
            "男",
            "ウーマンガールガール",
            "家族",
            "女性",
            "家",
            "両親",
            "人々",
            "人間",
            "子供"
        ],
        "skins": [
            {
                "unified": "1f468-200d-1f469-200d-1f467-200d-1f467",
                "native": "👨‍👩‍👧‍👧"
            }
        ],
        "version": 2
    },
    "man-man-boy": {
        "id": "man-man-boy",
        "name": "Family: Man, Man, Boy",
        "keywords": [
            "man",
            "man-boy",
            "family",
            "home",
            "parents",
            "people",
            "human",
            "children",
            "男",
            "マンボーイ",
            "家族",
            "家",
            "両親",
            "人々",
            "人間",
            "子供"
        ],
        "skins": [
            {
                "unified": "1f468-200d-1f468-200d-1f466",
                "native": "👨‍👨‍👦"
            }
        ],
        "version": 2
    },
    "man-man-girl": {
        "id": "man-man-girl",
        "name": "Family: Man, Man, Girl",
        "keywords": [
            "man",
            "man-girl",
            "family",
            "home",
            "parents",
            "people",
            "human",
            "children",
            "男",
            "男-女",
            "家族",
            "家",
            "両親",
            "人々",
            "人間",
            "子供"
        ],
        "skins": [
            {
                "unified": "1f468-200d-1f468-200d-1f467",
                "native": "👨‍👨‍👧"
            }
        ],
        "version": 2
    },
    "man-man-girl-boy": {
        "id": "man-man-girl-boy",
        "name": "Family: Man, Man, Girl, Boy",
        "keywords": [
            "man",
            "man-girl-boy",
            "family",
            "girl",
            "home",
            "parents",
            "people",
            "human",
            "children",
            "男",
            "マンガールボーイ",
            "家族",
            "女の子",
            "家",
            "両親",
            "人々",
            "人間",
            "子供"
        ],
        "skins": [
            {
                "unified": "1f468-200d-1f468-200d-1f467-200d-1f466",
                "native": "👨‍👨‍👧‍👦"
            }
        ],
        "version": 2
    },
    "man-man-boy-boy": {
        "id": "man-man-boy-boy",
        "name": "Family: Man, Man, Boy, Boy",
        "keywords": [
            "man",
            "man-boy-boy",
            "family",
            "home",
            "parents",
            "people",
            "human",
            "children",
            "男",
            "マンボーイボーイ",
            "家族",
            "家",
            "両親",
            "人々",
            "人間",
            "子供"
        ],
        "skins": [
            {
                "unified": "1f468-200d-1f468-200d-1f466-200d-1f466",
                "native": "👨‍👨‍👦‍👦"
            }
        ],
        "version": 2
    },
    "man-man-girl-girl": {
        "id": "man-man-girl-girl",
        "name": "Family: Man, Man, Girl, Girl",
        "keywords": [
            "man",
            "man-girl-girl",
            "family",
            "home",
            "parents",
            "people",
            "human",
            "children",
            "男",
            "男-女-女",
            "家族",
            "家",
            "両親",
            "人々",
            "人間",
            "子供"
        ],
        "skins": [
            {
                "unified": "1f468-200d-1f468-200d-1f467-200d-1f467",
                "native": "👨‍👨‍👧‍👧"
            }
        ],
        "version": 2
    },
    "woman-woman-boy": {
        "id": "woman-woman-boy",
        "name": "Family: Woman, Woman, Boy",
        "keywords": [
            "woman",
            "woman-boy",
            "family",
            "home",
            "parents",
            "people",
            "human",
            "children",
            "女性",
            "ウーマンボーイ",
            "家族",
            "家",
            "両親",
            "人々",
            "人間",
            "子供"
        ],
        "skins": [
            {
                "unified": "1f469-200d-1f469-200d-1f466",
                "native": "👩‍👩‍👦"
            }
        ],
        "version": 2
    },
    "woman-woman-girl": {
        "id": "woman-woman-girl",
        "name": "Family: Woman, Woman, Girl",
        "keywords": [
            "woman",
            "woman-girl",
            "family",
            "home",
            "parents",
            "people",
            "human",
            "children",
            "女性",
            "女性-女の子",
            "家族",
            "家",
            "両親",
            "人々",
            "人間",
            "子供"
        ],
        "skins": [
            {
                "unified": "1f469-200d-1f469-200d-1f467",
                "native": "👩‍👩‍👧"
            }
        ],
        "version": 2
    },
    "woman-woman-girl-boy": {
        "id": "woman-woman-girl-boy",
        "name": "Family: Woman, Woman, Girl, Boy",
        "keywords": [
            "woman",
            "woman-girl-boy",
            "family",
            "girl",
            "home",
            "parents",
            "people",
            "human",
            "children",
            "女性",
            "ウーマン・ガール・ボーイ",
            "家族",
            "女の子",
            "家",
            "両親",
            "人々",
            "人間",
            "子供"
        ],
        "skins": [
            {
                "unified": "1f469-200d-1f469-200d-1f467-200d-1f466",
                "native": "👩‍👩‍👧‍👦"
            }
        ],
        "version": 2
    },
    "woman-woman-boy-boy": {
        "id": "woman-woman-boy-boy",
        "name": "Family: Woman, Woman, Boy, Boy",
        "keywords": [
            "woman",
            "woman-boy-boy",
            "family",
            "home",
            "parents",
            "people",
            "human",
            "children",
            "女性",
            "ウーマン・ボーイ・ボーイ",
            "家族",
            "家",
            "両親",
            "人々",
            "人間",
            "子供"
        ],
        "skins": [
            {
                "unified": "1f469-200d-1f469-200d-1f466-200d-1f466",
                "native": "👩‍👩‍👦‍👦"
            }
        ],
        "version": 2
    },
    "woman-woman-girl-girl": {
        "id": "woman-woman-girl-girl",
        "name": "Family: Woman, Woman, Girl, Girl",
        "keywords": [
            "woman",
            "woman-girl-girl",
            "family",
            "home",
            "parents",
            "people",
            "human",
            "children",
            "女性",
            "ウーマンガールガール",
            "家族",
            "家",
            "両親",
            "人々",
            "人間",
            "子供"
        ],
        "skins": [
            {
                "unified": "1f469-200d-1f469-200d-1f467-200d-1f467",
                "native": "👩‍👩‍👧‍👧"
            }
        ],
        "version": 2
    },
    "man-boy": {
        "id": "man-boy",
        "name": "Family: Man, Boy",
        "keywords": [
            "man",
            "family",
            "home",
            "parent",
            "people",
            "human",
            "child",
            "男",
            "家族",
            "家",
            "親",
            "人々",
            "人間",
            "子供"
        ],
        "skins": [
            {
                "unified": "1f468-200d-1f466",
                "native": "👨‍👦"
            }
        ],
        "version": 4
    },
    "man-boy-boy": {
        "id": "man-boy-boy",
        "name": "Family: Man, Boy, Boy",
        "keywords": [
            "man",
            "boy-boy",
            "family",
            "home",
            "parent",
            "people",
            "human",
            "children",
            "男",
            "ボーイボーイ",
            "家族",
            "家",
            "親",
            "人々",
            "人間",
            "子供"
        ],
        "skins": [
            {
                "unified": "1f468-200d-1f466-200d-1f466",
                "native": "👨‍👦‍👦"
            }
        ],
        "version": 4
    },
    "man-girl": {
        "id": "man-girl",
        "name": "Family: Man, Girl",
        "keywords": [
            "man",
            "family",
            "home",
            "parent",
            "people",
            "human",
            "child",
            "男",
            "家族",
            "家",
            "親",
            "人々",
            "人間",
            "子供"
        ],
        "skins": [
            {
                "unified": "1f468-200d-1f467",
                "native": "👨‍👧"
            }
        ],
        "version": 4
    },
    "man-girl-boy": {
        "id": "man-girl-boy",
        "name": "Family: Man, Girl, Boy",
        "keywords": [
            "man",
            "girl-boy",
            "family",
            "girl",
            "home",
            "parent",
            "people",
            "human",
            "children",
            "男",
            "ガールボーイ",
            "家族",
            "女の子",
            "家",
            "親",
            "人々",
            "人間",
            "子供"
        ],
        "skins": [
            {
                "unified": "1f468-200d-1f467-200d-1f466",
                "native": "👨‍👧‍👦"
            }
        ],
        "version": 4
    },
    "man-girl-girl": {
        "id": "man-girl-girl",
        "name": "Family: Man, Girl, Girl",
        "keywords": [
            "man",
            "girl-girl",
            "family",
            "home",
            "parent",
            "people",
            "human",
            "children",
            "男",
            "ガールガール",
            "家族",
            "家",
            "親",
            "人々",
            "人間",
            "子供"
        ],
        "skins": [
            {
                "unified": "1f468-200d-1f467-200d-1f467",
                "native": "👨‍👧‍👧"
            }
        ],
        "version": 4
    },
    "woman-boy": {
        "id": "woman-boy",
        "name": "Family: Woman, Boy",
        "keywords": [
            "woman",
            "family",
            "home",
            "parent",
            "people",
            "human",
            "child",
            "女性",
            "家族",
            "家",
            "親",
            "人々",
            "人間",
            "子供"
        ],
        "skins": [
            {
                "unified": "1f469-200d-1f466",
                "native": "👩‍👦"
            }
        ],
        "version": 4
    },
    "woman-boy-boy": {
        "id": "woman-boy-boy",
        "name": "Family: Woman, Boy, Boy",
        "keywords": [
            "woman",
            "boy-boy",
            "family",
            "home",
            "parent",
            "people",
            "human",
            "children",
            "女性",
            "ボーイボーイ",
            "家族",
            "家",
            "親",
            "人々",
            "人間",
            "子供"
        ],
        "skins": [
            {
                "unified": "1f469-200d-1f466-200d-1f466",
                "native": "👩‍👦‍👦"
            }
        ],
        "version": 4
    },
    "woman-girl": {
        "id": "woman-girl",
        "name": "Family: Woman, Girl",
        "keywords": [
            "woman",
            "family",
            "home",
            "parent",
            "people",
            "human",
            "child",
            "女性",
            "家族",
            "家",
            "親",
            "人々",
            "人間",
            "子供"
        ],
        "skins": [
            {
                "unified": "1f469-200d-1f467",
                "native": "👩‍👧"
            }
        ],
        "version": 4
    },
    "woman-girl-boy": {
        "id": "woman-girl-boy",
        "name": "Family: Woman, Girl, Boy",
        "keywords": [
            "woman",
            "girl-boy",
            "family",
            "girl",
            "home",
            "parent",
            "people",
            "human",
            "children",
            "女性",
            "ガールボーイ",
            "家族",
            "女の子",
            "家",
            "親",
            "人々",
            "人間",
            "子供"
        ],
        "skins": [
            {
                "unified": "1f469-200d-1f467-200d-1f466",
                "native": "👩‍👧‍👦"
            }
        ],
        "version": 4
    },
    "woman-girl-girl": {
        "id": "woman-girl-girl",
        "name": "Family: Woman, Girl, Girl",
        "keywords": [
            "woman",
            "girl-girl",
            "family",
            "home",
            "parent",
            "people",
            "human",
            "children",
            "女性",
            "ガールガール",
            "家族",
            "家",
            "親",
            "人々",
            "人間",
            "子供"
        ],
        "skins": [
            {
                "unified": "1f469-200d-1f467-200d-1f467",
                "native": "👩‍👧‍👧"
            }
        ],
        "version": 4
    },
    "speaking_head_in_silhouette": {
        "id": "speaking_head_in_silhouette",
        "name": "Speaking Head",
        "keywords": [
            "in",
            "silhouette",
            "user",
            "person",
            "human",
            "sing",
            "say",
            "talk",
            "の",
            "シルエット",
            "ユーザー",
            "人",
            "人間",
            "歌う",
            "言う",
            "話"
        ],
        "skins": [
            {
                "unified": "1f5e3-fe0f",
                "native": "🗣️"
            }
        ],
        "version": 1
    },
    "bust_in_silhouette": {
        "id": "bust_in_silhouette",
        "name": "Bust in Silhouette",
        "keywords": [
            "user",
            "person",
            "human",
            "ユーザー",
            "人",
            "人間"
        ],
        "skins": [
            {
                "unified": "1f464",
                "native": "👤"
            }
        ],
        "version": 1
    },
    "busts_in_silhouette": {
        "id": "busts_in_silhouette",
        "name": "Busts in Silhouette",
        "keywords": [
            "user",
            "person",
            "human",
            "group",
            "team",
            "ユーザー",
            "人",
            "人間",
            "グループ",
            "チーム"
        ],
        "skins": [
            {
                "unified": "1f465",
                "native": "👥"
            }
        ],
        "version": 1
    },
    "people_hugging": {
        "id": "people_hugging",
        "name": "People Hugging",
        "keywords": [
            "care",
            "お手入れ"
        ],
        "skins": [
            {
                "unified": "1fac2",
                "native": "🫂"
            }
        ],
        "version": 13
    },
    "footprints": {
        "id": "footprints",
        "name": "Footprints",
        "keywords": [
            "feet",
            "tracking",
            "walking",
            "beach",
            "フィート",
            "追跡",
            "歩く",
            "ビーチ"
        ],
        "skins": [
            {
                "unified": "1f463",
                "native": "👣"
            }
        ],
        "version": 1
    },
    "monkey_face": {
        "id": "monkey_face",
        "name": "Monkey Face",
        "emoticons": [
            ":o)"
        ],
        "keywords": [
            "animal",
            "nature",
            "circus",
            "動物",
            "自然",
            "サーカス"
        ],
        "skins": [
            {
                "unified": "1f435",
                "native": "🐵"
            }
        ],
        "version": 1
    },
    "monkey": {
        "id": "monkey",
        "name": "Monkey",
        "keywords": [
            "animal",
            "nature",
            "banana",
            "circus",
            "動物",
            "自然",
            "バナナ",
            "サーカス"
        ],
        "skins": [
            {
                "unified": "1f412",
                "native": "🐒"
            }
        ],
        "version": 1
    },
    "gorilla": {
        "id": "gorilla",
        "name": "Gorilla",
        "keywords": [
            "animal",
            "nature",
            "circus",
            "動物",
            "自然",
            "サーカス"
        ],
        "skins": [
            {
                "unified": "1f98d",
                "native": "🦍"
            }
        ],
        "version": 3
    },
    "orangutan": {
        "id": "orangutan",
        "name": "Orangutan",
        "keywords": [
            "animal",
            "動物"
        ],
        "skins": [
            {
                "unified": "1f9a7",
                "native": "🦧"
            }
        ],
        "version": 12
    },
    "dog": {
        "id": "dog",
        "name": "Dog Face",
        "keywords": [
            "animal",
            "friend",
            "nature",
            "woof",
            "puppy",
            "pet",
            "faithful",
            "動物",
            "友達",
            "自然",
            "横糸",
            "子犬",
            "ペット",
            "忠実な"
        ],
        "skins": [
            {
                "unified": "1f436",
                "native": "🐶"
            }
        ],
        "version": 1
    },
    "dog2": {
        "id": "dog2",
        "name": "Dog",
        "keywords": [
            "dog2",
            "animal",
            "nature",
            "friend",
            "doge",
            "pet",
            "faithful",
            "犬2",
            "動物",
            "自然",
            "友達",
            "ドージェ",
            "ペット",
            "忠実な"
        ],
        "skins": [
            {
                "unified": "1f415",
                "native": "🐕"
            }
        ],
        "version": 1
    },
    "guide_dog": {
        "id": "guide_dog",
        "name": "Guide Dog",
        "keywords": [
            "animal",
            "blind",
            "動物",
            "盲目"
        ],
        "skins": [
            {
                "unified": "1f9ae",
                "native": "🦮"
            }
        ],
        "version": 12
    },
    "service_dog": {
        "id": "service_dog",
        "name": "Service Dog",
        "keywords": [
            "blind",
            "animal",
            "盲目",
            "動物"
        ],
        "skins": [
            {
                "unified": "1f415-200d-1f9ba",
                "native": "🐕‍🦺"
            }
        ],
        "version": 12
    },
    "poodle": {
        "id": "poodle",
        "name": "Poodle",
        "keywords": [
            "dog",
            "animal",
            "101",
            "nature",
            "pet",
            "犬",
            "動物",
            "101",
            "自然",
            "ペット"
        ],
        "skins": [
            {
                "unified": "1f429",
                "native": "🐩"
            }
        ],
        "version": 1
    },
    "wolf": {
        "id": "wolf",
        "name": "Wolf",
        "keywords": [
            "animal",
            "nature",
            "wild",
            "動物",
            "自然",
            "野生"
        ],
        "skins": [
            {
                "unified": "1f43a",
                "native": "🐺"
            }
        ],
        "version": 1
    },
    "fox_face": {
        "id": "fox_face",
        "name": "Fox",
        "keywords": [
            "face",
            "animal",
            "nature",
            "顔",
            "動物",
            "自然"
        ],
        "skins": [
            {
                "unified": "1f98a",
                "native": "🦊"
            }
        ],
        "version": 3
    },
    "raccoon": {
        "id": "raccoon",
        "name": "Raccoon",
        "keywords": [
            "animal",
            "nature",
            "動物",
            "自然"
        ],
        "skins": [
            {
                "unified": "1f99d",
                "native": "🦝"
            }
        ],
        "version": 11
    },
    "cat": {
        "id": "cat",
        "name": "Cat Face",
        "keywords": [
            "animal",
            "meow",
            "nature",
            "pet",
            "kitten",
            "動物",
            "ニャー",
            "自然",
            "ペット",
            "子猫"
        ],
        "skins": [
            {
                "unified": "1f431",
                "native": "🐱"
            }
        ],
        "version": 1
    },
    "cat2": {
        "id": "cat2",
        "name": "Cat",
        "keywords": [
            "cat2",
            "animal",
            "meow",
            "pet",
            "cats",
            "猫2",
            "動物",
            "ニャー",
            "ペット",
            "猫"
        ],
        "skins": [
            {
                "unified": "1f408",
                "native": "🐈"
            }
        ],
        "version": 1
    },
    "black_cat": {
        "id": "black_cat",
        "name": "Black Cat",
        "keywords": [
            "superstition",
            "luck",
            "迷信",
            "ラック"
        ],
        "skins": [
            {
                "unified": "1f408-200d-2b1b",
                "native": "🐈‍⬛"
            }
        ],
        "version": 13
    },
    "lion_face": {
        "id": "lion_face",
        "name": "Lion",
        "keywords": [
            "face",
            "animal",
            "nature",
            "顔",
            "動物",
            "自然"
        ],
        "skins": [
            {
                "unified": "1f981",
                "native": "🦁"
            }
        ],
        "version": 1
    },
    "tiger": {
        "id": "tiger",
        "name": "Tiger Face",
        "keywords": [
            "animal",
            "cat",
            "danger",
            "wild",
            "nature",
            "roar",
            "動物",
            "猫",
            "危険",
            "野生",
            "自然",
            "轟音"
        ],
        "skins": [
            {
                "unified": "1f42f",
                "native": "🐯"
            }
        ],
        "version": 1
    },
    "tiger2": {
        "id": "tiger2",
        "name": "Tiger",
        "keywords": [
            "tiger2",
            "animal",
            "nature",
            "roar",
            "tiger2",
            "動物",
            "自然",
            "轟音"
        ],
        "skins": [
            {
                "unified": "1f405",
                "native": "🐅"
            }
        ],
        "version": 1
    },
    "leopard": {
        "id": "leopard",
        "name": "Leopard",
        "keywords": [
            "animal",
            "nature",
            "動物",
            "自然"
        ],
        "skins": [
            {
                "unified": "1f406",
                "native": "🐆"
            }
        ],
        "version": 1
    },
    "horse": {
        "id": "horse",
        "name": "Horse Face",
        "keywords": [
            "animal",
            "brown",
            "nature",
            "動物",
            "茶色",
            "自然"
        ],
        "skins": [
            {
                "unified": "1f434",
                "native": "🐴"
            }
        ],
        "version": 1
    },
    "racehorse": {
        "id": "racehorse",
        "name": "Horse",
        "keywords": [
            "racehorse",
            "animal",
            "gamble",
            "luck",
            "競走馬",
            "動物",
            "ギャンブル",
            "ラック"
        ],
        "skins": [
            {
                "unified": "1f40e",
                "native": "🐎"
            }
        ],
        "version": 1
    },
    "unicorn_face": {
        "id": "unicorn_face",
        "name": "Unicorn",
        "keywords": [
            "face",
            "animal",
            "nature",
            "mystical",
            "顔",
            "動物",
            "自然",
            "神秘的な"
        ],
        "skins": [
            {
                "unified": "1f984",
                "native": "🦄"
            }
        ],
        "version": 1
    },
    "zebra_face": {
        "id": "zebra_face",
        "name": "Zebra",
        "keywords": [
            "face",
            "animal",
            "nature",
            "stripes",
            "safari",
            "顔",
            "動物",
            "自然",
            "ストライプ",
            "サファリ"
        ],
        "skins": [
            {
                "unified": "1f993",
                "native": "🦓"
            }
        ],
        "version": 5
    },
    "deer": {
        "id": "deer",
        "name": "Deer",
        "keywords": [
            "animal",
            "nature",
            "horns",
            "venison",
            "動物",
            "自然",
            "角",
            "鹿肉"
        ],
        "skins": [
            {
                "unified": "1f98c",
                "native": "🦌"
            }
        ],
        "version": 3
    },
    "bison": {
        "id": "bison",
        "name": "Bison",
        "keywords": [
            "ox",
            "牛"
        ],
        "skins": [
            {
                "unified": "1f9ac",
                "native": "🦬"
            }
        ],
        "version": 13
    },
    "cow": {
        "id": "cow",
        "name": "Cow Face",
        "keywords": [
            "beef",
            "ox",
            "animal",
            "nature",
            "moo",
            "milk",
            "牛肉",
            "牛",
            "動物",
            "自然",
            "ムー",
            "牛乳"
        ],
        "skins": [
            {
                "unified": "1f42e",
                "native": "🐮"
            }
        ],
        "version": 1
    },
    "ox": {
        "id": "ox",
        "name": "Ox",
        "keywords": [
            "animal",
            "cow",
            "beef",
            "動物",
            "牛",
            "牛肉"
        ],
        "skins": [
            {
                "unified": "1f402",
                "native": "🐂"
            }
        ],
        "version": 1
    },
    "water_buffalo": {
        "id": "water_buffalo",
        "name": "Water Buffalo",
        "keywords": [
            "animal",
            "nature",
            "ox",
            "cow",
            "動物",
            "自然",
            "牛",
            "牛"
        ],
        "skins": [
            {
                "unified": "1f403",
                "native": "🐃"
            }
        ],
        "version": 1
    },
    "cow2": {
        "id": "cow2",
        "name": "Cow",
        "keywords": [
            "cow2",
            "beef",
            "ox",
            "animal",
            "nature",
            "moo",
            "milk",
            "牛2",
            "牛肉",
            "牛",
            "動物",
            "自然",
            "ムー",
            "牛乳"
        ],
        "skins": [
            {
                "unified": "1f404",
                "native": "🐄"
            }
        ],
        "version": 1
    },
    "pig": {
        "id": "pig",
        "name": "Pig Face",
        "keywords": [
            "animal",
            "oink",
            "nature",
            "動物",
            "オーインク",
            "自然"
        ],
        "skins": [
            {
                "unified": "1f437",
                "native": "🐷"
            }
        ],
        "version": 1
    },
    "pig2": {
        "id": "pig2",
        "name": "Pig",
        "keywords": [
            "pig2",
            "animal",
            "nature",
            "豚2",
            "動物",
            "自然"
        ],
        "skins": [
            {
                "unified": "1f416",
                "native": "🐖"
            }
        ],
        "version": 1
    },
    "boar": {
        "id": "boar",
        "name": "Boar",
        "keywords": [
            "animal",
            "nature",
            "動物",
            "自然"
        ],
        "skins": [
            {
                "unified": "1f417",
                "native": "🐗"
            }
        ],
        "version": 1
    },
    "pig_nose": {
        "id": "pig_nose",
        "name": "Pig Nose",
        "keywords": [
            "animal",
            "oink",
            "動物",
            "ウインク"
        ],
        "skins": [
            {
                "unified": "1f43d",
                "native": "🐽"
            }
        ],
        "version": 1
    },
    "ram": {
        "id": "ram",
        "name": "Ram",
        "keywords": [
            "animal",
            "sheep",
            "nature",
            "動物",
            "羊",
            "自然"
        ],
        "skins": [
            {
                "unified": "1f40f",
                "native": "🐏"
            }
        ],
        "version": 1
    },
    "sheep": {
        "id": "sheep",
        "name": "Ewe",
        "keywords": [
            "sheep",
            "animal",
            "nature",
            "wool",
            "shipit",
            "羊",
            "動物",
            "自然",
            "ウール",
            "それを出荷"
        ],
        "skins": [
            {
                "unified": "1f411",
                "native": "🐑"
            }
        ],
        "version": 1
    },
    "goat": {
        "id": "goat",
        "name": "Goat",
        "keywords": [
            "animal",
            "nature",
            "動物",
            "自然"
        ],
        "skins": [
            {
                "unified": "1f410",
                "native": "🐐"
            }
        ],
        "version": 1
    },
    "dromedary_camel": {
        "id": "dromedary_camel",
        "name": "Camel",
        "keywords": [
            "dromedary",
            "animal",
            "hot",
            "desert",
            "hump",
            "ヒトコブラクダ",
            "動物",
            "熱い",
            "荒野",
            "こぶ"
        ],
        "skins": [
            {
                "unified": "1f42a",
                "native": "🐪"
            }
        ],
        "version": 1
    },
    "camel": {
        "id": "camel",
        "name": "Bactrian Camel",
        "keywords": [
            "two",
            "hump",
            "animal",
            "nature",
            "hot",
            "desert",
            "二",
            "こぶ",
            "動物",
            "自然",
            "熱い",
            "荒野"
        ],
        "skins": [
            {
                "unified": "1f42b",
                "native": "🐫"
            }
        ],
        "version": 1
    },
    "llama": {
        "id": "llama",
        "name": "Llama",
        "keywords": [
            "animal",
            "nature",
            "alpaca",
            "動物",
            "自然",
            "アルパカ"
        ],
        "skins": [
            {
                "unified": "1f999",
                "native": "🦙"
            }
        ],
        "version": 11
    },
    "giraffe_face": {
        "id": "giraffe_face",
        "name": "Giraffe",
        "keywords": [
            "face",
            "animal",
            "nature",
            "spots",
            "safari",
            "顔",
            "動物",
            "自然",
            "スポット",
            "サファリ"
        ],
        "skins": [
            {
                "unified": "1f992",
                "native": "🦒"
            }
        ],
        "version": 5
    },
    "elephant": {
        "id": "elephant",
        "name": "Elephant",
        "keywords": [
            "animal",
            "nature",
            "nose",
            "th",
            "circus",
            "動物",
            "自然",
            "鼻",
            "番目",
            "サーカス"
        ],
        "skins": [
            {
                "unified": "1f418",
                "native": "🐘"
            }
        ],
        "version": 1
    },
    "mammoth": {
        "id": "mammoth",
        "name": "Mammoth",
        "keywords": [
            "elephant",
            "tusks",
            "象",
            "牙"
        ],
        "skins": [
            {
                "unified": "1f9a3",
                "native": "🦣"
            }
        ],
        "version": 13
    },
    "rhinoceros": {
        "id": "rhinoceros",
        "name": "Rhinoceros",
        "keywords": [
            "animal",
            "nature",
            "horn",
            "動物",
            "自然",
            "ホーン"
        ],
        "skins": [
            {
                "unified": "1f98f",
                "native": "🦏"
            }
        ],
        "version": 3
    },
    "hippopotamus": {
        "id": "hippopotamus",
        "name": "Hippopotamus",
        "keywords": [
            "animal",
            "nature",
            "動物",
            "自然"
        ],
        "skins": [
            {
                "unified": "1f99b",
                "native": "🦛"
            }
        ],
        "version": 11
    },
    "mouse": {
        "id": "mouse",
        "name": "Mouse Face",
        "keywords": [
            "animal",
            "nature",
            "cheese",
            "wedge",
            "rodent",
            "動物",
            "自然",
            "チーズ",
            "くさび",
            "齧歯類"
        ],
        "skins": [
            {
                "unified": "1f42d",
                "native": "🐭"
            }
        ],
        "version": 1
    },
    "mouse2": {
        "id": "mouse2",
        "name": "Mouse",
        "keywords": [
            "mouse2",
            "animal",
            "nature",
            "rodent",
            "マウス2",
            "動物",
            "自然",
            "齧歯類"
        ],
        "skins": [
            {
                "unified": "1f401",
                "native": "🐁"
            }
        ],
        "version": 1
    },
    "rat": {
        "id": "rat",
        "name": "Rat",
        "keywords": [
            "animal",
            "mouse",
            "rodent",
            "動物",
            "ねずみ",
            "齧歯類"
        ],
        "skins": [
            {
                "unified": "1f400",
                "native": "🐀"
            }
        ],
        "version": 1
    },
    "hamster": {
        "id": "hamster",
        "name": "Hamster",
        "keywords": [
            "animal",
            "nature",
            "動物",
            "自然"
        ],
        "skins": [
            {
                "unified": "1f439",
                "native": "🐹"
            }
        ],
        "version": 1
    },
    "rabbit": {
        "id": "rabbit",
        "name": "Rabbit Face",
        "keywords": [
            "animal",
            "nature",
            "pet",
            "spring",
            "magic",
            "bunny",
            "動物",
            "自然",
            "ペット",
            "春",
            "魔法",
            "バニー"
        ],
        "skins": [
            {
                "unified": "1f430",
                "native": "🐰"
            }
        ],
        "version": 1
    },
    "rabbit2": {
        "id": "rabbit2",
        "name": "Rabbit",
        "keywords": [
            "rabbit2",
            "animal",
            "nature",
            "pet",
            "magic",
            "spring",
            "うさぎ2",
            "動物",
            "自然",
            "ペット",
            "魔法",
            "春"
        ],
        "skins": [
            {
                "unified": "1f407",
                "native": "🐇"
            }
        ],
        "version": 1
    },
    "chipmunk": {
        "id": "chipmunk",
        "name": "Chipmunk",
        "keywords": [
            "animal",
            "nature",
            "rodent",
            "squirrel",
            "動物",
            "自然",
            "齧歯類",
            "リス"
        ],
        "skins": [
            {
                "unified": "1f43f-fe0f",
                "native": "🐿️"
            }
        ],
        "version": 1
    },
    "beaver": {
        "id": "beaver",
        "name": "Beaver",
        "keywords": [
            "animal",
            "rodent",
            "動物",
            "齧歯類"
        ],
        "skins": [
            {
                "unified": "1f9ab",
                "native": "🦫"
            }
        ],
        "version": 13
    },
    "hedgehog": {
        "id": "hedgehog",
        "name": "Hedgehog",
        "keywords": [
            "animal",
            "nature",
            "spiny",
            "動物",
            "自然",
            "とげのある"
        ],
        "skins": [
            {
                "unified": "1f994",
                "native": "🦔"
            }
        ],
        "version": 5
    },
    "bat": {
        "id": "bat",
        "name": "Bat",
        "keywords": [
            "animal",
            "nature",
            "blind",
            "vampire",
            "動物",
            "自然",
            "盲目",
            "吸血鬼"
        ],
        "skins": [
            {
                "unified": "1f987",
                "native": "🦇"
            }
        ],
        "version": 3
    },
    "bear": {
        "id": "bear",
        "name": "Bear",
        "keywords": [
            "animal",
            "nature",
            "wild",
            "動物",
            "自然",
            "野生"
        ],
        "skins": [
            {
                "unified": "1f43b",
                "native": "🐻"
            }
        ],
        "version": 1
    },
    "polar_bear": {
        "id": "polar_bear",
        "name": "Polar Bear",
        "keywords": [
            "animal",
            "arctic",
            "動物",
            "北極"
        ],
        "skins": [
            {
                "unified": "1f43b-200d-2744-fe0f",
                "native": "🐻‍❄️"
            }
        ],
        "version": 13
    },
    "koala": {
        "id": "koala",
        "name": "Koala",
        "keywords": [
            "animal",
            "nature",
            "動物",
            "自然"
        ],
        "skins": [
            {
                "unified": "1f428",
                "native": "🐨"
            }
        ],
        "version": 1
    },
    "panda_face": {
        "id": "panda_face",
        "name": "Panda",
        "keywords": [
            "face",
            "animal",
            "nature",
            "顔",
            "動物",
            "自然"
        ],
        "skins": [
            {
                "unified": "1f43c",
                "native": "🐼"
            }
        ],
        "version": 1
    },
    "sloth": {
        "id": "sloth",
        "name": "Sloth",
        "keywords": [
            "animal",
            "動物"
        ],
        "skins": [
            {
                "unified": "1f9a5",
                "native": "🦥"
            }
        ],
        "version": 12
    },
    "otter": {
        "id": "otter",
        "name": "Otter",
        "keywords": [
            "animal",
            "動物"
        ],
        "skins": [
            {
                "unified": "1f9a6",
                "native": "🦦"
            }
        ],
        "version": 12
    },
    "skunk": {
        "id": "skunk",
        "name": "Skunk",
        "keywords": [
            "animal",
            "動物"
        ],
        "skins": [
            {
                "unified": "1f9a8",
                "native": "🦨"
            }
        ],
        "version": 12
    },
    "kangaroo": {
        "id": "kangaroo",
        "name": "Kangaroo",
        "keywords": [
            "animal",
            "nature",
            "australia",
            "joey",
            "hop",
            "marsupial",
            "動物",
            "自然",
            "オーストラリア",
            "ジョーイ",
            "ホップ",
            "有袋類"
        ],
        "skins": [
            {
                "unified": "1f998",
                "native": "🦘"
            }
        ],
        "version": 11
    },
    "badger": {
        "id": "badger",
        "name": "Badger",
        "keywords": [
            "animal",
            "nature",
            "honey",
            "動物",
            "自然",
            "ハニー"
        ],
        "skins": [
            {
                "unified": "1f9a1",
                "native": "🦡"
            }
        ],
        "version": 11
    },
    "feet": {
        "id": "feet",
        "name": "Paw Prints",
        "keywords": [
            "feet",
            "animal",
            "tracking",
            "footprints",
            "dog",
            "cat",
            "pet",
            "フィート",
            "動物",
            "追跡",
            "足跡",
            "犬",
            "猫",
            "ペット"
        ],
        "skins": [
            {
                "unified": "1f43e",
                "native": "🐾"
            }
        ],
        "version": 1
    },
    "turkey": {
        "id": "turkey",
        "name": "Turkey",
        "keywords": [
            "animal",
            "bird",
            "動物",
            "鳥"
        ],
        "skins": [
            {
                "unified": "1f983",
                "native": "🦃"
            }
        ],
        "version": 1
    },
    "chicken": {
        "id": "chicken",
        "name": "Chicken",
        "keywords": [
            "animal",
            "cluck",
            "nature",
            "bird",
            "動物",
            "カチャッ",
            "自然",
            "鳥"
        ],
        "skins": [
            {
                "unified": "1f414",
                "native": "🐔"
            }
        ],
        "version": 1
    },
    "rooster": {
        "id": "rooster",
        "name": "Rooster",
        "keywords": [
            "animal",
            "nature",
            "chicken",
            "動物",
            "自然",
            "鶏"
        ],
        "skins": [
            {
                "unified": "1f413",
                "native": "🐓"
            }
        ],
        "version": 1
    },
    "hatching_chick": {
        "id": "hatching_chick",
        "name": "Hatching Chick",
        "keywords": [
            "animal",
            "chicken",
            "egg",
            "born",
            "baby",
            "bird",
            "動物",
            "鶏",
            "卵",
            "生まれる",
            "赤ちゃん",
            "鳥"
        ],
        "skins": [
            {
                "unified": "1f423",
                "native": "🐣"
            }
        ],
        "version": 1
    },
    "baby_chick": {
        "id": "baby_chick",
        "name": "Baby Chick",
        "keywords": [
            "animal",
            "chicken",
            "bird",
            "動物",
            "鶏",
            "鳥"
        ],
        "skins": [
            {
                "unified": "1f424",
                "native": "🐤"
            }
        ],
        "version": 1
    },
    "hatched_chick": {
        "id": "hatched_chick",
        "name": "Front-Facing Baby Chick",
        "keywords": [
            "hatched",
            "front",
            "facing",
            "animal",
            "chicken",
            "bird",
            "孵化した",
            "正面",
            "直面している",
            "動物",
            "鶏",
            "鳥"
        ],
        "skins": [
            {
                "unified": "1f425",
                "native": "🐥"
            }
        ],
        "version": 1
    },
    "bird": {
        "id": "bird",
        "name": "Bird",
        "keywords": [
            "animal",
            "nature",
            "fly",
            "tweet",
            "spring",
            "動物",
            "自然",
            "飛ぶ",
            "つぶやき",
            "春"
        ],
        "skins": [
            {
                "unified": "1f426",
                "native": "🐦"
            }
        ],
        "version": 1
    },
    "penguin": {
        "id": "penguin",
        "name": "Penguin",
        "keywords": [
            "animal",
            "nature",
            "動物",
            "自然"
        ],
        "skins": [
            {
                "unified": "1f427",
                "native": "🐧"
            }
        ],
        "version": 1
    },
    "dove_of_peace": {
        "id": "dove_of_peace",
        "name": "Dove",
        "keywords": [
            "of",
            "peace",
            "animal",
            "bird",
            "の",
            "平和",
            "動物",
            "鳥"
        ],
        "skins": [
            {
                "unified": "1f54a-fe0f",
                "native": "🕊️"
            }
        ],
        "version": 1
    },
    "eagle": {
        "id": "eagle",
        "name": "Eagle",
        "keywords": [
            "animal",
            "nature",
            "bird",
            "動物",
            "自然",
            "鳥"
        ],
        "skins": [
            {
                "unified": "1f985",
                "native": "🦅"
            }
        ],
        "version": 3
    },
    "duck": {
        "id": "duck",
        "name": "Duck",
        "keywords": [
            "animal",
            "nature",
            "bird",
            "mallard",
            "動物",
            "自然",
            "鳥",
            "マガモ"
        ],
        "skins": [
            {
                "unified": "1f986",
                "native": "🦆"
            }
        ],
        "version": 3
    },
    "swan": {
        "id": "swan",
        "name": "Swan",
        "keywords": [
            "animal",
            "nature",
            "bird",
            "動物",
            "自然",
            "鳥"
        ],
        "skins": [
            {
                "unified": "1f9a2",
                "native": "🦢"
            }
        ],
        "version": 11
    },
    "owl": {
        "id": "owl",
        "name": "Owl",
        "keywords": [
            "animal",
            "nature",
            "bird",
            "hoot",
            "動物",
            "自然",
            "鳥",
            "フッ"
        ],
        "skins": [
            {
                "unified": "1f989",
                "native": "🦉"
            }
        ],
        "version": 3
    },
    "dodo": {
        "id": "dodo",
        "name": "Dodo",
        "keywords": [
            "animal",
            "bird",
            "動物",
            "鳥"
        ],
        "skins": [
            {
                "unified": "1f9a4",
                "native": "🦤"
            }
        ],
        "version": 13
    },
    "feather": {
        "id": "feather",
        "name": "Feather",
        "keywords": [
            "bird",
            "fly",
            "鳥",
            "飛ぶ"
        ],
        "skins": [
            {
                "unified": "1fab6",
                "native": "🪶"
            }
        ],
        "version": 13
    },
    "flamingo": {
        "id": "flamingo",
        "name": "Flamingo",
        "keywords": [
            "animal",
            "動物"
        ],
        "skins": [
            {
                "unified": "1f9a9",
                "native": "🦩"
            }
        ],
        "version": 12
    },
    "peacock": {
        "id": "peacock",
        "name": "Peacock",
        "keywords": [
            "animal",
            "nature",
            "peahen",
            "bird",
            "動物",
            "自然",
            "ピーヘン",
            "鳥"
        ],
        "skins": [
            {
                "unified": "1f99a",
                "native": "🦚"
            }
        ],
        "version": 11
    },
    "parrot": {
        "id": "parrot",
        "name": "Parrot",
        "keywords": [
            "animal",
            "nature",
            "bird",
            "pirate",
            "talk",
            "動物",
            "自然",
            "鳥",
            "海賊",
            "話"
        ],
        "skins": [
            {
                "unified": "1f99c",
                "native": "🦜"
            }
        ],
        "version": 11
    },
    "frog": {
        "id": "frog",
        "name": "Frog",
        "keywords": [
            "animal",
            "nature",
            "croak",
            "toad",
            "動物",
            "自然",
            "鳴き声",
            "ヒキガエル"
        ],
        "skins": [
            {
                "unified": "1f438",
                "native": "🐸"
            }
        ],
        "version": 1
    },
    "crocodile": {
        "id": "crocodile",
        "name": "Crocodile",
        "keywords": [
            "animal",
            "nature",
            "reptile",
            "lizard",
            "alligator",
            "動物",
            "自然",
            "爬虫類",
            "トカゲ",
            "アリゲーター"
        ],
        "skins": [
            {
                "unified": "1f40a",
                "native": "🐊"
            }
        ],
        "version": 1
    },
    "turtle": {
        "id": "turtle",
        "name": "Turtle",
        "keywords": [
            "animal",
            "slow",
            "nature",
            "tortoise",
            "動物",
            "遅い",
            "自然",
            "カメ"
        ],
        "skins": [
            {
                "unified": "1f422",
                "native": "🐢"
            }
        ],
        "version": 1
    },
    "lizard": {
        "id": "lizard",
        "name": "Lizard",
        "keywords": [
            "animal",
            "nature",
            "reptile",
            "動物",
            "自然",
            "爬虫類"
        ],
        "skins": [
            {
                "unified": "1f98e",
                "native": "🦎"
            }
        ],
        "version": 3
    },
    "snake": {
        "id": "snake",
        "name": "Snake",
        "keywords": [
            "animal",
            "evil",
            "nature",
            "hiss",
            "python",
            "動物",
            "悪",
            "自然",
            "ヒス",
            "パイソン"
        ],
        "skins": [
            {
                "unified": "1f40d",
                "native": "🐍"
            }
        ],
        "version": 1
    },
    "dragon_face": {
        "id": "dragon_face",
        "name": "Dragon Face",
        "keywords": [
            "animal",
            "myth",
            "nature",
            "chinese",
            "green",
            "動物",
            "神話",
            "自然",
            "中国語",
            "緑"
        ],
        "skins": [
            {
                "unified": "1f432",
                "native": "🐲"
            }
        ],
        "version": 1
    },
    "dragon": {
        "id": "dragon",
        "name": "Dragon",
        "keywords": [
            "animal",
            "myth",
            "nature",
            "chinese",
            "green",
            "動物",
            "神話",
            "自然",
            "中国語",
            "緑"
        ],
        "skins": [
            {
                "unified": "1f409",
                "native": "🐉"
            }
        ],
        "version": 1
    },
    "sauropod": {
        "id": "sauropod",
        "name": "Sauropod",
        "keywords": [
            "animal",
            "nature",
            "dinosaur",
            "brachiosaurus",
            "brontosaurus",
            "diplodocus",
            "extinct",
            "動物",
            "自然",
            "恐竜",
            "ブラキオサウルス",
            "ブロントサウルス",
            "ディプロドクス",
            "絶滅"
        ],
        "skins": [
            {
                "unified": "1f995",
                "native": "🦕"
            }
        ],
        "version": 5
    },
    "t-rex": {
        "id": "t-rex",
        "name": "T-Rex",
        "keywords": [
            "t",
            "rex",
            "animal",
            "nature",
            "dinosaur",
            "tyrannosaurus",
            "extinct",
            "t",
            "レックス",
            "動物",
            "自然",
            "恐竜",
            "ティラノサウルス",
            "絶滅"
        ],
        "skins": [
            {
                "unified": "1f996",
                "native": "🦖"
            }
        ],
        "version": 5
    },
    "whale": {
        "id": "whale",
        "name": "Spouting Whale",
        "keywords": [
            "animal",
            "nature",
            "sea",
            "ocean",
            "動物",
            "自然",
            "海",
            "海洋"
        ],
        "skins": [
            {
                "unified": "1f433",
                "native": "🐳"
            }
        ],
        "version": 1
    },
    "whale2": {
        "id": "whale2",
        "name": "Whale",
        "keywords": [
            "whale2",
            "animal",
            "nature",
            "sea",
            "ocean",
            "クジラ2",
            "動物",
            "自然",
            "海",
            "海洋"
        ],
        "skins": [
            {
                "unified": "1f40b",
                "native": "🐋"
            }
        ],
        "version": 1
    },
    "dolphin": {
        "id": "dolphin",
        "name": "Dolphin",
        "keywords": [
            "flipper",
            "animal",
            "nature",
            "fish",
            "sea",
            "ocean",
            "fins",
            "beach",
            "フリッパー",
            "動物",
            "自然",
            "魚",
            "海",
            "海洋",
            "フィン",
            "ビーチ"
        ],
        "skins": [
            {
                "unified": "1f42c",
                "native": "🐬"
            }
        ],
        "version": 1
    },
    "seal": {
        "id": "seal",
        "name": "Seal",
        "keywords": [
            "animal",
            "creature",
            "sea",
            "動物",
            "生き物",
            "海"
        ],
        "skins": [
            {
                "unified": "1f9ad",
                "native": "🦭"
            }
        ],
        "version": 13
    },
    "fish": {
        "id": "fish",
        "name": "Fish",
        "keywords": [
            "animal",
            "food",
            "nature",
            "動物",
            "食べ物",
            "自然"
        ],
        "skins": [
            {
                "unified": "1f41f",
                "native": "🐟"
            }
        ],
        "version": 1
    },
    "tropical_fish": {
        "id": "tropical_fish",
        "name": "Tropical Fish",
        "keywords": [
            "animal",
            "swim",
            "ocean",
            "beach",
            "nemo",
            "動物",
            "泳ぐ",
            "海洋",
            "ビーチ",
            "ニモ"
        ],
        "skins": [
            {
                "unified": "1f420",
                "native": "🐠"
            }
        ],
        "version": 1
    },
    "blowfish": {
        "id": "blowfish",
        "name": "Blowfish",
        "keywords": [
            "animal",
            "nature",
            "food",
            "sea",
            "ocean",
            "動物",
            "自然",
            "食べ物",
            "海",
            "海洋"
        ],
        "skins": [
            {
                "unified": "1f421",
                "native": "🐡"
            }
        ],
        "version": 1
    },
    "shark": {
        "id": "shark",
        "name": "Shark",
        "keywords": [
            "animal",
            "nature",
            "fish",
            "sea",
            "ocean",
            "jaws",
            "fins",
            "beach",
            "動物",
            "自然",
            "魚",
            "海",
            "海洋",
            "あご",
            "フィン",
            "ビーチ"
        ],
        "skins": [
            {
                "unified": "1f988",
                "native": "🦈"
            }
        ],
        "version": 3
    },
    "octopus": {
        "id": "octopus",
        "name": "Octopus",
        "keywords": [
            "animal",
            "creature",
            "ocean",
            "sea",
            "nature",
            "beach",
            "動物",
            "生き物",
            "海洋",
            "海",
            "自然",
            "ビーチ"
        ],
        "skins": [
            {
                "unified": "1f419",
                "native": "🐙"
            }
        ],
        "version": 1
    },
    "shell": {
        "id": "shell",
        "name": "Spiral Shell",
        "keywords": [
            "nature",
            "sea",
            "beach",
            "自然",
            "海",
            "ビーチ"
        ],
        "skins": [
            {
                "unified": "1f41a",
                "native": "🐚"
            }
        ],
        "version": 1
    },
    "coral": {
        "id": "coral",
        "name": "Coral",
        "keywords": [
            "ocean",
            "sea",
            "reef",
            "海洋",
            "海",
            "リーフ"
        ],
        "skins": [
            {
                "unified": "1fab8",
                "native": "🪸"
            }
        ],
        "version": 14
    },
    "snail": {
        "id": "snail",
        "name": "Snail",
        "keywords": [
            "slow",
            "animal",
            "shell",
            "遅い",
            "動物",
            "シェル"
        ],
        "skins": [
            {
                "unified": "1f40c",
                "native": "🐌"
            }
        ],
        "version": 1
    },
    "butterfly": {
        "id": "butterfly",
        "name": "Butterfly",
        "keywords": [
            "animal",
            "insect",
            "nature",
            "caterpillar",
            "動物",
            "虫",
            "自然",
            "毛虫"
        ],
        "skins": [
            {
                "unified": "1f98b",
                "native": "🦋"
            }
        ],
        "version": 3
    },
    "bug": {
        "id": "bug",
        "name": "Bug",
        "keywords": [
            "animal",
            "insect",
            "nature",
            "worm",
            "動物",
            "虫",
            "自然",
            "いも虫"
        ],
        "skins": [
            {
                "unified": "1f41b",
                "native": "🐛"
            }
        ],
        "version": 1
    },
    "ant": {
        "id": "ant",
        "name": "Ant",
        "keywords": [
            "animal",
            "insect",
            "nature",
            "bug",
            "動物",
            "虫",
            "自然",
            "バグ"
        ],
        "skins": [
            {
                "unified": "1f41c",
                "native": "🐜"
            }
        ],
        "version": 1
    },
    "bee": {
        "id": "bee",
        "name": "Honeybee",
        "keywords": [
            "bee",
            "animal",
            "insect",
            "nature",
            "bug",
            "spring",
            "honey",
            "蜂",
            "動物",
            "虫",
            "自然",
            "バグ",
            "春",
            "ハニー"
        ],
        "skins": [
            {
                "unified": "1f41d",
                "native": "🐝"
            }
        ],
        "version": 1
    },
    "beetle": {
        "id": "beetle",
        "name": "Beetle",
        "keywords": [
            "insect",
            "虫"
        ],
        "skins": [
            {
                "unified": "1fab2",
                "native": "🪲"
            }
        ],
        "version": 13
    },
    "ladybug": {
        "id": "ladybug",
        "name": "Lady Beetle",
        "keywords": [
            "ladybug",
            "animal",
            "insect",
            "nature",
            "てんとう虫",
            "動物",
            "虫",
            "自然"
        ],
        "skins": [
            {
                "unified": "1f41e",
                "native": "🐞"
            }
        ],
        "version": 1
    },
    "cricket": {
        "id": "cricket",
        "name": "Cricket",
        "keywords": [
            "animal",
            "chirp",
            "動物",
            "チャープ"
        ],
        "skins": [
            {
                "unified": "1f997",
                "native": "🦗"
            }
        ],
        "version": 5
    },
    "cockroach": {
        "id": "cockroach",
        "name": "Cockroach",
        "keywords": [
            "insect",
            "pests",
            "虫",
            "害虫"
        ],
        "skins": [
            {
                "unified": "1fab3",
                "native": "🪳"
            }
        ],
        "version": 13
    },
    "spider": {
        "id": "spider",
        "name": "Spider",
        "keywords": [
            "animal",
            "arachnid",
            "動物",
            "クモ"
        ],
        "skins": [
            {
                "unified": "1f577-fe0f",
                "native": "🕷️"
            }
        ],
        "version": 1
    },
    "spider_web": {
        "id": "spider_web",
        "name": "Spider Web",
        "keywords": [
            "animal",
            "insect",
            "arachnid",
            "silk",
            "動物",
            "虫",
            "クモ",
            "シルク"
        ],
        "skins": [
            {
                "unified": "1f578-fe0f",
                "native": "🕸️"
            }
        ],
        "version": 1
    },
    "scorpion": {
        "id": "scorpion",
        "name": "Scorpion",
        "keywords": [
            "animal",
            "arachnid",
            "動物",
            "クモ"
        ],
        "skins": [
            {
                "unified": "1f982",
                "native": "🦂"
            }
        ],
        "version": 1
    },
    "mosquito": {
        "id": "mosquito",
        "name": "Mosquito",
        "keywords": [
            "animal",
            "nature",
            "insect",
            "malaria",
            "動物",
            "自然",
            "虫",
            "マラリア"
        ],
        "skins": [
            {
                "unified": "1f99f",
                "native": "🦟"
            }
        ],
        "version": 11
    },
    "fly": {
        "id": "fly",
        "name": "Fly",
        "keywords": [
            "insect",
            "虫"
        ],
        "skins": [
            {
                "unified": "1fab0",
                "native": "🪰"
            }
        ],
        "version": 13
    },
    "worm": {
        "id": "worm",
        "name": "Worm",
        "keywords": [
            "animal",
            "動物"
        ],
        "skins": [
            {
                "unified": "1fab1",
                "native": "🪱"
            }
        ],
        "version": 13
    },
    "microbe": {
        "id": "microbe",
        "name": "Microbe",
        "keywords": [
            "amoeba",
            "bacteria",
            "germs",
            "virus",
            "アメーバ",
            "バクテリア",
            "ばい菌",
            "ウイルス"
        ],
        "skins": [
            {
                "unified": "1f9a0",
                "native": "🦠"
            }
        ],
        "version": 11
    },
    "bouquet": {
        "id": "bouquet",
        "name": "Bouquet",
        "keywords": [
            "flowers",
            "nature",
            "spring",
            "フラワーズ",
            "自然",
            "春"
        ],
        "skins": [
            {
                "unified": "1f490",
                "native": "💐"
            }
        ],
        "version": 1
    },
    "cherry_blossom": {
        "id": "cherry_blossom",
        "name": "Cherry Blossom",
        "keywords": [
            "nature",
            "plant",
            "spring",
            "flower",
            "自然",
            "植物",
            "春",
            "花"
        ],
        "skins": [
            {
                "unified": "1f338",
                "native": "🌸"
            }
        ],
        "version": 1
    },
    "white_flower": {
        "id": "white_flower",
        "name": "White Flower",
        "keywords": [
            "japanese",
            "spring",
            "日本",
            "春"
        ],
        "skins": [
            {
                "unified": "1f4ae",
                "native": "💮"
            }
        ],
        "version": 1
    },
    "lotus": {
        "id": "lotus",
        "name": "Lotus",
        "keywords": [
            "flower",
            "calm",
            "meditation",
            "花",
            "落ち着いて",
            "瞑想"
        ],
        "skins": [
            {
                "unified": "1fab7",
                "native": "🪷"
            }
        ],
        "version": 14
    },
    "rosette": {
        "id": "rosette",
        "name": "Rosette",
        "keywords": [
            "flower",
            "decoration",
            "military",
            "花",
            "装飾",
            "軍隊"
        ],
        "skins": [
            {
                "unified": "1f3f5-fe0f",
                "native": "🏵️"
            }
        ],
        "version": 1
    },
    "rose": {
        "id": "rose",
        "name": "Rose",
        "keywords": [
            "flowers",
            "valentines",
            "love",
            "spring",
            "フラワーズ",
            "バレンタイン",
            "愛",
            "春"
        ],
        "skins": [
            {
                "unified": "1f339",
                "native": "🌹"
            }
        ],
        "version": 1
    },
    "wilted_flower": {
        "id": "wilted_flower",
        "name": "Wilted Flower",
        "keywords": [
            "plant",
            "nature",
            "植物",
            "自然"
        ],
        "skins": [
            {
                "unified": "1f940",
                "native": "🥀"
            }
        ],
        "version": 3
    },
    "hibiscus": {
        "id": "hibiscus",
        "name": "Hibiscus",
        "keywords": [
            "plant",
            "vegetable",
            "flowers",
            "beach",
            "植物",
            "野菜",
            "フラワーズ",
            "ビーチ"
        ],
        "skins": [
            {
                "unified": "1f33a",
                "native": "🌺"
            }
        ],
        "version": 1
    },
    "sunflower": {
        "id": "sunflower",
        "name": "Sunflower",
        "keywords": [
            "nature",
            "plant",
            "fall",
            "自然",
            "植物",
            "秋"
        ],
        "skins": [
            {
                "unified": "1f33b",
                "native": "🌻"
            }
        ],
        "version": 1
    },
    "blossom": {
        "id": "blossom",
        "name": "Blossom",
        "keywords": [
            "nature",
            "flowers",
            "yellow",
            "自然",
            "フラワーズ",
            "黄色"
        ],
        "skins": [
            {
                "unified": "1f33c",
                "native": "🌼"
            }
        ],
        "version": 1
    },
    "tulip": {
        "id": "tulip",
        "name": "Tulip",
        "keywords": [
            "flowers",
            "plant",
            "nature",
            "summer",
            "spring",
            "フラワーズ",
            "植物",
            "自然",
            "夏",
            "春"
        ],
        "skins": [
            {
                "unified": "1f337",
                "native": "🌷"
            }
        ],
        "version": 1
    },
    "seedling": {
        "id": "seedling",
        "name": "Seedling",
        "keywords": [
            "plant",
            "nature",
            "grass",
            "lawn",
            "spring",
            "植物",
            "自然",
            "草",
            "芝生",
            "春"
        ],
        "skins": [
            {
                "unified": "1f331",
                "native": "🌱"
            }
        ],
        "version": 1
    },
    "potted_plant": {
        "id": "potted_plant",
        "name": "Potted Plant",
        "keywords": [
            "greenery",
            "house",
            "緑",
            "家"
        ],
        "skins": [
            {
                "unified": "1fab4",
                "native": "🪴"
            }
        ],
        "version": 13
    },
    "evergreen_tree": {
        "id": "evergreen_tree",
        "name": "Evergreen Tree",
        "keywords": [
            "plant",
            "nature",
            "植物",
            "自然"
        ],
        "skins": [
            {
                "unified": "1f332",
                "native": "🌲"
            }
        ],
        "version": 1
    },
    "deciduous_tree": {
        "id": "deciduous_tree",
        "name": "Deciduous Tree",
        "keywords": [
            "plant",
            "nature",
            "植物",
            "自然"
        ],
        "skins": [
            {
                "unified": "1f333",
                "native": "🌳"
            }
        ],
        "version": 1
    },
    "palm_tree": {
        "id": "palm_tree",
        "name": "Palm Tree",
        "keywords": [
            "plant",
            "vegetable",
            "nature",
            "summer",
            "beach",
            "mojito",
            "tropical",
            "植物",
            "野菜",
            "自然",
            "夏",
            "ビーチ",
            "モヒート",
            "トロピカル"
        ],
        "skins": [
            {
                "unified": "1f334",
                "native": "🌴"
            }
        ],
        "version": 1
    },
    "cactus": {
        "id": "cactus",
        "name": "Cactus",
        "keywords": [
            "vegetable",
            "plant",
            "nature",
            "野菜",
            "植物",
            "自然"
        ],
        "skins": [
            {
                "unified": "1f335",
                "native": "🌵"
            }
        ],
        "version": 1
    },
    "ear_of_rice": {
        "id": "ear_of_rice",
        "name": "Ear of Rice",
        "keywords": [
            "sheaf",
            "nature",
            "plant",
            "束",
            "自然",
            "植物"
        ],
        "skins": [
            {
                "unified": "1f33e",
                "native": "🌾"
            }
        ],
        "version": 1
    },
    "herb": {
        "id": "herb",
        "name": "Herb",
        "keywords": [
            "vegetable",
            "plant",
            "medicine",
            "weed",
            "grass",
            "lawn",
            "野菜",
            "植物",
            "薬",
            "雑草",
            "草",
            "芝生"
        ],
        "skins": [
            {
                "unified": "1f33f",
                "native": "🌿"
            }
        ],
        "version": 1
    },
    "shamrock": {
        "id": "shamrock",
        "name": "Shamrock",
        "keywords": [
            "vegetable",
            "plant",
            "nature",
            "irish",
            "clover",
            "野菜",
            "植物",
            "自然",
            "アイリッシュ",
            "クローバー"
        ],
        "skins": [
            {
                "unified": "2618-fe0f",
                "native": "☘️"
            }
        ],
        "version": 1
    },
    "four_leaf_clover": {
        "id": "four_leaf_clover",
        "name": "Four Leaf Clover",
        "keywords": [
            "vegetable",
            "plant",
            "nature",
            "lucky",
            "irish",
            "野菜",
            "植物",
            "自然",
            "ラッキー",
            "アイリッシュ"
        ],
        "skins": [
            {
                "unified": "1f340",
                "native": "🍀"
            }
        ],
        "version": 1
    },
    "maple_leaf": {
        "id": "maple_leaf",
        "name": "Maple Leaf",
        "keywords": [
            "nature",
            "plant",
            "vegetable",
            "ca",
            "fall",
            "自然",
            "植物",
            "野菜",
            "ca",
            "秋"
        ],
        "skins": [
            {
                "unified": "1f341",
                "native": "🍁"
            }
        ],
        "version": 1
    },
    "fallen_leaf": {
        "id": "fallen_leaf",
        "name": "Fallen Leaf",
        "keywords": [
            "nature",
            "plant",
            "vegetable",
            "leaves",
            "自然",
            "植物",
            "野菜",
            "葉"
        ],
        "skins": [
            {
                "unified": "1f342",
                "native": "🍂"
            }
        ],
        "version": 1
    },
    "leaves": {
        "id": "leaves",
        "name": "Leaf Fluttering in Wind",
        "keywords": [
            "leaves",
            "nature",
            "plant",
            "tree",
            "vegetable",
            "grass",
            "lawn",
            "spring",
            "葉",
            "自然",
            "植物",
            "木",
            "野菜",
            "草",
            "芝生",
            "春"
        ],
        "skins": [
            {
                "unified": "1f343",
                "native": "🍃"
            }
        ],
        "version": 1
    },
    "empty_nest": {
        "id": "empty_nest",
        "name": "Empty Nest",
        "keywords": [
            "bird",
            "鳥"
        ],
        "skins": [
            {
                "unified": "1fab9",
                "native": "🪹"
            }
        ],
        "version": 14
    },
    "nest_with_eggs": {
        "id": "nest_with_eggs",
        "name": "Nest with Eggs",
        "keywords": [
            "bird",
            "鳥"
        ],
        "skins": [
            {
                "unified": "1faba",
                "native": "🪺"
            }
        ],
        "version": 14
    },
    "grapes": {
        "id": "grapes",
        "name": "Grapes",
        "keywords": [
            "fruit",
            "food",
            "wine",
            "フルーツ",
            "食べ物",
            "ワイン"
        ],
        "skins": [
            {
                "unified": "1f347",
                "native": "🍇"
            }
        ],
        "version": 1
    },
    "melon": {
        "id": "melon",
        "name": "Melon",
        "keywords": [
            "fruit",
            "nature",
            "food",
            "フルーツ",
            "自然",
            "食べ物"
        ],
        "skins": [
            {
                "unified": "1f348",
                "native": "🍈"
            }
        ],
        "version": 1
    },
    "watermelon": {
        "id": "watermelon",
        "name": "Watermelon",
        "keywords": [
            "fruit",
            "food",
            "picnic",
            "summer",
            "フルーツ",
            "食べ物",
            "ピクニック",
            "夏"
        ],
        "skins": [
            {
                "unified": "1f349",
                "native": "🍉"
            }
        ],
        "version": 1
    },
    "tangerine": {
        "id": "tangerine",
        "name": "Tangerine",
        "keywords": [
            "food",
            "fruit",
            "nature",
            "orange",
            "食べ物",
            "フルーツ",
            "自然",
            "オレンジ"
        ],
        "skins": [
            {
                "unified": "1f34a",
                "native": "🍊"
            }
        ],
        "version": 1
    },
    "lemon": {
        "id": "lemon",
        "name": "Lemon",
        "keywords": [
            "fruit",
            "nature",
            "フルーツ",
            "自然"
        ],
        "skins": [
            {
                "unified": "1f34b",
                "native": "🍋"
            }
        ],
        "version": 1
    },
    "banana": {
        "id": "banana",
        "name": "Banana",
        "keywords": [
            "fruit",
            "food",
            "monkey",
            "フルーツ",
            "食べ物",
            "猿"
        ],
        "skins": [
            {
                "unified": "1f34c",
                "native": "🍌"
            }
        ],
        "version": 1
    },
    "pineapple": {
        "id": "pineapple",
        "name": "Pineapple",
        "keywords": [
            "fruit",
            "nature",
            "food",
            "フルーツ",
            "自然",
            "食べ物"
        ],
        "skins": [
            {
                "unified": "1f34d",
                "native": "🍍"
            }
        ],
        "version": 1
    },
    "mango": {
        "id": "mango",
        "name": "Mango",
        "keywords": [
            "fruit",
            "food",
            "tropical",
            "フルーツ",
            "食べ物",
            "トロピカル"
        ],
        "skins": [
            {
                "unified": "1f96d",
                "native": "🥭"
            }
        ],
        "version": 11
    },
    "apple": {
        "id": "apple",
        "name": "Red Apple",
        "keywords": [
            "fruit",
            "mac",
            "school",
            "フルーツ",
            "マック",
            "学校"
        ],
        "skins": [
            {
                "unified": "1f34e",
                "native": "🍎"
            }
        ],
        "version": 1
    },
    "green_apple": {
        "id": "green_apple",
        "name": "Green Apple",
        "keywords": [
            "fruit",
            "nature",
            "フルーツ",
            "自然"
        ],
        "skins": [
            {
                "unified": "1f34f",
                "native": "🍏"
            }
        ],
        "version": 1
    },
    "pear": {
        "id": "pear",
        "name": "Pear",
        "keywords": [
            "fruit",
            "nature",
            "food",
            "フルーツ",
            "自然",
            "食べ物"
        ],
        "skins": [
            {
                "unified": "1f350",
                "native": "🍐"
            }
        ],
        "version": 1
    },
    "peach": {
        "id": "peach",
        "name": "Peach",
        "keywords": [
            "fruit",
            "nature",
            "food",
            "フルーツ",
            "自然",
            "食べ物"
        ],
        "skins": [
            {
                "unified": "1f351",
                "native": "🍑"
            }
        ],
        "version": 1
    },
    "cherries": {
        "id": "cherries",
        "name": "Cherries",
        "keywords": [
            "food",
            "fruit",
            "食べ物",
            "フルーツ"
        ],
        "skins": [
            {
                "unified": "1f352",
                "native": "🍒"
            }
        ],
        "version": 1
    },
    "strawberry": {
        "id": "strawberry",
        "name": "Strawberry",
        "keywords": [
            "fruit",
            "food",
            "nature",
            "フルーツ",
            "食べ物",
            "自然"
        ],
        "skins": [
            {
                "unified": "1f353",
                "native": "🍓"
            }
        ],
        "version": 1
    },
    "blueberries": {
        "id": "blueberries",
        "name": "Blueberries",
        "keywords": [
            "fruit",
            "フルーツ"
        ],
        "skins": [
            {
                "unified": "1fad0",
                "native": "🫐"
            }
        ],
        "version": 13
    },
    "kiwifruit": {
        "id": "kiwifruit",
        "name": "Kiwifruit",
        "keywords": [
            "kiwi",
            "fruit",
            "food",
            "キウイ",
            "フルーツ",
            "食べ物"
        ],
        "skins": [
            {
                "unified": "1f95d",
                "native": "🥝"
            }
        ],
        "version": 3
    },
    "tomato": {
        "id": "tomato",
        "name": "Tomato",
        "keywords": [
            "fruit",
            "vegetable",
            "nature",
            "food",
            "フルーツ",
            "野菜",
            "自然",
            "食べ物"
        ],
        "skins": [
            {
                "unified": "1f345",
                "native": "🍅"
            }
        ],
        "version": 1
    },
    "olive": {
        "id": "olive",
        "name": "Olive",
        "keywords": [
            "fruit",
            "フルーツ"
        ],
        "skins": [
            {
                "unified": "1fad2",
                "native": "🫒"
            }
        ],
        "version": 13
    },
    "coconut": {
        "id": "coconut",
        "name": "Coconut",
        "keywords": [
            "fruit",
            "nature",
            "food",
            "palm",
            "フルーツ",
            "自然",
            "食べ物",
            "やし"
        ],
        "skins": [
            {
                "unified": "1f965",
                "native": "🥥"
            }
        ],
        "version": 5
    },
    "avocado": {
        "id": "avocado",
        "name": "Avocado",
        "keywords": [
            "fruit",
            "food",
            "フルーツ",
            "食べ物"
        ],
        "skins": [
            {
                "unified": "1f951",
                "native": "🥑"
            }
        ],
        "version": 3
    },
    "eggplant": {
        "id": "eggplant",
        "name": "Eggplant",
        "keywords": [
            "vegetable",
            "nature",
            "food",
            "aubergine",
            "野菜",
            "自然",
            "食べ物",
            "茄子"
        ],
        "skins": [
            {
                "unified": "1f346",
                "native": "🍆"
            }
        ],
        "version": 1
    },
    "potato": {
        "id": "potato",
        "name": "Potato",
        "keywords": [
            "food",
            "tuber",
            "vegatable",
            "starch",
            "食べ物",
            "塊茎",
            "野菜",
            "スターチ"
        ],
        "skins": [
            {
                "unified": "1f954",
                "native": "🥔"
            }
        ],
        "version": 3
    },
    "carrot": {
        "id": "carrot",
        "name": "Carrot",
        "keywords": [
            "vegetable",
            "food",
            "orange",
            "野菜",
            "食べ物",
            "オレンジ"
        ],
        "skins": [
            {
                "unified": "1f955",
                "native": "🥕"
            }
        ],
        "version": 3
    },
    "corn": {
        "id": "corn",
        "name": "Ear of Corn",
        "keywords": [
            "food",
            "vegetable",
            "plant",
            "食べ物",
            "野菜",
            "植物"
        ],
        "skins": [
            {
                "unified": "1f33d",
                "native": "🌽"
            }
        ],
        "version": 1
    },
    "hot_pepper": {
        "id": "hot_pepper",
        "name": "Hot Pepper",
        "keywords": [
            "food",
            "spicy",
            "chilli",
            "chili",
            "食べ物",
            "辛い",
            "チリ",
            "チリ"
        ],
        "skins": [
            {
                "unified": "1f336-fe0f",
                "native": "🌶️"
            }
        ],
        "version": 1
    },
    "bell_pepper": {
        "id": "bell_pepper",
        "name": "Bell Pepper",
        "keywords": [
            "fruit",
            "plant",
            "フルーツ",
            "植物"
        ],
        "skins": [
            {
                "unified": "1fad1",
                "native": "🫑"
            }
        ],
        "version": 13
    },
    "cucumber": {
        "id": "cucumber",
        "name": "Cucumber",
        "keywords": [
            "fruit",
            "food",
            "pickle",
            "フルーツ",
            "食べ物",
            "漬物"
        ],
        "skins": [
            {
                "unified": "1f952",
                "native": "🥒"
            }
        ],
        "version": 3
    },
    "leafy_green": {
        "id": "leafy_green",
        "name": "Leafy Green",
        "keywords": [
            "food",
            "vegetable",
            "plant",
            "bok",
            "choy",
            "cabbage",
            "kale",
            "lettuce",
            "食べ物",
            "野菜",
            "植物",
            "ボク",
            "チョイ",
            "キャベツ",
            "ケール",
            "レタス"
        ],
        "skins": [
            {
                "unified": "1f96c",
                "native": "🥬"
            }
        ],
        "version": 11
    },
    "broccoli": {
        "id": "broccoli",
        "name": "Broccoli",
        "keywords": [
            "fruit",
            "food",
            "vegetable",
            "フルーツ",
            "食べ物",
            "野菜"
        ],
        "skins": [
            {
                "unified": "1f966",
                "native": "🥦"
            }
        ],
        "version": 5
    },
    "garlic": {
        "id": "garlic",
        "name": "Garlic",
        "keywords": [
            "food",
            "spice",
            "cook",
            "食べ物",
            "スパイス",
            "料理"
        ],
        "skins": [
            {
                "unified": "1f9c4",
                "native": "🧄"
            }
        ],
        "version": 12
    },
    "onion": {
        "id": "onion",
        "name": "Onion",
        "keywords": [
            "cook",
            "food",
            "spice",
            "料理",
            "食べ物",
            "スパイス"
        ],
        "skins": [
            {
                "unified": "1f9c5",
                "native": "🧅"
            }
        ],
        "version": 12
    },
    "mushroom": {
        "id": "mushroom",
        "name": "Mushroom",
        "keywords": [
            "plant",
            "vegetable",
            "植物",
            "野菜"
        ],
        "skins": [
            {
                "unified": "1f344",
                "native": "🍄"
            }
        ],
        "version": 1
    },
    "peanuts": {
        "id": "peanuts",
        "name": "Peanuts",
        "keywords": [
            "food",
            "nut",
            "食べ物",
            "ナット"
        ],
        "skins": [
            {
                "unified": "1f95c",
                "native": "🥜"
            }
        ],
        "version": 3
    },
    "beans": {
        "id": "beans",
        "name": "Beans",
        "keywords": [
            "food",
            "食べ物"
        ],
        "skins": [
            {
                "unified": "1fad8",
                "native": "🫘"
            }
        ],
        "version": 14
    },
    "chestnut": {
        "id": "chestnut",
        "name": "Chestnut",
        "keywords": [
            "food",
            "squirrel",
            "食べ物",
            "リス"
        ],
        "skins": [
            {
                "unified": "1f330",
                "native": "🌰"
            }
        ],
        "version": 1
    },
    "bread": {
        "id": "bread",
        "name": "Bread",
        "keywords": [
            "food",
            "wheat",
            "breakfast",
            "toast",
            "食べ物",
            "小麦",
            "朝食",
            "トースト"
        ],
        "skins": [
            {
                "unified": "1f35e",
                "native": "🍞"
            }
        ],
        "version": 1
    },
    "croissant": {
        "id": "croissant",
        "name": "Croissant",
        "keywords": [
            "food",
            "bread",
            "french",
            "食べ物",
            "パン",
            "フランス語"
        ],
        "skins": [
            {
                "unified": "1f950",
                "native": "🥐"
            }
        ],
        "version": 3
    },
    "baguette_bread": {
        "id": "baguette_bread",
        "name": "Baguette Bread",
        "keywords": [
            "food",
            "french",
            "食べ物",
            "フランス語"
        ],
        "skins": [
            {
                "unified": "1f956",
                "native": "🥖"
            }
        ],
        "version": 3
    },
    "flatbread": {
        "id": "flatbread",
        "name": "Flatbread",
        "keywords": [
            "flour",
            "food",
            "小麦粉",
            "食べ物"
        ],
        "skins": [
            {
                "unified": "1fad3",
                "native": "🫓"
            }
        ],
        "version": 13
    },
    "pretzel": {
        "id": "pretzel",
        "name": "Pretzel",
        "keywords": [
            "food",
            "bread",
            "twisted",
            "食べ物",
            "パン",
            "ねじれた"
        ],
        "skins": [
            {
                "unified": "1f968",
                "native": "🥨"
            }
        ],
        "version": 5
    },
    "bagel": {
        "id": "bagel",
        "name": "Bagel",
        "keywords": [
            "food",
            "bread",
            "bakery",
            "schmear",
            "食べ物",
            "パン",
            "ベーカリー",
            "シュミア"
        ],
        "skins": [
            {
                "unified": "1f96f",
                "native": "🥯"
            }
        ],
        "version": 11
    },
    "pancakes": {
        "id": "pancakes",
        "name": "Pancakes",
        "keywords": [
            "food",
            "breakfast",
            "flapjacks",
            "hotcakes",
            "食べ物",
            "朝食",
            "フラップジャック",
            "ホットケーキ"
        ],
        "skins": [
            {
                "unified": "1f95e",
                "native": "🥞"
            }
        ],
        "version": 3
    },
    "waffle": {
        "id": "waffle",
        "name": "Waffle",
        "keywords": [
            "food",
            "breakfast",
            "食べ物",
            "朝食"
        ],
        "skins": [
            {
                "unified": "1f9c7",
                "native": "🧇"
            }
        ],
        "version": 12
    },
    "cheese_wedge": {
        "id": "cheese_wedge",
        "name": "Cheese Wedge",
        "keywords": [
            "food",
            "chadder",
            "食べ物",
            "チャダー"
        ],
        "skins": [
            {
                "unified": "1f9c0",
                "native": "🧀"
            }
        ],
        "version": 1
    },
    "meat_on_bone": {
        "id": "meat_on_bone",
        "name": "Meat on Bone",
        "keywords": [
            "good",
            "food",
            "drumstick",
            "良い",
            "食べ物",
            "ドラムスティック"
        ],
        "skins": [
            {
                "unified": "1f356",
                "native": "🍖"
            }
        ],
        "version": 1
    },
    "poultry_leg": {
        "id": "poultry_leg",
        "name": "Poultry Leg",
        "keywords": [
            "food",
            "meat",
            "drumstick",
            "bird",
            "chicken",
            "turkey",
            "食べ物",
            "肉",
            "ドラムスティック",
            "鳥",
            "鶏",
            "七面鳥"
        ],
        "skins": [
            {
                "unified": "1f357",
                "native": "🍗"
            }
        ],
        "version": 1
    },
    "cut_of_meat": {
        "id": "cut_of_meat",
        "name": "Cut of Meat",
        "keywords": [
            "food",
            "cow",
            "chop",
            "lambchop",
            "porkchop",
            "食べ物",
            "牛",
            "チョップ",
            "ラムチョップ",
            "ポークチョップ"
        ],
        "skins": [
            {
                "unified": "1f969",
                "native": "🥩"
            }
        ],
        "version": 5
    },
    "bacon": {
        "id": "bacon",
        "name": "Bacon",
        "keywords": [
            "food",
            "breakfast",
            "pork",
            "pig",
            "meat",
            "食べ物",
            "朝食",
            "豚肉",
            "豚",
            "肉"
        ],
        "skins": [
            {
                "unified": "1f953",
                "native": "🥓"
            }
        ],
        "version": 3
    },
    "hamburger": {
        "id": "hamburger",
        "name": "Hamburger",
        "keywords": [
            "meat",
            "fast",
            "food",
            "beef",
            "cheeseburger",
            "mcdonalds",
            "burger",
            "king",
            "肉",
            "速い",
            "食べ物",
            "牛肉",
            "チーズバーガー",
            "マクドナルド",
            "バーガー",
            "王"
        ],
        "skins": [
            {
                "unified": "1f354",
                "native": "🍔"
            }
        ],
        "version": 1
    },
    "fries": {
        "id": "fries",
        "name": "French Fries",
        "keywords": [
            "chips",
            "snack",
            "fast",
            "food",
            "チップス",
            "スナック",
            "速い",
            "食べ物"
        ],
        "skins": [
            {
                "unified": "1f35f",
                "native": "🍟"
            }
        ],
        "version": 1
    },
    "pizza": {
        "id": "pizza",
        "name": "Pizza",
        "keywords": [
            "food",
            "party",
            "食べ物",
            "パーティ"
        ],
        "skins": [
            {
                "unified": "1f355",
                "native": "🍕"
            }
        ],
        "version": 1
    },
    "hotdog": {
        "id": "hotdog",
        "name": "Hot Dog",
        "keywords": [
            "hotdog",
            "food",
            "frankfurter",
            "ホットドッグ",
            "食べ物",
            "フランクフルト"
        ],
        "skins": [
            {
                "unified": "1f32d",
                "native": "🌭"
            }
        ],
        "version": 1
    },
    "sandwich": {
        "id": "sandwich",
        "name": "Sandwich",
        "keywords": [
            "food",
            "lunch",
            "bread",
            "食べ物",
            "ランチ",
            "パン"
        ],
        "skins": [
            {
                "unified": "1f96a",
                "native": "🥪"
            }
        ],
        "version": 5
    },
    "taco": {
        "id": "taco",
        "name": "Taco",
        "keywords": [
            "food",
            "mexican",
            "食べ物",
            "メキシコ人"
        ],
        "skins": [
            {
                "unified": "1f32e",
                "native": "🌮"
            }
        ],
        "version": 1
    },
    "burrito": {
        "id": "burrito",
        "name": "Burrito",
        "keywords": [
            "food",
            "mexican",
            "食べ物",
            "メキシコ人"
        ],
        "skins": [
            {
                "unified": "1f32f",
                "native": "🌯"
            }
        ],
        "version": 1
    },
    "tamale": {
        "id": "tamale",
        "name": "Tamale",
        "keywords": [
            "food",
            "masa",
            "食べ物",
            "マサ"
        ],
        "skins": [
            {
                "unified": "1fad4",
                "native": "🫔"
            }
        ],
        "version": 13
    },
    "stuffed_flatbread": {
        "id": "stuffed_flatbread",
        "name": "Stuffed Flatbread",
        "keywords": [
            "food",
            "gyro",
            "食べ物",
            "ジャイロ"
        ],
        "skins": [
            {
                "unified": "1f959",
                "native": "🥙"
            }
        ],
        "version": 3
    },
    "falafel": {
        "id": "falafel",
        "name": "Falafel",
        "keywords": [
            "food",
            "食べ物"
        ],
        "skins": [
            {
                "unified": "1f9c6",
                "native": "🧆"
            }
        ],
        "version": 12
    },
    "egg": {
        "id": "egg",
        "name": "Egg",
        "keywords": [
            "food",
            "chicken",
            "breakfast",
            "食べ物",
            "鶏",
            "朝食"
        ],
        "skins": [
            {
                "unified": "1f95a",
                "native": "🥚"
            }
        ],
        "version": 3
    },
    "fried_egg": {
        "id": "fried_egg",
        "name": "Cooking",
        "keywords": [
            "fried",
            "egg",
            "food",
            "breakfast",
            "kitchen",
            "揚げた",
            "卵",
            "食べ物",
            "朝食",
            "台所"
        ],
        "skins": [
            {
                "unified": "1f373",
                "native": "🍳"
            }
        ],
        "version": 1
    },
    "shallow_pan_of_food": {
        "id": "shallow_pan_of_food",
        "name": "Shallow Pan of Food",
        "keywords": [
            "cooking",
            "casserole",
            "paella",
            "料理",
            "キャセロール",
            "パエリヤ"
        ],
        "skins": [
            {
                "unified": "1f958",
                "native": "🥘"
            }
        ],
        "version": 3
    },
    "stew": {
        "id": "stew",
        "name": "Pot of Food",
        "keywords": [
            "stew",
            "meat",
            "soup",
            "シチュー",
            "肉",
            "スープ"
        ],
        "skins": [
            {
                "unified": "1f372",
                "native": "🍲"
            }
        ],
        "version": 1
    },
    "fondue": {
        "id": "fondue",
        "name": "Fondue",
        "keywords": [
            "cheese",
            "pot",
            "food",
            "チーズ",
            "ポット",
            "食べ物"
        ],
        "skins": [
            {
                "unified": "1fad5",
                "native": "🫕"
            }
        ],
        "version": 13
    },
    "bowl_with_spoon": {
        "id": "bowl_with_spoon",
        "name": "Bowl with Spoon",
        "keywords": [
            "food",
            "breakfast",
            "cereal",
            "oatmeal",
            "porridge",
            "食べ物",
            "朝食",
            "穀物",
            "オートミール",
            "おかゆ"
        ],
        "skins": [
            {
                "unified": "1f963",
                "native": "🥣"
            }
        ],
        "version": 5
    },
    "green_salad": {
        "id": "green_salad",
        "name": "Green Salad",
        "keywords": [
            "food",
            "healthy",
            "lettuce",
            "食べ物",
            "健康",
            "レタス"
        ],
        "skins": [
            {
                "unified": "1f957",
                "native": "🥗"
            }
        ],
        "version": 3
    },
    "popcorn": {
        "id": "popcorn",
        "name": "Popcorn",
        "keywords": [
            "food",
            "movie",
            "theater",
            "films",
            "snack",
            "食べ物",
            "映画",
            "シアター",
            "映画",
            "スナック"
        ],
        "skins": [
            {
                "unified": "1f37f",
                "native": "🍿"
            }
        ],
        "version": 1
    },
    "butter": {
        "id": "butter",
        "name": "Butter",
        "keywords": [
            "food",
            "cook",
            "食べ物",
            "料理"
        ],
        "skins": [
            {
                "unified": "1f9c8",
                "native": "🧈"
            }
        ],
        "version": 12
    },
    "salt": {
        "id": "salt",
        "name": "Salt",
        "keywords": [
            "condiment",
            "shaker",
            "調味料",
            "シェーカー"
        ],
        "skins": [
            {
                "unified": "1f9c2",
                "native": "🧂"
            }
        ],
        "version": 11
    },
    "canned_food": {
        "id": "canned_food",
        "name": "Canned Food",
        "keywords": [
            "soup",
            "スープ"
        ],
        "skins": [
            {
                "unified": "1f96b",
                "native": "🥫"
            }
        ],
        "version": 5
    },
    "bento": {
        "id": "bento",
        "name": "Bento Box",
        "keywords": [
            "food",
            "japanese",
            "食べ物",
            "日本"
        ],
        "skins": [
            {
                "unified": "1f371",
                "native": "🍱"
            }
        ],
        "version": 1
    },
    "rice_cracker": {
        "id": "rice_cracker",
        "name": "Rice Cracker",
        "keywords": [
            "food",
            "japanese",
            "食べ物",
            "日本"
        ],
        "skins": [
            {
                "unified": "1f358",
                "native": "🍘"
            }
        ],
        "version": 1
    },
    "rice_ball": {
        "id": "rice_ball",
        "name": "Rice Ball",
        "keywords": [
            "food",
            "japanese",
            "食べ物",
            "日本"
        ],
        "skins": [
            {
                "unified": "1f359",
                "native": "🍙"
            }
        ],
        "version": 1
    },
    "rice": {
        "id": "rice",
        "name": "Cooked Rice",
        "keywords": [
            "food",
            "china",
            "asian",
            "食べ物",
            "中国",
            "アジア人"
        ],
        "skins": [
            {
                "unified": "1f35a",
                "native": "🍚"
            }
        ],
        "version": 1
    },
    "curry": {
        "id": "curry",
        "name": "Curry Rice",
        "keywords": [
            "food",
            "spicy",
            "hot",
            "indian",
            "食べ物",
            "辛い",
            "熱い",
            "インディアン"
        ],
        "skins": [
            {
                "unified": "1f35b",
                "native": "🍛"
            }
        ],
        "version": 1
    },
    "ramen": {
        "id": "ramen",
        "name": "Steaming Bowl",
        "keywords": [
            "ramen",
            "food",
            "japanese",
            "noodle",
            "chopsticks",
            "拉麺",
            "食べ物",
            "日本",
            "麺",
            "箸"
        ],
        "skins": [
            {
                "unified": "1f35c",
                "native": "🍜"
            }
        ],
        "version": 1
    },
    "spaghetti": {
        "id": "spaghetti",
        "name": "Spaghetti",
        "keywords": [
            "food",
            "italian",
            "noodle",
            "食べ物",
            "イタリアの",
            "麺"
        ],
        "skins": [
            {
                "unified": "1f35d",
                "native": "🍝"
            }
        ],
        "version": 1
    },
    "sweet_potato": {
        "id": "sweet_potato",
        "name": "Roasted Sweet Potato",
        "keywords": [
            "food",
            "nature",
            "食べ物",
            "自然"
        ],
        "skins": [
            {
                "unified": "1f360",
                "native": "🍠"
            }
        ],
        "version": 1
    },
    "oden": {
        "id": "oden",
        "name": "Oden",
        "keywords": [
            "food",
            "japanese",
            "食べ物",
            "日本"
        ],
        "skins": [
            {
                "unified": "1f362",
                "native": "🍢"
            }
        ],
        "version": 1
    },
    "sushi": {
        "id": "sushi",
        "name": "Sushi",
        "keywords": [
            "food",
            "fish",
            "japanese",
            "rice",
            "食べ物",
            "魚",
            "日本",
            "米"
        ],
        "skins": [
            {
                "unified": "1f363",
                "native": "🍣"
            }
        ],
        "version": 1
    },
    "fried_shrimp": {
        "id": "fried_shrimp",
        "name": "Fried Shrimp",
        "keywords": [
            "food",
            "animal",
            "appetizer",
            "summer",
            "食べ物",
            "動物",
            "前菜",
            "夏"
        ],
        "skins": [
            {
                "unified": "1f364",
                "native": "🍤"
            }
        ],
        "version": 1
    },
    "fish_cake": {
        "id": "fish_cake",
        "name": "Fish Cake with Swirl",
        "keywords": [
            "food",
            "japan",
            "sea",
            "beach",
            "narutomaki",
            "pink",
            "kamaboko",
            "surimi",
            "ramen",
            "食べ物",
            "日本",
            "海",
            "ビーチ",
            "なるとまき",
            "ピンク",
            "かまぼこ",
            "すり身",
            "拉麺"
        ],
        "skins": [
            {
                "unified": "1f365",
                "native": "🍥"
            }
        ],
        "version": 1
    },
    "moon_cake": {
        "id": "moon_cake",
        "name": "Moon Cake",
        "keywords": [
            "food",
            "autumn",
            "食べ物",
            "秋"
        ],
        "skins": [
            {
                "unified": "1f96e",
                "native": "🥮"
            }
        ],
        "version": 11
    },
    "dango": {
        "id": "dango",
        "name": "Dango",
        "keywords": [
            "food",
            "dessert",
            "sweet",
            "japanese",
            "barbecue",
            "meat",
            "食べ物",
            "デザート",
            "甘い",
            "日本",
            "バーベキュー",
            "肉"
        ],
        "skins": [
            {
                "unified": "1f361",
                "native": "🍡"
            }
        ],
        "version": 1
    },
    "dumpling": {
        "id": "dumpling",
        "name": "Dumpling",
        "keywords": [
            "food",
            "empanada",
            "pierogi",
            "potsticker",
            "食べ物",
            "エンパナーダ",
            "ピエロギ",
            "ポットステッカー"
        ],
        "skins": [
            {
                "unified": "1f95f",
                "native": "🥟"
            }
        ],
        "version": 5
    },
    "fortune_cookie": {
        "id": "fortune_cookie",
        "name": "Fortune Cookie",
        "keywords": [
            "food",
            "prophecy",
            "食べ物",
            "預言"
        ],
        "skins": [
            {
                "unified": "1f960",
                "native": "🥠"
            }
        ],
        "version": 5
    },
    "takeout_box": {
        "id": "takeout_box",
        "name": "Takeout Box",
        "keywords": [
            "food",
            "leftovers",
            "食べ物",
            "残り物"
        ],
        "skins": [
            {
                "unified": "1f961",
                "native": "🥡"
            }
        ],
        "version": 5
    },
    "crab": {
        "id": "crab",
        "name": "Crab",
        "keywords": [
            "animal",
            "crustacean",
            "動物",
            "甲殻類"
        ],
        "skins": [
            {
                "unified": "1f980",
                "native": "🦀"
            }
        ],
        "version": 1
    },
    "lobster": {
        "id": "lobster",
        "name": "Lobster",
        "keywords": [
            "animal",
            "nature",
            "bisque",
            "claws",
            "seafood",
            "動物",
            "自然",
            "ビスク",
            "爪",
            "シーフード"
        ],
        "skins": [
            {
                "unified": "1f99e",
                "native": "🦞"
            }
        ],
        "version": 11
    },
    "shrimp": {
        "id": "shrimp",
        "name": "Shrimp",
        "keywords": [
            "animal",
            "ocean",
            "nature",
            "seafood",
            "動物",
            "海洋",
            "自然",
            "シーフード"
        ],
        "skins": [
            {
                "unified": "1f990",
                "native": "🦐"
            }
        ],
        "version": 3
    },
    "squid": {
        "id": "squid",
        "name": "Squid",
        "keywords": [
            "animal",
            "nature",
            "ocean",
            "sea",
            "動物",
            "自然",
            "海洋",
            "海"
        ],
        "skins": [
            {
                "unified": "1f991",
                "native": "🦑"
            }
        ],
        "version": 3
    },
    "oyster": {
        "id": "oyster",
        "name": "Oyster",
        "keywords": [
            "food",
            "食べ物"
        ],
        "skins": [
            {
                "unified": "1f9aa",
                "native": "🦪"
            }
        ],
        "version": 12
    },
    "icecream": {
        "id": "icecream",
        "name": "Soft Ice Cream",
        "keywords": [
            "icecream",
            "food",
            "hot",
            "dessert",
            "summer",
            "アイスクリーム",
            "食べ物",
            "熱い",
            "デザート",
            "夏"
        ],
        "skins": [
            {
                "unified": "1f366",
                "native": "🍦"
            }
        ],
        "version": 1
    },
    "shaved_ice": {
        "id": "shaved_ice",
        "name": "Shaved Ice",
        "keywords": [
            "hot",
            "dessert",
            "summer",
            "熱い",
            "デザート",
            "夏"
        ],
        "skins": [
            {
                "unified": "1f367",
                "native": "🍧"
            }
        ],
        "version": 1
    },
    "ice_cream": {
        "id": "ice_cream",
        "name": "Ice Cream",
        "keywords": [
            "food",
            "hot",
            "dessert",
            "食べ物",
            "熱い",
            "デザート"
        ],
        "skins": [
            {
                "unified": "1f368",
                "native": "🍨"
            }
        ],
        "version": 1
    },
    "doughnut": {
        "id": "doughnut",
        "name": "Doughnut",
        "keywords": [
            "food",
            "dessert",
            "snack",
            "sweet",
            "donut",
            "食べ物",
            "デザート",
            "スナック",
            "甘い",
            "ドーナツ"
        ],
        "skins": [
            {
                "unified": "1f369",
                "native": "🍩"
            }
        ],
        "version": 1
    },
    "cookie": {
        "id": "cookie",
        "name": "Cookie",
        "keywords": [
            "food",
            "snack",
            "oreo",
            "chocolate",
            "sweet",
            "dessert",
            "食べ物",
            "スナック",
            "オレオ",
            "チョコレート",
            "甘い",
            "デザート"
        ],
        "skins": [
            {
                "unified": "1f36a",
                "native": "🍪"
            }
        ],
        "version": 1
    },
    "birthday": {
        "id": "birthday",
        "name": "Birthday Cake",
        "keywords": [
            "food",
            "dessert",
            "食べ物",
            "デザート"
        ],
        "skins": [
            {
                "unified": "1f382",
                "native": "🎂"
            }
        ],
        "version": 1
    },
    "cake": {
        "id": "cake",
        "name": "Shortcake",
        "keywords": [
            "cake",
            "food",
            "dessert",
            "ケーキ",
            "食べ物",
            "デザート"
        ],
        "skins": [
            {
                "unified": "1f370",
                "native": "🍰"
            }
        ],
        "version": 1
    },
    "cupcake": {
        "id": "cupcake",
        "name": "Cupcake",
        "keywords": [
            "food",
            "dessert",
            "bakery",
            "sweet",
            "食べ物",
            "デザート",
            "ベーカリー",
            "甘い"
        ],
        "skins": [
            {
                "unified": "1f9c1",
                "native": "🧁"
            }
        ],
        "version": 11
    },
    "pie": {
        "id": "pie",
        "name": "Pie",
        "keywords": [
            "food",
            "dessert",
            "pastry",
            "食べ物",
            "デザート",
            "ペストリー"
        ],
        "skins": [
            {
                "unified": "1f967",
                "native": "🥧"
            }
        ],
        "version": 5
    },
    "chocolate_bar": {
        "id": "chocolate_bar",
        "name": "Chocolate Bar",
        "keywords": [
            "food",
            "snack",
            "dessert",
            "sweet",
            "食べ物",
            "スナック",
            "デザート",
            "甘い"
        ],
        "skins": [
            {
                "unified": "1f36b",
                "native": "🍫"
            }
        ],
        "version": 1
    },
    "candy": {
        "id": "candy",
        "name": "Candy",
        "keywords": [
            "snack",
            "dessert",
            "sweet",
            "lolly",
            "スナック",
            "デザート",
            "甘い",
            "ロリ"
        ],
        "skins": [
            {
                "unified": "1f36c",
                "native": "🍬"
            }
        ],
        "version": 1
    },
    "lollipop": {
        "id": "lollipop",
        "name": "Lollipop",
        "keywords": [
            "food",
            "snack",
            "candy",
            "sweet",
            "食べ物",
            "スナック",
            "あめ",
            "甘い"
        ],
        "skins": [
            {
                "unified": "1f36d",
                "native": "🍭"
            }
        ],
        "version": 1
    },
    "custard": {
        "id": "custard",
        "name": "Custard",
        "keywords": [
            "dessert",
            "food",
            "デザート",
            "食べ物"
        ],
        "skins": [
            {
                "unified": "1f36e",
                "native": "🍮"
            }
        ],
        "version": 1
    },
    "honey_pot": {
        "id": "honey_pot",
        "name": "Honey Pot",
        "keywords": [
            "bees",
            "sweet",
            "kitchen",
            "ミツバチ",
            "甘い",
            "台所"
        ],
        "skins": [
            {
                "unified": "1f36f",
                "native": "🍯"
            }
        ],
        "version": 1
    },
    "baby_bottle": {
        "id": "baby_bottle",
        "name": "Baby Bottle",
        "keywords": [
            "food",
            "container",
            "milk",
            "食べ物",
            "容器",
            "牛乳"
        ],
        "skins": [
            {
                "unified": "1f37c",
                "native": "🍼"
            }
        ],
        "version": 1
    },
    "glass_of_milk": {
        "id": "glass_of_milk",
        "name": "Glass of Milk",
        "keywords": [
            "beverage",
            "drink",
            "cow",
            "飲み物",
            "飲む",
            "牛"
        ],
        "skins": [
            {
                "unified": "1f95b",
                "native": "🥛"
            }
        ],
        "version": 3
    },
    "coffee": {
        "id": "coffee",
        "name": "Hot Beverage",
        "keywords": [
            "coffee",
            "caffeine",
            "latte",
            "espresso",
            "コーヒー",
            "カフェイン",
            "ラテ",
            "エスプレッソ"
        ],
        "skins": [
            {
                "unified": "2615",
                "native": "☕"
            }
        ],
        "version": 1
    },
    "teapot": {
        "id": "teapot",
        "name": "Teapot",
        "keywords": [
            "drink",
            "hot",
            "飲む",
            "熱い"
        ],
        "skins": [
            {
                "unified": "1fad6",
                "native": "🫖"
            }
        ],
        "version": 13
    },
    "tea": {
        "id": "tea",
        "name": "Teacup Without Handle",
        "keywords": [
            "tea",
            "drink",
            "bowl",
            "breakfast",
            "green",
            "british",
            "お茶",
            "飲む",
            "丼鉢",
            "朝食",
            "緑",
            "イギリス人"
        ],
        "skins": [
            {
                "unified": "1f375",
                "native": "🍵"
            }
        ],
        "version": 1
    },
    "sake": {
        "id": "sake",
        "name": "Sake",
        "keywords": [
            "wine",
            "drink",
            "drunk",
            "beverage",
            "japanese",
            "alcohol",
            "booze",
            "ワイン",
            "飲む",
            "酔っ払い",
            "飲み物",
            "日本",
            "アルコール",
            "酒"
        ],
        "skins": [
            {
                "unified": "1f376",
                "native": "🍶"
            }
        ],
        "version": 1
    },
    "champagne": {
        "id": "champagne",
        "name": "Bottle with Popping Cork",
        "keywords": [
            "champagne",
            "drink",
            "wine",
            "celebration",
            "シャンパン",
            "飲む",
            "ワイン",
            "お祝い"
        ],
        "skins": [
            {
                "unified": "1f37e",
                "native": "🍾"
            }
        ],
        "version": 1
    },
    "wine_glass": {
        "id": "wine_glass",
        "name": "Wine Glass",
        "keywords": [
            "drink",
            "beverage",
            "drunk",
            "alcohol",
            "booze",
            "飲む",
            "飲み物",
            "酔っ払い",
            "アルコール",
            "酒"
        ],
        "skins": [
            {
                "unified": "1f377",
                "native": "🍷"
            }
        ],
        "version": 1
    },
    "cocktail": {
        "id": "cocktail",
        "name": "Cocktail Glass",
        "keywords": [
            "drink",
            "drunk",
            "alcohol",
            "beverage",
            "booze",
            "mojito",
            "飲む",
            "酔っ払い",
            "アルコール",
            "飲み物",
            "酒",
            "モヒート"
        ],
        "skins": [
            {
                "unified": "1f378",
                "native": "🍸"
            }
        ],
        "version": 1
    },
    "tropical_drink": {
        "id": "tropical_drink",
        "name": "Tropical Drink",
        "keywords": [
            "beverage",
            "cocktail",
            "summer",
            "beach",
            "alcohol",
            "booze",
            "mojito",
            "飲み物",
            "カクテル",
            "夏",
            "ビーチ",
            "アルコール",
            "酒",
            "モヒート"
        ],
        "skins": [
            {
                "unified": "1f379",
                "native": "🍹"
            }
        ],
        "version": 1
    },
    "beer": {
        "id": "beer",
        "name": "Beer Mug",
        "keywords": [
            "relax",
            "beverage",
            "drink",
            "drunk",
            "party",
            "pub",
            "summer",
            "alcohol",
            "booze",
            "リラックス",
            "飲み物",
            "飲む",
            "酔っ払い",
            "パーティ",
            "パブ",
            "夏",
            "アルコール",
            "酒"
        ],
        "skins": [
            {
                "unified": "1f37a",
                "native": "🍺"
            }
        ],
        "version": 1
    },
    "beers": {
        "id": "beers",
        "name": "Clinking Beer Mugs",
        "keywords": [
            "beers",
            "relax",
            "beverage",
            "drink",
            "drunk",
            "party",
            "pub",
            "summer",
            "alcohol",
            "booze",
            "ビール",
            "リラックス",
            "飲み物",
            "飲む",
            "酔っ払い",
            "パーティ",
            "パブ",
            "夏",
            "アルコール",
            "酒"
        ],
        "skins": [
            {
                "unified": "1f37b",
                "native": "🍻"
            }
        ],
        "version": 1
    },
    "clinking_glasses": {
        "id": "clinking_glasses",
        "name": "Clinking Glasses",
        "keywords": [
            "beverage",
            "drink",
            "party",
            "alcohol",
            "celebrate",
            "cheers",
            "wine",
            "champagne",
            "toast",
            "飲み物",
            "飲む",
            "パーティ",
            "アルコール",
            "祝う",
            "乾杯",
            "ワイン",
            "シャンパン",
            "トースト"
        ],
        "skins": [
            {
                "unified": "1f942",
                "native": "🥂"
            }
        ],
        "version": 3
    },
    "tumbler_glass": {
        "id": "tumbler_glass",
        "name": "Tumbler Glass",
        "keywords": [
            "drink",
            "beverage",
            "drunk",
            "alcohol",
            "liquor",
            "booze",
            "bourbon",
            "scotch",
            "whisky",
            "shot",
            "飲む",
            "飲み物",
            "酔っ払い",
            "アルコール",
            "酒",
            "酒",
            "バーボン",
            "スコッチ",
            "ウィスキー",
            "ショット"
        ],
        "skins": [
            {
                "unified": "1f943",
                "native": "🥃"
            }
        ],
        "version": 3
    },
    "pouring_liquid": {
        "id": "pouring_liquid",
        "name": "Pouring Liquid",
        "keywords": [
            "cup",
            "water",
            "カップ",
            "水"
        ],
        "skins": [
            {
                "unified": "1fad7",
                "native": "🫗"
            }
        ],
        "version": 14
    },
    "cup_with_straw": {
        "id": "cup_with_straw",
        "name": "Cup with Straw",
        "keywords": [
            "drink",
            "soda",
            "飲む",
            "ソーダ"
        ],
        "skins": [
            {
                "unified": "1f964",
                "native": "🥤"
            }
        ],
        "version": 5
    },
    "bubble_tea": {
        "id": "bubble_tea",
        "name": "Bubble Tea",
        "keywords": [
            "taiwan",
            "boba",
            "milk",
            "straw",
            "台湾",
            "ボバ",
            "牛乳",
            "ストロー"
        ],
        "skins": [
            {
                "unified": "1f9cb",
                "native": "🧋"
            }
        ],
        "version": 13
    },
    "beverage_box": {
        "id": "beverage_box",
        "name": "Beverage Box",
        "keywords": [
            "drink",
            "飲む"
        ],
        "skins": [
            {
                "unified": "1f9c3",
                "native": "🧃"
            }
        ],
        "version": 12
    },
    "mate_drink": {
        "id": "mate_drink",
        "name": "Mate",
        "keywords": [
            "drink",
            "tea",
            "beverage",
            "飲む",
            "お茶",
            "飲み物"
        ],
        "skins": [
            {
                "unified": "1f9c9",
                "native": "🧉"
            }
        ],
        "version": 12
    },
    "ice_cube": {
        "id": "ice_cube",
        "name": "Ice",
        "keywords": [
            "cube",
            "water",
            "cold",
            "立方体",
            "水",
            "寒い"
        ],
        "skins": [
            {
                "unified": "1f9ca",
                "native": "🧊"
            }
        ],
        "version": 12
    },
    "chopsticks": {
        "id": "chopsticks",
        "name": "Chopsticks",
        "keywords": [
            "food",
            "食べ物"
        ],
        "skins": [
            {
                "unified": "1f962",
                "native": "🥢"
            }
        ],
        "version": 5
    },
    "knife_fork_plate": {
        "id": "knife_fork_plate",
        "name": "Fork and Knife with Plate",
        "keywords": [
            "food",
            "eat",
            "meal",
            "lunch",
            "dinner",
            "restaurant",
            "食べ物",
            "食べる",
            "食事",
            "ランチ",
            "夕食",
            "レストラン"
        ],
        "skins": [
            {
                "unified": "1f37d-fe0f",
                "native": "🍽️"
            }
        ],
        "version": 1
    },
    "fork_and_knife": {
        "id": "fork_and_knife",
        "name": "Fork and Knife",
        "keywords": [
            "cutlery",
            "kitchen",
            "カトラリー",
            "台所"
        ],
        "skins": [
            {
                "unified": "1f374",
                "native": "🍴"
            }
        ],
        "version": 1
    },
    "spoon": {
        "id": "spoon",
        "name": "Spoon",
        "keywords": [
            "cutlery",
            "kitchen",
            "tableware",
            "カトラリー",
            "台所",
            "食器"
        ],
        "skins": [
            {
                "unified": "1f944",
                "native": "🥄"
            }
        ],
        "version": 3
    },
    "hocho": {
        "id": "hocho",
        "name": "Hocho",
        "keywords": [
            "knife",
            "kitchen",
            "blade",
            "cutlery",
            "weapon",
            "ナイフ",
            "台所",
            "刃",
            "カトラリー",
            "武器"
        ],
        "skins": [
            {
                "unified": "1f52a",
                "native": "🔪"
            }
        ],
        "version": 1
    },
    "jar": {
        "id": "jar",
        "name": "Jar",
        "keywords": [
            "container",
            "sauce",
            "容器",
            "ソース"
        ],
        "skins": [
            {
                "unified": "1fad9",
                "native": "🫙"
            }
        ],
        "version": 14
    },
    "amphora": {
        "id": "amphora",
        "name": "Amphora",
        "keywords": [
            "vase",
            "jar",
            "花瓶",
            "つぼ"
        ],
        "skins": [
            {
                "unified": "1f3fa",
                "native": "🏺"
            }
        ],
        "version": 1
    },
    "earth_africa": {
        "id": "earth_africa",
        "name": "Earth Globe Europe-Africa",
        "keywords": [
            "africa",
            "showing",
            "europe",
            "world",
            "international",
            "アフリカ",
            "表示",
            "ヨーロッパ",
            "世界",
            "国際的"
        ],
        "skins": [
            {
                "unified": "1f30d",
                "native": "🌍"
            }
        ],
        "version": 1
    },
    "earth_americas": {
        "id": "earth_americas",
        "name": "Earth Globe Americas",
        "keywords": [
            "showing",
            "world",
            "USA",
            "international",
            "表示",
            "世界",
            "アメリカ合衆国",
            "国際的"
        ],
        "skins": [
            {
                "unified": "1f30e",
                "native": "🌎"
            }
        ],
        "version": 1
    },
    "earth_asia": {
        "id": "earth_asia",
        "name": "Earth Globe Asia-Australia",
        "keywords": [
            "asia",
            "showing",
            "australia",
            "world",
            "east",
            "international",
            "アジア",
            "表示",
            "オーストラリア",
            "世界",
            "東",
            "国際的"
        ],
        "skins": [
            {
                "unified": "1f30f",
                "native": "🌏"
            }
        ],
        "version": 1
    },
    "globe_with_meridians": {
        "id": "globe_with_meridians",
        "name": "Globe with Meridians",
        "keywords": [
            "earth",
            "international",
            "world",
            "internet",
            "interweb",
            "i18n",
            "地球",
            "国際的",
            "世界",
            "インターネット",
            "インターウェブ",
            "i18n"
        ],
        "skins": [
            {
                "unified": "1f310",
                "native": "🌐"
            }
        ],
        "version": 1
    },
    "world_map": {
        "id": "world_map",
        "name": "World Map",
        "keywords": [
            "location",
            "direction",
            "位置",
            "方向"
        ],
        "skins": [
            {
                "unified": "1f5fa-fe0f",
                "native": "🗺️"
            }
        ],
        "version": 1
    },
    "japan": {
        "id": "japan",
        "name": "Map of Japan",
        "keywords": [
            "nation",
            "country",
            "japanese",
            "asia",
            "国家",
            "国",
            "日本",
            "アジア"
        ],
        "skins": [
            {
                "unified": "1f5fe",
                "native": "🗾"
            }
        ],
        "version": 1
    },
    "compass": {
        "id": "compass",
        "name": "Compass",
        "keywords": [
            "magnetic",
            "navigation",
            "orienteering",
            "磁気",
            "ナビゲーション",
            "オリエンテーリング"
        ],
        "skins": [
            {
                "unified": "1f9ed",
                "native": "🧭"
            }
        ],
        "version": 11
    },
    "snow_capped_mountain": {
        "id": "snow_capped_mountain",
        "name": "Snow-Capped Mountain",
        "keywords": [
            "snow",
            "capped",
            "photo",
            "nature",
            "environment",
            "winter",
            "cold",
            "雪",
            "キャップ",
            "写真",
            "自然",
            "環境",
            "冬",
            "寒い"
        ],
        "skins": [
            {
                "unified": "1f3d4-fe0f",
                "native": "🏔️"
            }
        ],
        "version": 1
    },
    "mountain": {
        "id": "mountain",
        "name": "Mountain",
        "keywords": [
            "photo",
            "nature",
            "environment",
            "写真",
            "自然",
            "環境"
        ],
        "skins": [
            {
                "unified": "26f0-fe0f",
                "native": "⛰️"
            }
        ],
        "version": 1
    },
    "volcano": {
        "id": "volcano",
        "name": "Volcano",
        "keywords": [
            "photo",
            "nature",
            "disaster",
            "写真",
            "自然",
            "災害"
        ],
        "skins": [
            {
                "unified": "1f30b",
                "native": "🌋"
            }
        ],
        "version": 1
    },
    "mount_fuji": {
        "id": "mount_fuji",
        "name": "Mount Fuji",
        "keywords": [
            "photo",
            "mountain",
            "nature",
            "japanese",
            "写真",
            "山",
            "自然",
            "日本"
        ],
        "skins": [
            {
                "unified": "1f5fb",
                "native": "🗻"
            }
        ],
        "version": 1
    },
    "camping": {
        "id": "camping",
        "name": "Camping",
        "keywords": [
            "photo",
            "outdoors",
            "tent",
            "写真",
            "屋外",
            "テント"
        ],
        "skins": [
            {
                "unified": "1f3d5-fe0f",
                "native": "🏕️"
            }
        ],
        "version": 1
    },
    "beach_with_umbrella": {
        "id": "beach_with_umbrella",
        "name": "Beach with Umbrella",
        "keywords": [
            "weather",
            "summer",
            "sunny",
            "sand",
            "mojito",
            "天気",
            "夏",
            "晴れ",
            "砂",
            "モヒート"
        ],
        "skins": [
            {
                "unified": "1f3d6-fe0f",
                "native": "🏖️"
            }
        ],
        "version": 1
    },
    "desert": {
        "id": "desert",
        "name": "Desert",
        "keywords": [
            "photo",
            "warm",
            "saharah",
            "写真",
            "暖かい",
            "サハラ"
        ],
        "skins": [
            {
                "unified": "1f3dc-fe0f",
                "native": "🏜️"
            }
        ],
        "version": 1
    },
    "desert_island": {
        "id": "desert_island",
        "name": "Desert Island",
        "keywords": [
            "photo",
            "tropical",
            "mojito",
            "写真",
            "トロピカル",
            "モヒート"
        ],
        "skins": [
            {
                "unified": "1f3dd-fe0f",
                "native": "🏝️"
            }
        ],
        "version": 1
    },
    "national_park": {
        "id": "national_park",
        "name": "National Park",
        "keywords": [
            "photo",
            "environment",
            "nature",
            "写真",
            "環境",
            "自然"
        ],
        "skins": [
            {
                "unified": "1f3de-fe0f",
                "native": "🏞️"
            }
        ],
        "version": 1
    },
    "stadium": {
        "id": "stadium",
        "name": "Stadium",
        "keywords": [
            "photo",
            "place",
            "sports",
            "concert",
            "venue",
            "写真",
            "場所",
            "スポーツ",
            "コンサート",
            "会場"
        ],
        "skins": [
            {
                "unified": "1f3df-fe0f",
                "native": "🏟️"
            }
        ],
        "version": 1
    },
    "classical_building": {
        "id": "classical_building",
        "name": "Classical Building",
        "keywords": [
            "art",
            "culture",
            "history",
            "美術",
            "文化",
            "歴史"
        ],
        "skins": [
            {
                "unified": "1f3db-fe0f",
                "native": "🏛️"
            }
        ],
        "version": 1
    },
    "building_construction": {
        "id": "building_construction",
        "name": "Building Construction",
        "keywords": [
            "wip",
            "working",
            "progress",
            "ワイプ",
            "働く",
            "進捗"
        ],
        "skins": [
            {
                "unified": "1f3d7-fe0f",
                "native": "🏗️"
            }
        ],
        "version": 1
    },
    "bricks": {
        "id": "bricks",
        "name": "Brick",
        "keywords": [
            "bricks",
            "レンガ"
        ],
        "skins": [
            {
                "unified": "1f9f1",
                "native": "🧱"
            }
        ],
        "version": 11
    },
    "rock": {
        "id": "rock",
        "name": "Rock",
        "keywords": [
            "stone",
            "結石"
        ],
        "skins": [
            {
                "unified": "1faa8",
                "native": "🪨"
            }
        ],
        "version": 13
    },
    "wood": {
        "id": "wood",
        "name": "Wood",
        "keywords": [
            "nature",
            "timber",
            "trunk",
            "自然",
            "木材",
            "トランク"
        ],
        "skins": [
            {
                "unified": "1fab5",
                "native": "🪵"
            }
        ],
        "version": 13
    },
    "hut": {
        "id": "hut",
        "name": "Hut",
        "keywords": [
            "house",
            "structure",
            "家",
            "構造"
        ],
        "skins": [
            {
                "unified": "1f6d6",
                "native": "🛖"
            }
        ],
        "version": 13
    },
    "house_buildings": {
        "id": "house_buildings",
        "name": "Houses",
        "keywords": [
            "house",
            "buildings",
            "photo",
            "家",
            "建物",
            "写真"
        ],
        "skins": [
            {
                "unified": "1f3d8-fe0f",
                "native": "🏘️"
            }
        ],
        "version": 1
    },
    "derelict_house_building": {
        "id": "derelict_house_building",
        "name": "Derelict House",
        "keywords": [
            "building",
            "abandon",
            "evict",
            "broken",
            "建物",
            "放棄する",
            "追い出す",
            "壊れた"
        ],
        "skins": [
            {
                "unified": "1f3da-fe0f",
                "native": "🏚️"
            }
        ],
        "version": 1
    },
    "house": {
        "id": "house",
        "name": "House",
        "keywords": [
            "building",
            "home",
            "建物",
            "家"
        ],
        "skins": [
            {
                "unified": "1f3e0",
                "native": "🏠"
            }
        ],
        "version": 1
    },
    "house_with_garden": {
        "id": "house_with_garden",
        "name": "House with Garden",
        "keywords": [
            "home",
            "plant",
            "nature",
            "家",
            "植物",
            "自然"
        ],
        "skins": [
            {
                "unified": "1f3e1",
                "native": "🏡"
            }
        ],
        "version": 1
    },
    "office": {
        "id": "office",
        "name": "Office Building",
        "keywords": [
            "bureau",
            "work",
            "局",
            "仕事"
        ],
        "skins": [
            {
                "unified": "1f3e2",
                "native": "🏢"
            }
        ],
        "version": 1
    },
    "post_office": {
        "id": "post_office",
        "name": "Japanese Post Office",
        "keywords": [
            "building",
            "envelope",
            "communication",
            "建物",
            "封筒",
            "コミュニケーション"
        ],
        "skins": [
            {
                "unified": "1f3e3",
                "native": "🏣"
            }
        ],
        "version": 1
    },
    "european_post_office": {
        "id": "european_post_office",
        "name": "Post Office",
        "keywords": [
            "european",
            "building",
            "email",
            "ヨーロッパ",
            "建物",
            "Eメール"
        ],
        "skins": [
            {
                "unified": "1f3e4",
                "native": "🏤"
            }
        ],
        "version": 1
    },
    "hospital": {
        "id": "hospital",
        "name": "Hospital",
        "keywords": [
            "building",
            "health",
            "surgery",
            "doctor",
            "建物",
            "健康",
            "手術",
            "医者"
        ],
        "skins": [
            {
                "unified": "1f3e5",
                "native": "🏥"
            }
        ],
        "version": 1
    },
    "bank": {
        "id": "bank",
        "name": "Bank",
        "keywords": [
            "building",
            "money",
            "sales",
            "cash",
            "business",
            "enterprise",
            "建物",
            "お金",
            "販売",
            "現金",
            "仕事",
            "企業"
        ],
        "skins": [
            {
                "unified": "1f3e6",
                "native": "🏦"
            }
        ],
        "version": 1
    },
    "hotel": {
        "id": "hotel",
        "name": "Hotel",
        "keywords": [
            "building",
            "accomodation",
            "checkin",
            "建物",
            "宿泊施設",
            "チェックイン"
        ],
        "skins": [
            {
                "unified": "1f3e8",
                "native": "🏨"
            }
        ],
        "version": 1
    },
    "love_hotel": {
        "id": "love_hotel",
        "name": "Love Hotel",
        "keywords": [
            "like",
            "affection",
            "dating",
            "好き",
            "愛情",
            "デート"
        ],
        "skins": [
            {
                "unified": "1f3e9",
                "native": "🏩"
            }
        ],
        "version": 1
    },
    "convenience_store": {
        "id": "convenience_store",
        "name": "Convenience Store",
        "keywords": [
            "building",
            "shopping",
            "groceries",
            "建物",
            "買い物",
            "食料品"
        ],
        "skins": [
            {
                "unified": "1f3ea",
                "native": "🏪"
            }
        ],
        "version": 1
    },
    "school": {
        "id": "school",
        "name": "School",
        "keywords": [
            "building",
            "student",
            "education",
            "learn",
            "teach",
            "建物",
            "学生",
            "教育",
            "学び",
            "教える"
        ],
        "skins": [
            {
                "unified": "1f3eb",
                "native": "🏫"
            }
        ],
        "version": 1
    },
    "department_store": {
        "id": "department_store",
        "name": "Department Store",
        "keywords": [
            "building",
            "shopping",
            "mall",
            "建物",
            "買い物",
            "モール"
        ],
        "skins": [
            {
                "unified": "1f3ec",
                "native": "🏬"
            }
        ],
        "version": 1
    },
    "factory": {
        "id": "factory",
        "name": "Factory",
        "keywords": [
            "building",
            "industry",
            "pollution",
            "smoke",
            "建物",
            "業界",
            "汚染",
            "煙"
        ],
        "skins": [
            {
                "unified": "1f3ed",
                "native": "🏭"
            }
        ],
        "version": 1
    },
    "japanese_castle": {
        "id": "japanese_castle",
        "name": "Japanese Castle",
        "keywords": [
            "photo",
            "building",
            "写真",
            "建物"
        ],
        "skins": [
            {
                "unified": "1f3ef",
                "native": "🏯"
            }
        ],
        "version": 1
    },
    "european_castle": {
        "id": "european_castle",
        "name": "Castle",
        "keywords": [
            "european",
            "building",
            "royalty",
            "history",
            "ヨーロッパ",
            "建物",
            "ロイヤリティ",
            "歴史"
        ],
        "skins": [
            {
                "unified": "1f3f0",
                "native": "🏰"
            }
        ],
        "version": 1
    },
    "wedding": {
        "id": "wedding",
        "name": "Wedding",
        "keywords": [
            "love",
            "like",
            "affection",
            "couple",
            "marriage",
            "bride",
            "groom",
            "愛",
            "好き",
            "愛情",
            "カップル",
            "結婚",
            "花嫁",
            "新郎"
        ],
        "skins": [
            {
                "unified": "1f492",
                "native": "💒"
            }
        ],
        "version": 1
    },
    "tokyo_tower": {
        "id": "tokyo_tower",
        "name": "Tokyo Tower",
        "keywords": [
            "photo",
            "japanese",
            "写真",
            "日本"
        ],
        "skins": [
            {
                "unified": "1f5fc",
                "native": "🗼"
            }
        ],
        "version": 1
    },
    "statue_of_liberty": {
        "id": "statue_of_liberty",
        "name": "Statue of Liberty",
        "keywords": [
            "american",
            "newyork",
            "アメリカ",
            "ニューヨーク"
        ],
        "skins": [
            {
                "unified": "1f5fd",
                "native": "🗽"
            }
        ],
        "version": 1
    },
    "church": {
        "id": "church",
        "name": "Church",
        "keywords": [
            "building",
            "religion",
            "christ",
            "建物",
            "宗教",
            "キリスト"
        ],
        "skins": [
            {
                "unified": "26ea",
                "native": "⛪"
            }
        ],
        "version": 1
    },
    "mosque": {
        "id": "mosque",
        "name": "Mosque",
        "keywords": [
            "islam",
            "worship",
            "minaret",
            "イスラム教",
            "崇拝",
            "ミナレット"
        ],
        "skins": [
            {
                "unified": "1f54c",
                "native": "🕌"
            }
        ],
        "version": 1
    },
    "hindu_temple": {
        "id": "hindu_temple",
        "name": "Hindu Temple",
        "keywords": [
            "religion",
            "宗教"
        ],
        "skins": [
            {
                "unified": "1f6d5",
                "native": "🛕"
            }
        ],
        "version": 12
    },
    "synagogue": {
        "id": "synagogue",
        "name": "Synagogue",
        "keywords": [
            "judaism",
            "worship",
            "temple",
            "jewish",
            "ユダヤ教",
            "崇拝",
            "寺",
            "ユダヤ人"
        ],
        "skins": [
            {
                "unified": "1f54d",
                "native": "🕍"
            }
        ],
        "version": 1
    },
    "shinto_shrine": {
        "id": "shinto_shrine",
        "name": "Shinto Shrine",
        "keywords": [
            "temple",
            "japan",
            "kyoto",
            "寺",
            "日本",
            "京都"
        ],
        "skins": [
            {
                "unified": "26e9-fe0f",
                "native": "⛩️"
            }
        ],
        "version": 1
    },
    "kaaba": {
        "id": "kaaba",
        "name": "Kaaba",
        "keywords": [
            "mecca",
            "mosque",
            "islam",
            "メッカ",
            "モスク",
            "イスラム教"
        ],
        "skins": [
            {
                "unified": "1f54b",
                "native": "🕋"
            }
        ],
        "version": 1
    },
    "fountain": {
        "id": "fountain",
        "name": "Fountain",
        "keywords": [
            "photo",
            "summer",
            "water",
            "fresh",
            "写真",
            "夏",
            "水",
            "新鮮"
        ],
        "skins": [
            {
                "unified": "26f2",
                "native": "⛲"
            }
        ],
        "version": 1
    },
    "tent": {
        "id": "tent",
        "name": "Tent",
        "keywords": [
            "photo",
            "camping",
            "outdoors",
            "写真",
            "キャンプ",
            "屋外"
        ],
        "skins": [
            {
                "unified": "26fa",
                "native": "⛺"
            }
        ],
        "version": 1
    },
    "foggy": {
        "id": "foggy",
        "name": "Foggy",
        "keywords": [
            "photo",
            "mountain",
            "写真",
            "山"
        ],
        "skins": [
            {
                "unified": "1f301",
                "native": "🌁"
            }
        ],
        "version": 1
    },
    "night_with_stars": {
        "id": "night_with_stars",
        "name": "Night with Stars",
        "keywords": [
            "evening",
            "city",
            "downtown",
            "夜",
            "街",
            "ダウンタウン"
        ],
        "skins": [
            {
                "unified": "1f303",
                "native": "🌃"
            }
        ],
        "version": 1
    },
    "cityscape": {
        "id": "cityscape",
        "name": "Cityscape",
        "keywords": [
            "photo",
            "night",
            "life",
            "urban",
            "写真",
            "夜",
            "人生",
            "都市"
        ],
        "skins": [
            {
                "unified": "1f3d9-fe0f",
                "native": "🏙️"
            }
        ],
        "version": 1
    },
    "sunrise_over_mountains": {
        "id": "sunrise_over_mountains",
        "name": "Sunrise over Mountains",
        "keywords": [
            "view",
            "vacation",
            "photo",
            "意見",
            "休暇",
            "写真"
        ],
        "skins": [
            {
                "unified": "1f304",
                "native": "🌄"
            }
        ],
        "version": 1
    },
    "sunrise": {
        "id": "sunrise",
        "name": "Sunrise",
        "keywords": [
            "morning",
            "view",
            "vacation",
            "photo",
            "朝",
            "意見",
            "休暇",
            "写真"
        ],
        "skins": [
            {
                "unified": "1f305",
                "native": "🌅"
            }
        ],
        "version": 1
    },
    "city_sunset": {
        "id": "city_sunset",
        "name": "Cityscape at Dusk",
        "keywords": [
            "city",
            "sunset",
            "photo",
            "evening",
            "sky",
            "buildings",
            "街",
            "日没",
            "写真",
            "夜",
            "空",
            "建物"
        ],
        "skins": [
            {
                "unified": "1f306",
                "native": "🌆"
            }
        ],
        "version": 1
    },
    "city_sunrise": {
        "id": "city_sunrise",
        "name": "Sunset",
        "keywords": [
            "city",
            "sunrise",
            "photo",
            "good",
            "morning",
            "dawn",
            "街",
            "日の出",
            "写真",
            "良い",
            "朝",
            "夜明け"
        ],
        "skins": [
            {
                "unified": "1f307",
                "native": "🌇"
            }
        ],
        "version": 1
    },
    "bridge_at_night": {
        "id": "bridge_at_night",
        "name": "Bridge at Night",
        "keywords": [
            "photo",
            "sanfrancisco",
            "写真",
            "サンフランシスコ"
        ],
        "skins": [
            {
                "unified": "1f309",
                "native": "🌉"
            }
        ],
        "version": 1
    },
    "hotsprings": {
        "id": "hotsprings",
        "name": "Hot Springs",
        "keywords": [
            "hotsprings",
            "bath",
            "warm",
            "relax",
            "熱水泉",
            "バス",
            "暖かい",
            "リラックス"
        ],
        "skins": [
            {
                "unified": "2668-fe0f",
                "native": "♨️"
            }
        ],
        "version": 1
    },
    "carousel_horse": {
        "id": "carousel_horse",
        "name": "Carousel Horse",
        "keywords": [
            "photo",
            "carnival",
            "写真",
            "カーニバル"
        ],
        "skins": [
            {
                "unified": "1f3a0",
                "native": "🎠"
            }
        ],
        "version": 1
    },
    "playground_slide": {
        "id": "playground_slide",
        "name": "Playground Slide",
        "keywords": [
            "fun",
            "park",
            "楽しい",
            "公園"
        ],
        "skins": [
            {
                "unified": "1f6dd",
                "native": "🛝"
            }
        ],
        "version": 14
    },
    "ferris_wheel": {
        "id": "ferris_wheel",
        "name": "Ferris Wheel",
        "keywords": [
            "photo",
            "carnival",
            "londoneye",
            "写真",
            "カーニバル",
            "ロンドンアイ"
        ],
        "skins": [
            {
                "unified": "1f3a1",
                "native": "🎡"
            }
        ],
        "version": 1
    },
    "roller_coaster": {
        "id": "roller_coaster",
        "name": "Roller Coaster",
        "keywords": [
            "carnival",
            "playground",
            "photo",
            "fun",
            "カーニバル",
            "遊び場",
            "写真",
            "楽しい"
        ],
        "skins": [
            {
                "unified": "1f3a2",
                "native": "🎢"
            }
        ],
        "version": 1
    },
    "barber": {
        "id": "barber",
        "name": "Barber Pole",
        "keywords": [
            "hair",
            "salon",
            "style",
            "髪",
            "サロン",
            "スタイル"
        ],
        "skins": [
            {
                "unified": "1f488",
                "native": "💈"
            }
        ],
        "version": 1
    },
    "circus_tent": {
        "id": "circus_tent",
        "name": "Circus Tent",
        "keywords": [
            "festival",
            "carnival",
            "party",
            "祭り",
            "カーニバル",
            "パーティ"
        ],
        "skins": [
            {
                "unified": "1f3aa",
                "native": "🎪"
            }
        ],
        "version": 1
    },
    "steam_locomotive": {
        "id": "steam_locomotive",
        "name": "Locomotive",
        "keywords": [
            "steam",
            "transportation",
            "vehicle",
            "train",
            "蒸気",
            "交通機関",
            "車両",
            "訓練"
        ],
        "skins": [
            {
                "unified": "1f682",
                "native": "🚂"
            }
        ],
        "version": 1
    },
    "railway_car": {
        "id": "railway_car",
        "name": "Railway Car",
        "keywords": [
            "transportation",
            "vehicle",
            "交通機関",
            "車両"
        ],
        "skins": [
            {
                "unified": "1f683",
                "native": "🚃"
            }
        ],
        "version": 1
    },
    "bullettrain_side": {
        "id": "bullettrain_side",
        "name": "High-Speed Train",
        "keywords": [
            "bullettrain",
            "side",
            "high",
            "speed",
            "transportation",
            "vehicle",
            "新幹線",
            "側",
            "高い",
            "スピード",
            "交通機関",
            "車両"
        ],
        "skins": [
            {
                "unified": "1f684",
                "native": "🚄"
            }
        ],
        "version": 1
    },
    "bullettrain_front": {
        "id": "bullettrain_front",
        "name": "Bullet Train",
        "keywords": [
            "bullettrain",
            "front",
            "transportation",
            "vehicle",
            "speed",
            "fast",
            "public",
            "travel",
            "新幹線",
            "正面",
            "交通機関",
            "車両",
            "スピード",
            "速い",
            "公共",
            "旅行"
        ],
        "skins": [
            {
                "unified": "1f685",
                "native": "🚅"
            }
        ],
        "version": 1
    },
    "train2": {
        "id": "train2",
        "name": "Train",
        "keywords": [
            "train2",
            "transportation",
            "vehicle",
            "train2",
            "交通機関",
            "車両"
        ],
        "skins": [
            {
                "unified": "1f686",
                "native": "🚆"
            }
        ],
        "version": 1
    },
    "metro": {
        "id": "metro",
        "name": "Metro",
        "keywords": [
            "transportation",
            "blue",
            "square",
            "mrt",
            "underground",
            "tube",
            "交通機関",
            "青",
            "四角",
            "mrt",
            "地下",
            "チューブ"
        ],
        "skins": [
            {
                "unified": "1f687",
                "native": "🚇"
            }
        ],
        "version": 1
    },
    "light_rail": {
        "id": "light_rail",
        "name": "Light Rail",
        "keywords": [
            "transportation",
            "vehicle",
            "交通機関",
            "車両"
        ],
        "skins": [
            {
                "unified": "1f688",
                "native": "🚈"
            }
        ],
        "version": 1
    },
    "station": {
        "id": "station",
        "name": "Station",
        "keywords": [
            "transportation",
            "vehicle",
            "public",
            "交通機関",
            "車両",
            "公共"
        ],
        "skins": [
            {
                "unified": "1f689",
                "native": "🚉"
            }
        ],
        "version": 1
    },
    "tram": {
        "id": "tram",
        "name": "Tram",
        "keywords": [
            "transportation",
            "vehicle",
            "交通機関",
            "車両"
        ],
        "skins": [
            {
                "unified": "1f68a",
                "native": "🚊"
            }
        ],
        "version": 1
    },
    "monorail": {
        "id": "monorail",
        "name": "Monorail",
        "keywords": [
            "transportation",
            "vehicle",
            "交通機関",
            "車両"
        ],
        "skins": [
            {
                "unified": "1f69d",
                "native": "🚝"
            }
        ],
        "version": 1
    },
    "mountain_railway": {
        "id": "mountain_railway",
        "name": "Mountain Railway",
        "keywords": [
            "transportation",
            "vehicle",
            "交通機関",
            "車両"
        ],
        "skins": [
            {
                "unified": "1f69e",
                "native": "🚞"
            }
        ],
        "version": 1
    },
    "train": {
        "id": "train",
        "name": "Tram Car",
        "keywords": [
            "train",
            "transportation",
            "vehicle",
            "carriage",
            "public",
            "travel",
            "訓練",
            "交通機関",
            "車両",
            "キャリッジ",
            "公共",
            "旅行"
        ],
        "skins": [
            {
                "unified": "1f68b",
                "native": "🚋"
            }
        ],
        "version": 1
    },
    "bus": {
        "id": "bus",
        "name": "Bus",
        "keywords": [
            "car",
            "vehicle",
            "transportation",
            "車",
            "車両",
            "交通機関"
        ],
        "skins": [
            {
                "unified": "1f68c",
                "native": "🚌"
            }
        ],
        "version": 1
    },
    "oncoming_bus": {
        "id": "oncoming_bus",
        "name": "Oncoming Bus",
        "keywords": [
            "vehicle",
            "transportation",
            "車両",
            "交通機関"
        ],
        "skins": [
            {
                "unified": "1f68d",
                "native": "🚍"
            }
        ],
        "version": 1
    },
    "trolleybus": {
        "id": "trolleybus",
        "name": "Trolleybus",
        "keywords": [
            "bart",
            "transportation",
            "vehicle",
            "バート",
            "交通機関",
            "車両"
        ],
        "skins": [
            {
                "unified": "1f68e",
                "native": "🚎"
            }
        ],
        "version": 1
    },
    "minibus": {
        "id": "minibus",
        "name": "Minibus",
        "keywords": [
            "vehicle",
            "car",
            "transportation",
            "車両",
            "車",
            "交通機関"
        ],
        "skins": [
            {
                "unified": "1f690",
                "native": "🚐"
            }
        ],
        "version": 1
    },
    "ambulance": {
        "id": "ambulance",
        "name": "Ambulance",
        "keywords": [
            "health",
            "911",
            "hospital",
            "健康",
            "911",
            "病院"
        ],
        "skins": [
            {
                "unified": "1f691",
                "native": "🚑"
            }
        ],
        "version": 1
    },
    "fire_engine": {
        "id": "fire_engine",
        "name": "Fire Engine",
        "keywords": [
            "transportation",
            "cars",
            "vehicle",
            "交通機関",
            "車",
            "車両"
        ],
        "skins": [
            {
                "unified": "1f692",
                "native": "🚒"
            }
        ],
        "version": 1
    },
    "police_car": {
        "id": "police_car",
        "name": "Police Car",
        "keywords": [
            "vehicle",
            "cars",
            "transportation",
            "law",
            "legal",
            "enforcement",
            "車両",
            "車",
            "交通機関",
            "法",
            "法的",
            "執行"
        ],
        "skins": [
            {
                "unified": "1f693",
                "native": "🚓"
            }
        ],
        "version": 1
    },
    "oncoming_police_car": {
        "id": "oncoming_police_car",
        "name": "Oncoming Police Car",
        "keywords": [
            "vehicle",
            "law",
            "legal",
            "enforcement",
            "911",
            "車両",
            "法",
            "法的",
            "執行",
            "911"
        ],
        "skins": [
            {
                "unified": "1f694",
                "native": "🚔"
            }
        ],
        "version": 1
    },
    "taxi": {
        "id": "taxi",
        "name": "Taxi",
        "keywords": [
            "uber",
            "vehicle",
            "cars",
            "transportation",
            "ユーバー",
            "車両",
            "車",
            "交通機関"
        ],
        "skins": [
            {
                "unified": "1f695",
                "native": "🚕"
            }
        ],
        "version": 1
    },
    "oncoming_taxi": {
        "id": "oncoming_taxi",
        "name": "Oncoming Taxi",
        "keywords": [
            "vehicle",
            "cars",
            "uber",
            "車両",
            "車",
            "ユーバー"
        ],
        "skins": [
            {
                "unified": "1f696",
                "native": "🚖"
            }
        ],
        "version": 1
    },
    "car": {
        "id": "car",
        "name": "Automobile",
        "keywords": [
            "car",
            "red",
            "transportation",
            "vehicle",
            "車",
            "赤",
            "交通機関",
            "車両"
        ],
        "skins": [
            {
                "unified": "1f697",
                "native": "🚗"
            }
        ],
        "version": 1
    },
    "oncoming_automobile": {
        "id": "oncoming_automobile",
        "name": "Oncoming Automobile",
        "keywords": [
            "car",
            "vehicle",
            "transportation",
            "車",
            "車両",
            "交通機関"
        ],
        "skins": [
            {
                "unified": "1f698",
                "native": "🚘"
            }
        ],
        "version": 1
    },
    "blue_car": {
        "id": "blue_car",
        "name": "Recreational Vehicle",
        "keywords": [
            "blue",
            "car",
            "sport",
            "utility",
            "transportation",
            "青",
            "車",
            "スポーツ",
            "効用",
            "交通機関"
        ],
        "skins": [
            {
                "unified": "1f699",
                "native": "🚙"
            }
        ],
        "version": 1
    },
    "pickup_truck": {
        "id": "pickup_truck",
        "name": "Pickup Truck",
        "keywords": [
            "car",
            "transportation",
            "車",
            "交通機関"
        ],
        "skins": [
            {
                "unified": "1f6fb",
                "native": "🛻"
            }
        ],
        "version": 13
    },
    "truck": {
        "id": "truck",
        "name": "Delivery Truck",
        "keywords": [
            "cars",
            "transportation",
            "車",
            "交通機関"
        ],
        "skins": [
            {
                "unified": "1f69a",
                "native": "🚚"
            }
        ],
        "version": 1
    },
    "articulated_lorry": {
        "id": "articulated_lorry",
        "name": "Articulated Lorry",
        "keywords": [
            "vehicle",
            "cars",
            "transportation",
            "express",
            "車両",
            "車",
            "交通機関",
            "特急"
        ],
        "skins": [
            {
                "unified": "1f69b",
                "native": "🚛"
            }
        ],
        "version": 1
    },
    "tractor": {
        "id": "tractor",
        "name": "Tractor",
        "keywords": [
            "vehicle",
            "car",
            "farming",
            "agriculture",
            "車両",
            "車",
            "農業",
            "農業"
        ],
        "skins": [
            {
                "unified": "1f69c",
                "native": "🚜"
            }
        ],
        "version": 1
    },
    "racing_car": {
        "id": "racing_car",
        "name": "Racing Car",
        "keywords": [
            "sports",
            "race",
            "fast",
            "formula",
            "f1",
            "スポーツ",
            "人種",
            "速い",
            "方式",
            "f1"
        ],
        "skins": [
            {
                "unified": "1f3ce-fe0f",
                "native": "🏎️"
            }
        ],
        "version": 1
    },
    "racing_motorcycle": {
        "id": "racing_motorcycle",
        "name": "Motorcycle",
        "keywords": [
            "racing",
            "race",
            "sports",
            "fast",
            "レース",
            "人種",
            "スポーツ",
            "速い"
        ],
        "skins": [
            {
                "unified": "1f3cd-fe0f",
                "native": "🏍️"
            }
        ],
        "version": 1
    },
    "motor_scooter": {
        "id": "motor_scooter",
        "name": "Motor Scooter",
        "keywords": [
            "vehicle",
            "vespa",
            "sasha",
            "車両",
            "ベスパ",
            "サーシャ"
        ],
        "skins": [
            {
                "unified": "1f6f5",
                "native": "🛵"
            }
        ],
        "version": 3
    },
    "manual_wheelchair": {
        "id": "manual_wheelchair",
        "name": "Manual Wheelchair",
        "keywords": [
            "accessibility",
            "アクセシビリティ"
        ],
        "skins": [
            {
                "unified": "1f9bd",
                "native": "🦽"
            }
        ],
        "version": 12
    },
    "motorized_wheelchair": {
        "id": "motorized_wheelchair",
        "name": "Motorized Wheelchair",
        "keywords": [
            "accessibility",
            "アクセシビリティ"
        ],
        "skins": [
            {
                "unified": "1f9bc",
                "native": "🦼"
            }
        ],
        "version": 12
    },
    "auto_rickshaw": {
        "id": "auto_rickshaw",
        "name": "Auto Rickshaw",
        "keywords": [
            "move",
            "transportation",
            "動く",
            "交通機関"
        ],
        "skins": [
            {
                "unified": "1f6fa",
                "native": "🛺"
            }
        ],
        "version": 12
    },
    "bike": {
        "id": "bike",
        "name": "Bicycle",
        "keywords": [
            "bike",
            "sports",
            "exercise",
            "hipster",
            "自転車",
            "スポーツ",
            "エクササイズ",
            "ヒップスター"
        ],
        "skins": [
            {
                "unified": "1f6b2",
                "native": "🚲"
            }
        ],
        "version": 1
    },
    "scooter": {
        "id": "scooter",
        "name": "Scooter",
        "keywords": [
            "kick",
            "vehicle",
            "razor",
            "キック",
            "車両",
            "かみそり"
        ],
        "skins": [
            {
                "unified": "1f6f4",
                "native": "🛴"
            }
        ],
        "version": 3
    },
    "skateboard": {
        "id": "skateboard",
        "name": "Skateboard",
        "keywords": [
            "board",
            "ボード"
        ],
        "skins": [
            {
                "unified": "1f6f9",
                "native": "🛹"
            }
        ],
        "version": 11
    },
    "roller_skate": {
        "id": "roller_skate",
        "name": "Roller Skate",
        "keywords": [
            "footwear",
            "sports",
            "履物",
            "スポーツ"
        ],
        "skins": [
            {
                "unified": "1f6fc",
                "native": "🛼"
            }
        ],
        "version": 13
    },
    "busstop": {
        "id": "busstop",
        "name": "Bus Stop",
        "keywords": [
            "busstop",
            "transportation",
            "wait",
            "バスのりば",
            "交通機関",
            "待って"
        ],
        "skins": [
            {
                "unified": "1f68f",
                "native": "🚏"
            }
        ],
        "version": 1
    },
    "motorway": {
        "id": "motorway",
        "name": "Motorway",
        "keywords": [
            "road",
            "cupertino",
            "interstate",
            "highway",
            "道",
            "クパチーノ",
            "州間高速道路",
            "高速道路"
        ],
        "skins": [
            {
                "unified": "1f6e3-fe0f",
                "native": "🛣️"
            }
        ],
        "version": 1
    },
    "railway_track": {
        "id": "railway_track",
        "name": "Railway Track",
        "keywords": [
            "train",
            "transportation",
            "訓練",
            "交通機関"
        ],
        "skins": [
            {
                "unified": "1f6e4-fe0f",
                "native": "🛤️"
            }
        ],
        "version": 1
    },
    "oil_drum": {
        "id": "oil_drum",
        "name": "Oil Drum",
        "keywords": [
            "barrell",
            "バレル"
        ],
        "skins": [
            {
                "unified": "1f6e2-fe0f",
                "native": "🛢️"
            }
        ],
        "version": 1
    },
    "fuelpump": {
        "id": "fuelpump",
        "name": "Fuel Pump",
        "keywords": [
            "fuelpump",
            "gas",
            "station",
            "petroleum",
            "燃料ポンプ",
            "ガス",
            "駅",
            "石油"
        ],
        "skins": [
            {
                "unified": "26fd",
                "native": "⛽"
            }
        ],
        "version": 1
    },
    "wheel": {
        "id": "wheel",
        "name": "Wheel",
        "keywords": [
            "car",
            "transport",
            "車",
            "輸送"
        ],
        "skins": [
            {
                "unified": "1f6de",
                "native": "🛞"
            }
        ],
        "version": 14
    },
    "rotating_light": {
        "id": "rotating_light",
        "name": "Police Car Light",
        "keywords": [
            "rotating",
            "ambulance",
            "911",
            "emergency",
            "alert",
            "error",
            "pinged",
            "law",
            "legal",
            "回転",
            "救急車",
            "911",
            "緊急",
            "アラート",
            "エラー",
            "ping",
            "法",
            "法的"
        ],
        "skins": [
            {
                "unified": "1f6a8",
                "native": "🚨"
            }
        ],
        "version": 1
    },
    "traffic_light": {
        "id": "traffic_light",
        "name": "Horizontal Traffic Light",
        "keywords": [
            "transportation",
            "signal",
            "交通機関",
            "信号"
        ],
        "skins": [
            {
                "unified": "1f6a5",
                "native": "🚥"
            }
        ],
        "version": 1
    },
    "vertical_traffic_light": {
        "id": "vertical_traffic_light",
        "name": "Vertical Traffic Light",
        "keywords": [
            "transportation",
            "driving",
            "交通機関",
            "運転"
        ],
        "skins": [
            {
                "unified": "1f6a6",
                "native": "🚦"
            }
        ],
        "version": 1
    },
    "octagonal_sign": {
        "id": "octagonal_sign",
        "name": "Stop Sign",
        "keywords": [
            "octagonal",
            "八角形"
        ],
        "skins": [
            {
                "unified": "1f6d1",
                "native": "🛑"
            }
        ],
        "version": 3
    },
    "construction": {
        "id": "construction",
        "name": "Construction",
        "keywords": [
            "wip",
            "progress",
            "caution",
            "warning",
            "ワイプ",
            "進捗",
            "注意",
            "警告"
        ],
        "skins": [
            {
                "unified": "1f6a7",
                "native": "🚧"
            }
        ],
        "version": 1
    },
    "anchor": {
        "id": "anchor",
        "name": "Anchor",
        "keywords": [
            "ship",
            "ferry",
            "sea",
            "boat",
            "船",
            "フェリー",
            "海",
            "ボート"
        ],
        "skins": [
            {
                "unified": "2693",
                "native": "⚓"
            }
        ],
        "version": 1
    },
    "ring_buoy": {
        "id": "ring_buoy",
        "name": "Ring Buoy",
        "keywords": [
            "life",
            "saver",
            "preserver",
            "人生",
            "セーバー",
            "防腐剤"
        ],
        "skins": [
            {
                "unified": "1f6df",
                "native": "🛟"
            }
        ],
        "version": 14
    },
    "boat": {
        "id": "boat",
        "name": "Sailboat",
        "keywords": [
            "boat",
            "ship",
            "summer",
            "transportation",
            "water",
            "sailing",
            "ボート",
            "船",
            "夏",
            "交通機関",
            "水",
            "セーリング"
        ],
        "skins": [
            {
                "unified": "26f5",
                "native": "⛵"
            }
        ],
        "version": 1
    },
    "canoe": {
        "id": "canoe",
        "name": "Canoe",
        "keywords": [
            "boat",
            "paddle",
            "water",
            "ship",
            "ボート",
            "パドル",
            "水",
            "船"
        ],
        "skins": [
            {
                "unified": "1f6f6",
                "native": "🛶"
            }
        ],
        "version": 3
    },
    "speedboat": {
        "id": "speedboat",
        "name": "Speedboat",
        "keywords": [
            "ship",
            "transportation",
            "vehicle",
            "summer",
            "船",
            "交通機関",
            "車両",
            "夏"
        ],
        "skins": [
            {
                "unified": "1f6a4",
                "native": "🚤"
            }
        ],
        "version": 1
    },
    "passenger_ship": {
        "id": "passenger_ship",
        "name": "Passenger Ship",
        "keywords": [
            "yacht",
            "cruise",
            "ferry",
            "ヨット",
            "クルーズ",
            "フェリー"
        ],
        "skins": [
            {
                "unified": "1f6f3-fe0f",
                "native": "🛳️"
            }
        ],
        "version": 1
    },
    "ferry": {
        "id": "ferry",
        "name": "Ferry",
        "keywords": [
            "boat",
            "ship",
            "yacht",
            "ボート",
            "船",
            "ヨット"
        ],
        "skins": [
            {
                "unified": "26f4-fe0f",
                "native": "⛴️"
            }
        ],
        "version": 1
    },
    "motor_boat": {
        "id": "motor_boat",
        "name": "Motor Boat",
        "keywords": [
            "ship",
            "船"
        ],
        "skins": [
            {
                "unified": "1f6e5-fe0f",
                "native": "🛥️"
            }
        ],
        "version": 1
    },
    "ship": {
        "id": "ship",
        "name": "Ship",
        "keywords": [
            "transportation",
            "titanic",
            "deploy",
            "交通機関",
            "タイタニック",
            "配備"
        ],
        "skins": [
            {
                "unified": "1f6a2",
                "native": "🚢"
            }
        ],
        "version": 1
    },
    "airplane": {
        "id": "airplane",
        "name": "Airplane",
        "keywords": [
            "vehicle",
            "transportation",
            "flight",
            "fly",
            "車両",
            "交通機関",
            "フライト",
            "飛ぶ"
        ],
        "skins": [
            {
                "unified": "2708-fe0f",
                "native": "✈️"
            }
        ],
        "version": 1
    },
    "small_airplane": {
        "id": "small_airplane",
        "name": "Small Airplane",
        "keywords": [
            "flight",
            "transportation",
            "fly",
            "vehicle",
            "フライト",
            "交通機関",
            "飛ぶ",
            "車両"
        ],
        "skins": [
            {
                "unified": "1f6e9-fe0f",
                "native": "🛩️"
            }
        ],
        "version": 1
    },
    "airplane_departure": {
        "id": "airplane_departure",
        "name": "Airplane Departure",
        "keywords": [
            "airport",
            "flight",
            "landing",
            "空港",
            "フライト",
            "着陸"
        ],
        "skins": [
            {
                "unified": "1f6eb",
                "native": "🛫"
            }
        ],
        "version": 1
    },
    "airplane_arriving": {
        "id": "airplane_arriving",
        "name": "Airplane Arrival",
        "keywords": [
            "arriving",
            "airport",
            "flight",
            "boarding",
            "到着",
            "空港",
            "フライト",
            "搭乗"
        ],
        "skins": [
            {
                "unified": "1f6ec",
                "native": "🛬"
            }
        ],
        "version": 1
    },
    "parachute": {
        "id": "parachute",
        "name": "Parachute",
        "keywords": [
            "fly",
            "glide",
            "飛ぶ",
            "滑る"
        ],
        "skins": [
            {
                "unified": "1fa82",
                "native": "🪂"
            }
        ],
        "version": 12
    },
    "seat": {
        "id": "seat",
        "name": "Seat",
        "keywords": [
            "sit",
            "airplane",
            "transport",
            "bus",
            "flight",
            "fly",
            "座る",
            "飛行機",
            "輸送",
            "バス",
            "フライト",
            "飛ぶ"
        ],
        "skins": [
            {
                "unified": "1f4ba",
                "native": "💺"
            }
        ],
        "version": 1
    },
    "helicopter": {
        "id": "helicopter",
        "name": "Helicopter",
        "keywords": [
            "transportation",
            "vehicle",
            "fly",
            "交通機関",
            "車両",
            "飛ぶ"
        ],
        "skins": [
            {
                "unified": "1f681",
                "native": "🚁"
            }
        ],
        "version": 1
    },
    "suspension_railway": {
        "id": "suspension_railway",
        "name": "Suspension Railway",
        "keywords": [
            "vehicle",
            "transportation",
            "車両",
            "交通機関"
        ],
        "skins": [
            {
                "unified": "1f69f",
                "native": "🚟"
            }
        ],
        "version": 1
    },
    "mountain_cableway": {
        "id": "mountain_cableway",
        "name": "Mountain Cableway",
        "keywords": [
            "transportation",
            "vehicle",
            "ski",
            "交通機関",
            "車両",
            "スキー"
        ],
        "skins": [
            {
                "unified": "1f6a0",
                "native": "🚠"
            }
        ],
        "version": 1
    },
    "aerial_tramway": {
        "id": "aerial_tramway",
        "name": "Aerial Tramway",
        "keywords": [
            "transportation",
            "vehicle",
            "ski",
            "交通機関",
            "車両",
            "スキー"
        ],
        "skins": [
            {
                "unified": "1f6a1",
                "native": "🚡"
            }
        ],
        "version": 1
    },
    "satellite": {
        "id": "satellite",
        "name": "Satellite",
        "keywords": [
            "communication",
            "gps",
            "orbit",
            "spaceflight",
            "NASA",
            "ISS",
            "コミュニケーション",
            "GPS",
            "軌道",
            "宇宙飛行",
            "NASA",
            "ISS"
        ],
        "skins": [
            {
                "unified": "1f6f0-fe0f",
                "native": "🛰️"
            }
        ],
        "version": 1
    },
    "rocket": {
        "id": "rocket",
        "name": "Rocket",
        "keywords": [
            "launch",
            "ship",
            "staffmode",
            "NASA",
            "outer",
            "space",
            "fly",
            "発売",
            "船",
            "スタッフモード",
            "NASA",
            "外側",
            "空",
            "飛ぶ"
        ],
        "skins": [
            {
                "unified": "1f680",
                "native": "🚀"
            }
        ],
        "version": 1
    },
    "flying_saucer": {
        "id": "flying_saucer",
        "name": "Flying Saucer",
        "keywords": [
            "transportation",
            "vehicle",
            "ufo",
            "交通機関",
            "車両",
            "うふ"
        ],
        "skins": [
            {
                "unified": "1f6f8",
                "native": "🛸"
            }
        ],
        "version": 5
    },
    "bellhop_bell": {
        "id": "bellhop_bell",
        "name": "Bellhop Bell",
        "keywords": [
            "service",
            "サービス"
        ],
        "skins": [
            {
                "unified": "1f6ce-fe0f",
                "native": "🛎️"
            }
        ],
        "version": 1
    },
    "luggage": {
        "id": "luggage",
        "name": "Luggage",
        "keywords": [
            "packing",
            "travel",
            "パッキング",
            "旅行"
        ],
        "skins": [
            {
                "unified": "1f9f3",
                "native": "🧳"
            }
        ],
        "version": 11
    },
    "hourglass": {
        "id": "hourglass",
        "name": "Hourglass",
        "keywords": [
            "done",
            "time",
            "clock",
            "oldschool",
            "limit",
            "exam",
            "quiz",
            "test",
            "終わり",
            "時間",
            "時計",
            "古い学校",
            "限界",
            "テスト",
            "クイズ",
            "テスト"
        ],
        "skins": [
            {
                "unified": "231b",
                "native": "⌛"
            }
        ],
        "version": 1
    },
    "hourglass_flowing_sand": {
        "id": "hourglass_flowing_sand",
        "name": "Hourglass Not Done",
        "keywords": [
            "flowing",
            "sand",
            "oldschool",
            "time",
            "countdown",
            "流れる",
            "砂",
            "古い学校",
            "時間",
            "秒読み"
        ],
        "skins": [
            {
                "unified": "23f3",
                "native": "⏳"
            }
        ],
        "version": 1
    },
    "watch": {
        "id": "watch",
        "name": "Watch",
        "keywords": [
            "time",
            "accessories",
            "時間",
            "アクセサリー"
        ],
        "skins": [
            {
                "unified": "231a",
                "native": "⌚"
            }
        ],
        "version": 1
    },
    "alarm_clock": {
        "id": "alarm_clock",
        "name": "Alarm Clock",
        "keywords": [
            "time",
            "wake",
            "時間",
            "目覚める"
        ],
        "skins": [
            {
                "unified": "23f0",
                "native": "⏰"
            }
        ],
        "version": 1
    },
    "stopwatch": {
        "id": "stopwatch",
        "name": "Stopwatch",
        "keywords": [
            "time",
            "deadline",
            "時間",
            "締め切り"
        ],
        "skins": [
            {
                "unified": "23f1-fe0f",
                "native": "⏱️"
            }
        ],
        "version": 1
    },
    "timer_clock": {
        "id": "timer_clock",
        "name": "Timer Clock",
        "keywords": [
            "alarm",
            "警報"
        ],
        "skins": [
            {
                "unified": "23f2-fe0f",
                "native": "⏲️"
            }
        ],
        "version": 1
    },
    "mantelpiece_clock": {
        "id": "mantelpiece_clock",
        "name": "Mantelpiece Clock",
        "keywords": [
            "time",
            "時間"
        ],
        "skins": [
            {
                "unified": "1f570-fe0f",
                "native": "🕰️"
            }
        ],
        "version": 1
    },
    "clock12": {
        "id": "clock12",
        "name": "Twelve O’clock",
        "keywords": [
            "clock12",
            "o",
            "clock",
            "time",
            "noon",
            "midnight",
            "midday",
            "late",
            "early",
            "schedule",
            "時計12",
            "お",
            "時計",
            "時間",
            "昼",
            "夜中",
            "正午",
            "遅い",
            "早い",
            "スケジュール"
        ],
        "skins": [
            {
                "unified": "1f55b",
                "native": "🕛"
            }
        ],
        "version": 1
    },
    "clock1230": {
        "id": "clock1230",
        "name": "Twelve-Thirty",
        "keywords": [
            "clock1230",
            "twelve",
            "thirty",
            "time",
            "late",
            "early",
            "schedule",
            "時計1230",
            "12",
            "30",
            "時間",
            "遅い",
            "早い",
            "スケジュール"
        ],
        "skins": [
            {
                "unified": "1f567",
                "native": "🕧"
            }
        ],
        "version": 1
    },
    "clock1": {
        "id": "clock1",
        "name": "One O’clock",
        "keywords": [
            "clock1",
            "o",
            "clock",
            "time",
            "late",
            "early",
            "schedule",
            "時計1",
            "お",
            "時計",
            "時間",
            "遅い",
            "早い",
            "スケジュール"
        ],
        "skins": [
            {
                "unified": "1f550",
                "native": "🕐"
            }
        ],
        "version": 1
    },
    "clock130": {
        "id": "clock130",
        "name": "One-Thirty",
        "keywords": [
            "clock130",
            "one",
            "thirty",
            "time",
            "late",
            "early",
            "schedule",
            "時計130",
            "一",
            "30",
            "時間",
            "遅い",
            "早い",
            "スケジュール"
        ],
        "skins": [
            {
                "unified": "1f55c",
                "native": "🕜"
            }
        ],
        "version": 1
    },
    "clock2": {
        "id": "clock2",
        "name": "Two O’clock",
        "keywords": [
            "clock2",
            "o",
            "clock",
            "time",
            "late",
            "early",
            "schedule",
            "クロック2",
            "お",
            "時計",
            "時間",
            "遅い",
            "早い",
            "スケジュール"
        ],
        "skins": [
            {
                "unified": "1f551",
                "native": "🕑"
            }
        ],
        "version": 1
    },
    "clock230": {
        "id": "clock230",
        "name": "Two-Thirty",
        "keywords": [
            "clock230",
            "two",
            "thirty",
            "time",
            "late",
            "early",
            "schedule",
            "クロック230",
            "二",
            "30",
            "時間",
            "遅い",
            "早い",
            "スケジュール"
        ],
        "skins": [
            {
                "unified": "1f55d",
                "native": "🕝"
            }
        ],
        "version": 1
    },
    "clock3": {
        "id": "clock3",
        "name": "Three O’clock",
        "keywords": [
            "clock3",
            "o",
            "clock",
            "time",
            "late",
            "early",
            "schedule",
            "クロック3",
            "お",
            "時計",
            "時間",
            "遅い",
            "早い",
            "スケジュール"
        ],
        "skins": [
            {
                "unified": "1f552",
                "native": "🕒"
            }
        ],
        "version": 1
    },
    "clock330": {
        "id": "clock330",
        "name": "Three-Thirty",
        "keywords": [
            "clock330",
            "three",
            "thirty",
            "time",
            "late",
            "early",
            "schedule",
            "クロック330",
            "三つ",
            "30",
            "時間",
            "遅い",
            "早い",
            "スケジュール"
        ],
        "skins": [
            {
                "unified": "1f55e",
                "native": "🕞"
            }
        ],
        "version": 1
    },
    "clock4": {
        "id": "clock4",
        "name": "Four O’clock",
        "keywords": [
            "clock4",
            "o",
            "clock",
            "time",
            "late",
            "early",
            "schedule",
            "クロック4",
            "お",
            "時計",
            "時間",
            "遅い",
            "早い",
            "スケジュール"
        ],
        "skins": [
            {
                "unified": "1f553",
                "native": "🕓"
            }
        ],
        "version": 1
    },
    "clock430": {
        "id": "clock430",
        "name": "Four-Thirty",
        "keywords": [
            "clock430",
            "four",
            "thirty",
            "time",
            "late",
            "early",
            "schedule",
            "クロック430",
            "四",
            "30",
            "時間",
            "遅い",
            "早い",
            "スケジュール"
        ],
        "skins": [
            {
                "unified": "1f55f",
                "native": "🕟"
            }
        ],
        "version": 1
    },
    "clock5": {
        "id": "clock5",
        "name": "Five O’clock",
        "keywords": [
            "clock5",
            "o",
            "clock",
            "time",
            "late",
            "early",
            "schedule",
            "時計5",
            "お",
            "時計",
            "時間",
            "遅い",
            "早い",
            "スケジュール"
        ],
        "skins": [
            {
                "unified": "1f554",
                "native": "🕔"
            }
        ],
        "version": 1
    },
    "clock530": {
        "id": "clock530",
        "name": "Five-Thirty",
        "keywords": [
            "clock530",
            "five",
            "thirty",
            "time",
            "late",
            "early",
            "schedule",
            "時計530",
            "五",
            "30",
            "時間",
            "遅い",
            "早い",
            "スケジュール"
        ],
        "skins": [
            {
                "unified": "1f560",
                "native": "🕠"
            }
        ],
        "version": 1
    },
    "clock6": {
        "id": "clock6",
        "name": "Six O’clock",
        "keywords": [
            "clock6",
            "o",
            "clock",
            "time",
            "late",
            "early",
            "schedule",
            "dawn",
            "dusk",
            "クロック6",
            "お",
            "時計",
            "時間",
            "遅い",
            "早い",
            "スケジュール",
            "夜明け",
            "夕暮れ"
        ],
        "skins": [
            {
                "unified": "1f555",
                "native": "🕕"
            }
        ],
        "version": 1
    },
    "clock630": {
        "id": "clock630",
        "name": "Six-Thirty",
        "keywords": [
            "clock630",
            "six",
            "thirty",
            "time",
            "late",
            "early",
            "schedule",
            "クロック630",
            "六",
            "30",
            "時間",
            "遅い",
            "早い",
            "スケジュール"
        ],
        "skins": [
            {
                "unified": "1f561",
                "native": "🕡"
            }
        ],
        "version": 1
    },
    "clock7": {
        "id": "clock7",
        "name": "Seven O’clock",
        "keywords": [
            "clock7",
            "o",
            "clock",
            "time",
            "late",
            "early",
            "schedule",
            "クロック7",
            "お",
            "時計",
            "時間",
            "遅い",
            "早い",
            "スケジュール"
        ],
        "skins": [
            {
                "unified": "1f556",
                "native": "🕖"
            }
        ],
        "version": 1
    },
    "clock730": {
        "id": "clock730",
        "name": "Seven-Thirty",
        "keywords": [
            "clock730",
            "seven",
            "thirty",
            "time",
            "late",
            "early",
            "schedule",
            "クロック730",
            "セブン",
            "30",
            "時間",
            "遅い",
            "早い",
            "スケジュール"
        ],
        "skins": [
            {
                "unified": "1f562",
                "native": "🕢"
            }
        ],
        "version": 1
    },
    "clock8": {
        "id": "clock8",
        "name": "Eight O’clock",
        "keywords": [
            "clock8",
            "o",
            "clock",
            "time",
            "late",
            "early",
            "schedule",
            "クロック8",
            "お",
            "時計",
            "時間",
            "遅い",
            "早い",
            "スケジュール"
        ],
        "skins": [
            {
                "unified": "1f557",
                "native": "🕗"
            }
        ],
        "version": 1
    },
    "clock830": {
        "id": "clock830",
        "name": "Eight-Thirty",
        "keywords": [
            "clock830",
            "eight",
            "thirty",
            "time",
            "late",
            "early",
            "schedule",
            "クロック830",
            "八",
            "30",
            "時間",
            "遅い",
            "早い",
            "スケジュール"
        ],
        "skins": [
            {
                "unified": "1f563",
                "native": "🕣"
            }
        ],
        "version": 1
    },
    "clock9": {
        "id": "clock9",
        "name": "Nine O’clock",
        "keywords": [
            "clock9",
            "o",
            "clock",
            "time",
            "late",
            "early",
            "schedule",
            "クロック9",
            "お",
            "時計",
            "時間",
            "遅い",
            "早い",
            "スケジュール"
        ],
        "skins": [
            {
                "unified": "1f558",
                "native": "🕘"
            }
        ],
        "version": 1
    },
    "clock930": {
        "id": "clock930",
        "name": "Nine-Thirty",
        "keywords": [
            "clock930",
            "nine",
            "thirty",
            "time",
            "late",
            "early",
            "schedule",
            "時計930",
            "九",
            "30",
            "時間",
            "遅い",
            "早い",
            "スケジュール"
        ],
        "skins": [
            {
                "unified": "1f564",
                "native": "🕤"
            }
        ],
        "version": 1
    },
    "clock10": {
        "id": "clock10",
        "name": "Ten O’clock",
        "keywords": [
            "clock10",
            "o",
            "clock",
            "time",
            "late",
            "early",
            "schedule",
            "時計10",
            "お",
            "時計",
            "時間",
            "遅い",
            "早い",
            "スケジュール"
        ],
        "skins": [
            {
                "unified": "1f559",
                "native": "🕙"
            }
        ],
        "version": 1
    },
    "clock1030": {
        "id": "clock1030",
        "name": "Ten-Thirty",
        "keywords": [
            "clock1030",
            "ten",
            "thirty",
            "time",
            "late",
            "early",
            "schedule",
            "時計1030",
            "十",
            "30",
            "時間",
            "遅い",
            "早い",
            "スケジュール"
        ],
        "skins": [
            {
                "unified": "1f565",
                "native": "🕥"
            }
        ],
        "version": 1
    },
    "clock11": {
        "id": "clock11",
        "name": "Eleven O’clock",
        "keywords": [
            "clock11",
            "o",
            "clock",
            "time",
            "late",
            "early",
            "schedule",
            "時計11",
            "お",
            "時計",
            "時間",
            "遅い",
            "早い",
            "スケジュール"
        ],
        "skins": [
            {
                "unified": "1f55a",
                "native": "🕚"
            }
        ],
        "version": 1
    },
    "clock1130": {
        "id": "clock1130",
        "name": "Eleven-Thirty",
        "keywords": [
            "clock1130",
            "eleven",
            "thirty",
            "time",
            "late",
            "early",
            "schedule",
            "時計1130",
            "十一",
            "30",
            "時間",
            "遅い",
            "早い",
            "スケジュール"
        ],
        "skins": [
            {
                "unified": "1f566",
                "native": "🕦"
            }
        ],
        "version": 1
    },
    "new_moon": {
        "id": "new_moon",
        "name": "New Moon",
        "keywords": [
            "nature",
            "twilight",
            "planet",
            "space",
            "night",
            "evening",
            "sleep",
            "自然",
            "トワイライト",
            "星",
            "空",
            "夜",
            "夜",
            "寝る"
        ],
        "skins": [
            {
                "unified": "1f311",
                "native": "🌑"
            }
        ],
        "version": 1
    },
    "waxing_crescent_moon": {
        "id": "waxing_crescent_moon",
        "name": "Waxing Crescent Moon",
        "keywords": [
            "nature",
            "twilight",
            "planet",
            "space",
            "night",
            "evening",
            "sleep",
            "自然",
            "トワイライト",
            "星",
            "空",
            "夜",
            "夜",
            "寝る"
        ],
        "skins": [
            {
                "unified": "1f312",
                "native": "🌒"
            }
        ],
        "version": 1
    },
    "first_quarter_moon": {
        "id": "first_quarter_moon",
        "name": "First Quarter Moon",
        "keywords": [
            "nature",
            "twilight",
            "planet",
            "space",
            "night",
            "evening",
            "sleep",
            "自然",
            "トワイライト",
            "星",
            "空",
            "夜",
            "夜",
            "寝る"
        ],
        "skins": [
            {
                "unified": "1f313",
                "native": "🌓"
            }
        ],
        "version": 1
    },
    "moon": {
        "id": "moon",
        "name": "Waxing Gibbous Moon",
        "keywords": [
            "nature",
            "night",
            "sky",
            "gray",
            "twilight",
            "planet",
            "space",
            "evening",
            "sleep",
            "自然",
            "夜",
            "空",
            "グレー",
            "トワイライト",
            "星",
            "空",
            "夜",
            "寝る"
        ],
        "skins": [
            {
                "unified": "1f314",
                "native": "🌔"
            }
        ],
        "version": 1
    },
    "full_moon": {
        "id": "full_moon",
        "name": "Full Moon",
        "keywords": [
            "nature",
            "yellow",
            "twilight",
            "planet",
            "space",
            "night",
            "evening",
            "sleep",
            "自然",
            "黄色",
            "トワイライト",
            "星",
            "空",
            "夜",
            "夜",
            "寝る"
        ],
        "skins": [
            {
                "unified": "1f315",
                "native": "🌕"
            }
        ],
        "version": 1
    },
    "waning_gibbous_moon": {
        "id": "waning_gibbous_moon",
        "name": "Waning Gibbous Moon",
        "keywords": [
            "nature",
            "twilight",
            "planet",
            "space",
            "night",
            "evening",
            "sleep",
            "waxing",
            "自然",
            "トワイライト",
            "星",
            "空",
            "夜",
            "夜",
            "寝る",
            "ワックスがけ"
        ],
        "skins": [
            {
                "unified": "1f316",
                "native": "🌖"
            }
        ],
        "version": 1
    },
    "last_quarter_moon": {
        "id": "last_quarter_moon",
        "name": "Last Quarter Moon",
        "keywords": [
            "nature",
            "twilight",
            "planet",
            "space",
            "night",
            "evening",
            "sleep",
            "自然",
            "トワイライト",
            "星",
            "空",
            "夜",
            "夜",
            "寝る"
        ],
        "skins": [
            {
                "unified": "1f317",
                "native": "🌗"
            }
        ],
        "version": 1
    },
    "waning_crescent_moon": {
        "id": "waning_crescent_moon",
        "name": "Waning Crescent Moon",
        "keywords": [
            "nature",
            "twilight",
            "planet",
            "space",
            "night",
            "evening",
            "sleep",
            "自然",
            "トワイライト",
            "星",
            "空",
            "夜",
            "夜",
            "寝る"
        ],
        "skins": [
            {
                "unified": "1f318",
                "native": "🌘"
            }
        ],
        "version": 1
    },
    "crescent_moon": {
        "id": "crescent_moon",
        "name": "Crescent Moon",
        "keywords": [
            "night",
            "sleep",
            "sky",
            "evening",
            "magic",
            "夜",
            "寝る",
            "空",
            "夜",
            "魔法"
        ],
        "skins": [
            {
                "unified": "1f319",
                "native": "🌙"
            }
        ],
        "version": 1
    },
    "new_moon_with_face": {
        "id": "new_moon_with_face",
        "name": "New Moon Face",
        "keywords": [
            "with",
            "nature",
            "twilight",
            "planet",
            "space",
            "night",
            "evening",
            "sleep",
            "と",
            "自然",
            "トワイライト",
            "星",
            "空",
            "夜",
            "夜",
            "寝る"
        ],
        "skins": [
            {
                "unified": "1f31a",
                "native": "🌚"
            }
        ],
        "version": 1
    },
    "first_quarter_moon_with_face": {
        "id": "first_quarter_moon_with_face",
        "name": "First Quarter Moon Face",
        "keywords": [
            "with",
            "nature",
            "twilight",
            "planet",
            "space",
            "night",
            "evening",
            "sleep",
            "と",
            "自然",
            "トワイライト",
            "星",
            "空",
            "夜",
            "夜",
            "寝る"
        ],
        "skins": [
            {
                "unified": "1f31b",
                "native": "🌛"
            }
        ],
        "version": 1
    },
    "last_quarter_moon_with_face": {
        "id": "last_quarter_moon_with_face",
        "name": "Last Quarter Moon Face",
        "keywords": [
            "with",
            "nature",
            "twilight",
            "planet",
            "space",
            "night",
            "evening",
            "sleep",
            "と",
            "自然",
            "トワイライト",
            "星",
            "空",
            "夜",
            "夜",
            "寝る"
        ],
        "skins": [
            {
                "unified": "1f31c",
                "native": "🌜"
            }
        ],
        "version": 1
    },
    "thermometer": {
        "id": "thermometer",
        "name": "Thermometer",
        "keywords": [
            "weather",
            "temperature",
            "hot",
            "cold",
            "天気",
            "温度",
            "熱い",
            "寒い"
        ],
        "skins": [
            {
                "unified": "1f321-fe0f",
                "native": "🌡️"
            }
        ],
        "version": 1
    },
    "sunny": {
        "id": "sunny",
        "name": "Sun",
        "keywords": [
            "sunny",
            "weather",
            "nature",
            "brightness",
            "summer",
            "beach",
            "spring",
            "晴れ",
            "天気",
            "自然",
            "輝度",
            "夏",
            "ビーチ",
            "春"
        ],
        "skins": [
            {
                "unified": "2600-fe0f",
                "native": "☀️"
            }
        ],
        "version": 1
    },
    "full_moon_with_face": {
        "id": "full_moon_with_face",
        "name": "Full Moon Face",
        "keywords": [
            "with",
            "nature",
            "twilight",
            "planet",
            "space",
            "night",
            "evening",
            "sleep",
            "と",
            "自然",
            "トワイライト",
            "星",
            "空",
            "夜",
            "夜",
            "寝る"
        ],
        "skins": [
            {
                "unified": "1f31d",
                "native": "🌝"
            }
        ],
        "version": 1
    },
    "sun_with_face": {
        "id": "sun_with_face",
        "name": "Sun with Face",
        "keywords": [
            "nature",
            "morning",
            "sky",
            "自然",
            "朝",
            "空"
        ],
        "skins": [
            {
                "unified": "1f31e",
                "native": "🌞"
            }
        ],
        "version": 1
    },
    "ringed_planet": {
        "id": "ringed_planet",
        "name": "Ringed Planet",
        "keywords": [
            "outerspace",
            "宇宙"
        ],
        "skins": [
            {
                "unified": "1fa90",
                "native": "🪐"
            }
        ],
        "version": 12
    },
    "star": {
        "id": "star",
        "name": "Star",
        "keywords": [
            "night",
            "yellow",
            "夜",
            "黄色"
        ],
        "skins": [
            {
                "unified": "2b50",
                "native": "⭐"
            }
        ],
        "version": 1
    },
    "star2": {
        "id": "star2",
        "name": "Glowing Star",
        "keywords": [
            "star2",
            "night",
            "sparkle",
            "awesome",
            "good",
            "magic",
            "スター2",
            "夜",
            "輝く",
            "素晴らしい",
            "良い",
            "魔法"
        ],
        "skins": [
            {
                "unified": "1f31f",
                "native": "🌟"
            }
        ],
        "version": 1
    },
    "stars": {
        "id": "stars",
        "name": "Shooting Star",
        "keywords": [
            "stars",
            "night",
            "photo",
            "出演者",
            "夜",
            "写真"
        ],
        "skins": [
            {
                "unified": "1f320",
                "native": "🌠"
            }
        ],
        "version": 1
    },
    "milky_way": {
        "id": "milky_way",
        "name": "Milky Way",
        "keywords": [
            "photo",
            "space",
            "stars",
            "写真",
            "空",
            "出演者"
        ],
        "skins": [
            {
                "unified": "1f30c",
                "native": "🌌"
            }
        ],
        "version": 1
    },
    "cloud": {
        "id": "cloud",
        "name": "Cloud",
        "keywords": [
            "weather",
            "sky",
            "天気",
            "空"
        ],
        "skins": [
            {
                "unified": "2601-fe0f",
                "native": "☁️"
            }
        ],
        "version": 1
    },
    "partly_sunny": {
        "id": "partly_sunny",
        "name": "Sun Behind Cloud",
        "keywords": [
            "partly",
            "sunny",
            "weather",
            "nature",
            "cloudy",
            "morning",
            "fall",
            "spring",
            "部分的に",
            "晴れ",
            "天気",
            "自然",
            "曇り",
            "朝",
            "秋",
            "春"
        ],
        "skins": [
            {
                "unified": "26c5",
                "native": "⛅"
            }
        ],
        "version": 1
    },
    "thunder_cloud_and_rain": {
        "id": "thunder_cloud_and_rain",
        "name": "Cloud with Lightning and Rain",
        "keywords": [
            "thunder",
            "weather",
            "雷",
            "天気"
        ],
        "skins": [
            {
                "unified": "26c8-fe0f",
                "native": "⛈️"
            }
        ],
        "version": 1
    },
    "mostly_sunny": {
        "id": "mostly_sunny",
        "name": "Sun Behind Small Cloud",
        "keywords": [
            "mostly",
            "sunny",
            "weather",
            "多くの場合",
            "晴れ",
            "天気"
        ],
        "skins": [
            {
                "unified": "1f324-fe0f",
                "native": "🌤️"
            }
        ],
        "version": 1
    },
    "barely_sunny": {
        "id": "barely_sunny",
        "name": "Sun Behind Large Cloud",
        "keywords": [
            "barely",
            "sunny",
            "weather",
            "かろうじて",
            "晴れ",
            "天気"
        ],
        "skins": [
            {
                "unified": "1f325-fe0f",
                "native": "🌥️"
            }
        ],
        "version": 1
    },
    "partly_sunny_rain": {
        "id": "partly_sunny_rain",
        "name": "Sun Behind Rain Cloud",
        "keywords": [
            "partly",
            "sunny",
            "weather",
            "部分的に",
            "晴れ",
            "天気"
        ],
        "skins": [
            {
                "unified": "1f326-fe0f",
                "native": "🌦️"
            }
        ],
        "version": 1
    },
    "rain_cloud": {
        "id": "rain_cloud",
        "name": "Cloud with Rain",
        "keywords": [
            "weather",
            "天気"
        ],
        "skins": [
            {
                "unified": "1f327-fe0f",
                "native": "🌧️"
            }
        ],
        "version": 1
    },
    "snow_cloud": {
        "id": "snow_cloud",
        "name": "Cloud with Snow",
        "keywords": [
            "weather",
            "天気"
        ],
        "skins": [
            {
                "unified": "1f328-fe0f",
                "native": "🌨️"
            }
        ],
        "version": 1
    },
    "lightning": {
        "id": "lightning",
        "name": "Cloud with Lightning",
        "keywords": [
            "weather",
            "thunder",
            "天気",
            "雷"
        ],
        "skins": [
            {
                "unified": "1f329-fe0f",
                "native": "🌩️"
            }
        ],
        "version": 1
    },
    "tornado": {
        "id": "tornado",
        "name": "Tornado",
        "keywords": [
            "cloud",
            "weather",
            "cyclone",
            "twister",
            "雲",
            "天気",
            "サイクロン",
            "ツイスター"
        ],
        "skins": [
            {
                "unified": "1f32a-fe0f",
                "native": "🌪️"
            }
        ],
        "version": 1
    },
    "fog": {
        "id": "fog",
        "name": "Fog",
        "keywords": [
            "weather",
            "天気"
        ],
        "skins": [
            {
                "unified": "1f32b-fe0f",
                "native": "🌫️"
            }
        ],
        "version": 1
    },
    "wind_blowing_face": {
        "id": "wind_blowing_face",
        "name": "Wind Face",
        "keywords": [
            "blowing",
            "gust",
            "air",
            "吹く",
            "突風",
            "空気"
        ],
        "skins": [
            {
                "unified": "1f32c-fe0f",
                "native": "🌬️"
            }
        ],
        "version": 1
    },
    "cyclone": {
        "id": "cyclone",
        "name": "Cyclone",
        "keywords": [
            "weather",
            "swirl",
            "blue",
            "cloud",
            "vortex",
            "spiral",
            "whirlpool",
            "spin",
            "tornado",
            "hurricane",
            "typhoon",
            "天気",
            "渦",
            "青",
            "雲",
            "渦",
            "螺旋",
            "ワールプール",
            "スピン",
            "竜巻",
            "ハリケーン",
            "台風"
        ],
        "skins": [
            {
                "unified": "1f300",
                "native": "🌀"
            }
        ],
        "version": 1
    },
    "rainbow": {
        "id": "rainbow",
        "name": "Rainbow",
        "keywords": [
            "nature",
            "happy",
            "unicorn",
            "face",
            "photo",
            "sky",
            "spring",
            "自然",
            "ハッピー",
            "ユニコーン",
            "顔",
            "写真",
            "空",
            "春"
        ],
        "skins": [
            {
                "unified": "1f308",
                "native": "🌈"
            }
        ],
        "version": 1
    },
    "closed_umbrella": {
        "id": "closed_umbrella",
        "name": "Closed Umbrella",
        "keywords": [
            "weather",
            "rain",
            "drizzle",
            "天気",
            "雨",
            "霧雨"
        ],
        "skins": [
            {
                "unified": "1f302",
                "native": "🌂"
            }
        ],
        "version": 1
    },
    "umbrella": {
        "id": "umbrella",
        "name": "Umbrella",
        "keywords": [
            "weather",
            "spring",
            "天気",
            "春"
        ],
        "skins": [
            {
                "unified": "2602-fe0f",
                "native": "☂️"
            }
        ],
        "version": 1
    },
    "umbrella_with_rain_drops": {
        "id": "umbrella_with_rain_drops",
        "name": "Umbrella with Rain Drops",
        "keywords": [
            "rainy",
            "weather",
            "spring",
            "雨の",
            "天気",
            "春"
        ],
        "skins": [
            {
                "unified": "2614",
                "native": "☔"
            }
        ],
        "version": 1
    },
    "umbrella_on_ground": {
        "id": "umbrella_on_ground",
        "name": "Umbrella on Ground",
        "keywords": [
            "weather",
            "summer",
            "天気",
            "夏"
        ],
        "skins": [
            {
                "unified": "26f1-fe0f",
                "native": "⛱️"
            }
        ],
        "version": 1
    },
    "zap": {
        "id": "zap",
        "name": "High Voltage",
        "keywords": [
            "zap",
            "thunder",
            "weather",
            "lightning",
            "bolt",
            "fast",
            "ザップ",
            "雷",
            "天気",
            "雷",
            "ボルト",
            "速い"
        ],
        "skins": [
            {
                "unified": "26a1",
                "native": "⚡"
            }
        ],
        "version": 1
    },
    "snowflake": {
        "id": "snowflake",
        "name": "Snowflake",
        "keywords": [
            "winter",
            "season",
            "cold",
            "weather",
            "christmas",
            "xmas",
            "冬",
            "季節",
            "寒い",
            "天気",
            "クリスマス",
            "クリスマス"
        ],
        "skins": [
            {
                "unified": "2744-fe0f",
                "native": "❄️"
            }
        ],
        "version": 1
    },
    "snowman": {
        "id": "snowman",
        "name": "Snowman",
        "keywords": [
            "winter",
            "season",
            "cold",
            "weather",
            "christmas",
            "xmas",
            "frozen",
            "冬",
            "季節",
            "寒い",
            "天気",
            "クリスマス",
            "クリスマス",
            "凍った"
        ],
        "skins": [
            {
                "unified": "2603-fe0f",
                "native": "☃️"
            }
        ],
        "version": 1
    },
    "snowman_without_snow": {
        "id": "snowman_without_snow",
        "name": "Snowman Without Snow",
        "keywords": [
            "winter",
            "season",
            "cold",
            "weather",
            "christmas",
            "xmas",
            "frozen",
            "冬",
            "季節",
            "寒い",
            "天気",
            "クリスマス",
            "クリスマス",
            "凍った"
        ],
        "skins": [
            {
                "unified": "26c4",
                "native": "⛄"
            }
        ],
        "version": 1
    },
    "comet": {
        "id": "comet",
        "name": "Comet",
        "keywords": [
            "space",
            "空"
        ],
        "skins": [
            {
                "unified": "2604-fe0f",
                "native": "☄️"
            }
        ],
        "version": 1
    },
    "fire": {
        "id": "fire",
        "name": "Fire",
        "keywords": [
            "hot",
            "cook",
            "flame",
            "熱い",
            "料理",
            "火炎"
        ],
        "skins": [
            {
                "unified": "1f525",
                "native": "🔥"
            }
        ],
        "version": 1
    },
    "droplet": {
        "id": "droplet",
        "name": "Droplet",
        "keywords": [
            "water",
            "drip",
            "faucet",
            "spring",
            "水",
            "滴下",
            "蛇口",
            "春"
        ],
        "skins": [
            {
                "unified": "1f4a7",
                "native": "💧"
            }
        ],
        "version": 1
    },
    "ocean": {
        "id": "ocean",
        "name": "Water Wave",
        "keywords": [
            "ocean",
            "sea",
            "nature",
            "tsunami",
            "disaster",
            "海洋",
            "海",
            "自然",
            "津波",
            "災害"
        ],
        "skins": [
            {
                "unified": "1f30a",
                "native": "🌊"
            }
        ],
        "version": 1
    },
    "jack_o_lantern": {
        "id": "jack_o_lantern",
        "name": "Jack-O-Lantern",
        "keywords": [
            "jack",
            "o",
            "lantern",
            "halloween",
            "light",
            "pumpkin",
            "creepy",
            "fall",
            "ジャック",
            "お",
            "ランタン",
            "ハロウィン",
            "ライト",
            "かぼちゃ",
            "気味の悪い",
            "秋"
        ],
        "skins": [
            {
                "unified": "1f383",
                "native": "🎃"
            }
        ],
        "version": 1
    },
    "christmas_tree": {
        "id": "christmas_tree",
        "name": "Christmas Tree",
        "keywords": [
            "festival",
            "vacation",
            "december",
            "xmas",
            "celebration",
            "祭り",
            "休暇",
            "12月",
            "クリスマス",
            "お祝い"
        ],
        "skins": [
            {
                "unified": "1f384",
                "native": "🎄"
            }
        ],
        "version": 1
    },
    "fireworks": {
        "id": "fireworks",
        "name": "Fireworks",
        "keywords": [
            "photo",
            "festival",
            "carnival",
            "congratulations",
            "写真",
            "祭り",
            "カーニバル",
            "おめでとう"
        ],
        "skins": [
            {
                "unified": "1f386",
                "native": "🎆"
            }
        ],
        "version": 1
    },
    "sparkler": {
        "id": "sparkler",
        "name": "Sparkler",
        "keywords": [
            "stars",
            "night",
            "shine",
            "出演者",
            "夜",
            "輝く"
        ],
        "skins": [
            {
                "unified": "1f387",
                "native": "🎇"
            }
        ],
        "version": 1
    },
    "firecracker": {
        "id": "firecracker",
        "name": "Firecracker",
        "keywords": [
            "dynamite",
            "boom",
            "explode",
            "explosion",
            "explosive",
            "ダイナマイト",
            "ブーム",
            "爆発",
            "爆発",
            "爆発物"
        ],
        "skins": [
            {
                "unified": "1f9e8",
                "native": "🧨"
            }
        ],
        "version": 11
    },
    "sparkles": {
        "id": "sparkles",
        "name": "Sparkles",
        "keywords": [
            "stars",
            "shine",
            "shiny",
            "cool",
            "awesome",
            "good",
            "magic",
            "出演者",
            "輝く",
            "光沢のある",
            "いいね",
            "素晴らしい",
            "良い",
            "魔法"
        ],
        "skins": [
            {
                "unified": "2728",
                "native": "✨"
            }
        ],
        "version": 1
    },
    "balloon": {
        "id": "balloon",
        "name": "Balloon",
        "keywords": [
            "party",
            "celebration",
            "birthday",
            "circus",
            "パーティ",
            "お祝い",
            "誕生日",
            "サーカス"
        ],
        "skins": [
            {
                "unified": "1f388",
                "native": "🎈"
            }
        ],
        "version": 1
    },
    "tada": {
        "id": "tada",
        "name": "Party Popper",
        "keywords": [
            "tada",
            "congratulations",
            "birthday",
            "magic",
            "circus",
            "celebration",
            "タダ",
            "おめでとう",
            "誕生日",
            "魔法",
            "サーカス",
            "お祝い"
        ],
        "skins": [
            {
                "unified": "1f389",
                "native": "🎉"
            }
        ],
        "version": 1
    },
    "confetti_ball": {
        "id": "confetti_ball",
        "name": "Confetti Ball",
        "keywords": [
            "festival",
            "party",
            "birthday",
            "circus",
            "祭り",
            "パーティ",
            "誕生日",
            "サーカス"
        ],
        "skins": [
            {
                "unified": "1f38a",
                "native": "🎊"
            }
        ],
        "version": 1
    },
    "tanabata_tree": {
        "id": "tanabata_tree",
        "name": "Tanabata Tree",
        "keywords": [
            "plant",
            "nature",
            "branch",
            "summer",
            "植物",
            "自然",
            "ブランチ",
            "夏"
        ],
        "skins": [
            {
                "unified": "1f38b",
                "native": "🎋"
            }
        ],
        "version": 1
    },
    "bamboo": {
        "id": "bamboo",
        "name": "Pine Decoration",
        "keywords": [
            "bamboo",
            "plant",
            "nature",
            "vegetable",
            "panda",
            "竹",
            "植物",
            "自然",
            "野菜",
            "パンダ"
        ],
        "skins": [
            {
                "unified": "1f38d",
                "native": "🎍"
            }
        ],
        "version": 1
    },
    "dolls": {
        "id": "dolls",
        "name": "Japanese Dolls",
        "keywords": [
            "toy",
            "kimono",
            "おもちゃ",
            "着物"
        ],
        "skins": [
            {
                "unified": "1f38e",
                "native": "🎎"
            }
        ],
        "version": 1
    },
    "flags": {
        "id": "flags",
        "name": "Carp Streamer",
        "keywords": [
            "flags",
            "fish",
            "japanese",
            "koinobori",
            "banner",
            "フラグ",
            "魚",
            "日本",
            "こいのぼり",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f38f",
                "native": "🎏"
            }
        ],
        "version": 1
    },
    "wind_chime": {
        "id": "wind_chime",
        "name": "Wind Chime",
        "keywords": [
            "nature",
            "ding",
            "spring",
            "bell",
            "自然",
            "ディン",
            "春",
            "ベル"
        ],
        "skins": [
            {
                "unified": "1f390",
                "native": "🎐"
            }
        ],
        "version": 1
    },
    "rice_scene": {
        "id": "rice_scene",
        "name": "Moon Viewing Ceremony",
        "keywords": [
            "rice",
            "scene",
            "photo",
            "japan",
            "asia",
            "tsukimi",
            "米",
            "シーン",
            "写真",
            "日本",
            "アジア",
            "つきみ"
        ],
        "skins": [
            {
                "unified": "1f391",
                "native": "🎑"
            }
        ],
        "version": 1
    },
    "red_envelope": {
        "id": "red_envelope",
        "name": "Red Envelope",
        "keywords": [
            "gift",
            "贈り物"
        ],
        "skins": [
            {
                "unified": "1f9e7",
                "native": "🧧"
            }
        ],
        "version": 11
    },
    "ribbon": {
        "id": "ribbon",
        "name": "Ribbon",
        "keywords": [
            "decoration",
            "pink",
            "girl",
            "bowtie",
            "装飾",
            "ピンク",
            "女の子",
            "ちょうネクタイ"
        ],
        "skins": [
            {
                "unified": "1f380",
                "native": "🎀"
            }
        ],
        "version": 1
    },
    "gift": {
        "id": "gift",
        "name": "Wrapped Gift",
        "keywords": [
            "present",
            "birthday",
            "christmas",
            "xmas",
            "現在",
            "誕生日",
            "クリスマス",
            "クリスマス"
        ],
        "skins": [
            {
                "unified": "1f381",
                "native": "🎁"
            }
        ],
        "version": 1
    },
    "reminder_ribbon": {
        "id": "reminder_ribbon",
        "name": "Reminder Ribbon",
        "keywords": [
            "sports",
            "cause",
            "support",
            "awareness",
            "スポーツ",
            "原因",
            "サポート",
            "意識"
        ],
        "skins": [
            {
                "unified": "1f397-fe0f",
                "native": "🎗️"
            }
        ],
        "version": 1
    },
    "admission_tickets": {
        "id": "admission_tickets",
        "name": "Admission Tickets",
        "keywords": [
            "sports",
            "concert",
            "entrance",
            "スポーツ",
            "コンサート",
            "入り口"
        ],
        "skins": [
            {
                "unified": "1f39f-fe0f",
                "native": "🎟️"
            }
        ],
        "version": 1
    },
    "ticket": {
        "id": "ticket",
        "name": "Ticket",
        "keywords": [
            "event",
            "concert",
            "pass",
            "イベント",
            "コンサート",
            "合格"
        ],
        "skins": [
            {
                "unified": "1f3ab",
                "native": "🎫"
            }
        ],
        "version": 1
    },
    "medal": {
        "id": "medal",
        "name": "Military Medal",
        "keywords": [
            "award",
            "winning",
            "army",
            "アワード",
            "勝つ",
            "軍"
        ],
        "skins": [
            {
                "unified": "1f396-fe0f",
                "native": "🎖️"
            }
        ],
        "version": 1
    },
    "trophy": {
        "id": "trophy",
        "name": "Trophy",
        "keywords": [
            "win",
            "award",
            "contest",
            "place",
            "ftw",
            "ceremony",
            "勝つ",
            "アワード",
            "コンテスト",
            "場所",
            "フト",
            "式"
        ],
        "skins": [
            {
                "unified": "1f3c6",
                "native": "🏆"
            }
        ],
        "version": 1
    },
    "sports_medal": {
        "id": "sports_medal",
        "name": "Sports Medal",
        "keywords": [
            "award",
            "winning",
            "アワード",
            "勝つ"
        ],
        "skins": [
            {
                "unified": "1f3c5",
                "native": "🏅"
            }
        ],
        "version": 1
    },
    "first_place_medal": {
        "id": "first_place_medal",
        "name": "1st Place Medal",
        "keywords": [
            "first",
            "award",
            "winning",
            "初め",
            "アワード",
            "勝つ"
        ],
        "skins": [
            {
                "unified": "1f947",
                "native": "🥇"
            }
        ],
        "version": 3
    },
    "second_place_medal": {
        "id": "second_place_medal",
        "name": "2nd Place Medal",
        "keywords": [
            "second",
            "award",
            "2番",
            "アワード"
        ],
        "skins": [
            {
                "unified": "1f948",
                "native": "🥈"
            }
        ],
        "version": 3
    },
    "third_place_medal": {
        "id": "third_place_medal",
        "name": "3rd Place Medal",
        "keywords": [
            "third",
            "award",
            "三番目",
            "アワード"
        ],
        "skins": [
            {
                "unified": "1f949",
                "native": "🥉"
            }
        ],
        "version": 3
    },
    "soccer": {
        "id": "soccer",
        "name": "Soccer Ball",
        "keywords": [
            "sports",
            "football",
            "スポーツ",
            "フットボール"
        ],
        "skins": [
            {
                "unified": "26bd",
                "native": "⚽"
            }
        ],
        "version": 1
    },
    "baseball": {
        "id": "baseball",
        "name": "Baseball",
        "keywords": [
            "sports",
            "balls",
            "スポーツ",
            "ボール"
        ],
        "skins": [
            {
                "unified": "26be",
                "native": "⚾"
            }
        ],
        "version": 1
    },
    "softball": {
        "id": "softball",
        "name": "Softball",
        "keywords": [
            "sports",
            "balls",
            "スポーツ",
            "ボール"
        ],
        "skins": [
            {
                "unified": "1f94e",
                "native": "🥎"
            }
        ],
        "version": 11
    },
    "basketball": {
        "id": "basketball",
        "name": "Basketball",
        "keywords": [
            "sports",
            "balls",
            "NBA",
            "スポーツ",
            "ボール",
            "NBA"
        ],
        "skins": [
            {
                "unified": "1f3c0",
                "native": "🏀"
            }
        ],
        "version": 1
    },
    "volleyball": {
        "id": "volleyball",
        "name": "Volleyball",
        "keywords": [
            "sports",
            "balls",
            "スポーツ",
            "ボール"
        ],
        "skins": [
            {
                "unified": "1f3d0",
                "native": "🏐"
            }
        ],
        "version": 1
    },
    "football": {
        "id": "football",
        "name": "American Football",
        "keywords": [
            "sports",
            "balls",
            "NFL",
            "スポーツ",
            "ボール",
            "NFL"
        ],
        "skins": [
            {
                "unified": "1f3c8",
                "native": "🏈"
            }
        ],
        "version": 1
    },
    "rugby_football": {
        "id": "rugby_football",
        "name": "Rugby Football",
        "keywords": [
            "sports",
            "team",
            "スポーツ",
            "チーム"
        ],
        "skins": [
            {
                "unified": "1f3c9",
                "native": "🏉"
            }
        ],
        "version": 1
    },
    "tennis": {
        "id": "tennis",
        "name": "Tennis",
        "keywords": [
            "sports",
            "balls",
            "green",
            "スポーツ",
            "ボール",
            "緑"
        ],
        "skins": [
            {
                "unified": "1f3be",
                "native": "🎾"
            }
        ],
        "version": 1
    },
    "flying_disc": {
        "id": "flying_disc",
        "name": "Flying Disc",
        "keywords": [
            "sports",
            "frisbee",
            "ultimate",
            "スポーツ",
            "フリスビー",
            "究極"
        ],
        "skins": [
            {
                "unified": "1f94f",
                "native": "🥏"
            }
        ],
        "version": 11
    },
    "bowling": {
        "id": "bowling",
        "name": "Bowling",
        "keywords": [
            "sports",
            "fun",
            "play",
            "スポーツ",
            "楽しい",
            "遊ぶ"
        ],
        "skins": [
            {
                "unified": "1f3b3",
                "native": "🎳"
            }
        ],
        "version": 1
    },
    "cricket_bat_and_ball": {
        "id": "cricket_bat_and_ball",
        "name": "Cricket Game",
        "keywords": [
            "bat",
            "and",
            "ball",
            "sports",
            "コウモリ",
            "と",
            "ボール",
            "スポーツ"
        ],
        "skins": [
            {
                "unified": "1f3cf",
                "native": "🏏"
            }
        ],
        "version": 1
    },
    "field_hockey_stick_and_ball": {
        "id": "field_hockey_stick_and_ball",
        "name": "Field Hockey",
        "keywords": [
            "stick",
            "and",
            "ball",
            "sports",
            "棒",
            "と",
            "ボール",
            "スポーツ"
        ],
        "skins": [
            {
                "unified": "1f3d1",
                "native": "🏑"
            }
        ],
        "version": 1
    },
    "ice_hockey_stick_and_puck": {
        "id": "ice_hockey_stick_and_puck",
        "name": "Ice Hockey",
        "keywords": [
            "stick",
            "and",
            "puck",
            "sports",
            "棒",
            "と",
            "パック",
            "スポーツ"
        ],
        "skins": [
            {
                "unified": "1f3d2",
                "native": "🏒"
            }
        ],
        "version": 1
    },
    "lacrosse": {
        "id": "lacrosse",
        "name": "Lacrosse",
        "keywords": [
            "sports",
            "ball",
            "stick",
            "スポーツ",
            "ボール",
            "棒"
        ],
        "skins": [
            {
                "unified": "1f94d",
                "native": "🥍"
            }
        ],
        "version": 11
    },
    "table_tennis_paddle_and_ball": {
        "id": "table_tennis_paddle_and_ball",
        "name": "Ping Pong",
        "keywords": [
            "table",
            "tennis",
            "paddle",
            "and",
            "ball",
            "sports",
            "pingpong",
            "テーブル",
            "テニス",
            "パドル",
            "と",
            "ボール",
            "スポーツ",
            "卓球"
        ],
        "skins": [
            {
                "unified": "1f3d3",
                "native": "🏓"
            }
        ],
        "version": 1
    },
    "badminton_racquet_and_shuttlecock": {
        "id": "badminton_racquet_and_shuttlecock",
        "name": "Badminton",
        "keywords": [
            "racquet",
            "and",
            "shuttlecock",
            "sports",
            "ラケット",
            "と",
            "羽根",
            "スポーツ"
        ],
        "skins": [
            {
                "unified": "1f3f8",
                "native": "🏸"
            }
        ],
        "version": 1
    },
    "boxing_glove": {
        "id": "boxing_glove",
        "name": "Boxing Glove",
        "keywords": [
            "sports",
            "fighting",
            "スポーツ",
            "ファインティング"
        ],
        "skins": [
            {
                "unified": "1f94a",
                "native": "🥊"
            }
        ],
        "version": 3
    },
    "martial_arts_uniform": {
        "id": "martial_arts_uniform",
        "name": "Martial Arts Uniform",
        "keywords": [
            "judo",
            "karate",
            "taekwondo",
            "柔道",
            "空手",
            "テコンドー"
        ],
        "skins": [
            {
                "unified": "1f94b",
                "native": "🥋"
            }
        ],
        "version": 3
    },
    "goal_net": {
        "id": "goal_net",
        "name": "Goal Net",
        "keywords": [
            "sports",
            "スポーツ"
        ],
        "skins": [
            {
                "unified": "1f945",
                "native": "🥅"
            }
        ],
        "version": 3
    },
    "golf": {
        "id": "golf",
        "name": "Flag in Hole",
        "keywords": [
            "golf",
            "sports",
            "business",
            "summer",
            "ゴルフ",
            "スポーツ",
            "仕事",
            "夏"
        ],
        "skins": [
            {
                "unified": "26f3",
                "native": "⛳"
            }
        ],
        "version": 1
    },
    "ice_skate": {
        "id": "ice_skate",
        "name": "Ice Skate",
        "keywords": [
            "sports",
            "スポーツ"
        ],
        "skins": [
            {
                "unified": "26f8-fe0f",
                "native": "⛸️"
            }
        ],
        "version": 1
    },
    "fishing_pole_and_fish": {
        "id": "fishing_pole_and_fish",
        "name": "Fishing Pole",
        "keywords": [
            "and",
            "fish",
            "food",
            "hobby",
            "summer",
            "と",
            "魚",
            "食べ物",
            "趣味",
            "夏"
        ],
        "skins": [
            {
                "unified": "1f3a3",
                "native": "🎣"
            }
        ],
        "version": 1
    },
    "diving_mask": {
        "id": "diving_mask",
        "name": "Diving Mask",
        "keywords": [
            "sport",
            "ocean",
            "スポーツ",
            "海洋"
        ],
        "skins": [
            {
                "unified": "1f93f",
                "native": "🤿"
            }
        ],
        "version": 12
    },
    "running_shirt_with_sash": {
        "id": "running_shirt_with_sash",
        "name": "Running Shirt",
        "keywords": [
            "with",
            "sash",
            "play",
            "pageant",
            "と",
            "サッシ",
            "遊ぶ",
            "ページェント"
        ],
        "skins": [
            {
                "unified": "1f3bd",
                "native": "🎽"
            }
        ],
        "version": 1
    },
    "ski": {
        "id": "ski",
        "name": "Skis",
        "keywords": [
            "ski",
            "sports",
            "winter",
            "cold",
            "snow",
            "スキー",
            "スポーツ",
            "冬",
            "寒い",
            "雪"
        ],
        "skins": [
            {
                "unified": "1f3bf",
                "native": "🎿"
            }
        ],
        "version": 1
    },
    "sled": {
        "id": "sled",
        "name": "Sled",
        "keywords": [
            "sleigh",
            "luge",
            "toboggan",
            "そり",
            "リュージュ",
            "トボガン"
        ],
        "skins": [
            {
                "unified": "1f6f7",
                "native": "🛷"
            }
        ],
        "version": 5
    },
    "curling_stone": {
        "id": "curling_stone",
        "name": "Curling Stone",
        "keywords": [
            "sports",
            "スポーツ"
        ],
        "skins": [
            {
                "unified": "1f94c",
                "native": "🥌"
            }
        ],
        "version": 5
    },
    "dart": {
        "id": "dart",
        "name": "Bullseye",
        "keywords": [
            "dart",
            "direct",
            "hit",
            "game",
            "play",
            "bar",
            "target",
            "ダーツ",
            "直接",
            "打つ",
            "ゲーム",
            "遊ぶ",
            "バー",
            "目標"
        ],
        "skins": [
            {
                "unified": "1f3af",
                "native": "🎯"
            }
        ],
        "version": 1
    },
    "yo-yo": {
        "id": "yo-yo",
        "name": "Yo-Yo",
        "keywords": [
            "yo",
            "toy",
            "よ",
            "おもちゃ"
        ],
        "skins": [
            {
                "unified": "1fa80",
                "native": "🪀"
            }
        ],
        "version": 12
    },
    "kite": {
        "id": "kite",
        "name": "Kite",
        "keywords": [
            "wind",
            "fly",
            "風",
            "飛ぶ"
        ],
        "skins": [
            {
                "unified": "1fa81",
                "native": "🪁"
            }
        ],
        "version": 12
    },
    "8ball": {
        "id": "8ball",
        "name": "Billiards",
        "keywords": [
            "8ball",
            "pool",
            "8",
            "ball",
            "hobby",
            "game",
            "luck",
            "magic",
            "8ボール",
            "プール",
            "8",
            "ボール",
            "趣味",
            "ゲーム",
            "ラック",
            "魔法"
        ],
        "skins": [
            {
                "unified": "1f3b1",
                "native": "🎱"
            }
        ],
        "version": 1
    },
    "crystal_ball": {
        "id": "crystal_ball",
        "name": "Crystal Ball",
        "keywords": [
            "disco",
            "party",
            "magic",
            "circus",
            "fortune",
            "teller",
            "ディスコ",
            "パーティ",
            "魔法",
            "サーカス",
            "運",
            "テラー"
        ],
        "skins": [
            {
                "unified": "1f52e",
                "native": "🔮"
            }
        ],
        "version": 1
    },
    "magic_wand": {
        "id": "magic_wand",
        "name": "Magic Wand",
        "keywords": [
            "supernature",
            "power",
            "超自然",
            "力"
        ],
        "skins": [
            {
                "unified": "1fa84",
                "native": "🪄"
            }
        ],
        "version": 13
    },
    "nazar_amulet": {
        "id": "nazar_amulet",
        "name": "Nazar Amulet",
        "keywords": [
            "bead",
            "charm",
            "ビーズ",
            "魅力"
        ],
        "skins": [
            {
                "unified": "1f9ff",
                "native": "🧿"
            }
        ],
        "version": 11
    },
    "hamsa": {
        "id": "hamsa",
        "name": "Hamsa",
        "keywords": [
            "religion",
            "protection",
            "宗教",
            "保護"
        ],
        "skins": [
            {
                "unified": "1faac",
                "native": "🪬"
            }
        ],
        "version": 14
    },
    "video_game": {
        "id": "video_game",
        "name": "Video Game",
        "keywords": [
            "play",
            "console",
            "PS4",
            "controller",
            "遊ぶ",
            "コンソール",
            "PS4",
            "コントローラ"
        ],
        "skins": [
            {
                "unified": "1f3ae",
                "native": "🎮"
            }
        ],
        "version": 1
    },
    "joystick": {
        "id": "joystick",
        "name": "Joystick",
        "keywords": [
            "game",
            "play",
            "ゲーム",
            "遊ぶ"
        ],
        "skins": [
            {
                "unified": "1f579-fe0f",
                "native": "🕹️"
            }
        ],
        "version": 1
    },
    "slot_machine": {
        "id": "slot_machine",
        "name": "Slot Machine",
        "keywords": [
            "bet",
            "gamble",
            "vegas",
            "fruit",
            "luck",
            "casino",
            "ベット",
            "ギャンブル",
            "ラスベガス",
            "フルーツ",
            "ラック",
            "カジノ"
        ],
        "skins": [
            {
                "unified": "1f3b0",
                "native": "🎰"
            }
        ],
        "version": 1
    },
    "game_die": {
        "id": "game_die",
        "name": "Game Die",
        "keywords": [
            "dice",
            "random",
            "tabletop",
            "play",
            "luck",
            "さいころ",
            "ランダム",
            "テーブルトップ",
            "遊ぶ",
            "ラック"
        ],
        "skins": [
            {
                "unified": "1f3b2",
                "native": "🎲"
            }
        ],
        "version": 1
    },
    "jigsaw": {
        "id": "jigsaw",
        "name": "Puzzle Piece",
        "keywords": [
            "jigsaw",
            "interlocking",
            "ジグソーパズル",
            "連動"
        ],
        "skins": [
            {
                "unified": "1f9e9",
                "native": "🧩"
            }
        ],
        "version": 11
    },
    "teddy_bear": {
        "id": "teddy_bear",
        "name": "Teddy Bear",
        "keywords": [
            "plush",
            "stuffed",
            "ぬいぐるみ",
            "つめた"
        ],
        "skins": [
            {
                "unified": "1f9f8",
                "native": "🧸"
            }
        ],
        "version": 11
    },
    "pinata": {
        "id": "pinata",
        "name": "Pinata",
        "keywords": [
            "mexico",
            "candy",
            "celebration",
            "メキシコ",
            "あめ",
            "お祝い"
        ],
        "skins": [
            {
                "unified": "1fa85",
                "native": "🪅"
            }
        ],
        "version": 13
    },
    "mirror_ball": {
        "id": "mirror_ball",
        "name": "Mirror Ball",
        "keywords": [
            "disco",
            "dance",
            "party",
            "ディスコ",
            "ダンス",
            "パーティ"
        ],
        "skins": [
            {
                "unified": "1faa9",
                "native": "🪩"
            }
        ],
        "version": 14
    },
    "nesting_dolls": {
        "id": "nesting_dolls",
        "name": "Nesting Dolls",
        "keywords": [
            "matryoshka",
            "toy",
            "マトリョーシカ",
            "おもちゃ"
        ],
        "skins": [
            {
                "unified": "1fa86",
                "native": "🪆"
            }
        ],
        "version": 13
    },
    "spades": {
        "id": "spades",
        "name": "Spade Suit",
        "keywords": [
            "spades",
            "poker",
            "cards",
            "suits",
            "magic",
            "スペード",
            "ポーカー",
            "カード",
            "スーツ",
            "魔法"
        ],
        "skins": [
            {
                "unified": "2660-fe0f",
                "native": "♠️"
            }
        ],
        "version": 1
    },
    "hearts": {
        "id": "hearts",
        "name": "Heart Suit",
        "keywords": [
            "hearts",
            "poker",
            "cards",
            "magic",
            "suits",
            "心",
            "ポーカー",
            "カード",
            "魔法",
            "スーツ"
        ],
        "skins": [
            {
                "unified": "2665-fe0f",
                "native": "♥️"
            }
        ],
        "version": 1
    },
    "diamonds": {
        "id": "diamonds",
        "name": "Diamond Suit",
        "keywords": [
            "diamonds",
            "poker",
            "cards",
            "magic",
            "suits",
            "ダイヤモンド",
            "ポーカー",
            "カード",
            "魔法",
            "スーツ"
        ],
        "skins": [
            {
                "unified": "2666-fe0f",
                "native": "♦️"
            }
        ],
        "version": 1
    },
    "clubs": {
        "id": "clubs",
        "name": "Club Suit",
        "keywords": [
            "clubs",
            "poker",
            "cards",
            "magic",
            "suits",
            "クラブ",
            "ポーカー",
            "カード",
            "魔法",
            "スーツ"
        ],
        "skins": [
            {
                "unified": "2663-fe0f",
                "native": "♣️"
            }
        ],
        "version": 1
    },
    "chess_pawn": {
        "id": "chess_pawn",
        "name": "Chess Pawn",
        "keywords": [
            "expendable",
            "消耗品"
        ],
        "skins": [
            {
                "unified": "265f-fe0f",
                "native": "♟️"
            }
        ],
        "version": 11
    },
    "black_joker": {
        "id": "black_joker",
        "name": "Joker",
        "keywords": [
            "black",
            "poker",
            "cards",
            "game",
            "play",
            "magic",
            "黒",
            "ポーカー",
            "カード",
            "ゲーム",
            "遊ぶ",
            "魔法"
        ],
        "skins": [
            {
                "unified": "1f0cf",
                "native": "🃏"
            }
        ],
        "version": 1
    },
    "mahjong": {
        "id": "mahjong",
        "name": "Mahjong Red Dragon",
        "keywords": [
            "game",
            "play",
            "chinese",
            "kanji",
            "ゲーム",
            "遊ぶ",
            "中国語",
            "漢字"
        ],
        "skins": [
            {
                "unified": "1f004",
                "native": "🀄"
            }
        ],
        "version": 1
    },
    "flower_playing_cards": {
        "id": "flower_playing_cards",
        "name": "Flower Playing Cards",
        "keywords": [
            "game",
            "sunset",
            "red",
            "ゲーム",
            "日没",
            "赤"
        ],
        "skins": [
            {
                "unified": "1f3b4",
                "native": "🎴"
            }
        ],
        "version": 1
    },
    "performing_arts": {
        "id": "performing_arts",
        "name": "Performing Arts",
        "keywords": [
            "acting",
            "theater",
            "drama",
            "演技",
            "シアター",
            "ドラマ"
        ],
        "skins": [
            {
                "unified": "1f3ad",
                "native": "🎭"
            }
        ],
        "version": 1
    },
    "frame_with_picture": {
        "id": "frame_with_picture",
        "name": "Framed Picture",
        "keywords": [
            "frame",
            "with",
            "photography",
            "フレーム",
            "と",
            "写真"
        ],
        "skins": [
            {
                "unified": "1f5bc-fe0f",
                "native": "🖼️"
            }
        ],
        "version": 1
    },
    "art": {
        "id": "art",
        "name": "Artist Palette",
        "keywords": [
            "art",
            "design",
            "paint",
            "draw",
            "colors",
            "美術",
            "デザイン",
            "ペイント",
            "描く",
            "色"
        ],
        "skins": [
            {
                "unified": "1f3a8",
                "native": "🎨"
            }
        ],
        "version": 1
    },
    "thread": {
        "id": "thread",
        "name": "Thread",
        "keywords": [
            "needle",
            "sewing",
            "spool",
            "string",
            "針",
            "縫い",
            "スプール",
            "弦"
        ],
        "skins": [
            {
                "unified": "1f9f5",
                "native": "🧵"
            }
        ],
        "version": 11
    },
    "sewing_needle": {
        "id": "sewing_needle",
        "name": "Sewing Needle",
        "keywords": [
            "stitches",
            "縫い目"
        ],
        "skins": [
            {
                "unified": "1faa1",
                "native": "🪡"
            }
        ],
        "version": 13
    },
    "yarn": {
        "id": "yarn",
        "name": "Yarn",
        "keywords": [
            "ball",
            "crochet",
            "knit",
            "ボール",
            "かぎ針編み",
            "ニット"
        ],
        "skins": [
            {
                "unified": "1f9f6",
                "native": "🧶"
            }
        ],
        "version": 11
    },
    "knot": {
        "id": "knot",
        "name": "Knot",
        "keywords": [
            "rope",
            "scout",
            "ロープ",
            "スカウト"
        ],
        "skins": [
            {
                "unified": "1faa2",
                "native": "🪢"
            }
        ],
        "version": 13
    },
    "eyeglasses": {
        "id": "eyeglasses",
        "name": "Glasses",
        "keywords": [
            "eyeglasses",
            "fashion",
            "accessories",
            "eyesight",
            "nerdy",
            "dork",
            "geek",
            "眼鏡",
            "ファッション",
            "アクセサリー",
            "視力",
            "オタク",
            "ドーク",
            "オタク"
        ],
        "skins": [
            {
                "unified": "1f453",
                "native": "👓"
            }
        ],
        "version": 1
    },
    "dark_sunglasses": {
        "id": "dark_sunglasses",
        "name": "Sunglasses",
        "keywords": [
            "dark",
            "face",
            "cool",
            "accessories",
            "暗い",
            "顔",
            "いいね",
            "アクセサリー"
        ],
        "skins": [
            {
                "unified": "1f576-fe0f",
                "native": "🕶️"
            }
        ],
        "version": 1
    },
    "goggles": {
        "id": "goggles",
        "name": "Goggles",
        "keywords": [
            "eyes",
            "protection",
            "safety",
            "目",
            "保護",
            "安全性"
        ],
        "skins": [
            {
                "unified": "1f97d",
                "native": "🥽"
            }
        ],
        "version": 11
    },
    "lab_coat": {
        "id": "lab_coat",
        "name": "Lab Coat",
        "keywords": [
            "doctor",
            "experiment",
            "scientist",
            "chemist",
            "医者",
            "実験",
            "科学者",
            "化学者"
        ],
        "skins": [
            {
                "unified": "1f97c",
                "native": "🥼"
            }
        ],
        "version": 11
    },
    "safety_vest": {
        "id": "safety_vest",
        "name": "Safety Vest",
        "keywords": [
            "protection",
            "保護"
        ],
        "skins": [
            {
                "unified": "1f9ba",
                "native": "🦺"
            }
        ],
        "version": 12
    },
    "necktie": {
        "id": "necktie",
        "name": "Necktie",
        "keywords": [
            "shirt",
            "suitup",
            "formal",
            "fashion",
            "cloth",
            "business",
            "シャツ",
            "スーツアップ",
            "丁寧",
            "ファッション",
            "布",
            "仕事"
        ],
        "skins": [
            {
                "unified": "1f454",
                "native": "👔"
            }
        ],
        "version": 1
    },
    "shirt": {
        "id": "shirt",
        "name": "T-Shirt",
        "keywords": [
            "shirt",
            "tshirt",
            "t",
            "fashion",
            "cloth",
            "casual",
            "tee",
            "シャツ",
            "Tシャツ",
            "t",
            "ファッション",
            "布",
            "カジュアル",
            "ティー"
        ],
        "skins": [
            {
                "unified": "1f455",
                "native": "👕"
            }
        ],
        "version": 1
    },
    "jeans": {
        "id": "jeans",
        "name": "Jeans",
        "keywords": [
            "fashion",
            "shopping",
            "ファッション",
            "買い物"
        ],
        "skins": [
            {
                "unified": "1f456",
                "native": "👖"
            }
        ],
        "version": 1
    },
    "scarf": {
        "id": "scarf",
        "name": "Scarf",
        "keywords": [
            "neck",
            "winter",
            "clothes",
            "首",
            "冬",
            "服"
        ],
        "skins": [
            {
                "unified": "1f9e3",
                "native": "🧣"
            }
        ],
        "version": 5
    },
    "gloves": {
        "id": "gloves",
        "name": "Gloves",
        "keywords": [
            "hands",
            "winter",
            "clothes",
            "手",
            "冬",
            "服"
        ],
        "skins": [
            {
                "unified": "1f9e4",
                "native": "🧤"
            }
        ],
        "version": 5
    },
    "coat": {
        "id": "coat",
        "name": "Coat",
        "keywords": [
            "jacket",
            "ジャケット"
        ],
        "skins": [
            {
                "unified": "1f9e5",
                "native": "🧥"
            }
        ],
        "version": 5
    },
    "socks": {
        "id": "socks",
        "name": "Socks",
        "keywords": [
            "stockings",
            "clothes",
            "ストッキング",
            "服"
        ],
        "skins": [
            {
                "unified": "1f9e6",
                "native": "🧦"
            }
        ],
        "version": 5
    },
    "dress": {
        "id": "dress",
        "name": "Dress",
        "keywords": [
            "clothes",
            "fashion",
            "shopping",
            "服",
            "ファッション",
            "買い物"
        ],
        "skins": [
            {
                "unified": "1f457",
                "native": "👗"
            }
        ],
        "version": 1
    },
    "kimono": {
        "id": "kimono",
        "name": "Kimono",
        "keywords": [
            "dress",
            "fashion",
            "women",
            "female",
            "japanese",
            "ドレス",
            "ファッション",
            "女性",
            "女性",
            "日本"
        ],
        "skins": [
            {
                "unified": "1f458",
                "native": "👘"
            }
        ],
        "version": 1
    },
    "sari": {
        "id": "sari",
        "name": "Sari",
        "keywords": [
            "dress",
            "ドレス"
        ],
        "skins": [
            {
                "unified": "1f97b",
                "native": "🥻"
            }
        ],
        "version": 12
    },
    "one-piece_swimsuit": {
        "id": "one-piece_swimsuit",
        "name": "One-Piece Swimsuit",
        "keywords": [
            "one",
            "piece",
            "fashion",
            "一",
            "ピース",
            "ファッション"
        ],
        "skins": [
            {
                "unified": "1fa71",
                "native": "🩱"
            }
        ],
        "version": 12
    },
    "briefs": {
        "id": "briefs",
        "name": "Briefs",
        "keywords": [
            "clothing",
            "衣類"
        ],
        "skins": [
            {
                "unified": "1fa72",
                "native": "🩲"
            }
        ],
        "version": 12
    },
    "shorts": {
        "id": "shorts",
        "name": "Shorts",
        "keywords": [
            "clothing",
            "衣類"
        ],
        "skins": [
            {
                "unified": "1fa73",
                "native": "🩳"
            }
        ],
        "version": 12
    },
    "bikini": {
        "id": "bikini",
        "name": "Bikini",
        "keywords": [
            "swimming",
            "female",
            "woman",
            "girl",
            "fashion",
            "beach",
            "summer",
            "水泳",
            "女性",
            "女性",
            "女の子",
            "ファッション",
            "ビーチ",
            "夏"
        ],
        "skins": [
            {
                "unified": "1f459",
                "native": "👙"
            }
        ],
        "version": 1
    },
    "womans_clothes": {
        "id": "womans_clothes",
        "name": "Womans Clothes",
        "keywords": [
            "woman",
            "s",
            "fashion",
            "shopping",
            "bags",
            "female",
            "女性",
            "s",
            "ファッション",
            "買い物",
            "バッグ",
            "女性"
        ],
        "skins": [
            {
                "unified": "1f45a",
                "native": "👚"
            }
        ],
        "version": 1
    },
    "purse": {
        "id": "purse",
        "name": "Purse",
        "keywords": [
            "fashion",
            "accessories",
            "money",
            "sales",
            "shopping",
            "ファッション",
            "アクセサリー",
            "お金",
            "販売",
            "買い物"
        ],
        "skins": [
            {
                "unified": "1f45b",
                "native": "👛"
            }
        ],
        "version": 1
    },
    "handbag": {
        "id": "handbag",
        "name": "Handbag",
        "keywords": [
            "fashion",
            "accessory",
            "accessories",
            "shopping",
            "ファッション",
            "アクセサリー",
            "アクセサリー",
            "買い物"
        ],
        "skins": [
            {
                "unified": "1f45c",
                "native": "👜"
            }
        ],
        "version": 1
    },
    "pouch": {
        "id": "pouch",
        "name": "Pouch",
        "keywords": [
            "clutch",
            "bag",
            "accessories",
            "shopping",
            "クラッチ",
            "バッグ",
            "アクセサリー",
            "買い物"
        ],
        "skins": [
            {
                "unified": "1f45d",
                "native": "👝"
            }
        ],
        "version": 1
    },
    "shopping_bags": {
        "id": "shopping_bags",
        "name": "Shopping Bags",
        "keywords": [
            "mall",
            "buy",
            "purchase",
            "モール",
            "買う",
            "購入"
        ],
        "skins": [
            {
                "unified": "1f6cd-fe0f",
                "native": "🛍️"
            }
        ],
        "version": 1
    },
    "school_satchel": {
        "id": "school_satchel",
        "name": "Backpack",
        "keywords": [
            "school",
            "satchel",
            "student",
            "education",
            "bag",
            "学校",
            "かばん",
            "学生",
            "教育",
            "バッグ"
        ],
        "skins": [
            {
                "unified": "1f392",
                "native": "🎒"
            }
        ],
        "version": 1
    },
    "thong_sandal": {
        "id": "thong_sandal",
        "name": "Thong Sandal",
        "keywords": [
            "footwear",
            "summer",
            "履物",
            "夏"
        ],
        "skins": [
            {
                "unified": "1fa74",
                "native": "🩴"
            }
        ],
        "version": 13
    },
    "mans_shoe": {
        "id": "mans_shoe",
        "name": "Mans Shoe",
        "keywords": [
            "man",
            "s",
            "fashion",
            "male",
            "男",
            "s",
            "ファッション",
            "男"
        ],
        "skins": [
            {
                "unified": "1f45e",
                "native": "👞"
            }
        ],
        "version": 1
    },
    "athletic_shoe": {
        "id": "athletic_shoe",
        "name": "Running Shoe",
        "keywords": [
            "athletic",
            "shoes",
            "sports",
            "sneakers",
            "アスレチック",
            "靴",
            "スポーツ",
            "スニーカー"
        ],
        "skins": [
            {
                "unified": "1f45f",
                "native": "👟"
            }
        ],
        "version": 1
    },
    "hiking_boot": {
        "id": "hiking_boot",
        "name": "Hiking Boot",
        "keywords": [
            "backpacking",
            "camping",
            "バックパッキング",
            "キャンプ"
        ],
        "skins": [
            {
                "unified": "1f97e",
                "native": "🥾"
            }
        ],
        "version": 11
    },
    "womans_flat_shoe": {
        "id": "womans_flat_shoe",
        "name": "Flat Shoe",
        "keywords": [
            "womans",
            "ballet",
            "slip",
            "on",
            "slipper",
            "女性",
            "バレエ",
            "スリップ",
            "の上",
            "スリッパ"
        ],
        "skins": [
            {
                "unified": "1f97f",
                "native": "🥿"
            }
        ],
        "version": 11
    },
    "high_heel": {
        "id": "high_heel",
        "name": "High-Heeled Shoe",
        "keywords": [
            "high",
            "heel",
            "heeled",
            "fashion",
            "shoes",
            "female",
            "pumps",
            "stiletto",
            "高い",
            "ヒール",
            "かかと",
            "ファッション",
            "靴",
            "女性",
            "パンプス",
            "スティレット"
        ],
        "skins": [
            {
                "unified": "1f460",
                "native": "👠"
            }
        ],
        "version": 1
    },
    "sandal": {
        "id": "sandal",
        "name": "Womans Sandal",
        "keywords": [
            "woman",
            "s",
            "shoes",
            "fashion",
            "flip",
            "flops",
            "女性",
            "s",
            "靴",
            "ファッション",
            "フリップ",
            "フロップ"
        ],
        "skins": [
            {
                "unified": "1f461",
                "native": "👡"
            }
        ],
        "version": 1
    },
    "ballet_shoes": {
        "id": "ballet_shoes",
        "name": "Ballet Shoes",
        "keywords": [
            "dance",
            "ダンス"
        ],
        "skins": [
            {
                "unified": "1fa70",
                "native": "🩰"
            }
        ],
        "version": 12
    },
    "boot": {
        "id": "boot",
        "name": "Womans Boots",
        "keywords": [
            "boot",
            "woman",
            "s",
            "shoes",
            "fashion",
            "ブート",
            "女性",
            "s",
            "靴",
            "ファッション"
        ],
        "skins": [
            {
                "unified": "1f462",
                "native": "👢"
            }
        ],
        "version": 1
    },
    "crown": {
        "id": "crown",
        "name": "Crown",
        "keywords": [
            "king",
            "kod",
            "leader",
            "royalty",
            "lord",
            "王",
            "コード",
            "リーダー",
            "ロイヤリティ",
            "主"
        ],
        "skins": [
            {
                "unified": "1f451",
                "native": "👑"
            }
        ],
        "version": 1
    },
    "womans_hat": {
        "id": "womans_hat",
        "name": "Womans Hat",
        "keywords": [
            "woman",
            "s",
            "fashion",
            "accessories",
            "female",
            "lady",
            "spring",
            "女性",
            "s",
            "ファッション",
            "アクセサリー",
            "女性",
            "レディ",
            "春"
        ],
        "skins": [
            {
                "unified": "1f452",
                "native": "👒"
            }
        ],
        "version": 1
    },
    "tophat": {
        "id": "tophat",
        "name": "Top Hat",
        "keywords": [
            "tophat",
            "magic",
            "gentleman",
            "classy",
            "circus",
            "トップハット",
            "魔法",
            "紳士",
            "上品な",
            "サーカス"
        ],
        "skins": [
            {
                "unified": "1f3a9",
                "native": "🎩"
            }
        ],
        "version": 1
    },
    "mortar_board": {
        "id": "mortar_board",
        "name": "Graduation Cap",
        "keywords": [
            "mortar",
            "board",
            "school",
            "college",
            "degree",
            "university",
            "hat",
            "legal",
            "learn",
            "education",
            "モルタル",
            "ボード",
            "学校",
            "カレッジ",
            "程度",
            "大学",
            "帽子",
            "法的",
            "学び",
            "教育"
        ],
        "skins": [
            {
                "unified": "1f393",
                "native": "🎓"
            }
        ],
        "version": 1
    },
    "billed_cap": {
        "id": "billed_cap",
        "name": "Billed Cap",
        "keywords": [
            "baseball",
            "野球"
        ],
        "skins": [
            {
                "unified": "1f9e2",
                "native": "🧢"
            }
        ],
        "version": 5
    },
    "military_helmet": {
        "id": "military_helmet",
        "name": "Military Helmet",
        "keywords": [
            "army",
            "protection",
            "軍",
            "保護"
        ],
        "skins": [
            {
                "unified": "1fa96",
                "native": "🪖"
            }
        ],
        "version": 13
    },
    "helmet_with_white_cross": {
        "id": "helmet_with_white_cross",
        "name": "Rescue Worker’s Helmet",
        "keywords": [
            "with",
            "white",
            "cross",
            "worker",
            "s",
            "construction",
            "build",
            "と",
            "白",
            "クロス",
            "ワーカー",
            "s",
            "工事",
            "建てる"
        ],
        "skins": [
            {
                "unified": "26d1-fe0f",
                "native": "⛑️"
            }
        ],
        "version": 1
    },
    "prayer_beads": {
        "id": "prayer_beads",
        "name": "Prayer Beads",
        "keywords": [
            "dhikr",
            "religious",
            "ディクル",
            "宗教的"
        ],
        "skins": [
            {
                "unified": "1f4ff",
                "native": "📿"
            }
        ],
        "version": 1
    },
    "lipstick": {
        "id": "lipstick",
        "name": "Lipstick",
        "keywords": [
            "female",
            "girl",
            "fashion",
            "woman",
            "女性",
            "女の子",
            "ファッション",
            "女性"
        ],
        "skins": [
            {
                "unified": "1f484",
                "native": "💄"
            }
        ],
        "version": 1
    },
    "ring": {
        "id": "ring",
        "name": "Ring",
        "keywords": [
            "wedding",
            "propose",
            "marriage",
            "valentines",
            "diamond",
            "fashion",
            "jewelry",
            "gem",
            "engagement",
            "結婚式",
            "提案する",
            "結婚",
            "バレンタイン",
            "ダイヤモンド",
            "ファッション",
            "ジュエリー",
            "宝石",
            "婚約"
        ],
        "skins": [
            {
                "unified": "1f48d",
                "native": "💍"
            }
        ],
        "version": 1
    },
    "gem": {
        "id": "gem",
        "name": "Gem Stone",
        "keywords": [
            "blue",
            "ruby",
            "diamond",
            "jewelry",
            "青",
            "ルビー",
            "ダイヤモンド",
            "ジュエリー"
        ],
        "skins": [
            {
                "unified": "1f48e",
                "native": "💎"
            }
        ],
        "version": 1
    },
    "mute": {
        "id": "mute",
        "name": "Muted Speaker",
        "keywords": [
            "mute",
            "sound",
            "volume",
            "silence",
            "quiet",
            "無音",
            "音",
            "音量",
            "沈黙",
            "静かな"
        ],
        "skins": [
            {
                "unified": "1f507",
                "native": "🔇"
            }
        ],
        "version": 1
    },
    "speaker": {
        "id": "speaker",
        "name": "Speaker",
        "keywords": [
            "low",
            "volume",
            "sound",
            "silence",
            "broadcast",
            "低い",
            "音量",
            "音",
            "沈黙",
            "ブロードキャスト"
        ],
        "skins": [
            {
                "unified": "1f508",
                "native": "🔈"
            }
        ],
        "version": 1
    },
    "sound": {
        "id": "sound",
        "name": "Speaker Medium Volume",
        "keywords": [
            "sound",
            "broadcast",
            "音",
            "ブロードキャスト"
        ],
        "skins": [
            {
                "unified": "1f509",
                "native": "🔉"
            }
        ],
        "version": 1
    },
    "loud_sound": {
        "id": "loud_sound",
        "name": "Speaker High Volume",
        "keywords": [
            "loud",
            "sound",
            "noise",
            "noisy",
            "broadcast",
            "うるさい",
            "音",
            "ノイズ",
            "うるさい",
            "ブロードキャスト"
        ],
        "skins": [
            {
                "unified": "1f50a",
                "native": "🔊"
            }
        ],
        "version": 1
    },
    "loudspeaker": {
        "id": "loudspeaker",
        "name": "Loudspeaker",
        "keywords": [
            "volume",
            "sound",
            "音量",
            "音"
        ],
        "skins": [
            {
                "unified": "1f4e2",
                "native": "📢"
            }
        ],
        "version": 1
    },
    "mega": {
        "id": "mega",
        "name": "Megaphone",
        "keywords": [
            "mega",
            "sound",
            "speaker",
            "volume",
            "メガ",
            "音",
            "スピーカー",
            "音量"
        ],
        "skins": [
            {
                "unified": "1f4e3",
                "native": "📣"
            }
        ],
        "version": 1
    },
    "postal_horn": {
        "id": "postal_horn",
        "name": "Postal Horn",
        "keywords": [
            "instrument",
            "music",
            "楽器",
            "音楽"
        ],
        "skins": [
            {
                "unified": "1f4ef",
                "native": "📯"
            }
        ],
        "version": 1
    },
    "bell": {
        "id": "bell",
        "name": "Bell",
        "keywords": [
            "sound",
            "notification",
            "christmas",
            "xmas",
            "chime",
            "音",
            "通知",
            "クリスマス",
            "クリスマス",
            "チャイム"
        ],
        "skins": [
            {
                "unified": "1f514",
                "native": "🔔"
            }
        ],
        "version": 1
    },
    "no_bell": {
        "id": "no_bell",
        "name": "Bell with Slash",
        "keywords": [
            "no",
            "sound",
            "volume",
            "mute",
            "quiet",
            "silent",
            "いいえ",
            "音",
            "音量",
            "無音",
            "静かな",
            "静けさ"
        ],
        "skins": [
            {
                "unified": "1f515",
                "native": "🔕"
            }
        ],
        "version": 1
    },
    "musical_score": {
        "id": "musical_score",
        "name": "Musical Score",
        "keywords": [
            "treble",
            "clef",
            "compose",
            "高音",
            "クレフ",
            "作曲"
        ],
        "skins": [
            {
                "unified": "1f3bc",
                "native": "🎼"
            }
        ],
        "version": 1
    },
    "musical_note": {
        "id": "musical_note",
        "name": "Musical Note",
        "keywords": [
            "score",
            "tone",
            "sound",
            "スコア",
            "調子",
            "音"
        ],
        "skins": [
            {
                "unified": "1f3b5",
                "native": "🎵"
            }
        ],
        "version": 1
    },
    "notes": {
        "id": "notes",
        "name": "Musical Notes",
        "keywords": [
            "music",
            "score",
            "音楽",
            "スコア"
        ],
        "skins": [
            {
                "unified": "1f3b6",
                "native": "🎶"
            }
        ],
        "version": 1
    },
    "studio_microphone": {
        "id": "studio_microphone",
        "name": "Studio Microphone",
        "keywords": [
            "sing",
            "recording",
            "artist",
            "talkshow",
            "歌う",
            "録音",
            "アーティスト",
            "トークショー"
        ],
        "skins": [
            {
                "unified": "1f399-fe0f",
                "native": "🎙️"
            }
        ],
        "version": 1
    },
    "level_slider": {
        "id": "level_slider",
        "name": "Level Slider",
        "keywords": [
            "scale",
            "規模"
        ],
        "skins": [
            {
                "unified": "1f39a-fe0f",
                "native": "🎚️"
            }
        ],
        "version": 1
    },
    "control_knobs": {
        "id": "control_knobs",
        "name": "Control Knobs",
        "keywords": [
            "dial",
            "ダイヤル"
        ],
        "skins": [
            {
                "unified": "1f39b-fe0f",
                "native": "🎛️"
            }
        ],
        "version": 1
    },
    "microphone": {
        "id": "microphone",
        "name": "Microphone",
        "keywords": [
            "sound",
            "music",
            "PA",
            "sing",
            "talkshow",
            "音",
            "音楽",
            "パ",
            "歌う",
            "トークショー"
        ],
        "skins": [
            {
                "unified": "1f3a4",
                "native": "🎤"
            }
        ],
        "version": 1
    },
    "headphones": {
        "id": "headphones",
        "name": "Headphone",
        "keywords": [
            "headphones",
            "music",
            "score",
            "gadgets",
            "ヘッドホン",
            "音楽",
            "スコア",
            "ガジェット"
        ],
        "skins": [
            {
                "unified": "1f3a7",
                "native": "🎧"
            }
        ],
        "version": 1
    },
    "radio": {
        "id": "radio",
        "name": "Radio",
        "keywords": [
            "communication",
            "music",
            "podcast",
            "program",
            "コミュニケーション",
            "音楽",
            "ポッドキャスト",
            "プログラム"
        ],
        "skins": [
            {
                "unified": "1f4fb",
                "native": "📻"
            }
        ],
        "version": 1
    },
    "saxophone": {
        "id": "saxophone",
        "name": "Saxophone",
        "keywords": [
            "music",
            "instrument",
            "jazz",
            "blues",
            "音楽",
            "楽器",
            "ジャズ",
            "ブルース"
        ],
        "skins": [
            {
                "unified": "1f3b7",
                "native": "🎷"
            }
        ],
        "version": 1
    },
    "accordion": {
        "id": "accordion",
        "name": "Accordion",
        "keywords": [
            "music",
            "音楽"
        ],
        "skins": [
            {
                "unified": "1fa97",
                "native": "🪗"
            }
        ],
        "version": 13
    },
    "guitar": {
        "id": "guitar",
        "name": "Guitar",
        "keywords": [
            "music",
            "instrument",
            "音楽",
            "楽器"
        ],
        "skins": [
            {
                "unified": "1f3b8",
                "native": "🎸"
            }
        ],
        "version": 1
    },
    "musical_keyboard": {
        "id": "musical_keyboard",
        "name": "Musical Keyboard",
        "keywords": [
            "piano",
            "instrument",
            "compose",
            "ピアノ",
            "楽器",
            "作曲"
        ],
        "skins": [
            {
                "unified": "1f3b9",
                "native": "🎹"
            }
        ],
        "version": 1
    },
    "trumpet": {
        "id": "trumpet",
        "name": "Trumpet",
        "keywords": [
            "music",
            "brass",
            "音楽",
            "真鍮"
        ],
        "skins": [
            {
                "unified": "1f3ba",
                "native": "🎺"
            }
        ],
        "version": 1
    },
    "violin": {
        "id": "violin",
        "name": "Violin",
        "keywords": [
            "music",
            "instrument",
            "orchestra",
            "symphony",
            "音楽",
            "楽器",
            "オーケストラ",
            "交響曲"
        ],
        "skins": [
            {
                "unified": "1f3bb",
                "native": "🎻"
            }
        ],
        "version": 1
    },
    "banjo": {
        "id": "banjo",
        "name": "Banjo",
        "keywords": [
            "music",
            "instructment",
            "音楽",
            "命令"
        ],
        "skins": [
            {
                "unified": "1fa95",
                "native": "🪕"
            }
        ],
        "version": 12
    },
    "drum_with_drumsticks": {
        "id": "drum_with_drumsticks",
        "name": "Drum",
        "keywords": [
            "with",
            "drumsticks",
            "music",
            "instrument",
            "snare",
            "と",
            "ドラムスティック",
            "音楽",
            "楽器",
            "スネア"
        ],
        "skins": [
            {
                "unified": "1f941",
                "native": "🥁"
            }
        ],
        "version": 3
    },
    "long_drum": {
        "id": "long_drum",
        "name": "Long Drum",
        "keywords": [
            "music",
            "音楽"
        ],
        "skins": [
            {
                "unified": "1fa98",
                "native": "🪘"
            }
        ],
        "version": 13
    },
    "iphone": {
        "id": "iphone",
        "name": "Mobile Phone",
        "keywords": [
            "iphone",
            "technology",
            "apple",
            "gadgets",
            "dial",
            "アイフォン",
            "テクノロジー",
            "りんご",
            "ガジェット",
            "ダイヤル"
        ],
        "skins": [
            {
                "unified": "1f4f1",
                "native": "📱"
            }
        ],
        "version": 1
    },
    "calling": {
        "id": "calling",
        "name": "Mobile Phone with Arrow",
        "keywords": [
            "calling",
            "iphone",
            "incoming",
            "呼び出し",
            "アイフォン",
            "入ってくる"
        ],
        "skins": [
            {
                "unified": "1f4f2",
                "native": "📲"
            }
        ],
        "version": 1
    },
    "phone": {
        "id": "phone",
        "name": "Telephone",
        "keywords": [
            "phone",
            "technology",
            "communication",
            "dial",
            "電話",
            "テクノロジー",
            "コミュニケーション",
            "ダイヤル"
        ],
        "skins": [
            {
                "unified": "260e-fe0f",
                "native": "☎️"
            }
        ],
        "version": 1
    },
    "telephone_receiver": {
        "id": "telephone_receiver",
        "name": "Telephone Receiver",
        "keywords": [
            "technology",
            "communication",
            "dial",
            "テクノロジー",
            "コミュニケーション",
            "ダイヤル"
        ],
        "skins": [
            {
                "unified": "1f4de",
                "native": "📞"
            }
        ],
        "version": 1
    },
    "pager": {
        "id": "pager",
        "name": "Pager",
        "keywords": [
            "bbcall",
            "oldschool",
            "90s",
            "bbcall",
            "古い学校",
            "90年代"
        ],
        "skins": [
            {
                "unified": "1f4df",
                "native": "📟"
            }
        ],
        "version": 1
    },
    "fax": {
        "id": "fax",
        "name": "Fax Machine",
        "keywords": [
            "communication",
            "technology",
            "コミュニケーション",
            "テクノロジー"
        ],
        "skins": [
            {
                "unified": "1f4e0",
                "native": "📠"
            }
        ],
        "version": 1
    },
    "battery": {
        "id": "battery",
        "name": "Battery",
        "keywords": [
            "power",
            "energy",
            "sustain",
            "力",
            "エネルギー",
            "保つ"
        ],
        "skins": [
            {
                "unified": "1f50b",
                "native": "🔋"
            }
        ],
        "version": 1
    },
    "low_battery": {
        "id": "low_battery",
        "name": "Low Battery",
        "keywords": [
            "drained",
            "dead",
            "枯渇",
            "死"
        ],
        "skins": [
            {
                "unified": "1faab",
                "native": "🪫"
            }
        ],
        "version": 14
    },
    "electric_plug": {
        "id": "electric_plug",
        "name": "Electric Plug",
        "keywords": [
            "charger",
            "power",
            "充電器",
            "力"
        ],
        "skins": [
            {
                "unified": "1f50c",
                "native": "🔌"
            }
        ],
        "version": 1
    },
    "computer": {
        "id": "computer",
        "name": "Laptop",
        "keywords": [
            "computer",
            "technology",
            "screen",
            "display",
            "monitor",
            "コンピューター",
            "テクノロジー",
            "画面",
            "画面",
            "モニター"
        ],
        "skins": [
            {
                "unified": "1f4bb",
                "native": "💻"
            }
        ],
        "version": 1
    },
    "desktop_computer": {
        "id": "desktop_computer",
        "name": "Desktop Computer",
        "keywords": [
            "technology",
            "computing",
            "screen",
            "テクノロジー",
            "コンピューティング",
            "画面"
        ],
        "skins": [
            {
                "unified": "1f5a5-fe0f",
                "native": "🖥️"
            }
        ],
        "version": 1
    },
    "printer": {
        "id": "printer",
        "name": "Printer",
        "keywords": [
            "paper",
            "ink",
            "紙",
            "インク"
        ],
        "skins": [
            {
                "unified": "1f5a8-fe0f",
                "native": "🖨️"
            }
        ],
        "version": 1
    },
    "keyboard": {
        "id": "keyboard",
        "name": "Keyboard",
        "keywords": [
            "technology",
            "computer",
            "type",
            "input",
            "text",
            "テクノロジー",
            "コンピューター",
            "タイプ",
            "入力",
            "文章"
        ],
        "skins": [
            {
                "unified": "2328-fe0f",
                "native": "⌨️"
            }
        ],
        "version": 1
    },
    "three_button_mouse": {
        "id": "three_button_mouse",
        "name": "Computer Mouse",
        "keywords": [
            "three",
            "button",
            "click",
            "三つ",
            "ボタン",
            "クリック"
        ],
        "skins": [
            {
                "unified": "1f5b1-fe0f",
                "native": "🖱️"
            }
        ],
        "version": 1
    },
    "trackball": {
        "id": "trackball",
        "name": "Trackball",
        "keywords": [
            "technology",
            "trackpad",
            "テクノロジー",
            "トラックパッド"
        ],
        "skins": [
            {
                "unified": "1f5b2-fe0f",
                "native": "🖲️"
            }
        ],
        "version": 1
    },
    "minidisc": {
        "id": "minidisc",
        "name": "Minidisc",
        "keywords": [
            "computer",
            "disk",
            "technology",
            "record",
            "data",
            "90s",
            "コンピューター",
            "ディスク",
            "テクノロジー",
            "記録",
            "データ",
            "90年代"
        ],
        "skins": [
            {
                "unified": "1f4bd",
                "native": "💽"
            }
        ],
        "version": 1
    },
    "floppy_disk": {
        "id": "floppy_disk",
        "name": "Floppy Disk",
        "keywords": [
            "oldschool",
            "technology",
            "save",
            "90s",
            "80s",
            "古い学校",
            "テクノロジー",
            "保存",
            "90年代",
            "80年代"
        ],
        "skins": [
            {
                "unified": "1f4be",
                "native": "💾"
            }
        ],
        "version": 1
    },
    "cd": {
        "id": "cd",
        "name": "Optical Disc",
        "keywords": [
            "cd",
            "disk",
            "technology",
            "dvd",
            "90s",
            "CD",
            "ディスク",
            "テクノロジー",
            "DVD",
            "90年代"
        ],
        "skins": [
            {
                "unified": "1f4bf",
                "native": "💿"
            }
        ],
        "version": 1
    },
    "dvd": {
        "id": "dvd",
        "name": "Dvd",
        "keywords": [
            "cd",
            "disk",
            "disc",
            "CD",
            "ディスク",
            "ディスク"
        ],
        "skins": [
            {
                "unified": "1f4c0",
                "native": "📀"
            }
        ],
        "version": 1
    },
    "abacus": {
        "id": "abacus",
        "name": "Abacus",
        "keywords": [
            "calculation",
            "計算"
        ],
        "skins": [
            {
                "unified": "1f9ee",
                "native": "🧮"
            }
        ],
        "version": 11
    },
    "movie_camera": {
        "id": "movie_camera",
        "name": "Movie Camera",
        "keywords": [
            "film",
            "record",
            "映画",
            "記録"
        ],
        "skins": [
            {
                "unified": "1f3a5",
                "native": "🎥"
            }
        ],
        "version": 1
    },
    "film_frames": {
        "id": "film_frames",
        "name": "Film Frames",
        "keywords": [
            "movie",
            "映画"
        ],
        "skins": [
            {
                "unified": "1f39e-fe0f",
                "native": "🎞️"
            }
        ],
        "version": 1
    },
    "film_projector": {
        "id": "film_projector",
        "name": "Film Projector",
        "keywords": [
            "video",
            "tape",
            "record",
            "movie",
            "ビデオ",
            "テープ",
            "記録",
            "映画"
        ],
        "skins": [
            {
                "unified": "1f4fd-fe0f",
                "native": "📽️"
            }
        ],
        "version": 1
    },
    "clapper": {
        "id": "clapper",
        "name": "Clapper Board",
        "keywords": [
            "movie",
            "film",
            "record",
            "映画",
            "映画",
            "記録"
        ],
        "skins": [
            {
                "unified": "1f3ac",
                "native": "🎬"
            }
        ],
        "version": 1
    },
    "tv": {
        "id": "tv",
        "name": "Television",
        "keywords": [
            "tv",
            "technology",
            "program",
            "oldschool",
            "show",
            "テレビ",
            "テクノロジー",
            "プログラム",
            "古い学校",
            "見せる"
        ],
        "skins": [
            {
                "unified": "1f4fa",
                "native": "📺"
            }
        ],
        "version": 1
    },
    "camera": {
        "id": "camera",
        "name": "Camera",
        "keywords": [
            "gadgets",
            "photography",
            "ガジェット",
            "写真"
        ],
        "skins": [
            {
                "unified": "1f4f7",
                "native": "📷"
            }
        ],
        "version": 1
    },
    "camera_with_flash": {
        "id": "camera_with_flash",
        "name": "Camera with Flash",
        "keywords": [
            "photography",
            "gadgets",
            "写真",
            "ガジェット"
        ],
        "skins": [
            {
                "unified": "1f4f8",
                "native": "📸"
            }
        ],
        "version": 1
    },
    "video_camera": {
        "id": "video_camera",
        "name": "Video Camera",
        "keywords": [
            "film",
            "record",
            "映画",
            "記録"
        ],
        "skins": [
            {
                "unified": "1f4f9",
                "native": "📹"
            }
        ],
        "version": 1
    },
    "vhs": {
        "id": "vhs",
        "name": "Videocassette",
        "keywords": [
            "vhs",
            "record",
            "video",
            "oldschool",
            "90s",
            "80s",
            "vhs",
            "記録",
            "ビデオ",
            "古い学校",
            "90年代",
            "80年代"
        ],
        "skins": [
            {
                "unified": "1f4fc",
                "native": "📼"
            }
        ],
        "version": 1
    },
    "mag": {
        "id": "mag",
        "name": "Magnifying Glass Tilted Left",
        "keywords": [
            "mag",
            "search",
            "zoom",
            "find",
            "detective",
            "マグ",
            "検索",
            "ズーム",
            "探す",
            "探偵"
        ],
        "skins": [
            {
                "unified": "1f50d",
                "native": "🔍"
            }
        ],
        "version": 1
    },
    "mag_right": {
        "id": "mag_right",
        "name": "Magnifying Glass Tilted Right",
        "keywords": [
            "mag",
            "search",
            "zoom",
            "find",
            "detective",
            "マグ",
            "検索",
            "ズーム",
            "探す",
            "探偵"
        ],
        "skins": [
            {
                "unified": "1f50e",
                "native": "🔎"
            }
        ],
        "version": 1
    },
    "candle": {
        "id": "candle",
        "name": "Candle",
        "keywords": [
            "fire",
            "wax",
            "火",
            "ワックス"
        ],
        "skins": [
            {
                "unified": "1f56f-fe0f",
                "native": "🕯️"
            }
        ],
        "version": 1
    },
    "bulb": {
        "id": "bulb",
        "name": "Light Bulb",
        "keywords": [
            "electricity",
            "idea",
            "電気",
            "アイディア"
        ],
        "skins": [
            {
                "unified": "1f4a1",
                "native": "💡"
            }
        ],
        "version": 1
    },
    "flashlight": {
        "id": "flashlight",
        "name": "Flashlight",
        "keywords": [
            "dark",
            "camping",
            "sight",
            "night",
            "暗い",
            "キャンプ",
            "視力",
            "夜"
        ],
        "skins": [
            {
                "unified": "1f526",
                "native": "🔦"
            }
        ],
        "version": 1
    },
    "izakaya_lantern": {
        "id": "izakaya_lantern",
        "name": "Izakaya Lantern",
        "keywords": [
            "red",
            "paper",
            "light",
            "halloween",
            "spooky",
            "赤",
            "紙",
            "ライト",
            "ハロウィン",
            "不気味な"
        ],
        "skins": [
            {
                "unified": "1f3ee",
                "native": "🏮"
            }
        ],
        "version": 1
    },
    "diya_lamp": {
        "id": "diya_lamp",
        "name": "Diya Lamp",
        "keywords": [
            "lighting",
            "点灯"
        ],
        "skins": [
            {
                "unified": "1fa94",
                "native": "🪔"
            }
        ],
        "version": 12
    },
    "notebook_with_decorative_cover": {
        "id": "notebook_with_decorative_cover",
        "name": "Notebook with Decorative Cover",
        "keywords": [
            "classroom",
            "notes",
            "record",
            "paper",
            "study",
            "クラスルーム",
            "ノート",
            "記録",
            "紙",
            "勉強"
        ],
        "skins": [
            {
                "unified": "1f4d4",
                "native": "📔"
            }
        ],
        "version": 1
    },
    "closed_book": {
        "id": "closed_book",
        "name": "Closed Book",
        "keywords": [
            "read",
            "library",
            "knowledge",
            "textbook",
            "learn",
            "読む",
            "図書館",
            "知識",
            "教科書",
            "学び"
        ],
        "skins": [
            {
                "unified": "1f4d5",
                "native": "📕"
            }
        ],
        "version": 1
    },
    "book": {
        "id": "book",
        "name": "Open Book",
        "keywords": [
            "read",
            "library",
            "knowledge",
            "literature",
            "learn",
            "study",
            "読む",
            "図書館",
            "知識",
            "文学",
            "学び",
            "勉強"
        ],
        "skins": [
            {
                "unified": "1f4d6",
                "native": "📖"
            }
        ],
        "version": 1
    },
    "green_book": {
        "id": "green_book",
        "name": "Green Book",
        "keywords": [
            "read",
            "library",
            "knowledge",
            "study",
            "読む",
            "図書館",
            "知識",
            "勉強"
        ],
        "skins": [
            {
                "unified": "1f4d7",
                "native": "📗"
            }
        ],
        "version": 1
    },
    "blue_book": {
        "id": "blue_book",
        "name": "Blue Book",
        "keywords": [
            "read",
            "library",
            "knowledge",
            "learn",
            "study",
            "読む",
            "図書館",
            "知識",
            "学び",
            "勉強"
        ],
        "skins": [
            {
                "unified": "1f4d8",
                "native": "📘"
            }
        ],
        "version": 1
    },
    "orange_book": {
        "id": "orange_book",
        "name": "Orange Book",
        "keywords": [
            "read",
            "library",
            "knowledge",
            "textbook",
            "study",
            "読む",
            "図書館",
            "知識",
            "教科書",
            "勉強"
        ],
        "skins": [
            {
                "unified": "1f4d9",
                "native": "📙"
            }
        ],
        "version": 1
    },
    "books": {
        "id": "books",
        "name": "Books",
        "keywords": [
            "literature",
            "library",
            "study",
            "文学",
            "図書館",
            "勉強"
        ],
        "skins": [
            {
                "unified": "1f4da",
                "native": "📚"
            }
        ],
        "version": 1
    },
    "notebook": {
        "id": "notebook",
        "name": "Notebook",
        "keywords": [
            "stationery",
            "record",
            "notes",
            "paper",
            "study",
            "文房具",
            "記録",
            "ノート",
            "紙",
            "勉強"
        ],
        "skins": [
            {
                "unified": "1f4d3",
                "native": "📓"
            }
        ],
        "version": 1
    },
    "ledger": {
        "id": "ledger",
        "name": "Ledger",
        "keywords": [
            "notes",
            "paper",
            "ノート",
            "紙"
        ],
        "skins": [
            {
                "unified": "1f4d2",
                "native": "📒"
            }
        ],
        "version": 1
    },
    "page_with_curl": {
        "id": "page_with_curl",
        "name": "Page with Curl",
        "keywords": [
            "documents",
            "office",
            "paper",
            "ドキュメント",
            "オフィス",
            "紙"
        ],
        "skins": [
            {
                "unified": "1f4c3",
                "native": "📃"
            }
        ],
        "version": 1
    },
    "scroll": {
        "id": "scroll",
        "name": "Scroll",
        "keywords": [
            "documents",
            "ancient",
            "history",
            "paper",
            "ドキュメント",
            "古代",
            "歴史",
            "紙"
        ],
        "skins": [
            {
                "unified": "1f4dc",
                "native": "📜"
            }
        ],
        "version": 1
    },
    "page_facing_up": {
        "id": "page_facing_up",
        "name": "Page Facing Up",
        "keywords": [
            "documents",
            "office",
            "paper",
            "information",
            "ドキュメント",
            "オフィス",
            "紙",
            "情報"
        ],
        "skins": [
            {
                "unified": "1f4c4",
                "native": "📄"
            }
        ],
        "version": 1
    },
    "newspaper": {
        "id": "newspaper",
        "name": "Newspaper",
        "keywords": [
            "press",
            "headline",
            "プレス",
            "見出し"
        ],
        "skins": [
            {
                "unified": "1f4f0",
                "native": "📰"
            }
        ],
        "version": 1
    },
    "rolled_up_newspaper": {
        "id": "rolled_up_newspaper",
        "name": "Rolled-Up Newspaper",
        "keywords": [
            "rolled",
            "up",
            "press",
            "headline",
            "巻いた",
            "上",
            "プレス",
            "見出し"
        ],
        "skins": [
            {
                "unified": "1f5de-fe0f",
                "native": "🗞️"
            }
        ],
        "version": 1
    },
    "bookmark_tabs": {
        "id": "bookmark_tabs",
        "name": "Bookmark Tabs",
        "keywords": [
            "favorite",
            "save",
            "order",
            "tidy",
            "お気に入り",
            "保存",
            "注文",
            "几帳面"
        ],
        "skins": [
            {
                "unified": "1f4d1",
                "native": "📑"
            }
        ],
        "version": 1
    },
    "bookmark": {
        "id": "bookmark",
        "name": "Bookmark",
        "keywords": [
            "favorite",
            "label",
            "save",
            "お気に入り",
            "ラベル",
            "保存"
        ],
        "skins": [
            {
                "unified": "1f516",
                "native": "🔖"
            }
        ],
        "version": 1
    },
    "label": {
        "id": "label",
        "name": "Label",
        "keywords": [
            "sale",
            "tag",
            "セール",
            "鬼ごっこ"
        ],
        "skins": [
            {
                "unified": "1f3f7-fe0f",
                "native": "🏷️"
            }
        ],
        "version": 1
    },
    "moneybag": {
        "id": "moneybag",
        "name": "Money Bag",
        "keywords": [
            "moneybag",
            "dollar",
            "payment",
            "coins",
            "sale",
            "お財布",
            "ドル",
            "支払い",
            "コイン",
            "セール"
        ],
        "skins": [
            {
                "unified": "1f4b0",
                "native": "💰"
            }
        ],
        "version": 1
    },
    "coin": {
        "id": "coin",
        "name": "Coin",
        "keywords": [
            "money",
            "currency",
            "お金",
            "通貨"
        ],
        "skins": [
            {
                "unified": "1fa99",
                "native": "🪙"
            }
        ],
        "version": 13
    },
    "yen": {
        "id": "yen",
        "name": "Yen Banknote",
        "keywords": [
            "money",
            "sales",
            "japanese",
            "dollar",
            "currency",
            "お金",
            "販売",
            "日本",
            "ドル",
            "通貨"
        ],
        "skins": [
            {
                "unified": "1f4b4",
                "native": "💴"
            }
        ],
        "version": 1
    },
    "dollar": {
        "id": "dollar",
        "name": "Dollar Banknote",
        "keywords": [
            "money",
            "sales",
            "bill",
            "currency",
            "お金",
            "販売",
            "明細書",
            "通貨"
        ],
        "skins": [
            {
                "unified": "1f4b5",
                "native": "💵"
            }
        ],
        "version": 1
    },
    "euro": {
        "id": "euro",
        "name": "Euro Banknote",
        "keywords": [
            "money",
            "sales",
            "dollar",
            "currency",
            "お金",
            "販売",
            "ドル",
            "通貨"
        ],
        "skins": [
            {
                "unified": "1f4b6",
                "native": "💶"
            }
        ],
        "version": 1
    },
    "pound": {
        "id": "pound",
        "name": "Pound Banknote",
        "keywords": [
            "british",
            "sterling",
            "money",
            "sales",
            "bills",
            "uk",
            "england",
            "currency",
            "イギリス人",
            "スターリング",
            "お金",
            "販売",
            "手形",
            "イギリス",
            "イングランド",
            "通貨"
        ],
        "skins": [
            {
                "unified": "1f4b7",
                "native": "💷"
            }
        ],
        "version": 1
    },
    "money_with_wings": {
        "id": "money_with_wings",
        "name": "Money with Wings",
        "keywords": [
            "dollar",
            "bills",
            "payment",
            "sale",
            "ドル",
            "手形",
            "支払い",
            "セール"
        ],
        "skins": [
            {
                "unified": "1f4b8",
                "native": "💸"
            }
        ],
        "version": 1
    },
    "credit_card": {
        "id": "credit_card",
        "name": "Credit Card",
        "keywords": [
            "money",
            "sales",
            "dollar",
            "bill",
            "payment",
            "shopping",
            "お金",
            "販売",
            "ドル",
            "明細書",
            "支払い",
            "買い物"
        ],
        "skins": [
            {
                "unified": "1f4b3",
                "native": "💳"
            }
        ],
        "version": 1
    },
    "receipt": {
        "id": "receipt",
        "name": "Receipt",
        "keywords": [
            "accounting",
            "expenses",
            "会計",
            "経費"
        ],
        "skins": [
            {
                "unified": "1f9fe",
                "native": "🧾"
            }
        ],
        "version": 11
    },
    "chart": {
        "id": "chart",
        "name": "Chart Increasing with Yen",
        "keywords": [
            "green",
            "square",
            "graph",
            "presentation",
            "stats",
            "緑",
            "四角",
            "グラフ",
            "プレゼンテーション",
            "統計"
        ],
        "skins": [
            {
                "unified": "1f4b9",
                "native": "💹"
            }
        ],
        "version": 1
    },
    "email": {
        "id": "email",
        "name": "Envelope",
        "keywords": [
            "email",
            "letter",
            "postal",
            "inbox",
            "communication",
            "Eメール",
            "手紙",
            "郵便",
            "受信箱",
            "コミュニケーション"
        ],
        "skins": [
            {
                "unified": "2709-fe0f",
                "native": "✉️"
            }
        ],
        "version": 1
    },
    "e-mail": {
        "id": "e-mail",
        "name": "E-Mail",
        "keywords": [
            "e",
            "mail",
            "communication",
            "inbox",
            "e",
            "郵便",
            "コミュニケーション",
            "受信箱"
        ],
        "skins": [
            {
                "unified": "1f4e7",
                "native": "📧"
            }
        ],
        "version": 1
    },
    "incoming_envelope": {
        "id": "incoming_envelope",
        "name": "Incoming Envelope",
        "keywords": [
            "email",
            "inbox",
            "Eメール",
            "受信箱"
        ],
        "skins": [
            {
                "unified": "1f4e8",
                "native": "📨"
            }
        ],
        "version": 1
    },
    "envelope_with_arrow": {
        "id": "envelope_with_arrow",
        "name": "Envelope with Arrow",
        "keywords": [
            "email",
            "communication",
            "Eメール",
            "コミュニケーション"
        ],
        "skins": [
            {
                "unified": "1f4e9",
                "native": "📩"
            }
        ],
        "version": 1
    },
    "outbox_tray": {
        "id": "outbox_tray",
        "name": "Outbox Tray",
        "keywords": [
            "inbox",
            "email",
            "受信箱",
            "Eメール"
        ],
        "skins": [
            {
                "unified": "1f4e4",
                "native": "📤"
            }
        ],
        "version": 1
    },
    "inbox_tray": {
        "id": "inbox_tray",
        "name": "Inbox Tray",
        "keywords": [
            "email",
            "documents",
            "Eメール",
            "書類"
        ],
        "skins": [
            {
                "unified": "1f4e5",
                "native": "📥"
            }
        ],
        "version": 1
    },
    "package": {
        "id": "package",
        "name": "Package",
        "keywords": [
            "mail",
            "gift",
            "cardboard",
            "box",
            "moving",
            "郵便",
            "贈り物",
            "段ボール",
            "箱",
            "動く"
        ],
        "skins": [
            {
                "unified": "1f4e6",
                "native": "📦"
            }
        ],
        "version": 1
    },
    "mailbox": {
        "id": "mailbox",
        "name": "Closed Mailbox with Raised Flag",
        "keywords": [
            "email",
            "inbox",
            "communication",
            "Eメール",
            "受信箱",
            "コミュニケーション"
        ],
        "skins": [
            {
                "unified": "1f4eb",
                "native": "📫"
            }
        ],
        "version": 1
    },
    "mailbox_closed": {
        "id": "mailbox_closed",
        "name": "Closed Mailbox with Lowered Flag",
        "keywords": [
            "email",
            "communication",
            "inbox",
            "Eメール",
            "コミュニケーション",
            "受信箱"
        ],
        "skins": [
            {
                "unified": "1f4ea",
                "native": "📪"
            }
        ],
        "version": 1
    },
    "mailbox_with_mail": {
        "id": "mailbox_with_mail",
        "name": "Open Mailbox with Raised Flag",
        "keywords": [
            "mail",
            "email",
            "inbox",
            "communication",
            "郵便",
            "Eメール",
            "受信箱",
            "コミュニケーション"
        ],
        "skins": [
            {
                "unified": "1f4ec",
                "native": "📬"
            }
        ],
        "version": 1
    },
    "mailbox_with_no_mail": {
        "id": "mailbox_with_no_mail",
        "name": "Open Mailbox with Lowered Flag",
        "keywords": [
            "no",
            "mail",
            "email",
            "inbox",
            "いいえ",
            "郵便",
            "Eメール",
            "受信箱"
        ],
        "skins": [
            {
                "unified": "1f4ed",
                "native": "📭"
            }
        ],
        "version": 1
    },
    "postbox": {
        "id": "postbox",
        "name": "Postbox",
        "keywords": [
            "email",
            "letter",
            "envelope",
            "Eメール",
            "手紙",
            "封筒"
        ],
        "skins": [
            {
                "unified": "1f4ee",
                "native": "📮"
            }
        ],
        "version": 1
    },
    "ballot_box_with_ballot": {
        "id": "ballot_box_with_ballot",
        "name": "Ballot Box with Ballot",
        "keywords": [
            "election",
            "vote",
            "選挙",
            "投票"
        ],
        "skins": [
            {
                "unified": "1f5f3-fe0f",
                "native": "🗳️"
            }
        ],
        "version": 1
    },
    "pencil2": {
        "id": "pencil2",
        "name": "Pencil",
        "keywords": [
            "pencil2",
            "stationery",
            "write",
            "paper",
            "writing",
            "school",
            "study",
            "pencil2",
            "文房具",
            "書く",
            "紙",
            "書き込み",
            "学校",
            "勉強"
        ],
        "skins": [
            {
                "unified": "270f-fe0f",
                "native": "✏️"
            }
        ],
        "version": 1
    },
    "black_nib": {
        "id": "black_nib",
        "name": "Black Nib",
        "keywords": [
            "pen",
            "stationery",
            "writing",
            "write",
            "ペン",
            "文房具",
            "書き込み",
            "書く"
        ],
        "skins": [
            {
                "unified": "2712-fe0f",
                "native": "✒️"
            }
        ],
        "version": 1
    },
    "lower_left_fountain_pen": {
        "id": "lower_left_fountain_pen",
        "name": "Fountain Pen",
        "keywords": [
            "lower",
            "left",
            "stationery",
            "writing",
            "write",
            "低い",
            "左",
            "文房具",
            "書き込み",
            "書く"
        ],
        "skins": [
            {
                "unified": "1f58b-fe0f",
                "native": "🖋️"
            }
        ],
        "version": 1
    },
    "lower_left_ballpoint_pen": {
        "id": "lower_left_ballpoint_pen",
        "name": "Pen",
        "keywords": [
            "lower",
            "left",
            "ballpoint",
            "stationery",
            "writing",
            "write",
            "低い",
            "左",
            "ボールペン",
            "文房具",
            "書き込み",
            "書く"
        ],
        "skins": [
            {
                "unified": "1f58a-fe0f",
                "native": "🖊️"
            }
        ],
        "version": 1
    },
    "lower_left_paintbrush": {
        "id": "lower_left_paintbrush",
        "name": "Paintbrush",
        "keywords": [
            "lower",
            "left",
            "drawing",
            "creativity",
            "art",
            "低い",
            "左",
            "描く",
            "創造性",
            "美術"
        ],
        "skins": [
            {
                "unified": "1f58c-fe0f",
                "native": "🖌️"
            }
        ],
        "version": 1
    },
    "lower_left_crayon": {
        "id": "lower_left_crayon",
        "name": "Crayon",
        "keywords": [
            "lower",
            "left",
            "drawing",
            "creativity",
            "低い",
            "左",
            "描く",
            "創造性"
        ],
        "skins": [
            {
                "unified": "1f58d-fe0f",
                "native": "🖍️"
            }
        ],
        "version": 1
    },
    "memo": {
        "id": "memo",
        "name": "Memo",
        "keywords": [
            "pencil",
            "write",
            "documents",
            "stationery",
            "paper",
            "writing",
            "legal",
            "exam",
            "quiz",
            "test",
            "study",
            "compose",
            "鉛筆",
            "書く",
            "ドキュメント",
            "文房具",
            "紙",
            "書き込み",
            "法的",
            "テスト",
            "クイズ",
            "テスト",
            "勉強",
            "作曲"
        ],
        "skins": [
            {
                "unified": "1f4dd",
                "native": "📝"
            }
        ],
        "version": 1
    },
    "briefcase": {
        "id": "briefcase",
        "name": "Briefcase",
        "keywords": [
            "business",
            "documents",
            "work",
            "law",
            "legal",
            "job",
            "career",
            "仕事",
            "ドキュメント",
            "仕事",
            "法",
            "法的",
            "仕事",
            "キャリア"
        ],
        "skins": [
            {
                "unified": "1f4bc",
                "native": "💼"
            }
        ],
        "version": 1
    },
    "file_folder": {
        "id": "file_folder",
        "name": "File Folder",
        "keywords": [
            "documents",
            "business",
            "office",
            "ドキュメント",
            "仕事",
            "オフィス"
        ],
        "skins": [
            {
                "unified": "1f4c1",
                "native": "📁"
            }
        ],
        "version": 1
    },
    "open_file_folder": {
        "id": "open_file_folder",
        "name": "Open File Folder",
        "keywords": [
            "documents",
            "load",
            "ドキュメント",
            "ロード"
        ],
        "skins": [
            {
                "unified": "1f4c2",
                "native": "📂"
            }
        ],
        "version": 1
    },
    "card_index_dividers": {
        "id": "card_index_dividers",
        "name": "Card Index Dividers",
        "keywords": [
            "organizing",
            "business",
            "stationery",
            "整理",
            "仕事",
            "文房具"
        ],
        "skins": [
            {
                "unified": "1f5c2-fe0f",
                "native": "🗂️"
            }
        ],
        "version": 1
    },
    "date": {
        "id": "date",
        "name": "Calendar",
        "keywords": [
            "date",
            "schedule",
            "日にち",
            "スケジュール"
        ],
        "skins": [
            {
                "unified": "1f4c5",
                "native": "📅"
            }
        ],
        "version": 1
    },
    "calendar": {
        "id": "calendar",
        "name": "Tear-off Calendar",
        "keywords": [
            "tear",
            "off",
            "schedule",
            "date",
            "planning",
            "破れ目",
            "オフ",
            "スケジュール",
            "日にち",
            "企画"
        ],
        "skins": [
            {
                "unified": "1f4c6",
                "native": "📆"
            }
        ],
        "version": 1
    },
    "spiral_note_pad": {
        "id": "spiral_note_pad",
        "name": "Spiral Notepad",
        "keywords": [
            "note",
            "pad",
            "memo",
            "stationery",
            "ノート",
            "パッド",
            "メモ",
            "文房具"
        ],
        "skins": [
            {
                "unified": "1f5d2-fe0f",
                "native": "🗒️"
            }
        ],
        "version": 1
    },
    "spiral_calendar_pad": {
        "id": "spiral_calendar_pad",
        "name": "Spiral Calendar",
        "keywords": [
            "pad",
            "date",
            "schedule",
            "planning",
            "パッド",
            "日にち",
            "スケジュール",
            "企画"
        ],
        "skins": [
            {
                "unified": "1f5d3-fe0f",
                "native": "🗓️"
            }
        ],
        "version": 1
    },
    "card_index": {
        "id": "card_index",
        "name": "Card Index",
        "keywords": [
            "business",
            "stationery",
            "仕事",
            "文房具"
        ],
        "skins": [
            {
                "unified": "1f4c7",
                "native": "📇"
            }
        ],
        "version": 1
    },
    "chart_with_upwards_trend": {
        "id": "chart_with_upwards_trend",
        "name": "Chart Increasing",
        "keywords": [
            "with",
            "upwards",
            "trend",
            "graph",
            "presentation",
            "stats",
            "recovery",
            "business",
            "economics",
            "money",
            "sales",
            "good",
            "success",
            "と",
            "上向き",
            "傾向",
            "グラフ",
            "プレゼンテーション",
            "統計",
            "回復",
            "仕事",
            "経済",
            "お金",
            "販売",
            "良い",
            "成功"
        ],
        "skins": [
            {
                "unified": "1f4c8",
                "native": "📈"
            }
        ],
        "version": 1
    },
    "chart_with_downwards_trend": {
        "id": "chart_with_downwards_trend",
        "name": "Chart Decreasing",
        "keywords": [
            "with",
            "downwards",
            "trend",
            "graph",
            "presentation",
            "stats",
            "recession",
            "business",
            "economics",
            "money",
            "sales",
            "bad",
            "failure",
            "と",
            "下向き",
            "傾向",
            "グラフ",
            "プレゼンテーション",
            "統計",
            "不況",
            "仕事",
            "経済",
            "お金",
            "販売",
            "悪い",
            "失敗"
        ],
        "skins": [
            {
                "unified": "1f4c9",
                "native": "📉"
            }
        ],
        "version": 1
    },
    "bar_chart": {
        "id": "bar_chart",
        "name": "Bar Chart",
        "keywords": [
            "graph",
            "presentation",
            "stats",
            "グラフ",
            "プレゼンテーション",
            "統計"
        ],
        "skins": [
            {
                "unified": "1f4ca",
                "native": "📊"
            }
        ],
        "version": 1
    },
    "clipboard": {
        "id": "clipboard",
        "name": "Clipboard",
        "keywords": [
            "stationery",
            "documents",
            "文房具",
            "書類"
        ],
        "skins": [
            {
                "unified": "1f4cb",
                "native": "📋"
            }
        ],
        "version": 1
    },
    "pushpin": {
        "id": "pushpin",
        "name": "Pushpin",
        "keywords": [
            "stationery",
            "mark",
            "here",
            "文房具",
            "マーク",
            "ここ"
        ],
        "skins": [
            {
                "unified": "1f4cc",
                "native": "📌"
            }
        ],
        "version": 1
    },
    "round_pushpin": {
        "id": "round_pushpin",
        "name": "Round Pushpin",
        "keywords": [
            "stationery",
            "location",
            "map",
            "here",
            "文房具",
            "位置",
            "地図",
            "ここ"
        ],
        "skins": [
            {
                "unified": "1f4cd",
                "native": "📍"
            }
        ],
        "version": 1
    },
    "paperclip": {
        "id": "paperclip",
        "name": "Paperclip",
        "keywords": [
            "documents",
            "stationery",
            "ドキュメント",
            "文房具"
        ],
        "skins": [
            {
                "unified": "1f4ce",
                "native": "📎"
            }
        ],
        "version": 1
    },
    "linked_paperclips": {
        "id": "linked_paperclips",
        "name": "Linked Paperclips",
        "keywords": [
            "documents",
            "stationery",
            "ドキュメント",
            "文房具"
        ],
        "skins": [
            {
                "unified": "1f587-fe0f",
                "native": "🖇️"
            }
        ],
        "version": 1
    },
    "straight_ruler": {
        "id": "straight_ruler",
        "name": "Straight Ruler",
        "keywords": [
            "stationery",
            "calculate",
            "length",
            "math",
            "school",
            "drawing",
            "architect",
            "sketch",
            "文房具",
            "計算する",
            "長さ",
            "算数",
            "学校",
            "描く",
            "建築家",
            "スケッチ"
        ],
        "skins": [
            {
                "unified": "1f4cf",
                "native": "📏"
            }
        ],
        "version": 1
    },
    "triangular_ruler": {
        "id": "triangular_ruler",
        "name": "Triangular Ruler",
        "keywords": [
            "stationery",
            "math",
            "architect",
            "sketch",
            "文房具",
            "算数",
            "建築家",
            "スケッチ"
        ],
        "skins": [
            {
                "unified": "1f4d0",
                "native": "📐"
            }
        ],
        "version": 1
    },
    "scissors": {
        "id": "scissors",
        "name": "Scissors",
        "keywords": [
            "stationery",
            "cut",
            "文房具",
            "切る"
        ],
        "skins": [
            {
                "unified": "2702-fe0f",
                "native": "✂️"
            }
        ],
        "version": 1
    },
    "card_file_box": {
        "id": "card_file_box",
        "name": "Card File Box",
        "keywords": [
            "business",
            "stationery",
            "仕事",
            "文房具"
        ],
        "skins": [
            {
                "unified": "1f5c3-fe0f",
                "native": "🗃️"
            }
        ],
        "version": 1
    },
    "file_cabinet": {
        "id": "file_cabinet",
        "name": "File Cabinet",
        "keywords": [
            "filing",
            "organizing",
            "ファイリング",
            "整理"
        ],
        "skins": [
            {
                "unified": "1f5c4-fe0f",
                "native": "🗄️"
            }
        ],
        "version": 1
    },
    "wastebasket": {
        "id": "wastebasket",
        "name": "Wastebasket",
        "keywords": [
            "bin",
            "trash",
            "rubbish",
            "garbage",
            "toss",
            "置き場",
            "ごみ",
            "ごみ",
            "ごみ",
            "投げ捨てる"
        ],
        "skins": [
            {
                "unified": "1f5d1-fe0f",
                "native": "🗑️"
            }
        ],
        "version": 1
    },
    "lock": {
        "id": "lock",
        "name": "Lock",
        "keywords": [
            "locked",
            "security",
            "password",
            "padlock",
            "ロックされた",
            "安全",
            "パスワード",
            "南京錠"
        ],
        "skins": [
            {
                "unified": "1f512",
                "native": "🔒"
            }
        ],
        "version": 1
    },
    "unlock": {
        "id": "unlock",
        "name": "Unlocked",
        "keywords": [
            "unlock",
            "privacy",
            "security",
            "ロック解除",
            "プライバシー",
            "安全"
        ],
        "skins": [
            {
                "unified": "1f513",
                "native": "🔓"
            }
        ],
        "version": 1
    },
    "lock_with_ink_pen": {
        "id": "lock_with_ink_pen",
        "name": "Locked with Pen",
        "keywords": [
            "lock",
            "ink",
            "security",
            "secret",
            "ロック",
            "インク",
            "安全",
            "ひみつ"
        ],
        "skins": [
            {
                "unified": "1f50f",
                "native": "🔏"
            }
        ],
        "version": 1
    },
    "closed_lock_with_key": {
        "id": "closed_lock_with_key",
        "name": "Locked with Key",
        "keywords": [
            "closed",
            "lock",
            "security",
            "privacy",
            "閉まっている",
            "ロック",
            "安全",
            "プライバシー"
        ],
        "skins": [
            {
                "unified": "1f510",
                "native": "🔐"
            }
        ],
        "version": 1
    },
    "key": {
        "id": "key",
        "name": "Key",
        "keywords": [
            "lock",
            "door",
            "password",
            "ロック",
            "ドア",
            "パスワード"
        ],
        "skins": [
            {
                "unified": "1f511",
                "native": "🔑"
            }
        ],
        "version": 1
    },
    "old_key": {
        "id": "old_key",
        "name": "Old Key",
        "keywords": [
            "lock",
            "door",
            "password",
            "ロック",
            "ドア",
            "パスワード"
        ],
        "skins": [
            {
                "unified": "1f5dd-fe0f",
                "native": "🗝️"
            }
        ],
        "version": 1
    },
    "hammer": {
        "id": "hammer",
        "name": "Hammer",
        "keywords": [
            "tools",
            "build",
            "create",
            "ツール",
            "建てる",
            "作成"
        ],
        "skins": [
            {
                "unified": "1f528",
                "native": "🔨"
            }
        ],
        "version": 1
    },
    "axe": {
        "id": "axe",
        "name": "Axe",
        "keywords": [
            "tool",
            "chop",
            "cut",
            "道具",
            "チョップ",
            "切る"
        ],
        "skins": [
            {
                "unified": "1fa93",
                "native": "🪓"
            }
        ],
        "version": 12
    },
    "pick": {
        "id": "pick",
        "name": "Pick",
        "keywords": [
            "tools",
            "dig",
            "ツール",
            "掘る"
        ],
        "skins": [
            {
                "unified": "26cf-fe0f",
                "native": "⛏️"
            }
        ],
        "version": 1
    },
    "hammer_and_pick": {
        "id": "hammer_and_pick",
        "name": "Hammer and Pick",
        "keywords": [
            "tools",
            "build",
            "create",
            "ツール",
            "建てる",
            "作成"
        ],
        "skins": [
            {
                "unified": "2692-fe0f",
                "native": "⚒️"
            }
        ],
        "version": 1
    },
    "hammer_and_wrench": {
        "id": "hammer_and_wrench",
        "name": "Hammer and Wrench",
        "keywords": [
            "tools",
            "build",
            "create",
            "ツール",
            "建てる",
            "作成"
        ],
        "skins": [
            {
                "unified": "1f6e0-fe0f",
                "native": "🛠️"
            }
        ],
        "version": 1
    },
    "dagger_knife": {
        "id": "dagger_knife",
        "name": "Dagger",
        "keywords": [
            "knife",
            "weapon",
            "ナイフ",
            "武器"
        ],
        "skins": [
            {
                "unified": "1f5e1-fe0f",
                "native": "🗡️"
            }
        ],
        "version": 1
    },
    "crossed_swords": {
        "id": "crossed_swords",
        "name": "Crossed Swords",
        "keywords": [
            "weapon",
            "武器"
        ],
        "skins": [
            {
                "unified": "2694-fe0f",
                "native": "⚔️"
            }
        ],
        "version": 1
    },
    "gun": {
        "id": "gun",
        "name": "Pistol",
        "keywords": [
            "gun",
            "violence",
            "weapon",
            "revolver",
            "銃",
            "暴力",
            "武器",
            "リボルバー"
        ],
        "skins": [
            {
                "unified": "1f52b",
                "native": "🔫"
            }
        ],
        "version": 1
    },
    "boomerang": {
        "id": "boomerang",
        "name": "Boomerang",
        "keywords": [
            "weapon",
            "武器"
        ],
        "skins": [
            {
                "unified": "1fa83",
                "native": "🪃"
            }
        ],
        "version": 13
    },
    "bow_and_arrow": {
        "id": "bow_and_arrow",
        "name": "Bow and Arrow",
        "keywords": [
            "sports",
            "スポーツ"
        ],
        "skins": [
            {
                "unified": "1f3f9",
                "native": "🏹"
            }
        ],
        "version": 1
    },
    "shield": {
        "id": "shield",
        "name": "Shield",
        "keywords": [
            "protection",
            "security",
            "保護",
            "安全"
        ],
        "skins": [
            {
                "unified": "1f6e1-fe0f",
                "native": "🛡️"
            }
        ],
        "version": 1
    },
    "carpentry_saw": {
        "id": "carpentry_saw",
        "name": "Carpentry Saw",
        "keywords": [
            "cut",
            "chop",
            "切る",
            "チョップ"
        ],
        "skins": [
            {
                "unified": "1fa9a",
                "native": "🪚"
            }
        ],
        "version": 13
    },
    "wrench": {
        "id": "wrench",
        "name": "Wrench",
        "keywords": [
            "tools",
            "diy",
            "ikea",
            "fix",
            "maintainer",
            "ツール",
            "DIY",
            "イケア",
            "修理",
            "メンテナー"
        ],
        "skins": [
            {
                "unified": "1f527",
                "native": "🔧"
            }
        ],
        "version": 1
    },
    "screwdriver": {
        "id": "screwdriver",
        "name": "Screwdriver",
        "keywords": [
            "tools",
            "ツール"
        ],
        "skins": [
            {
                "unified": "1fa9b",
                "native": "🪛"
            }
        ],
        "version": 13
    },
    "nut_and_bolt": {
        "id": "nut_and_bolt",
        "name": "Nut and Bolt",
        "keywords": [
            "handy",
            "tools",
            "fix",
            "ハンディ",
            "ツール",
            "修理"
        ],
        "skins": [
            {
                "unified": "1f529",
                "native": "🔩"
            }
        ],
        "version": 1
    },
    "gear": {
        "id": "gear",
        "name": "Gear",
        "keywords": [
            "cog",
            "コグ"
        ],
        "skins": [
            {
                "unified": "2699-fe0f",
                "native": "⚙️"
            }
        ],
        "version": 1
    },
    "compression": {
        "id": "compression",
        "name": "Clamp",
        "keywords": [
            "compression",
            "tool",
            "圧縮",
            "道具"
        ],
        "skins": [
            {
                "unified": "1f5dc-fe0f",
                "native": "🗜️"
            }
        ],
        "version": 1
    },
    "scales": {
        "id": "scales",
        "name": "Balance Scale",
        "keywords": [
            "scales",
            "law",
            "fairness",
            "weight",
            "うろこ",
            "法",
            "公平",
            "重さ"
        ],
        "skins": [
            {
                "unified": "2696-fe0f",
                "native": "⚖️"
            }
        ],
        "version": 1
    },
    "probing_cane": {
        "id": "probing_cane",
        "name": "White Cane",
        "keywords": [
            "probing",
            "accessibility",
            "プロービング",
            "アクセシビリティ"
        ],
        "skins": [
            {
                "unified": "1f9af",
                "native": "🦯"
            }
        ],
        "version": 12
    },
    "link": {
        "id": "link",
        "name": "Link",
        "keywords": [
            "rings",
            "url",
            "リング",
            "URL"
        ],
        "skins": [
            {
                "unified": "1f517",
                "native": "🔗"
            }
        ],
        "version": 1
    },
    "chains": {
        "id": "chains",
        "name": "Chains",
        "keywords": [
            "lock",
            "arrest",
            "ロック",
            "逮捕"
        ],
        "skins": [
            {
                "unified": "26d3-fe0f",
                "native": "⛓️"
            }
        ],
        "version": 1
    },
    "hook": {
        "id": "hook",
        "name": "Hook",
        "keywords": [
            "tools",
            "ツール"
        ],
        "skins": [
            {
                "unified": "1fa9d",
                "native": "🪝"
            }
        ],
        "version": 13
    },
    "toolbox": {
        "id": "toolbox",
        "name": "Toolbox",
        "keywords": [
            "tools",
            "diy",
            "fix",
            "maintainer",
            "mechanic",
            "ツール",
            "DIY",
            "修理",
            "メンテナ",
            "メカニック"
        ],
        "skins": [
            {
                "unified": "1f9f0",
                "native": "🧰"
            }
        ],
        "version": 11
    },
    "magnet": {
        "id": "magnet",
        "name": "Magnet",
        "keywords": [
            "attraction",
            "magnetic",
            "アトラクション",
            "磁気"
        ],
        "skins": [
            {
                "unified": "1f9f2",
                "native": "🧲"
            }
        ],
        "version": 11
    },
    "ladder": {
        "id": "ladder",
        "name": "Ladder",
        "keywords": [
            "tools",
            "ツール"
        ],
        "skins": [
            {
                "unified": "1fa9c",
                "native": "🪜"
            }
        ],
        "version": 13
    },
    "alembic": {
        "id": "alembic",
        "name": "Alembic",
        "keywords": [
            "distilling",
            "science",
            "experiment",
            "chemistry",
            "蒸留",
            "化学",
            "実験",
            "化学"
        ],
        "skins": [
            {
                "unified": "2697-fe0f",
                "native": "⚗️"
            }
        ],
        "version": 1
    },
    "test_tube": {
        "id": "test_tube",
        "name": "Test Tube",
        "keywords": [
            "chemistry",
            "experiment",
            "lab",
            "science",
            "化学",
            "実験",
            "ラボ",
            "化学"
        ],
        "skins": [
            {
                "unified": "1f9ea",
                "native": "🧪"
            }
        ],
        "version": 11
    },
    "petri_dish": {
        "id": "petri_dish",
        "name": "Petri Dish",
        "keywords": [
            "bacteria",
            "biology",
            "culture",
            "lab",
            "バクテリア",
            "生物学",
            "文化",
            "ラボ"
        ],
        "skins": [
            {
                "unified": "1f9eb",
                "native": "🧫"
            }
        ],
        "version": 11
    },
    "dna": {
        "id": "dna",
        "name": "Dna",
        "keywords": [
            "biologist",
            "genetics",
            "life",
            "生物学者",
            "遺伝学",
            "人生"
        ],
        "skins": [
            {
                "unified": "1f9ec",
                "native": "🧬"
            }
        ],
        "version": 11
    },
    "microscope": {
        "id": "microscope",
        "name": "Microscope",
        "keywords": [
            "laboratory",
            "experiment",
            "zoomin",
            "science",
            "study",
            "ラボ",
            "実験",
            "ズームイン",
            "化学",
            "勉強"
        ],
        "skins": [
            {
                "unified": "1f52c",
                "native": "🔬"
            }
        ],
        "version": 1
    },
    "telescope": {
        "id": "telescope",
        "name": "Telescope",
        "keywords": [
            "stars",
            "space",
            "zoom",
            "science",
            "astronomy",
            "出演者",
            "空",
            "ズーム",
            "化学",
            "天文学"
        ],
        "skins": [
            {
                "unified": "1f52d",
                "native": "🔭"
            }
        ],
        "version": 1
    },
    "satellite_antenna": {
        "id": "satellite_antenna",
        "name": "Satellite Antenna",
        "keywords": [
            "communication",
            "future",
            "radio",
            "space",
            "コミュニケーション",
            "未来",
            "無線",
            "空"
        ],
        "skins": [
            {
                "unified": "1f4e1",
                "native": "📡"
            }
        ],
        "version": 1
    },
    "syringe": {
        "id": "syringe",
        "name": "Syringe",
        "keywords": [
            "health",
            "hospital",
            "drugs",
            "blood",
            "medicine",
            "needle",
            "doctor",
            "nurse",
            "健康",
            "病院",
            "薬物",
            "血",
            "薬",
            "針",
            "医者",
            "看護婦"
        ],
        "skins": [
            {
                "unified": "1f489",
                "native": "💉"
            }
        ],
        "version": 1
    },
    "drop_of_blood": {
        "id": "drop_of_blood",
        "name": "Drop of Blood",
        "keywords": [
            "period",
            "hurt",
            "harm",
            "wound",
            "期間",
            "傷つく",
            "危害",
            "傷"
        ],
        "skins": [
            {
                "unified": "1fa78",
                "native": "🩸"
            }
        ],
        "version": 12
    },
    "pill": {
        "id": "pill",
        "name": "Pill",
        "keywords": [
            "health",
            "medicine",
            "doctor",
            "pharmacy",
            "drug",
            "健康",
            "薬",
            "医者",
            "薬局",
            "薬"
        ],
        "skins": [
            {
                "unified": "1f48a",
                "native": "💊"
            }
        ],
        "version": 1
    },
    "adhesive_bandage": {
        "id": "adhesive_bandage",
        "name": "Adhesive Bandage",
        "keywords": [
            "heal",
            "癒し"
        ],
        "skins": [
            {
                "unified": "1fa79",
                "native": "🩹"
            }
        ],
        "version": 12
    },
    "crutch": {
        "id": "crutch",
        "name": "Crutch",
        "keywords": [
            "accessibility",
            "assist",
            "アクセシビリティ",
            "支援する"
        ],
        "skins": [
            {
                "unified": "1fa7c",
                "native": "🩼"
            }
        ],
        "version": 14
    },
    "stethoscope": {
        "id": "stethoscope",
        "name": "Stethoscope",
        "keywords": [
            "health",
            "健康"
        ],
        "skins": [
            {
                "unified": "1fa7a",
                "native": "🩺"
            }
        ],
        "version": 12
    },
    "x-ray": {
        "id": "x-ray",
        "name": "X-Ray",
        "keywords": [
            "x",
            "ray",
            "skeleton",
            "medicine",
            "バツ",
            "レイ",
            "スケルトン",
            "薬"
        ],
        "skins": [
            {
                "unified": "1fa7b",
                "native": "🩻"
            }
        ],
        "version": 14
    },
    "door": {
        "id": "door",
        "name": "Door",
        "keywords": [
            "house",
            "entry",
            "exit",
            "家",
            "エントリ",
            "出口"
        ],
        "skins": [
            {
                "unified": "1f6aa",
                "native": "🚪"
            }
        ],
        "version": 1
    },
    "elevator": {
        "id": "elevator",
        "name": "Elevator",
        "keywords": [
            "lift",
            "リフト"
        ],
        "skins": [
            {
                "unified": "1f6d7",
                "native": "🛗"
            }
        ],
        "version": 13
    },
    "mirror": {
        "id": "mirror",
        "name": "Mirror",
        "keywords": [
            "reflection",
            "反射"
        ],
        "skins": [
            {
                "unified": "1fa9e",
                "native": "🪞"
            }
        ],
        "version": 13
    },
    "window": {
        "id": "window",
        "name": "Window",
        "keywords": [
            "scenery",
            "景色"
        ],
        "skins": [
            {
                "unified": "1fa9f",
                "native": "🪟"
            }
        ],
        "version": 13
    },
    "bed": {
        "id": "bed",
        "name": "Bed",
        "keywords": [
            "sleep",
            "rest",
            "寝る",
            "休み"
        ],
        "skins": [
            {
                "unified": "1f6cf-fe0f",
                "native": "🛏️"
            }
        ],
        "version": 1
    },
    "couch_and_lamp": {
        "id": "couch_and_lamp",
        "name": "Couch and Lamp",
        "keywords": [
            "read",
            "chill",
            "読む",
            "チル"
        ],
        "skins": [
            {
                "unified": "1f6cb-fe0f",
                "native": "🛋️"
            }
        ],
        "version": 1
    },
    "chair": {
        "id": "chair",
        "name": "Chair",
        "keywords": [
            "sit",
            "furniture",
            "座る",
            "家具"
        ],
        "skins": [
            {
                "unified": "1fa91",
                "native": "🪑"
            }
        ],
        "version": 12
    },
    "toilet": {
        "id": "toilet",
        "name": "Toilet",
        "keywords": [
            "restroom",
            "wc",
            "washroom",
            "bathroom",
            "potty",
            "トイレ",
            "トイレ",
            "洗面所",
            "トイレ",
            "トイレ"
        ],
        "skins": [
            {
                "unified": "1f6bd",
                "native": "🚽"
            }
        ],
        "version": 1
    },
    "plunger": {
        "id": "plunger",
        "name": "Plunger",
        "keywords": [
            "toilet",
            "トイレ"
        ],
        "skins": [
            {
                "unified": "1faa0",
                "native": "🪠"
            }
        ],
        "version": 13
    },
    "shower": {
        "id": "shower",
        "name": "Shower",
        "keywords": [
            "clean",
            "water",
            "bathroom",
            "綺麗",
            "水",
            "トイレ"
        ],
        "skins": [
            {
                "unified": "1f6bf",
                "native": "🚿"
            }
        ],
        "version": 1
    },
    "bathtub": {
        "id": "bathtub",
        "name": "Bathtub",
        "keywords": [
            "clean",
            "shower",
            "bathroom",
            "綺麗",
            "シャワー",
            "トイレ"
        ],
        "skins": [
            {
                "unified": "1f6c1",
                "native": "🛁"
            }
        ],
        "version": 1
    },
    "mouse_trap": {
        "id": "mouse_trap",
        "name": "Mouse Trap",
        "keywords": [
            "cheese",
            "チーズ"
        ],
        "skins": [
            {
                "unified": "1faa4",
                "native": "🪤"
            }
        ],
        "version": 13
    },
    "razor": {
        "id": "razor",
        "name": "Razor",
        "keywords": [
            "cut",
            "切る"
        ],
        "skins": [
            {
                "unified": "1fa92",
                "native": "🪒"
            }
        ],
        "version": 12
    },
    "lotion_bottle": {
        "id": "lotion_bottle",
        "name": "Lotion Bottle",
        "keywords": [
            "moisturizer",
            "sunscreen",
            "保湿剤",
            "日焼け止め"
        ],
        "skins": [
            {
                "unified": "1f9f4",
                "native": "🧴"
            }
        ],
        "version": 11
    },
    "safety_pin": {
        "id": "safety_pin",
        "name": "Safety Pin",
        "keywords": [
            "diaper",
            "おむつ"
        ],
        "skins": [
            {
                "unified": "1f9f7",
                "native": "🧷"
            }
        ],
        "version": 11
    },
    "broom": {
        "id": "broom",
        "name": "Broom",
        "keywords": [
            "cleaning",
            "sweeping",
            "witch",
            "クリーニング",
            "スイープ",
            "魔女"
        ],
        "skins": [
            {
                "unified": "1f9f9",
                "native": "🧹"
            }
        ],
        "version": 11
    },
    "basket": {
        "id": "basket",
        "name": "Basket",
        "keywords": [
            "laundry",
            "ランドリー"
        ],
        "skins": [
            {
                "unified": "1f9fa",
                "native": "🧺"
            }
        ],
        "version": 11
    },
    "roll_of_paper": {
        "id": "roll_of_paper",
        "name": "Roll of Paper",
        "keywords": [],
        "skins": [
            {
                "unified": "1f9fb",
                "native": "🧻"
            }
        ],
        "version": 11
    },
    "bucket": {
        "id": "bucket",
        "name": "Bucket",
        "keywords": [
            "water",
            "container",
            "水",
            "容器"
        ],
        "skins": [
            {
                "unified": "1faa3",
                "native": "🪣"
            }
        ],
        "version": 13
    },
    "soap": {
        "id": "soap",
        "name": "Soap",
        "keywords": [
            "bar",
            "bathing",
            "cleaning",
            "lather",
            "バー",
            "入浴",
            "クリーニング",
            "泡"
        ],
        "skins": [
            {
                "unified": "1f9fc",
                "native": "🧼"
            }
        ],
        "version": 11
    },
    "bubbles": {
        "id": "bubbles",
        "name": "Bubbles",
        "keywords": [
            "soap",
            "fun",
            "carbonation",
            "sparkling",
            "石鹸",
            "楽しい",
            "炭酸",
            "スパークリング"
        ],
        "skins": [
            {
                "unified": "1fae7",
                "native": "🫧"
            }
        ],
        "version": 14
    },
    "toothbrush": {
        "id": "toothbrush",
        "name": "Toothbrush",
        "keywords": [
            "hygiene",
            "dental",
            "衛生",
            "歯科"
        ],
        "skins": [
            {
                "unified": "1faa5",
                "native": "🪥"
            }
        ],
        "version": 13
    },
    "sponge": {
        "id": "sponge",
        "name": "Sponge",
        "keywords": [
            "absorbing",
            "cleaning",
            "porous",
            "吸収",
            "クリーニング",
            "多孔質"
        ],
        "skins": [
            {
                "unified": "1f9fd",
                "native": "🧽"
            }
        ],
        "version": 11
    },
    "fire_extinguisher": {
        "id": "fire_extinguisher",
        "name": "Fire Extinguisher",
        "keywords": [
            "quench",
            "鎮める"
        ],
        "skins": [
            {
                "unified": "1f9ef",
                "native": "🧯"
            }
        ],
        "version": 11
    },
    "shopping_trolley": {
        "id": "shopping_trolley",
        "name": "Shopping Cart",
        "keywords": [
            "trolley",
            "トロリー"
        ],
        "skins": [
            {
                "unified": "1f6d2",
                "native": "🛒"
            }
        ],
        "version": 3
    },
    "smoking": {
        "id": "smoking",
        "name": "Cigarette",
        "keywords": [
            "smoking",
            "kills",
            "tobacco",
            "joint",
            "smoke",
            "喫煙",
            "殺す",
            "タバコ",
            "ジョイント",
            "煙"
        ],
        "skins": [
            {
                "unified": "1f6ac",
                "native": "🚬"
            }
        ],
        "version": 1
    },
    "coffin": {
        "id": "coffin",
        "name": "Coffin",
        "keywords": [
            "vampire",
            "dead",
            "die",
            "death",
            "rip",
            "graveyard",
            "cemetery",
            "casket",
            "funeral",
            "box",
            "吸血鬼",
            "死",
            "死ぬ",
            "死",
            "RIP",
            "墓地",
            "お墓",
            "棺",
            "葬儀",
            "箱"
        ],
        "skins": [
            {
                "unified": "26b0-fe0f",
                "native": "⚰️"
            }
        ],
        "version": 1
    },
    "headstone": {
        "id": "headstone",
        "name": "Headstone",
        "keywords": [
            "death",
            "rip",
            "grave",
            "死",
            "RIP",
            "墓"
        ],
        "skins": [
            {
                "unified": "1faa6",
                "native": "🪦"
            }
        ],
        "version": 13
    },
    "funeral_urn": {
        "id": "funeral_urn",
        "name": "Funeral Urn",
        "keywords": [
            "dead",
            "die",
            "death",
            "rip",
            "ashes",
            "死",
            "死ぬ",
            "死",
            "RIP",
            "灰"
        ],
        "skins": [
            {
                "unified": "26b1-fe0f",
                "native": "⚱️"
            }
        ],
        "version": 1
    },
    "moyai": {
        "id": "moyai",
        "name": "Moai",
        "keywords": [
            "moyai",
            "rock",
            "easter",
            "island",
            "もやい",
            "石",
            "イースター",
            "小島"
        ],
        "skins": [
            {
                "unified": "1f5ff",
                "native": "🗿"
            }
        ],
        "version": 1
    },
    "placard": {
        "id": "placard",
        "name": "Placard",
        "keywords": [
            "announcement",
            "発表"
        ],
        "skins": [
            {
                "unified": "1faa7",
                "native": "🪧"
            }
        ],
        "version": 13
    },
    "identification_card": {
        "id": "identification_card",
        "name": "Identification Card",
        "keywords": [
            "document",
            "書類"
        ],
        "skins": [
            {
                "unified": "1faaa",
                "native": "🪪"
            }
        ],
        "version": 14
    },
    "atm": {
        "id": "atm",
        "name": "Atm Sign",
        "keywords": [
            "money",
            "sales",
            "cash",
            "blue",
            "square",
            "payment",
            "bank",
            "お金",
            "販売",
            "現金",
            "青",
            "四角",
            "支払い",
            "銀行"
        ],
        "skins": [
            {
                "unified": "1f3e7",
                "native": "🏧"
            }
        ],
        "version": 1
    },
    "put_litter_in_its_place": {
        "id": "put_litter_in_its_place",
        "name": "Litter in Bin Sign",
        "keywords": [
            "put",
            "its",
            "place",
            "blue",
            "square",
            "human",
            "info",
            "置く",
            "これは",
            "場所",
            "青",
            "四角",
            "人間",
            "情報"
        ],
        "skins": [
            {
                "unified": "1f6ae",
                "native": "🚮"
            }
        ],
        "version": 1
    },
    "potable_water": {
        "id": "potable_water",
        "name": "Potable Water",
        "keywords": [
            "blue",
            "square",
            "liquid",
            "restroom",
            "cleaning",
            "faucet",
            "青",
            "四角",
            "液体",
            "トイレ",
            "クリーニング",
            "蛇口"
        ],
        "skins": [
            {
                "unified": "1f6b0",
                "native": "🚰"
            }
        ],
        "version": 1
    },
    "wheelchair": {
        "id": "wheelchair",
        "name": "Wheelchair Symbol",
        "keywords": [
            "blue",
            "square",
            "disabled",
            "accessibility",
            "青",
            "四角",
            "無効",
            "アクセシビリティ"
        ],
        "skins": [
            {
                "unified": "267f",
                "native": "♿"
            }
        ],
        "version": 1
    },
    "mens": {
        "id": "mens",
        "name": "Men’s Room",
        "keywords": [
            "mens",
            "men",
            "s",
            "toilet",
            "restroom",
            "wc",
            "blue",
            "square",
            "gender",
            "male",
            "男性用",
            "男性",
            "s",
            "トイレ",
            "トイレ",
            "トイレ",
            "青",
            "四角",
            "性別",
            "男"
        ],
        "skins": [
            {
                "unified": "1f6b9",
                "native": "🚹"
            }
        ],
        "version": 1
    },
    "womens": {
        "id": "womens",
        "name": "Women’s Room",
        "keywords": [
            "womens",
            "women",
            "s",
            "purple",
            "square",
            "woman",
            "female",
            "toilet",
            "loo",
            "restroom",
            "gender",
            "婦人向け",
            "女性",
            "s",
            "紫",
            "四角",
            "女性",
            "女性",
            "トイレ",
            "ルー",
            "トイレ",
            "性別"
        ],
        "skins": [
            {
                "unified": "1f6ba",
                "native": "🚺"
            }
        ],
        "version": 1
    },
    "restroom": {
        "id": "restroom",
        "name": "Restroom",
        "keywords": [
            "blue",
            "square",
            "toilet",
            "refresh",
            "wc",
            "gender",
            "青",
            "四角",
            "トイレ",
            "リフレッシュ",
            "トイレ",
            "性別"
        ],
        "skins": [
            {
                "unified": "1f6bb",
                "native": "🚻"
            }
        ],
        "version": 1
    },
    "baby_symbol": {
        "id": "baby_symbol",
        "name": "Baby Symbol",
        "keywords": [
            "orange",
            "square",
            "child",
            "オレンジ",
            "四角",
            "子供"
        ],
        "skins": [
            {
                "unified": "1f6bc",
                "native": "🚼"
            }
        ],
        "version": 1
    },
    "wc": {
        "id": "wc",
        "name": "Water Closet",
        "keywords": [
            "wc",
            "toilet",
            "restroom",
            "blue",
            "square",
            "トイレ",
            "トイレ",
            "トイレ",
            "青",
            "四角"
        ],
        "skins": [
            {
                "unified": "1f6be",
                "native": "🚾"
            }
        ],
        "version": 1
    },
    "passport_control": {
        "id": "passport_control",
        "name": "Passport Control",
        "keywords": [
            "custom",
            "blue",
            "square",
            "カスタム",
            "青",
            "四角"
        ],
        "skins": [
            {
                "unified": "1f6c2",
                "native": "🛂"
            }
        ],
        "version": 1
    },
    "customs": {
        "id": "customs",
        "name": "Customs",
        "keywords": [
            "passport",
            "border",
            "blue",
            "square",
            "パスポート",
            "国境",
            "青",
            "四角"
        ],
        "skins": [
            {
                "unified": "1f6c3",
                "native": "🛃"
            }
        ],
        "version": 1
    },
    "baggage_claim": {
        "id": "baggage_claim",
        "name": "Baggage Claim",
        "keywords": [
            "blue",
            "square",
            "airport",
            "transport",
            "青",
            "四角",
            "空港",
            "輸送"
        ],
        "skins": [
            {
                "unified": "1f6c4",
                "native": "🛄"
            }
        ],
        "version": 1
    },
    "left_luggage": {
        "id": "left_luggage",
        "name": "Left Luggage",
        "keywords": [
            "blue",
            "square",
            "travel",
            "青",
            "四角",
            "旅行"
        ],
        "skins": [
            {
                "unified": "1f6c5",
                "native": "🛅"
            }
        ],
        "version": 1
    },
    "warning": {
        "id": "warning",
        "name": "Warning",
        "keywords": [
            "exclamation",
            "wip",
            "alert",
            "error",
            "problem",
            "issue",
            "感嘆符",
            "ワイプ",
            "アラート",
            "エラー",
            "問題",
            "問題"
        ],
        "skins": [
            {
                "unified": "26a0-fe0f",
                "native": "⚠️"
            }
        ],
        "version": 1
    },
    "children_crossing": {
        "id": "children_crossing",
        "name": "Children Crossing",
        "keywords": [
            "school",
            "warning",
            "danger",
            "sign",
            "driving",
            "yellow",
            "diamond",
            "学校",
            "警告",
            "危険",
            "サイン",
            "運転",
            "黄色",
            "ダイヤモンド"
        ],
        "skins": [
            {
                "unified": "1f6b8",
                "native": "🚸"
            }
        ],
        "version": 1
    },
    "no_entry": {
        "id": "no_entry",
        "name": "No Entry",
        "keywords": [
            "limit",
            "security",
            "privacy",
            "bad",
            "denied",
            "stop",
            "circle",
            "限界",
            "安全",
            "プライバシー",
            "悪い",
            "拒否された",
            "ストップ",
            "丸"
        ],
        "skins": [
            {
                "unified": "26d4",
                "native": "⛔"
            }
        ],
        "version": 1
    },
    "no_entry_sign": {
        "id": "no_entry_sign",
        "name": "Prohibited",
        "keywords": [
            "no",
            "entry",
            "sign",
            "forbid",
            "stop",
            "limit",
            "denied",
            "disallow",
            "circle",
            "いいえ",
            "エントリ",
            "サイン",
            "禁止",
            "ストップ",
            "限界",
            "拒否された",
            "許可しない",
            "丸"
        ],
        "skins": [
            {
                "unified": "1f6ab",
                "native": "🚫"
            }
        ],
        "version": 1
    },
    "no_bicycles": {
        "id": "no_bicycles",
        "name": "No Bicycles",
        "keywords": [
            "cyclist",
            "prohibited",
            "circle",
            "サイクリスト",
            "禁止",
            "丸"
        ],
        "skins": [
            {
                "unified": "1f6b3",
                "native": "🚳"
            }
        ],
        "version": 1
    },
    "no_smoking": {
        "id": "no_smoking",
        "name": "No Smoking",
        "keywords": [
            "cigarette",
            "blue",
            "square",
            "smell",
            "smoke",
            "シガレット",
            "青",
            "四角",
            "匂い",
            "煙"
        ],
        "skins": [
            {
                "unified": "1f6ad",
                "native": "🚭"
            }
        ],
        "version": 1
    },
    "do_not_litter": {
        "id": "do_not_litter",
        "name": "No Littering",
        "keywords": [
            "do",
            "not",
            "litter",
            "trash",
            "bin",
            "garbage",
            "circle",
            "する",
            "いいえ",
            "くず",
            "ごみ",
            "置き場",
            "ごみ",
            "丸"
        ],
        "skins": [
            {
                "unified": "1f6af",
                "native": "🚯"
            }
        ],
        "version": 1
    },
    "non-potable_water": {
        "id": "non-potable_water",
        "name": "Non-Potable Water",
        "keywords": [
            "non",
            "potable",
            "drink",
            "faucet",
            "tap",
            "circle",
            "非",
            "飲用可能",
            "飲む",
            "蛇口",
            "タップ",
            "丸"
        ],
        "skins": [
            {
                "unified": "1f6b1",
                "native": "🚱"
            }
        ],
        "version": 1
    },
    "no_pedestrians": {
        "id": "no_pedestrians",
        "name": "No Pedestrians",
        "keywords": [
            "rules",
            "crossing",
            "walking",
            "circle",
            "ルール",
            "交差点",
            "歩く",
            "丸"
        ],
        "skins": [
            {
                "unified": "1f6b7",
                "native": "🚷"
            }
        ],
        "version": 1
    },
    "no_mobile_phones": {
        "id": "no_mobile_phones",
        "name": "No Mobile Phones",
        "keywords": [
            "iphone",
            "mute",
            "circle",
            "アイフォン",
            "無音",
            "丸"
        ],
        "skins": [
            {
                "unified": "1f4f5",
                "native": "📵"
            }
        ],
        "version": 1
    },
    "underage": {
        "id": "underage",
        "name": "No One Under Eighteen",
        "keywords": [
            "underage",
            "18",
            "drink",
            "pub",
            "night",
            "minor",
            "circle",
            "未成年者",
            "18",
            "飲む",
            "パブ",
            "夜",
            "マイナー",
            "丸"
        ],
        "skins": [
            {
                "unified": "1f51e",
                "native": "🔞"
            }
        ],
        "version": 1
    },
    "radioactive_sign": {
        "id": "radioactive_sign",
        "name": "Radioactive",
        "keywords": [
            "sign",
            "nuclear",
            "danger",
            "サイン",
            "核",
            "危険"
        ],
        "skins": [
            {
                "unified": "2622-fe0f",
                "native": "☢️"
            }
        ],
        "version": 1
    },
    "biohazard_sign": {
        "id": "biohazard_sign",
        "name": "Biohazard",
        "keywords": [
            "sign",
            "danger",
            "サイン",
            "危険"
        ],
        "skins": [
            {
                "unified": "2623-fe0f",
                "native": "☣️"
            }
        ],
        "version": 1
    },
    "arrow_up": {
        "id": "arrow_up",
        "name": "Up Arrow",
        "keywords": [
            "blue",
            "square",
            "continue",
            "top",
            "direction",
            "青",
            "四角",
            "続く",
            "上",
            "方向"
        ],
        "skins": [
            {
                "unified": "2b06-fe0f",
                "native": "⬆️"
            }
        ],
        "version": 1
    },
    "arrow_upper_right": {
        "id": "arrow_upper_right",
        "name": "Up-Right Arrow",
        "keywords": [
            "upper",
            "right",
            "up",
            "blue",
            "square",
            "point",
            "direction",
            "diagonal",
            "northeast",
            "アッパー",
            "右",
            "上",
            "青",
            "四角",
            "点",
            "方向",
            "対角線",
            "北東"
        ],
        "skins": [
            {
                "unified": "2197-fe0f",
                "native": "↗️"
            }
        ],
        "version": 1
    },
    "arrow_right": {
        "id": "arrow_right",
        "name": "Right Arrow",
        "keywords": [
            "blue",
            "square",
            "next",
            "青",
            "四角",
            "次"
        ],
        "skins": [
            {
                "unified": "27a1-fe0f",
                "native": "➡️"
            }
        ],
        "version": 1
    },
    "arrow_lower_right": {
        "id": "arrow_lower_right",
        "name": "South East Arrow",
        "keywords": [
            "lower",
            "right",
            "down",
            "blue",
            "square",
            "direction",
            "diagonal",
            "southeast",
            "低い",
            "右",
            "下",
            "青",
            "四角",
            "方向",
            "対角線",
            "南東"
        ],
        "skins": [
            {
                "unified": "2198-fe0f",
                "native": "↘️"
            }
        ],
        "version": 1
    },
    "arrow_down": {
        "id": "arrow_down",
        "name": "Down Arrow",
        "keywords": [
            "blue",
            "square",
            "direction",
            "bottom",
            "青",
            "四角",
            "方向",
            "下"
        ],
        "skins": [
            {
                "unified": "2b07-fe0f",
                "native": "⬇️"
            }
        ],
        "version": 1
    },
    "arrow_lower_left": {
        "id": "arrow_lower_left",
        "name": "Down-Left Arrow",
        "keywords": [
            "lower",
            "left",
            "down",
            "blue",
            "square",
            "direction",
            "diagonal",
            "southwest",
            "低い",
            "左",
            "下",
            "青",
            "四角",
            "方向",
            "対角線",
            "南西"
        ],
        "skins": [
            {
                "unified": "2199-fe0f",
                "native": "↙️"
            }
        ],
        "version": 1
    },
    "arrow_left": {
        "id": "arrow_left",
        "name": "Left Arrow",
        "keywords": [
            "blue",
            "square",
            "previous",
            "back",
            "青",
            "四角",
            "前",
            "戻る"
        ],
        "skins": [
            {
                "unified": "2b05-fe0f",
                "native": "⬅️"
            }
        ],
        "version": 1
    },
    "arrow_upper_left": {
        "id": "arrow_upper_left",
        "name": "Up-Left Arrow",
        "keywords": [
            "upper",
            "left",
            "up",
            "blue",
            "square",
            "point",
            "direction",
            "diagonal",
            "northwest",
            "アッパー",
            "左",
            "上",
            "青",
            "四角",
            "点",
            "方向",
            "対角線",
            "北西"
        ],
        "skins": [
            {
                "unified": "2196-fe0f",
                "native": "↖️"
            }
        ],
        "version": 1
    },
    "arrow_up_down": {
        "id": "arrow_up_down",
        "name": "Up Down Arrow",
        "keywords": [
            "blue",
            "square",
            "direction",
            "way",
            "vertical",
            "青",
            "四角",
            "方向",
            "道",
            "垂直"
        ],
        "skins": [
            {
                "unified": "2195-fe0f",
                "native": "↕️"
            }
        ],
        "version": 1
    },
    "left_right_arrow": {
        "id": "left_right_arrow",
        "name": "Left Right Arrow",
        "keywords": [
            "shape",
            "direction",
            "horizontal",
            "sideways",
            "形",
            "方向",
            "水平",
            "横向き"
        ],
        "skins": [
            {
                "unified": "2194-fe0f",
                "native": "↔️"
            }
        ],
        "version": 1
    },
    "leftwards_arrow_with_hook": {
        "id": "leftwards_arrow_with_hook",
        "name": "Right Arrow Curving Left",
        "keywords": [
            "leftwards",
            "with",
            "hook",
            "back",
            "return",
            "blue",
            "square",
            "undo",
            "enter",
            "左",
            "と",
            "針",
            "戻る",
            "戻る",
            "青",
            "四角",
            "元に戻す",
            "入力"
        ],
        "skins": [
            {
                "unified": "21a9-fe0f",
                "native": "↩️"
            }
        ],
        "version": 1
    },
    "arrow_right_hook": {
        "id": "arrow_right_hook",
        "name": "Left Arrow Curving Right",
        "keywords": [
            "hook",
            "blue",
            "square",
            "return",
            "rotate",
            "direction",
            "針",
            "青",
            "四角",
            "戻る",
            "回転",
            "方向"
        ],
        "skins": [
            {
                "unified": "21aa-fe0f",
                "native": "↪️"
            }
        ],
        "version": 1
    },
    "arrow_heading_up": {
        "id": "arrow_heading_up",
        "name": "Right Arrow Curving Up",
        "keywords": [
            "heading",
            "blue",
            "square",
            "direction",
            "top",
            "見出し",
            "青",
            "四角",
            "方向",
            "上"
        ],
        "skins": [
            {
                "unified": "2934-fe0f",
                "native": "⤴️"
            }
        ],
        "version": 1
    },
    "arrow_heading_down": {
        "id": "arrow_heading_down",
        "name": "Right Arrow Curving Down",
        "keywords": [
            "heading",
            "blue",
            "square",
            "direction",
            "bottom",
            "見出し",
            "青",
            "四角",
            "方向",
            "下"
        ],
        "skins": [
            {
                "unified": "2935-fe0f",
                "native": "⤵️"
            }
        ],
        "version": 1
    },
    "arrows_clockwise": {
        "id": "arrows_clockwise",
        "name": "Clockwise Vertical Arrows",
        "keywords": [
            "sync",
            "cycle",
            "round",
            "repeat",
            "同期",
            "サイクル",
            "ラウンド",
            "繰り返す"
        ],
        "skins": [
            {
                "unified": "1f503",
                "native": "🔃"
            }
        ],
        "version": 1
    },
    "arrows_counterclockwise": {
        "id": "arrows_counterclockwise",
        "name": "Counterclockwise Arrows Button",
        "keywords": [
            "blue",
            "square",
            "sync",
            "cycle",
            "青",
            "四角",
            "同期",
            "サイクル"
        ],
        "skins": [
            {
                "unified": "1f504",
                "native": "🔄"
            }
        ],
        "version": 1
    },
    "back": {
        "id": "back",
        "name": "Back Arrow",
        "keywords": [
            "words",
            "return",
            "言葉",
            "戻る"
        ],
        "skins": [
            {
                "unified": "1f519",
                "native": "🔙"
            }
        ],
        "version": 1
    },
    "end": {
        "id": "end",
        "name": "End Arrow",
        "keywords": [
            "words",
            "言葉"
        ],
        "skins": [
            {
                "unified": "1f51a",
                "native": "🔚"
            }
        ],
        "version": 1
    },
    "on": {
        "id": "on",
        "name": "On! Arrow",
        "keywords": [
            "on",
            "words",
            "の上",
            "言葉"
        ],
        "skins": [
            {
                "unified": "1f51b",
                "native": "🔛"
            }
        ],
        "version": 1
    },
    "soon": {
        "id": "soon",
        "name": "Soon Arrow",
        "keywords": [
            "words",
            "言葉"
        ],
        "skins": [
            {
                "unified": "1f51c",
                "native": "🔜"
            }
        ],
        "version": 1
    },
    "top": {
        "id": "top",
        "name": "Top Arrow",
        "keywords": [
            "words",
            "blue",
            "square",
            "言葉",
            "青",
            "四角"
        ],
        "skins": [
            {
                "unified": "1f51d",
                "native": "🔝"
            }
        ],
        "version": 1
    },
    "place_of_worship": {
        "id": "place_of_worship",
        "name": "Place of Worship",
        "keywords": [
            "religion",
            "church",
            "temple",
            "prayer",
            "宗教",
            "教会",
            "寺",
            "祈り"
        ],
        "skins": [
            {
                "unified": "1f6d0",
                "native": "🛐"
            }
        ],
        "version": 1
    },
    "atom_symbol": {
        "id": "atom_symbol",
        "name": "Atom Symbol",
        "keywords": [
            "science",
            "physics",
            "chemistry",
            "化学",
            "物理",
            "化学"
        ],
        "skins": [
            {
                "unified": "269b-fe0f",
                "native": "⚛️"
            }
        ],
        "version": 1
    },
    "om_symbol": {
        "id": "om_symbol",
        "name": "Om",
        "keywords": [
            "symbol",
            "hinduism",
            "buddhism",
            "sikhism",
            "jainism",
            "シンボル",
            "ヒンズー教",
            "仏教",
            "シーク教",
            "ジャイナ教"
        ],
        "skins": [
            {
                "unified": "1f549-fe0f",
                "native": "🕉️"
            }
        ],
        "version": 1
    },
    "star_of_david": {
        "id": "star_of_david",
        "name": "Star of David",
        "keywords": [
            "judaism",
            "ユダヤ教"
        ],
        "skins": [
            {
                "unified": "2721-fe0f",
                "native": "✡️"
            }
        ],
        "version": 1
    },
    "wheel_of_dharma": {
        "id": "wheel_of_dharma",
        "name": "Wheel of Dharma",
        "keywords": [
            "hinduism",
            "buddhism",
            "sikhism",
            "jainism",
            "ヒンズー教",
            "仏教",
            "シーク教",
            "ジャイナ教"
        ],
        "skins": [
            {
                "unified": "2638-fe0f",
                "native": "☸️"
            }
        ],
        "version": 1
    },
    "yin_yang": {
        "id": "yin_yang",
        "name": "Yin Yang",
        "keywords": [
            "balance",
            "バランス"
        ],
        "skins": [
            {
                "unified": "262f-fe0f",
                "native": "☯️"
            }
        ],
        "version": 1
    },
    "latin_cross": {
        "id": "latin_cross",
        "name": "Latin Cross",
        "keywords": [
            "christianity",
            "キリスト教"
        ],
        "skins": [
            {
                "unified": "271d-fe0f",
                "native": "✝️"
            }
        ],
        "version": 1
    },
    "orthodox_cross": {
        "id": "orthodox_cross",
        "name": "Orthodox Cross",
        "keywords": [
            "suppedaneum",
            "religion",
            "suppedaneum",
            "宗教"
        ],
        "skins": [
            {
                "unified": "2626-fe0f",
                "native": "☦️"
            }
        ],
        "version": 1
    },
    "star_and_crescent": {
        "id": "star_and_crescent",
        "name": "Star and Crescent",
        "keywords": [
            "islam",
            "イスラム教"
        ],
        "skins": [
            {
                "unified": "262a-fe0f",
                "native": "☪️"
            }
        ],
        "version": 1
    },
    "peace_symbol": {
        "id": "peace_symbol",
        "name": "Peace Symbol",
        "keywords": [
            "hippie",
            "ヒッピー"
        ],
        "skins": [
            {
                "unified": "262e-fe0f",
                "native": "☮️"
            }
        ],
        "version": 1
    },
    "menorah_with_nine_branches": {
        "id": "menorah_with_nine_branches",
        "name": "Menorah",
        "keywords": [
            "with",
            "nine",
            "branches",
            "hanukkah",
            "candles",
            "jewish",
            "と",
            "九",
            "枝",
            "ハヌカ",
            "ろうそく",
            "ユダヤ人"
        ],
        "skins": [
            {
                "unified": "1f54e",
                "native": "🕎"
            }
        ],
        "version": 1
    },
    "six_pointed_star": {
        "id": "six_pointed_star",
        "name": "Dotted Six-Pointed Star",
        "keywords": [
            "six",
            "pointed",
            "purple",
            "square",
            "religion",
            "jewish",
            "hexagram",
            "六",
            "とがった",
            "紫",
            "四角",
            "宗教",
            "ユダヤ人",
            "ヘキサグラム"
        ],
        "skins": [
            {
                "unified": "1f52f",
                "native": "🔯"
            }
        ],
        "version": 1
    },
    "aries": {
        "id": "aries",
        "name": "Aries",
        "keywords": [
            "sign",
            "purple",
            "square",
            "zodiac",
            "astrology",
            "サイン",
            "紫",
            "四角",
            "ゾディアック",
            "占星術"
        ],
        "skins": [
            {
                "unified": "2648",
                "native": "♈"
            }
        ],
        "version": 1
    },
    "taurus": {
        "id": "taurus",
        "name": "Taurus",
        "keywords": [
            "purple",
            "square",
            "sign",
            "zodiac",
            "astrology",
            "紫",
            "四角",
            "サイン",
            "ゾディアック",
            "占星術"
        ],
        "skins": [
            {
                "unified": "2649",
                "native": "♉"
            }
        ],
        "version": 1
    },
    "gemini": {
        "id": "gemini",
        "name": "Gemini",
        "keywords": [
            "sign",
            "zodiac",
            "purple",
            "square",
            "astrology",
            "サイン",
            "ゾディアック",
            "紫",
            "四角",
            "占星術"
        ],
        "skins": [
            {
                "unified": "264a",
                "native": "♊"
            }
        ],
        "version": 1
    },
    "cancer": {
        "id": "cancer",
        "name": "Cancer",
        "keywords": [
            "sign",
            "zodiac",
            "purple",
            "square",
            "astrology",
            "サイン",
            "ゾディアック",
            "紫",
            "四角",
            "占星術"
        ],
        "skins": [
            {
                "unified": "264b",
                "native": "♋"
            }
        ],
        "version": 1
    },
    "leo": {
        "id": "leo",
        "name": "Leo",
        "keywords": [
            "sign",
            "purple",
            "square",
            "zodiac",
            "astrology",
            "サイン",
            "紫",
            "四角",
            "ゾディアック",
            "占星術"
        ],
        "skins": [
            {
                "unified": "264c",
                "native": "♌"
            }
        ],
        "version": 1
    },
    "virgo": {
        "id": "virgo",
        "name": "Virgo",
        "keywords": [
            "sign",
            "zodiac",
            "purple",
            "square",
            "astrology",
            "サイン",
            "ゾディアック",
            "紫",
            "四角",
            "占星術"
        ],
        "skins": [
            {
                "unified": "264d",
                "native": "♍"
            }
        ],
        "version": 1
    },
    "libra": {
        "id": "libra",
        "name": "Libra",
        "keywords": [
            "sign",
            "purple",
            "square",
            "zodiac",
            "astrology",
            "サイン",
            "紫",
            "四角",
            "ゾディアック",
            "占星術"
        ],
        "skins": [
            {
                "unified": "264e",
                "native": "♎"
            }
        ],
        "version": 1
    },
    "scorpius": {
        "id": "scorpius",
        "name": "Scorpio",
        "keywords": [
            "scorpius",
            "sign",
            "zodiac",
            "purple",
            "square",
            "astrology",
            "さそり座",
            "サイン",
            "ゾディアック",
            "紫",
            "四角",
            "占星術"
        ],
        "skins": [
            {
                "unified": "264f",
                "native": "♏"
            }
        ],
        "version": 1
    },
    "sagittarius": {
        "id": "sagittarius",
        "name": "Sagittarius",
        "keywords": [
            "sign",
            "zodiac",
            "purple",
            "square",
            "astrology",
            "サイン",
            "ゾディアック",
            "紫",
            "四角",
            "占星術"
        ],
        "skins": [
            {
                "unified": "2650",
                "native": "♐"
            }
        ],
        "version": 1
    },
    "capricorn": {
        "id": "capricorn",
        "name": "Capricorn",
        "keywords": [
            "sign",
            "zodiac",
            "purple",
            "square",
            "astrology",
            "サイン",
            "ゾディアック",
            "紫",
            "四角",
            "占星術"
        ],
        "skins": [
            {
                "unified": "2651",
                "native": "♑"
            }
        ],
        "version": 1
    },
    "aquarius": {
        "id": "aquarius",
        "name": "Aquarius",
        "keywords": [
            "sign",
            "purple",
            "square",
            "zodiac",
            "astrology",
            "サイン",
            "紫",
            "四角",
            "ゾディアック",
            "占星術"
        ],
        "skins": [
            {
                "unified": "2652",
                "native": "♒"
            }
        ],
        "version": 1
    },
    "pisces": {
        "id": "pisces",
        "name": "Pisces",
        "keywords": [
            "purple",
            "square",
            "sign",
            "zodiac",
            "astrology",
            "紫",
            "四角",
            "サイン",
            "ゾディアック",
            "占星術"
        ],
        "skins": [
            {
                "unified": "2653",
                "native": "♓"
            }
        ],
        "version": 1
    },
    "ophiuchus": {
        "id": "ophiuchus",
        "name": "Ophiuchus",
        "keywords": [
            "sign",
            "purple",
            "square",
            "constellation",
            "astrology",
            "サイン",
            "紫",
            "四角",
            "星座",
            "占星術"
        ],
        "skins": [
            {
                "unified": "26ce",
                "native": "⛎"
            }
        ],
        "version": 1
    },
    "twisted_rightwards_arrows": {
        "id": "twisted_rightwards_arrows",
        "name": "Shuffle Tracks Button",
        "keywords": [
            "twisted",
            "rightwards",
            "arrows",
            "blue",
            "square",
            "music",
            "random",
            "ねじれた",
            "右",
            "矢印",
            "青",
            "四角",
            "音楽",
            "ランダム"
        ],
        "skins": [
            {
                "unified": "1f500",
                "native": "🔀"
            }
        ],
        "version": 1
    },
    "repeat": {
        "id": "repeat",
        "name": "Repeat Button",
        "keywords": [
            "loop",
            "record",
            "ループ",
            "記録"
        ],
        "skins": [
            {
                "unified": "1f501",
                "native": "🔁"
            }
        ],
        "version": 1
    },
    "repeat_one": {
        "id": "repeat_one",
        "name": "Repeat Single Button",
        "keywords": [
            "one",
            "blue",
            "square",
            "loop",
            "一",
            "青",
            "四角",
            "ループ"
        ],
        "skins": [
            {
                "unified": "1f502",
                "native": "🔂"
            }
        ],
        "version": 1
    },
    "arrow_forward": {
        "id": "arrow_forward",
        "name": "Play Button",
        "keywords": [
            "arrow",
            "forward",
            "blue",
            "square",
            "right",
            "direction",
            "矢印",
            "前方",
            "青",
            "四角",
            "右",
            "方向"
        ],
        "skins": [
            {
                "unified": "25b6-fe0f",
                "native": "▶️"
            }
        ],
        "version": 1
    },
    "fast_forward": {
        "id": "fast_forward",
        "name": "Fast-Forward Button",
        "keywords": [
            "fast",
            "forward",
            "blue",
            "square",
            "play",
            "speed",
            "continue",
            "速い",
            "前方",
            "青",
            "四角",
            "遊ぶ",
            "スピード",
            "続く"
        ],
        "skins": [
            {
                "unified": "23e9",
                "native": "⏩"
            }
        ],
        "version": 1
    },
    "black_right_pointing_double_triangle_with_vertical_bar": {
        "id": "black_right_pointing_double_triangle_with_vertical_bar",
        "name": "Next Track Button",
        "keywords": [
            "black",
            "right",
            "pointing",
            "double",
            "triangle",
            "with",
            "vertical",
            "bar",
            "forward",
            "blue",
            "square",
            "黒",
            "右",
            "ポインティング",
            "ダブル",
            "三角形",
            "と",
            "垂直",
            "バー",
            "前方",
            "青",
            "四角"
        ],
        "skins": [
            {
                "unified": "23ed-fe0f",
                "native": "⏭️"
            }
        ],
        "version": 1
    },
    "black_right_pointing_triangle_with_double_vertical_bar": {
        "id": "black_right_pointing_triangle_with_double_vertical_bar",
        "name": "Play or Pause Button",
        "keywords": [
            "black",
            "right",
            "pointing",
            "triangle",
            "with",
            "double",
            "vertical",
            "bar",
            "blue",
            "square",
            "黒",
            "右",
            "ポインティング",
            "三角形",
            "と",
            "ダブル",
            "垂直",
            "バー",
            "青",
            "四角"
        ],
        "skins": [
            {
                "unified": "23ef-fe0f",
                "native": "⏯️"
            }
        ],
        "version": 1
    },
    "arrow_backward": {
        "id": "arrow_backward",
        "name": "Reverse Button",
        "keywords": [
            "arrow",
            "backward",
            "blue",
            "square",
            "left",
            "direction",
            "矢印",
            "後方",
            "青",
            "四角",
            "左",
            "方向"
        ],
        "skins": [
            {
                "unified": "25c0-fe0f",
                "native": "◀️"
            }
        ],
        "version": 1
    },
    "rewind": {
        "id": "rewind",
        "name": "Fast Reverse Button",
        "keywords": [
            "rewind",
            "play",
            "blue",
            "square",
            "巻き戻し",
            "遊ぶ",
            "青",
            "四角"
        ],
        "skins": [
            {
                "unified": "23ea",
                "native": "⏪"
            }
        ],
        "version": 1
    },
    "black_left_pointing_double_triangle_with_vertical_bar": {
        "id": "black_left_pointing_double_triangle_with_vertical_bar",
        "name": "Last Track Button",
        "keywords": [
            "black",
            "left",
            "pointing",
            "double",
            "triangle",
            "with",
            "vertical",
            "bar",
            "backward",
            "黒",
            "左",
            "ポインティング",
            "ダブル",
            "三角形",
            "と",
            "垂直",
            "バー",
            "後方"
        ],
        "skins": [
            {
                "unified": "23ee-fe0f",
                "native": "⏮️"
            }
        ],
        "version": 1
    },
    "arrow_up_small": {
        "id": "arrow_up_small",
        "name": "Upwards Button",
        "keywords": [
            "arrow",
            "up",
            "small",
            "blue",
            "square",
            "triangle",
            "direction",
            "point",
            "forward",
            "top",
            "矢印",
            "上",
            "小さい",
            "青",
            "四角",
            "三角形",
            "方向",
            "点",
            "前方",
            "上"
        ],
        "skins": [
            {
                "unified": "1f53c",
                "native": "🔼"
            }
        ],
        "version": 1
    },
    "arrow_double_up": {
        "id": "arrow_double_up",
        "name": "Fast Up Button",
        "keywords": [
            "arrow",
            "double",
            "blue",
            "square",
            "direction",
            "top",
            "矢印",
            "ダブル",
            "青",
            "四角",
            "方向",
            "上"
        ],
        "skins": [
            {
                "unified": "23eb",
                "native": "⏫"
            }
        ],
        "version": 1
    },
    "arrow_down_small": {
        "id": "arrow_down_small",
        "name": "Downwards Button",
        "keywords": [
            "arrow",
            "down",
            "small",
            "blue",
            "square",
            "direction",
            "bottom",
            "矢印",
            "下",
            "小さい",
            "青",
            "四角",
            "方向",
            "下"
        ],
        "skins": [
            {
                "unified": "1f53d",
                "native": "🔽"
            }
        ],
        "version": 1
    },
    "arrow_double_down": {
        "id": "arrow_double_down",
        "name": "Fast Down Button",
        "keywords": [
            "arrow",
            "double",
            "blue",
            "square",
            "direction",
            "bottom",
            "矢印",
            "ダブル",
            "青",
            "四角",
            "方向",
            "下"
        ],
        "skins": [
            {
                "unified": "23ec",
                "native": "⏬"
            }
        ],
        "version": 1
    },
    "double_vertical_bar": {
        "id": "double_vertical_bar",
        "name": "Pause Button",
        "keywords": [
            "double",
            "vertical",
            "bar",
            "blue",
            "square",
            "ダブル",
            "垂直",
            "バー",
            "青",
            "四角"
        ],
        "skins": [
            {
                "unified": "23f8-fe0f",
                "native": "⏸️"
            }
        ],
        "version": 1
    },
    "black_square_for_stop": {
        "id": "black_square_for_stop",
        "name": "Stop Button",
        "keywords": [
            "black",
            "square",
            "for",
            "blue",
            "黒",
            "四角",
            "ために",
            "青"
        ],
        "skins": [
            {
                "unified": "23f9-fe0f",
                "native": "⏹️"
            }
        ],
        "version": 1
    },
    "black_circle_for_record": {
        "id": "black_circle_for_record",
        "name": "Record Button",
        "keywords": [
            "black",
            "circle",
            "for",
            "blue",
            "square",
            "黒",
            "丸",
            "ために",
            "青",
            "四角"
        ],
        "skins": [
            {
                "unified": "23fa-fe0f",
                "native": "⏺️"
            }
        ],
        "version": 1
    },
    "eject": {
        "id": "eject",
        "name": "Eject Button",
        "keywords": [
            "blue",
            "square",
            "青",
            "四角"
        ],
        "skins": [
            {
                "unified": "23cf-fe0f",
                "native": "⏏️"
            }
        ],
        "version": 1
    },
    "cinema": {
        "id": "cinema",
        "name": "Cinema",
        "keywords": [
            "blue",
            "square",
            "record",
            "film",
            "movie",
            "curtain",
            "stage",
            "theater",
            "青",
            "四角",
            "記録",
            "映画",
            "映画",
            "カーテン",
            "ステージ",
            "シアター"
        ],
        "skins": [
            {
                "unified": "1f3a6",
                "native": "🎦"
            }
        ],
        "version": 1
    },
    "low_brightness": {
        "id": "low_brightness",
        "name": "Dim Button",
        "keywords": [
            "low",
            "brightness",
            "sun",
            "afternoon",
            "warm",
            "summer",
            "低い",
            "輝度",
            "太陽",
            "午後",
            "暖かい",
            "夏"
        ],
        "skins": [
            {
                "unified": "1f505",
                "native": "🔅"
            }
        ],
        "version": 1
    },
    "high_brightness": {
        "id": "high_brightness",
        "name": "Bright Button",
        "keywords": [
            "high",
            "brightness",
            "sun",
            "light",
            "高い",
            "輝度",
            "太陽",
            "ライト"
        ],
        "skins": [
            {
                "unified": "1f506",
                "native": "🔆"
            }
        ],
        "version": 1
    },
    "signal_strength": {
        "id": "signal_strength",
        "name": "Antenna Bars",
        "keywords": [
            "signal",
            "strength",
            "blue",
            "square",
            "reception",
            "phone",
            "internet",
            "connection",
            "wifi",
            "bluetooth",
            "信号",
            "強さ",
            "青",
            "四角",
            "受信",
            "電話",
            "インターネット",
            "繋がり",
            "Wi-Fi",
            "ブルートゥース"
        ],
        "skins": [
            {
                "unified": "1f4f6",
                "native": "📶"
            }
        ],
        "version": 1
    },
    "vibration_mode": {
        "id": "vibration_mode",
        "name": "Vibration Mode",
        "keywords": [
            "orange",
            "square",
            "phone",
            "オレンジ",
            "四角",
            "電話"
        ],
        "skins": [
            {
                "unified": "1f4f3",
                "native": "📳"
            }
        ],
        "version": 1
    },
    "mobile_phone_off": {
        "id": "mobile_phone_off",
        "name": "Mobile Phone off",
        "keywords": [
            "mute",
            "orange",
            "square",
            "silence",
            "quiet",
            "無音",
            "オレンジ",
            "四角",
            "沈黙",
            "静かな"
        ],
        "skins": [
            {
                "unified": "1f4f4",
                "native": "📴"
            }
        ],
        "version": 1
    },
    "female_sign": {
        "id": "female_sign",
        "name": "Female Sign",
        "keywords": [
            "woman",
            "women",
            "lady",
            "girl",
            "女性",
            "女性",
            "レディ",
            "女の子"
        ],
        "skins": [
            {
                "unified": "2640-fe0f",
                "native": "♀️"
            }
        ],
        "version": 4
    },
    "male_sign": {
        "id": "male_sign",
        "name": "Male Sign",
        "keywords": [
            "man",
            "boy",
            "men",
            "男",
            "男の子",
            "男性"
        ],
        "skins": [
            {
                "unified": "2642-fe0f",
                "native": "♂️"
            }
        ],
        "version": 4
    },
    "transgender_symbol": {
        "id": "transgender_symbol",
        "name": "Transgender Symbol",
        "keywords": [
            "lgbtq",
            "LGBTQ"
        ],
        "skins": [
            {
                "unified": "26a7-fe0f",
                "native": "⚧️"
            }
        ],
        "version": 13
    },
    "heavy_multiplication_x": {
        "id": "heavy_multiplication_x",
        "name": "Multiply",
        "keywords": [
            "heavy",
            "multiplication",
            "x",
            "sign",
            "math",
            "calculation",
            "重い",
            "乗算",
            "バツ",
            "サイン",
            "算数",
            "計算"
        ],
        "skins": [
            {
                "unified": "2716-fe0f",
                "native": "✖️"
            }
        ],
        "version": 1
    },
    "heavy_plus_sign": {
        "id": "heavy_plus_sign",
        "name": "Plus",
        "keywords": [
            "heavy",
            "sign",
            "math",
            "calculation",
            "addition",
            "more",
            "increase",
            "重い",
            "サイン",
            "算数",
            "計算",
            "添加",
            "もっと",
            "増加"
        ],
        "skins": [
            {
                "unified": "2795",
                "native": "➕"
            }
        ],
        "version": 1
    },
    "heavy_minus_sign": {
        "id": "heavy_minus_sign",
        "name": "Minus",
        "keywords": [
            "heavy",
            "sign",
            "math",
            "calculation",
            "subtract",
            "less",
            "重い",
            "サイン",
            "算数",
            "計算",
            "減算",
            "以下"
        ],
        "skins": [
            {
                "unified": "2796",
                "native": "➖"
            }
        ],
        "version": 1
    },
    "heavy_division_sign": {
        "id": "heavy_division_sign",
        "name": "Divide",
        "keywords": [
            "heavy",
            "division",
            "sign",
            "math",
            "calculation",
            "重い",
            "分割",
            "サイン",
            "算数",
            "計算"
        ],
        "skins": [
            {
                "unified": "2797",
                "native": "➗"
            }
        ],
        "version": 1
    },
    "heavy_equals_sign": {
        "id": "heavy_equals_sign",
        "name": "Heavy Equals Sign",
        "keywords": [
            "math",
            "算数"
        ],
        "skins": [
            {
                "unified": "1f7f0",
                "native": "🟰"
            }
        ],
        "version": 14
    },
    "infinity": {
        "id": "infinity",
        "name": "Infinity",
        "keywords": [
            "forever",
            "永遠に"
        ],
        "skins": [
            {
                "unified": "267e-fe0f",
                "native": "♾️"
            }
        ],
        "version": 11
    },
    "bangbang": {
        "id": "bangbang",
        "name": "Double Exclamation Mark",
        "keywords": [
            "bangbang",
            "surprise",
            "バンバン",
            "サプライズ"
        ],
        "skins": [
            {
                "unified": "203c-fe0f",
                "native": "‼️"
            }
        ],
        "version": 1
    },
    "interrobang": {
        "id": "interrobang",
        "name": "Exclamation Question Mark",
        "keywords": [
            "interrobang",
            "wat",
            "punctuation",
            "surprise",
            "インターロバン",
            "ワット",
            "句読点",
            "サプライズ"
        ],
        "skins": [
            {
                "unified": "2049-fe0f",
                "native": "⁉️"
            }
        ],
        "version": 1
    },
    "question": {
        "id": "question",
        "name": "Red Question Mark",
        "keywords": [
            "doubt",
            "confused",
            "疑い",
            "混乱している"
        ],
        "skins": [
            {
                "unified": "2753",
                "native": "❓"
            }
        ],
        "version": 1
    },
    "grey_question": {
        "id": "grey_question",
        "name": "White Question Mark",
        "keywords": [
            "grey",
            "doubts",
            "gray",
            "huh",
            "confused",
            "グレー",
            "疑問",
            "グレー",
            "は",
            "混乱している"
        ],
        "skins": [
            {
                "unified": "2754",
                "native": "❔"
            }
        ],
        "version": 1
    },
    "grey_exclamation": {
        "id": "grey_exclamation",
        "name": "White Exclamation Mark",
        "keywords": [
            "grey",
            "surprise",
            "punctuation",
            "gray",
            "wow",
            "warning",
            "グレー",
            "サプライズ",
            "句読点",
            "グレー",
            "おお",
            "警告"
        ],
        "skins": [
            {
                "unified": "2755",
                "native": "❕"
            }
        ],
        "version": 1
    },
    "exclamation": {
        "id": "exclamation",
        "name": "Red Exclamation Mark",
        "keywords": [
            "heavy",
            "danger",
            "surprise",
            "punctuation",
            "wow",
            "warning",
            "重い",
            "危険",
            "サプライズ",
            "句読点",
            "おお",
            "警告"
        ],
        "skins": [
            {
                "unified": "2757",
                "native": "❗"
            }
        ],
        "version": 1
    },
    "wavy_dash": {
        "id": "wavy_dash",
        "name": "Wavy Dash",
        "keywords": [
            "draw",
            "line",
            "moustache",
            "mustache",
            "squiggle",
            "scribble",
            "描く",
            "ライン",
            "口ひげ",
            "口ひげ",
            "くねくね",
            "らくがき"
        ],
        "skins": [
            {
                "unified": "3030-fe0f",
                "native": "〰️"
            }
        ],
        "version": 1
    },
    "currency_exchange": {
        "id": "currency_exchange",
        "name": "Currency Exchange",
        "keywords": [
            "money",
            "sales",
            "dollar",
            "travel",
            "お金",
            "販売",
            "ドル",
            "旅行"
        ],
        "skins": [
            {
                "unified": "1f4b1",
                "native": "💱"
            }
        ],
        "version": 1
    },
    "heavy_dollar_sign": {
        "id": "heavy_dollar_sign",
        "name": "Heavy Dollar Sign",
        "keywords": [
            "money",
            "sales",
            "payment",
            "currency",
            "buck",
            "お金",
            "販売",
            "支払い",
            "通貨",
            "バック"
        ],
        "skins": [
            {
                "unified": "1f4b2",
                "native": "💲"
            }
        ],
        "version": 1
    },
    "medical_symbol": {
        "id": "medical_symbol",
        "name": "Medical Symbol",
        "keywords": [
            "staff",
            "of",
            "aesculapius",
            "health",
            "hospital",
            "スタッフ",
            "の",
            "アスクレピオス",
            "健康",
            "病院"
        ],
        "skins": [
            {
                "unified": "2695-fe0f",
                "native": "⚕️"
            }
        ],
        "version": 4
    },
    "recycle": {
        "id": "recycle",
        "name": "Recycling Symbol",
        "keywords": [
            "recycle",
            "arrow",
            "environment",
            "garbage",
            "trash",
            "リサイクル",
            "矢印",
            "環境",
            "ごみ",
            "ごみ"
        ],
        "skins": [
            {
                "unified": "267b-fe0f",
                "native": "♻️"
            }
        ],
        "version": 1
    },
    "fleur_de_lis": {
        "id": "fleur_de_lis",
        "name": "Fleur-De-Lis",
        "keywords": [
            "fleur",
            "de",
            "lis",
            "decorative",
            "scout",
            "フルール",
            "デ",
            "リス",
            "装飾的",
            "スカウト"
        ],
        "skins": [
            {
                "unified": "269c-fe0f",
                "native": "⚜️"
            }
        ],
        "version": 1
    },
    "trident": {
        "id": "trident",
        "name": "Trident Emblem",
        "keywords": [
            "weapon",
            "spear",
            "武器",
            "槍"
        ],
        "skins": [
            {
                "unified": "1f531",
                "native": "🔱"
            }
        ],
        "version": 1
    },
    "name_badge": {
        "id": "name_badge",
        "name": "Name Badge",
        "keywords": [
            "fire",
            "forbid",
            "火",
            "禁止する"
        ],
        "skins": [
            {
                "unified": "1f4db",
                "native": "📛"
            }
        ],
        "version": 1
    },
    "beginner": {
        "id": "beginner",
        "name": "Japanese Symbol for Beginner",
        "keywords": [
            "badge",
            "shield",
            "バッジ",
            "シールド"
        ],
        "skins": [
            {
                "unified": "1f530",
                "native": "🔰"
            }
        ],
        "version": 1
    },
    "o": {
        "id": "o",
        "name": "Hollow Red Circle",
        "keywords": [
            "o",
            "round",
            "お",
            "ラウンド"
        ],
        "skins": [
            {
                "unified": "2b55",
                "native": "⭕"
            }
        ],
        "version": 1
    },
    "white_check_mark": {
        "id": "white_check_mark",
        "name": "Check Mark Button",
        "keywords": [
            "white",
            "green",
            "square",
            "ok",
            "agree",
            "vote",
            "election",
            "answer",
            "tick",
            "白",
            "緑",
            "四角",
            "Ok",
            "同意",
            "投票",
            "選挙",
            "答え",
            "ダニ"
        ],
        "skins": [
            {
                "unified": "2705",
                "native": "✅"
            }
        ],
        "version": 1
    },
    "ballot_box_with_check": {
        "id": "ballot_box_with_check",
        "name": "Check Box with Check",
        "keywords": [
            "ballot",
            "ok",
            "agree",
            "confirm",
            "black",
            "square",
            "vote",
            "election",
            "yes",
            "tick",
            "投票",
            "Ok",
            "同意",
            "確認",
            "黒",
            "四角",
            "投票",
            "選挙",
            "はい",
            "ダニ"
        ],
        "skins": [
            {
                "unified": "2611-fe0f",
                "native": "☑️"
            }
        ],
        "version": 1
    },
    "heavy_check_mark": {
        "id": "heavy_check_mark",
        "name": "Check Mark",
        "keywords": [
            "heavy",
            "ok",
            "nike",
            "answer",
            "yes",
            "tick",
            "重い",
            "Ok",
            "ナイキ",
            "答え",
            "はい",
            "ダニ"
        ],
        "skins": [
            {
                "unified": "2714-fe0f",
                "native": "✔️"
            }
        ],
        "version": 1
    },
    "x": {
        "id": "x",
        "name": "Cross Mark",
        "keywords": [
            "x",
            "no",
            "delete",
            "remove",
            "cancel",
            "red",
            "バツ",
            "いいえ",
            "消去",
            "削除",
            "キャンセル",
            "赤"
        ],
        "skins": [
            {
                "unified": "274c",
                "native": "❌"
            }
        ],
        "version": 1
    },
    "negative_squared_cross_mark": {
        "id": "negative_squared_cross_mark",
        "name": "Cross Mark Button",
        "keywords": [
            "negative",
            "squared",
            "x",
            "green",
            "square",
            "no",
            "deny",
            "ネガティブ",
            "二乗",
            "バツ",
            "緑",
            "四角",
            "いいえ",
            "拒否"
        ],
        "skins": [
            {
                "unified": "274e",
                "native": "❎"
            }
        ],
        "version": 1
    },
    "curly_loop": {
        "id": "curly_loop",
        "name": "Curly Loop",
        "keywords": [
            "scribble",
            "draw",
            "shape",
            "squiggle",
            "落書き",
            "描く",
            "形",
            "くねくね"
        ],
        "skins": [
            {
                "unified": "27b0",
                "native": "➰"
            }
        ],
        "version": 1
    },
    "loop": {
        "id": "loop",
        "name": "Double Curly Loop",
        "keywords": [
            "tape",
            "cassette",
            "テープ",
            "カセット"
        ],
        "skins": [
            {
                "unified": "27bf",
                "native": "➿"
            }
        ],
        "version": 1
    },
    "part_alternation_mark": {
        "id": "part_alternation_mark",
        "name": "Part Alternation Mark",
        "keywords": [
            "graph",
            "presentation",
            "stats",
            "business",
            "economics",
            "bad",
            "グラフ",
            "プレゼンテーション",
            "統計",
            "仕事",
            "経済",
            "悪い"
        ],
        "skins": [
            {
                "unified": "303d-fe0f",
                "native": "〽️"
            }
        ],
        "version": 1
    },
    "eight_spoked_asterisk": {
        "id": "eight_spoked_asterisk",
        "name": "Eight Spoked Asterisk",
        "keywords": [
            "star",
            "sparkle",
            "green",
            "square",
            "星",
            "輝く",
            "緑",
            "四角"
        ],
        "skins": [
            {
                "unified": "2733-fe0f",
                "native": "✳️"
            }
        ],
        "version": 1
    },
    "eight_pointed_black_star": {
        "id": "eight_pointed_black_star",
        "name": "Eight-Pointed Star",
        "keywords": [
            "eight",
            "pointed",
            "black",
            "orange",
            "square",
            "shape",
            "polygon",
            "八",
            "とがった",
            "黒",
            "オレンジ",
            "四角",
            "形",
            "ポリゴン"
        ],
        "skins": [
            {
                "unified": "2734-fe0f",
                "native": "✴️"
            }
        ],
        "version": 1
    },
    "sparkle": {
        "id": "sparkle",
        "name": "Sparkle",
        "keywords": [
            "stars",
            "green",
            "square",
            "awesome",
            "good",
            "fireworks",
            "出演者",
            "緑",
            "四角",
            "素晴らしい",
            "良い",
            "花火"
        ],
        "skins": [
            {
                "unified": "2747-fe0f",
                "native": "❇️"
            }
        ],
        "version": 1
    },
    "copyright": {
        "id": "copyright",
        "name": "Copyright",
        "keywords": [
            "ip",
            "license",
            "circle",
            "law",
            "legal",
            "IP",
            "ライセンス",
            "丸",
            "法",
            "法的"
        ],
        "skins": [
            {
                "unified": "00a9-fe0f",
                "native": "©️"
            }
        ],
        "version": 1
    },
    "registered": {
        "id": "registered",
        "name": "Registered",
        "keywords": [
            "alphabet",
            "circle",
            "アルファベット",
            "丸"
        ],
        "skins": [
            {
                "unified": "00ae-fe0f",
                "native": "®️"
            }
        ],
        "version": 1
    },
    "tm": {
        "id": "tm",
        "name": "Trade Mark",
        "keywords": [
            "tm",
            "trademark",
            "brand",
            "law",
            "legal",
            "tm",
            "商標",
            "ブランド",
            "法",
            "法的"
        ],
        "skins": [
            {
                "unified": "2122-fe0f",
                "native": "™️"
            }
        ],
        "version": 1
    },
    "hash": {
        "id": "hash",
        "name": "Hash Key",
        "keywords": [
            "keycap",
            "",
            "symbol",
            "blue",
            "square",
            "twitter",
            "キーキャップ",
            "",
            "シンボル",
            "青",
            "四角",
            "ツイッター"
        ],
        "skins": [
            {
                "unified": "0023-fe0f-20e3",
                "native": "#️⃣"
            }
        ],
        "version": 1
    },
    "keycap_star": {
        "id": "keycap_star",
        "name": "Keycap: *",
        "keywords": [
            "keycap",
            "star",
            "",
            "キーキャップ",
            "星",
            ""
        ],
        "skins": [
            {
                "unified": "002a-fe0f-20e3",
                "native": "*️⃣"
            }
        ],
        "version": 2
    },
    "zero": {
        "id": "zero",
        "name": "Keycap 0",
        "keywords": [
            "zero",
            "numbers",
            "blue",
            "square",
            "null",
            "ゼロ",
            "数字",
            "青",
            "四角",
            "ヌル"
        ],
        "skins": [
            {
                "unified": "0030-fe0f-20e3",
                "native": "0️⃣"
            }
        ],
        "version": 1
    },
    "one": {
        "id": "one",
        "name": "Keycap 1",
        "keywords": [
            "one",
            "blue",
            "square",
            "numbers",
            "一",
            "青",
            "四角",
            "数字"
        ],
        "skins": [
            {
                "unified": "0031-fe0f-20e3",
                "native": "1️⃣"
            }
        ],
        "version": 1
    },
    "two": {
        "id": "two",
        "name": "Keycap 2",
        "keywords": [
            "two",
            "numbers",
            "prime",
            "blue",
            "square",
            "二",
            "数字",
            "プライム",
            "青",
            "四角"
        ],
        "skins": [
            {
                "unified": "0032-fe0f-20e3",
                "native": "2️⃣"
            }
        ],
        "version": 1
    },
    "three": {
        "id": "three",
        "name": "Keycap 3",
        "keywords": [
            "three",
            "numbers",
            "prime",
            "blue",
            "square",
            "三つ",
            "数字",
            "プライム",
            "青",
            "四角"
        ],
        "skins": [
            {
                "unified": "0033-fe0f-20e3",
                "native": "3️⃣"
            }
        ],
        "version": 1
    },
    "four": {
        "id": "four",
        "name": "Keycap 4",
        "keywords": [
            "four",
            "numbers",
            "blue",
            "square",
            "四",
            "数字",
            "青",
            "四角"
        ],
        "skins": [
            {
                "unified": "0034-fe0f-20e3",
                "native": "4️⃣"
            }
        ],
        "version": 1
    },
    "five": {
        "id": "five",
        "name": "Keycap 5",
        "keywords": [
            "five",
            "numbers",
            "blue",
            "square",
            "prime",
            "五",
            "数字",
            "青",
            "四角",
            "プライム"
        ],
        "skins": [
            {
                "unified": "0035-fe0f-20e3",
                "native": "5️⃣"
            }
        ],
        "version": 1
    },
    "six": {
        "id": "six",
        "name": "Keycap 6",
        "keywords": [
            "six",
            "numbers",
            "blue",
            "square",
            "六",
            "数字",
            "青",
            "四角"
        ],
        "skins": [
            {
                "unified": "0036-fe0f-20e3",
                "native": "6️⃣"
            }
        ],
        "version": 1
    },
    "seven": {
        "id": "seven",
        "name": "Keycap 7",
        "keywords": [
            "seven",
            "numbers",
            "blue",
            "square",
            "prime",
            "セブン",
            "数字",
            "青",
            "四角",
            "プライム"
        ],
        "skins": [
            {
                "unified": "0037-fe0f-20e3",
                "native": "7️⃣"
            }
        ],
        "version": 1
    },
    "eight": {
        "id": "eight",
        "name": "Keycap 8",
        "keywords": [
            "eight",
            "blue",
            "square",
            "numbers",
            "八",
            "青",
            "四角",
            "数字"
        ],
        "skins": [
            {
                "unified": "0038-fe0f-20e3",
                "native": "8️⃣"
            }
        ],
        "version": 1
    },
    "nine": {
        "id": "nine",
        "name": "Keycap 9",
        "keywords": [
            "nine",
            "blue",
            "square",
            "numbers",
            "九",
            "青",
            "四角",
            "数字"
        ],
        "skins": [
            {
                "unified": "0039-fe0f-20e3",
                "native": "9️⃣"
            }
        ],
        "version": 1
    },
    "keycap_ten": {
        "id": "keycap_ten",
        "name": "Keycap 10",
        "keywords": [
            "ten",
            "numbers",
            "blue",
            "square",
            "十",
            "数字",
            "青",
            "四角"
        ],
        "skins": [
            {
                "unified": "1f51f",
                "native": "🔟"
            }
        ],
        "version": 1
    },
    "capital_abcd": {
        "id": "capital_abcd",
        "name": "Input Latin Uppercase",
        "keywords": [
            "capital",
            "abcd",
            "alphabet",
            "words",
            "blue",
            "square",
            "資本",
            "あいうえお",
            "アルファベット",
            "言葉",
            "青",
            "四角"
        ],
        "skins": [
            {
                "unified": "1f520",
                "native": "🔠"
            }
        ],
        "version": 1
    },
    "abcd": {
        "id": "abcd",
        "name": "Input Latin Lowercase",
        "keywords": [
            "abcd",
            "blue",
            "square",
            "alphabet",
            "あいうえお",
            "青",
            "四角",
            "アルファベット"
        ],
        "skins": [
            {
                "unified": "1f521",
                "native": "🔡"
            }
        ],
        "version": 1
    },
    "symbols": {
        "id": "symbols",
        "name": "Input Symbols",
        "keywords": [
            "blue",
            "square",
            "music",
            "note",
            "ampersand",
            "percent",
            "glyphs",
            "characters",
            "青",
            "四角",
            "音楽",
            "ノート",
            "アンパサンド",
            "パーセント",
            "グリフ",
            "文字"
        ],
        "skins": [
            {
                "unified": "1f523",
                "native": "🔣"
            }
        ],
        "version": 1
    },
    "abc": {
        "id": "abc",
        "name": "Input Latin Letters",
        "keywords": [
            "abc",
            "blue",
            "square",
            "alphabet",
            "abc",
            "青",
            "四角",
            "アルファベット"
        ],
        "skins": [
            {
                "unified": "1f524",
                "native": "🔤"
            }
        ],
        "version": 1
    },
    "a": {
        "id": "a",
        "name": "A Button (blood Type)",
        "keywords": [
            "red",
            "square",
            "alphabet",
            "letter",
            "赤",
            "四角",
            "アルファベット",
            "手紙"
        ],
        "skins": [
            {
                "unified": "1f170-fe0f",
                "native": "🅰️"
            }
        ],
        "version": 1
    },
    "ab": {
        "id": "ab",
        "name": "Negative Squared Ab",
        "keywords": [
            "button",
            "red",
            "square",
            "alphabet",
            "ボタン",
            "赤",
            "四角",
            "アルファベット"
        ],
        "skins": [
            {
                "unified": "1f18e",
                "native": "🆎"
            }
        ],
        "version": 1
    },
    "b": {
        "id": "b",
        "name": "B Button (blood Type)",
        "keywords": [
            "red",
            "square",
            "alphabet",
            "letter",
            "赤",
            "四角",
            "アルファベット",
            "手紙"
        ],
        "skins": [
            {
                "unified": "1f171-fe0f",
                "native": "🅱️"
            }
        ],
        "version": 1
    },
    "cl": {
        "id": "cl",
        "name": "Cl Button",
        "keywords": [
            "alphabet",
            "words",
            "red",
            "square",
            "アルファベット",
            "言葉",
            "赤",
            "四角"
        ],
        "skins": [
            {
                "unified": "1f191",
                "native": "🆑"
            }
        ],
        "version": 1
    },
    "cool": {
        "id": "cool",
        "name": "Cool Button",
        "keywords": [
            "words",
            "blue",
            "square",
            "言葉",
            "青",
            "四角"
        ],
        "skins": [
            {
                "unified": "1f192",
                "native": "🆒"
            }
        ],
        "version": 1
    },
    "free": {
        "id": "free",
        "name": "Free Button",
        "keywords": [
            "blue",
            "square",
            "words",
            "青",
            "四角",
            "言葉"
        ],
        "skins": [
            {
                "unified": "1f193",
                "native": "🆓"
            }
        ],
        "version": 1
    },
    "information_source": {
        "id": "information_source",
        "name": "Information",
        "keywords": [
            "source",
            "blue",
            "square",
            "alphabet",
            "letter",
            "ソース",
            "青",
            "四角",
            "アルファベット",
            "手紙"
        ],
        "skins": [
            {
                "unified": "2139-fe0f",
                "native": "ℹ️"
            }
        ],
        "version": 1
    },
    "id": {
        "id": "id",
        "name": "Id Button",
        "keywords": [
            "purple",
            "square",
            "words",
            "紫",
            "四角",
            "言葉"
        ],
        "skins": [
            {
                "unified": "1f194",
                "native": "🆔"
            }
        ],
        "version": 1
    },
    "m": {
        "id": "m",
        "name": "Circled M",
        "keywords": [
            "alphabet",
            "blue",
            "circle",
            "letter",
            "アルファベット",
            "青",
            "丸",
            "手紙"
        ],
        "skins": [
            {
                "unified": "24c2-fe0f",
                "native": "Ⓜ️"
            }
        ],
        "version": 1
    },
    "new": {
        "id": "new",
        "name": "New Button",
        "keywords": [
            "blue",
            "square",
            "words",
            "start",
            "青",
            "四角",
            "言葉",
            "始める"
        ],
        "skins": [
            {
                "unified": "1f195",
                "native": "🆕"
            }
        ],
        "version": 1
    },
    "ng": {
        "id": "ng",
        "name": "Ng Button",
        "keywords": [
            "blue",
            "square",
            "words",
            "shape",
            "icon",
            "青",
            "四角",
            "言葉",
            "形",
            "アイコン"
        ],
        "skins": [
            {
                "unified": "1f196",
                "native": "🆖"
            }
        ],
        "version": 1
    },
    "o2": {
        "id": "o2",
        "name": "O Button (blood Type)",
        "keywords": [
            "o2",
            "alphabet",
            "red",
            "square",
            "letter",
            "o2",
            "アルファベット",
            "赤",
            "四角",
            "手紙"
        ],
        "skins": [
            {
                "unified": "1f17e-fe0f",
                "native": "🅾️"
            }
        ],
        "version": 1
    },
    "ok": {
        "id": "ok",
        "name": "Ok Button",
        "keywords": [
            "good",
            "agree",
            "yes",
            "blue",
            "square",
            "良い",
            "同意",
            "はい",
            "青",
            "四角"
        ],
        "skins": [
            {
                "unified": "1f197",
                "native": "🆗"
            }
        ],
        "version": 1
    },
    "parking": {
        "id": "parking",
        "name": "P Button",
        "keywords": [
            "parking",
            "cars",
            "blue",
            "square",
            "alphabet",
            "letter",
            "パーキング",
            "車",
            "青",
            "四角",
            "アルファベット",
            "手紙"
        ],
        "skins": [
            {
                "unified": "1f17f-fe0f",
                "native": "🅿️"
            }
        ],
        "version": 1
    },
    "sos": {
        "id": "sos",
        "name": "Sos Button",
        "keywords": [
            "help",
            "red",
            "square",
            "words",
            "emergency",
            "911",
            "ヘルプ",
            "赤",
            "四角",
            "言葉",
            "緊急",
            "911"
        ],
        "skins": [
            {
                "unified": "1f198",
                "native": "🆘"
            }
        ],
        "version": 1
    },
    "up": {
        "id": "up",
        "name": "Up! Button",
        "keywords": [
            "up",
            "blue",
            "square",
            "above",
            "high",
            "上",
            "青",
            "四角",
            "その上",
            "高い"
        ],
        "skins": [
            {
                "unified": "1f199",
                "native": "🆙"
            }
        ],
        "version": 1
    },
    "vs": {
        "id": "vs",
        "name": "Vs Button",
        "keywords": [
            "words",
            "orange",
            "square",
            "言葉",
            "オレンジ",
            "四角"
        ],
        "skins": [
            {
                "unified": "1f19a",
                "native": "🆚"
            }
        ],
        "version": 1
    },
    "koko": {
        "id": "koko",
        "name": "Squared Katakana Koko",
        "keywords": [
            "japanese",
            "here",
            "button",
            "blue",
            "square",
            "destination",
            "日本",
            "ここ",
            "ボタン",
            "青",
            "四角",
            "行き先"
        ],
        "skins": [
            {
                "unified": "1f201",
                "native": "🈁"
            }
        ],
        "version": 1
    },
    "sa": {
        "id": "sa",
        "name": "Squared Katakana Sa",
        "keywords": [
            "japanese",
            "service",
            "charge",
            "button",
            "blue",
            "square",
            "日本",
            "サービス",
            "充電",
            "ボタン",
            "青",
            "四角"
        ],
        "skins": [
            {
                "unified": "1f202-fe0f",
                "native": "🈂️"
            }
        ],
        "version": 1
    },
    "u6708": {
        "id": "u6708",
        "name": "Japanese “monthly Amount” Button",
        "keywords": [
            "u6708",
            "monthly",
            "amount",
            "chinese",
            "month",
            "moon",
            "orange",
            "square",
            "kanji",
            "u6708",
            "毎月",
            "額",
            "中国語",
            "月",
            "月",
            "オレンジ",
            "四角",
            "漢字"
        ],
        "skins": [
            {
                "unified": "1f237-fe0f",
                "native": "🈷️"
            }
        ],
        "version": 1
    },
    "u6709": {
        "id": "u6709",
        "name": "Squared Cjk Unified Ideograph-6709",
        "keywords": [
            "u6709",
            "japanese",
            "not",
            "free",
            "of",
            "charge",
            "button",
            "orange",
            "square",
            "chinese",
            "have",
            "kanji",
            "u6709",
            "日本",
            "いいえ",
            "無料",
            "の",
            "充電",
            "ボタン",
            "オレンジ",
            "四角",
            "中国語",
            "もつ",
            "漢字"
        ],
        "skins": [
            {
                "unified": "1f236",
                "native": "🈶"
            }
        ],
        "version": 1
    },
    "u6307": {
        "id": "u6307",
        "name": "Japanese “reserved” Button",
        "keywords": [
            "u6307",
            "reserved",
            "chinese",
            "point",
            "green",
            "square",
            "kanji",
            "u6307",
            "予約済み",
            "中国語",
            "点",
            "緑",
            "四角",
            "漢字"
        ],
        "skins": [
            {
                "unified": "1f22f",
                "native": "🈯"
            }
        ],
        "version": 1
    },
    "ideograph_advantage": {
        "id": "ideograph_advantage",
        "name": "Japanese “bargain” Button",
        "keywords": [
            "ideograph",
            "advantage",
            "bargain",
            "chinese",
            "kanji",
            "obtain",
            "get",
            "circle",
            "表意文字",
            "アドバンテージ",
            "バーゲン",
            "中国語",
            "漢字",
            "入手",
            "得る",
            "丸"
        ],
        "skins": [
            {
                "unified": "1f250",
                "native": "🉐"
            }
        ],
        "version": 1
    },
    "u5272": {
        "id": "u5272",
        "name": "Japanese “discount” Button",
        "keywords": [
            "u5272",
            "discount",
            "cut",
            "divide",
            "chinese",
            "kanji",
            "pink",
            "square",
            "u5272",
            "割引",
            "切る",
            "分ける",
            "中国語",
            "漢字",
            "ピンク",
            "四角"
        ],
        "skins": [
            {
                "unified": "1f239",
                "native": "🈹"
            }
        ],
        "version": 1
    },
    "u7121": {
        "id": "u7121",
        "name": "Japanese “free of Charge” Button",
        "keywords": [
            "u7121",
            "free",
            "charge",
            "nothing",
            "chinese",
            "kanji",
            "orange",
            "square",
            "u7121",
            "無料",
            "充電",
            "なし",
            "中国語",
            "漢字",
            "オレンジ",
            "四角"
        ],
        "skins": [
            {
                "unified": "1f21a",
                "native": "🈚"
            }
        ],
        "version": 1
    },
    "u7981": {
        "id": "u7981",
        "name": "Japanese “prohibited” Button",
        "keywords": [
            "u7981",
            "prohibited",
            "kanji",
            "chinese",
            "forbidden",
            "limit",
            "restricted",
            "red",
            "square",
            "u7981",
            "禁止",
            "漢字",
            "中国語",
            "禁断",
            "限界",
            "制限付き",
            "赤",
            "四角"
        ],
        "skins": [
            {
                "unified": "1f232",
                "native": "🈲"
            }
        ],
        "version": 1
    },
    "accept": {
        "id": "accept",
        "name": "Circled Ideograph Accept",
        "keywords": [
            "japanese",
            "acceptable",
            "button",
            "ok",
            "good",
            "chinese",
            "kanji",
            "agree",
            "yes",
            "orange",
            "circle",
            "日本",
            "許容できる",
            "ボタン",
            "Ok",
            "良い",
            "中国語",
            "漢字",
            "同意",
            "はい",
            "オレンジ",
            "丸"
        ],
        "skins": [
            {
                "unified": "1f251",
                "native": "🉑"
            }
        ],
        "version": 1
    },
    "u7533": {
        "id": "u7533",
        "name": "Japanese “application” Button",
        "keywords": [
            "u7533",
            "application",
            "chinese",
            "kanji",
            "orange",
            "square",
            "u7533",
            "応用",
            "中国語",
            "漢字",
            "オレンジ",
            "四角"
        ],
        "skins": [
            {
                "unified": "1f238",
                "native": "🈸"
            }
        ],
        "version": 1
    },
    "u5408": {
        "id": "u5408",
        "name": "Japanese “passing Grade” Button",
        "keywords": [
            "u5408",
            "passing",
            "grade",
            "chinese",
            "join",
            "kanji",
            "red",
            "square",
            "u5408",
            "通過",
            "学年",
            "中国語",
            "加入",
            "漢字",
            "赤",
            "四角"
        ],
        "skins": [
            {
                "unified": "1f234",
                "native": "🈴"
            }
        ],
        "version": 1
    },
    "u7a7a": {
        "id": "u7a7a",
        "name": "Japanese “vacancy” Button",
        "keywords": [
            "u7a7a",
            "vacancy",
            "kanji",
            "chinese",
            "empty",
            "sky",
            "blue",
            "square",
            "u7a7a",
            "欠員",
            "漢字",
            "中国語",
            "空",
            "空",
            "青",
            "四角"
        ],
        "skins": [
            {
                "unified": "1f233",
                "native": "🈳"
            }
        ],
        "version": 1
    },
    "congratulations": {
        "id": "congratulations",
        "name": "Circled Ideograph Congratulation",
        "keywords": [
            "congratulations",
            "japanese",
            "button",
            "chinese",
            "kanji",
            "red",
            "circle",
            "おめでとう",
            "日本",
            "ボタン",
            "中国語",
            "漢字",
            "赤",
            "丸"
        ],
        "skins": [
            {
                "unified": "3297-fe0f",
                "native": "㊗️"
            }
        ],
        "version": 1
    },
    "secret": {
        "id": "secret",
        "name": "Circled Ideograph Secret",
        "keywords": [
            "japanese",
            "button",
            "privacy",
            "chinese",
            "sshh",
            "kanji",
            "red",
            "circle",
            "日本",
            "ボタン",
            "プライバシー",
            "中国語",
            "シーッ",
            "漢字",
            "赤",
            "丸"
        ],
        "skins": [
            {
                "unified": "3299-fe0f",
                "native": "㊙️"
            }
        ],
        "version": 1
    },
    "u55b6": {
        "id": "u55b6",
        "name": "Squared Cjk Unified Ideograph-55b6",
        "keywords": [
            "u55b6",
            "japanese",
            "open",
            "for",
            "business",
            "button",
            "opening",
            "hours",
            "orange",
            "square",
            "u55b6",
            "日本",
            "開ける",
            "ために",
            "仕事",
            "ボタン",
            "オープニング",
            "時間",
            "オレンジ",
            "四角"
        ],
        "skins": [
            {
                "unified": "1f23a",
                "native": "🈺"
            }
        ],
        "version": 1
    },
    "u6e80": {
        "id": "u6e80",
        "name": "Japanese “no Vacancy” Button",
        "keywords": [
            "u6e80",
            "no",
            "vacancy",
            "full",
            "chinese",
            "red",
            "square",
            "kanji",
            "u6e80",
            "いいえ",
            "欠員",
            "満杯",
            "中国語",
            "赤",
            "四角",
            "漢字"
        ],
        "skins": [
            {
                "unified": "1f235",
                "native": "🈵"
            }
        ],
        "version": 1
    },
    "red_circle": {
        "id": "red_circle",
        "name": "Red Circle",
        "keywords": [
            "shape",
            "error",
            "danger",
            "形",
            "エラー",
            "危険"
        ],
        "skins": [
            {
                "unified": "1f534",
                "native": "🔴"
            }
        ],
        "version": 1
    },
    "large_orange_circle": {
        "id": "large_orange_circle",
        "name": "Orange Circle",
        "keywords": [
            "large",
            "round",
            "大きい",
            "ラウンド"
        ],
        "skins": [
            {
                "unified": "1f7e0",
                "native": "🟠"
            }
        ],
        "version": 12
    },
    "large_yellow_circle": {
        "id": "large_yellow_circle",
        "name": "Yellow Circle",
        "keywords": [
            "large",
            "round",
            "大きい",
            "ラウンド"
        ],
        "skins": [
            {
                "unified": "1f7e1",
                "native": "🟡"
            }
        ],
        "version": 12
    },
    "large_green_circle": {
        "id": "large_green_circle",
        "name": "Green Circle",
        "keywords": [
            "large",
            "round",
            "大きい",
            "ラウンド"
        ],
        "skins": [
            {
                "unified": "1f7e2",
                "native": "🟢"
            }
        ],
        "version": 12
    },
    "large_blue_circle": {
        "id": "large_blue_circle",
        "name": "Blue Circle",
        "keywords": [
            "large",
            "shape",
            "icon",
            "button",
            "大きい",
            "形",
            "アイコン",
            "ボタン"
        ],
        "skins": [
            {
                "unified": "1f535",
                "native": "🔵"
            }
        ],
        "version": 1
    },
    "large_purple_circle": {
        "id": "large_purple_circle",
        "name": "Purple Circle",
        "keywords": [
            "large",
            "round",
            "大きい",
            "ラウンド"
        ],
        "skins": [
            {
                "unified": "1f7e3",
                "native": "🟣"
            }
        ],
        "version": 12
    },
    "large_brown_circle": {
        "id": "large_brown_circle",
        "name": "Brown Circle",
        "keywords": [
            "large",
            "round",
            "大きい",
            "ラウンド"
        ],
        "skins": [
            {
                "unified": "1f7e4",
                "native": "🟤"
            }
        ],
        "version": 12
    },
    "black_circle": {
        "id": "black_circle",
        "name": "Black Circle",
        "keywords": [
            "shape",
            "button",
            "round",
            "形",
            "ボタン",
            "ラウンド"
        ],
        "skins": [
            {
                "unified": "26ab",
                "native": "⚫"
            }
        ],
        "version": 1
    },
    "white_circle": {
        "id": "white_circle",
        "name": "White Circle",
        "keywords": [
            "shape",
            "round",
            "形",
            "ラウンド"
        ],
        "skins": [
            {
                "unified": "26aa",
                "native": "⚪"
            }
        ],
        "version": 1
    },
    "large_red_square": {
        "id": "large_red_square",
        "name": "Red Square",
        "keywords": [
            "large",
            "大きい"
        ],
        "skins": [
            {
                "unified": "1f7e5",
                "native": "🟥"
            }
        ],
        "version": 12
    },
    "large_orange_square": {
        "id": "large_orange_square",
        "name": "Orange Square",
        "keywords": [
            "large",
            "大きい"
        ],
        "skins": [
            {
                "unified": "1f7e7",
                "native": "🟧"
            }
        ],
        "version": 12
    },
    "large_yellow_square": {
        "id": "large_yellow_square",
        "name": "Yellow Square",
        "keywords": [
            "large",
            "大きい"
        ],
        "skins": [
            {
                "unified": "1f7e8",
                "native": "🟨"
            }
        ],
        "version": 12
    },
    "large_green_square": {
        "id": "large_green_square",
        "name": "Green Square",
        "keywords": [
            "large",
            "大きい"
        ],
        "skins": [
            {
                "unified": "1f7e9",
                "native": "🟩"
            }
        ],
        "version": 12
    },
    "large_blue_square": {
        "id": "large_blue_square",
        "name": "Blue Square",
        "keywords": [
            "large",
            "大きい"
        ],
        "skins": [
            {
                "unified": "1f7e6",
                "native": "🟦"
            }
        ],
        "version": 12
    },
    "large_purple_square": {
        "id": "large_purple_square",
        "name": "Purple Square",
        "keywords": [
            "large",
            "大きい"
        ],
        "skins": [
            {
                "unified": "1f7ea",
                "native": "🟪"
            }
        ],
        "version": 12
    },
    "large_brown_square": {
        "id": "large_brown_square",
        "name": "Brown Square",
        "keywords": [
            "large",
            "大きい"
        ],
        "skins": [
            {
                "unified": "1f7eb",
                "native": "🟫"
            }
        ],
        "version": 12
    },
    "black_large_square": {
        "id": "black_large_square",
        "name": "Black Large Square",
        "keywords": [
            "shape",
            "icon",
            "button",
            "形",
            "アイコン",
            "ボタン"
        ],
        "skins": [
            {
                "unified": "2b1b",
                "native": "⬛"
            }
        ],
        "version": 1
    },
    "white_large_square": {
        "id": "white_large_square",
        "name": "White Large Square",
        "keywords": [
            "shape",
            "icon",
            "stone",
            "button",
            "形",
            "アイコン",
            "結石",
            "ボタン"
        ],
        "skins": [
            {
                "unified": "2b1c",
                "native": "⬜"
            }
        ],
        "version": 1
    },
    "black_medium_square": {
        "id": "black_medium_square",
        "name": "Black Medium Square",
        "keywords": [
            "shape",
            "button",
            "icon",
            "形",
            "ボタン",
            "アイコン"
        ],
        "skins": [
            {
                "unified": "25fc-fe0f",
                "native": "◼️"
            }
        ],
        "version": 1
    },
    "white_medium_square": {
        "id": "white_medium_square",
        "name": "White Medium Square",
        "keywords": [
            "shape",
            "stone",
            "icon",
            "形",
            "結石",
            "アイコン"
        ],
        "skins": [
            {
                "unified": "25fb-fe0f",
                "native": "◻️"
            }
        ],
        "version": 1
    },
    "black_medium_small_square": {
        "id": "black_medium_small_square",
        "name": "Black Medium Small Square",
        "keywords": [
            "icon",
            "shape",
            "button",
            "アイコン",
            "形",
            "ボタン"
        ],
        "skins": [
            {
                "unified": "25fe",
                "native": "◾"
            }
        ],
        "version": 1
    },
    "white_medium_small_square": {
        "id": "white_medium_small_square",
        "name": "White Medium Small Square",
        "keywords": [
            "shape",
            "stone",
            "icon",
            "button",
            "形",
            "結石",
            "アイコン",
            "ボタン"
        ],
        "skins": [
            {
                "unified": "25fd",
                "native": "◽"
            }
        ],
        "version": 1
    },
    "black_small_square": {
        "id": "black_small_square",
        "name": "Black Small Square",
        "keywords": [
            "shape",
            "icon",
            "形",
            "アイコン"
        ],
        "skins": [
            {
                "unified": "25aa-fe0f",
                "native": "▪️"
            }
        ],
        "version": 1
    },
    "white_small_square": {
        "id": "white_small_square",
        "name": "White Small Square",
        "keywords": [
            "shape",
            "icon",
            "形",
            "アイコン"
        ],
        "skins": [
            {
                "unified": "25ab-fe0f",
                "native": "▫️"
            }
        ],
        "version": 1
    },
    "large_orange_diamond": {
        "id": "large_orange_diamond",
        "name": "Large Orange Diamond",
        "keywords": [
            "shape",
            "jewel",
            "gem",
            "形",
            "宝石",
            "宝石"
        ],
        "skins": [
            {
                "unified": "1f536",
                "native": "🔶"
            }
        ],
        "version": 1
    },
    "large_blue_diamond": {
        "id": "large_blue_diamond",
        "name": "Large Blue Diamond",
        "keywords": [
            "shape",
            "jewel",
            "gem",
            "形",
            "宝石",
            "宝石"
        ],
        "skins": [
            {
                "unified": "1f537",
                "native": "🔷"
            }
        ],
        "version": 1
    },
    "small_orange_diamond": {
        "id": "small_orange_diamond",
        "name": "Small Orange Diamond",
        "keywords": [
            "shape",
            "jewel",
            "gem",
            "形",
            "宝石",
            "宝石"
        ],
        "skins": [
            {
                "unified": "1f538",
                "native": "🔸"
            }
        ],
        "version": 1
    },
    "small_blue_diamond": {
        "id": "small_blue_diamond",
        "name": "Small Blue Diamond",
        "keywords": [
            "shape",
            "jewel",
            "gem",
            "形",
            "宝石",
            "宝石"
        ],
        "skins": [
            {
                "unified": "1f539",
                "native": "🔹"
            }
        ],
        "version": 1
    },
    "small_red_triangle": {
        "id": "small_red_triangle",
        "name": "Red Triangle Pointed Up",
        "keywords": [
            "small",
            "shape",
            "direction",
            "top",
            "小さい",
            "形",
            "方向",
            "上"
        ],
        "skins": [
            {
                "unified": "1f53a",
                "native": "🔺"
            }
        ],
        "version": 1
    },
    "small_red_triangle_down": {
        "id": "small_red_triangle_down",
        "name": "Red Triangle Pointed Down",
        "keywords": [
            "small",
            "shape",
            "direction",
            "bottom",
            "小さい",
            "形",
            "方向",
            "下"
        ],
        "skins": [
            {
                "unified": "1f53b",
                "native": "🔻"
            }
        ],
        "version": 1
    },
    "diamond_shape_with_a_dot_inside": {
        "id": "diamond_shape_with_a_dot_inside",
        "name": "Diamond with a Dot",
        "keywords": [
            "shape",
            "inside",
            "jewel",
            "blue",
            "gem",
            "crystal",
            "fancy",
            "形",
            "中身",
            "宝石",
            "青",
            "宝石",
            "結晶",
            "はで"
        ],
        "skins": [
            {
                "unified": "1f4a0",
                "native": "💠"
            }
        ],
        "version": 1
    },
    "radio_button": {
        "id": "radio_button",
        "name": "Radio Button",
        "keywords": [
            "input",
            "old",
            "music",
            "circle",
            "入力",
            "古い",
            "音楽",
            "丸"
        ],
        "skins": [
            {
                "unified": "1f518",
                "native": "🔘"
            }
        ],
        "version": 1
    },
    "white_square_button": {
        "id": "white_square_button",
        "name": "White Square Button",
        "keywords": [
            "shape",
            "input",
            "形",
            "入力"
        ],
        "skins": [
            {
                "unified": "1f533",
                "native": "🔳"
            }
        ],
        "version": 1
    },
    "black_square_button": {
        "id": "black_square_button",
        "name": "Black Square Button",
        "keywords": [
            "shape",
            "input",
            "frame",
            "形",
            "入力",
            "フレーム"
        ],
        "skins": [
            {
                "unified": "1f532",
                "native": "🔲"
            }
        ],
        "version": 1
    },
    "checkered_flag": {
        "id": "checkered_flag",
        "name": "Chequered Flag",
        "keywords": [
            "checkered",
            "contest",
            "finishline",
            "race",
            "gokart",
            "市松",
            "コンテスト",
            "ゴール",
            "人種",
            "ゴーカート"
        ],
        "skins": [
            {
                "unified": "1f3c1",
                "native": "🏁"
            }
        ],
        "version": 1
    },
    "triangular_flag_on_post": {
        "id": "triangular_flag_on_post",
        "name": "Triangular Flag",
        "keywords": [
            "on",
            "post",
            "mark",
            "milestone",
            "place",
            "の上",
            "役職",
            "マーク",
            "マイルストーン",
            "場所"
        ],
        "skins": [
            {
                "unified": "1f6a9",
                "native": "🚩"
            }
        ],
        "version": 1
    },
    "crossed_flags": {
        "id": "crossed_flags",
        "name": "Crossed Flags",
        "keywords": [
            "japanese",
            "nation",
            "country",
            "border",
            "日本",
            "国家",
            "国",
            "国境"
        ],
        "skins": [
            {
                "unified": "1f38c",
                "native": "🎌"
            }
        ],
        "version": 1
    },
    "waving_black_flag": {
        "id": "waving_black_flag",
        "name": "Black Flag",
        "keywords": [
            "waving",
            "pirate",
            "手を振る",
            "海賊"
        ],
        "skins": [
            {
                "unified": "1f3f4",
                "native": "🏴"
            }
        ],
        "version": 1
    },
    "waving_white_flag": {
        "id": "waving_white_flag",
        "name": "White Flag",
        "keywords": [
            "waving",
            "losing",
            "loser",
            "lost",
            "surrender",
            "give",
            "up",
            "fail",
            "手を振る",
            "負け",
            "敗者",
            "失った",
            "降伏",
            "与える",
            "上",
            "失敗"
        ],
        "skins": [
            {
                "unified": "1f3f3-fe0f",
                "native": "🏳️"
            }
        ],
        "version": 1
    },
    "rainbow-flag": {
        "id": "rainbow-flag",
        "name": "Rainbow Flag",
        "keywords": [
            "pride",
            "gay",
            "lgbt",
            "glbt",
            "queer",
            "homosexual",
            "lesbian",
            "bisexual",
            "transgender",
            "誇り",
            "ゲイ",
            "LGBT",
            "glbt",
            "クィア",
            "同性愛者",
            "レズビアン",
            "バイセクシャル",
            "トランスジェンダー"
        ],
        "skins": [
            {
                "unified": "1f3f3-fe0f-200d-1f308",
                "native": "🏳️‍🌈"
            }
        ],
        "version": 4
    },
    "transgender_flag": {
        "id": "transgender_flag",
        "name": "Transgender Flag",
        "keywords": [
            "lgbtq",
            "LGBTQ"
        ],
        "skins": [
            {
                "unified": "1f3f3-fe0f-200d-26a7-fe0f",
                "native": "🏳️‍⚧️"
            }
        ],
        "version": 13
    },
    "pirate_flag": {
        "id": "pirate_flag",
        "name": "Pirate Flag",
        "keywords": [
            "skull",
            "crossbones",
            "banner",
            "頭蓋骨",
            "どくろ",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f3f4-200d-2620-fe0f",
                "native": "🏴‍☠️"
            }
        ],
        "version": 11
    },
    "flag-ac": {
        "id": "flag-ac",
        "name": "Ascension Island Flag",
        "keywords": [
            "ac",
            "交流"
        ],
        "skins": [
            {
                "unified": "1f1e6-1f1e8",
                "native": "🇦🇨"
            }
        ],
        "version": 2
    },
    "flag-ad": {
        "id": "flag-ad",
        "name": "Andorra Flag",
        "keywords": [
            "ad",
            "nation",
            "country",
            "banner",
            "広告",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1e6-1f1e9",
                "native": "🇦🇩"
            }
        ],
        "version": 2
    },
    "flag-ae": {
        "id": "flag-ae",
        "name": "United Arab Emirates Flag",
        "keywords": [
            "ae",
            "nation",
            "country",
            "banner",
            "ええ",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1e6-1f1ea",
                "native": "🇦🇪"
            }
        ],
        "version": 2
    },
    "flag-af": {
        "id": "flag-af",
        "name": "Afghanistan Flag",
        "keywords": [
            "af",
            "nation",
            "country",
            "banner",
            "アフ",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1e6-1f1eb",
                "native": "🇦🇫"
            }
        ],
        "version": 2
    },
    "flag-ag": {
        "id": "flag-ag",
        "name": "Antigua & Barbuda Flag",
        "keywords": [
            "ag",
            "nation",
            "country",
            "banner",
            "ag",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1e6-1f1ec",
                "native": "🇦🇬"
            }
        ],
        "version": 2
    },
    "flag-ai": {
        "id": "flag-ai",
        "name": "Anguilla Flag",
        "keywords": [
            "ai",
            "nation",
            "country",
            "banner",
            "あい",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1e6-1f1ee",
                "native": "🇦🇮"
            }
        ],
        "version": 2
    },
    "flag-al": {
        "id": "flag-al",
        "name": "Albania Flag",
        "keywords": [
            "al",
            "nation",
            "country",
            "banner",
            "アル",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1e6-1f1f1",
                "native": "🇦🇱"
            }
        ],
        "version": 2
    },
    "flag-am": {
        "id": "flag-am",
        "name": "Armenia Flag",
        "keywords": [
            "am",
            "nation",
            "country",
            "banner",
            "午前",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1e6-1f1f2",
                "native": "🇦🇲"
            }
        ],
        "version": 2
    },
    "flag-ao": {
        "id": "flag-ao",
        "name": "Angola Flag",
        "keywords": [
            "ao",
            "nation",
            "country",
            "banner",
            "あお",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1e6-1f1f4",
                "native": "🇦🇴"
            }
        ],
        "version": 2
    },
    "flag-aq": {
        "id": "flag-aq",
        "name": "Antarctica Flag",
        "keywords": [
            "aq",
            "nation",
            "country",
            "banner",
            "アクア",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1e6-1f1f6",
                "native": "🇦🇶"
            }
        ],
        "version": 2
    },
    "flag-ar": {
        "id": "flag-ar",
        "name": "Argentina Flag",
        "keywords": [
            "ar",
            "nation",
            "country",
            "banner",
            "ar",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1e6-1f1f7",
                "native": "🇦🇷"
            }
        ],
        "version": 2
    },
    "flag-as": {
        "id": "flag-as",
        "name": "American Samoa Flag",
        "keywords": [
            "as",
            "ws",
            "nation",
            "country",
            "banner",
            "として",
            "ws",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1e6-1f1f8",
                "native": "🇦🇸"
            }
        ],
        "version": 2
    },
    "flag-at": {
        "id": "flag-at",
        "name": "Austria Flag",
        "keywords": [
            "at",
            "nation",
            "country",
            "banner",
            "で",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1e6-1f1f9",
                "native": "🇦🇹"
            }
        ],
        "version": 2
    },
    "flag-au": {
        "id": "flag-au",
        "name": "Australia Flag",
        "keywords": [
            "au",
            "nation",
            "country",
            "banner",
            "au",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1e6-1f1fa",
                "native": "🇦🇺"
            }
        ],
        "version": 2
    },
    "flag-aw": {
        "id": "flag-aw",
        "name": "Aruba Flag",
        "keywords": [
            "aw",
            "nation",
            "country",
            "banner",
            "ああ",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1e6-1f1fc",
                "native": "🇦🇼"
            }
        ],
        "version": 2
    },
    "flag-ax": {
        "id": "flag-ax",
        "name": "Åland Islands Flag",
        "keywords": [
            "ax",
            "aland",
            "Aland",
            "nation",
            "country",
            "banner",
            "斧",
            "土地",
            "土地",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1e6-1f1fd",
                "native": "🇦🇽"
            }
        ],
        "version": 2
    },
    "flag-az": {
        "id": "flag-az",
        "name": "Azerbaijan Flag",
        "keywords": [
            "az",
            "nation",
            "country",
            "banner",
            "アズ",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1e6-1f1ff",
                "native": "🇦🇿"
            }
        ],
        "version": 2
    },
    "flag-ba": {
        "id": "flag-ba",
        "name": "Bosnia & Herzegovina Flag",
        "keywords": [
            "ba",
            "nation",
            "country",
            "banner",
            "バ",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1e7-1f1e6",
                "native": "🇧🇦"
            }
        ],
        "version": 2
    },
    "flag-bb": {
        "id": "flag-bb",
        "name": "Barbados Flag",
        "keywords": [
            "bb",
            "nation",
            "country",
            "banner",
            "bb",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1e7-1f1e7",
                "native": "🇧🇧"
            }
        ],
        "version": 2
    },
    "flag-bd": {
        "id": "flag-bd",
        "name": "Bangladesh Flag",
        "keywords": [
            "bd",
            "nation",
            "country",
            "banner",
            "BD",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1e7-1f1e9",
                "native": "🇧🇩"
            }
        ],
        "version": 2
    },
    "flag-be": {
        "id": "flag-be",
        "name": "Belgium Flag",
        "keywords": [
            "be",
            "nation",
            "country",
            "banner",
            "なれ",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1e7-1f1ea",
                "native": "🇧🇪"
            }
        ],
        "version": 2
    },
    "flag-bf": {
        "id": "flag-bf",
        "name": "Burkina Faso Flag",
        "keywords": [
            "bf",
            "nation",
            "country",
            "banner",
            "bf",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1e7-1f1eb",
                "native": "🇧🇫"
            }
        ],
        "version": 2
    },
    "flag-bg": {
        "id": "flag-bg",
        "name": "Bulgaria Flag",
        "keywords": [
            "bg",
            "nation",
            "country",
            "banner",
            "bg",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1e7-1f1ec",
                "native": "🇧🇬"
            }
        ],
        "version": 2
    },
    "flag-bh": {
        "id": "flag-bh",
        "name": "Bahrain Flag",
        "keywords": [
            "bh",
            "nation",
            "country",
            "banner",
            "bh",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1e7-1f1ed",
                "native": "🇧🇭"
            }
        ],
        "version": 2
    },
    "flag-bi": {
        "id": "flag-bi",
        "name": "Burundi Flag",
        "keywords": [
            "bi",
            "nation",
            "country",
            "banner",
            "バイ",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1e7-1f1ee",
                "native": "🇧🇮"
            }
        ],
        "version": 2
    },
    "flag-bj": {
        "id": "flag-bj",
        "name": "Benin Flag",
        "keywords": [
            "bj",
            "nation",
            "country",
            "banner",
            "bj",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1e7-1f1ef",
                "native": "🇧🇯"
            }
        ],
        "version": 2
    },
    "flag-bl": {
        "id": "flag-bl",
        "name": "St. Barthélemy Flag",
        "keywords": [
            "bl",
            "st",
            "barthelemy",
            "saint",
            "nation",
            "country",
            "banner",
            "bl",
            "st",
            "バーテレミー",
            "聖人",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1e7-1f1f1",
                "native": "🇧🇱"
            }
        ],
        "version": 2
    },
    "flag-bm": {
        "id": "flag-bm",
        "name": "Bermuda Flag",
        "keywords": [
            "bm",
            "nation",
            "country",
            "banner",
            "うーん",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1e7-1f1f2",
                "native": "🇧🇲"
            }
        ],
        "version": 2
    },
    "flag-bn": {
        "id": "flag-bn",
        "name": "Brunei Flag",
        "keywords": [
            "bn",
            "darussalam",
            "nation",
            "country",
            "banner",
            "bn",
            "ダルサラーム",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1e7-1f1f3",
                "native": "🇧🇳"
            }
        ],
        "version": 2
    },
    "flag-bo": {
        "id": "flag-bo",
        "name": "Bolivia Flag",
        "keywords": [
            "bo",
            "nation",
            "country",
            "banner",
            "ぼ",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1e7-1f1f4",
                "native": "🇧🇴"
            }
        ],
        "version": 2
    },
    "flag-bq": {
        "id": "flag-bq",
        "name": "Caribbean Netherlands Flag",
        "keywords": [
            "bq",
            "bonaire",
            "nation",
            "country",
            "banner",
            "バーベキュー",
            "ボネール",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1e7-1f1f6",
                "native": "🇧🇶"
            }
        ],
        "version": 2
    },
    "flag-br": {
        "id": "flag-br",
        "name": "Brazil Flag",
        "keywords": [
            "br",
            "nation",
            "country",
            "banner",
            "br",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1e7-1f1f7",
                "native": "🇧🇷"
            }
        ],
        "version": 2
    },
    "flag-bs": {
        "id": "flag-bs",
        "name": "Bahamas Flag",
        "keywords": [
            "bs",
            "nation",
            "country",
            "banner",
            "bs",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1e7-1f1f8",
                "native": "🇧🇸"
            }
        ],
        "version": 2
    },
    "flag-bt": {
        "id": "flag-bt",
        "name": "Bhutan Flag",
        "keywords": [
            "bt",
            "nation",
            "country",
            "banner",
            "で",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1e7-1f1f9",
                "native": "🇧🇹"
            }
        ],
        "version": 2
    },
    "flag-bv": {
        "id": "flag-bv",
        "name": "Bouvet Island Flag",
        "keywords": [
            "bv",
            "norway",
            "bv",
            "ノルウェー"
        ],
        "skins": [
            {
                "unified": "1f1e7-1f1fb",
                "native": "🇧🇻"
            }
        ],
        "version": 2
    },
    "flag-bw": {
        "id": "flag-bw",
        "name": "Botswana Flag",
        "keywords": [
            "bw",
            "nation",
            "country",
            "banner",
            "bw",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1e7-1f1fc",
                "native": "🇧🇼"
            }
        ],
        "version": 2
    },
    "flag-by": {
        "id": "flag-by",
        "name": "Belarus Flag",
        "keywords": [
            "by",
            "nation",
            "country",
            "banner",
            "に",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1e7-1f1fe",
                "native": "🇧🇾"
            }
        ],
        "version": 2
    },
    "flag-bz": {
        "id": "flag-bz",
        "name": "Belize Flag",
        "keywords": [
            "bz",
            "nation",
            "country",
            "banner",
            "bz",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1e7-1f1ff",
                "native": "🇧🇿"
            }
        ],
        "version": 2
    },
    "flag-ca": {
        "id": "flag-ca",
        "name": "Canada Flag",
        "keywords": [
            "ca",
            "nation",
            "country",
            "banner",
            "ca",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1e8-1f1e6",
                "native": "🇨🇦"
            }
        ],
        "version": 2
    },
    "flag-cc": {
        "id": "flag-cc",
        "name": "Cocos (keeling) Islands Flag",
        "keywords": [
            "cc",
            "keeling",
            "nation",
            "country",
            "banner",
            "cc",
            "キーリング",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1e8-1f1e8",
                "native": "🇨🇨"
            }
        ],
        "version": 2
    },
    "flag-cd": {
        "id": "flag-cd",
        "name": "Congo - Kinshasa Flag",
        "keywords": [
            "cd",
            "democratic",
            "republic",
            "nation",
            "country",
            "banner",
            "CD",
            "民主的",
            "共和国",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1e8-1f1e9",
                "native": "🇨🇩"
            }
        ],
        "version": 2
    },
    "flag-cf": {
        "id": "flag-cf",
        "name": "Central African Republic Flag",
        "keywords": [
            "cf",
            "nation",
            "country",
            "banner",
            "cf",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1e8-1f1eb",
                "native": "🇨🇫"
            }
        ],
        "version": 2
    },
    "flag-cg": {
        "id": "flag-cg",
        "name": "Congo - Brazzaville Flag",
        "keywords": [
            "cg",
            "nation",
            "country",
            "banner",
            "cg",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1e8-1f1ec",
                "native": "🇨🇬"
            }
        ],
        "version": 2
    },
    "flag-ch": {
        "id": "flag-ch",
        "name": "Switzerland Flag",
        "keywords": [
            "ch",
            "nation",
            "country",
            "banner",
            "ち",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1e8-1f1ed",
                "native": "🇨🇭"
            }
        ],
        "version": 2
    },
    "flag-ci": {
        "id": "flag-ci",
        "name": "Côte D’ivoire Flag",
        "keywords": [
            "ci",
            "cote",
            "d",
            "ivoire",
            "ivory",
            "coast",
            "nation",
            "country",
            "banner",
            "シ",
            "コート",
            "d",
            "アイボワール",
            "象牙",
            "海岸",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1e8-1f1ee",
                "native": "🇨🇮"
            }
        ],
        "version": 2
    },
    "flag-ck": {
        "id": "flag-ck",
        "name": "Cook Islands Flag",
        "keywords": [
            "ck",
            "nation",
            "country",
            "banner",
            "ク",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1e8-1f1f0",
                "native": "🇨🇰"
            }
        ],
        "version": 2
    },
    "flag-cl": {
        "id": "flag-cl",
        "name": "Chile Flag",
        "keywords": [
            "cl",
            "nation",
            "country",
            "banner",
            "cl",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1e8-1f1f1",
                "native": "🇨🇱"
            }
        ],
        "version": 2
    },
    "flag-cm": {
        "id": "flag-cm",
        "name": "Cameroon Flag",
        "keywords": [
            "cm",
            "nation",
            "country",
            "banner",
            "cm",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1e8-1f1f2",
                "native": "🇨🇲"
            }
        ],
        "version": 2
    },
    "cn": {
        "id": "cn",
        "name": "China Flag",
        "keywords": [
            "cn",
            "chinese",
            "prc",
            "country",
            "nation",
            "banner",
            "cn",
            "中国語",
            "prc",
            "国",
            "国家",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1e8-1f1f3",
                "native": "🇨🇳"
            }
        ],
        "version": 1
    },
    "flag-co": {
        "id": "flag-co",
        "name": "Colombia Flag",
        "keywords": [
            "co",
            "nation",
            "country",
            "banner",
            "コ",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1e8-1f1f4",
                "native": "🇨🇴"
            }
        ],
        "version": 2
    },
    "flag-cp": {
        "id": "flag-cp",
        "name": "Clipperton Island Flag",
        "keywords": [
            "cp",
            "cp"
        ],
        "skins": [
            {
                "unified": "1f1e8-1f1f5",
                "native": "🇨🇵"
            }
        ],
        "version": 2
    },
    "flag-cr": {
        "id": "flag-cr",
        "name": "Costa Rica Flag",
        "keywords": [
            "cr",
            "nation",
            "country",
            "banner",
            "cr",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1e8-1f1f7",
                "native": "🇨🇷"
            }
        ],
        "version": 2
    },
    "flag-cu": {
        "id": "flag-cu",
        "name": "Cuba Flag",
        "keywords": [
            "cu",
            "nation",
            "country",
            "banner",
            "ク",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1e8-1f1fa",
                "native": "🇨🇺"
            }
        ],
        "version": 2
    },
    "flag-cv": {
        "id": "flag-cv",
        "name": "Cape Verde Flag",
        "keywords": [
            "cv",
            "cabo",
            "nation",
            "country",
            "banner",
            "履歴書",
            "カボ",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1e8-1f1fb",
                "native": "🇨🇻"
            }
        ],
        "version": 2
    },
    "flag-cw": {
        "id": "flag-cw",
        "name": "Curaçao Flag",
        "keywords": [
            "cw",
            "curacao",
            "nation",
            "country",
            "banner",
            "cw",
            "キュラソー",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1e8-1f1fc",
                "native": "🇨🇼"
            }
        ],
        "version": 2
    },
    "flag-cx": {
        "id": "flag-cx",
        "name": "Christmas Island Flag",
        "keywords": [
            "cx",
            "nation",
            "country",
            "banner",
            "cx",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1e8-1f1fd",
                "native": "🇨🇽"
            }
        ],
        "version": 2
    },
    "flag-cy": {
        "id": "flag-cy",
        "name": "Cyprus Flag",
        "keywords": [
            "cy",
            "nation",
            "country",
            "banner",
            "サイ",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1e8-1f1fe",
                "native": "🇨🇾"
            }
        ],
        "version": 2
    },
    "flag-cz": {
        "id": "flag-cz",
        "name": "Czechia Flag",
        "keywords": [
            "cz",
            "nation",
            "country",
            "banner",
            "cz",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1e8-1f1ff",
                "native": "🇨🇿"
            }
        ],
        "version": 2
    },
    "de": {
        "id": "de",
        "name": "Germany Flag",
        "keywords": [
            "de",
            "german",
            "nation",
            "country",
            "banner",
            "デ",
            "ドイツ人",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1e9-1f1ea",
                "native": "🇩🇪"
            }
        ],
        "version": 1
    },
    "flag-dg": {
        "id": "flag-dg",
        "name": "Diego Garcia Flag",
        "keywords": [
            "dg",
            "dg"
        ],
        "skins": [
            {
                "unified": "1f1e9-1f1ec",
                "native": "🇩🇬"
            }
        ],
        "version": 2
    },
    "flag-dj": {
        "id": "flag-dj",
        "name": "Djibouti Flag",
        "keywords": [
            "dj",
            "nation",
            "country",
            "banner",
            "DJ",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1e9-1f1ef",
                "native": "🇩🇯"
            }
        ],
        "version": 2
    },
    "flag-dk": {
        "id": "flag-dk",
        "name": "Denmark Flag",
        "keywords": [
            "dk",
            "nation",
            "country",
            "banner",
            "dk",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1e9-1f1f0",
                "native": "🇩🇰"
            }
        ],
        "version": 2
    },
    "flag-dm": {
        "id": "flag-dm",
        "name": "Dominica Flag",
        "keywords": [
            "dm",
            "nation",
            "country",
            "banner",
            "dm",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1e9-1f1f2",
                "native": "🇩🇲"
            }
        ],
        "version": 2
    },
    "flag-do": {
        "id": "flag-do",
        "name": "Dominican Republic Flag",
        "keywords": [
            "do",
            "nation",
            "country",
            "banner",
            "する",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1e9-1f1f4",
                "native": "🇩🇴"
            }
        ],
        "version": 2
    },
    "flag-dz": {
        "id": "flag-dz",
        "name": "Algeria Flag",
        "keywords": [
            "dz",
            "nation",
            "country",
            "banner",
            "dz",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1e9-1f1ff",
                "native": "🇩🇿"
            }
        ],
        "version": 2
    },
    "flag-ea": {
        "id": "flag-ea",
        "name": "Ceuta & Melilla Flag",
        "keywords": [
            "ea",
            "あ"
        ],
        "skins": [
            {
                "unified": "1f1ea-1f1e6",
                "native": "🇪🇦"
            }
        ],
        "version": 2
    },
    "flag-ec": {
        "id": "flag-ec",
        "name": "Ecuador Flag",
        "keywords": [
            "ec",
            "nation",
            "country",
            "banner",
            "ec",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1ea-1f1e8",
                "native": "🇪🇨"
            }
        ],
        "version": 2
    },
    "flag-ee": {
        "id": "flag-ee",
        "name": "Estonia Flag",
        "keywords": [
            "ee",
            "nation",
            "country",
            "banner",
            "ええ",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1ea-1f1ea",
                "native": "🇪🇪"
            }
        ],
        "version": 2
    },
    "flag-eg": {
        "id": "flag-eg",
        "name": "Egypt Flag",
        "keywords": [
            "eg",
            "nation",
            "country",
            "banner",
            "例えば",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1ea-1f1ec",
                "native": "🇪🇬"
            }
        ],
        "version": 2
    },
    "flag-eh": {
        "id": "flag-eh",
        "name": "Western Sahara Flag",
        "keywords": [
            "eh",
            "nation",
            "country",
            "banner",
            "ええ",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1ea-1f1ed",
                "native": "🇪🇭"
            }
        ],
        "version": 2
    },
    "flag-er": {
        "id": "flag-er",
        "name": "Eritrea Flag",
        "keywords": [
            "er",
            "nation",
            "country",
            "banner",
            "ええ",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1ea-1f1f7",
                "native": "🇪🇷"
            }
        ],
        "version": 2
    },
    "es": {
        "id": "es",
        "name": "Spain Flag",
        "keywords": [
            "es",
            "nation",
            "country",
            "banner",
            "エス",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1ea-1f1f8",
                "native": "🇪🇸"
            }
        ],
        "version": 1
    },
    "flag-et": {
        "id": "flag-et",
        "name": "Ethiopia Flag",
        "keywords": [
            "et",
            "nation",
            "country",
            "banner",
            "エ",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1ea-1f1f9",
                "native": "🇪🇹"
            }
        ],
        "version": 2
    },
    "flag-eu": {
        "id": "flag-eu",
        "name": "European Union Flag",
        "keywords": [
            "eu",
            "banner",
            "EU",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1ea-1f1fa",
                "native": "🇪🇺"
            }
        ],
        "version": 2
    },
    "flag-fi": {
        "id": "flag-fi",
        "name": "Finland Flag",
        "keywords": [
            "fi",
            "nation",
            "country",
            "banner",
            "フィ",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1eb-1f1ee",
                "native": "🇫🇮"
            }
        ],
        "version": 2
    },
    "flag-fj": {
        "id": "flag-fj",
        "name": "Fiji Flag",
        "keywords": [
            "fj",
            "nation",
            "country",
            "banner",
            "J F",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1eb-1f1ef",
                "native": "🇫🇯"
            }
        ],
        "version": 2
    },
    "flag-fk": {
        "id": "flag-fk",
        "name": "Falkland Islands Flag",
        "keywords": [
            "fk",
            "malvinas",
            "nation",
            "country",
            "banner",
            "fk",
            "マルビナス",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1eb-1f1f0",
                "native": "🇫🇰"
            }
        ],
        "version": 2
    },
    "flag-fm": {
        "id": "flag-fm",
        "name": "Micronesia Flag",
        "keywords": [
            "fm",
            "federated",
            "states",
            "nation",
            "country",
            "banner",
            "fm",
            "連合",
            "状態",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1eb-1f1f2",
                "native": "🇫🇲"
            }
        ],
        "version": 2
    },
    "flag-fo": {
        "id": "flag-fo",
        "name": "Faroe Islands Flag",
        "keywords": [
            "fo",
            "nation",
            "country",
            "banner",
            "フォ",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1eb-1f1f4",
                "native": "🇫🇴"
            }
        ],
        "version": 2
    },
    "fr": {
        "id": "fr",
        "name": "France Flag",
        "keywords": [
            "fr",
            "banner",
            "nation",
            "french",
            "country",
            "fr",
            "バナー",
            "国家",
            "フランス語",
            "国"
        ],
        "skins": [
            {
                "unified": "1f1eb-1f1f7",
                "native": "🇫🇷"
            }
        ],
        "version": 1
    },
    "flag-ga": {
        "id": "flag-ga",
        "name": "Gabon Flag",
        "keywords": [
            "ga",
            "nation",
            "country",
            "banner",
            "が",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1ec-1f1e6",
                "native": "🇬🇦"
            }
        ],
        "version": 2
    },
    "gb": {
        "id": "gb",
        "name": "United Kingdom Flag",
        "keywords": [
            "gb",
            "uk",
            "great",
            "britain",
            "northern",
            "ireland",
            "nation",
            "country",
            "banner",
            "british",
            "UK",
            "english",
            "england",
            "union",
            "jack",
            "GB",
            "イギリス",
            "素晴らしい",
            "英国",
            "北",
            "アイルランド",
            "国家",
            "国",
            "バナー",
            "イギリス人",
            "イギリス",
            "英語",
            "イングランド",
            "連合",
            "ジャック"
        ],
        "skins": [
            {
                "unified": "1f1ec-1f1e7",
                "native": "🇬🇧"
            }
        ],
        "version": 1
    },
    "flag-gd": {
        "id": "flag-gd",
        "name": "Grenada Flag",
        "keywords": [
            "gd",
            "nation",
            "country",
            "banner",
            "gd",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1ec-1f1e9",
                "native": "🇬🇩"
            }
        ],
        "version": 2
    },
    "flag-ge": {
        "id": "flag-ge",
        "name": "Georgia Flag",
        "keywords": [
            "ge",
            "nation",
            "country",
            "banner",
            "ゲ",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1ec-1f1ea",
                "native": "🇬🇪"
            }
        ],
        "version": 2
    },
    "flag-gf": {
        "id": "flag-gf",
        "name": "French Guiana Flag",
        "keywords": [
            "gf",
            "nation",
            "country",
            "banner",
            "gf",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1ec-1f1eb",
                "native": "🇬🇫"
            }
        ],
        "version": 2
    },
    "flag-gg": {
        "id": "flag-gg",
        "name": "Guernsey Flag",
        "keywords": [
            "gg",
            "nation",
            "country",
            "banner",
            "gg",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1ec-1f1ec",
                "native": "🇬🇬"
            }
        ],
        "version": 2
    },
    "flag-gh": {
        "id": "flag-gh",
        "name": "Ghana Flag",
        "keywords": [
            "gh",
            "nation",
            "country",
            "banner",
            "ぐ",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1ec-1f1ed",
                "native": "🇬🇭"
            }
        ],
        "version": 2
    },
    "flag-gi": {
        "id": "flag-gi",
        "name": "Gibraltar Flag",
        "keywords": [
            "gi",
            "nation",
            "country",
            "banner",
            "ギ",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1ec-1f1ee",
                "native": "🇬🇮"
            }
        ],
        "version": 2
    },
    "flag-gl": {
        "id": "flag-gl",
        "name": "Greenland Flag",
        "keywords": [
            "gl",
            "nation",
            "country",
            "banner",
            "GL",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1ec-1f1f1",
                "native": "🇬🇱"
            }
        ],
        "version": 2
    },
    "flag-gm": {
        "id": "flag-gm",
        "name": "Gambia Flag",
        "keywords": [
            "gm",
            "nation",
            "country",
            "banner",
            "グラム",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1ec-1f1f2",
                "native": "🇬🇲"
            }
        ],
        "version": 2
    },
    "flag-gn": {
        "id": "flag-gn",
        "name": "Guinea Flag",
        "keywords": [
            "gn",
            "nation",
            "country",
            "banner",
            "おやすみなさい",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1ec-1f1f3",
                "native": "🇬🇳"
            }
        ],
        "version": 2
    },
    "flag-gp": {
        "id": "flag-gp",
        "name": "Guadeloupe Flag",
        "keywords": [
            "gp",
            "nation",
            "country",
            "banner",
            "GP",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1ec-1f1f5",
                "native": "🇬🇵"
            }
        ],
        "version": 2
    },
    "flag-gq": {
        "id": "flag-gq",
        "name": "Equatorial Guinea Flag",
        "keywords": [
            "gq",
            "gn",
            "nation",
            "country",
            "banner",
            "gq",
            "おやすみなさい",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1ec-1f1f6",
                "native": "🇬🇶"
            }
        ],
        "version": 2
    },
    "flag-gr": {
        "id": "flag-gr",
        "name": "Greece Flag",
        "keywords": [
            "gr",
            "nation",
            "country",
            "banner",
            "グラム",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1ec-1f1f7",
                "native": "🇬🇷"
            }
        ],
        "version": 2
    },
    "flag-gs": {
        "id": "flag-gs",
        "name": "South Georgia & South Sandwich Islands Flag",
        "keywords": [
            "gs",
            "nation",
            "country",
            "banner",
            "グス",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1ec-1f1f8",
                "native": "🇬🇸"
            }
        ],
        "version": 2
    },
    "flag-gt": {
        "id": "flag-gt",
        "name": "Guatemala Flag",
        "keywords": [
            "gt",
            "nation",
            "country",
            "banner",
            "gt",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1ec-1f1f9",
                "native": "🇬🇹"
            }
        ],
        "version": 2
    },
    "flag-gu": {
        "id": "flag-gu",
        "name": "Guam Flag",
        "keywords": [
            "gu",
            "nation",
            "country",
            "banner",
            "ぐ",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1ec-1f1fa",
                "native": "🇬🇺"
            }
        ],
        "version": 2
    },
    "flag-gw": {
        "id": "flag-gw",
        "name": "Guinea-Bissau Flag",
        "keywords": [
            "gw",
            "guinea",
            "bissau",
            "nation",
            "country",
            "banner",
            "gw",
            "ギニア",
            "ビサウ",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1ec-1f1fc",
                "native": "🇬🇼"
            }
        ],
        "version": 2
    },
    "flag-gy": {
        "id": "flag-gy",
        "name": "Guyana Flag",
        "keywords": [
            "gy",
            "nation",
            "country",
            "banner",
            "ジー",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1ec-1f1fe",
                "native": "🇬🇾"
            }
        ],
        "version": 2
    },
    "flag-hk": {
        "id": "flag-hk",
        "name": "Hong Kong Sar China Flag",
        "keywords": [
            "hk",
            "nation",
            "country",
            "banner",
            "香港",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1ed-1f1f0",
                "native": "🇭🇰"
            }
        ],
        "version": 2
    },
    "flag-hm": {
        "id": "flag-hm",
        "name": "Heard & Mcdonald Islands Flag",
        "keywords": [
            "hm",
            "うーん"
        ],
        "skins": [
            {
                "unified": "1f1ed-1f1f2",
                "native": "🇭🇲"
            }
        ],
        "version": 2
    },
    "flag-hn": {
        "id": "flag-hn",
        "name": "Honduras Flag",
        "keywords": [
            "hn",
            "nation",
            "country",
            "banner",
            "ん",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1ed-1f1f3",
                "native": "🇭🇳"
            }
        ],
        "version": 2
    },
    "flag-hr": {
        "id": "flag-hr",
        "name": "Croatia Flag",
        "keywords": [
            "hr",
            "nation",
            "country",
            "banner",
            "時間",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1ed-1f1f7",
                "native": "🇭🇷"
            }
        ],
        "version": 2
    },
    "flag-ht": {
        "id": "flag-ht",
        "name": "Haiti Flag",
        "keywords": [
            "ht",
            "nation",
            "country",
            "banner",
            "ht",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1ed-1f1f9",
                "native": "🇭🇹"
            }
        ],
        "version": 2
    },
    "flag-hu": {
        "id": "flag-hu",
        "name": "Hungary Flag",
        "keywords": [
            "hu",
            "nation",
            "country",
            "banner",
            "ふ",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1ed-1f1fa",
                "native": "🇭🇺"
            }
        ],
        "version": 2
    },
    "flag-ic": {
        "id": "flag-ic",
        "name": "Canary Islands Flag",
        "keywords": [
            "ic",
            "nation",
            "country",
            "banner",
            "IC",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1ee-1f1e8",
                "native": "🇮🇨"
            }
        ],
        "version": 2
    },
    "flag-id": {
        "id": "flag-id",
        "name": "Indonesia Flag",
        "keywords": [
            "id",
            "nation",
            "country",
            "banner",
            "ID",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1ee-1f1e9",
                "native": "🇮🇩"
            }
        ],
        "version": 2
    },
    "flag-ie": {
        "id": "flag-ie",
        "name": "Ireland Flag",
        "keywords": [
            "ie",
            "nation",
            "country",
            "banner",
            "すなわち",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1ee-1f1ea",
                "native": "🇮🇪"
            }
        ],
        "version": 2
    },
    "flag-il": {
        "id": "flag-il",
        "name": "Israel Flag",
        "keywords": [
            "il",
            "nation",
            "country",
            "banner",
            "イル",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1ee-1f1f1",
                "native": "🇮🇱"
            }
        ],
        "version": 2
    },
    "flag-im": {
        "id": "flag-im",
        "name": "Isle of Man Flag",
        "keywords": [
            "im",
            "nation",
            "country",
            "banner",
            "わたしは",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1ee-1f1f2",
                "native": "🇮🇲"
            }
        ],
        "version": 2
    },
    "flag-in": {
        "id": "flag-in",
        "name": "India Flag",
        "keywords": [
            "in",
            "nation",
            "country",
            "banner",
            "の",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1ee-1f1f3",
                "native": "🇮🇳"
            }
        ],
        "version": 2
    },
    "flag-io": {
        "id": "flag-io",
        "name": "British Indian Ocean Territory Flag",
        "keywords": [
            "io",
            "nation",
            "country",
            "banner",
            "イオ",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1ee-1f1f4",
                "native": "🇮🇴"
            }
        ],
        "version": 2
    },
    "flag-iq": {
        "id": "flag-iq",
        "name": "Iraq Flag",
        "keywords": [
            "iq",
            "nation",
            "country",
            "banner",
            "iq",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1ee-1f1f6",
                "native": "🇮🇶"
            }
        ],
        "version": 2
    },
    "flag-ir": {
        "id": "flag-ir",
        "name": "Iran Flag",
        "keywords": [
            "ir",
            "islamic",
            "republic",
            "nation",
            "country",
            "banner",
            "私",
            "イスラム",
            "共和国",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1ee-1f1f7",
                "native": "🇮🇷"
            }
        ],
        "version": 2
    },
    "flag-is": {
        "id": "flag-is",
        "name": "Iceland Flag",
        "keywords": [
            "is",
            "nation",
            "country",
            "banner",
            "は",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1ee-1f1f8",
                "native": "🇮🇸"
            }
        ],
        "version": 2
    },
    "it": {
        "id": "it",
        "name": "Italy Flag",
        "keywords": [
            "it",
            "nation",
            "country",
            "banner",
            "それ",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1ee-1f1f9",
                "native": "🇮🇹"
            }
        ],
        "version": 1
    },
    "flag-je": {
        "id": "flag-je",
        "name": "Jersey Flag",
        "keywords": [
            "je",
            "nation",
            "country",
            "banner",
            "ジェ",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1ef-1f1ea",
                "native": "🇯🇪"
            }
        ],
        "version": 2
    },
    "flag-jm": {
        "id": "flag-jm",
        "name": "Jamaica Flag",
        "keywords": [
            "jm",
            "nation",
            "country",
            "banner",
            "jm",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1ef-1f1f2",
                "native": "🇯🇲"
            }
        ],
        "version": 2
    },
    "flag-jo": {
        "id": "flag-jo",
        "name": "Jordan Flag",
        "keywords": [
            "jo",
            "nation",
            "country",
            "banner",
            "じょう",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1ef-1f1f4",
                "native": "🇯🇴"
            }
        ],
        "version": 2
    },
    "jp": {
        "id": "jp",
        "name": "Japan Flag",
        "keywords": [
            "jp",
            "japanese",
            "nation",
            "country",
            "banner",
            "jp",
            "日本",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1ef-1f1f5",
                "native": "🇯🇵"
            }
        ],
        "version": 1
    },
    "flag-ke": {
        "id": "flag-ke",
        "name": "Kenya Flag",
        "keywords": [
            "ke",
            "nation",
            "country",
            "banner",
            "ケ",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1f0-1f1ea",
                "native": "🇰🇪"
            }
        ],
        "version": 2
    },
    "flag-kg": {
        "id": "flag-kg",
        "name": "Kyrgyzstan Flag",
        "keywords": [
            "kg",
            "nation",
            "country",
            "banner",
            "kg",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1f0-1f1ec",
                "native": "🇰🇬"
            }
        ],
        "version": 2
    },
    "flag-kh": {
        "id": "flag-kh",
        "name": "Cambodia Flag",
        "keywords": [
            "kh",
            "nation",
            "country",
            "banner",
            "ク",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1f0-1f1ed",
                "native": "🇰🇭"
            }
        ],
        "version": 2
    },
    "flag-ki": {
        "id": "flag-ki",
        "name": "Kiribati Flag",
        "keywords": [
            "ki",
            "nation",
            "country",
            "banner",
            "気",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1f0-1f1ee",
                "native": "🇰🇮"
            }
        ],
        "version": 2
    },
    "flag-km": {
        "id": "flag-km",
        "name": "Comoros Flag",
        "keywords": [
            "km",
            "nation",
            "country",
            "banner",
            "キロ",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1f0-1f1f2",
                "native": "🇰🇲"
            }
        ],
        "version": 2
    },
    "flag-kn": {
        "id": "flag-kn",
        "name": "St. Kitts & Nevis Flag",
        "keywords": [
            "kn",
            "st",
            "saint",
            "nation",
            "country",
            "banner",
            "クン",
            "st",
            "聖人",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1f0-1f1f3",
                "native": "🇰🇳"
            }
        ],
        "version": 2
    },
    "flag-kp": {
        "id": "flag-kp",
        "name": "North Korea Flag",
        "keywords": [
            "kp",
            "nation",
            "country",
            "banner",
            "kp",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1f0-1f1f5",
                "native": "🇰🇵"
            }
        ],
        "version": 2
    },
    "kr": {
        "id": "kr",
        "name": "South Korea Flag",
        "keywords": [
            "kr",
            "nation",
            "country",
            "banner",
            "kr",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1f0-1f1f7",
                "native": "🇰🇷"
            }
        ],
        "version": 1
    },
    "flag-kw": {
        "id": "flag-kw",
        "name": "Kuwait Flag",
        "keywords": [
            "kw",
            "nation",
            "country",
            "banner",
            "kw",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1f0-1f1fc",
                "native": "🇰🇼"
            }
        ],
        "version": 2
    },
    "flag-ky": {
        "id": "flag-ky",
        "name": "Cayman Islands Flag",
        "keywords": [
            "ky",
            "nation",
            "country",
            "banner",
            "き",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1f0-1f1fe",
                "native": "🇰🇾"
            }
        ],
        "version": 2
    },
    "flag-kz": {
        "id": "flag-kz",
        "name": "Kazakhstan Flag",
        "keywords": [
            "kz",
            "nation",
            "country",
            "banner",
            "kz",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1f0-1f1ff",
                "native": "🇰🇿"
            }
        ],
        "version": 2
    },
    "flag-la": {
        "id": "flag-la",
        "name": "Laos Flag",
        "keywords": [
            "la",
            "lao",
            "democratic",
            "republic",
            "nation",
            "country",
            "banner",
            "ラ",
            "ラオ",
            "民主的",
            "共和国",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1f1-1f1e6",
                "native": "🇱🇦"
            }
        ],
        "version": 2
    },
    "flag-lb": {
        "id": "flag-lb",
        "name": "Lebanon Flag",
        "keywords": [
            "lb",
            "nation",
            "country",
            "banner",
            "ポンド",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1f1-1f1e7",
                "native": "🇱🇧"
            }
        ],
        "version": 2
    },
    "flag-lc": {
        "id": "flag-lc",
        "name": "St. Lucia Flag",
        "keywords": [
            "lc",
            "st",
            "saint",
            "nation",
            "country",
            "banner",
            "lc",
            "st",
            "聖人",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1f1-1f1e8",
                "native": "🇱🇨"
            }
        ],
        "version": 2
    },
    "flag-li": {
        "id": "flag-li",
        "name": "Liechtenstein Flag",
        "keywords": [
            "li",
            "nation",
            "country",
            "banner",
            "リ",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1f1-1f1ee",
                "native": "🇱🇮"
            }
        ],
        "version": 2
    },
    "flag-lk": {
        "id": "flag-lk",
        "name": "Sri Lanka Flag",
        "keywords": [
            "lk",
            "nation",
            "country",
            "banner",
            "ルク",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1f1-1f1f0",
                "native": "🇱🇰"
            }
        ],
        "version": 2
    },
    "flag-lr": {
        "id": "flag-lr",
        "name": "Liberia Flag",
        "keywords": [
            "lr",
            "nation",
            "country",
            "banner",
            "lr",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1f1-1f1f7",
                "native": "🇱🇷"
            }
        ],
        "version": 2
    },
    "flag-ls": {
        "id": "flag-ls",
        "name": "Lesotho Flag",
        "keywords": [
            "ls",
            "nation",
            "country",
            "banner",
            "ls",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1f1-1f1f8",
                "native": "🇱🇸"
            }
        ],
        "version": 2
    },
    "flag-lt": {
        "id": "flag-lt",
        "name": "Lithuania Flag",
        "keywords": [
            "lt",
            "nation",
            "country",
            "banner",
            "それ",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1f1-1f1f9",
                "native": "🇱🇹"
            }
        ],
        "version": 2
    },
    "flag-lu": {
        "id": "flag-lu",
        "name": "Luxembourg Flag",
        "keywords": [
            "lu",
            "nation",
            "country",
            "banner",
            "ル",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1f1-1f1fa",
                "native": "🇱🇺"
            }
        ],
        "version": 2
    },
    "flag-lv": {
        "id": "flag-lv",
        "name": "Latvia Flag",
        "keywords": [
            "lv",
            "nation",
            "country",
            "banner",
            "LV",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1f1-1f1fb",
                "native": "🇱🇻"
            }
        ],
        "version": 2
    },
    "flag-ly": {
        "id": "flag-ly",
        "name": "Libya Flag",
        "keywords": [
            "ly",
            "nation",
            "country",
            "banner",
            "はい",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1f1-1f1fe",
                "native": "🇱🇾"
            }
        ],
        "version": 2
    },
    "flag-ma": {
        "id": "flag-ma",
        "name": "Morocco Flag",
        "keywords": [
            "ma",
            "nation",
            "country",
            "banner",
            "ま",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1f2-1f1e6",
                "native": "🇲🇦"
            }
        ],
        "version": 2
    },
    "flag-mc": {
        "id": "flag-mc",
        "name": "Monaco Flag",
        "keywords": [
            "mc",
            "nation",
            "country",
            "banner",
            "MC",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1f2-1f1e8",
                "native": "🇲🇨"
            }
        ],
        "version": 2
    },
    "flag-md": {
        "id": "flag-md",
        "name": "Moldova Flag",
        "keywords": [
            "md",
            "republic",
            "nation",
            "country",
            "banner",
            "md",
            "共和国",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1f2-1f1e9",
                "native": "🇲🇩"
            }
        ],
        "version": 2
    },
    "flag-me": {
        "id": "flag-me",
        "name": "Montenegro Flag",
        "keywords": [
            "me",
            "nation",
            "country",
            "banner",
            "自分",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1f2-1f1ea",
                "native": "🇲🇪"
            }
        ],
        "version": 2
    },
    "flag-mf": {
        "id": "flag-mf",
        "name": "St. Martin Flag",
        "keywords": [
            "mf",
            "st",
            "mf",
            "st"
        ],
        "skins": [
            {
                "unified": "1f1f2-1f1eb",
                "native": "🇲🇫"
            }
        ],
        "version": 2
    },
    "flag-mg": {
        "id": "flag-mg",
        "name": "Madagascar Flag",
        "keywords": [
            "mg",
            "nation",
            "country",
            "banner",
            "mg",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1f2-1f1ec",
                "native": "🇲🇬"
            }
        ],
        "version": 2
    },
    "flag-mh": {
        "id": "flag-mh",
        "name": "Marshall Islands Flag",
        "keywords": [
            "mh",
            "nation",
            "country",
            "banner",
            "ああ",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1f2-1f1ed",
                "native": "🇲🇭"
            }
        ],
        "version": 2
    },
    "flag-mk": {
        "id": "flag-mk",
        "name": "North Macedonia Flag",
        "keywords": [
            "mk",
            "nation",
            "country",
            "banner",
            "mk",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1f2-1f1f0",
                "native": "🇲🇰"
            }
        ],
        "version": 2
    },
    "flag-ml": {
        "id": "flag-ml",
        "name": "Mali Flag",
        "keywords": [
            "ml",
            "nation",
            "country",
            "banner",
            "ミリリットル",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1f2-1f1f1",
                "native": "🇲🇱"
            }
        ],
        "version": 2
    },
    "flag-mm": {
        "id": "flag-mm",
        "name": "Myanmar (burma) Flag",
        "keywords": [
            "mm",
            "nation",
            "country",
            "banner",
            "んん",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1f2-1f1f2",
                "native": "🇲🇲"
            }
        ],
        "version": 2
    },
    "flag-mn": {
        "id": "flag-mn",
        "name": "Mongolia Flag",
        "keywords": [
            "mn",
            "nation",
            "country",
            "banner",
            "分",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1f2-1f1f3",
                "native": "🇲🇳"
            }
        ],
        "version": 2
    },
    "flag-mo": {
        "id": "flag-mo",
        "name": "Macao Sar China Flag",
        "keywords": [
            "mo",
            "nation",
            "country",
            "banner",
            "モ",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1f2-1f1f4",
                "native": "🇲🇴"
            }
        ],
        "version": 2
    },
    "flag-mp": {
        "id": "flag-mp",
        "name": "Northern Mariana Islands Flag",
        "keywords": [
            "mp",
            "nation",
            "country",
            "banner",
            "MP",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1f2-1f1f5",
                "native": "🇲🇵"
            }
        ],
        "version": 2
    },
    "flag-mq": {
        "id": "flag-mq",
        "name": "Martinique Flag",
        "keywords": [
            "mq",
            "nation",
            "country",
            "banner",
            "mq",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1f2-1f1f6",
                "native": "🇲🇶"
            }
        ],
        "version": 2
    },
    "flag-mr": {
        "id": "flag-mr",
        "name": "Mauritania Flag",
        "keywords": [
            "mr",
            "nation",
            "country",
            "banner",
            "氏",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1f2-1f1f7",
                "native": "🇲🇷"
            }
        ],
        "version": 2
    },
    "flag-ms": {
        "id": "flag-ms",
        "name": "Montserrat Flag",
        "keywords": [
            "ms",
            "nation",
            "country",
            "banner",
            "MS",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1f2-1f1f8",
                "native": "🇲🇸"
            }
        ],
        "version": 2
    },
    "flag-mt": {
        "id": "flag-mt",
        "name": "Malta Flag",
        "keywords": [
            "mt",
            "nation",
            "country",
            "banner",
            "mt",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1f2-1f1f9",
                "native": "🇲🇹"
            }
        ],
        "version": 2
    },
    "flag-mu": {
        "id": "flag-mu",
        "name": "Mauritius Flag",
        "keywords": [
            "mu",
            "nation",
            "country",
            "banner",
            "ムー",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1f2-1f1fa",
                "native": "🇲🇺"
            }
        ],
        "version": 2
    },
    "flag-mv": {
        "id": "flag-mv",
        "name": "Maldives Flag",
        "keywords": [
            "mv",
            "nation",
            "country",
            "banner",
            "MV",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1f2-1f1fb",
                "native": "🇲🇻"
            }
        ],
        "version": 2
    },
    "flag-mw": {
        "id": "flag-mw",
        "name": "Malawi Flag",
        "keywords": [
            "mw",
            "nation",
            "country",
            "banner",
            "mw",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1f2-1f1fc",
                "native": "🇲🇼"
            }
        ],
        "version": 2
    },
    "flag-mx": {
        "id": "flag-mx",
        "name": "Mexico Flag",
        "keywords": [
            "mx",
            "nation",
            "country",
            "banner",
            "mx",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1f2-1f1fd",
                "native": "🇲🇽"
            }
        ],
        "version": 2
    },
    "flag-my": {
        "id": "flag-my",
        "name": "Malaysia Flag",
        "keywords": [
            "my",
            "nation",
            "country",
            "banner",
            "私の",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1f2-1f1fe",
                "native": "🇲🇾"
            }
        ],
        "version": 2
    },
    "flag-mz": {
        "id": "flag-mz",
        "name": "Mozambique Flag",
        "keywords": [
            "mz",
            "nation",
            "country",
            "banner",
            "mz",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1f2-1f1ff",
                "native": "🇲🇿"
            }
        ],
        "version": 2
    },
    "flag-na": {
        "id": "flag-na",
        "name": "Namibia Flag",
        "keywords": [
            "na",
            "nation",
            "country",
            "banner",
            "な",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1f3-1f1e6",
                "native": "🇳🇦"
            }
        ],
        "version": 2
    },
    "flag-nc": {
        "id": "flag-nc",
        "name": "New Caledonia Flag",
        "keywords": [
            "nc",
            "nation",
            "country",
            "banner",
            "nc",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1f3-1f1e8",
                "native": "🇳🇨"
            }
        ],
        "version": 2
    },
    "flag-ne": {
        "id": "flag-ne",
        "name": "Niger Flag",
        "keywords": [
            "ne",
            "nation",
            "country",
            "banner",
            "ネ",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1f3-1f1ea",
                "native": "🇳🇪"
            }
        ],
        "version": 2
    },
    "flag-nf": {
        "id": "flag-nf",
        "name": "Norfolk Island Flag",
        "keywords": [
            "nf",
            "nation",
            "country",
            "banner",
            "nf",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1f3-1f1eb",
                "native": "🇳🇫"
            }
        ],
        "version": 2
    },
    "flag-ng": {
        "id": "flag-ng",
        "name": "Nigeria Flag",
        "keywords": [
            "ng",
            "nation",
            "country",
            "banner",
            "ん",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1f3-1f1ec",
                "native": "🇳🇬"
            }
        ],
        "version": 2
    },
    "flag-ni": {
        "id": "flag-ni",
        "name": "Nicaragua Flag",
        "keywords": [
            "ni",
            "nation",
            "country",
            "banner",
            "に",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1f3-1f1ee",
                "native": "🇳🇮"
            }
        ],
        "version": 2
    },
    "flag-nl": {
        "id": "flag-nl",
        "name": "Netherlands Flag",
        "keywords": [
            "nl",
            "nation",
            "country",
            "banner",
            "nl",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1f3-1f1f1",
                "native": "🇳🇱"
            }
        ],
        "version": 2
    },
    "flag-no": {
        "id": "flag-no",
        "name": "Norway Flag",
        "keywords": [
            "no",
            "nation",
            "country",
            "banner",
            "いいえ",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1f3-1f1f4",
                "native": "🇳🇴"
            }
        ],
        "version": 2
    },
    "flag-np": {
        "id": "flag-np",
        "name": "Nepal Flag",
        "keywords": [
            "np",
            "nation",
            "country",
            "banner",
            "np",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1f3-1f1f5",
                "native": "🇳🇵"
            }
        ],
        "version": 2
    },
    "flag-nr": {
        "id": "flag-nr",
        "name": "Nauru Flag",
        "keywords": [
            "nr",
            "nation",
            "country",
            "banner",
            "nr",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1f3-1f1f7",
                "native": "🇳🇷"
            }
        ],
        "version": 2
    },
    "flag-nu": {
        "id": "flag-nu",
        "name": "Niue Flag",
        "keywords": [
            "nu",
            "nation",
            "country",
            "banner",
            "ヌ",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1f3-1f1fa",
                "native": "🇳🇺"
            }
        ],
        "version": 2
    },
    "flag-nz": {
        "id": "flag-nz",
        "name": "New Zealand Flag",
        "keywords": [
            "nz",
            "nation",
            "country",
            "banner",
            "nz",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1f3-1f1ff",
                "native": "🇳🇿"
            }
        ],
        "version": 2
    },
    "flag-om": {
        "id": "flag-om",
        "name": "Oman Flag",
        "keywords": [
            "om",
            "symbol",
            "nation",
            "country",
            "banner",
            "オム",
            "シンボル",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1f4-1f1f2",
                "native": "🇴🇲"
            }
        ],
        "version": 2
    },
    "flag-pa": {
        "id": "flag-pa",
        "name": "Panama Flag",
        "keywords": [
            "pa",
            "nation",
            "country",
            "banner",
            "パ",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1f5-1f1e6",
                "native": "🇵🇦"
            }
        ],
        "version": 2
    },
    "flag-pe": {
        "id": "flag-pe",
        "name": "Peru Flag",
        "keywords": [
            "pe",
            "nation",
            "country",
            "banner",
            "ペ",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1f5-1f1ea",
                "native": "🇵🇪"
            }
        ],
        "version": 2
    },
    "flag-pf": {
        "id": "flag-pf",
        "name": "French Polynesia Flag",
        "keywords": [
            "pf",
            "nation",
            "country",
            "banner",
            "pf",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1f5-1f1eb",
                "native": "🇵🇫"
            }
        ],
        "version": 2
    },
    "flag-pg": {
        "id": "flag-pg",
        "name": "Papua New Guinea Flag",
        "keywords": [
            "pg",
            "nation",
            "country",
            "banner",
            "pg",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1f5-1f1ec",
                "native": "🇵🇬"
            }
        ],
        "version": 2
    },
    "flag-ph": {
        "id": "flag-ph",
        "name": "Philippines Flag",
        "keywords": [
            "ph",
            "nation",
            "country",
            "banner",
            "ph",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1f5-1f1ed",
                "native": "🇵🇭"
            }
        ],
        "version": 2
    },
    "flag-pk": {
        "id": "flag-pk",
        "name": "Pakistan Flag",
        "keywords": [
            "pk",
            "nation",
            "country",
            "banner",
            "pk",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1f5-1f1f0",
                "native": "🇵🇰"
            }
        ],
        "version": 2
    },
    "flag-pl": {
        "id": "flag-pl",
        "name": "Poland Flag",
        "keywords": [
            "pl",
            "nation",
            "country",
            "banner",
            "pl",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1f5-1f1f1",
                "native": "🇵🇱"
            }
        ],
        "version": 2
    },
    "flag-pm": {
        "id": "flag-pm",
        "name": "St. Pierre & Miquelon Flag",
        "keywords": [
            "pm",
            "st",
            "saint",
            "nation",
            "country",
            "banner",
            "午後",
            "st",
            "聖人",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1f5-1f1f2",
                "native": "🇵🇲"
            }
        ],
        "version": 2
    },
    "flag-pn": {
        "id": "flag-pn",
        "name": "Pitcairn Islands Flag",
        "keywords": [
            "pn",
            "nation",
            "country",
            "banner",
            "pn",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1f5-1f1f3",
                "native": "🇵🇳"
            }
        ],
        "version": 2
    },
    "flag-pr": {
        "id": "flag-pr",
        "name": "Puerto Rico Flag",
        "keywords": [
            "pr",
            "nation",
            "country",
            "banner",
            "広報",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1f5-1f1f7",
                "native": "🇵🇷"
            }
        ],
        "version": 2
    },
    "flag-ps": {
        "id": "flag-ps",
        "name": "Palestinian Territories Flag",
        "keywords": [
            "ps",
            "palestine",
            "nation",
            "country",
            "banner",
            "ps",
            "パレスチナ",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1f5-1f1f8",
                "native": "🇵🇸"
            }
        ],
        "version": 2
    },
    "flag-pt": {
        "id": "flag-pt",
        "name": "Portugal Flag",
        "keywords": [
            "pt",
            "nation",
            "country",
            "banner",
            "pt",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1f5-1f1f9",
                "native": "🇵🇹"
            }
        ],
        "version": 2
    },
    "flag-pw": {
        "id": "flag-pw",
        "name": "Palau Flag",
        "keywords": [
            "pw",
            "nation",
            "country",
            "banner",
            "pw",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1f5-1f1fc",
                "native": "🇵🇼"
            }
        ],
        "version": 2
    },
    "flag-py": {
        "id": "flag-py",
        "name": "Paraguay Flag",
        "keywords": [
            "py",
            "nation",
            "country",
            "banner",
            "パイ",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1f5-1f1fe",
                "native": "🇵🇾"
            }
        ],
        "version": 2
    },
    "flag-qa": {
        "id": "flag-qa",
        "name": "Qatar Flag",
        "keywords": [
            "qa",
            "nation",
            "country",
            "banner",
            "か",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1f6-1f1e6",
                "native": "🇶🇦"
            }
        ],
        "version": 2
    },
    "flag-re": {
        "id": "flag-re",
        "name": "Réunion Flag",
        "keywords": [
            "re",
            "reunion",
            "nation",
            "country",
            "banner",
            "レ",
            "再会",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1f7-1f1ea",
                "native": "🇷🇪"
            }
        ],
        "version": 2
    },
    "flag-ro": {
        "id": "flag-ro",
        "name": "Romania Flag",
        "keywords": [
            "ro",
            "nation",
            "country",
            "banner",
            "ロ",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1f7-1f1f4",
                "native": "🇷🇴"
            }
        ],
        "version": 2
    },
    "flag-rs": {
        "id": "flag-rs",
        "name": "Serbia Flag",
        "keywords": [
            "rs",
            "nation",
            "country",
            "banner",
            "rs",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1f7-1f1f8",
                "native": "🇷🇸"
            }
        ],
        "version": 2
    },
    "ru": {
        "id": "ru",
        "name": "Russia Flag",
        "keywords": [
            "ru",
            "russian",
            "federation",
            "nation",
            "country",
            "banner",
            "ル",
            "ロシア",
            "フェデレーション",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1f7-1f1fa",
                "native": "🇷🇺"
            }
        ],
        "version": 1
    },
    "flag-rw": {
        "id": "flag-rw",
        "name": "Rwanda Flag",
        "keywords": [
            "rw",
            "nation",
            "country",
            "banner",
            "rw",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1f7-1f1fc",
                "native": "🇷🇼"
            }
        ],
        "version": 2
    },
    "flag-sa": {
        "id": "flag-sa",
        "name": "Saudi Arabia Flag",
        "keywords": [
            "sa",
            "nation",
            "country",
            "banner",
            "サ",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1f8-1f1e6",
                "native": "🇸🇦"
            }
        ],
        "version": 2
    },
    "flag-sb": {
        "id": "flag-sb",
        "name": "Solomon Islands Flag",
        "keywords": [
            "sb",
            "nation",
            "country",
            "banner",
            "sb",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1f8-1f1e7",
                "native": "🇸🇧"
            }
        ],
        "version": 2
    },
    "flag-sc": {
        "id": "flag-sc",
        "name": "Seychelles Flag",
        "keywords": [
            "sc",
            "nation",
            "country",
            "banner",
            "sc",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1f8-1f1e8",
                "native": "🇸🇨"
            }
        ],
        "version": 2
    },
    "flag-sd": {
        "id": "flag-sd",
        "name": "Sudan Flag",
        "keywords": [
            "sd",
            "nation",
            "country",
            "banner",
            "SD",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1f8-1f1e9",
                "native": "🇸🇩"
            }
        ],
        "version": 2
    },
    "flag-se": {
        "id": "flag-se",
        "name": "Sweden Flag",
        "keywords": [
            "se",
            "nation",
            "country",
            "banner",
            "セ",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1f8-1f1ea",
                "native": "🇸🇪"
            }
        ],
        "version": 2
    },
    "flag-sg": {
        "id": "flag-sg",
        "name": "Singapore Flag",
        "keywords": [
            "sg",
            "nation",
            "country",
            "banner",
            "sg",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1f8-1f1ec",
                "native": "🇸🇬"
            }
        ],
        "version": 2
    },
    "flag-sh": {
        "id": "flag-sh",
        "name": "St. Helena Flag",
        "keywords": [
            "sh",
            "st",
            "saint",
            "ascension",
            "tristan",
            "cunha",
            "nation",
            "country",
            "banner",
            "し",
            "st",
            "聖人",
            "上昇",
            "トリスタン",
            "クンハ",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1f8-1f1ed",
                "native": "🇸🇭"
            }
        ],
        "version": 2
    },
    "flag-si": {
        "id": "flag-si",
        "name": "Slovenia Flag",
        "keywords": [
            "si",
            "nation",
            "country",
            "banner",
            "シ",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1f8-1f1ee",
                "native": "🇸🇮"
            }
        ],
        "version": 2
    },
    "flag-sj": {
        "id": "flag-sj",
        "name": "Svalbard & Jan Mayen Flag",
        "keywords": [
            "sj",
            "じ"
        ],
        "skins": [
            {
                "unified": "1f1f8-1f1ef",
                "native": "🇸🇯"
            }
        ],
        "version": 2
    },
    "flag-sk": {
        "id": "flag-sk",
        "name": "Slovakia Flag",
        "keywords": [
            "sk",
            "nation",
            "country",
            "banner",
            "スク",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1f8-1f1f0",
                "native": "🇸🇰"
            }
        ],
        "version": 2
    },
    "flag-sl": {
        "id": "flag-sl",
        "name": "Sierra Leone Flag",
        "keywords": [
            "sl",
            "nation",
            "country",
            "banner",
            "sl",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1f8-1f1f1",
                "native": "🇸🇱"
            }
        ],
        "version": 2
    },
    "flag-sm": {
        "id": "flag-sm",
        "name": "San Marino Flag",
        "keywords": [
            "sm",
            "nation",
            "country",
            "banner",
            "sm",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1f8-1f1f2",
                "native": "🇸🇲"
            }
        ],
        "version": 2
    },
    "flag-sn": {
        "id": "flag-sn",
        "name": "Senegal Flag",
        "keywords": [
            "sn",
            "nation",
            "country",
            "banner",
            "スン",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1f8-1f1f3",
                "native": "🇸🇳"
            }
        ],
        "version": 2
    },
    "flag-so": {
        "id": "flag-so",
        "name": "Somalia Flag",
        "keywords": [
            "so",
            "nation",
            "country",
            "banner",
            "それで",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1f8-1f1f4",
                "native": "🇸🇴"
            }
        ],
        "version": 2
    },
    "flag-sr": {
        "id": "flag-sr",
        "name": "Suriname Flag",
        "keywords": [
            "sr",
            "nation",
            "country",
            "banner",
            "sr",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1f8-1f1f7",
                "native": "🇸🇷"
            }
        ],
        "version": 2
    },
    "flag-ss": {
        "id": "flag-ss",
        "name": "South Sudan Flag",
        "keywords": [
            "ss",
            "sd",
            "nation",
            "country",
            "banner",
            "ss",
            "SD",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1f8-1f1f8",
                "native": "🇸🇸"
            }
        ],
        "version": 2
    },
    "flag-st": {
        "id": "flag-st",
        "name": "São Tomé & Príncipe Flag",
        "keywords": [
            "st",
            "sao",
            "tome",
            "principe",
            "nation",
            "country",
            "banner",
            "st",
            "さお",
            "私に",
            "プリンシペ",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1f8-1f1f9",
                "native": "🇸🇹"
            }
        ],
        "version": 2
    },
    "flag-sv": {
        "id": "flag-sv",
        "name": "El Salvador Flag",
        "keywords": [
            "sv",
            "nation",
            "country",
            "banner",
            "sv",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1f8-1f1fb",
                "native": "🇸🇻"
            }
        ],
        "version": 2
    },
    "flag-sx": {
        "id": "flag-sx",
        "name": "Sint Maarten Flag",
        "keywords": [
            "sx",
            "dutch",
            "nation",
            "country",
            "banner",
            "sx",
            "オランダの",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1f8-1f1fd",
                "native": "🇸🇽"
            }
        ],
        "version": 2
    },
    "flag-sy": {
        "id": "flag-sy",
        "name": "Syria Flag",
        "keywords": [
            "sy",
            "syrian",
            "arab",
            "republic",
            "nation",
            "country",
            "banner",
            "シ",
            "シリア",
            "アラブ",
            "共和国",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1f8-1f1fe",
                "native": "🇸🇾"
            }
        ],
        "version": 2
    },
    "flag-sz": {
        "id": "flag-sz",
        "name": "Eswatini Flag",
        "keywords": [
            "sz",
            "nation",
            "country",
            "banner",
            "sz",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1f8-1f1ff",
                "native": "🇸🇿"
            }
        ],
        "version": 2
    },
    "flag-ta": {
        "id": "flag-ta",
        "name": "Tristan Da Cunha Flag",
        "keywords": [
            "ta",
            "タ"
        ],
        "skins": [
            {
                "unified": "1f1f9-1f1e6",
                "native": "🇹🇦"
            }
        ],
        "version": 2
    },
    "flag-tc": {
        "id": "flag-tc",
        "name": "Turks & Caicos Islands Flag",
        "keywords": [
            "tc",
            "nation",
            "country",
            "banner",
            "tc",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1f9-1f1e8",
                "native": "🇹🇨"
            }
        ],
        "version": 2
    },
    "flag-td": {
        "id": "flag-td",
        "name": "Chad Flag",
        "keywords": [
            "td",
            "nation",
            "country",
            "banner",
            "td",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1f9-1f1e9",
                "native": "🇹🇩"
            }
        ],
        "version": 2
    },
    "flag-tf": {
        "id": "flag-tf",
        "name": "French Southern Territories Flag",
        "keywords": [
            "tf",
            "nation",
            "country",
            "banner",
            "tf",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1f9-1f1eb",
                "native": "🇹🇫"
            }
        ],
        "version": 2
    },
    "flag-tg": {
        "id": "flag-tg",
        "name": "Togo Flag",
        "keywords": [
            "tg",
            "nation",
            "country",
            "banner",
            "tg",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1f9-1f1ec",
                "native": "🇹🇬"
            }
        ],
        "version": 2
    },
    "flag-th": {
        "id": "flag-th",
        "name": "Thailand Flag",
        "keywords": [
            "th",
            "nation",
            "country",
            "banner",
            "番目",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1f9-1f1ed",
                "native": "🇹🇭"
            }
        ],
        "version": 2
    },
    "flag-tj": {
        "id": "flag-tj",
        "name": "Tajikistan Flag",
        "keywords": [
            "tj",
            "nation",
            "country",
            "banner",
            "tj",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1f9-1f1ef",
                "native": "🇹🇯"
            }
        ],
        "version": 2
    },
    "flag-tk": {
        "id": "flag-tk",
        "name": "Tokelau Flag",
        "keywords": [
            "tk",
            "nation",
            "country",
            "banner",
            "tk",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1f9-1f1f0",
                "native": "🇹🇰"
            }
        ],
        "version": 2
    },
    "flag-tl": {
        "id": "flag-tl",
        "name": "Timor-Leste Flag",
        "keywords": [
            "tl",
            "timor",
            "leste",
            "nation",
            "country",
            "banner",
            "tl",
            "ティモール",
            "レス",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1f9-1f1f1",
                "native": "🇹🇱"
            }
        ],
        "version": 2
    },
    "flag-tm": {
        "id": "flag-tm",
        "name": "Turkmenistan Flag",
        "keywords": [
            "tm",
            "nation",
            "country",
            "banner",
            "tm",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1f9-1f1f2",
                "native": "🇹🇲"
            }
        ],
        "version": 2
    },
    "flag-tn": {
        "id": "flag-tn",
        "name": "Tunisia Flag",
        "keywords": [
            "tn",
            "nation",
            "country",
            "banner",
            "tn",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1f9-1f1f3",
                "native": "🇹🇳"
            }
        ],
        "version": 2
    },
    "flag-to": {
        "id": "flag-to",
        "name": "Tonga Flag",
        "keywords": [
            "to",
            "nation",
            "country",
            "banner",
            "に",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1f9-1f1f4",
                "native": "🇹🇴"
            }
        ],
        "version": 2
    },
    "flag-tr": {
        "id": "flag-tr",
        "name": "Turkey Flag",
        "keywords": [
            "tr",
            "nation",
            "country",
            "banner",
            "トレ",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1f9-1f1f7",
                "native": "🇹🇷"
            }
        ],
        "version": 2
    },
    "flag-tt": {
        "id": "flag-tt",
        "name": "Trinidad & Tobago Flag",
        "keywords": [
            "tt",
            "nation",
            "country",
            "banner",
            "tt",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1f9-1f1f9",
                "native": "🇹🇹"
            }
        ],
        "version": 2
    },
    "flag-tv": {
        "id": "flag-tv",
        "name": "Tuvalu Flag",
        "keywords": [
            "tv",
            "nation",
            "country",
            "banner",
            "テレビ",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1f9-1f1fb",
                "native": "🇹🇻"
            }
        ],
        "version": 2
    },
    "flag-tw": {
        "id": "flag-tw",
        "name": "Taiwan Flag",
        "keywords": [
            "tw",
            "nation",
            "country",
            "banner",
            "トゥ",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1f9-1f1fc",
                "native": "🇹🇼"
            }
        ],
        "version": 2
    },
    "flag-tz": {
        "id": "flag-tz",
        "name": "Tanzania Flag",
        "keywords": [
            "tz",
            "united",
            "republic",
            "nation",
            "country",
            "banner",
            "ツ",
            "ユナイテッド",
            "共和国",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1f9-1f1ff",
                "native": "🇹🇿"
            }
        ],
        "version": 2
    },
    "flag-ua": {
        "id": "flag-ua",
        "name": "Ukraine Flag",
        "keywords": [
            "ua",
            "nation",
            "country",
            "banner",
            "あ",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1fa-1f1e6",
                "native": "🇺🇦"
            }
        ],
        "version": 2
    },
    "flag-ug": {
        "id": "flag-ug",
        "name": "Uganda Flag",
        "keywords": [
            "ug",
            "nation",
            "country",
            "banner",
            "ウグ",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1fa-1f1ec",
                "native": "🇺🇬"
            }
        ],
        "version": 2
    },
    "flag-um": {
        "id": "flag-um",
        "name": "U.s. Outlying Islands Flag",
        "keywords": [
            "um",
            "u",
            "s",
            "ええと",
            "あなた",
            "s"
        ],
        "skins": [
            {
                "unified": "1f1fa-1f1f2",
                "native": "🇺🇲"
            }
        ],
        "version": 2
    },
    "flag-un": {
        "id": "flag-un",
        "name": "United Nations Flag",
        "keywords": [
            "un",
            "banner",
            "国連",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1fa-1f1f3",
                "native": "🇺🇳"
            }
        ],
        "version": 4
    },
    "us": {
        "id": "us",
        "name": "United States Flag",
        "keywords": [
            "us",
            "america",
            "nation",
            "country",
            "banner",
            "私たち",
            "アメリカ",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1fa-1f1f8",
                "native": "🇺🇸"
            }
        ],
        "version": 1
    },
    "flag-uy": {
        "id": "flag-uy",
        "name": "Uruguay Flag",
        "keywords": [
            "uy",
            "nation",
            "country",
            "banner",
            "あなた",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1fa-1f1fe",
                "native": "🇺🇾"
            }
        ],
        "version": 2
    },
    "flag-uz": {
        "id": "flag-uz",
        "name": "Uzbekistan Flag",
        "keywords": [
            "uz",
            "nation",
            "country",
            "banner",
            "ウズ",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1fa-1f1ff",
                "native": "🇺🇿"
            }
        ],
        "version": 2
    },
    "flag-va": {
        "id": "flag-va",
        "name": "Vatican City Flag",
        "keywords": [
            "va",
            "nation",
            "country",
            "banner",
            "ヴァ",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1fb-1f1e6",
                "native": "🇻🇦"
            }
        ],
        "version": 2
    },
    "flag-vc": {
        "id": "flag-vc",
        "name": "St. Vincent & Grenadines Flag",
        "keywords": [
            "vc",
            "st",
            "saint",
            "nation",
            "country",
            "banner",
            "vc",
            "st",
            "聖人",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1fb-1f1e8",
                "native": "🇻🇨"
            }
        ],
        "version": 2
    },
    "flag-ve": {
        "id": "flag-ve",
        "name": "Venezuela Flag",
        "keywords": [
            "ve",
            "bolivarian",
            "republic",
            "nation",
            "country",
            "banner",
            "ve",
            "ボリバル",
            "共和国",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1fb-1f1ea",
                "native": "🇻🇪"
            }
        ],
        "version": 2
    },
    "flag-vg": {
        "id": "flag-vg",
        "name": "British Virgin Islands Flag",
        "keywords": [
            "vg",
            "bvi",
            "nation",
            "country",
            "banner",
            "vg",
            "bvi",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1fb-1f1ec",
                "native": "🇻🇬"
            }
        ],
        "version": 2
    },
    "flag-vi": {
        "id": "flag-vi",
        "name": "U.s. Virgin Islands Flag",
        "keywords": [
            "vi",
            "u",
            "s",
            "us",
            "nation",
            "country",
            "banner",
            "ヴィ",
            "あなた",
            "s",
            "私たち",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1fb-1f1ee",
                "native": "🇻🇮"
            }
        ],
        "version": 2
    },
    "flag-vn": {
        "id": "flag-vn",
        "name": "Vietnam Flag",
        "keywords": [
            "vn",
            "viet",
            "nam",
            "nation",
            "country",
            "banner",
            "vn",
            "ベトナム",
            "ナム",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1fb-1f1f3",
                "native": "🇻🇳"
            }
        ],
        "version": 2
    },
    "flag-vu": {
        "id": "flag-vu",
        "name": "Vanuatu Flag",
        "keywords": [
            "vu",
            "nation",
            "country",
            "banner",
            "ヴ",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1fb-1f1fa",
                "native": "🇻🇺"
            }
        ],
        "version": 2
    },
    "flag-wf": {
        "id": "flag-wf",
        "name": "Wallis & Futuna Flag",
        "keywords": [
            "wf",
            "nation",
            "country",
            "banner",
            "wf",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1fc-1f1eb",
                "native": "🇼🇫"
            }
        ],
        "version": 2
    },
    "flag-ws": {
        "id": "flag-ws",
        "name": "Samoa Flag",
        "keywords": [
            "ws",
            "nation",
            "country",
            "banner",
            "ws",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1fc-1f1f8",
                "native": "🇼🇸"
            }
        ],
        "version": 2
    },
    "flag-xk": {
        "id": "flag-xk",
        "name": "Kosovo Flag",
        "keywords": [
            "xk",
            "nation",
            "country",
            "banner",
            "Xのk",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1fd-1f1f0",
                "native": "🇽🇰"
            }
        ],
        "version": 2
    },
    "flag-ye": {
        "id": "flag-ye",
        "name": "Yemen Flag",
        "keywords": [
            "ye",
            "nation",
            "country",
            "banner",
            "あなた",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1fe-1f1ea",
                "native": "🇾🇪"
            }
        ],
        "version": 2
    },
    "flag-yt": {
        "id": "flag-yt",
        "name": "Mayotte Flag",
        "keywords": [
            "yt",
            "nation",
            "country",
            "banner",
            "yt",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1fe-1f1f9",
                "native": "🇾🇹"
            }
        ],
        "version": 2
    },
    "flag-za": {
        "id": "flag-za",
        "name": "South Africa Flag",
        "keywords": [
            "za",
            "nation",
            "country",
            "banner",
            "座",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1ff-1f1e6",
                "native": "🇿🇦"
            }
        ],
        "version": 2
    },
    "flag-zm": {
        "id": "flag-zm",
        "name": "Zambia Flag",
        "keywords": [
            "zm",
            "nation",
            "country",
            "banner",
            "zm",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1ff-1f1f2",
                "native": "🇿🇲"
            }
        ],
        "version": 2
    },
    "flag-zw": {
        "id": "flag-zw",
        "name": "Zimbabwe Flag",
        "keywords": [
            "zw",
            "nation",
            "country",
            "banner",
            "ず",
            "国家",
            "国",
            "バナー"
        ],
        "skins": [
            {
                "unified": "1f1ff-1f1fc",
                "native": "🇿🇼"
            }
        ],
        "version": 2
    },
    "flag-england": {
        "id": "flag-england",
        "name": "England Flag",
        "keywords": [
            "english",
            "英語"
        ],
        "skins": [
            {
                "unified": "1f3f4-e0067-e0062-e0065-e006e-e0067-e007f",
                "native": "🏴󠁧󠁢󠁥󠁮󠁧󠁿"
            }
        ],
        "version": 5
    },
    "flag-scotland": {
        "id": "flag-scotland",
        "name": "Scotland Flag",
        "keywords": [
            "scottish",
            "スコットランド"
        ],
        "skins": [
            {
                "unified": "1f3f4-e0067-e0062-e0073-e0063-e0074-e007f",
                "native": "🏴󠁧󠁢󠁳󠁣󠁴󠁿"
            }
        ],
        "version": 5
    },
    "flag-wales": {
        "id": "flag-wales",
        "name": "Wales Flag",
        "keywords": [
            "welsh",
            "ウェルシュ"
        ],
        "skins": [
            {
                "unified": "1f3f4-e0067-e0062-e0077-e006c-e0073-e007f",
                "native": "🏴󠁧󠁢󠁷󠁬󠁳󠁿"
            }
        ],
        "version": 5
    },

    // ------------------ カスタム ---------------------
    "arigatougozaimasu": {
        "id": "arigatougozaimasu",
        "name": "ありがとうございます",
        "keywords": [
            "arigatougozaimasu",
            "ありがとう",
            "ありがとうございます",
            "arigatou",
            "感謝",
            "thank you",
        ],
        "skins": [
            {
                src: "/images/emojis/arigatougozaimasu.png",
                native: "/images/emojis/arigatougozaimasu.png",
            },
        ],
        "type": "image",
        "version": 4
    },
    "yasasii": {
        "id": "yasasii",
        "name": "やさしい",
        "keywords": [
            "yasasii",
            "ありがとう",
            "うれしい",
            "優しい",
            "ありがとうございます",
            "arigatou",
            "感謝",
            "thank you",
        ],
        "skins": [
            {
                src: "/images/emojis/yasasii.png",
                native: "/images/emojis/yasasii.png",
            },
        ],
        "type": "image",
        "version": 4
    },
    "ojigi_gif": {
        "id": "ojigi_gif",
        "name": "動くおじぎ",
        "keywords": [
            "ojigi",
            "ありがとう",
            "おじぎ",
            "お辞儀",
            "ぺこ",
            "感謝",
            "thank you",
            "gif"
        ],
        "skins": [
            {
                src: "/images/emojis/ojigi.gif",
                native: "/images/emojis/ojigi.gif",
            },
        ],
        "type": "image",
        "version": 4
    },
    "lgtm": {
        "id": "lgtm",
        "name": "LGTM",
        "keywords": [
            "lgtm",
            "いいね",
            "good",
            "look",
            "エンジニア",
            "engineer",
            "プログラミング"
        ],
        "skins": [
            {
                src: "/images/emojis/lgtm.png",
                native: "/images/emojis/lgtm.png",
            },
        ],
        "type": "image",
        "version": 4
    },
    "gomenne": {
        "id": "gomenne",
        "name": "ごめんね",
        "keywords": [
            "gomenne",
            "ごめん",
            "ごめんなさい",
            "sorry",
        ],
        "skins": [
            {
                src: "/images/emojis/gomenne.png",
                native: "/images/emojis/gomenne.png",
            },
        ],
        "type": "image",
        "version": 4
    },
    "sumimasen": {
        "id": "sumimasen",
        "name": "すみません",
        "keywords": [
            "sumimasen",
            "すみません",
            "ごめん",
            "ごめんなさい",
        ],
        "skins": [
            {
                src: "/images/emojis/sumimasen.webp",
                native: "/images/emojis/sumimasen.webp",
            },
        ],
        "type": "image",
        "version": 4
    },
    "makotonimousiwakegozaimasensaihatubousisakuwokangaemasu": {
        "id": "makotonimousiwakegozaimasensaihatubousisakuwokangaemasu",
        "name": "誠に申し訳ございません再発防止策を考えます",
        "keywords": [
            "makotonimousiwakegozaimasensaihatubousisakuwokangaemasu",
            "すみません",
            "ごめん",
            "ごめんなさい",
            "誠に申し訳ございません再発防止策を考えます",
            "もうしわけない",
            "sumimasen",
            "mousiwake",
            "申し訳",
            "再発",
        ],
        "skins": [
            {
                src: "/images/emojis/makotonimousiwakegozaimasensaihatubousisakuwokangaemasu.png",
                native: "/images/emojis/makotonimousiwakegozaimasensaihatubousisakuwokangaemasu.png",
            },
        ],
        "type": "image",
        "version": 4
    },
    "syoutidesu": {
        "id": "syoutidesu",
        "name": "承知です",
        "keywords": [
            "了解",
            "承知",
            "しょうち",
            "承知しました",
            "承知です",
        ],
        "skins": [
            {
                src: "/images/emojis/syoutidesu.webp",
                native: "/images/emojis/syoutidesu.webp",
            },
        ],
        "type": "image",
        "version": 4
    },
    "god": {
        "id": "god",
        "name": "神",
        "keywords": [
            "god",
            "神",
            "かみ",
        ],
        "skins": [
            {
                src: "/images/emojis/god.png",
                native: "/images/emojis/god.png",
            },
        ],
        "type": "image",
        "version": 4
    },
    "kamitaiou": {
        "id": "kamitaiou",
        "name": "神対応",
        "keywords": [
            "kamitaiou",
            "arigatou",
            "kansya",
            "神",
            "かみ",
            "ありがと",
            "感謝",
            "すばら",
            "kami",
        ],
        "skins": [
            {
                src: "/images/emojis/kamitaiou.png",
                native: "/images/emojis/kamitaiou.png",
            },
        ],
        "type": "image",
        "version": 4
    },
    "tondemogozaimasen": {
        "id": "tondemogozaimasen",
        "name": "とんでもございません",
        "keywords": [
            "tondemogozaimasen",
            "sumimasen",
            "とんでもない",
            "とんでもございません",
            "kansya",
            "arigatou",
            "ありがと",
            "感謝",
            "すばら",
            "tondemonaidesu",
            "ieie",
            "いえいえ",
            "こちらこそ",
            "そんなことない",
            "とんでもない",
            "ありがとう",
        ],
        "skins": [
            {
                src: "/images/emojis/tondemogozaimasen.png",
                native: "/images/emojis/tondemogozaimasen.png",
            },
        ],
        "type": "image",
        "version": 4
    },
    "yorosikuonegaisimasu": {
        "id": "yorosikuonegaisimasu",
        "name": "よろしくお願いします",
        "keywords": [
            "よろ",
            "よろしく",
            "宜しく",
            "よろしくお願いします",
            "よろしくおねがいします",
            "yorosiku",
        ],
        "skins": [
            {
                src: "/images/emojis/yorosikuonegaisimasu.webp",
                native: "/images/emojis/yorosikuonegaisimasu.webp",
            },
        ],
        "type": "image",
        "version": 4
    },
    "naruhodo": {
        "id": "naruhodo",
        "name": "なるほど",
        "keywords": [
            "なるほど",
            "なる",
            "naruhodo",
            "成る程",
        ],
        "skins": [
            {
                src: "/images/emojis/naruhodo.png",
                native: "/images/emojis/naruhodo.png",
            },
        ],
        "type": "image",
        "version": 4
    },
    "done": {
        "id": "done",
        "name": "DONE",
        "keywords": [
            "done",
            "終わり",
            "どね",
            "だん",
            "完了",
        ],
        "skins": [
            {
                src: "/images/emojis/done.png",
                native: "/images/emojis/done.png",
            },
        ],
        "type": "image",
        "version": 4
    },
    "kakuninsimasu": {
        "id": "kakuninsimasu",
        "name": "確認します",
        "keywords": [
            "確認",
            "確認します",
            "kakunin",
            "かくにん",
        ],
        "skins": [
            {
                src: "/images/emojis/kakuninsimasu.png",
                native: "/images/emojis/kakuninsimasu.png",
            },
        ],
        "type": "image",
        "version": 4
    },
    "tasikani": {
        "id": "tasikani",
        "name": "たしかに",
        "keywords": [
            "たしかに",
            "確かに",
            "tasikani",
        ],
        "skins": [
            {
                src: "/images/emojis/tasikani.png",
                native: "/images/emojis/tasikani.png",
            },
        ],
        "type": "image",
        "version": 4
    },
    "tasikani_image": {
        "id": "tasikani_image",
        "name": "たし蟹",
        "keywords": [
            "たしかに",
            "確かに",
            "tasikani_image",
            "かに",
            "kani"
        ],
        "skins": [
            {
                src: "/images/emojis/tasikani_image.png",
                native: "/images/emojis/tasikani_image.png",
            },
        ],
        "type": "image",
        "version": 4
    },
    "taioutyuu": {
        "id": "taioutyuu",
        "name": "対応中...",
        "keywords": [
            "対応",
            "対応中",
            "doing",
            "taiou",
        ],
        "skins": [
            {
                src: "/images/emojis/taioutyuu.webp",
                native: "/images/emojis/taioutyuu.webp",
            },
        ],
        "type": "image",
        "version": 4
    },
    "gyoi": {
        "id": "gyoi",
        "name": "御意",
        "keywords": [
            "gyoi",
            "ぎょい",
            "御意",
        ],
        "skins": [
            {
                src: "/images/emojis/gyoi.png",
                native: "/images/emojis/gyoi.png",
            },
        ],
        "type": "image",
        "version": 4
    },

    "ok": {
        "id": "ok",
        "name": "OK",
        "keywords": [
            "ok",
            "おっけー",
        ],
        "skins": [
            {
                src: "/images/emojis/ok.png",
                native: "/images/emojis/ok.png",
            },
        ],
        "type": "image",
        "version": 4
    },
    "taibouno": {
        "id": "taibouno",
        "name": "待望の",
        "keywords": [
            "待望の",
            "taibouno",
        ],
        "skins": [
            {
                src: "/images/emojis/taibouno.png",
                native: "/images/emojis/taibouno.png",
            },
        ],
        "type": "image",
        "version": 4
    },
    "syusseki": {
        "id": "syusseki",
        "name": "出席",
        "keywords": [
            "出席",
            "syusseki",
        ],
        "skins": [
            {
                src: "/images/emojis/syusseki.png",
                native: "/images/emojis/syusseki.png",
            },
        ],
        "type": "image",
        "version": 4
    },
    "kesseki": {
        "id": "kesseki",
        "name": "欠席",
        "keywords": [
            "欠席",
            "kesseki",
        ],
        "skins": [
            {
                src: "/images/emojis/kesseki.png",
                native: "/images/emojis/kesseki.png",
            },
        ],
        "type": "image",
        "version": 4
    },
    "sasuga": {
        "id": "sasuga",
        "name": "さすが！",
        "keywords": [
            "sasuga",
            "さすが",
            "流石"
        ],
        "skins": [
            {
                src: "/images/emojis/sasuga.png",
                native: "/images/emojis/sasuga.png",
            },
        ],
        "type": "image",
        "version": 4
    },
    "saikou": {
        "id": "saikou",
        "name": "最高",
        "keywords": [
            "最高",
            "saikou",
        ],
        "skins": [
            {
                src: "/images/emojis/saikou.png",
                native: "/images/emojis/saikou.png",
            },
        ],
        "type": "image",
        "version": 4
    },
    "ryoukaidesu": {
        "id": "ryoukaidesu",
        "name": "了解です",
        "keywords": [
            "了解です",
            "了解",
            "ryoukaidesu",
        ],
        "skins": [
            {
                src: "/images/emojis/ryoukaidesu.png",
                native: "/images/emojis/ryoukaidesu.png",
            },
        ],
        "type": "image",
        "version": 4
    },
    "sumi": {
        "id": "sumi",
        "name": "済み",
        "keywords": [
            "済み",
            "ずみ",
            "sumi",
        ],
        "skins": [
            {
                src: "/images/emojis/sumi.png",
                native: "/images/emojis/sumi.png",
            },
        ],
        "type": "image",
        "version": 4
    },
    "odaizini": {
        "id": "odaizini",
        "name": "お大事に",
        "keywords": [
            "お大事に",
            "odaizini",
        ],
        "skins": [
            {
                src: "/images/emojis/odaizini.png",
                native: "/images/emojis/odaizini.png",
            },
        ],
        "type": "image",
        "version": 4
    },
    "asitadeok": {
        "id": "asitadeok",
        "name": "明日でOK",
        "keywords": [
            "ok",
            "asita",
            "明日でOK",
        ],
        "skins": [
            {
                src: "/images/emojis/asitadeok.png",
                native: "/images/emojis/asitadeok.png",
            },
        ],
        "type": "image",
        "version": 4
    },
    "wara": {
        "id": "wara",
        "name": "wara",
        "keywords": [
            "wara",
            "笑う",
            "笑",
            "w",
        ],
        "skins": [
            {
                src: "/images/emojis/wara.png",
                native: "/images/emojis/wara.png",
            },
        ],
        "type": "image",
        "version": 4
    },
    "ww": {
        "id": "ww",
        "name": "ww",
        "keywords": [
            "wara",
            "笑う",
            "笑",
            "w",
            "ww"
        ],
        "skins": [
            {
                src: "/images/emojis/ww.png",
                native: "/images/emojis/ww.png",
            },
        ],
        "type": "image",
        "version": 4
    },
    "rocket_gif": {
        "id": "rocket_gif",
        "name": "rocket_gif",
        "keywords": [
            "rocket",
            "gif",
        ],
        "skins": [
            {
                src: "/images/emojis/rocket_gif.gif",
                native: "/images/emojis/rocket_gif.gif",
            },
        ],
        "type": "image",
        "version": 4
    },
    "kidoku": {
        "id": "kidoku",
        "name": "既読",
        "keywords": [
            "既読",
            "kidoku",
        ],
        "skins": [
            {
                src: "/images/emojis/kidoku.png",
                native: "/images/emojis/kidoku.png",
            },
        ],
        "type": "image",
        "version": 4
    },
    "yosasou": {
        "id": "yosasou",
        "name": "良さそう",
        "keywords": [
            "yosasou",
            "よさそう",
            "良さそう",
            "LGTM",
            "良い"
        ],
        "skins": [
            {
                src: "/images/emojis/yosasou.png",
                native: "/images/emojis/yosasou.png",
            },
        ],
        "type": "image",
        "version": 4
    },
    "otukaresamadesu": {
        "id": "otukaresamadesu",
        "name": "お疲れ様です",
        "keywords": [
            "otukaresamadesu",
            "お疲れ",
            "お疲れ様です",
            "お疲れ様",
            "おつかれ",
            "おつ",
        ],
        "skins": [
            {
                src: "/images/emojis/otukaresamadesu.png",
                native: "/images/emojis/otukaresamadesu.png",
            },
        ],
        "type": "image",
        "version": 4
    },
    "otyadouzo": {
        "id": "otyadouzo",
        "name": "お茶どうぞ",
        "keywords": [
            "otyadouzo",
            "お疲れ",
            "お疲れ様です",
            "お疲れ様",
            "おつかれ",
            "おつ",
            "otya",
            "おつ",
            "ちゃ",
            "おちゃ",
            "どうぞ",
        ],
        "skins": [
            {
                src: "/images/emojis/otyadouzo.png",
                native: "/images/emojis/otyadouzo.png",
            },
        ],
        "type": "image",
        "version": 4
    },
    "sarani": {
        "id": "sarani",
        "name": "さらに",
        "keywords": [
            "sarani",
            "さらに",
            "もっと",
            "ますます",
            "いいね",
            "good",
        ],
        "skins": [
            {
                src: "/images/emojis/sarani.png",
                native: "/images/emojis/sarani.png",
            },
        ],
        "type": "image",
        "version": 4
    },
    "osu": {
        "id": "osu",
        "name": "押忍",
        "keywords": [
            "osu",
            "おす",
            "押忍",
        ],
        "skins": [
            {
                src: "/images/emojis/osu.png",
                native: "/images/emojis/osu.png",
            },
        ],
        "type": "image",
        "version": 4
    },
    "kansya": {
        "id": "kansya",
        "name": "感謝",
        "keywords": [
            "arigatougozaimasu",
            "ありがとう",
            "ありがとうございます",
            "arigatou",
            "感謝",
            "thank you",
            "kansya",
        ],
        "skins": [
            {
                src: "/images/emojis/kansya.png",
                native: "/images/emojis/kansya.png",
            },
        ],
        "type": "image",
        "version": 4
    },

    "azasu": {
        "id": "azasu",
        "name": "あざす",
        "keywords": [
            "arigatougozaimasu",
            "ありがとう",
            "ありがとうございます",
            "arigatou",
            "感謝",
            "thank you",
            "azasu",
            "あざす"
        ],
        "skins": [
            {
                src: "/images/emojis/azasu.png",
                native: "/images/emojis/azasu.png",
            },
        ],
        "type": "image",
        "version": 4
    },

    "yoisyo": {
        "id": "yoisyo",
        "name": "ヨイショ",
        "keywords": [
            "ヨイショ",
            "yoisyo",
        ],
        "skins": [
            {
                src: "/images/emojis/yoisyo.png",
                native: "/images/emojis/yoisyo.png",
            },
        ],
        "type": "image",
        "version": 4
    },

    "okininasarazu": {
        "id": "okininasarazu",
        "name": "お気になさらず",
        "keywords": [
            "okininasarazu",
            "お気になさらず",
        ],
        "skins": [
            {
                src: "/images/emojis/okininasarazu.png",
                native: "/images/emojis/okininasarazu.png",
            },
        ],
        "type": "image",
        "version": 4
    },
    "raja": {
        "id": "raja",
        "name": "ラジャ",
        "keywords": [
            "ラジャ",
            "了解",
            "raja",
            "らじゃ",
        ],
        "skins": [
            {
                src: "/images/emojis/raja.png",
                native: "/images/emojis/raja.png",
            },
        ],
        "type": "image",
        "version": 4
    },
    "sigodeki": {
        "id": "sigodeki",
        "name": "しごでき",
        "keywords": [
            "sigodeki",
            "しごでき",
        ],
        "skins": [
            {
                src: "/images/emojis/sigodeki.png",
                native: "/images/emojis/sigodeki.png",
            },
        ],
        "type": "image",
        "version": 4
    },
    "thinking_face_gif": {
        "id": "thinking_face_gif",
        "name": "Thinking Face GIF",
        "keywords": [
            "hmmm",
            "think",
            "consider",
            "うーん",
            "考え",
            "検討",
            "gif"
        ],
        "skins": [
            {
                src: "/images/emojis/thinking_face_gif.gif",
                native: "/images/emojis/thinking_face_gif.gif",
            },
        ],
        "type": "image",
        "version": 1
    },
    "taiousimasu": {
        "id": "taiousimasu",
        "name": "対応します",
        "keywords": [
            "対応します",
            "taiousimasu",
            "やります",
            "taiou"
        ],
        "skins": [
            {
                src: "/images/emojis/taiousimasu.png",
                native: "/images/emojis/taiousimasu.png",
            },
        ],
        "type": "image",
        "version": 4
    },
    "attoutekikansya": {
        "id": "attoutekikansya",
        "name": "圧倒的感謝",
        "keywords": [
            "感謝",
            "圧倒的感謝",
            "ありがとう",
            "thank you",
        ],
        "skins": [
            {
                src: "/images/emojis/attoutekikansya.png",
                native: "/images/emojis/attoutekikansya.png",
            },
        ],
        "type": "image",
        "version": 4
    },
    "emoi": {
        "id": "emoi",
        "name": "エモい",
        "keywords": [
            "エモい",
            "emoi",
        ],
        "skins": [
            {
                src: "/images/emojis/emoi.png",
                native: "/images/emojis/emoi.png",
            },
        ],
        "type": "image",
        "version": 4
    },
    "kawaii": {
        "id": "kawaii",
        "name": "かわいい",
        "keywords": [
            "対応します",
            "kawaii",
            "かわいい",
            "可愛い"
        ],
        "skins": [
            {
                src: "/images/emojis/kawaii.png",
                native: "/images/emojis/kawaii.png",
            },
        ],
        "type": "image",
        "version": 4
    },
    "siawase": {
        "id": "siawase",
        "name": "幸",
        "keywords": [
            "幸せ",
            "siawase",
            "しあわせ",
            "幸"
        ],
        "skins": [
            {
                src: "/images/emojis/siawase.png",
                native: "/images/emojis/siawase.png",
            },
        ],
        "type": "image",
        "version": 4
    },
    "suteki": {
        "id": "suteki",
        "name": "素敵",
        "keywords": [
            "素敵",
            "suteki",
            "すてき",
        ],
        "skins": [
            {
                src: "/images/emojis/suteki.png",
                native: "/images/emojis/suteki.png",
            },
        ],
        "type": "image",
        "version": 4
    },
    "uresii": {
        "id": "uresii",
        "name": "うれしい",
        "keywords": [
            "うれしい",
            "uresii",
            "嬉しい",
        ],
        "skins": [
            {
                src: "/images/emojis/uresii.png",
                native: "/images/emojis/uresii.png",
            },
        ],
        "type": "image",
        "version": 4
    },
    "joy_gif": {
        "id": "joy gif",
        "name": "Face with Tears of Joy GIF",
        "keywords": [
            "cry",
            "weep",
            "happy",
            "happytears",
            "haha",
            "gif",
            "泣く",
            "泣く",
            "ハッピー",
            "ハッピーティアーズ",
            "ハハ"
        ],
        "skins": [
            {
                src: "/images/emojis/joy_gif.gif",
                native: "/images/emojis/joy_gif.gif",
            },
        ],
        "type": "image",
        "version": 4
    },
    "wakaru": {
        "id": "wakaru",
        "name": "わかる",
        "keywords": [
            "wakaru",
            "分かる",
            "わかる",
            "happytears",
            "haha",
            "gif",
            "泣く",
            "泣く",
            "ハッピー",
            "ハッピーティアーズ",
            "ハハ"
        ],
        "skins": [
            {
                src: "/images/emojis/wakaru.png",
                native: "/images/emojis/wakaru.png",
            },
        ],
        "type": "image",
        "version": 4
    },
    "wakaru": {
        "id": "wakaru",
        "name": "わかる",
        "keywords": [
            "wakaru",
            "分かる",
            "わかる",
            "happytears",
            "haha",
            "gif",
            "泣く",
            "泣く",
            "ハッピー",
            "ハッピーティアーズ",
            "ハハ"
        ],
        "skins": [
            {
                src: "/images/emojis/wakaru.png",
                native: "/images/emojis/wakaru.png",
            },
        ],
        "type": "image",
        "version": 4
    },
    "humuhumu": {
        "id": "humuhumu",
        "name": "ふむふむ",
        "keywords": [
            "humuhumu",
            "ふむふむ",
        ],
        "skins": [
            {
                src: "/images/emojis/humuhumu.png",
                native: "/images/emojis/humuhumu.png",
            },
        ],
        "type": "image",
        "version": 4
    },
    "situreisimasita": {
        "id": "situreisimasita",
        "name": "失礼しました",
        "keywords": [
            "situreisimasita",
            "失礼",
            "しつれい",
            "失礼しました",
        ],
        "skins": [
            {
                src: "/images/emojis/situreisimasita.png",
                native: "/images/emojis/situreisimasita.png",
            },
        ],
        "type": "image",
        "version": 4
    },
    "arigatougozaimasu_gif": {
        "id": "arigatougozaimasu_gif",
        "name": "ありがとうございます GIF",
        "keywords": [
            "arigatougozaimasu",
            "ありがとう",
            "ありがとうございます",
            "arigatou",
            "感謝",
            "thank you",
            "gif"
        ],
        "skins": [
            {
                src: "/images/emojis/arigatougozaimasu_gif.gif",
                native: "/images/emojis/arigatougozaimasu_gif.gif",
            },
        ],
        "type": "image",
        "version": 4
    },
    "iine": {
        "id": "iine",
        "name": "いいね",
        "keywords": [
            "iine",
            "いいね",
            "いいやん",
        ],
        "skins": [
            {
                src: "/images/emojis/iine.png",
                native: "/images/emojis/iine.png",
            },
        ],
        "type": "image",
        "version": 4
    },
    "nice": {
        "id": "nice",
        "name": "ナイス",
        "keywords": [
            "nice",
            "ナイス",
        ],
        "skins": [
            {
                src: "/images/emojis/nice.png",
                native: "/images/emojis/nice.png",
            },
        ],
        "type": "image",
        "version": 4
    },
    "dotou": {
        "id": "dotou",
        "name": "怒涛",
        "keywords": [
            "dotou",
            "怒涛",
        ],
        "skins": [
            {
                src: "/images/emojis/dotou.png",
                native: "/images/emojis/dotou.png",
            },
        ],
        "type": "image",
        "version": 4
    },
    "dotou": {
        "id": "dotou",
        "name": "怒涛",
        "keywords": [
            "dotou",
            "怒涛",
        ],
        "skins": [
            {
                "src": "/images/emojis/dotou.png",
                "native": "/images/emojis/dotou.png",
            },
        ],
        "type": "image",
        "version": 4
    },
    "gj": {
        "id": "gj",
        "name": "GJ",
        "keywords": [
            "gj",
            "Good Job"
        ],
        "skins": [
            {
                "src": "/images/emojis/gj.png",
                "native": "/images/emojis/gj.png",
            },
        ],
        "type": "image",
        "version": 4
    },
    "haya": {
        "id": "haya",
        "name": "早っ",
        "keywords": [
            "haya",
            "早"
        ],
        "skins": [
            {
                "src": "/images/emojis/haya.png",
                "native": "/images/emojis/haya.png",
            },
        ],
        "type": "image",
        "version": 4
    },
    "kakutei": {
        "id": "kakutei",
        "name": "確定",
        "keywords": [
            "kakutei",
            "確定"
        ],
        "skins": [
            {
                "src": "/images/emojis/kakutei.png",
                "native": "/images/emojis/kakutei.png",
            },
        ],
        "type": "image",
        "version": 4
    },
    "kininaru": {
        "id": "kininaru",
        "name": "気になる",
        "keywords": [
            "kininaru",
            "気になる",
            "きになる"
        ],
        "skins": [
            {
                "src": "/images/emojis/kininaru.png",
                "native": "/images/emojis/kininaru.png",
            },
        ],
        "type": "image",
        "version": 4
    },
    "iidesune": {
        "id": "iidesune",
        "name": "いいですね",
        "keywords": [
            "iidesune",
            "いいね",
            "いい"
        ],
        "skins": [
            {
                "src": "/images/emojis/iidesune.png",
                "native": "/images/emojis/iidesune.png",
            },
        ],
        "type": "image",
        "version": 4
    },
    "motirondesu": {
        "id": "motirondesu",
        "name": "もちろんです",
        "keywords": [
            "motirondesu",
            "いいね",
            "desune",
            "もち",
            "もちろん",
            "いい"
        ],
        "skins": [
            {
                "src": "/images/emojis/motirondesu.png",
                "native": "/images/emojis/motirondesu.png",
            },
        ],
        "type": "image",
        "version": 4
    },
    "iketeru": {
        "id": "iketeru",
        "name": "イケてる",
        "keywords": [
            "iketeru",
            "イケてる",
            "いいね",
            "いけてる",
            "いけ"
        ],
        "skins": [
            {
                "src": "/images/emojis/iketeru.png",
                "native": "/images/emojis/iketeru.png",
            },
        ],
        "type": "image",
        "version": 4
    },
    "heart_gif": {
        "id": "heart_gif",
        "name": "ハート GIF",
        "keywords": [
            "ありがとう",
            "heart",
            "ハート",
            "love",
            "gif"
        ],
        "skins": [
            {
                src: "/images/emojis/heart_gif.webp",
                native: "/images/emojis/heart_gif.webp",
            },
        ],
        "type": "image",
        "version": 4
    },
    "atu": {
        "id": "atu",
        "name": "熱",
        "keywords": [
            "atu",
            "あつ",
            "アツ",
            "熱",
            "hot",
            "暑"
        ],
        "skins": [
            {
                "src": "/images/emojis/atu.png",
                "native": "/images/emojis/atu.png",
            },
        ],
        "type": "image",
        "version": 4
    },
    "ganbarimasu": {
        "id": "ganbarimasu",
        "name": "頑張ります",
        "keywords": [
            "ganbarimasu",
            "頑張ります",
            "がんばる",
            "がんば",
            "ガンバ",
        ],
        "skins": [
            {
                "src": "/images/emojis/ganbarimasu.png",
                "native": "/images/emojis/ganbarimasu.png",
            },
        ],
        "type": "image",
        "version": 4
    },
    "kotirakoso": {
        "id": "kotirakoso",
        "name": "こちらこそ",
        "keywords": [
            "kotirakoso",
            "こちらこそ",
        ],
        "skins": [
            {
                "src": "/images/emojis/kotirakoso.png",
                "native": "/images/emojis/kotirakoso.png",
            },
        ],
        "type": "image",
        "version": 4
    },
    "kuse": {
        "id": "kuse",
        "name": "癖",
        "keywords": [
            "kuse",
            "くせ",
            "癖",
        ],
        "skins": [
            {
                "src": "/images/emojis/kuse.png",
                "native": "/images/emojis/kuse.png",
            },
        ],
        "type": "image",
        "version": 4
    },
    "manji": {
        "id": "manji",
        "name": "卍",
        "keywords": [
            "manji",
            "まんじ",
            "卍",
        ],
        "skins": [
            {
                "src": "/images/emojis/manji.png",
                "native": "/images/emojis/manji.png",
            },
        ],
        "type": "image",
        "version": 4
    },
    "nanitozo": {
        "id": "nanitozo",
        "name": "何卒",
        "keywords": [
            "nanitozo",
            "何卒",
            "なにとぞ",
        ],
        "skins": [
            {
                "src": "/images/emojis/nanitozo.png",
                "native": "/images/emojis/nanitozo.png",
            },
        ],
        "type": "image",
        "version": 4
    },
    "doui": {
        "id": "doui",
        "name": "同意",
        "keywords": [
            "doui",
            "同意",
            "どうい",
        ],
        "skins": [
            {
                "src": "/images/emojis/doui.png",
                "native": "/images/emojis/doui.png",
            },
        ],
        "type": "image",
        "version": 4
    },
    "doukanndesu": {
        "id": "doukanndesu",
        "name": "同感です",
        "keywords": [
            "doukanndesu",
            "同意",
            "doui",
            "どうい",
            "どうかん",
            "同感",
            "おなじく",
            "おなじ",
            "onazi",
        ],
        "skins": [
            {
                "src": "/images/emojis/doukanndesu.png",
                "native": "/images/emojis/doukanndesu.png",
            },
        ],
        "type": "image",
        "version": 4
    },
    "gekiatu": {
        "id": "gekiatu",
        "name": "激アツ",
        "keywords": [
            "gekiatu",
            "激アツ",
            "あつ",
            "アツ",
            "熱",
        ],
        "skins": [
            {
                "src": "/images/emojis/gekiatu.png",
                "native": "/images/emojis/gekiatu.png",
            },
        ],
        "type": "image",
        "version": 4
    },
    "sorena": {
        "id": "sorena",
        "name": "それな",
        "keywords": [
            "sorena",
            "それな",
            "それ",
        ],
        "skins": [
            {
                "src": "/images/emojis/sorena.png",
                "native": "/images/emojis/sorena.png",
            },
        ],
        "type": "image",
        "version": 4
    },
    "subara": {
        "id": "subara",
        "name": "すばら",
        "keywords": [
            "subara",
            "すばら",
            "素晴らしい",
        ],
        "skins": [
            {
                "src": "/images/emojis/subara.png",
                "native": "/images/emojis/subara.png",
            },
        ],
        "type": "image",
        "version": 4
    },
    "gogogogo": {
        "id": "gogogogo",
        "name": "ゴゴゴゴ",
        "keywords": [
            "gogogogo",
            "ゴゴゴゴ",
            "ごご"
        ],
        "skins": [
            {
                "src": "/images/emojis/gogogogo.webp",
                "native": "/images/emojis/gogogogo.webp",
            },
        ],
        "type": "image",
        "version": 4
    },
    "tyou": {
        "id": "tyou",
        "name": "超",
        "keywords": [
            "金",
            "kin",
            "ちょう",
            "超"
        ],
        "skins": [
            {
                "src": "/images/emojis/tyou.png",
                "native": "/images/emojis/tyou.png",
            },
        ],
        "type": "image",
        "version": 4
    },
    "merge": {
        "id": "merge",
        "name": "マージ",
        "keywords": [
            "まーじ",
            "マージ",
            "merge",
            "エンジニア",
            "engineer",
            "プログラミング"
        ],
        "skins": [
            {
                "src": "/images/emojis/merge.png",
                "native": "/images/emojis/merge.png",
            },
        ],
        "type": "image",
        "version": 4
    },
    "merge_icon": {
        "id": "merge_icon",
        "name": "merge_icon",
        "keywords": [
            "まーじ",
            "マージ",
            "merge",
            "エンジニア",
            "engineer",
            "プログラミング"
        ],
        "skins": [
            {
                "src": "/images/emojis/marge-icon.png",
                "native": "/images/emojis/marge-icon.png",
            },
        ],
        "type": "image",
        "version": 4
    },
    "syousyouomatikudasai": {
        "id": "syousyouomatikudasai",
        "name": "少々お待ちください",
        "keywords": [
            "おまち",
            "まって",
            "少々お待ちください",
            "syousyouomatikudasai",
            "まつ",
            "すこし",
            "mati",
            "matu"
        ],
        "skins": [
            {
                "src": "/images/emojis/syousyouomatikudasai.png",
                "native": "/images/emojis/syousyouomatikudasai.png",
            },
        ],
        "type": "image",
        "version": 4
    },

    "tasukaru": {
        "id": "tasukaru",
        "name": "助かる",
        "keywords": [
            "たすかる",
            "tasukaru",
            "助かる",
        ],
        "skins": [
            {
                "src": "/images/emojis/tasukaru.png",
                "native": "/images/emojis/tasukaru.png",
            },
        ],
        "type": "image",
        "version": 4
    },
    "tasukarimasu": {
        "id": "tasukarimasu",
        "name": "助かります",
        "keywords": [
            "たすかる",
            "tasukaru",
            "助かる",
            "助かります",
            "tasukarimasu",
        ],
        "skins": [
            {
                "src": "/images/emojis/tasukarimasu.png",
                "native": "/images/emojis/tasukarimasu.png",
            },
        ],
        "type": "image",
        "version": 4
    },
    "arigato": {
        "id": "arigato",
        "name": "ありがと",
        "keywords": [
            "ありがと",
            "arigato",
            "感謝"
        ],
        "skins": [
            {
                "src": "/images/emojis/arigato.png",
                "native": "/images/emojis/arigato.png"
            }
        ],
        "type": "image",
        "version": 4
    },
    "kakuninzumi": {
        "id": "kakuninzumi",
        "name": "確認済み",
        "keywords": [
            "確認済み",
            "kakuninzumi",
            "確認完了",
            "かくにん"
        ],
        "skins": [
            {
                "src": "/images/emojis/kakuninzumi.png",
                "native": "/images/emojis/kakuninzumi.png"
            }
        ],
        "type": "image",
        "version": 4
    },
    "sugoi": {
        "id": "sugoi",
        "name": "すごい",
        "keywords": [
            "すごい",
            "sugoi",
            "凄い"
        ],
        "skins": [
            {
                "src": "/images/emojis/sugoi.png",
                "native": "/images/emojis/sugoi.png"
            }
        ],
        "type": "image",
        "version": 4
    },
    "taiouzumi": {
        "id": "taiouzumi",
        "name": "対応済み",
        "keywords": [
            "対応済み",
            "taiouzumi",
            "対応完了",
            "たいおう"
        ],
        "skins": [
            {
                "src": "/images/emojis/taiouzumi.png",
                "native": "/images/emojis/taiouzumi.png"
            }
        ],
        "type": "image",
        "version": 4
    },
    "tensai": {
        "id": "tensai",
        "name": "天才",
        "keywords": [
            "天才",
            "tensai",
            "才能",
            "てんさい"
        ],
        "skins": [
            {
                "src": "/images/emojis/tensai.png",
                "native": "/images/emojis/tensai.png"
            }
        ],
        "type": "image",
        "version": 4
    },
    "murisezu": {
        "id": "murisezu",
        "name": "無理せず",
        "keywords": [
            "無理せず",
            "murisezu",
            "むり",
            "muri"
        ],
        "skins": [
            {
                "src": "/images/emojis/murisezu.png",
                "native": "/images/emojis/murisezu.png"
            }
        ],
        "type": "image",
        "version": 4
    },
    "panda_img": {
        "id": "panda_img",
        "name": "パンダ",
        "keywords": [
            "face",
            "panda",
            "animal",
            "nature",
            "顔",
            "動物",
            "自然"
        ],
        "skins": [
            {
                "src": "/images/emojis/panda_img.png",
                "native": "/images/emojis/panda_img.png"
            }
        ],
        "type": "image",
        "version": 4
    },
    "aoi_neko": {
        "id": "aoi_neko",
        "name": "青い猫",
        "keywords": [
            "にゃんちゅう",
            "ねこ",
            "neko",
            "あおい",
            "blue",
            "動物",
            "ao",
            "nyan",
            "tyu",
            "ねずみ",
            "猫",
            "自然"
        ],
        "skins": [
            {
                "src": "/images/emojis/aoi_neko.png",
                "native": "/images/emojis/aoi_neko.png"
            }
        ],
        "type": "image",
        "version": 4
    },
    "maturi": {
        "id": "maturi",
        "name": "祭",
        "keywords": [
            "maturi",
            "祭",
            "まつり",
        ],
        "skins": [
            {
                "src": "/images/emojis/maturi.png",
                "native": "/images/emojis/maturi.png"
            }
        ],
        "type": "image",
        "version": 4
    },
    "syuuseisimasita": {
        "id": "syuuseisimasita",
        "name": "修正しました",
        "keywords": [
            "修正しました",
            "syuuseisimasita",
        ],
        "skins": [
            {
                "src": "/images/emojis/syuuseisimasita.png",
                "native": "/images/emojis/syuuseisimasita.png"
            }
        ],
        "type": "image",
        "version": 4
    },
    "kita": {
        "id": "kita",
        "name": "キタッ",
        "keywords": [
            "kita",
            "キタッ",
            "きた",
        ],
        "skins": [
            {
                "src": "/images/emojis/kita.png",
                "native": "/images/emojis/kita.png"
            }
        ],
        "type": "image",
        "version": 4
    },
    "mondainasasou": {
        "id": "mondainasasou",
        "name": "問題なさそう",
        "keywords": [
            "問題なさそう",
            "mondainasasou",
            "もんだい",
        ],
        "skins": [
            {
                "src": "/images/emojis/mondainasasou.png",
                "native": "/images/emojis/mondainasasou.png"
            }
        ],
        "type": "image",
        "version": 4
    },
    "maturi": {
        "id": "maturi",
        "name": "祭",
        "keywords": [
            "maturi",
            "祭",
            "まつり",
        ],
        "skins": [
            {
                "src": "/images/emojis/maturi.png",
                "native": "/images/emojis/maturi.png"
            }
        ],
        "type": "image",
        "version": 4
    },
    "murisezu": {
        "id": "murisezu",
        "name": "無理せず",
        "keywords": [
            "無理せず",
            "murisezu",
            "むり",
            "muri"
        ],
        "skins": [
            {
                "src": "/images/emojis/murisezu.png",
                "native": "/images/emojis/murisezu.png"
            }
        ],
        "type": "image",
        "version": 4
    },
    "maturi": {
        "id": "maturi",
        "name": "祭",
        "keywords": [
            "maturi",
            "祭",
            "まつり",
        ],
        "skins": [
            {
                "src": "/images/emojis/maturi.png",
                "native": "/images/emojis/maturi.png"
            }
        ],
        "type": "image",
        "version": 4
    },
    "yabasugi": {
        "id": "yabasugi",
        "name": "ヤバすぎ",
        "keywords": [
            "yabasugi",
            "やばすぎ",
            "yabai"
        ],
        "skins": [
            {
                "src": "/images/emojis/yabasugi.png",
                "native": "/images/emojis/yabasugi.png"
            }
        ],
        "type": "image",
        "version": 4
    },
    "motibe": {
        "id": "motibe",
        "name": "モチベ",
        "keywords": [
            "motibe",
            "もちべ",
            "モチベ",
            "モチベーション"
        ],
        "skins": [
            {
                "src": "/images/emojis/motibe.png",
                "native": "/images/emojis/motibe.png"
            }
        ],
        "type": "image",
        "version": 4
    },
    "nice_to_have": {
        "id": "nice_to_have",
        "name": "Nice to have",
        "keywords": [
            "Nice",
            "ないす",
            "いいね",
            "できれば",
            "have",
            "nice to have",
        ],
        "skins": [
            {
                "src": "/images/emojis/nice_to_have.png",
                "native": "/images/emojis/nice_to_have.png"
            }
        ],
        "type": "image",
        "version": 4
    },
    "omosiroi": {
        "id": "omosiroi",
        "name": "面白い",
        "keywords": [
            "おもしろい",
            "おもろい",
            "omoroi",
            "omosiroi",
            "笑う",
            "warau",
        ],
        "skins": [
            {
                "src": "/images/emojis/omosiroi.png",
                "native": "/images/emojis/omosiroi.png"
            }
        ],
        "type": "image",
        "version": 4
    },
    "waiwai_gif": {
        "id": "waiwai_gif",
        "name": "わいわい",
        "keywords": [
            "waiwai",
            "わいわい",
            "おもしろい",
            "おもろい",
            "omoroi",
            "waiwai",
            "笑う",
            "warau",
            "gif",
        ],
        "skins": [
            {
                "src": "/images/emojis/waiwai_gif.gif",
                "native": "/images/emojis/waiwai_gif.gif"
            }
        ],
        "type": "image",
        "version": 4
    },
    "devil_gif": {
        "id": "devil_gif",
        "name": "デビルGIF",
        "keywords": [
            "悪魔",
            "gif",
            "デビル",
            "devil",
            "にや",
            "笑う",
            "warau",
        ],
        "skins": [
            {
                "src": "/images/emojis/devil_gif.gif",
                "native": "/images/emojis/devil_gif.gif"
            }
        ],
        "type": "image",
        "version": 4
    },
    "turtle_gif": {
        "id": "turtle_gif",
        "name": "亀GIF",
        "keywords": [
            "亀",
            "gif",
            "turtle",
            "かめ",
            "おそい",
            "動物",
            "osoi",
            "緑",
            "みどり",
        ],
        "skins": [
            {
                "src": "/images/emojis/turtle_gif.gif",
                "native": "/images/emojis/turtle_gif.gif"
            }
        ],
        "type": "image",
        "version": 4
    },
    "sunglasses_face_gif": {
        "id": "sunglasses_face_gif",
        "name": "サングラスフェイスGIF",
        "keywords": [
            "サングラス",
            "サングラスフェイス",
            "わらう",
            "sunglasses",
            "メガネ",
            "gif"
        ],
        "skins": [
            {
                "src": "/images/emojis/sunglasses_face_gif.gif",
                "native": "/images/emojis/sunglasses_face_gif.gif"
            }
        ],
        "type": "image",
        "version": 4
    },
    "piero_gif": {
        "id": "piero_gif",
        "name": "piero_gif",
        "keywords": [
            "ピエロ",
            "clown",
            "clown_face",
            "笑う",
            "わらう",
            "おどる",
            "おどろく",
            "びっくり",
            "gif"
        ],
        "skins": [
            {
                "src": "/images/emojis/piero_gif.gif",
                "native": "/images/emojis/piero_gif.gif"
            }
        ],
        "type": "image",
        "version": 4
    },
    "loudly-crying_gif": {
        "id": "loudly-crying_gif",
        "name": "loudly-crying_gif",
        "keywords": [
            "泣く",
            "かなしい",
            "つらい",
            "おもしろい",
            "爆笑",
            "笑う",
            "わらう",
            "gif",
            "cry",
            "weep",
            "happy",
            "happytears",
            "haha",
            "泣く",
            "泣く",
            "ハッピー",
        ],
        "skins": [
            {
                "src": "/images/emojis/loudly-crying_gif.gif",
                "native": "/images/emojis/loudly-crying_gif.gif"
            }
        ],
        "type": "image",
        "version": 4
    },
    "iiyo": {
        "id": "iiyo",
        "name": "いいよ",
        "keywords": [
            "iiyo",
            "いいよ",
            "OK",
        ],
        "skins": [
            {
                "src": "/images/emojis/iiyo.png",
                "native": "/images/emojis/iiyo.png"
            }
        ],
        "type": "image",
        "version": 4
    },
    "tuyoi": {
        "id": "tuyoi",
        "name": "強い",
        "keywords": [
            "tuyoi",
            "強い",
            "つよい",
        ],
        "skins": [
            {
                "src": "/images/emojis/tuyoi.png",
                "native": "/images/emojis/tuyoi.png"
            }
        ],
        "type": "image",
        "version": 4
    },
    "tuyotuyo": {
        "id": "tuyotuyo",
        "name": "ツヨツヨ",
        "keywords": [
            "tuyotuyo",
            "強い",
            "tuyoi",
            "つよい",
            "つよつよ",
        ],
        "skins": [
            {
                "src": "/images/emojis/tuyotuyo.png",
                "native": "/images/emojis/tuyotuyo.png"
            }
        ],
        "type": "image",
        "version": 4
    },
    "sasugasennsei": {
        "id": "sasugasennsei",
        "name": "さすが先生",
        "keywords": [
            "sasugasennsei",
            "さすが",
            "流石",
            "先生",
            "sensei"
        ],
        "skins": [
            {
                "src": "/images/emojis/sasugasennsei.png",
                "native": "/images/emojis/sasugasennsei.png"
            }
        ],
        "type": "image",
        "version": 4
    },
    "todo": {
        "id": "todo",
        "name": "TODO",
        "keywords": [
            "todo",
            "あと",
            "TODO",
        ],
        "skins": [
            {
                "src": "/images/emojis/todo.png",
                "native": "/images/emojis/todo.png"
            }
        ],
        "type": "image",
        "version": 4
    },
    "desu": {
        "id": "desu",
        "name": "です",
        "keywords": [
            "desu",
            "です",
            "そうですね",
        ],
        "skins": [
            {
                "src": "/images/emojis/desu.png",
                "native": "/images/emojis/desu.png"
            }
        ],
        "type": "image",
        "version": 4
    },
    "yes": {
        "id": "yes",
        "name": "YES",
        "keywords": [
            "はい",
            "そう",
            "YES",
            "yes",
        ],
        "skins": [
            {
                "src": "/images/emojis/yes.png",
                "native": "/images/emojis/yes.png"
            }
        ],
        "type": "image",
        "version": 4
    },
    "noo": {
        "id": "noo",
        "name": "NO",
        "keywords": [
            "no",
            "NO",
            "いいえ",
        ],
        "skins": [
            {
                "src": "/images/emojis/noo.png",
                "native": "/images/emojis/noo.png"
            }
        ],
        "type": "image",
        "version": 4
    },
    "clap_gif": {
        "id": "iiyo",
        "name": "拍手_GIF",
        "keywords": [
            "clap",
            "praise",
            "applause",
            "congrats",
            "yay",
            "拍手",
            "賞賛",
            "拍手",
            "おめでとう",
            "わーい",
            "gif"
        ],
        "skins": [
            {
                "src": "/images/emojis/clap_gif.png",
                "native": "/images/emojis/clap_gif.png"
            }
        ],
        "type": "image",
        "version": 4
    },
    "nekomimu_gif": {
        "id": "nekomimu_gif",
        "name": "nekomimu_gif",
        "keywords": [
            "nekomimu",
            "うれしい",
            "neko",
            "みーむ",
            "ミーム",
            "猫2",
            "おどる",
            "賞賛",
            "わーい",
            "gif"
        ],
        "skins": [
            {
                "src": "/images/emojis/nekomimu_gif.gif",
                "native": "/images/emojis/nekomimu_gif.gif"
            }
        ],
        "type": "image",
        "version": 4
    },
    "neko_takusan_gif": {
        "id": "neko_takusan_gif",
        "name": "neko_takusan_gif",
        "keywords": [
            "nekomimu",
            "動物",
            "たくさん",
            "多い",
            "ooi",
            "うれしい",
            "neko",
            "みーむ",
            "ミーム",
            "猫2",
            "おどる",
            "賞賛",
            "わーい",
            "gif"
        ],
        "skins": [
            {
                "src": "/images/emojis/neko_takusan_gif.gif",
                "native": "/images/emojis/neko_takusan_gif.gif"
            }
        ],
        "type": "image",
        "version": 4
    },
    "pc_neko_gif": {
        "id": "pc_neko_gif",
        "name": "pc_neko_gif",
        "keywords": [
            "nekomimu",
            "こまる",
            "neko",
            "みーむ",
            "ミーム",
            "猫2",
            "pc",
            "賞賛",
            "わーい",
            "gif"
        ],
        "skins": [
            {
                "src": "/images/emojis/pc_neko_gif.gif",
                "native": "/images/emojis/pc_neko_gif.gif"
            }
        ],
        "type": "image",
        "version": 4
    },
    "tori_gif": {
        "id": "tori_gif",
        "name": "tori_gif",
        "keywords": [
            "tori",
            "レインボー",
            "にじ",
            "嬉しい",
            "動物",
            "どうぶつ",
            "はげしい",
            "おどる",
            "わーい",
            "gif"
        ],
        "skins": [
            {
                "src": "/images/emojis/tori_gif.avif",
                "native": "/images/emojis/tori_gif.avif"
            }
        ],
        "type": "image",
        "version": 4
    },
    "otesuukakemasu": {
        "id": "otesuukakemasu",
        "name": "お手数かけます",
        "keywords": [
            "otesuukakemasu",
            "おてすう",
            "おてすうかけます",
            "お手数",
            "オテスウ",
            "otesuu",
        ],
        "skins": [
            {
                "src": "/images/emojis/otesuukakemasu.png",
                "native": "/images/emojis/otesuukakemasu.png"
            }
        ],
        "type": "image",
        "version": 4
    },
    "omakasekudasai": {
        "id": "omakasekudasai",
        "name": "お任せください",
        "keywords": [
            "omakasekudasai",
            "makasete",
            "おまかせ",
            "お任せ",
            "まかせて",
            "任せてください",
            "お任せください",
        ],
        "skins": [
            {
                "src": "/images/emojis/omakasekudasai.png",
                "native": "/images/emojis/omakasekudasai.png"
            }
        ],
        "type": "image",
        "version": 4
    },
    "yaritai": {
        "id": "yaritai",
        "name": "やりたい",
        "keywords": [
            "yarimasu",
            "yaritai",
            "対応",
            "やりたい",
            "や",
            "やります",
        ],
        "skins": [
            {
                "src": "/images/emojis/yaritai.png",
                "native": "/images/emojis/yaritai.png"
            }
        ],
        "type": "image",
        "version": 4
    },
    "nobisirosikanai": {
        "id": "nobisirosikanai",
        "name": "伸び代しかない",
        "keywords": [
            "のび",
            "nobisirosikanai",
            "伸び代",
            "改善",
            "かいぜん",
            "kaizen",
        ],
        "skins": [
            {
                "src": "/images/emojis/nobisirosikanai.png",
                "native": "/images/emojis/nobisirosikanai.png"
            }
        ],
        "type": "image",
        "version": 4
    },
    "kuyasii": {
        "id": "kuyasii",
        "name": "くやしい",
        "keywords": [
            "くや",
            "kuyasii",
            "悔しい",
            "えー",
            "かなしい",
        ],
        "skins": [
            {
                "src": "/images/emojis/kuyasii.png",
                "native": "/images/emojis/kuyasii.png"
            }
        ],
        "type": "image",
        "version": 4
    },
    "syoutiitasimasita": {
        "id": "syoutiitasimasita",
        "name": "承知いたしました",
        "keywords": [
            "しょうち",
            "syoutiitasimasita",
            "了解",
            "わかり",
            "shouti",
            "ok",
        ],
        "skins": [
            {
                "src": "/images/emojis/syoutiitasimasita.png",
                "native": "/images/emojis/syoutiitasimasita.png"
            }
        ],
        "type": "image",
        "version": 4
    },
    "okdesu": {
        "id": "okdesu",
        "name": "OKです",
        "keywords": [
            "しょうち",
            "okdesu",
            "了解",
            "わかり",
            "shouti",
            "ok",
            "syouti",
            "ok",
        ],
        "skins": [
            {
                "src": "/images/emojis/okdesu.png",
                "native": "/images/emojis/okdesu.png"
            }
        ],
        "type": "image",
        "version": 4
    },
    "sigohaya": {
        "id": "sigohaya",
        "name": "しごはや",
        "keywords": [
            "しごはや",
            "sigohaya",
            "haya",
            "sigo",
            "はやい",
            "しごと",
            "仕事",
            "早い",
        ],
        "skins": [
            {
                "src": "/images/emojis/sigohaya.png",
                "native": "/images/emojis/sigohaya.png"
            }
        ],
        "type": "image",
        "version": 4
    },
    "desudesu": {
        "id": "desudesu",
        "name": "ですです",
        "keywords": [
            "ですです",
            "desudesu",
            "そう",
            "です",
            "sou",
        ],
        "skins": [
            {
                "src": "/images/emojis/desudesu.png",
                "native": "/images/emojis/desudesu.png"
            }
        ],
        "type": "image",
        "version": 4
    },
    "tootoi": {
        "id": "tootoi",
        "name": "尊い",
        "keywords": [
            "とおとい",
            "tootoi",
        ],
        "skins": [
            {
                "src": "/images/emojis/tootoi.png",
                "native": "/images/emojis/tootoi.png"
            }
        ],
        "type": "image",
        "version": 4
    },
    "manabi": {
        "id": "manabi",
        "name": "学び",
        "keywords": [
            "まなび",
            "manabi",
            "学び",
            "べんきょう",
            "なるほど",
        ],
        "skins": [
            {
                "src": "/images/emojis/manabi.png",
                "native": "/images/emojis/manabi.png"
            }
        ],
        "type": "image",
        "version": 4
    },
    "akagami_megane": {
        "id": "akagami_megane",
        "name": "赤髪_眼鏡_女性",
        "keywords": [
            "めがね",
            "akagami_megane",
            "woman",
            "女性",
            "赤",
        ],
        "skins": [
            {
                "src": "/images/emojis/akagami_megane.png",
                "native": "/images/emojis/akagami_megane.png"
            }
        ],
        "type": "image",
        "version": 4
    },
    "sunsun": {
        "id": "sunsun",
        "name": "sunsun",
        "keywords": [
            "fuwaa",
            "sunsun",
            "青い",
            "blue",
            "ao",
            "あお",
        ],
        "skins": [
            {
                "src": "/images/emojis/sunsun.png",
                "native": "/images/emojis/sunsun.png"
            }
        ],
        "type": "image",
        "version": 4
    },
    "fukai": {
        "id": "fukai",
        "name": "深い",
        "keywords": [
            "まなび",
            "fukai",
            "学び",
            "ふかい",
        ],
        "skins": [
            {
                "src": "/images/emojis/fukai.png",
                "native": "/images/emojis/fukai.png"
            }
        ],
        "type": "image",
        "version": 4
    },
    "tayorininarimasu": {
        "id": "tayorininarimasu",
        "name": "頼りになります",
        "keywords": [
            "頼りになります",
            "tayorininarimasu",
            "たより",
            "すごい",
            "ありがとう",
        ],
        "skins": [
            {
                "src": "/images/emojis/tayorininarimasu.png",
                "native": "/images/emojis/tayorininarimasu.png"
            }
        ],
        "type": "image",
        "version": 4
    },
    "kakkoii": {
        "id": "kakkoii",
        "name": "かっこいい",
        "keywords": [
            "かわいい",
            "かっこいい",
            "イケメン",
            "カッコいい",
            "kakkoii",
        ],
        "skins": [
            {
                "src": "/images/emojis/kakkoii.png",
                "native": "/images/emojis/kakkoii.png"
            }
        ],
        "type": "image",
        "version": 4
    },
    "kowa": {
        "id": "kowa",
        "name": "こわ",
        "keywords": [
            "こわ",
            "怖",
            "kowai",
            "きょうふ",
            "びっくり",
        ],
        "skins": [
            {
                "src": "/images/emojis/kowa.png",
                "native": "/images/emojis/kowa.png"
            }
        ],
        "type": "image",
        "version": 4
    },
    "nanto": {
        "id": "nanto",
        "name": "なんと",
        "keywords": [
            "なんと",
            "びっくり",
            "nanto",
            "bikkuri",
            "おどろく",
            "odoroki",
        ],
        "skins": [
            {
                "src": "/images/emojis/nanto.png",
                "native": "/images/emojis/nanto.png"
            }
        ],
        "type": "image",
        "version": 4
    },
    "kimotissyo": {
        "id": "kimotissyo",
        "name": "気持ちっしょ",
        "keywords": [
            "kimoti",
            "大丈夫",
            "kiai",
            "kannkeinai",
        ],
        "skins": [
            {
                "src": "/images/emojis/kimotissyo.png",
                "native": "/images/emojis/kimotissyo.png"
            }
        ],
        "type": "image",
        "version": 4
    },
    "haakusimasita": {
        "id": "haakusimasita",
        "name": "把握しました",
        "keywords": [
            "把握",
            "了解",
            "理解",
            "rikai",
            "ryoukai",
            "把握しました",
            "haaku",
            "わかりました",
            "かしこまりました",
        ],
        "skins": [
            {
                "src": "/images/emojis/haakusimasita.png",
                "native": "/images/emojis/haakusimasita.png"
            }
        ],
        "type": "image",
        "version": 4
    },
    "tondemonaidesu": {
        "id": "tondemonaidesu",
        "name": "とんでもないです",
        "keywords": [
            "tondemonaidesu",
            "ieie",
            "いえいえ",
            "こちらこそ",
            "そんなことない",
            "とんでもない",
            "ありがとう",
        ],
        "skins": [
            {
                "src": "/images/emojis/tondemonaidesu.png",
                "native": "/images/emojis/tondemonaidesu.png"
            }
        ],
        "type": "image",
        "version": 4
    },
    "zennzenndesu": {
        "id": "zennzenndesu",
        "name": "全然です",
        "keywords": [
            "zennzenndesu",
            "ieie",
            "いえいえ",
            "こちらこそ",
            "そんなことない",
            "とんでもない",
            "ありがとう",
            "ie",
            "ぜんぜん",
        ],
        "skins": [
            {
                "src": "/images/emojis/zennzenndesu.png",
                "native": "/images/emojis/zennzenndesu.png"
            }
        ],
        "type": "image",
        "version": 4
    },
    "aoi_sakana": {
        "id": "aoi_sakana",
        "name": "青い魚",
        "keywords": [
            "aoi_sakana",
            "魚",
            "sakana",
            "さかな",
            "dolly",
            "dori-",
            "どりー",
        ],
        "skins": [
            {
                "src": "/images/emojis/aoi_sakana.png",
                "native": "/images/emojis/aoi_sakana.png"
            }
        ],
        "type": "image",
        "version": 4
    },
    "zehi": {
        "id": "zehi",
        "name": "是非",
        "keywords": [
            "zehi",
            "ぜひ",
            "是非",
        ],
        "skins": [
            {
                "src": "/images/emojis/zehi.png",
                "native": "/images/emojis/zehi.png"
            }
        ],
        "type": "image",
        "version": 4
    },
    "desune": {
        "id": "desune",
        "name": "ですね!",
        "keywords": [
            "desune",
            "ですね",
            "そう",
            "同意",
        ],
        "skins": [
            {
                "src": "/images/emojis/desune.png",
                "native": "/images/emojis/desune.png"
            }
        ],
        "type": "image",
        "version": 4
    },
    "dane": {
        "id": "dane",
        "name": "だね!",
        "keywords": [
            "dane",
            "ですね",
            "だね",
            "ね",
            "ne",
            "そう",
            "同意",
        ],
        "skins": [
            {
                "src": "/images/emojis/dane.png",
                "native": "/images/emojis/dane.png"
            }
        ],
        "type": "image",
        "version": 4
    },
    "ossyarutooridesu": {
        "id": "ossyarutooridesu",
        "name": "おっしゃる通りです",
        "keywords": [
            "ossyarutooridesu",
            "ですね",
            "そう",
            "同意",
            "同感",
            "sore",
            "です",
            "desu",
            "間違いない",
        ],
        "skins": [
            {
                "src": "/images/emojis/ossyarutooridesu.png",
                "native": "/images/emojis/ossyarutooridesu.png"
            }
        ],
        "type": "image",
        "version": 4
    },
    "matigainai": {
        "id": "matigainai",
        "name": "間違いない",
        "keywords": [
            "matigainai",
            "ですね",
            "そう",
            "同意",
            "同感",
            "sore",
            "です",
            "desu",
            "間違いない",
        ],
        "skins": [
            {
                "src": "/images/emojis/matigainai.png",
                "native": "/images/emojis/matigainai.png"
            }
        ],
        "type": "image",
        "version": 4
    },
    "yorokobi": {
        "id": "yorokobi",
        "name": "よろこび",
        "keywords": [
            "yorokobi",
            "よろこび",
            "うれしい",
            "uresii",
            "yorokobi",
            "喜び",
            "喜ぶ",
            "arigatou",
            "ありがとう",
            "感謝",
        ],
        "skins": [
            {
                "src": "/images/emojis/yorokobi.png",
                "native": "/images/emojis/yorokobi.png"
            }
        ],
        "type": "image",
        "version": 4
    },
    "tanosii": {
        "id": "tanosii",
        "name": "たのしい",
        "keywords": [
            "tanosii",
            "楽しい",
            "たのしい",
            "よろこび",
            "うれしい",
            "uresii",
            "tanosii",
            "喜び",
            "喜ぶ",
            "arigatou",
            "ありがとう",
            "感謝",
        ],
        "skins": [
            {
                "src": "/images/emojis/tanosii.png",
                "native": "/images/emojis/tanosii.png"
            }
        ],
        "type": "image",
        "version": 4
    },

    "donmai": {
        "id": "donmai",
        "name": "どんまい",
        "keywords": [
            "donmai",
            "かなしい",
            "つらい",
            "donmai",
            "どんまい",
            "だいじょうぶ",
            "zannnenn",
            "ざんねん",
            "しょぼん",
            "syobon",
            "くやしい",
            "たいへん",
            "taihen",
        ],
        "skins": [
            {
                "src": "/images/emojis/donmai.png",
                "native": "/images/emojis/donmai.png"
            }
        ],
        "type": "image",
        "version": 4
    },
    "syobon": {
        "id": "syobon",
        "name": "しょぼん",
        "keywords": [
            "donmai",
            "かなしい",
            "つらい",
            "donmai",
            "どんまい",
            "だいじょうぶ",
            "zannnenn",
            "ざんねん",
            "しょぼん",
            "syobon",
            "くやしい",
            "たいへん",
            "taihen",
        ],
        "skins": [
            {
                "src": "/images/emojis/syobon.png",
                "native": "/images/emojis/syobon.png"
            }
        ],
        "type": "image",
        "version": 4
    },
    "taihen": {
        "id": "taihen",
        "name": "大変",
        "keywords": [
            "donmai",
            "かなしい",
            "つらい",
            "donmai",
            "どんまい",
            "だいじょうぶ",
            "zannnenn",
            "ざんねん",
            "しょぼん",
            "syobon",
            "くやしい",
            "たいへん",
            "taihen",
        ],
        "skins": [
            {
                "src": "/images/emojis/taihen.png",
                "native": "/images/emojis/taihen.png"
            }
        ],
        "type": "image",
        "version": 4
    },
    "turai": {
        "id": "turai",
        "name": "つらい",
        "keywords": [
            "donmai",
            "かなしい",
            "つらい",
            "donmai",
            "どんまい",
            "だいじょうぶ",
            "zannnenn",
            "ざんねん",
            "しょぼん",
            "syobon",
            "くやしい",
            "たいへん",
            "taihen",
        ],
        "skins": [
            {
                "src": "/images/emojis/turai.png",
                "native": "/images/emojis/turai.png"
            }
        ],
        "type": "image",
        "version": 4
    },
    "zannnen": {
        "id": "zannnen",
        "name": "ざんねん",
        "keywords": [
            "donmai",
            "かなしい",
            "つらい",
            "donmai",
            "どんまい",
            "だいじょうぶ",
            "zannnenn",
            "ざんねん",
            "しょぼん",
            "syobon",
            "くやしい",
            "たいへん",
            "taihen",
        ],
        "skins": [
            {
                "src": "/images/emojis/zannnen.png",
                "native": "/images/emojis/zannnen.png"
            }
        ],
        "type": "image",
        "version": 4
    },
    "memo_img": {
        "id": "memo_img",
        "name": "memo_img",
        "keywords": [
            "memo",
            "pencil",
            "write",
            "documents",
            "stationery",
            "paper",
            "writing",
            "legal",
            "exam",
            "quiz",
            "test",
            "study",
            "compose",
            "鉛筆",
            "書く",
            "ドキュメント",
            "文房具",
            "紙",
            "書き込み",
            "法的",
            "テスト",
            "クイズ",
            "テスト",
            "勉強",
            "todo",
            "作曲"
        ],
        "skins": [
            {
                "src": "/images/emojis/memo_img.png",
                "native": "/images/emojis/memo_img.png"
            }
        ],
        "type": "image",
        "version": 4
    },
    "aoi_penguin_gif": {
        "id": "aoi_penguin_gif",
        "name": "aoi_penguin_gif",
        "keywords": [
            "animal",
            "nature",
            "動物",
            "自然",
            "sam",
            "penguin",
            "pen",
            "ぺんぎん",
            "ao",
        ],
        "skins": [
            {
                "src": "/images/emojis/aoi_penguin_gif.gif",
                "native": "/images/emojis/aoi_penguin_gif.gif"
            }
        ],
        "type": "image",
        "version": 4
    },
    "tennsyonnagaru": {
        "id": "tennsyonnagaru",
        "name": "tennsyonnagaru",
        "keywords": [
            "tennsyonnagaru",
            "tennsyon",
            "テンション",
            "上がる",
            "いいね",
            "ii",
            "agaru",
            "良い",
        ],
        "skins": [
            {
                "src": "/images/emojis/tennsyonnagaru.png",
                "native": "/images/emojis/tennsyonnagaru.png"
            }
        ],
        "type": "image",
        "version": 4
    },
    "kiri": {
        "id": "kiri",
        "name": "kiri",
        "keywords": [
            "kiri",
            "cheese",
            "food",
            "chadder",
            "食べ物",
            "チャダー",
            "きり",
            "ちーず",
        ],
        "skins": [
            {
                "src": "/images/emojis/kiri.webp",
                "native": "/images/emojis/kiri.webp"
            }
        ],
        "type": "image",
        "version": 4
    },



    // ------------------- 五十音 ------------------------
    "a": {
        "id": "a",
        "name": "あ",
        "keywords": [
            "あ",
            "みす",
            "miss",
            "あ",
            "a",
        ],
        "skins": [
            {
                "src": "/images/emojis/a.png",
                "native": "/images/emojis/a.png"
            }
        ],
        "type": "image",
        "version": 4
    },
    "i": {
        "id": "i",
        "name": "い",
        "keywords": [
            "い",
            "i",
        ],
        "skins": [
            {
                "src": "/images/emojis/i.png",
                "native": "/images/emojis/i.png"
            }
        ],
        "type": "image",
        "version": 4
    },
    "u": {
        "id": "u",
        "name": "う",
        "keywords": [
            "う",
            "u",
        ],
        "skins": [
            {
                "src": "/images/emojis/u.png",
                "native": "/images/emojis/u.png"
            }
        ],
        "type": "image",
        "version": 4
    },
    "e": {
        "id": "e",
        "name": "え",
        "keywords": [
            "え",
            "e"
        ],
        "skins": [
            {
                "src": "/images/emojis/e.png",
                "native": "/images/emojis/e.png"
            }
        ],
        "type": "image",
        "version": 4
    },
    "o": {
        "id": "o",
        "name": "お",
        "keywords": [
            "お",
            "o"
        ],
        "skins": [
            {
                "src": "/images/emojis/o.png",
                "native": "/images/emojis/o.png"
            }
        ],
        "type": "image",
        "version": 4
    },
    "ka": {
        "id": "ka",
        "name": "か",
        "keywords": [
            "か",
            "ka"
        ],
        "skins": [
            {
                "src": "/images/emojis/ka.png",
                "native": "/images/emojis/ka.png"
            }
        ],
        "type": "image",
        "version": 4
    },
    "ki": {
        "id": "ki",
        "name": "き",
        "keywords": [
            "き",
            "ki"
        ],
        "skins": [
            {
                "src": "/images/emojis/ki.png",
                "native": "/images/emojis/ki.png"
            }
        ],
        "type": "image",
        "version": 4
    },
    "ku": {
        "id": "ku",
        "name": "く",
        "keywords": [
            "く",
            "ku"
        ],
        "skins": [
            {
                "src": "/images/emojis/ku.png",
                "native": "/images/emojis/ku.png"
            }
        ],
        "type": "image",
        "version": 4
    },
    "ke": {
        "id": "ke",
        "name": "け",
        "keywords": [
            "け",
            "ke"
        ],
        "skins": [
            {
                "src": "/images/emojis/ke.png",
                "native": "/images/emojis/ke.png"
            }
        ],
        "type": "image",
        "version": 4
    },
    "ko": {
        "id": "ko",
        "name": "こ",
        "keywords": [
            "こ",
            "ko"
        ],
        "skins": [
            {
                "src": "/images/emojis/ko.png",
                "native": "/images/emojis/ko.png"
            }
        ],
        "type": "image",
        "version": 4
    },
    "sa": {
        "id": "sa",
        "name": "さ",
        "keywords": [
            "さ",
            "sa"
        ],
        "skins": [
            {
                "src": "/images/emojis/sa.png",
                "native": "/images/emojis/sa.png"
            }
        ],
        "type": "image",
        "version": 4
    },
    "si": {
        "id": "si",
        "name": "し",
        "keywords": [
            "し",
            "si"
        ],
        "skins": [
            {
                "src": "/images/emojis/si.png",
                "native": "/images/emojis/si.png"
            }
        ],
        "type": "image",
        "version": 4
    },
    "su": {
        "id": "su",
        "name": "す",
        "keywords": [
            "す",
            "su"
        ],
        "skins": [
            {
                "src": "/images/emojis/su.png",
                "native": "/images/emojis/su.png"
            }
        ],
        "type": "image",
        "version": 4
    },
    "se": {
        "id": "se",
        "name": "せ",
        "keywords": [
            "せ",
            "se"
        ],
        "skins": [
            {
                "src": "/images/emojis/se.png",
                "native": "/images/emojis/se.png"
            }
        ],
        "type": "image",
        "version": 4
    },
    "so": {
        "id": "so",
        "name": "そ",
        "keywords": [
            "そ",
            "so"
        ],
        "skins": [
            {
                "src": "/images/emojis/so.png",
                "native": "/images/emojis/so.png"
            }
        ],
        "type": "image",
        "version": 4
    },
    "ta": {
        "id": "ta",
        "name": "た",
        "keywords": [
            "た",
            "ta"
        ],
        "skins": [
            {
                "src": "/images/emojis/ta.png",
                "native": "/images/emojis/ta.png"
            }
        ],
        "type": "image",
        "version": 4
    },
    "ti": {
        "id": "ti",
        "name": "ち",
        "keywords": [
            "ち",
            "ti"
        ],
        "skins": [
            {
                "src": "/images/emojis/ti.png",
                "native": "/images/emojis/ti.png"
            }
        ],
        "type": "image",
        "version": 4
    },
    "tu": {
        "id": "tu",
        "name": "つ",
        "keywords": [
            "つ",
            "tu"
        ],
        "skins": [
            {
                "src": "/images/emojis/tu.png",
                "native": "/images/emojis/tu.png"
            }
        ],
        "type": "image",
        "version": 4
    },
    "te": {
        "id": "te",
        "name": "て",
        "keywords": [
            "て",
            "te"
        ],
        "skins": [
            {
                "src": "/images/emojis/te.png",
                "native": "/images/emojis/te.png"
            }
        ],
        "type": "image",
        "version": 4
    },
    "to": {
        "id": "to",
        "name": "と",
        "keywords": [
            "と",
            "to"
        ],
        "skins": [
            {
                "src": "/images/emojis/to.png",
                "native": "/images/emojis/to.png"
            }
        ],
        "type": "image",
        "version": 4
    },
    "na": {
        "id": "na",
        "name": "な",
        "keywords": [
            "な",
            "na"
        ],
        "skins": [
            {
                "src": "/images/emojis/na.png",
                "native": "/images/emojis/na.png"
            }
        ],
        "type": "image",
        "version": 4
    },
    "ni": {
        "id": "ni",
        "name": "に",
        "keywords": [
            "に",
            "ni"
        ],
        "skins": [
            {
                "src": "/images/emojis/ni.png",
                "native": "/images/emojis/ni.png"
            }
        ],
        "type": "image",
        "version": 4
    },
    "nu": {
        "id": "nu",
        "name": "ぬ",
        "keywords": [
            "ぬ",
            "nu"
        ],
        "skins": [
            {
                "src": "/images/emojis/nu.png",
                "native": "/images/emojis/nu.png"
            }
        ],
        "type": "image",
        "version": 4
    },
    "ne": {
        "id": "ne",
        "name": "ね",
        "keywords": [
            "ne",
            "ね",
            "sore",
            "それな",
            "わかる",
            "同意",
        ],
        "skins": [
            {
                "src": "/images/emojis/ne.png",
                "native": "/images/emojis/ne.png"
            }
        ],
        "type": "image",
        "version": 4
    },
    "no": {
        "id": "no",
        "name": "の",
        "keywords": [
            "の",
            "no"
        ],
        "skins": [
            {
                "src": "/images/emojis/no.png",
                "native": "/images/emojis/no.png"
            }
        ],
        "type": "image",
        "version": 4
    },
    "ha": {
        "id": "ha",
        "name": "は",
        "keywords": [
            "は",
            "ha"
        ],
        "skins": [
            {
                "src": "/images/emojis/ha.png",
                "native": "/images/emojis/ha.png"
            }
        ],
        "type": "image",
        "version": 4
    },
    "hi": {
        "id": "hi",
        "name": "ひ",
        "keywords": [
            "ひ",
            "hi"
        ],
        "skins": [
            {
                "src": "/images/emojis/hi.png",
                "native": "/images/emojis/hi.png"
            }
        ],
        "type": "image",
        "version": 4
    },
    "hu": {
        "id": "hu",
        "name": "ふ",
        "keywords": [
            "ふ",
            "hu"
        ],
        "skins": [
            {
                "src": "/images/emojis/hu.png",
                "native": "/images/emojis/hu.png"
            }
        ],
        "type": "image",
        "version": 4
    },
    "he": {
        "id": "he",
        "name": "へ",
        "keywords": [
            "へ",
            "he"
        ],
        "skins": [
            {
                "src": "/images/emojis/he.png",
                "native": "/images/emojis/he.png"
            }
        ],
        "type": "image",
        "version": 4
    },
    "ho": {
        "id": "ho",
        "name": "ほ",
        "keywords": [
            "ほ",
            "ho"
        ],
        "skins": [
            {
                "src": "/images/emojis/ho.png",
                "native": "/images/emojis/ho.png"
            }
        ],
        "type": "image",
        "version": 4
    },
    "ma": {
        "id": "ma",
        "name": "ま",
        "keywords": [
            "ま",
            "ma"
        ],
        "skins": [
            {
                "src": "/images/emojis/ma.png",
                "native": "/images/emojis/ma.png"
            }
        ],
        "type": "image",
        "version": 4
    },
    "mi": {
        "id": "mi",
        "name": "み",
        "keywords": [
            "み",
            "mi"
        ],
        "skins": [
            {
                "src": "/images/emojis/mi.png",
                "native": "/images/emojis/mi.png"
            }
        ],
        "type": "image",
        "version": 4
    },
    "mu": {
        "id": "mu",
        "name": "む",
        "keywords": [
            "む",
            "mu"
        ],
        "skins": [
            {
                "src": "/images/emojis/mu.png",
                "native": "/images/emojis/mu.png"
            }
        ],
        "type": "image",
        "version": 4
    },
    "me": {
        "id": "me",
        "name": "め",
        "keywords": [
            "め",
            "me"
        ],
        "skins": [
            {
                "src": "/images/emojis/me.png",
                "native": "/images/emojis/me.png"
            }
        ],
        "type": "image",
        "version": 4
    },
    "mo": {
        "id": "mo",
        "name": "も",
        "keywords": [
            "も",
            "mo"
        ],
        "skins": [
            {
                "src": "/images/emojis/mo.png",
                "native": "/images/emojis/mo.png"
            }
        ],
        "type": "image",
        "version": 4
    },
    "ya": {
        "id": "ya",
        "name": "や",
        "keywords": [
            "や",
            "ya"
        ],
        "skins": [
            {
                "src": "/images/emojis/ya.png",
                "native": "/images/emojis/ya.png"
            }
        ],
        "type": "image",
        "version": 4
    },
    "yu": {
        "id": "yu",
        "name": "ゆ",
        "keywords": [
            "ゆ",
            "yu"
        ],
        "skins": [
            {
                "src": "/images/emojis/yu.png",
                "native": "/images/emojis/yu.png"
            }
        ],
        "type": "image",
        "version": 4
    },
    "yo": {
        "id": "yo",
        "name": "よ",
        "keywords": [
            "よ",
            "yo"
        ],
        "skins": [
            {
                "src": "/images/emojis/yo.png",
                "native": "/images/emojis/yo.png"
            }
        ],
        "type": "image",
        "version": 4
    },
    "ra": {
        "id": "ra",
        "name": "ら",
        "keywords": [
            "ら",
            "ra"
        ],
        "skins": [
            {
                "src": "/images/emojis/ra.png",
                "native": "/images/emojis/ra.png"
            }
        ],
        "type": "image",
        "version": 4
    },
    "ri": {
        "id": "ri",
        "name": "り",
        "keywords": [
            "り",
            "ri"
        ],
        "skins": [
            {
                "src": "/images/emojis/ri.png",
                "native": "/images/emojis/ri.png"
            }
        ],
        "type": "image",
        "version": 4
    },
    "ru": {
        "id": "ru",
        "name": "る",
        "keywords": [
            "る",
            "ru"
        ],
        "skins": [
            {
                "src": "/images/emojis/ru.png",
                "native": "/images/emojis/ru.png"
            }
        ],
        "type": "image",
        "version": 4
    },
    "re": {
        "id": "re",
        "name": "れ",
        "keywords": [
            "れ",
            "re"
        ],
        "skins": [
            {
                "src": "/images/emojis/re.png",
                "native": "/images/emojis/re.png"
            }
        ],
        "type": "image",
        "version": 4
    },
    "ro": {
        "id": "ro",
        "name": "ろ",
        "keywords": [
            "ろ",
            "ro"
        ],
        "skins": [
            {
                "src": "/images/emojis/ro.png",
                "native": "/images/emojis/ro.png"
            }
        ],
        "type": "image",
        "version": 4
    },
    "wa": {
        "id": "wa",
        "name": "わ",
        "keywords": [
            "わ",
            "wa"
        ],
        "skins": [
            {
                "src": "/images/emojis/wa.png",
                "native": "/images/emojis/wa.png"
            }
        ],
        "type": "image",
        "version": 4
    },
    "wo": {
        "id": "wo",
        "name": "を",
        "keywords": [
            "を",
            "wo"
        ],
        "skins": [
            {
                "src": "/images/emojis/wo.png",
                "native": "/images/emojis/wo.png"
            }
        ],
        "type": "image",
        "version": 4
    },
    "nn": {
        "id": "nn",
        "name": "ん",
        "keywords": [
            "ん",
            "n"
        ],
        "skins": [
            {
                "src": "/images/emojis/nn.png",
                "native": "/images/emojis/nn.png"
            }
        ],
        "type": "image",
        "version": 4
    },
};

// 追加のカテゴリー
export const customCategory = {
    "id": "custom",
    "emojis": [
        // "smile", // 😄
        // "+1", // 👍
        "white_check_mark", // ✅
        "clap", // 👏
        "ok_hand", // 👌
        "pray", // 🙏
        "man-bowing", // 🙇‍♂️
        "heart", // ❤️
        "thinking_face", // 🤔
        "fearful", // 😨
        "cry", // 😢
        "tada", // 🎉
        "muscle", // 💪
        "eyes", // 👀
        "100", // 💯
        "man-gesturing-ok", // 🙆‍♂️
        "rocket", // 🚀
        "hankey", // 💩
        // ----- 画像 -----
        "arigatougozaimasu",
        "arigato",
        "kansya",
        "attoutekikansya",
        "yorosikuonegaisimasu",
        "gomenne",
        "sumimasen",
        "makotonimousiwakegozaimasensaihatubousisakuwokangaemasu",
        "situreisimasita",
        "syoutidesu",
        "ryoukaidesu",
        "tasikani",
        "tasikani_image",
        "naruhodo",
        "doui",
        "doukanndesu",
        "sumi",
        "done",
        "lgtm",
        "kakuninsimasu",
        "taioutyuu",
        "taiousimasu",
        "kakuninzumi",
        "taiouzumi",
        "syuuseisimasita",
        "mondainasasou",
        "merge",
        "merge_icon",
        "syousyouomatikudasai",
        "kakutei",
        "gyoi",
        "ok",
        "okdesu",
        "taibouno",
        "kita",
        "syusseki",
        "kesseki",
        "sasuga",
        "saikou",
        "sugoi",
        "tensai",
        "god",
        "kamitaiou",
        "haya",
        "asitadeok",
        "kotirakoso",
        "ww",
        "wara",
        "kidoku",
        "yosasou",
        "yabasugi",
        "uresii",
        "tanosii",
        "kawaii",
        "wakaru",
        "humuhumu",
        "iine",
        "nice",
        "dotou",
        "gj",
        "ganbarimasu",
        "otukaresamadesu",
        "okininasarazu",
        "odaizini",
        "murisezu",
        "yoisyo",
        "raja",
        "sigodeki",
        "sigohaya",
        "emoi",
        "siawase",
        "suteki",
        "gekiatu",
        "atu",
        "akagami_megane",
        "sunsun",
        "panda_img",
        "aoi_neko",
        "aoi_sakana",
        "maturi",
        "kininaru",
        "iidesune",
        "motibe",
        "iketeru",
        "nanitozo",
        "sorena",
        "azasu",
        "nice_to_have",
        "osu",
        "manji",
        "gogogogo",
        "tyou",
        "subara",
        "tasukaru",
        "tasukarimasu",
        "kuse",
        "omosiroi",
        "iiyo",
        "tuyoi",
        "tuyotuyo",
        "sasugasennsei",
        "todo",
        "desu",
        "yes",
        "noo",
        "otesuukakemasu",
        "omakasekudasai",
        "yaritai",
        "nobisirosikanai",
        "kuyasii",
        "donmai",
        "syobon",
        "taihen",
        "turai",
        "zannnen",
        "syoutiitasimasita",
        "desudesu",
        "matigainai",
        "ossyarutooridesu",
        "tootoi",
        "manabi",
        "fukai",
        "tayorininarimasu",
        "kakkoii",
        "kowa",
        "nanto",
        "haakusimasita",
        "kimotissyo",
        "tondemogozaimasen",
        "tondemonaidesu",
        "zehi",
        "desune",
        "yorokobi",
        "memo_img",
        "motirondesu",
        "zennzenndesu",
        "otyadouzo",
        "sarani",
        "tennsyonnagaru",
        "sunsun",
        "kiri",
        "dane",
        "yasasii",
        // ------ gif ------
        "waiwai_gif",
        "arigatougozaimasu_gif",
        "rocket_gif",
        "thinking_face_gif",
        "joy_gif",
        "heart_gif",
        "clap_gif",
        "ojigi_gif",
        "nekomimu_gif",
        "aoi_penguin_gif",
        "devil_gif",
        "turtle_gif",
        "sunglasses_face_gif",
        "piero_gif",
        "loudly-crying_gif",
        "pc_neko_gif",
        "neko_takusan_gif",
        "tori_gif",
    ]
};

// 追加のカテゴリー(五十音)
export const hiraganaSyllabaryCategory = {
    "id": "hiraganaSyllabary",
    "emojis": [
        "a",
        "i",
        "u",
        "e",
        "o",
        "ka",
        "ki",
        "ku",
        "ke",
        "ko",
        "sa",
        "si",
        "su",
        "se",
        "so",
        "ta",
        "ti",
        "tu",
        "te",
        "to",
        "na",
        "ni",
        "nu",
        "ne",
        "no",
        "ha",
        "hi",
        "hu",
        "he",
        "ho",
        "ma",
        "mi",
        "mu",
        "me",
        "mo",
        "ya",
        "yu",
        "yo",
        "ra",
        "ri",
        "ru",
        "re",
        "ro",
        "wa",
        "wo",
        "nn",
    ]
};